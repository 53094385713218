<template>
    <div class="dashboard_box">
        <EmployeLeftbar />
        <div class="dashboard_right">
            <EmployeHeader @check-in-out="handleCheckInOut"/>
            <EmployeeDashboard ref="dashboard"/>
        </div>    
    </div>    
</template>
<script>
import "../assets/css/dashboard.css"
import { createLocal } from 'the-storages'
import { BASE_URL } from "../config";
import axios from "axios";

const mirror = createLocal()
const storage = mirror._prx

import EmployeHeader from "@/components/EmployeDashboard/EmployeHeader.vue"
import EmployeLeftbar from "@/components/EmployeDashboard/EmployeLeftbar.vue"

// import HeaderEmp from "../components/UserDashboard/HeaderEmp.vue";

// import SidebarEmp from "../components/UserDashboard/SidebarEmp.vue";

import EmployeeDashboard from "../components/UserDashboard/EmployeeDashboard.vue";

export default {
    name: 'EmpDashboardPage',
    components:{
        EmployeHeader,
        EmployeLeftbar,
        EmployeeDashboard
    },
    data() {
        return {
            storageData: mirror,
            storage: storage,
        }
    },
    methods :{
        async handleCheckInOut() {
            console.log("handleinout");
            // Call the abcd method in the Dashboard component
            await this.$refs.dashboard.abcde('253', '4/3/2024', '3');
        },
    },
    async created(){
        this.userName = mirror.USERNAME
   },
    beforeRouteEnter(to, from, next) {
      //console.log(storage.get('ID'));
      if (storage.get('ID')) {
          next();
      }
      else {
          next('/');
      }
  }
}
</script>
<style scoped>
</style>