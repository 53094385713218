<template>
  <div class="emp_dashboard_section">
    <!-- Left side content -->
    <div class="emp_dashboard_left">
      <EmpDashboardLeftside />
    </div>
    <!-- Right side content -->
    <div class="emp_dashboard_right">
      <EmpDashboardHeader @userListUpdated="updateUserList" @imageUpdated="updateImage" />
      <div class="emp_dashboard_content">
        <!-- Pass userList data to DashboardTeamEmploye component -->
        <DashboardTeamEmploye :userListString="userList1" :userImageString="userImage" />
      </div>
    </div>
  </div>
</template>

<script>
import EmpDashboardHeader from "@/components/EmpDashboard/EmpDashboardHeader.vue";
import EmpDashboardLeftside from "@/components/EmpDashboard/EmpDashboardLeftside.vue";
import DashboardTeamEmploye from "@/components/EmpDashboard/DashboardTeamEmploye.vue";
import { createLocal } from "the-storages";
const mirror = createLocal();

export default {
  name: "EmpDashboardNewPage",
  components: {
    EmpDashboardHeader,
    EmpDashboardLeftside,
    DashboardTeamEmploye,
  },
  data() {
    return {
      ID: "", // Initialize ID if it's not already initialized
      userList1: [],
      userImage: '',
    };
  },
  created() {
    if (!mirror.ID) {
      localStorage.clear();
      this.$router.push('/');
    }
  },
  methods: {
    // Method to update userList data
    updateUserList(userList) {
      this.userList1 = userList;
    },
    updateImage(image) {
      this.userImage = image + '?' + new Date().getTime(); // Append a unique query parameter
      console.log("Image updated:", image);
    },
  },
  watch: {
    userList1(newVal, oldVal) {
      console.log("userList1 updated:", newVal);
    },
    userImage: {
      handler(newVal, oldVal) {
        console.log("userImage updated:", newVal);
      },
      deep: true, // Ensure deep watching for nested object changes
    },
  },
};
</script>
