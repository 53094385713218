<template>
  <div class="main-right">
     <div class="main-title">
       <h2>Holiday List for {{ current_year }}</h2>
     </div>
     <div class="head-bar">
       <ul>
         <li>
           No of Holidays: <span></span> <span> {{holiday_list.length }}</span>
         </li>
       </ul>
       <div class="fild-info ml-auto">
         <!-- <button class="stl-btn" @click="clickedHolidayDownload()">
           download
           <img src="../../../public/assets/images/icon/download.svg" alt="">
         </button> -->
         <button class="stl-btn" id="modal-tggl1" @click="isOpen()">
           Add New Holiday
         </button>
         
       </div>
     </div>
     
     <div class="table-section" id="holidaylistdownload">
       <div class="table-wapper">
         <div class="table-data px-0">
           <table cellspacing="0" cellpadding="0" border="0">
             <thead>
               <tr >
                 <th>Name</th>
                 <th>Date</th>
                 <th>Actions</th>
               </tr>
             </thead>
             <tbody>
               <tr v-for="user in holiday_list" :key="user">
                 <td>{{user.holiday_name}}</td>
                 <td>{{user.holiday_date}}</td>
                 <td><input type='button' value='Delete' @click='deleteRecord(index,user.id)'></td>
               </tr>
             </tbody>
           </table>
         </div>
       </div>
   </div>

   <div class="popup-modal" id="popupModa1" v-if="seen">
     <div class="mp-content">
       <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
         <img src="../../../public/assets/images/icon/x-lg.svg" alt="">
       </button>
       <div class="bdy-sect">
         <h3>Add New Holiday</h3>
         <div class="item-cr add_holiday">
           <form v-on:submit.prevent="fetchDate" v-on:reset="resetForm" ref="form" name="form">
            
             <div class="fr-grp new_emp">
               <!-- <div class="ipm">
                 <img src="../../../public/assets/images/icon/calendar-plus.png" alt="">
               </div> -->
               <!-- <datepicker  v-model="date" /> -->
               <datepicker :full-month-name="true" v-model="date" placeholder="Select date"/>
               <span v-if="errormessage_date!=''" class="errormessage valid_msg">{{ errormessage_date }}</span>
               <!-- <input type="text" class="date" placeholder="Pick the multiple dates"> -->
             </div>
     
             <div class="frm-ilne">
               <label for="">Title-</label>
               <input type="text" v-model="title" placeholder="Title">
               <span v-if="errormessage_title!=''" class="errormessage valid_msg">{{ errormessage_title }}</span>

             </div>
             <div class="frg-inline">
               <input type="reset" value="clear">
               <input v-if="addDate == false" type="submit" value="submit">
               <input v-else disabled type="submit" value="submit">
             </div>
           </form>

         </div>
       </div>
       
     </div>
   </div>
   <!-- modal(popup) -->
   <!-- <datepicker  v-model="date" placeholder=""/> -->
 </div>
</template>

<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';
// import Datepicker from '@vuepic/vue-datepicker';
// import Datepicker from 'vue3-datepicker';
import Datepicker from 'vuejs3-datepicker';

const mirror = createLocal()
const storage = mirror._prx

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

import Swal from 'sweetalert2';

export default {
 name: 'AdminHolidayList',
 components:{
     Datepicker
 },
 data() {
     return {
     
         storageData: mirror,
         storage: storage,
         users: [],
         searchQuery: null,
         dataVal:{},
         seen : false,
         date:null,
         title:'',
         holidaydata:[],
         getHolidayData:{},
         submit_status:'',
         errormessage_date:'',
         errormessage_title:'',
         holiday_list:[],
         getHolidayData:{},
         holiday_array:[],
         current_year:'',
         addDate: false
     }
 },
 async created(event){
   // this.fetchDate();
   this.current_year= new Date().getFullYear();
   console.log("curr year",this.current_year);
   this.holidayData(this.current_year);
   // console.log("ffffffff",this.holiday_array);
   //this.fetchDate();
 },
 methods : {

  async clickedHolidayDownload(){
      var pdf = new jsPDF("1", "mm", "a3");
      var element = document.getElementById('holidaylistdownload');
      var width= element.style.width;
      var height = element.style.height;
          html2canvas(element).then(canvas => {
              //console.log(canvas);
              var image = canvas.toDataURL('image/png');
              pdf.addImage(image, 'JPEG', 15, 40, width, height);
              pdf.save('HolidayList' + '.pdf')
      });
  },
  resetForm() {
      this.date = null;
      this.title = '';
      this.errormessage_date = '';
      this.errormessage_title = '';
      // Any other fields that need to be reset
  },
  async fetchDate(){

    this.addDate = true; 
    var dateStringified = this.date.toString().split(" ");
    var holidayMonth = this.date.getMonth()+ 1;
    var dateFormatted = dateStringified[2]+'/'+holidayMonth+'/'+dateStringified[3];
    console.log("date",dateFormatted);
    console.log("holiday1",this.holiday_array);

    console.log("kdyugdugd",this.holiday_array.includes(dateFormatted));
    if(!this.date){
    
      this.errormessage_date = 'Please Select a Date';
            setTimeout(() => {
                    this.errormessage_date = '';
                },3000);
            this.errormessage_title = "";
            
    }
    
    
    else if(this.holiday_array.includes(dateFormatted)){
      this.errormessage_date = 'This date is alreday taken';
            setTimeout(() => {
                    this.errormessage_date = '';
                },3000);
            this.errormessage_title = "";
    }
    else if(!this.title){
      this.errormessage_title = 'Please Select a Title';
            setTimeout(() => {
                    this.errormessage_title = '';
                },3000);
            this.errormessage_date = "";
    }
    // else if(this.title.type!='text'){
    //   this.errormessage_title = 'Please Select a Title';
    //         setTimeout(() => {
    //                 this.errormessage_title = '';
    //             },3000);
    //         this.errormessage_date = "";
    // }
    else{
    var dateStringified = this.date.toString().split(" ");
    var holidayMonth = this.date.getMonth()+ 1;
    var dateFormatted = dateStringified[2]+'/'+holidayMonth+'/'+dateStringified[3];
    this.getHolidayData = {
          'date': dateFormatted, 'title':this.title, 'month':holidayMonth, 'year':dateStringified[3]
    };
        console.log(this.getHolidayData);

    await axios.post(BASE_URL + '/api/getHolidayData',this.getHolidayData)
            .then((response)=>{
              this.submit_status=  response.data.data;
              this.seen = false;
              if(response.data.data == true){

                Swal.fire({
                            title: 'Done',
                            text:  response.data.message,
                            icon: 'success',
                        
                        });


                this.date = "";
                this.title = "";
              

                }else{

                Swal.fire({
                        title: 'OPPS',
                        text:   "Holiday list not submitted",
                        icon: 'warning',
                    
                    });
                }
        })
    //console.log("gysgysg",this.holiday_list);
    // return this.holiday_list;
    this.holidayData(dateStringified[3]);

    }
    this.addDate = false; 

  
  },
  async holidayData(year){
    this.getHolidayData = {
          'year':year
    }
    await axios.post(BASE_URL + '/api/fetchHolidayData',this.getHolidayData)
            .then((response)=>{
              this.holiday_list=  response.data.holiday_list;
              this.holiday_array= this.holiday_list.map(x => x.holiday_date);
              console.log("holiday??????",this.holiday_list);
            
        })
  },
    async deleteRecord(index,id){
    
    if(confirm("Are you sure you want to check in?")){
        await axios.post(BASE_URL + '/api/deleteHoliday', { 
        'id': id
      })
      .then((response)=> {
        this.deleteData = response.data           
      });
      this.holidayData(this.current_year);
    }
    else{
      
    }

    }
    ,
  closeDatepicker(event){
      this.seen = false;
    },

    isOpen(){
      console.log("rr",this.submit_status);
      this.seen = true;
  },
 }
}
</script>