<template>
    <div class="dashboard_header">
        <div class="left">
            <ul>
                <!-- <li>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill="#2f7eed" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg>
                </li> -->
                <li>Welcome<br> 
                    <span>{{userName}}</span>
                </li>
                <!-- <li>
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
                </li>
                <li>Dashboard</li> -->
            </ul>
        </div>
        <div class="right">
            <div class="timer">
              <button v-if="text==='Loading..'" class="timerdiv" disabled @click="added(),timer(text)" >
                  <div class="clock_box">
                      <img src="@/assets/dashboard/clock1.gif" alt="clock1" v-if = "text == 'Check Out'" />
                      <img src="@/assets/dashboard/clock2.png" alt="clock2" v-else />
                  </div>
                  <div class="times_box">
                      <h3>
                          <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                      </h3>
                      <div>
                          <p v-if="text==='Loading..'" >{{ text}}</p>
                          <p v-else >{{ text}}</p>
                      </div>
                  </div>
              </button>
              <button v-else class="timerdiv" @click="added(),timer(text)" >
                  <div class="clock_box">
                      <img src="@/assets/dashboard/clock1.gif" alt="clock1" v-if = "text == 'Check Out'" />
                      <img src="@/assets/dashboard/clock2.png" alt="clock2" v-else />
                  </div>
                  <div class="times_box">
                      <h3>
                          <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                      </h3>
                      <div>
                          <p v-if="text==='Loading..'" >{{ text}}</p>
                          <p v-else >{{ text}}</p>
                      </div>
                  </div>
              </button>
            </div>
            <!-- <div class="timer" v-if="text==='Loading..'">
                <button
                    
                    @click="added(),timer(text)"
                    :style="{ backgroundColor: buttonBackground }"
                    >
                    <div class="clock_box">
                        <img v-if="clockHide" src="@/assets/dashboard/clock2.png" alt="clock2" />
                        <img v-else src="@/assets/dashboard/clock1.gif" alt="clock1" />
                    </div>

                    <li>
                        <div class="task-timer">
                            <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                        </div>
                    </li>

                    <div class="times_box">
                        <h3>{{ text }}</h3>
                    </div>
                </button>
            </div>
            <div class="timer" v-else>
                <button
                    @click="added(),timer(text)"
                    :style="{ backgroundColor: buttonBackground }"
                    >
                    <div class="clock_box">
                        <img v-if="clockHide" src="@/assets/dashboard/clock2.png" alt="clock2" />
                        <img v-else src="@/assets/dashboard/clock1.gif" alt="clock1" />
                    </div>
                    <div class="times_box">
                        <h3>{{ text }}</h3>
                    </div>
                </button>
            </div> -->
            <!-- <div class="leave-st-main">
                <div class=" use-id-frm">
                    <ul class="timer_log">
                        <li>
                        <div class="task-timer">
                            <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                        </div>
                        </li>

                        <li>
                        <button v-if="text==='Loading..'" :disabled="buttoncheckfordisable" @click="added(),timer(text)" >{{ text}}</button>
                        <button v-else @click="added(),timer(text)" >{{ text}}</button>
                        </li>

                    </ul>
                </div>
            </div> -->

            <!-- <div class="timer">
                <button class="timerdiv" @click="added(),timer(text)"  >
                    <div class="clock_box">
                        <img src="@/assets/dashboard/clock1.gif" alt="clock1" v-if = "text == 'Check Out'" />
                        <img src="@/assets/dashboard/clock2.png" alt="clock2" v-else />
                    </div>
                    <div class="times_box">
                        <h3>
                            <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                        </h3>
                        <div>
                            <p v-if="text==='Loading..'" >{{ text}}</p>
                            <p v-else >{{ text}}</p>
                        </div>
                    </div>
                </button>
            </div> -->

            <!-- <ul class="timer_log">
                <li>
                {{currentdateandtime}}
                </li>        
                <li>
                <div class="task-timer">
                    <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                </div>
                </li>

                <li>
                <button v-if="text==='Loading..'" :disabled="buttoncheckfordisable" @click="added(),timer(text)" >{{ text}}</button>
                <button v-else @click="added(),timer(text)" >{{ text}}</button>
                </li>
            </ul> -->

            <div class="announcement">
                <div class="announcement_btn">
                    <!-- <button class="modal_button" @click="toggleClass1">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512"><path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"/></svg>
                    </button> -->
                    <button class="modal_button">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512"><path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"/></svg>
                    </button>
                    <span>
                        0
                    </span>
                </div>
                <div :class="{ 'active_class': isClassAdded1 }" class="modal_divs">
                    <div class="modal_width">
                        <div class="header">
                            <div class="name">
                                <h2>Announcements</h2>
                            </div>
                            <div class="close">
                                <button @click="toggleClass1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                </button>
                            </div>
                        </div>
                        <div class="modal_body">
                            <div class="box">
                                <div class="text">
                                    <h3>
                                        After the task is completed or ongoing, please submit the detailed report Not just like done/completed comments, please provide a detailed report. You can discuss the PM team.
                                        <span>21h</span> 
                                    </h3>
                                    <p>
                                        <span></span>
                                        Kuntal Patra
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{ 'modal_background': isClassAdded1 }" @click="toggleClass1"></div>
            </div>

            <div class="notifiaction">
                <div class="notifaction_btn">
                    <button class="modal_button" @click="toggleClass2">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="18" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v25.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm0 96c61.9 0 112 50.1 112 112v25.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V208c0-61.9 50.1-112 112-112zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/></svg>
                    </button>
                    <span>
                        {{notification_count}}
                    </span>
                </div>
                <div :class="{ 'active_class': isClassAdded }" class="modal_div">
                    <div class="modal_width">
                        <div class="header">
                            <div class="name">
                                <h2>Activites</h2>
                            </div>
                            <div class="close">
                                <button @click="toggleClass">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                </button>
                            </div>
                        </div>
                        <div class="modal_body" >
                            <div v-if="notification !=''" v-for="user in notification">
                                <div class="box" v-if="user.type == 'WFH' ">
                                    <div class="image">
                                        <img v-if="user.seenstatus == 'seen'" src="@/assets/images/other/notyf-eye-off.png" alt=""><img v-if="user.seenstatus == 'unseen'" src="@/assets/images/other/notyf-eye.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Casual Leave' ">
                                    <div class="image">
                                        <img v-if="user.seenstatus == 'seen'" src="@/assets/images/other/notyf-eye-off.png" alt=""><img v-if="user.seenstatus == 'unseen'" src="@/assets/images/other/notyf-eye.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Medical Leave' ">
                                    <div class="image">
                                        <img v-if="user.seenstatus == 'seen'" src="@/assets/images/other/notyf-eye-off.png" alt=""><img v-if="user.seenstatus == 'unseen'" src="@/assets/images/other/notyf-eye.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Provisional' ">
                                    <div class="image">
                                        <img v-if="user.seenstatus == 'seen'" src="@/assets/images/other/notyf-eye-off.png" alt=""><img v-if="user.seenstatus == 'unseen'" src="@/assets/images/other/notyf-eye.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Date Change' ">
                                    <div class="image">
                                        <img v-if="user.seenstatus == 'seen'" src="@/assets/images/other/notyf-eye-off.png" alt=""><img v-if="user.seenstatus == 'unseen'" src="@/assets/images/other/notyf-eye.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Unpaid' ">
                                    <div class="image">
                                        <img v-if="user.seenstatus == 'seen'" src="@/assets/images/other/notyf-eye-off.png" alt=""><img v-if="user.seenstatus == 'unseen'" src="@/assets/images/other/notyf-eye.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Carry forward' ">
                                    <div class="image">
                                        <img v-if="user.seenstatus == 'seen'" src="@/assets/images/other/notyf-eye-off.png" alt=""><img v-if="user.seenstatus == 'unseen'" src="@/assets/images/other/notyf-eye.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for this financial year has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                            </div>
                            <div v-else class="notification_div">
                                <p>No new notification to show</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{ 'modal_background': isClassAdded }" @click="toggleClass"></div>
            </div>

            <div class="profile">
                <div class="dropdown">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img v-if="empimage!=''" v-bind:src="empimage" alt="">
                        <!-- <img v-else src="@/assets/dashboard/profile-1.jpg" alt="profile"> -->
                        <span class="image_name" v-else >{{first_name_letter}}{{ last_name_letter }}</span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <div class="profile">
                            <!-- <div class="image">
                                <img src="@/assets/dashboard/profile-1.jpg" alt="profile">
                            </div> -->
                            <div class="text">
                                <h2>{{userName}} {{ userlName }}</h2>
                            </div>
                        </div>
                        <!-- <div class="profile_link">
                            <ul>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                                        </span>
                                        Profile
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
                                        </span>
                                        Settings
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z"/></svg>
                                        </span>
                                        Change Password
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <div class="profile_mode">
                            <h2>Mode</h2>
                            <ul>
                                <li>
                                    <a href="#">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"/></svg>
                                        </span>
                                        Light
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"/></svg>
                                        </span>
                                        Dark
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                        <div class="profile_on">
                            <ul>
                                <li>
                                    <a class="dropdown-item" href="#" v-on:click="logout">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"/></svg>
                                        </span>
                                        Log Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createLocal, createSession, createStorage } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import HackTimer from "../../HackTimer";
const mirror = createLocal()
const storage = mirror._prx

export default {
    name: 'EmployeHeader',
    data() {
        return {
            isClassAdded: false,
            isClassAdded1: false,
            clockHide: true,
            buttonBackground: "#3cb89b",
            currentTime: "00:00:00",
            buttonText: "Check In",
            checkInTime: null,
            checkOutTime: null,
            showPopup: true,
            text: 'Loading..',
            transactions: [],
            timerId:'',
            time0:'',
            timerSelection : 'Work',
            startingMinutes : '',
            startingSeconds : '00',
            timerRunning:'',
            stopTimer:'',
            playing : false,
            currentMinutes:'',
            currentSeconds:'',
            updateCoundtdownIntervalHandle : null,
            initial_minutes: '',
            initial_seconds: '',
            buttoncheckfordisable:true,
            storageData: mirror,
            storage: storage,
            isHovering: false,
            isHovering1 : false,
            notification: '',
            countn:0,
            empimage:'',
            fetchednotificationcount:'',
            empname:'',
            notification_count:0,
            first_name_letter:'',
            last_name_letter:'',
            statusInputData:'',
            current_CinCoutStatus:''
        };
    },

async created(event){
        
    this.userName = mirror.USERNAME;
    this.userlName = storage.USERLNAME;
    this.userEmail = mirror.USEREMAIL;
    this.empId = mirror.EMPID;
    this.casual_leave = mirror.CASUAL_LEAVE;
    this.sick_leave = mirror.SICK_LEAVE;
    this.emp_status = mirror.EMP_STATUS;
    this.first_name_letter = this.userName.charAt(0);
    this.last_name_letter = this.userlName.charAt(0);
    this.ID = mirror.ID;
    const notification_result =await axios.post(BASE_URL + '/api/getempnotification',{id:this.ID});
    this.notification = notification_result.data.notificationdata;
    this.fetchednotificationcount = notification_result.data.fetchednotificationcount;
    // this.countn = notification_result.data.count.length;
    this.empimage = notification_result.data.empimage.image;
    this.notification_count = notification_result.data.empnotifcation_count;
    this.fetchkey();

    var currentTime1 = new Date();
    var currentOffset = currentTime1.getTimezoneOffset();
    var offsettobechanged = 330 + currentOffset;

    console.log("currentOffset",currentOffset);
    console.log("offsettobechanged",offsettobechanged);


    const now = new Date();
    const utcOffset = (offsettobechanged/60).toFixed(1); // IST UTC offset is 5 hours and 30 minutes
    console.log("utcOffset",utcOffset);
    // Calculate the IST time by adding the UTC offset
    const istTime = new Date(now.getTime() + utcOffset * 60 * 60 * 1000);
    var today0 = istTime.toLocaleString();
    console.log("istTime",istTime.toLocaleString());





    var today_date0 = today0.split(",");
    var today_date = today_date0[0].split("/");
    this.currentdateandtime = today_date0[0];
    console.log("today_date",today_date0);

    var today_date_formatted = today_date[1].concat("/",today_date[0]).concat("/",today_date[2]);
    var currentdate = today_date[1].concat("-",today_date[0]).concat("-",today_date[2]);
    var currenttime = today_date0[1];

    var todayTime_split = today_date0[1].split(':'); 
    console.log("dgsfsgs",todayTime_split);

    var ampm = todayTime_split[2].split(" ")[1];
    if(ampm == undefined){
        if(todayTime_split[0]>=12){
        ampm = 'pm';
        }else{
        ampm = 'am';
        }
    }
    console.log("ampm",ampm);
    console.log("todayTime_split",todayTime_split[0]);

    if (ampm == 'AM'|| ampm == 'am') {
        if (todayTime_split[0]==12) {
        var todayTime_seconds = (+todayTime_split[0]-12) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
        } else {
        var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
        }
        console.log("am");
    }
    else{
        console.log("pm");

        if (todayTime_split[0]==12) {
        var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]);  
        console.log("pm if");

        }
        else if(todayTime_split[0]>12){
        var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]);  
        
        }else{
        var todayTime_seconds = (+todayTime_split[0]+12) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
        }
    }

    // var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
    console.log("currtime", todayTime_seconds);

    var currdate = new Date();
    var currmonth = currdate.getMonth()+1;
    var currday = currdate.getDate();
    var curryear = currdate.getFullYear();
    var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear);

    var start_date1=new Date();
    var attendance_year1 =start_date1.getFullYear();
    var attendance_month1 =start_date1.getMonth();
    var month = attendance_month1;
   
    var uId = this.ID;
    // var today = today_date0[0];
    var month = attendance_month1;
    var today = concatteddate;
    // console.log("aaaaaaa",uId);
    // console.log("bbbbbbb",today);
    // console.log("ccccccc",month);
    this.abcd(uId, today, month);

    this.statusInputData = {"id":uId, "date":today, "month":month};
    const getInOutStatus= await axios.post(BASE_URL + '/api/getCheckinStatus',this.statusInputData);
    console.log("ifcheckedin",getInOutStatus.data.statusInOut);
    this.current_CinCoutStatus = (getInOutStatus.data.statusInOut==null)?'out':getInOutStatus.data.statusInOut.status;
    var lastCheckinTime = (getInOutStatus.data.last_chekin_time==null)?'00:00:00':getInOutStatus.data.last_chekin_time.start_time;

    console.log("lastCheckinTime",lastCheckinTime);
    var lastCheckinTime_split = lastCheckinTime.split(':'); 
    var ampmcheckin = lastCheckinTime_split[2].split(" ")[1];
    console.log("lastCheckinTime",lastCheckinTime_split[0]);

    if (ampmcheckin == 'AM'||ampmcheckin == 'am') {
      if (lastCheckinTime_split[0]==12) {
        var lastCheckinTime_seconds = (+lastCheckinTime_split[0]-12) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
        
      } else {
        var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
        
      }
      console.log("am");
    } else {
      if (lastCheckinTime_split[0]==12) {
        var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
        
      }else{
        var lastCheckinTime_seconds = (+lastCheckinTime_split[0]+12) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 

      }
    console.log("pm");
      
    }
    // var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
    this.startingSeconds = todayTime_seconds - lastCheckinTime_seconds;
  
    console.log("timeeeeeeee1", lastCheckinTime_seconds);
    console.log("timeeeeeeee2", this.startingSeconds);
    console.log("timeeeeeeee3", todayTime_seconds);
    console.log("status", this.current_CinCoutStatus);
  


    if (this.current_CinCoutStatus=="out") {
      this.text = "Check In";
      this.startingSeconds = "0";
      //this.chechinCheckOutStatus = false;
      console.log("IF");
      
    }
    else{
      this.text = "Check Out";
      this.chechinCheckOutStatus = true;
      this.clock(this.chechinCheckOutStatus,this.startingSeconds);
      console.log("ELSE IF");

    } 

    },
   
    methods: {
        toggleClass() {
            this.isClassAdded = !this.isClassAdded;
        },
        toggleClass1() {
            this.isClassAdded1 = !this.isClassAdded1;
        },

        async toggleClass2(){
            this.notification_count = 0;
            this.isClassAdded = !this.isClassAdded;
            const emp_image =await axios.post(BASE_URL + '/api/updateusernotifiction',{id:this.ID});
        },
        
        toggleClock() {
            if (this.clockHide) {
                // Check In
                this.checkInTime = new Date();
                this.timer = setInterval(() => {
                // Update currentTime every second
                this.currentTime = this.formatTime(new Date() - this.checkInTime);
                }, 1000);
            } else {
                // Check Out
                this.checkOutTime = new Date();
                clearInterval(this.timer);
                this.currentTime = "00:00:00"; // Reset timer display
            }

            this.clockHide = !this.clockHide;
            this.buttonBackground =
                this.buttonBackground === "#3cb89b" ? "#e95b6d" : "#3cb89b";
            this.buttonText = this.clockHide ? "Check In" : "Check Out";
        },

        formatTime(ms) {
            // Format milliseconds to HH:mm:ss
            const seconds = Math.floor((ms / 1000) % 60);
            const minutes = Math.floor((ms / (1000 * 60)) % 60);
            const hours = Math.floor(ms / (1000 * 60 * 60));

            const formattedTime = `${this.padZero(hours)}:${this.padZero(
                minutes
            )}:${this.padZero(seconds)}`;
            return formattedTime;
        },

        padZero(value) {
            // Add leading zero if needed
            return value < 10 ? `0${value}` : value;
        },

        closePopup() {
            this.showPopup = false;
        },

        async added() {
            
            this.$emit('check-in-out');
            this.clicked=!this.clicked;
            // this.clicked = true;
            var last_time = new Date().toLocaleString();
            var last_time_s = last_time.split(",");
            const latest_time = "11:00:00 AM";
            var latest_in = last_time_s[0].concat(", ",latest_time)

            var today1= new Date().toLocaleString();
            var today_date1 = today1.split(",");
            var start_date_added=new Date();
            var attendance_month_added =start_date_added.getMonth();
            //////////////////////

            ///////////////////////////

            if (this.text == "Check In") {
            if(confirm("Are you sure you want to check in?")){

            this.startingSeconds = "00"
            this.text = "Check Out";
            var start_time1=new Date().toLocaleString();
            var checkin_hrs = new Date().getHours();
            console.log("checkin_hrs",checkin_hrs);
            console.log("start_time1",start_time1);
            var checkin_date = start_time1.split(",");
            console.log("checkin_date",checkin_date[1]);
            var checkin_ampm = checkin_date[1].split(" ")[2];
            console.log("checkin_ampm",checkin_ampm);
            if (checkin_ampm =='am') {
            checkin_ampm = 'AM';
            } else if(checkin_ampm =='pm'){
            checkin_ampm = 'PM';
            
            }else if(checkin_ampm == undefined){
            console.log("else if");
            checkin_ampm = checkin_hrs >= 12 ? 'PM' : 'AM';
            }else{
            console.log("else");

            checkin_ampm = checkin_ampm
            }
            console.log("checkin_ampm1",checkin_ampm);

            var checkin_time =  checkin_date[1].split(" ")[1].concat(" ",checkin_ampm);
            console.log("checkin_time",checkin_time);

            var start_date=new Date();
            //  var curr_m = start_date.getMonth()+1;
            //  var curr_d = start_date.getDate();
            //  var curr_y = start_date.getFullYear();
            //  var curr_date = curr_m.concat("/",curr_d).concat("/",curr_y);
            
            var start_date1= new Date().toLocaleString();
            var start_date2 = start_date1.split(",");

            var attendance_year =start_date.getFullYear();
            var attendance_month =start_date.getMonth();
            // const ctime = start_time.split();
            //   console.log("dhdugdyydg",ctime[0]);
            //  var cin =this.cin.push(checkin_time);
            var cinms = start_date.getTime();
            var currdate = new Date();
            var currmonth = currdate.getMonth()+1;
            var currday = currdate.getDate();
            var curryear = currdate.getFullYear();
            var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear)
            // console.log("checkin1 time",checkin_date[0],checkin_date[1],cinms,attendance_month);
            // console.log(Date.parse(latest_in) > Date.parse(last_time));
            var late_status =Date.parse(latest_in) < Date.parse(last_time);
            // console.log("late",late_status);

            var current_date = new Date();
            var currday = String(current_date.getDate()).padStart(2, '0');
            var current_day = current_date.getDay();
            var currentmonth = current_date.getMonth()+1;
            var current_year = current_date.getFullYear();
            var concattedcurrdate = currentmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",current_year);
            console.log("concatteddate",concattedcurrdate);
            await axios.post(BASE_URL + '/api/getemployeeleaverecord',{
                        'id': this.ID,
                        'month':currentmonth,
                        'year':current_year
                    })
                    .then((response)=>{
                        //console.log(response.data);

                        // this.leaverecord = response.data.empdetails;
                        this.holiday_list = response.data.holiday_list;
                        this.holiday_array = this.holiday_list.map(x => x.holiday_date);
                })

                this.holiday_array_formatted = new Array();

            for (let i = 0; i < this.holiday_array.length; i++) {
            var split_holiday = this.holiday_array[i].split("/");
            this.holiday_array_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]));
            
            }
            console.log("this.holiday_array_formatted",this.holiday_array_formatted);


            var isWorkingDay;
            if (current_day == 6 || current_day == 0) {
            console.log("if working");
            isWorkingDay = 0;
            }else if(this.holiday_array_formatted.includes(concattedcurrdate)){
            console.log("else if working");

            isWorkingDay = 0;
            }else{
            console.log("else working");

            isWorkingDay = 1;
            }
            console.log("isWorkingDay",isWorkingDay);
            this.dataVal = {"checkin_time":checkin_time,"checkin_date":concatteddate,"checkout_time":'',"checkout_date":'',"cinms":cinms,"coutms":coutms,"empid":this.ID,"empname":this.userName,"emplname":this.userlName,"empemail":this.userEmail,"checkin_type":this.text,"late_status":late_status,"attendance_year":attendance_year,
            "attendance_month":attendance_month,"today_date":concatteddate,"status":"in","isWorkingDay":isWorkingDay,"encryptedstring":this.encryptedstring};
            const res=await axios.post(BASE_URL + '/api/getattendance1',this.dataVal);
            this.fetchkey();
            // this.abcd(this.ID,checkin_date[0]);
            }


            } else {
            if(confirm("Are you sure you want to check out?")){

            this.text = "Check In";
            var checkin_hrs = new Date().getHours();
            console.log("checkin_hrs",checkin_hrs);
            var end_time1=new Date().toLocaleString();
            var checkout_date = end_time1.split(",");
            console.log("checkin_date",checkout_date[1]);
            var checkout_ampm = checkout_date[1].split(" ")[2];
            console.log("checkout_ampm",checkout_ampm);
            if (checkout_ampm =='am') {
            checkout_ampm = 'AM';
            } else if(checkout_ampm =='pm'){
            checkout_ampm = 'PM';
            
            }else if(checkout_ampm == undefined){
            console.log("else if");
            checkout_ampm = checkin_hrs >= 12 ? 'PM' : 'AM';
            }else{
            checkout_ampm = checkout_ampm;
            }
            console.log("checkout_ampm",checkout_ampm);

            var checkout_time =  checkout_date[1].split(" ")[1].concat(" ",checkout_ampm);
            console.log("checkout_time",checkout_time);
            //  checkout_date = [];
            var end_date=new Date();
            //  var cinms = start_date.getTime();

            var currdate = new Date();
            var currmonth = currdate.getMonth()+1;
            var currday = currdate.getDate();
            var curryear = currdate.getFullYear();
            var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear)
            //  var cout =this.cout.push(checkout_time);
            var coutms = end_date.getTime();

            clearInterval(this.timerId);
            // console.log("time2",this.timerId);
            this.timerId = null;

            this.dataVal = {"checkin_time":'',"checkin_date":'',"checkout_time":checkout_time,"checkout_date":concatteddate,"cinms":cinms,"coutms":coutms,"empid":this.ID,"empname":this.userName,"emplname":this.userlName,"empemail":this.userEmail,"checkin_type":this.text,"attendance_year":attendance_year,"attendance_month":attendance_month,"status":"out","encryptedstring":this.encryptedstring};
            const res=await axios.post(BASE_URL + '/api/getattendance1',this.dataVal);
            this.fetchkey();
            // this.abcd(this.ID,checkout_date[0]);
            }else{
            location.reload();
            }
            }

            this.abcd(this.ID,concatteddate,attendance_month_added);

        },

        timer(type) {

            if (type=="Check Out") {

                console.log("ydctcxgrsessrty if",this.updateCoundtdownIntervalHandle);

                this.playing = true;
                
                this.startingMinutes = this.initial_minutes;
                
                let time = this.startingSeconds;
                //console.log("uydtdt",time);
                if (this.updateCoundtdownIntervalHandle !== null) {
                    clearInterval(this.updateCoundtdownIntervalHandle);
                }
                this.updateCoundtdownIntervalHandle = setInterval(updateCoundtdown, 1000);
                time++;

                function updateCoundtdown() {
                    // if (this.playing) {
                    // console.log("showtime4",time);
                    let hours = Math.floor(time / 3600);
                    // console.log("showtime5",hours);
                    var minutes = Math.floor(time / 60);
                    minutes = minutes % 60;

                    // console.log("showtime6",minutes);
                    let seconds = time % 60;

                    seconds = seconds < 10 ? '0' + seconds : seconds;
                    minutes = minutes < 10 ? '0' + minutes : minutes;
                    hours   = hours   < 10 ? '0' + hours   : hours;

                    this.currentMinutes = minutes;
                    this.currentSeconds = seconds;
                    this.currentHours   = hours;
                    // console.log("minutes,seconds",minutes,seconds);

                    document.querySelector("#minutes").innerHTML = minutes;
                    document.querySelector("#seconds").innerHTML = seconds;
                    document.querySelector("#hours").innerHTML   = hours;
                    time++;
                        // console.log("time2",minutes + ':' + seconds);
                
                }
            } else {

                console.log("ydctcxgrsessrty else",this.updateCoundtdownIntervalHandle);
                clearInterval(this.updateCoundtdownIntervalHandle);
            }
        },

        async fetchkey(){
            await axios.post(BASE_URL + '/api/fetchapikey',{id:this.ID})          
                .then((response)=>{
                    console.log("responsekey",response.data.apikey.api_key);
                    var decryptedString =  atob(response.data.apikey.api_key)
                    console.log("decryptedString",decryptedString);
                    let d = parseInt(decryptedString);
                    //  console.log("newstringtobeencrypted",decryptedString[0]);
                    var d1 = parseInt(decryptedString[0]) +  parseInt(decryptedString[5]);
                    var d2 = parseInt(decryptedString[1]) +  parseInt(decryptedString[6]);
                    var d3 = parseInt(decryptedString[2]) +  parseInt(decryptedString[7]);
                    var d4 = parseInt(decryptedString[3]) +  parseInt(decryptedString[8]);
                    var d5 = parseInt(decryptedString[4]) +  parseInt(decryptedString[9]);
                    this.newstringtobeencrypted = d1.toString() + d2.toString() + d3.toString() +d4.toString() + d5.toString();
                    console.log("newstringtobeencrypted",this.newstringtobeencrypted);
                    this.encryptedstring = btoa(this.newstringtobeencrypted);
                })
        },

        
        async getleavedata(getempdata){
            await axios.post(BASE_URL + '/api/getemployeeleaverecord',getempdata)
                .then((response)=>{
                    //console.log(response.data);

                    this.leaverecord = response.data.empdetails;
                    //  console.log("leaverecord",this.Leave.getleavedetails);
                    this.leaverecord2 = response.data.empdetails;
                    this.holiday_list = response.data.holiday_list;
                    this.holiday_array = this.holiday_list.map(x => x.holiday_date);
                    this.wfhrecord = response.data.wfh_details;
                    this.datechangerecord = response.data.datechange_details;

            })
        },

        async getleaveremaining(getempdata){
            await axios.post(BASE_URL + '/api/getemployeeclml',getempdata)
                .then((response)=>{
                    // console.log(response.data);

                    this.remaining_leave = response.data.remaining_leave;
                    this.remaining_leave_ml = response.data.remaining_leave_ml;
                    this.provisional_leave_count = response.data.provisional_leave_count;

            })
        },

        async abcd(uId,today,month){
            this.idData = {"id":uId, "date":today, "month":month};
            const attendance_data= await axios.post(BASE_URL + '/api/get_attendance',this.idData);
            this.userList = attendance_data.data.userList;
            this.userDetails = attendance_data.data.userDetails;
            this.monthly_attendance_count = attendance_data.data.monthly_attendance_count;
            // console.log("test1",this.monthly_attendance_count);
            // this.current_CinCoutStatus= (attendance_data.data.userDetails==null)?'out':attendance_data.data.userDetails.status;
            console.log("test2",this.current_CinCoutStatus);

            var monthly_hours_with_seconds_split = attendance_data.data.total_hours_monthly.split(":");
            this.monthly_hours = monthly_hours_with_seconds_split[0].concat(":", monthly_hours_with_seconds_split[1])
            // this.monthly_hours = attendance_data.data.total_hours_monthly;
            this.position = attendance_data.data.user_designation.position;

        // console.log("ABCD: ",monthly_hours)
        },

        clock(status,startTime){
            console.log("clock start end");
            this.playing = status; // true /false

            this.startingMinutes = this.initial_minutes;

            let time = startTime;
            console.log("uydtdt",startTime);
            if (this.updateCoundtdownIntervalHandle !== null) {
                console.log("if clock",time);
                clearInterval(this.updateCoundtdownIntervalHandle);
            }
            else{
                console.log("else clock",time);
            }
            // if (this.updateCoundtdownIntervalHandle!="00:00:00") {
            
            // }
            // console.log("time1",time);
            this.updateCoundtdownIntervalHandle = setInterval(updateCoundtdown, 1000);
            // time++;
            // console.log("time2",time);
            function updateCoundtdown() {
                // if (this.playing) {
                // console.log("showtime1",time);
                let hours = Math.floor(time / 3600);
                // console.log("hours",hours);
                var minutes = Math.floor(time / 60);
                minutes = minutes % 60;
                // console.log("minutes",minutes);
                let seconds = time % 60;
                // console.log("seconds",seconds);


                seconds = seconds < 10 ? '0' + seconds : seconds;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                hours   = hours   < 10 ? '0' + hours   : hours;

                this.currentMinutes = minutes;
                this.currentSeconds = seconds;
                this.currentHours   = hours;
                console.log("minutes,seconds.................",minutes,seconds);

                document.querySelector("#minutes").innerHTML = minutes;
                document.querySelector("#seconds").innerHTML = seconds;
                document.querySelector("#hours").innerHTML   = hours;
                time++;
                // console.log("time1",minutes + ':' + seconds);
            
            }
        },

        async showMoreNotification(){
            const notificationdata = {
                        count: this.fetchednotificationcount,
                        id:this.ID
                    };

            const res=await axios.post(BASE_URL + '/api/getempnotification',notificationdata);
            this.notification = res.data.notificationdata;
            this.fetchednotificationcount = res.data.fetchednotificationcount;
            console.log("count",this.fetchednotificationcount);
        },

        async viewAllNotification(){
            const allnotificationdata = {
                        id:this.ID
                    };

            const res=await axios.post(BASE_URL + '/api/getallempnotification',allnotificationdata);
            this.notification = res.data.notificationdata;
            this.fetchednotificationcount = res.data.fetchednotificationcount;
            console.log("count",this.fetchednotificationcount);
        },

        async logout(){
            var today0= new Date().toLocaleString();
            var today_date0 = today0.split(",");
            // var today = today_date0[0];

            var currdate = new Date();
            var currmonth = currdate.getMonth()+1;
            var currday = currdate.getDate();
            var curryear = currdate.getFullYear();
            var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear);
            var today = concatteddate;
            console.log(concatteddate);

            this.statusInputData = {"id":this.ID, "date":today};
            const getInOutStatus= await axios.post(BASE_URL + '/api/getInOutStatus',this.statusInputData);
            this.current_CinCoutStatus = (getInOutStatus.data.statusInOut==null)?'out':getInOutStatus.data.statusInOut.status;
            console.log("idlogout",this.ID);

            console.log("ininoutout",this.current_CinCoutStatus);
                if (this.current_CinCoutStatus=="out") {
                localStorage.clear();
                this.$router.push('/');
                }if (this.current_CinCoutStatus=="in") {
                if(confirm("You are still checked in! Please check out beforhand")){
                }
                }
     
        },

        checkInOut() {
        // Emit an event to notify the parent component
        console.log("emitt");
        this.$emit('check-in-out');
        },

    },

    beforeRouteEnter(to, from, next) {
    console.log(storage.get('ID'));
    if (storage.get('ID')) {
        next();
    }
    else {
        next('/');
    }
}
}

</script>