<template>
    <div class="emp_dashboard_section">
      <div class="emp_dashboard_left">
        <EmpDashboardLeftside />
      </div>
      <div class="emp_dashboard_right">
        <!-- Header component is placed here -->
        <EmpDashboardHeader @reportSubmitted="showdailyreport"/>
        <div class="emp_dashboard_content">
          <div class="dashboard_body">
            <div class="main_box">
              <div class="row h-100">
                <div class="col-5">
                  <div class="table_box holiday_list">
                    <div class="heading">
                      <h2>Holiday List</h2>
                    </div>
                    <div class="table_div">
                      <table>
                        <thead>
                          <tr>
                            <th>Holiday Name</th>
                            <th>Holiday Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(holidayList, index) in holidayListData" :key="index">
                            <td>{{ holidayList.title }}</td>
                            <td>{{ holidayList.start }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <div class="calender_view">
                    <!-- FullCalendar component -->
                    <FullCalendar :options='calendarOptions' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import "../assets/css/dashboard.css";
  import EmpDashboardHeader from "@/components/EmpDashboard/EmpDashboardHeader.vue";
  import EmpDashboardLeftside from "@/components/EmpDashboard/EmpDashboardLeftside.vue";
  import FullCalendar from '@fullcalendar/vue3';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import axios from "axios";
  import { BASE_URL } from "../config";
  
  export default {
    name: 'HolidayList',
    components: {
      EmpDashboardHeader,
      EmpDashboardLeftside,
      FullCalendar,
      dayGridPlugin
    },
    data() {
      return {
        ID:'',
        holidayListData: [],
        calendarOptions: {
          plugins: [dayGridPlugin],
          initialView: 'dayGridMonth',
          weekends: true,
          events: [],
          // eventDidMount: function(arg) {
          //   if (arg.event.classNames[0]) {
          //     var childElement = document.querySelector('.'+arg.event.classNames[0]);
          //     let parentElement = null;
          //     if (childElement) { 
          //       parentElement = childElement.parentElement;
          //       while (parentElement && !parentElement.classList.contains('fc-day')) {
          //         parentElement = parentElement.parentElement;
          //       }
          //       if (parentElement) { 
          //         parentElement.classList.add('eventExistSec');
          //       }
          //     }
          //   }
          // },
        }
      };
    },
    created() {
      this.getHolidayList();
    },
    methods: {
      async getHolidayList() {
        try {
          const response = await axios.post(BASE_URL + '/api/getHolidayList',{id:this.ID});
          this.holidayListData = response.data.holidayList;
          this.generateEvents();
        } catch (error) {
          console.error('Error fetching holiday list:', error);
        }
      },
      generateEvents() {
        if (Array.isArray(this.holidayListData)) {
          this.calendarOptions.events = this.holidayListData.map(holiday => {
            // Parse the date string into a JavaScript Date object
            const dateParts = holiday.start.split('/');
            const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
            return {
              title: holiday.title,
              start: formattedDate,
              allDay: holiday.allDay,
              classNames: holiday.classNames,
            };
          });
        } else {
          console.error('Holiday list is not an array:', this.holidayListData);
        }
      },
    }
  }
  </script>
  