<template>
    <div class="loginBox">
        <div class="loginLeftBox">
            <div class="loginLogo">
                <img src="@/assets/logo.png">
            </div>
            <div class="loginText">
                <h2>Welcome to</h2>
                <h1>reddensoft family</h1>
            </div>
        </div>
        <div class="loginRightBox">
            <div class="loginRightShadow">
                <h2>Login</h2>
                <form @submit.prevent="onSubmit">
                    <div v-if="errormessage != ''" class="errormessage">
                      {{ errormessage }}
                    </div>
                    <div class="loginRightInput" :class="{ error: v$.form.email.$errors.length }">
                        <input type="email" placeholder="Email" class="inputOne" v-model="v$.form.email.$model" >
                        <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                        <span v-if="errormessage_email!=''" class="errormessage">{{ errormessage_email }}</span>
                        <span class="lolgRightOne">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#5A5A5A" class="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                              </svg>
                        </span>
                    </div>
                    <div class="loginRightInput" :class="{ error: v$.form.password.$errors.length }">
                        <input v-if="showPassword" type="text" class="inputOne" placeholder="Password" v-model="v$.form.password.$model" >
                        <input v-else type="password" class="inputOne" placeholder="Password" v-model="v$.form.password.$model">
                          <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                        <span class="lolgRightTwo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#5A5A5A" class="bi bi-lock" viewBox="0 0 16 16">
                                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                            </svg>
                        </span>
                        <span class="lolgRightThree">
                            <button type="button"  @click="toggleShow" :class="{ show: isActive }">
                                <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                </svg>
                                <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </button>
                        </span>
                    </div>
                    <div class="loginRightbtn">
                        <button class="logButton" type="submit">Log In</button>
                    </div>
                    <!-- <span v-if="errormessage_pass!=''" class="errormessage pt-5">{{ errormessage_pass }}</span> -->
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { BASE_URL } from "../config";
import axios from "axios";
import { createLocal, createSession, createStorage } from 'the-storages';

const mirror = createLocal();
const storage = mirror._prx;

export default {
  name: 'Login',
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      errormessage: "",
      storageData: mirror,
      storage: storage,
      showPassword: false,
      password: null,
      isActive:false,
      errormessage_email:"",
      errormessage_pass:"",
      adminmail:""
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          min: minLength(6),
        },
      },
    };
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  created(event){
    axios.get(BASE_URL + '/api/fetch_admin_email').then(({ data }) => {
                this.adminmail = data.map(x => x.email);
            })
  },
  methods: {
    toggleShow() {
      //console.log('Test');
      this.isActive = !this.isActive;
      this.showPassword = !this.showPassword;
    },
    onSubmit() {
      //console.log("LOG:", this.v$.$error);
      //console.log('test');
      //console.log(this.form.password);
      if(!this.form.email){
          this.errormessage_email = 'Please Enter your mail id.';
          setTimeout(() => {
                this.errormessage_email = '';
            },3000);
          this.errormessage_pass = "";
      }else if(!this.form.password){
          this.errormessage_pass = 'Please Enter your password.';
          setTimeout(() => {
                this.errormessage_pass = '';
            },3000);
          this.errormessage_email = "";
      }else{
        this.errormessage_email = "";
        this.errormessage_pass = "";

            if (!this.v$.$error) {

                const getdata = {email: this.form.email, password:this.form.password};
                
                //console.log(getdata.email);login
                // console.log("test",data[1])
                // const date = new Date().toLocaleString();
                // console.log("test",new Date().toLocaleString())
                //console.log(this.users);
          
                if(this.adminmail.includes(getdata.email)){

                    axios.post(BASE_URL + '/api/login',getdata)
                            .then((response)=>{
                            console.log(response.data);
                            if(response.data.success == true){
                              this.errormessage = "";
                              this.storage.set("AUTHID", response.data.userId);
                              this.storage.set("USERNAME", response.data.username);
                              this.storage.set("USEREMAIL", response.data.useremail);
                              this.storage.set("USERPASSWORD", response.data.userpass);
                              this.storage.set("TOKEN", response.data.token);
                              this.storage.set("STATUS", response.data.status);
                              this.storage.set("ADMIN_STATUS", response.data.userstatus);
                              this.$router.push('/adminDashboard');
                            //   console.log(this.storageData);
                            //   console.log(storage.get('STATUS'));

                            }else{
                                this.remember = "";
                                this.errormessage = response.data.message;

                                setTimeout(() => {
                                    this.errormessage = "";
                                },3000);

                            }
                            
                        })

                }else{
                  //console.log("Employee:" + getdata.email);

                  axios.post(BASE_URL + '/api/emplogin',getdata)
                        .then((response)=>{
                        //console.log(response.data.success);
                            if(response.data.success == true){
                                //console.log("Data:",response.data.emp_status);
                                this.errormessage = "";
                                this.storage.set("ID", response.data.userId);
                                this.storage.set("USERNAME", response.data.username);
                                this.storage.set("USERLNAME", response.data.userlname);
                                this.storage.set("USEREMAIL", response.data.useremail);
                                this.storage.set("EMPID", response.data.empid);
                                this.storage.set("CASUAL_LEAVE", response.data.casual_leave);
                                this.storage.set("SICK_LEAVE", response.data.sick_leave);
                                this.storage.set("EMP_STATUS", response.data.emp_status);
                                this.storage.set("position", response.data.position);

                                if (response.data.marketing_status ==  null) {

                                this.storage.set("marketing_status", 'non_member');
                                this.$router.push('/emp-dashboard-new'); 

                                } else {

                                this.storage.set("marketing_status", response.data.marketing_status);
                                this.$router.push('/lms-emp-dashboard');     
                                }

                            }else{
                                this.errormessage = response.data.message;

                                setTimeout(() => {
                                    this.errormessage = "";
                                },3000);
                            }
                        })
                }


            }else{
                console.log("ELSE:");
            }
      }
    }
  }
}
</script>
<style scoped>
        *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
        }
        .lolgRightThree button{
            background: none;
            border: none;
            cursor: pointer;
        }
        .lolgRightThree button svg:last-child{
            display: none;
        }
        .lolgRightThree button.show svg:first-child{
            display: none;
        }
        .lolgRightThree button.show svg:last-child{
            display: block;
        }
        .loginBox  {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100vh;
            background: #fff;
        }
        .loginBox .loginLeftBox  {
            width: 550px;
            height: 100%;
        }
        .loginBox .loginText  {
            width: calc(100% - 550px);
        }
        .loginBox .loginLeftBox  {
            background-image: url(@/assets/background1.jpg);
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
        }
        .loginBox .loginLeftBox .loginLogo  {
            padding: 64px 0 83px 0;
            position: relative;
            margin: 0 auto;
        }
        .loginBox .loginLeftBox .loginLogo::after {
            content: "";
            width: 190px;
            height: 1px;
            top: 130px;
            left: 230px;
            background: #D9D9D9;
            position: absolute;
        }
        .loginBox .loginLeftBox .loginLogo::before {
            content: "";
            width: 190px;
            height: 1px;
            top: 138px;
            left: 145px;
            background: #D9D9D9;
            position: absolute;
        }
        .loginBox .loginLeftBox .loginLogo img  {
            display: block;
            margin: 0 auto;
            position: relative;
        }
        .loginBox .loginLeftBox .loginText  {
            text-align: center;
            padding-bottom: 0px;
            width: 100%;
        }
        .loginBox .loginLeftBox .loginText h2  {
            font-weight: 400;
            font-size: 27px;
            line-height: 52px;
            text-align: center;
            letter-spacing: 0.4em;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        .loginBox .loginLeftBox .loginText h1  {
            font-weight: 900;
            font-size: 66px;
            line-height: 70px;
            text-align: center;
            text-transform: uppercase;
            
            color: #FFFFFF;
        }
        .loginBox .loginRightBox  {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% - 550px);
        }
        .loginBox .loginRightBox .loginRightShadow  {
            background: #FFFFFF;
            box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.09);
            padding: 74px 72px 94px 72px;
            text-align: center;
            width: 100%;
            max-width: 550px;
            position: relative;
        }
        .loginBox .loginRightBox .loginRightShadow h2  {
            font-weight: 900;
            font-size: 48px;
            line-height: 44px;
            color: #050505;
            text-transform: uppercase;
            padding-bottom: 70px;
        }
        .loginBox .loginRightBox .loginRightShadow .loginRightInput  {
            position: relative;
        }
        .loginBox .loginRightBox .loginRightShadow .loginRightInput  {
            margin-bottom: 40px;
        }
        .loginBox .loginRightBox .loginRightShadow .loginRightInput .inputOne  {
            width: 100%;
            height: 70px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 10px;
            position: relative;
            display: block;
            padding-left: 58px;
            outline: none;
            font-size:15px
        }
        .loginBox .loginRightBox .loginRightShadow .loginRightInput .lolgRightOne  {
            position: absolute;
            top: 24px;
            left: 24px;
        }
        .loginBox .loginRightBox .loginRightShadow .loginRightInput .lolgRightTwo  {
            position: absolute;
            top: 24px;
            left: 24px;
        }
        .loginBox .loginRightBox .loginRightShadow .loginRightInput .lolgRightThree  {
            position: absolute;
            top: 24px;
            right: 24px;
        }
        .loginBox .loginRightBox .loginRightShadow .loginRightbtn .logButton  {
            height: 55px;
            left: 791px;
            top: 577px;
            background: linear-gradient(146.43deg, #E16742 -1.05%, #5576BA 93.06%), #5D59FF;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            line-height: 14px;
            color: #FFFFFF;
            border: none;
            outline: none;
            width: 100%;
            cursor: pointer;
        }
        .input-errors {
            position: absolute;
            top: -7px;
            left: 19px;
            background: #fff;
            padding: 0 12px;
            color: #fc6060;
        }
        /* .errormessage {
            font-size: 14px;
            color: #444;
            display: flex;
            justify-content: flex-start;
            position: absolute;
            bottom: -7px;
            left: 10px;
            background: #fff;
            padding: 0 12px;
        } */

.errormessage {
    position: absolute;
    top: 140px;
    right: 0;
    margin: 0 auto;
    left: 0;
    background: #fff;
    padding: 0 6px;
    color: #fc6060;
}

.input-errors .error-msg  {
    font-size: 13px;
    font-weight: 400;
}

@media (max-width:991px)  {
    .loginBox {
        flex-direction: column;
        height: auto;
    }
    .loginBox .loginLeftBox {
        height: auto;
        width: 100%;
    }
    .loginBox .loginRightBox  {
        width: 100%;
        padding-top: 50px;
    }
    .loginBox .loginLeftBox .loginText h1 {
        font-size: 36px;
        line-height: normal;
        padding-bottom: 100px;
    }
    .loginBox .loginLeftBox .loginText h2 {
        font-size: 22px;
        line-height: 40px;
    }
    .loginBox .loginLeftBox .loginLogo::before,
    .loginBox .loginLeftBox .loginLogo::after  {
        content: unset;
    }
    .loginBox .loginLeftBox .loginLogo {
        padding: 64px 0 50px 0;
    }
    .loginBox .loginRightBox .loginRightShadow {
        padding: 50px 30px 50px 30px;
    }
    .loginBox .loginRightBox .loginRightShadow h2 {
        font-size: 40px;
        line-height: 30px;
        padding-bottom: 46px;
    }
}
@media (max-width:767px)  {
    .loginBox .loginLeftBox .loginText h1 {
        font-size: 26px;
        padding-bottom: 130px;
    }
    .loginBox .loginLeftBox .loginText h2 {
        font-size: 19px;
        line-height: 40px;
    }
    .loginBox .loginRightBox {
        width: 100%;
        padding: 30px 10px;
    }
    .loginBox .loginRightBox .loginRightShadow h2 {
        font-size: 28px;
        padding-bottom: 30px;
    }
    .loginBox .loginRightBox .loginRightShadow {
        padding: 20px 15px 30px 15px;
    }
    .loginBox .loginRightBox .loginRightShadow .loginRightInput .inputOne {
        height: 52px;
        border-radius: 5px;
        padding-left: 50px;
    }
    .loginBox .loginRightBox .loginRightShadow .loginRightInput .lolgRightOne,
    .loginBox .loginRightBox .loginRightShadow .loginRightInput .lolgRightTwo {
        top: 15px;
        left: 17px;
    }
    .loginBox .loginRightBox .loginRightShadow .loginRightbtn .logButton  {
        height: 44px;
    }
}
</style>