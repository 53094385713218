<template>
  <div class="main-right">
      <div class="main-title d-flex align-items-center justify-content-between">
        <h2>Attendance Adjustment {{new Date().getFullYear()}} </h2>
        <label  class="main_data_clrs new_emp date_picker_div">
            <small style="white-space: nowrap;">Pick date</small>
          <!-- <datepicker v-model="picked" />      -->
          <datepicker :full-month-name="true" v-model="picked" placeholder="DOJ"/>   
        </label>
      </div>

      <div>        
        


        <div class="head-bar head_bar_two justify-content-start">
          <div class="top_select">
              <label>Application Type:</label>
              <select v-model="state3.type">
                <option value="Attendance">Attendance</option>
                <option value="Leave">Leave</option>
                <!-- <option value="WFH">Work From Home</option> -->
              </select>
          </div>

          <div class="top_select">
            <label>Name:</label>
              <select v-model="state3.name" @change="onSelectName($event)">
                <option v-if="state3.empid" v-for="namedata in ddname" :value="namedata.id">{{ namedata.empname }}</option>
                <option v-else v-for="namedata in searchempnamedata" :value="namedata.id">{{ namedata.empname }}</option>
              </select>
          </div>

          <div class="top_select">
            <label>Employee ID:</label>
              <select v-model="state3.empid" @change="onSelectId($event)">
                <option v-if="state3.name" v-for="iddata in ddempid" :value="iddata.id">{{ iddata.employeeid }}</option>
                <!-- <option v-if="state3.name != ''" v-for="iddata in employeeidlist" :value="iddata.id">{{ iddata.employeeid }}</option> -->
                <option v-else  v-for="iddata in employeeidlist" :value="iddata.id">{{ iddata.employeeid }}</option>
              </select>
          </div>

          <div class="btn-lps">
            <button v-if="state3.type == 'Attendance'" type="button" class="edtl mx-auto" id="modal-tggl1" @click="adjust(state3.name, state3.empid, state3.type)">Get Details</button>
          </div>

          <div class="btn-lps">
            <button type="button" class="edtl mx-auto" id="modal-tggl1" @click="clear()">Clear</button>
          </div>
        </div>

      </div>


      <div class="head-bar p-3 mt-4 flex-wrap" v-if="state3.type == 'Attendance'" >

        <div class="row gx-5 w-100 " >
          <div class="col-4 edit-input-box border-end">
              <small class="pb-2">Check in time</small>
              <input class="form-control" type="time" v-model="state2.start" placeholder="--:--"/>
          </div>

          <div class="col-4 edit-input-box border-end">
              <small class="pb-2">Check out time</small>
              <input class="form-control" type="time" v-model="state2.end" placeholder="--:--"/>
          </div>

          <div class="col-4 edit-input-box border-end">
              <small class="pb-2">Total Working hours</small>
              <input class="form-control" type="text" readonly v-model="state2.total" placeholder=""/>
          </div>

          <div v-if="isRemark" class="col-8 pt-4 edit-input-box">
              <small class="pb-2">Remark</small>
              <textarea class="form-control" rows="3" v-model="state2.remarks" placeholder="Reason"></textarea>
          </div>

        </div>

        <div class="d-flex pt-3 align-items-center flex-nowrap">
          <button class="btn-add-edit ms-auto"  @click="updateAttendance(state2.start, state2.end, state2.total, state2.remarks)">
            Add Manual Time
          </button>
          <button class="btn-add-edit-blue ms-1" @click="this.isRemark = !this.isRemark">
            Remark
          </button>
          <button class="btn-add-edit-blue ms-1" @click="removeAttendance()">
            Remove Data
          </button>
        </div>

      </div>

      <div class="head-bar p-3 mt-4 flex-wrap" v-if="state3.type == 'Leave'">

        <div class="row gx-5 w-100 ">
          <div class="col-4 edit-input-box border-end">
            <small class="pb-2">Leave type</small>
            <select class="form-select" v-model="state1.leave">
              <option>Casual Leave</option>
              <option>Medical Leave</option>
              <option>Provisional</option>
            </select>
          </div>

          <div class="col-4 edit-input-box">
            <small class="pb-2">Upload File</small>
            <input class="form-control" type="file"   @change="updatePhoto" placeholder="--:--"/>
          </div>

          <div class="col-8 pt-4 edit-input-box">
              <small class="pb-2">Remark</small>
              <textarea class="form-control" rows="3" v-model="state1.remark" placeholder="Reason"></textarea>
          </div>
        </div>

        <div class="d-flex pt-3 align-items-center flex-nowrap">
          <button class="btn-add-edit ms-auto" @click="updateLeave()">
            Add Leave
          </button>
          
        </div>

      </div>

     
      
      

  </div>
</template>

<script>
import { createLocal } from 'the-storages';
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';
// import Datepicker from 'vue3-datepicker';

import Datepicker from 'vuejs3-datepicker';

import { ref } from 'vue';
const date = ref(new Date());

const mirror = createLocal();
const storage = mirror._prx;

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { reactive, computed } from "vue"
import Swal from 'sweetalert2'




export default {
name: 'AttendanceAdjustment',
components:{
  Pagination,
  Datepicker
},
setup() {
  const state3 = reactive({
          name: '',
          empid:'',
          type:''
      })

  const state2 = reactive({
        start:'',
        end:'',
        total:'',
        remarks:''
  }) 
  
  const state1 = reactive({
        leave:'',
        file:'',
        remarks:''
  })  
      

},
data() {
  return {
      page: 1,
      storageData: mirror,
      storage: storage,
      is_show: false,
      messagedata:  {},
      searchText:"",
      nofound:false,
      errormessage: "",
      totalData:'',
      searchData:'',
      dateSearch:'',
      flag:'',
      errormessage1:"",
      totalleavetaken: "",
      searchQuery: null,
      dropdownVal: null,
      picked: new Date(),
      isRemark: false,
      searchempnamedata:[],
      employeeidlist:'',
      state3: {},
      dateFormatted:'',
      attendanceData:'',
      stime:'',
      state1:{},
      state2:{},
      fileSelected:'',
      ddempid:'',
      ddname:''

  }
},
async created(){
 this.userName = mirror.USERNAME
 this.userEmail = mirror.USEREMAIL;

 this.ID = mirror.AUTHID;
//  console.log("sgvsvfs",this.picked);
//       var date = new Date(this.picked);
//       console.log("date",date);

 await axios.get(BASE_URL + '/api/getempname')
                .then((response)=>{
  this.searchempnamedata = response.data.empnamelist;
  this.employeeidlist = response.data.employeeidlist;
  console.log("response",response.data);          
  })

},

methods :{

    clear(){
      this.state3.name = '';
      this.state3.empid = '';
      this.state3.type = '';
      this.state2.start = '';
      this.state2.end = '';
      this.state2.total = '';
      this.state2.remarks = '';
      this.state1.leave = '';
      this.state1.file = '';
      this.state1.remarks = '';
      this.picked= new Date();

    },
    HandelDropdown(val){
      console.log(val)
      if(this.dropdownVal === val){
        this.dropdownVal = null
      } else{
        this.dropdownVal = val
      }
        
    },

    async dropdownval(){
      console.log("susvfdt",this.state3.name);
    },

    async adjust(name, id, type){

      console.log("vsftdyfsgf",this.dateFormatted);
      if (type=='Attendance') {
        const getDateData = {'name':name,'id':id, 'type':type, 'date':this.dateFormatted};
          await axios.post(BASE_URL + '/api/getAttendancedatatobeadjusted', getDateData)
          .then((response)=>{

            if (response.data.success == false) {
              Swal.fire({
                        title: 'OPPS',
                        text:   response.data.message,
                        icon: 'warning',
                    
                    });
            } else {
              this.attendanceData = response.data.attendance_details;
              if (this.attendanceData == null) {
                this.state2.start = '';
                this.state2.end = '';
                this.state2.start = '';
              }else{
              var start_time = this.attendanceData.start_time;
              if (start_time==null) {
                this.state2.start = '';
                
              } else {
                var start_time_withoutampm = start_time.split(" ");
                var stime = start_time_withoutampm[0];
                var start_split = stime.split(":");
                var ampm = start_time_withoutampm[1];
                console.log("ampm",ampm);
                if (ampm=='AM') {

                start_split[0] = start_split[0].toString().padStart(2, '0');
                console.log("abcd",start_split[0]);
                var start_hr_min = start_split[0].concat(":",start_split[1])
                  
                } else {
                var hr =  parseInt(start_split[0] ) + 12;
                var start_hr_min = (hr.toString()).concat(":",start_split[1])
                }
                console.log("start_hr_min",start_hr_min);
                this.state2.start = start_hr_min; 
              }

  //
              var end_time = this.attendanceData.end_time;
              console.log("end",end_time);
              if (end_time==null) {
                this.state2.end = '';
              } else {
                var end_time_withoutampm = end_time.split(" ");
                var stime = end_time_withoutampm[0];
                var end_split = stime.split(":");
                var ampm = end_time_withoutampm[1];
                if (ampm=='AM') {

                end_split[0] = end_split[0].toString().padStart(2, '0');
                var end_hr_min = end_split[0].concat(":",end_split[1])
                  
                }
                else if(ampm=='PM' && end_split[0] == 12){
                  var hr =  parseInt(end_split[0] );
                  var end_hr_min = (hr.toString()).concat(":",end_split[1])
                } else {
                  var hr =  parseInt(end_split[0] ) + 12;
                  var end_hr_min = (hr.toString()).concat(":",end_split[1])
                }
                console.log("end_hr_min",end_hr_min);

                this.state2.end = end_hr_min; 
                console.log("this.state2.end",this.state2.end);

              }

                this.state2.total = this.attendanceData.hourly_works;
              }
            }

            // console.log("start_hr_min",start_hr_min);
            // console.log(this.stime);
        })  
      }
                

    },


     onSearch(){
         //this.getResults(this.searchText);
     },
      // getResults() {
          
      //     const data ={
      //         userId: this.ID,
      //         flag: this.flag,
      //         searchText:this.searchText,

      //     }
      //     //console.log(data);
          
      //     axios.post(BASE_URL + `/api/getallLeaveReport`,data)
      //         .then((response)=>{

      //           console.log(response.data);

      //         this.messagedata = response.data;

      //     })
      // },

      // async gettotalleavetaken(){
      //     await axios.post(BASE_URL + '/api/gettotalleavetaken')
      //               .then((response)=>{
      //                   //console.log(response.data.pending_leave.count);
      //                   if(response.data.success == true){
      //                       this.totalleavetaken = response.data.taken_leave.count;

      //                   }

      //           })
      // },
      // async clickedDownload(){
      //     var pdf = new jsPDF("1", "mm", "a1");
      //     var element = document.getElementById('divToPrintLeaveReportList');
      //     var width= element.style.width;
      //     var height = element.style.height;
      //         html2canvas(element).then(canvas => {
      //             //console.log(canvas);
      //             var image = canvas.toDataURL('image/png');
      //             pdf.addImage(image, 'JPEG', 15, 40, width, height);
      //             pdf.save('LeaveReportList' + '.pdf')
      //     });
      // },

     async updateAttendance(start, end, total, remarks){
        console.log("start",start);
        console.log("end",end);
        var start_split = start.split(":");
        var start_min = parseInt(start_split[0]*60) + parseInt(start_split[1]);
        var end_split = end.split(":");
        var end_min = parseInt(end_split[0]*60) + parseInt(end_split[1]);
        console.log("start_min",start_min);
        console.log("end_min",end_min);
        var work_min = end_min - start_min;
        console.log("work_min",work_min);
        var work_hr = Math.floor(work_min / 60);
        var remaining_min = work_min % 60;
        var hourly_works = (work_hr.toString().padStart(2, '0')).concat(":", remaining_min.toString().padStart(2, '0')).concat(":", '00');
        console.log("hourly_works",work_min);

        if (work_min<0) {
          var positive_work = false;
        } else {
          var positive_work = true;
          
        }

        if (start_split[0]<12) {
          var start_ampm = 'AM';
          var start_hours = start_split[0];
        } else {
          var start_ampm = 'PM';     
          var start_hours = start_split[0] - 12;    
        }

        if (end_split[0]<12) {
          var end_ampm = 'AM';
          var end_hours = end_split[0];

        } else {
          var end_ampm = 'PM';    
          var end_hours = end_split[0] - 12;    

        }

        var start_time = (start_hours.toString()).concat(":",start_split[1].toString().padStart(2, '0')).concat(":",'00').concat(" ",start_ampm);
        var end_time = (end_hours.toString()).concat(":",end_split[1].toString().padStart(2, '0')).concat(":",'00').concat(" ",end_ampm);
        // console.log("start_hrs",start_time); 
        // console.log("end_hrs",end_time);

        const attendancedatatobeupdated ={ 'start': start_time, 'end': end_time, 'total': hourly_works, 'id':this.state3.empid, 'date':this.dateFormatted, 'remarks': remarks,
        'start_abs': start, 'end_abs':end, 'positive_or_neg': positive_work}
        await axios.post(BASE_URL + '/api/updateattendancedata', attendancedatatobeupdated)
          .then((response)=>{
            if(response.data.success == true){

              Swal.fire({
                          title: 'Done',
                          text:  response.data.message,
                          icon: 'success',
                      
                      });

              this.state2.start = '';
              this.state2.end = '';
              this.state2.total = '';

              }else{

              Swal.fire({
                      title: 'OPPS',
                      text:   response.data.message,
                      icon: 'warning',
                  
                  });
              }
          })


      },

      updatePhoto (event) {
        this.fileSelected = event.target.files[0];
        console.log("fileSelected",this.fileSelected);
    },

    async updateLeave(){
      let formData = new FormData();

      formData.append('leave', this.state1.leave);
      formData.append('remark', this.state1.remark);
      formData.append('file', this.fileSelected);
      formData.append('id', this.state3.empid);
      formData.append('name', this.state3.name);
      formData.append('date', this.dateFormatted);
      console.log("data",formData);

      await axios.post(BASE_URL + '/api/updateadjustedleave',formData)
                        .then((response)=>{
                            //console.log(response.data);

                            if(response.data.success == true){

                                Swal.fire({
                                            title: 'Done',
                                            text:  response.data.message,
                                            icon: 'success',
                                        
                                        });

                            }else{

                                Swal.fire({
                                        title: 'OPPS',
                                        text:   response.data.message,
                                        icon: 'warning',
                                    
                                    });
                            }

                    })
    },

    async onSelectName(event){
      await axios.post(BASE_URL + '/api/namedropdownval',{'id':this.state3.name})
      .then((response)=>{
        // console.log("nameres",this.state3.name);
        this.ddempid = response.data.empidwrtnameselected;
      })
    },

    async onSelectId(event){
      await axios.post(BASE_URL + '/api/iddropdownval',{'id':this.state3.empid})
      .then((response)=>{
        // console.log("nameres",this.state3.name);
        this.ddname = response.data.empidwrtnameselected;
      })
    },

    async removeAttendance(){

      let formData = new FormData();

      formData.append('id', this.state3.empid);
      formData.append('name', this.state3.name);
      formData.append('date', this.dateFormatted);

      await axios.post(BASE_URL + '/api/removeAttendanceData',formData)
        .then((response)=>{

            this.state2.start = '',
            this.state2.end = '',
            this.state2.total = '',
            Swal.fire({
                        title: 'Done',
                        text:  response.data.message,
                        icon: 'success',
                    
                    });

        })                    
    }


},

watch:{
  async picked(){
    var date = new Date(this.picked);
          console.log("date",this.picked);
          var year = date.getFullYear();
          var month = String(date.getMonth() + 1).padStart(2, '0');
          var day = String(date.getDate()).padStart(2, '0')
          console.log("dateselected",this.picked.toLocaleString());
          this.dateFormatted = day.concat("-",month).concat("-",year);
          // console.log("dateFormatted",this.dateFormatted);
    },

},

computed: {
       resultQuery(){
       if(this.searchQuery){
       return this.empnamedata.filter((row)=>{
       
       return this.searchQuery.toLowerCase().split(' ').every( w => row.empname.concat(row.employeeid).toLowerCase().includes(w));
       //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
       })
       }else{
       return this.empnamedata;
       }
       },
},
mounted(){
  // this.getResults();
  // this.gettotalleavetaken();
},

}
</script>

<style >
.edit-input-box small{
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  padding-right: 8px;
  display: block;
}
.edit-input-box.border-end{
  border-right: solid 1px #dde1e7;
}
.edit-input-box.border-end:last-child{
  border-right: 0;
}
.btn-add-edit{
  padding: 10px 15px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #54a0ff;
  transition: all .3s ease-in-out;
  font-size: 12px;
  font-weight: 600;
}
.btn-add-edit-blue{
  padding: 10px 15px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #ff785f;
  transition: all .3s ease-in-out;
  font-size: 12px;
  font-weight: 600;
}
.main-right .date-picker .main_data_clrs .v3dp__input_wrapper input{
  border: solid 1px #e7e7e7 !important;
}
.fw-semibold{
  font-weight: 600;
}
.brop-list input.search-input{
  width: 100%;
  border-radius: 3px;
  line-height: 30px;
  padding: 5px 10px;
  font-size: 13px;
  border: solid 1px #dbe0e6;
}
  .name-list{
    height: 250px;
    margin-top: 10px;
    padding: 0 5px;
  }
  .list-scroll{
    overflow-y: scroll;
  }
  .list-scroll::-webkit-scrollbar-track{
    background-color: #ffffff;
  }

  .list-scroll::-webkit-scrollbar{
    width: 4px;
    background-color: #ffffff;
  }

  .list-scroll::-webkit-scrollbar-thumb{
    background-color: #464d515e;
  }
  .name-list-items{
    display: flex;
    padding: 10px;
    border-radius: 5px;
    background-color: transparent;
    transition: 0.5s;
  }
  .name-list-items:hover{
    background-color: #54a1ff19;
  }
  .name-list-items p{
    font-size: 14px;
    margin: 0;
    color: #3a4148;
    padding-left: 10px;
    text-transform: capitalize;
  }
  .name-list-items span{
    width: 20px;
    height: 20px;
    text-transform: uppercase;
    background-color: #54a0ff;
    border-radius: 2px;
    display: inline-flex;
    font-size: 10px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    color: #fff;
  }
  .name-list-items:nth-child(5n+2) span{
    background-color: #abbb38;
  }
  .name-list-items:nth-child(5n+3) span{
    background-color: #d2a439;
  }
  .name-list-items:nth-child(5n+4) span{
    background-color: #3dc3b5;
  }
  .name-list-items:nth-child(5n+5) span{
    background-color: #cf489e;
  }


  .brop-btn{
    position: relative;
    background-color: #54a0ff;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 7px 15px;
    margin-right: 10px;
  }
  .brop-btn svg{
    margin-left: 8px;
  }

  .brop-list{
    position: absolute;
    top: 100%;
    width: 250px;
    left: 0;
    margin-top: 5px;
    padding: 5px 5px 15px;
    border-radius: 5px;
    border: solid 1px #e2e6eb;
    background-color: #fff;
    z-index: 12;
  }

</style>