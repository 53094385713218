<template>
    <div class="dashboard_box deal_dashboard deal_dashboard_new">
        <div class="dashboard_right">
            <div class="dashboard_body">
                <div class="main_box">
                    <div class="top">
                        <div class="top_box">
                            <div class="name">
                                <h2>Deal Report For {{month_proper_name}}</h2>
                            </div>
                            <div class="btn_deal">
                                <div class="month_picker_boxes">
                                    <label>Select Month :</label>
                                    <month-picker-input placeholder="Select a month" @change="showDate" :no-default="true"></month-picker-input>
                                </div>
                                <!-- <button class="stl-btn modal_caldn_btn" id="modal-tggl1" @click="isOpen()">
                                    <img src="@/assets/images/icon/calendar-plus.png" alt=""> Select a month 
                                </button> -->
                            </div>
                        </div>
                    </div>

                    <!-- <div class="popup-modal" id="popupModa1" v-if="seen">
                        <div class="mp-content">
                            <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
                                <img src="@/assets/images/icon/x-lg.svg" alt="">
                            </button>
                            <div class="bdy-sect">
                                <h3>Select a month</h3>
                                <month-picker @change="showDate"  no-default></month-picker>
                            </div>            
                        </div>
                    </div> -->

                    <div class="table-section">
                        <!-- <div class="heading">
                            <h2>Report</h2>
                        </div> -->
                        <div class="table-wapper">
                            <div class="table-data table-w-100">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Client Name</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Deal Status</th>
                                            <th>Payment Status</th>
                                            <th>Payment Method</th>
                                            <th>Beneficiary Details</th>

                                            <th>Member Name</th>
                                            <th>Team Leader</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dealReportdata.length != 0">
                                        <tr v-for="(row, index) in dealReportdata" :key="index">
                                            <td>
                                                {{ index + 1 }}
                                            </td>
                                            <td>
                                                <span>{{ row.client }}</span>
                                                <!-- <input v-else v-model="row.client" type="text" /> -->
                                            </td>
                                            <td>
                                                <input v-if="row.id == rowtobeedited_id && showeditedrow == true" v-model="row.amount" type="number" min="0" @input="validateAmount" />
                                                <span v-else>{{ row.amount }}</span>
                                            </td>
                                            <td>
                                                {{ row.date }}
                                            </td>
                                            <td>
                                                
                                                <select v-if="row.id == rowtobeedited_id && showeditedrow == true" v-model="row.payment_status">
                                                    <option value="Pending">Pending</option>
                                                    <option value="Closed">Closed</option>
                                                    <option value="Failed">Failed</option>
                                                </select>
                                                <span v-else>{{ row.payment_status }}</span>
                                            </td>
                                            <td>
                                                <select v-if="row.id == rowtobeedited_id && showeditedrow == true" v-model="row.payment_completion_status">
                                                    <option value="Pending">Pending</option>
                                                    <option value="Paid">Paid</option>
                                                </select>
                                                <span v-else>{{ row.payment_completion_status }}</span>
                                            </td>
                                            <!-- <td v-else>
                                                NA
                                            </td> -->
                                            <td>
                                                <select v-if="row.id == rowtobeedited_id && showeditedrow == true"  v-model="row.payment_method">
                                                    <!-- <select class="addNewInput" v-model="transaction.payment_method" placeholder="Select"> -->
                                                        <option :value="blood" disabled>Select One</option>
                                                        <option value="Banking">Bank Transfer</option>
                                                        <option value="Crypto">Crypto</option>
                                                        <option value="Paypal">Paypal</option>

                                                </select>
                                                <!-- </div> -->
                                                <div v-else>
                                                    {{ row.payment_method }}
                                                </div>  
                                            </td>
                                            <td>
                                                <select v-if="row.id == rowtobeedited_id && showeditedrow == true"  v-model="row.beneficiary">
                                                    <!-- <select class="addNewInput" v-model="transaction.payment_method" placeholder="Select"> -->
                                                        <option :value="blood" disabled>Select One</option>
                                                        <option value="Reddensoft">Reddensoft</option>
                                                        <option value="Lynkme">Lynkme 360</option>
                                                        <option value="Bigwave">Bigwave</option>

                                                </select>
                                                <!-- </div> -->
                                                <div v-else>
                                                    {{ row.beneficiary }}
                                                </div>  
                                            </td>
                                            <!-- <td v-else>
                                                NA
                                            </td> -->
                                            <td>
                                                <span>{{ row.empname }}</span>
                                                <!-- <input v-else v-model="row.empname" type="text" /> -->
                                            </td>
                                            <td>
                                                <span>{{ row.teamLeaderName }}</span>
                                                <!-- <input v-else v-model="row.teamLeaderName" type="text" /> -->
                                            </td>
                                            <td>
                                                <div class="btn-lps d-flex">
                                                    <!-- <button class="btn-lps" @click="updateDetails(row,row.id)">
                                                        {{ row.isEditing ? 'Update' : 'Edit' }}
                                                    </button> -->
                                                    <button v-if="row.id == rowtobeedited_id && showeditedrow == true" @click="updateDetails(row.id,row.amount,row.payment_status, row.payment_completion_status, row.empid, row.payment_method, row.beneficiary)" class="edtl">Update</button>
                                                    <button v-else @click="editDetails(row.id)" class="edtl">Edit</button>
                                                    <button class="edtl" @click="openiopopupBtn(row.id, row.payment_status, row.beneficiary)">
                                                        IO
                                                    </button>
                                                    <button class="edtl" @click="openiopopupBtn1(row.id, row.payment_status, row.beneficiary)">
                                                        Invoice
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="nodeals-style" v-else>
                                        No deals to show for this month
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="deal_modal_div">
                        <div class="modal_background"  v-if="openiopopup ==true && deal_status == 'Closed'">
                            <div class="modal_content">
                                <div class="modal_box">
                                    <div class="modal_heading">
                                        <h2>Insertion Order</h2>
                                        <button @click="closeiopopup()">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                        </button>
                                    </div>
                                    <div class="modal_scroll">
                                        <div class="modal_name">
                                                            <h3>Advertiser Details</h3>
                                        </div>
                                        <div class="d-flex">
                                            <div class="col modal_input">
                                                <label>Company Name :</label>
                                                <!-- <input type="text" placeholder="Enter company name" v-model="io.company_name"/> -->
                                                <p class="modal_labelp">
                                                    {{ io.company_name }}
                                                </p>
                                            </div>
                                            <div class="col modal_input">
                                                <label>Address :</label>
                                                <!-- <input type="text" placeholder="Enter company address" v-model="io.company_address"/> -->
                                                <p class="modal_labelp">
                                                    {{ io.company_address }}
                                                </p>
                                                
                                            </div>
                                        </div>
                                        <div class="modal_name">
                                            <h3>Contact person</h3>
                                        </div>            
                                        <div class="d-flex flex-wrap align-items-end">
                                            <div class="col-4 modal_input">
                                                <label>Name :</label>
                                                <!-- <input type="text" placeholder="Enter name" v-model="io.contact_person_name"/> -->
                                                <p class="modal_labelp">
                                                    {{ io.contact_person_name }}
                                                </p>
                                                
                                            </div>
                                            <div class="col-4 modal_input">
                                                <label>Registration number :</label>
                                                <!-- <input type="text" placeholder="Enter registration number" v-model="io.contact_person_reg_no"/> -->
                                                <p class="modal_labelp">
                                                    {{ io.contact_person_reg_no }}
                                                </p>
                                                
                                            </div>
                                            <div class="col-4 modal_input">
                                                <label>Email :</label>
                                                <!-- <input type="text" placeholder="Enter company email" v-model="io.contact_person_email"/> -->
                                                <p class="modal_labelp">
                                                    {{ io.contact_person_email }}
                                                </p>
                                                
                                            </div>
                                        </div>
                                        <div class="modal_name">
                                            <h3>Campaign Details</h3>
                                        </div>
                                        <div class="modal_campaign">
                                            <div class="row">
                                                <div class="col details_label">
                                                    <label>Site / Application</label>
                                                    <!-- <input type="text" placeholder="Enter site" v-model="io.web_app_name"/> -->
                                                    <p class="modal_labelp">
                                                        {{ io.web_app_name }}
                                                    </p>
                                                    
                                                </div>
                                                <div class="col details_label">
                                                    <label>Start Date</label>
                                                    <!-- <input type="date" placeholder="Enter start date" v-model="io.start_date"/> -->
                                                    <p class="modal_labelp">
                                                        {{ io.start_date }}
                                                    </p>
                                                    
                                                </div>
                                                <div class="col details_label">
                                                    <label>End Date</label>
                                                    <!-- <input type="date" placeholder="Enter end date" v-model="io.end_date"/> -->
                                                    <p class="modal_labelp">
                                                        {{ io.end_date }}
                                                    </p>
                                                    
                                                </div>
                                                <div class="col details_label">
                                                    <label>Placement</label>
                                                    <!-- <input type="text" placeholder="Enter placement" v-model="io.placemnet"/> -->
                                                    <p class="modal_labelp">
                                                        {{ io.placemnet }}
                                                    </p>
                                                    
                                                </div>
                                                <div class="col details_label">
                                                    <label>Total Budget</label>
                                                    <!-- <input type="number" placeholder="Enter total budget" v-model="io.total_budget"/> -->
                                                    <p class="modal_labelp">
                                                        {{ io.total_budget }}
                                                    </p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal_name">
                                            <h3>Campaign Type</h3>
                                        </div>
                                        <div class="campaing_select" v-if="campaign_categories == null">
                                        <div class="col-6">
                                            <div class="input_box">
                                                <input type="text" placeholder="Enter category" v-model="newCategory.name"/>
                                                <button type="submit" @click="addName">Add</button>
                                            </div>
                                        </div>
                                        <div v-for="(category, index) in categories" :key="index" class="soloemail_details">
                                        <div class="modal_name">
                                            <h3>{{ category.name }}</h3>
                                        </div>
                                        <div class="modal_campaign">
                                            <div class="row">
                                            <div class="col details_label">
                                                <label>Model</label>
                                                <input type="text" placeholder="Enter model" v-model="category.model"/>
                                            </div>
                                            <div class="col details_label">
                                                <label>Rate</label>
                                                <input type="text" placeholder="Enter rate"  v-model="category.rate"/>
                                            </div>
                                            <div class="col details_label">
                                                <label>Quantity</label>
                                                <input type="text" placeholder="Enter quantity" v-model="category.quantity"/>
                                            </div>
                                            <div class="col details_label">
                                                <label>Total</label>
                                                <input type="text" placeholder="Enter total" v-model="category.total"/>
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                        </div>

                                        <div class="campaing_select" v-else>
                                        <!-- <div class="campaing_select" v-if="campaign_categories != null"> -->
                                        <div class="email_template_tables">
                                            <div class="table-section table_height">
                                                <div class="table-wapper">
                                                    <div class="table-data table-w-100">
                                                        <div class="table_scroll">
                                                            <table class="payroll-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Name
                                                                        </th>
                                                                        <th>
                                                                            Quantity
                                                                        </th>
                                                                        <th>
                                                                            PRICE
                                                                        </th>
                                                                        <th>
                                                                            TOTAL
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
                                                                    <tr v-for="(row, index) in campaign_categories" :key="index">
                                                                        <td>
                                                                            {{row.name}}
                                                                        </td>
                                                                        <td>
                                                                            {{row.quantity}}
                                                                        </td>
                                                                        <td>
                                                                            {{row.rate}}
                                                                        </td>
                                                                        <td>
                                                                            {{row.total}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </div>            
                                            <div class="table_total">
                                                <ul>
                                                    <li>TOTAL</li>
                                                    <li><span class="green">${{totalAmount}}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="modal_button">
                                        <!-- <button @click="generateIO(io, row.id)">
                                            Generate
                                        </button> -->
                                        <button @click="exportToPDF(row.id)">
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal_background"  v-if="openiopopup1 ==true && deal_status == 'Closed'">
                            <div class="modal_content">
                                <div class="modal_box">
                                    <div class="modal_heading">
                                        <h2>Invoice</h2>
                                        <button @click="closeiopopup1()">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                                        </button>
                                    </div>
                                    <div class="modal_scroll">
                                        <div class="modal_name">
                                            <h3>Advertiser Details</h3>
                                        </div>
                                        <div class="d-flex">
                                            <div class="col modal_input">
                                                <label>Company Name :</label>
                                                <!-- <input type="text" placeholder="Enter company name" v-model="invoice.cName"/> -->
                                                <p class="modal_labelp">
                                                    {{ invoice.cName }}
                                                </p>
                                                
                                            </div>
                                            <div class="col modal_input">

                                                
                                                <label>Address :</label>
                                                <!-- <input type="text" placeholder="Enter company address" v-model="invoice.cAddress"/> -->
                                                <p class="modal_labelp">
                                                    {{ invoice.cAddress }}
                                                </p>
                                                
                                            </div>
                                        </div>
                                        <div class="modal_name">
                                            <h3>Contact person </h3>
                                        </div>            
                                        <div class="d-flex flex-wrap">
                                            <div class="col-4 modal_input">
                                                <label>Name :</label>
                                                <!-- <input type="text" placeholder="Enter name" v-model="invoice.contactName"/> -->
                                                <p class="modal_labelp">
                                                    {{ invoice.contactName }}
                                                </p>
                                                
                                            </div>
                                            <div class="col-4 modal_input">
                                                <label>Registration number :</label>
                                                <!-- <input type="text" placeholder="Enter registration number" v-model="invoice.regNo"/> -->
                                                <p class="modal_labelp">
                                                    {{ invoice.regNo }}
                                                </p>
                                                
                                            </div>
                                            <div class="col-4 modal_input">
                                                <label>Email :</label>
                                                <!-- <input type="text" placeholder="Enter company email" v-model="invoice.contactEmail"/> -->
                                                <p class="modal_labelp">
                                                    {{ invoice.contactEmail }}
                                                </p>
                                                
                                            </div>
                                        </div>
                                        <!-- <div class="modal_name">
                                            <h3>Campaign Details</h3>
                                        </div>
                                        <div class="modal_campaign">
                                            <div class="row">
                                                <div class="col details_label">
                                                    <label>Site / Application</label>
                                                    <input type="text" placeholder="Enter site"/>
                                                </div>
                                                <div class="col details_label">
                                                    <label>Start Date</label>
                                                    <input type="date" placeholder="Enter start date"/>
                                                </div>
                                                <div class="col details_label">
                                                    <label>End Date</label>
                                                    <input type="date" placeholder="Enter end date"/>
                                                </div>
                                                <div class="col details_label">
                                                    <label>Placement</label>
                                                    <input type="text" placeholder="Enter placement"/>
                                                </div>
                                                <div class="col details_label">
                                                    <label>Total Budget</label>
                                                    <input type="number" placeholder="Enter total budget"/>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="modal_name">
                                            <h3>Campaign Type</h3>
                                        </div>
                                        <div class="campaing_select">
                                            <div class="email_template_tables">
                                                <div class="table-section table_height">
                                                    <div class="table-wapper">
                                                        <div class="table-data table-w-100">
                                                            <div class="table_scroll">
                                                                <table class="payroll-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Name
                                                                            </th>
                                                                            <th>
                                                                                Quantity
                                                                            </th>
                                                                            <th>
                                                                                PRICE
                                                                            </th>
                                                                            <th>
                                                                                TOTAL
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody >
                                                                        <tr v-for="(row, index) in campaign_data_invoice" :key="index">
                                                                            <td>
                                                                                {{row.name}}
                                                                            </td>
                                                                            <td>
                                                                                {{row.quantity}}
                                                                            </td>
                                                                            <td>
                                                                                {{row.rate}}
                                                                            </td>
                                                                            <td>
                                                                                {{row.total}}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                
                                                <div class="table_total">
                                                    <ul>
                                                        <li>TOTAL</li>
                                                        <li><span class="green">${{totalInvoiceAmount}}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal_button">
                                        <button  @click="exportToPDFInvoice(row.id)">
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import "../../assets/css/dashboard.css"
// import "../assets/css/dashboard.css"
import EmployeHeader from "@/components/EmployeDashboard/EmployeHeader.vue"
import { ref } from 'vue'
// import { MonthPicker } from 'vue-month-picker';
import { BASE_URL } from "../../config";
import axios from "axios";
import { reactive } from "vue";
import html2pdf from "html2pdf.js";
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import { MonthPickerInput } from 'vue-month-picker'

export default {
    name: 'DealReport',
    components: {
        EmployeHeader,
        MonthPickerInput
    },
    // setup() {
    //       const state3 = reactive({
    //           team_target:'',
    //           self_target:'',
    //           selectedEmployee: '',
    //           teamLeader:'',
    //           monthlytarget:'',
    //           monthly_target_achieved:'',
    //           monthly_team_target_achieved:'',
              
    //       })
    //       const io = reactive({
    //           cName:'',
    //           cAddress:'',
    //           contactName: '',
    //           contactEmail:'',
    //           regNo:'',
    //           siteName:'',
    //           startDate:'',
    //           endDate:'',
    //           placement:'',
    //           budget:'',
    //           solo_name:'',
    //           solo_rate:'',
    //           solo_quantity:'',
    //           solo_total:'',
    //           email_template_name:'',
    //           email_template_rate:'',
    //           email_template_quantity:'',
    //           email_template_total:'',
    //       })

    //       const isOpen = ref(false)
    //       const isOpenNew = ref(false)
          
    //       return {
    //           state3,
    //           isOpen,
    //           isOpenNew,
    //           io
    //       }
    //   },
    data() {
        return {
            seen : false,
            openiopopup:false,
            openiopopup1:false,
            dealReportdata:[],
            monthlytarget:'',
            month_name:'',
            picked_year:'',
            monthlyTargets: [],
            monthAbbreviations: {
                '1': 'Jan',
                '2': 'Feb',
                '3': 'Mar',
                '4': 'Apr',
                '5': 'May',
                '6': 'Jun',
                '7': 'Jul',
                '8': 'Aug',
                '9': 'Sep',
                '10': 'Oct',
                '11': 'Nov',
                '12': 'Dec',
            },

            monthlyTeamTargets: [],
            monthlyTargetsAchieved: [],
            monthlyTeamTargetsAchieved: [],
            month_proper_name:'',
            pastDataArray:[],
            pastTeamDataArray:[],
            monthsMetTarget:'',
            monthsMetTeamTarget:'',
            rowtobeedited_id:'',
            showeditedrow: false,
            pdfdata:'',
            campaign_data:[],
            campaign_data_invoice:[],
            deal_status: '',
            campaign_categories:[],
            newCategory: {
                name: '',
                model: '',
                rate: '',
                quantity: '',
                total: ''
            },
            categories: [],
            invoiceid:'',
            io_status:'',
            invoice_status:''

        }
    },
    setup() {
        const row = reactive({
            amount:'',
            payment_status:''
              
          })
        const isOpen = ref(false)

        const selectedData = ref([]);

        function handleItemClick(title) {
            if (!selectedData.value.includes(title)) {
                selectedData.value.push(title);
            } else {
                selectedData.value = selectedData.value.filter(item => item !== title);
            }
            console.log("chgrdtr",selectedData);

        }

        const menuItems = [
            { title: 'Solo Email' },
            { title: 'Email Template' }
        ];

        // const io = reactive({
        //       id:'',
        //       cName:'',
        //       cAddress:'',
        //       contactName: '',
        //       contactEmail:'',
        //       regNo:'',
        //       siteName:'',
        //       startDate:'',
        //       endDate:'',
        //       placement:'',
        //       budget:'',
        //       solo_name:'',
        //       solo_rate:'',
        //       solo_quantity:'',
        //       solo_total:'',
        //       email_template_name:'',
        //       email_template_rate:'',
        //       email_template_quantity:'',
        //       email_template_total:'',
        //   })
        const io = reactive({
            company_name: '',
            company_address:'',
            contact_person_name: '',
            contact_person_reg_no: '',
            contact_person_email: '',
            web_app_name: '',
            start_date:'',
            end_date:'',
            placemnet:'',
            total_budget:'',
            solo_email_model:'',
            solo_email_rate:'',
            solo_email_quantity:'',
            solo_email_total:'',
            email_template_model:'',
            email_template_rate:'',
            email_template_quantity:'',
            email_template_total:'',
        })

          const invoice = reactive({
              id:'',
              cName:'',
              cAddress:'',
              contactName: '',
              contactEmail:'',
              regNo:'',
          })
        return {
            selectedData,
            handleItemClick,
            menuItems,
            isOpen,
            row,
            io,
            invoice

        }
    },

    async created(event){
        const date = new Date();
        this.picked_year = date.getFullYear();
        this.month_name = date.getMonth()+1;
        const month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        this.month_proper_name = month_name_array[date.getMonth()];

        this.getDealReport()
    },

    computed: {
        totalAmount() {
        return this.campaign_categories.reduce((total, row) => total + parseFloat(row.total), 0).toFixed(2);
        },
        totalInvoiceAmount() {
        return this.campaign_data_invoice.reduce((total, row) => total + parseFloat(row.total), 0).toFixed(2);
        }
    },
    methods: {
        validateAmount(event) {
                // Ensure that the value is non-negative
                const amount = parseFloat(event.target.value);
                if (!isNaN(amount) && amount < 0) {
                    event.target.value = 0; // Set value to 0 if negative
                    // this.transaction.amount = 0; // Update data property
                }
            },
        addName() {
            if (this.newCategory.name.trim() !== '') {
                this.categories.push({ ...this.newCategory });
                this.newCategory = {
                name: '',
                model: '',
                rate: '',
                quantity: '',
                total: ''
                };
            }
        },
        isOpen(){
            this.seen = true;
        },
        closeDatepicker(event){
            this.seen = false;
        },
        async showDate (date) {

            this.month_name = date.monthIndex;
            this.month_proper_name = date.month;
            this.picked_year = date.year;
            this.getDealReport()
            console.log("date m",date);

            setTimeout(() => {
                this.seen = false;
            },500);
            
        },

        async openiopopupBtn(id, status, beneficiary){
            this.invoiceid = id;
            await axios.post(BASE_URL + '/api/iopopupdetails',{'id':id})
            .then((response)=>{
                console.log("test",response.data.iodetails.solo_email_model);
                // this.io.id = response.data.iodetails.id,
                this.io.company_name = response.data.iodetails.company_name;
                this.io.company_address = response.data.iodetails.company_address;
                this.io.contact_person_name = response.data.iodetails.company_name;
                this.io.contact_person_reg_no = response.data.iodetails.contact_person_reg_no;
                this.io.contact_person_email = response.data.iodetails.contact_person_email;
                this.io.web_app_name = response.data.iodetails.web_app_name;
                this.io.start_date = response.data.iodetails.start_date;
                this.io.end_date = response.data.iodetails.end_date;
                this.io.placemnet = response.data.iodetails.placemnet;
                this.io.total_budget = response.data.iodetails.total_budget;
                // this.io.solo_name = response.data.iodetails.solo_email_model,
                // this.io.solo_rate = response.data.iodetails.solo_email_rate,
                // this.io.solo_quantity = response.data.iodetails.solo_email_quantity,
                // this.io.solo_total = response.data.iodetails.solo_email_total,
                // this.io.email_template_name = response.data.iodetails.email_template_model,
                // this.io.email_template_rate = response.data.iodetails.email_template_rate,
                // this.io.email_template_quantity = response.data.iodetails.email_template_quantity,
                // this.io.email_template_total = response.data.iodetails.email_template_total

                // if (response.data.iodetails.solo_email_model != null) {
                //     this.selectedData.push('Solo Email');
                // }
                // if (response.data.iodetails.email_template_model != null) {
                //     this.selectedData.push('Email Template');
                // }
                this.campaign_categories = response.data.iocampaigndetails
                this.io_status = response.data.iodetails.io_status
                console.log("huuhu", this.io_status);
            });

            if (beneficiary == null) {
                this.tostMessage('warning','Please add benificiary details first')
                
            }
            else if (this.io_status == '1') {                    
                this.openiopopup = true;
                this.deal_status = status
            } else if(status == "Closed") {
                this.tostMessage('warning','IO deatils has not been updated for this deal')
            }
            else {
                this.tostMessage('warning','This deal is not close yet')
            }

        },

        // openiopopupBtn(){
        //     this.openiopopup = true;
        // },
        closeiopopup(){
            this.openiopopup = false;
            this.deal_status = '';
        },
        // toggleEdit(row) {
        //     row.isEditing = !row.isEditing;
        // },

        async openiopopupBtn1(id, status, beneficiary){
  
            await axios.post(BASE_URL + '/api/iopopupdetails',{'id':id})
            .then((response)=>{
                // console.log("test",response.data.iodetails.solo_email_model);
                this.invoice.id = response.data.iodetails.id,
                this.invoice.cName = response.data.iodetails.company_name,
                this.invoice.cAddress = response.data.iodetails.company_address,
                this.invoice.contactName = response.data.iodetails.contact_person_name,
                this.invoice.contactEmail = response.data.iodetails.contact_person_email,
                this.invoice.regNo = response.data.iodetails.contact_person_reg_no,
                this.campaign_data_invoice = response.data.iocampaigndetails
                this.invoice_status = response.data.iodetails.io_status

            });

            if (beneficiary == null) {
                this.tostMessage('warning','Please add benificiary details first')
                
            }
            else if (this.invoice_status == '1') {                    
                this.openiopopup1 = true;
                this.deal_status = status
            } else if(status == "Closed") {
                this.tostMessage('warning','Invoice deatils has not been updated for this deal')
            }
            else {
                this.tostMessage('warning','This deal is not close yet')
            }

        },

        closeiopopup1(){
            this.openiopopup1 = false;
            this.deal_status = '';

        },

        async getDealReport(){
        await axios.post(BASE_URL + '/api/getDealReport',{'month':this.month_name, 'year':this.picked_year})
        
        .then((response)=>{
        this.dealReportdata = response.data.dealReportList;

        });
        // console.log("dealReportdata",this.dealReportdata);

        },

        async editDetails(id){
            this.rowtobeedited_id = id;
            this.showeditedrow = true;
        },

        async updateDetails(id,amount,payment_status, payment_completion_status,empid, payment_method, beneficiary){

            if (payment_completion_status == 'Paid') {
                if (payment_status != 'Closed') {
                    payment_completion_status == 'Pending';
                    this.tostMessage('warning', "Payment Status can not be paid for an unclosed deal!");   
                    // this.getMonthData();


                }
                else if (payment_method == null) {
                    payment_completion_status == 'Pending';
                    this.tostMessage('warning', 'Please add payment method first!');   
                    // this.getMonthData();


                }
                else if(beneficiary == null){
                    payment_completion_status == 'Pending';
                    this.tostMessage('warning', 'Please add beenficiary details first!');                        
                    // this.getMonthData();


                }
                else{
                    this.showeditedrow = false;
                    console.log("row.amount",amount);
                    console.log("row.payment_status",payment_status);
                    await axios.post(BASE_URL + '/api/updatedealReport',{'deal_id':id, 'amount':amount, 'status':payment_status,
                    'payment_status':payment_completion_status, 'id': empid, 'payment_method':payment_method, 'beneficiary':beneficiary})
                        .then((response)=>{
                    this.tostMessage('success', 'Deal details updated successfully.');                        
                            
                        });
                    this.getDealReport();
                }
            }
            else{
                this.showeditedrow = false;
                console.log("row.amount",amount);
                console.log("row.payment_status",payment_status);
                await axios.post(BASE_URL + '/api/updatedealReport',{'deal_id':id, 'amount':amount, 'status':payment_status,
                'payment_status':payment_completion_status, 'id': empid, 'payment_method':payment_method, 'beneficiary':beneficiary})
                    .then((response)=>{
                        
                    });
                this.getDealReport();
            }

        },

        async toDataUrl(){

            await fetch('https://cdn.logo.com/hotlink-ok/logo-social.png')
            .then(response => response.blob())
            .then(blob => {
                // Read the image data as a Data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                // Convert the Data URL to base64
                const base64Data = reader.result.split(',')[1];
                console.log(base64Data);
                return base64Data;

                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error('Error fetching or converting the image:', error);
            });

        },

        async exportToPDF(id) {
            this.tostMessage("info", "Preparing download...");

            const toDataUrl = async function (url, callback) {
                //Convert to base64
                return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                    resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                };
                xhr.onerror = () => {
                    reject({
                    status: this.status,
                    statusText: xhr.statusText,
                    });
                };
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
                });
            };

            // var url = "https://works-reddensoft.com/lms/admin/public/assets/signature.png";
            var url_bwd_head_logo = BASE_URL + "/public/assets/bwd-head-logo.svg";
            let bwd_head_logo = await toDataUrl(url_bwd_head_logo);

            var url_bwd_head_round = BASE_URL + "/public/assets/bwd-head-round.svg";
            let bwd_head_round = await toDataUrl(url_bwd_head_round);

            var url_Polygon1 = BASE_URL + "/public/assets/Polygon1.png";
            let Polygon1 = await toDataUrl(url_Polygon1);

            var url_sing = BASE_URL + "/public/assets/sing.png";
            let sing = await toDataUrl(url_sing);

            var url = BASE_URL + "/public/assets/signature.png";
            let urlBase64 = await toDataUrl(url);

            var url_lynkme_logo2 = BASE_URL + "/public/assets/lynkme360-logo2.svg";
            let lynkme_logo2 = await toDataUrl(url_lynkme_logo2);

            var url_lynkme_head_right =
            BASE_URL + "/public/assets/lynkme-head-right.svg";
            let lynkme_head_right = await toDataUrl(url_lynkme_head_right);

            axios.post(BASE_URL + '/api/fetchDealDataForIO',{ 'id':this.invoiceid, empid:this.ID})
            // axios.post(BASE_URL + '/api/fetchDealDataForIO',{ 'id':this.io.id})
            .then((response)=>{
                console.log(response.data.iodetails);
                

                this.pdfdata = response.data.iodetails;
                var email = response.data.email;
                var empname = response.data.empname;
                var position = response.data.position;
                var date = response.data.date;
                var signature_img = response.data.signature;
                var lynkme_alias = response.data.lynkme_alias;
                var bwd_title = response.data.bwd_title;
                var lynkme_title = response.data.lynkme_title;
                var signature_lynkme = response.data.signature_lynkme;
                //this.htmltopdf(this.salarydata);
                console.log('signature_img',signature_img);
                //var html = `<h1>${this.cl_slip_payable}</h1>`;

                //html2pdf(html);


                let signature =  toDataUrl(signature_img);

                //this.htmltopdf(this.salarydata);
                //var html = `<h1>${this.cl_slip_payable}</h1>`;

            //html2pdf(html);

            this.htmltopdf(this.pdfdata,urlBase64, email,
              bwd_head_logo,
              bwd_head_round,
              Polygon1,
              sing,
              lynkme_logo2,
              lynkme_head_right,
              empname,
              position,
              date,
              signature_img,
              lynkme_alias,
              bwd_title,
              lynkme_title,
              signature_lynkme);

            })
        
        },

        // async htmltopdf(res,logo){

        //     console.log("res",res);
        //     const resvalue = Object.values(res);
        //     console.log("resvalue",resvalue);
        //     const items = JSON.parse(resvalue[20]);

        //     var htmldemo = `
        //             <style></style>
        //             <div style="width:100%; padding:20px " id="pdfFormat">
                    
        //                 <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                     <tbody>
        //                         <tr>
        //                             <td>
        //                                 <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
        //                                     Insertion Order
        //                                 </p>
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
        //                                 <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Reddensoft Infotech PVT LTD</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Amp Mall</p>
        //                                             </td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
        //                                 <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[12]}</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[13]}</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[14]}</p>
        //                                             </td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td style="padding: 15px 0;"></td>
        //                         </tr>
        //                         <tr>
        //                             <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
        //                                 <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">big Wave Development LLC</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Ricky Bell</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 (727) 217-5552</p>
        //                                             </td>
        //                                         </tr>
        //                                         <tr>
        //                                             <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
        //                                             </td>
        //                                             <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Ricky@bigwavedevelopment.com</p>
        //                                             </td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td style="padding: 15px 0;"></td>
        //                         </tr>
        //                         <tr>
        //                             <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
        //                                 <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <thead>
        //                                         <tr>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
        //                                             </th>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
        //                                             </th>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
        //                                             </th>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
        //                                             </th>
        //                                             <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
        //                                             </th>
        //                                         </tr>
        //                                     </thead>
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[15]}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[16]}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[17]}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[18]}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[19]}</p>
        //                                             </td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <thead>
        //                                         <tr>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
        //                                             </th>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
        //                                             </th>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
        //                                             </th>
        //                                             <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
        //                                             </th>
        //                                             <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
        //                                             </th>
        //                                         </tr>
        //                                     </thead>
        //                                     <tbody>
        //                                         ${items.map(item => `
        //                                         <tr>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${item.name}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.rate}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.total}</p>
        //                                             </td>
        //                                             </tr>
        //                                         `).join('')}

        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
        //                                                 </p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;width: 28%;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;"></p>
        //                                             </td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td style="padding: 15px 0;"></td>
        //                         </tr>
        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
        //                                             </td>
        //                                             <td style="border-bottom: 1px solid #000;width: 28%;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
        //                                             </td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td>
        //                                 <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
        //                                             </td>
        //                                             <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
        //                                                 <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
        //                                                     This Insertion Order ("IO") serves as a binding agreement between 
        //                                                     Big Wave Development and “Advertiser” Signature on this Insertion 
        //                                                     signifies acceptance by Advertiser of this agreement</p>
        //                                             </td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>        
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td style="padding: 15px 0 350px 0;"></td>
        //                         </tr>
        //                         <tr>
        //                             <td style="padding: 0 50px 10px 50px;">
        //                                 <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td>
        //                                 <ul style="margin: 0;padding: 0 100px 10px 100px;">
        //                                     <li>
        //                                         <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
        //                                             The total cost must be paid in full before rendering the service unless otherwise specified. All 
        //                                             payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
        //                                             unless otherwise specified.
        //                                         </p>
        //                                     </li>
        //                                     <li>
        //                                         <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
        //                                             Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
        //                                             Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
        //                                             pornographic content on any of the creative materials
        //                                         </p>
        //                                     </li>
        //                                     <li>
        //                                         <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
        //                                             Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
        //                                         </p>
        //                                     </li>
        //                                 </ul>
        //                             </td>
        //                         </tr>

        //                         <tr>
        //                             <td style="padding: 15px 0;"></td>
        //                         </tr>
        //                         <tr>
        //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
        //                                 <tr>
        //                                     <td style="width: 50%;padding: 0 0 0 50px;">
        //                                         <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
        //                                         <ul style="margin: 0; padding: 0;">
        //                                             <li style="list-style: none;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Signature: 
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
        //                                                         <img width="200" height="35"
        //                                                         style=" vertical-align: text-top;text-align: center;"
        //                                                         src="`+ logo +`" alt="">   
        //                                                     </span>
        //                                                 </p>
        //                                             </li>
        //                                             <li style="list-style: none;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Name:
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
        //                                                 </p>
        //                                             </li>
        //                                             <li style="list-style: none;"> 
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Title:
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
        //                                                 </p>
        //                                             </li>
        //                                             <li style="list-style: none;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Date:
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
        //                                                 </p>
        //                                             </li>
        //                                         </ul>
        //                                     </td>
        //                                     <td style="width: 50%;padding: 0 50px 0 0;">
        //                                         <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
        //                                         <ul style="margin: 0; padding: 0;">
        //                                             <li style="list-style: none;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Signature: 
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
        //                                                         <img width="200" height="35"
        //                                                         style=" vertical-align: text-top;text-align: center;"
        //                                                         src="`+ logo +`" alt="">   
        //                                                     </span>
        //                                                 </p>
        //                                             </li>
        //                                             <li style="list-style: none;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Name:
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
        //                                                 </p>
        //                                             </li>
        //                                             <li style="list-style: none;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Title:
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
        //                                                 </p>
        //                                             </li>
        //                                             <li style="list-style: none;">
        //                                                 <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
        //                                                     Date:
        //                                                     <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
        //                                                 </p>
        //                                             </li>
        //                                         </ul>
        //                                     </td>
        //                                 </tr>
        //                             </table>
        //                         </tr>
                                
        //                     </tbody>
        //                 </table>
        //             `;

        //     console.log(htmldemo);
        //     htmldemo += ``;
        //     html2pdf(htmldemo,{filename: 'hh'.concat("-","payslip").concat("-",'mm'.concat("-",'nn')) });

        //     // axios.post(BASE_URL + '/api/savesalarypdf',formData)
        //     //     .then((response)=>{
        //     //         console.log("uyertfgeusyyr",response.data.salary_details);
        //     //     })


        // },

        async htmltopdf(res,logo, email, bwd_head_logo,
            bwd_head_round,
            Polygon1,
            sing,
            lynkme_logo2,
            lynkme_head_right,
            empname,
            position,
            date,
            signature_img,
            lynkme_alias,
            bwd_title,
            lynkme_title,
            signature_lynkme)
        {
            const toDataUrl = async function (url, callback) {
                //Convert to base64
                return new Promise((resolve, reject) => {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function () {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                    };
                    xhr.onerror = () => {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText,
                    });
                    };
                    xhr.open("GET", url);
                    xhr.responseType = "blob";
                    xhr.send();
                });
                };
            let signature = await toDataUrl(signature_img);
            let signature_lynkMe = await toDataUrl(signature_lynkme);

            console.log("res",res);
            const resvalue = Object.values(res);
            console.log("resvalueeeeeee",resvalue);
            const items = JSON.parse(resvalue[25]);
            console.log("itemssssssss",items);
            var ioid = String(resvalue[0]).padStart(3, '0');

            // var htmldemo = `
            //         <style></style>
            //         <div style="width:100%; padding:20px " id="pdfFormat">
                    
            //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                 <tbody>
            //                     <tr>
            //                         <td>
            //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
            //                                 Big Wave Development LLC
            //                             </p>
            //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
            //                                 www.bigwavedevelopment.com
            //                             </a>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#5032687</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>
                                
            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>

            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>
            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>

            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <thead>
            //                                     <tr>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
            //                                         </th>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
            //                                         </th>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
            //                                         </th>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
            //                                         </th>
            //                                     </tr>
            //                                 </thead>
            //                                 <tbody>


            //                                     ${items.map(item => `
            //                                     <tr>
            //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
            //                                         </td>
            //                                     </tr>
            //                                     `).join('')}

            //                                     <tr>
            //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>

            //                     <tr>
            //                         <td>
            //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
            //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span> 
            //                             </p>
            //                         </td>
            //                     </tr>
                                
            //                 </tbody>
            //             </table>
            //         `;

            
            var htmldemo = `
                    <style></style>
                    <div style="width:100%; padding:20px " id="pdfFormat">
                    
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                            Insertion Order
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">big Wave Development LLC</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[2]}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 (727) 217-5552</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <thead>
                                                <tr>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                    </th>
                                                    <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[20]}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[21]}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[22]}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${resvalue[23]}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${resvalue[24]}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <thead>
                                                <tr>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                    </th>
                                                    <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                ${items.map(item => `
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                    </td>
                                                </tr>
                                                `).join('')}
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                        </p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;width: 28%;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${resvalue[24]}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;width: 28%;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                    </td>
                                                    <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                            This Insertion Order ("IO") serves as a binding agreement between 
                                                            Big Wave Development and “Advertiser” Signature on this Insertion 
                                                            signifies acceptance by Advertiser of this agreement</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0 350px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="padding: 0 50px 10px 50px;">
                                        <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                    payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                    unless otherwise specified.
                                                </p>
                                            </li>
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                    Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                    pornographic content on any of the creative materials
                                                </p>
                                            </li>
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                </p>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                        <tr>
                                            <td style="width: 50%;padding: 0 0 0 50px;">
                                                <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                <ul style="margin: 0; padding: 0;">
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Signature: 
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                <img width="200" height="35"
                                                                style=" vertical-align: text-top;text-align: center;"
                                                                src="`+ logo +`" alt="">   
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Name:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;"> 
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Title:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Date:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td style="width: 50%;padding: 0 50px 0 0;">
                                                <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                <ul style="margin: 0; padding: 0;">
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Signature: 
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                <img width="200" height="35"
                                                                style=" vertical-align: text-top;text-align: center;"
                                                                src="`+ logo +`" alt="">   
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Name:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Title:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Date:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </table>
                                </tr>
                                
                            </tbody>
                        </table>
                    `;
            // console.log(htmldemo);

            var htmldemo_lynkme =
            `
                            <style></style>
                            <div style="width:100%; padding:20px " id="pdfFormat">
                            
                                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                                    Insertion Order
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                    resvalue[15]
                                                                }</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                    resvalue[16]
                                                                }</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                    resvalue[17]
                                                                }</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                    resvalue[18]
                                                                }</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                    resvalue[19]
                                                                }</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td style="padding: 15px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">LynkMe360 LLC</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[2]}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, 218 Datura St. West Palm Beach, FL 33401-5679</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 727 440 5867</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                            </td>
                                                            <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td style="padding: 15px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                            </th>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                            </th>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                            </th>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                            </th>
                                                            <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                    resvalue[20]
                                                                }</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                    resvalue[21]
                                                                }</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                    resvalue[22]
                                                                }</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                    resvalue[23]
                                                                }</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                    resvalue[24]
                                                                }</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                            </th>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                            </th>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                            </th>
                                                            <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                            </th>
                                                            <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        ${items
                                                            .map(
                                                            (item) => `
                                                        <tr>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                            </td>
                                                        </tr>
                                                        `
                                                            )
                                                            .join("")}
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                                </p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;width: 28%;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                    resvalue[24]
                                                                }</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td style="padding: 15px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                            </td>
                                                            <td style="border-bottom: 1px solid #000;width: 28%;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                            </td>
                                                            <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                                    This Insertion Order ("IO") serves as a binding agreement between 
                                                                    Big Wave Development and “Advertiser” Signature on this Insertion 
                                                                    signifies acceptance by Advertiser of this agreement</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>        
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td style="padding: 15px 0 350px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding: 0 50px 10px 50px;">
                                                <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                                    <li>
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                            The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                            payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                            unless otherwise specified.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                            Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                            Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                            pornographic content on any of the creative materials
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                            Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
    
                                        <tr>
                                            <td style="padding: 15px 0;"></td>
                                        </tr>
                                        <tr>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                                <tr>
                                                    <td style="width: 50%;padding: 0 0 0 50px;">
                                                        <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                        <ul style="margin: 0; padding: 0;">
                                                            <li style="list-style: none;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                    Signature: 
                                                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                        <img width="200" height="35"
                                                                        style=" vertical-align: text-top;text-align: center;"
                                                                        src="` +
            logo +
            `" alt="">   
                                                                    </span>
                                                                </p>
                                                            </li>
                                                            <li style="list-style: none;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                    Name:
                                                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                                </p>
                                                            </li>
                                                            <li style="list-style: none;"> 
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                    Title:
                                                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                                </p>
                                                            </li>
                                                            <li style="list-style: none;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                    Date:
                                                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td style="width: 50%;padding: 0 50px 0 0;">
                                                        <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                        <ul style="margin: 0; padding: 0;">
                                                            <li style="list-style: none;">
                                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                    Signature: 
                                                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                        <img width="200" height="35"
                                                                        style=" vertical-align: text-top;text-align: center;"
                                                                        src="` +
            logo +
            `" alt="">   
                                    </span>
                                </p>
                            </li>
                            <li style="list-style: none;">
                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                    Name:
                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                                </p>
                            </li>
                            <li style="list-style: none;">
                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                    Title:
                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                                </p>
                            </li>
                            <li style="list-style: none;">
                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                    Date:
                                    <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                                </p>
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
            </tr>
    
            </tbody>
            </table>
            `;


      var htmlBW =
              `<html>
    
    
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;;padding:00 20px 40px 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:15px; padding-top:30px;"><img src="` +bwd_head_logo +`" alt="" /></td>
                                        <td style="padding-bottom:15px; padding-top:30px;" align="right"><img src="` +bwd_head_round +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                
                        <tr>
                            <td style="font-size: 25px; font-weight: 700; color: #353535; padding: 11px 0 22px;">Insertion Order</td>
                        </tr>
                
                        <tr>
                            <td style="padding-bottom:20px">
                                <table style="border-spacing:0px; width: 100%; padding-bottom: 12px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top;">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" width="270px" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Advertiser Details :</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Name:</b> &nbsp; &nbsp;      Big Wave Development LLC</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Address:</b>     7901 4th ST. N & St. Petersburg, <br>
                                                                &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  FL.UN 33702 </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:0 3px 30px 10px;"><b>Website:</b>      www.bigwavedevelopment.com </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Publisher Details:</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td align="right" style="vertical-align: top;">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Contact Person:</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:7px 10px 3px 10px;"><b>Contact Name:</b> ${resvalue[17]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Email:</b>   ${resvalue[19]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Registration Number:</b>  ${resvalue[18]}</td>
                                                        </tr>
                
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </td>
                        </tr>
                
                        <tr>
                            <td bgcolor="#EAEAEA" style="padding: 20px 15px;">
                            <table style="border-spacing:0px; width: 100%; border-radius: 4px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Company Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">Big Wave Development LLC</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Contact Person:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[17]}</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Address: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">7901 4th ST. N St. Petersburg, FL.UN 33702 </td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">City and Country:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">United States</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Telephone: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">+1 (727) 217 5552</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Email:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[19]}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </td> 
                        </tr>
                
                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Details:</td>
                        </tr>
                
                        <tr>
                            <td>
                            <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word; border-radius: 4px 0 0 4px;">
                                                            Site / Application
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word;">
                                                            Start Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word;">
                                                            End Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word;">
                                                            Placement
                                                        </th>
                                                        <th bgcolor="#D9D9D9" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 4px 4px 0;">
                                                            Total Budget (inclusive all taxes)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[20]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[21]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[22]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[23]}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                
                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Type:</td>
                        </tr>
                
                        <tr>
                            <td>
                            <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Category
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Model
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Rate
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Quantity
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="160px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 4px 4px 0;">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                    .map(
                                                        (item) => `
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="120px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.name}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.model}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.rate}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.quantity}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.total}</td>
                                                    </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                    <tr>
                                                        <td bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Grand Total</td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                
                        <tr>
                            <td style="padding-top: 15px;">
                            <table width: 100%; style="border-spacing:0px; page-break-after: always" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Payment Terms
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Prepayment
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="300px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Further comments</td>
                                                        <td bgcolor="#E6E6E6" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">This insertion order(“IO”) serves as a binding <br>
                                                            agreement between  Big Wave  Development <br>
                                                            and “Advertiser” signature on this insertion <br>
                                                            signifies acceptance by Advertiser of this agreement
                                                            </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                        
                    
                    </tbody>
                </table>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;;padding:0 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:15px"><img src="` +bwd_head_logo +`" alt="" /></td>
                                        <td style="padding-bottom:15px" align="right"><img src="` +bwd_head_round +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>

                        <tr>
                            <td style="font-size: 14px; color: #242424; font-weight: 700; padding-top: 25px;">
                                Additional Details:  
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`"alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                The total cost must be paid in full before rendering the service unless otherwise specified.
                All payments must be made by bank Wire/Crypto. All advertising purchases are 
                non-refundable unless otherwise specified.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                Advertiser must not allow illegal content, spa, spyware illegal drugs, medicine, ROM, EMU’s
                Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads) obscener or pornographic content or any of creative materials
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                Bigwave Development is not responsible for the success or failure of the advertiser’s
                campaign
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr>
                        <td>
                            <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                            <td>
                                <table align="right" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px; color: #4F77BA; line-height: 16px; font-weight: 400; padding-top: 27px;">
                                                Big wave Development LLC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; color: #000; line-height: 16px; font-weight: 700; padding-top: 8px;">
                                                Signature::
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 10px;">
                                                <img src="` +signature +`" alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 25px; font-size: 12px; line-height: 26px; color: #242424; font-weight: 600;">
                                                Name: ${empname} <br>
                                                Tittle: ${bwd_title} <br>
                                                Date:${date}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </td>  

                            </tr>
                            </table>
                            </td>
                        </tr>
                        
                    
                    </tbody>
                </table>
                
                </html>`;

            
                var htmlLynkMe =
                `<html>

                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;;padding:0 20px 40px 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td style="padding-top:20px">
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%; border-bottom: 1px solid #D9D9D9;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:20px; padding-top:30px;"><img src="` +lynkme_logo2 +`" alt="" /></td>
                                        <td style="padding-bottom:20px; padding-top:30px;" align="right"><img src="` +lynkme_head_right +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>

                        <tr>
                            <td style="font-size: 25px; font-weight: 700; color: #353535; padding: 11px 0 22px;">Insertion Order</td>
                        </tr>

                        <tr>
                            <td>
                                <table width="100%" style="border-spacing:0px; padding-bottom: 12px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top; padding-bottom:30px">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" width="250px" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Advertiser Details:</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:7px 10px;"><b>Name:</b> &nbsp; &nbsp;     ${resvalue[15]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:0 10px 30px 10px;"><b>Address:</b>     ${resvalue[16]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Publisher Details:</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td align="right" style="vertical-align: top;">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" width="220px" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Contact Person:</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:7px 10px 3px 10px;"><b>Contact Name:</b> ${resvalue[17]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Email:</b>     ${resvalue[19]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Registration Number:</b> ${resvalue[18]}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </td>
                        </tr>

                        <tr>
                            <td style="padding:20px 15px;" bgcolor="#EAEAEA">
                            <table width="100%" style="border-spacing:0px; padding: 20px 15px; border-radius: 4px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Company Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">LinkMe360 LLC</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Contact Person:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[17]}</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Address: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">PNC Bank, 218 Datura St. West Palm Beach,FL-33401-5679 </td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">City and Country:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">United States</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Telephone: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">+1 (727) 440 5687</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Email:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[19]}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </td> 
                        </tr>

                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Details:</td>
                        </tr>

                        <tr>
                            <td>
                            <table width="600px" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word; border-radius: 4px 0 0 4px;">
                                                            Site / Application
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;">
                                                            Start Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;">
                                                            End Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;">
                                                            Placement
                                                        </th>
                                                        <th bgcolor="#D9D9D9" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;border-radius: 0 4px 4px 0;">
                                                            Total Budget (inclusive all taxes)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[20]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[21]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[22]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[23]}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">$${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>

                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Type:</td>
                        </tr>

                        <tr>
                            <td>
                            <table width="100%" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Category
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Model
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Rate
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Quantity
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="160px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 4px 4px 0;">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                    .map(
                                                        (item) => `
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="120px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.name}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.model}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.rate}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.quantity}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.total}</td>
                                                    </tr>
                                                </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                    <tr>
                                                        <td bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Grand Total</td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>

                        <tr>
                            <td style="padding-top: 15px;">
                            <table width="100%" style="border-spacing:0px;; page-break-after: always" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Payment Terms 
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Prepayment
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="300px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Further comments</td>
                                                        <td bgcolor="#E6E6E6" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">This insertion order(“IO”) serves as a binding <br>
                                                            agreement between  Big Wave  Development <br>
                                                            and “Advertiser” signature on this insertion <br>
                                                            signifies acceptance by Advertiser of this agreement
                                                            </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>


                        
                    
                    </tbody>
                </table>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;padding:0 20px; width: 600px;  bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:20px"><img src="` +lynkme_logo2 +`" alt="" /></td>
                                        <td style="padding-bottom:20px" align="right"><img src="` +lynkme_head_right +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>

                        <tr>
                            <td style="font-size: 14px; color: #242424; font-weight: 700; padding-top: 25px;">
                                Additional Details: 
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                The total cost must be paid in full before rendering the service unless otherwise specified. 
                All
                payments must be made by Bank Wire/Crypto. All advertising purchases are
                non-refundable
                unless otherwise specified.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's,
                                                Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)
                                                obscene or
                                                pornographic content on any of the creative materials.                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                LynkMe360 LLC is not responsible for the success or failure of the advertiser's campaign.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr>
                        <td>
                            <table style="border-spacing:0px; width: 100%; padding-top: 27px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tr>

                            <td>
                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px; color: #5DB533; line-height: 16px; font-weight: 400; padding:30px 0 0">
                                                ${resvalue[15]}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; color: #000; line-height: 16px; font-weight: 700; padding-top: 8px;">
                                                Signature:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 10px; height: 50px;">
                                                                        
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 25px; font-size: 12px; line-height: 26px; color: #242424; font-weight: 600;">
                                                Name:  <br>
                                                Title:  <br>
                                                Date:
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </td>  

                            <td>
                                <table align="right" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px; color: #5DB533; line-height: 16px; font-weight: 400; padding:30px 0 0">
                                                Big wave Development LLC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; color: #000; line-height: 16px; font-weight: 700; padding-top: 8px;">
                                                Signature:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 10px;">
                                                <img src="` +signature_lynkMe +`" alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 25px; font-size: 12px; line-height: 26px; color: #242424; font-weight: 600;">
                                                Name: ${lynkme_alias} <br>
                                                Title: ${lynkme_title} <br>
                                                Date:${date}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </td> 

                            </tr>
                            </table>
                            </td>
                        </tr>
                        
                    
                    </tbody>
                </table>

                </html>`

        if (resvalue[27] == "Bigwave") {
            // console.log(htmldemo);
            htmlBW += ``;
            html2pdf(htmlBW, {
            filename: resvalue[3]
                .concat("-", resvalue[27])
                .concat("-", "IO")
                .concat("-", ioid),
            });
        } else if (resvalue[27] == "Lynkme") {
            htmlLynkMe += ``;
            html2pdf(htmlLynkMe, {
            filename: resvalue[3]
                .concat("-", resvalue[27])
                .concat("-", "IO")
                .concat("-", ioid),
            });
        } else {
            this.tostMessage("warning", "Can not download IO for Reddensoft.");
        }
        this.tostMessage("success", "Downloaded");

            // axios.post(BASE_URL + '/api/savesalarypdf',formData)
            //     .then((response)=>{
            //         console.log("uyertfgeusyyr",response.data.salary_details);
            //     })

        },

        async exportToPDFInvoice(id){
            this.tostMessage("info", "Preparing download...");            
            const toDataUrl = async function (url, callback) {
                //Convert to base64
                return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
                };
                xhr.onerror = () => {
                    reject({
                    status: this.status,
                    statusText: xhr.statusText,
                    });
                };
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
                });
            };

            // var url = "https://works-reddensoft.com/lms/admin/public/assets/signature.png";
            // var url = "https://works-reddensoft.com/lms/admin/public/assets/reddensoft_logo.svg";
            var url = BASE_URL + "/public/assets/reddensoft_logo.svg";
            let urlBase64 = await toDataUrl(url);

            var url_bwd_head_logo = BASE_URL + "/public/assets/bwd-head-logo.svg";
            let bwd_head_logo = await toDataUrl(url_bwd_head_logo);

            var url_bwd_head_round = BASE_URL + "/public/assets/bwd-head-round.svg";
            let bwd_head_round = await toDataUrl(url_bwd_head_round);

            var url_Polygon1 = BASE_URL + "/public/assets/Polygon1.png";
            let Polygon1 = await toDataUrl(url_Polygon1);

            var url_sing = BASE_URL + "/public/assets/sing.png";
            let sing = await toDataUrl(url_sing);

            var url_lynkme_logo2 = BASE_URL + "/public/assets/lynkme360-logo2.svg";
            let lynkme_logo2 = await toDataUrl(url_lynkme_logo2);

            var url_lynkme_head_right =
            BASE_URL + "/public/assets/lynkme-head-right.svg";
            let lynkme_head_right = await toDataUrl(url_lynkme_head_right);

            var url_lynkme_head_logo =
            BASE_URL + "/public/assets/lynkme-head-logo.svg";
            let lynkme_head_logo = await toDataUrl(url_lynkme_head_logo);

            var url_Rectangle1 = BASE_URL + "/public/assets/Rectangle1.svg";
            let Rectangle1 = await toDataUrl(url_Rectangle1);

            var url_Rectangle2 = BASE_URL + "/public/assets/Rectangle2.svg";
            let Rectangle2 = await toDataUrl(url_Rectangle2);

            axios.post(BASE_URL + '/api/fetchDealDataForIO',{ 'id':this.invoice.id})
            .then((response)=>{
                console.log(response.data.iodetails);
                
                this.pdfdata = response.data.iodetails;
                //this.htmltopdf(this.salarydata);
                //var html = `<h1>${this.cl_slip_payable}</h1>`;

            //html2pdf(html);

            this.htmltopdfInvoice(              
              this.pdfdata,
              urlBase64,
              bwd_head_logo,
              bwd_head_round,
              Polygon1,
              sing,
              lynkme_logo2,
              lynkme_head_right,
              lynkme_head_logo,
              Rectangle1,
              Rectangle2);

            })
     
        },

        async htmltopdfInvoice(
            res,
            logo,
            bwd_head_logo,
            bwd_head_round,
            Polygon1,
            sing,
            lynkme_logo2,
            lynkme_head_right,
            lynkme_head_logo,
            Rectangle1,
            Rectangle2){

            console.log("res",res);
            const resvalue = Object.values(res);
            console.log("resvalue",resvalue);
            const items = JSON.parse(resvalue[25]);
            console.log("itemsss",items);
            var ioid = String(resvalue[0]).padStart(3, '0');

            // var htmldemo = `
            //         <style></style>
            //         <div style="width:100%; padding:20px " id="pdfFormat">
                    
            //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                 <tbody>
            //                     <tr>
            //                         <td>
            //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
            //                                 Big Wave Development LLC
            //                             </p>
            //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
            //                                 www.bigwavedevelopment.com
            //                             </a>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#${ioid}</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>
                                
            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>

            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>
            //                     <tr>
            //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
            //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
            //                         </td>
            //                     </tr>
            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <tbody>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
            //                                         </td>
            //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>

            //                     <tr>
            //                         <td>
            //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            //                                 <thead>
            //                                     <tr>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
            //                                         </th>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
            //                                         </th>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
            //                                         </th>
            //                                         <th>
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
            //                                         </th>
            //                                     </tr>
            //                                 </thead>
            //                                 <tbody>


            //                                     ${items.map(item => `
            //                                     <tr>
            //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
            //                                         </td>
            //                                     </tr>
            //                                     `).join('')}

            //                                     <tr>
            //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
            //                                         </td>
            //                                         <td style="border-bottom: 1px solid #000;">
            //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
            //                                         </td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>        
            //                         </td>
            //                     </tr>

            //                     <tr>
            //                         <td style="padding: 15px 0;"></td>
            //                     </tr>

            //                     <tr>
            //                         <td>
            //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
            //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span> 
            //                             </p>
            //                         </td>
            //                     </tr>
                                
            //                 </tbody>
            //             </table>
            //         `;

            // console.log(htmldemo);
            // htmldemo += ``;
            // html2pdf(htmldemo,{filename: resvalue[3].concat("-",resvalue[27]).concat("-","Invoice").concat("-",ioid) });
            var invoice_bw = `<html>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;padding:0 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:15px; padding-top:30px;"><img src="` +bwd_head_logo +`" alt="" /></td>
                                        <td align="right" style="padding-bottom:15px; padding-top:30px;"><img src="` +bwd_head_round +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                        <tr>
                            <td>
                                <table style="border-spacing:0px; width: 100%; padding-top: 15px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td valign="top" style="font-size: 24px; line-height: 30px; font-weight: 700; padding-top:30px">INVOICE</td>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 600; padding-top:30px">${resvalue[8]} <br> #${ioid}</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 700; padding-top: 30px;">TO:</td>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 700; padding-top: 30px;">Contact Person:</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 400; padding-top: 4px;"><b> Name: </b> Big Wave Development LLC</td>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 700; padding-top: 4px;">Contact Name: ${resvalue[17]}</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 400; padding-top: 4px;"><b> Address: </b> 7901 4th ST. N St. Petersburg, <br>
                                                &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; FL.UN 33702 </td>
                                            <td valign="top" style="font-size: 12px; line-height: 15px; font-weight: 700; padding-top: 4px;">Email: ${resvalue[19]}</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 15px; font-weight: 400; padding-top: 4px;"><b> Website: </b>  www.bigwavedevelopment.com</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; font-weight: 700; line-height: 18px; padding-top: 24px; padding-bottom: 15px;">Wire Transfer Details:</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                        <td style="padding:20px 15px" bgcolor="#E0E0E0">
                            <table style="border-spacing:0px; width: 100%; padding: 20px 15px; border-radius: 4px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Beneficiary Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">Big Wave Development LLC</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Bank Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Account Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">1242641948</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Routing Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">267084199</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Swift Code:  </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNCCUS33</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Bank Address:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank, National Association <br> Pittsburgh 00115</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td> 
                        </tr>
                        <tr>
                            <td style="font-size: 14px; line-height: 20px; color: #242424; padding: 18px 0 12px; font-weight: 700;">Sub: Invoice:</td>
                        </tr>
                        <tr>
                        <td>
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#B2CBF4" width="220px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0;">
                                                            Item Description
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="80px" align="center" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Quantity
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="80px" align="center" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Rate
                                                        </th>
                                                        <th bgcolor="#B2CBF4" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                                                            Price
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody bgcolor="#E9E8E8">
                                                    ${items
                                                    .map(
                                                    (item) => `
                                                    <tr>
                                                        <td align="left" width="220px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            ${item.name}
                                                        </td>
                                                        <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                                                            ${item.quantity}
                                                        </td>
                                                        <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                                                            ${item.rate}
                                                        </td>
                                                        <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                                                            $${item.total}
                                                        </td>
                                                    </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                </tbody>
                                                <tfoot width="100%">
                                                    <tr>
                                                        <th bgcolor="#B2CBF4" width="600px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0;">
                                                            Grand Total
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="300px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">
                                                            
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="300px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">
                                                            
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="300px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                                                            $${resvalue[24]}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                        </td>
                        </tr>
                        <tr>
                            <td style="font-size: 8px; color: #515151; font-weight: 400; font-style: italic; padding-top: 6px;">If you have any questions or concerns regarding this invoice, contact via email. <b style="color: 515151;"> Thank you! </b></td>
                        </tr>
                    </tbody>
                </table>            
                </html>`

                var invoice_lynkme =`<html>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif; width: 600px; border-bottom: 54px #242424 solid;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="vertical-align: bottom; padding-bottom:30px; padding-top:30px;"><img src="` +lynkme_head_logo +`"  alt="" /></td>
                                        <td style="vertical-align: bottom; padding-bottom:30px; padding-top:30px;">
                                            <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="color: #41BE0A; font-size: 24px; line-height: 28px; font-weight: 700; padding-bottom: 15px;">INVOICE</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 14px; font-weight: 700; line-height: 18px;">Invoice Number:</td> <td style="font-size: 14px; font-weight: 400; line-height: 18px;">&nbsp; #${ioid}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 14px; font-weight: 700; line-height: 18px;">Invoice Date</td> <td style="font-size: 14px; font-weight: 400; line-height: 18px;">&nbsp; ${resvalue[8]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                
                        <tr>
                            <td style="padding-bottom: 30px;">
                                <table style="border-spacing:0px; padding-bottom: 15px; width: 100%; padding-top: 15px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="font-size: 11px; line-height: 15px; color: #41BE0A;">Billed To</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 16px; line-height: 20px; color: #242424; font-weight: 700; padding-bottom: 5px;">${resvalue[15]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Address: </b> ${resvalue[16]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Email: </b> ${resvalue[19]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td align="right">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="font-size: 11px; line-height: 15px; color: #41BE0A;">Billed From</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 16px; line-height: 20px; color: #242424; font-weight: 700; padding-bottom: 5px;">Lynk Me 360 LLC </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Address: </b> 2630 W Broward BLVD Suite 203-1713 <br>
                                                                Fort Lauderdale, FL 3312 United States, </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Tel: </b> +1 727 440 5867</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                
                        <tr>
                            <td style="padding-bottom: 30px;">
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#41BE0A" width="220px" align="left" style="font-size: 10px;font-weight: 600;color: #fff; padding: 10px;white-space: nowrap;">
                                                            Item Description
                                                        </th>
                                                        <th bgcolor="#242424" width="140px" align="center" style="font-size: 10px;font-weight: 600;color: #fff; padding: 10px;white-space: nowrap; border-left: 5px solid #fff;">
                                                            Volume
                                                        </th>
                                                        <th bgcolor="#242424" align="left" style="font-size: 10px;font-weight: 600;color: #fff; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 5px solid #fff;">
                                                            Total ($)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                    .map(
                                                    (item) => `
                                                    <tr>
                                                        <td align="left" width="220px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            ${item.name}
                                                        </td>
                                                        <td align="center" width="140px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            ${item.quantity}
                                                        </td>
                                                        <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap;">
                                                            ${item.total}
                                                        </td>
                                                    </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th width="220px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-top: 1px solid #000000; border-bottom: 1px solid #000;">
                                                            Total Amount
                                                        </th>
                                                        <th width="140px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-top: 1px solid #000000; border-bottom: 1px solid #000;">
                                                            
                                                        </th>
                                                        <th align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-top: 1px solid #000000; border-bottom: 1px solid #000;">
                                                            $${resvalue[24]}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                
                        <tr>
                            <td>
                            <table style="border-spacing:0px; width: 100%; padding-top: 34px; padding-bottom: 50px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="font-size: 16px; line-height: 20px; color: #000000; font-weight: 700; padding-bottom: 15px;">Transaction Details :</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 16px; line-height: 20px; color: #000000; font-weight: 600; padding-bottom: 15px;">Wire Transfer Details:</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Beneficiary Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">LynkMe360 LLC</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Bank Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Account Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">1251348057</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Routing Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">267084199</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Swift Code:  </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNCCUS33</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom:60px">Bank Address:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank, 218 Datura St. <br> West Palm Beach,FL-33401-5679</td>
                                    </tr>
                                </tbody>
                            </table>
                            </td> 
                        </tr>
                        
                    </tbody>
                </table>
                
                </html>`

                var invoice_rs = `<html>
                    <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif; width: 600px;" cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                    <tr>
                    <td>
                    <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td width="30px" style="vertical-align: bottom;"><img src="`+Rectangle2+`" alt="" /></td>
                    <td width="540px" style="vertical-align: top; padding: 0 15px;">
                    <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td>
                    <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="vertical-align: bottom;">
                    <table style="border-spacing:0px; padding-bottom: 20px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="padding-top:30px;"><img src="`+logo+`" alt=""/></td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; padding-top:30px; font-weight: 400; line-height: 18px; padding-top: 20px;">Reddensoft Infotech Private Limited <br>
                    AMP Baishakhi, 7th Floor, Room 705, AG-112, Sec-II <br>
                    Saltlake, Kolkata-700091</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; font-weight: 700; line-height: 18px; color: #5976B5;">info@reddensoft.com</td>
                    </tr>
                    </tbody>
                    </table>
                    </td>
                    <td style="vertical-align: bottom;">
                    <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="color: #353535; font-size: 24px; line-height: 28px; font-weight: 700; padding-bottom: 15px;">INVOICE</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight:400; line-height: 18px;">${
                    resvalue[8]
                    }</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight: 700; line-height: 18px;">#${ioid}</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight: 700; line-height: 18px; padding-top: 12px;">To:</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight: 700; line-height: 18px; padding-top: 5px;">${resvalue[15]}</td>
                    </tr>

                    </tbody>
                    </table>
                    </td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; line-height: 18px; color: #242424; font-weight: 700; padding-top: 30px; padding-bottom: 30px;">Sub: Invoice:</td>
                    </tr>
                    </tbody>
                    </table>
                    </td>
                    </tr>

                    <tr>
                    <td>
                    <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td valign="top">
                    <table style="border-spacing:0px; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                    <th bgcolor="#DCDCDC" width="200px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0;">
                    Item Description
                    </th>
                    <th bgcolor="#DCDCDC" width="80px" align="center" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                    Quantity
                    </th>
                    <th bgcolor="#DCDCDC" width="108px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                    Unit Price ($)
                    </th>
                    <th bgcolor="#DCDCDC" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                    Total ($)
                    </th>
                    </tr>
                    </thead>
                    <tbody>
                    ${items
                    .map(
                    (item) => `
                    <tr>
                    <td align="left" width="200px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                    ${item.name}
                    </td>
                    <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${item.quantity}
                    </td>
                    <td align="left" width="108px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${item.rate}
                    </td>
                    <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${item.total}
                    </td>
                    </tr>
                    `
                    )
                    .join("")}

                    <tr>
                    <td bgcolor="#BBBDC0" align="left" width="200px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">

                    </td>
                    <td bgcolor="#BBBDC0" align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">

                    </td>
                    <td bgcolor="#BBBDC0" align="left" width="108px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    Subtotal
                    </td>
                    <td bgcolor="#BBBDC0" align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${resvalue[24]}
                    </td>
                    </tr>

                    <tr>
                    <td align="left" width="200px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">

                    </td>
                    <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">

                    </td>
                    <td align="left" width="108px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    Tax-0%
                    </td>
                    <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    0.00
                    </td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr>
                    <th bgcolor="#BCBDC0" width="200px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">

                    </th>
                    <th bgcolor="#BCBDC0" width="80px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">

                    </th>
                    <th bgcolor="#BCBDC0" width="108px" align="left" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap;">
                    TOTAL
                    </th>
                    <th bgcolor="#BCBDC0" align="left" style="font-size: 14px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap;">
                    ${resvalue[24]}
                    </th>
                    </tr>
                    </tfoot>
                    </table>
                    </td>
                    </tr>
                    </tbody>
                    </table>  
                    </td>
                    </tr>

                    <tr>
                    <td style="padding:30px 0">
                    <table style="border-spacing:0px; width: 100%; padding-top: 25px; padding-bottom: 15px;" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="font-size: 16px; line-height: 20px; color: #000000; font-weight: 700; padding-bottom: 15px;">Transaction Details :</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Payment Option: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Wise.com</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Company Name: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Reddensoft Infotech PVT Ltd</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Bank Name: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Axis Bank</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Account Number: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">1010101010</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">IFSC Code:</td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">UTIB00000</td>
                    </tr>
                    <tr>
                    <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Swift Code:</td>
                    <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">AXIS55555</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Bank Address:</td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Kolkata, West Bengal, India</td>
                    </tr>
                    </tbody>
                    </table>
                    </td> 
                    </tr>

                    <tr>
                    <td align="center" style="font-size: 8px; color: #515151; font-weight: 400; font-style: italic; padding: 10px 0px; border-top: 1px solid #CBCBCB;">If you have any questions or concerns regarding this invoice, contact via email. <b style="color: 515151;"> Thank you! </b></td>
                    </tr>
                    </tbody>
                    </table> 
                    </td>
                    <td width="30px" style="vertical-align: top;"><img src="` +Rectangle1 +`" alt="" /></td>
                    </tr>
                    </tbody>
                    </table>
                    </td>
                    </tr>





                    </tbody>
                    </table>
                                
                </html>`

            if (resvalue[27] == "Bigwave") {
                // console.log(htmldemo);
                invoice_bw += ``;
                html2pdf(invoice_bw, {
                filename: resvalue[3]
                    .concat("-", resvalue[27])
                    .concat("-", "Invoice")
                    .concat("-", ioid),
                });
            } else if (resvalue[27] == "Lynkme") {
                invoice_lynkme += ``;
                html2pdf(invoice_lynkme, {
                filename: resvalue[3]
                    .concat("-", resvalue[27])
                    .concat("-", "Invoice")
                    .concat("-", ioid),
                });
            } else if (resvalue[27] == "Reddensoft") {
                invoice_rs += ``;
                html2pdf(invoice_rs, {
                filename: resvalue[3]
                    .concat("-", resvalue[27])
                    .concat("-", "Invoice")
                    .concat("-", ioid),
                });
            } else {
                console.log("error");
            }
            this.tostMessage("success", "Downloaded");

            // axios.post(BASE_URL + '/api/savesalarypdf',formData)
            //     .then((response)=>{
            //         console.log("uyertfgeusyyr",response.data.salary_details);
            //     })

        },

        async generateIO(io,id){
            console.log("id",id);
            console.log("io",io);
            console.log("newCategory",this.newCategory);
            console.log("categories",this.categories);
            await axios.post(BASE_URL + '/api/saveioDetails',{
                'io': io,'id':this.invoiceid, 'category':this.categories
            })
            .then((response)=>{
                this.io.company_name = '';
                this.io.company_address = '';
                this.io.contact_person_name = '';
                this.io.contact_person_reg_no = '';
                this.io.contact_person_email = '';
                this.io.web_app_name = '';
                this.io.start_date = '';
                this.io.end_date = '';
                this.io.placemnet = '';
                this.io.total_budget = '';
                this.io.solo_email_model = '';
                this.io.solo_email_rate = '';
                this.io.solo_email_quantity = '';
                this.io.solo_email_total = '';
                this.io.email_template_model = '';
                this.io.email_template_rate = '';
                this.io.email_template_quantity = '';
                this.io.email_template_total = '';
                this.closeiopopup();
                // this.showdealdetails();
            });
        },

        tostMessage(status,message){
            console.log("status",status);
                createToast({
                    // title: title,
                    description: message
                },
                {
                type: status,
                timeout: 2500,
                position: 'bottom-right',
                showIcon: 'true'
            })
        },
        
    },

}

</script>