<template>
    <div class="dashboard_box deal_dashboard">
        <div class="dashboard_right">
            <div class="dashboard_body">
                <div class="main_box">
                    <div class="top">
                        <div class="top_box">
                            <div class="name">
                                <h2>Insentive Details</h2>
                            </div>
                        </div>
                    </div>

                    <div class="name_insentive">
                        <h4>{{url_name}}
                            <span>( March )</span>
                        </h4>
                    </div>

                    <div class="table_box">
                        <div class="heading">
                            <h2>Taget Of Last Quarter</h2>
                        </div>
                        <div class="table_div">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>Months Name</th>
                                        <th>Target Amount</th>
                                        <th>Achived Amount</th>
                                        <th>Eligible For Incentive</th>
                                        <th>Incentive Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in tableData" :key="index">
                                        <td>
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            {{ row.monthsName }}
                                        </td>
                                        <td>
                                            {{ row.targetAmount }}
                                        </td>
                                        <td>
                                            {{ row.achivedAmount }}
                                        </td>
                                        <td>
                                            <span v-if="!row.isEditing">{{ row.eligibleForIncentive }}</span>
                                            <select v-else v-model="row.eligibleForIncentive">
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </td>
                                        <td>
                                            <span>{{ row.incentiveAmount }}</span>
                                        </td>
                                        <td>
                                            <div class="table_flex d-flex">
                                                <button class="btn-lps" @click="toggleEdit(row)">
                                                    {{ row.isEditing ? 'Update' : 'Edit' }}
                                                </button>
                                                <button class="btn-lps">
                                                    Save
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import "@/assets/css/dashboard.css"

export default {
    name: 'DealReport',
    data() {
        return {
            tableData: [
                {
                    monthsName: 'February',
                    targetAmount: "$8000",
                    achivedAmount: '$9000',
                    eligibleForIncentive: "yes",
                    incentiveAmount: "100",
                },
                {
                    monthsName: 'March',
                    targetAmount: "$8000",
                    achivedAmount: '$9000',
                    eligibleForIncentive: "yes",
                    incentiveAmount: "100",
                },
                {
                    monthsName: 'April',
                    targetAmount: "$8000",
                    achivedAmount: '$9000',
                    eligibleForIncentive: "yes",
                    incentiveAmount: "100",
                }
            ],

            year_selected:'',
            from_selected:'',
            to_selected:'',

            menuItems: [
                {
                    title: '2020',
                },
                {
                    title: '2021',
                },
                {
                    title: '2022',
                },
                {
                    title: '2023',
                },
                {
                    title: '2024',
                }
            ],
            frommenuItems: [
                {
                    title: 'January',
                },
                {
                    title: 'February',
                },
                {
                    title: 'March',
                },
                {
                    title: 'April',
                },
                {
                    title: 'May',
                },
                {
                    title: 'June',
                },
                {
                    title: 'July',
                },
                {
                    title: 'August',
                },
                {
                    title: 'September',
                },
                {
                    title: 'October',
                },
                {
                    title: 'November',
                },
                {
                    title: 'December',
                }
            ],
            tomenuItems: [
            {
                    title: 'January',
                },
                {
                    title: 'February',
                },
                {
                    title: 'March',
                },
                {
                    title: 'April',
                },
                {
                    title: 'May',
                },
                {
                    title: 'June',
                },
                {
                    title: 'July',
                },
                {
                    title: 'August',
                },
                {
                    title: 'September',
                },
                {
                    title: 'October',
                },
                {
                    title: 'November',
                },
                {
                    title: 'December',
                }
            ],
            url_id:'',
            url_name:'',
            url_status:'',
        }
    },
    async created(event){
        this.url_id = this.$route.params.id;
        this.url_name = this.$route.params.uname;
        this.url_status = this.$route.params.status;

    },
    methods: {
        toggleEdit(row) {
            row.isEditing = !row.isEditing;
        },
        handleItemClick(value) {
            this.year_selected = value;
        },
        handleItemClick1(value) {
            this.from_selected = value;
        },
        handleItemClick2(value) {
            this.to_selected = value;
        },
    }
}

</script>