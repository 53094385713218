import { createStore } from 'vuex';

export default createStore({
  state: {
    scrollPosition: 0, // Initial scroll position
  },
  mutations: {
    setScrollPosition(state, position) {
      state.scrollPosition = position;
    },
  },
});
