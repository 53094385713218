<template>
  <header>
    <div class="nav-cont-main">
      <a href="#" class="logo-brand">
        <img src="@/assets/images/logo/reddensoft_logo.svg" alt="" class="logo">
      </a>
      <div class="navbar-collapse rightalign">
        <ul class="navbar-link">
          
          <li>
            <router-link to="/adminaddnewemppage" class="a-n-emp">Add New Employee</router-link>
          </li>
          <li class="dropdown btn-drp">
            <button class="brn-notfy dropdown-toggle">
              <img src="@/assets/images/icon/bell.svg" alt="">
              <span class="notfy-count" >{{this.count}}</span>
            </button>
            <div class="dropdown-menu notification-cont">
              <div class="w-bg">
                <div class="dropdown-header">
                  <h6>Notification</h6>
                </div>
                
              <div class="simplebar-content">
        
                <a href="#" class="noty-list noty-list-new" v-for="user in notificationdata" :key="user" >
               
                  <div class="ttx" v-if="user.type == 'WFH' " >
                    <button :style="'background : ' + (user.status == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">
                      <img v-if="user.image!=''" v-bind:src="user.image" alt="profile-image">
                      <img v-else src="@/assets/profile-image.png" alt="profile-image" >

                      <div class="notification_right">
                        <div class="notification_right_l">
                     <b> {{user.empname}} has applied for {{user.type}} </b>
                      <p>{{user.created_time}}</p>
                      </div> 
                      <div class="notification_right_r">
                      <p>{{user.created_at}}</p>
                    </div>
                     </div>
                      
                    </button>
                  </div>                      
                  <div class="ttx" v-if="user.type == 'Casual Leave'">
                    <button :style="'background: ' + (user.status == 'seen' ? '' : 'rgb(198 213 222)') " @click="onLeaveClick(user.empid, user.date, user.type)">
                      <img v-if="user.image!=''" v-bind:src="user.image" alt="">
                      <img v-else src="@/assets/profile-image.png" alt="profile-image" >

                      <div class="notification_right">
                        <div class="notification_right_l">
                          <b> {{user.empname}} has applied for {{user.type}} </b>
                      <p>{{user.created_time}}</p>
                    </div> 
                      <div class="notification_right_r">
                      <p>{{user.created_at}}</p>
                    </div> 
                  </div> 
                    </button>
                  </div>
                  <div class="ttx" v-if="user.type == 'Medical Leave' ">
                    <button :style="'background: ' + (user.status == 'seen' ? '' : 'rgb(198 213 222)') " @click="onLeaveClick(user.empid, user.date, user.type)">
                      <img v-if="user.image!=''" v-bind:src="user.image" alt="">
                      <img v-else src="@/assets/profile-image.png" alt="profile-image" >
                      <div class="notification_right">
                        <div class="notification_right_l">
                          <b>  {{user.empname}} has applied for {{user.type}} </b>
                      <p>{{user.created_time}}</p>
                    </div> 
                      <div class="notification_right_r">
                      <p>{{user.created_at}}</p>
                    </div> 
                  </div>
                    </button>
                  </div>
                  <div class="ttx" v-if="user.type == 'Provisional' ">
                    <button :style="'background: ' + (user.status == 'seen' ? '' : 'rgb(198 213 222)') " @click="onLeaveClick(user.empid, user.date, user.type)">
                      <img v-if="user.image!=''" v-bind:src="user.image" alt="">
                      <img v-else src="@/assets/profile-image.png" alt="profile-image" >
                      <div class="notification_right">
                        <div class="notification_right_l">
                          <b>  {{user.empname}} has applied for {{user.type}} Leave </b>
                      <p>{{user.created_time}}</p>
                    </div> 
                      <div class="notification_right_r">
                      <p>{{user.created_at}}</p>
                    </div> 
                  </div>
                    </button>
                  </div>
                  <div class="ttx" v-if="user.type == 'Date Change' ">
                    <button :style="'background: ' + (user.status == 'seen' ? '' : 'rgb(198 213 222)') " @click="onDateChangeClick(user.empid, user.date, user.type)">
                      <img v-if="user.image!=''" v-bind:src="user.image" alt="">
                      <img v-else src="@/assets/profile-image.png" alt="profile-image" >
                      <div class="notification_right">
                        <div class="notification_right_l">
                          <b>   {{user.empname}} has applied for {{user.type}} Leave </b>
                    </div>
                      <p>{{user.created_time}}</p>
                      <div class="notification_right_r">
                      <p>{{user.created_at}}</p>
                    </div> 
                  </div>
                    </button>
                  </div>
                   
                </a>  
                <!-- <div class="noty-all">
              </div>   
                 -->
              <button @click="showMoreNotification()">See More..</button>
                
                    <!-- <p v-if="notificationdata.length == 0">No new notifications</p> -->
                  
                
              
      
              </div>
              <!-- <div class="noty-all">
                <button @click="onClick()">View all</button>
              </div> -->
              </div>
              
            </div>
          </li>
          <li class="dropdown wlcm-box">
            <button class="dropdown-toggle user-set">
              <img src="@/assets/images/men.png" alt="">
            </button>
              <div class="dropdown-menu notification-cont">
                <div class="w-bg admin-log-box">
                  <h6>Welcome !</h6>
                <ul>
                  <!-- <li>
                    <a href="#">
                      <img src="@/assets/images/icon/person-circle.svg" alt="">
                      <span>My Account</span>
                    </a>
                  </li> -->
                  <li>
                    <a href="#" v-on:click="logout">
                      <img src="@/assets/images/icon/box-arrow-in-left.svg" alt="">
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
                </div>
              </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";

const mirror = createLocal()
const storage = mirror._prx

export default {
  name: 'HeaderAdmin',
  components:{
      
  },
  data() {
      return {
          storageData: mirror,
          storage: storage,
          isHovering: false,
          isHovering1 : false,
          notificationdata:'',
          count:'0',
          fetchednotificationcount:'',
          intervalId: null,
      }
  },
  methods :{
     logout(){
         localStorage.clear();
         this.$router.push('/');
     },
     async onWfhClick(id,date,type){
      const res=await axios.post(BASE_URL + '/api/changenotificationstatus',{id: id, date: date, type: type});
      const res1=await axios.post(BASE_URL + '/api/fetchnotifications');
      this.notificationdata = res1.data.notificationdata;
      // this.$router.push({name: 'AdminWfhDetailsPage', params: { id: id, date:date, type:type }});
      this.$router.push({name: 'AdminWfhDetailsPage', hash: '#'+id+','+date});
      const res2=await axios.post(BASE_URL + '/api/getnotifiactioncount');
      this.count = res2.data.count.length;
   
     },
    async onLeaveClick(id,date,type){
      const res=await axios.post(BASE_URL + '/api/changenotificationstatus',{id: id, date: date, type: type});
      const res2=await axios.post(BASE_URL + '/api/fetchnotifications');
      this.notificationdata = res2.data.notificationdata;
      this.$router.push({name: 'AdminLeaveStatus',hash: '#'+id+','+date});
      const res1=await axios.post(BASE_URL + '/api/getnotifiactioncount');
      this.count = res1.data.count.length;
     },

     async onDateChangeClick(id,date,type){
      const res=await axios.post(BASE_URL + '/api/changenotificationstatus',{id: id, date: date, type: type});
      const res2=await axios.post(BASE_URL + '/api/fetchnotifications');
      this.notificationdata = res2.data.notificationdata;
      var date_split = date.split(",");
      var datetobechanged = date_split[0];
      this.$router.push({name: 'AdminDateChangeStatusPage',hash: '#'+id+','+datetobechanged});
      const res1=await axios.post(BASE_URL + '/api/getnotifiactioncount');
      this.count = res1.data.count.length;
     },

     async onClick(){
      const result=await axios.post(BASE_URL + '/api/fetchnotifications');
      this.notificationdata = res2.data.allnotificationdata;

     },

     async showMoreNotification(){
      const notificationdata = {
                  count: this.fetchednotificationcount,
              };

      const res=await axios.post(BASE_URL + '/api/fetchnotifications',notificationdata);
      this.notificationdata = res.data.notificationdata;
      this.fetchednotificationcount = res.data.fetchednotificationcount;
     },

     async checkLoginCredentials() {
      try {
        const response = await axios.post(BASE_URL + '/api/checklogincredentials', {
          email: mirror.USEREMAIL,
          password: mirror.USERPASSWORD,
        });
        if (response.data.success == false) {
          this.logout();
        }
      } catch (error) {
        console.error("Error checking login credentials:", error);
      }
    }


  },
  async created(){
      // this.checkLoginCredentials();
      this.userName = mirror.USERNAME;
      const res=await axios.post(BASE_URL + '/api/fetchnotifications');
      this.notificationdata = res.data.notificationdata;
      this.fetchednotificationcount = res.data.fetchednotificationcount;
      console.log("notifyyyyyyyy",this.notificationdata);
      const res1=await axios.post(BASE_URL + '/api/getnotifiactioncount');
      this.count = res1.data.count.length;
      // console.log("csgvfyc",res1.data);
 },
  beforeRouteEnter(to, from, next) {
  //console.log(storage.get('TOKEN'));
      if (storage.get('TOKEN')) {
          next();
      }
      else {
          next('/');
      }
  },
  async mounted() {
    this.intervalId = setInterval(this.checkLoginCredentials, 60000); // 1 minute
    // await this.fetchNotificationData();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
}
</script>
<style scoped> 

.ttx button img  {
width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}
</style>