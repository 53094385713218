<template>
  <div class="dashboard_body main_scroll">
    <div class="main-right">
      <div class="main-title">
      <!-- <h1>{{this.userName}} {{this.comma}}<span class="dstion">{{this.position}}</span> </h1>        -->
      <h2>Attendance Record for {{this.month_name}}{{this.space}}{{this.year}}</h2>
      </div>
      <div class="use-id-frm user_border">
        <ul>
          <li>Email: 
            <div class="value-id"> {{this.userEmail}}</div>
          </li>
          <li>Employee ID: 
            <div class="value-id">{{this.empId}}</div>
          </li>
          <li v-if="emp_status=='Permanent'">Yearly Casual Leave: 
            <div class="value-id">{{yearly_leave_count}}/{{casual_leave}}</div>
          </li>
          <li v-if="emp_status=='Permanent'">Medical Leave: 
            <div class="value-id">{{yearly_medical_count}}/{{sick_leave}}</div>
          </li>
        </ul>
      </div>
      <div class="leave-st-main">
        <div class=" use-id-frm user_border mt-4 mb-4">

          <div class="btn-gpt">
            <button @click="isOpen = true">Apply for Leave</button>

            <button @click="isOpenWfh = true">Apply for WFH</button>

            <button @click="isOpenDateChange = true">Apply for Date Change</button>

            
            <!-- <button v-if="downloadstatus==null" type="button" :disabled="buttoncheckfordisable" @click="exportToPDF(this.ID)">Salary Slip <img src="@/assets/images/icon/download.svg" alt=""></button>
            <button v-else type="button"  @click="exportToPDF(this.ID)">Salary Slip <img src="@/assets/images/icon/download.svg" alt=""></button> -->

            <button v-show="isDesiredMonth" v-if="emp_status=='Permanent'" @click="isOpenCarryForward = true">Carry Forward Leave</button>
          </div>
          
          <ul class="timer_log">
            <li>
              {{currentdateandtime}}
            </li>   
            
            <!-- <div class="timer">
              <button class="timerdiv" @click="added(),timer(text)" >
                  <div class="clock_box">
                      <img src="@/assets/dashboard/clock1.gif" alt="clock1" v-if = "text == 'Check Out'" />
                      <img src="@/assets/dashboard/clock2.png" alt="clock2" v-else />
                  </div>
                  <div class="times_box">
                      <h3>
                          <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                      </h3>
                      <div>
                          <p v-if="text==='Loading..'" >{{ text}}</p>
                          <p v-else >{{ text}}</p>
                      </div>
                  </div>
              </button>
            </div> -->
            
            <!-- <li>
              <div class="task-timer">
                <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
              </div>
            </li>

            <li>
              <button v-if="text==='Loading..'" :disabled="buttoncheckfordisable" @click="added(),timer(text)" >{{ text}}</button>
              <button v-else @click="added(),timer(text)" >{{ text}}</button>
            </li> -->

          </ul>
        </div>
        <div class="user-leave-st">
          <div class="item-card">
            <div class="cr-title">
              <h5>{{this.month_name}}  Present</h5>
            </div>
            
            <div class="count-nb">
              <img src="@/assets/images/icon/present1.svg" alt="">
              {{this.monthly_attendance_record_latest}}
            </div>
            <ul class="dtl">
              <li>total number of working days:  {{(this.total_weekdays_monthly - this.commonDates)}}</li> 
            </ul>
          </div>
          <div class="item-card" v-if="emp_status=='Permanent'">
            <div class="cr-title">
              <h5>Casual Leave</h5>
            </div>
            <div class="count-nb">
              <img src="@/assets/images/icon/casual-leave1.svg" alt="">
              {{casual_leave}}
            </div>
            <ul class="dtl">
              <li>Yearly Leaves Available: <span>{{yearly_leave_count}}</span></li>
              <li>Monthly Available: <span>{{max_paid_leave}}</span></li>
              <li>Monthly Taken: <span>{{monthly_leave_taken}}</span></li>
              <li>Unpaid Leaves Taken: <span>{{yearly_unpaid_count}}</span></li>
      
            </ul>
          </div>
          <div class="item-card" v-if="emp_status=='Permanent'">
            <div class="cr-title">
              <h5>Yearly Medical Leave</h5>
            </div>
            <div class="count-nb">
              <img src="@/assets/images/icon/medical1.svg" alt="">
              {{sick_leave}}
            </div>
            <ul class="dtl">
              <li>Available: <span>{{yearly_medical_count}}</span></li>
              <li>Taken: <span>{{ sick_leave - yearly_medical_count}}</span></li>
            </ul>
          </div>
          <div class="item-card" v-if="emp_status=='Provisional'">
            <div class="cr-title">
              <h5>Total Leave</h5>
            </div>
            <div class="count-nb">
              <img src="@/assets/images/icon/casual-leave1.svg" alt="">
    <!-- 
              <li v-if="provisional_leave_count!=''">{{provisional_leave_count}}</li>
              <li v-else>0</li> -->
              {{provisional_leave_count}}
            </div>
          </div>
          <div class="item-card">
            <div class="cr-title">
              <h5>{{this.month_name}} Hours Worked </h5>
            </div>
            <div class="count-nb">
              <img src="@/assets/images/icon/hours1.svg" alt="">
              {{monthly_hours}}
            </div>
            <ul class="dtl">
              <li>total number of days worked: {{this.monthly_attendance_record_fullday.length + this.monthly_attendance_record_halfday.length/2}}</li>
            
            </ul>
          </div>
          <div class="item-card">
            <div class="cr-title">
              <h5>{{this.month_name}} Absent</h5>
            </div>
            <div v-if="checkinstatusforabsentcount==null" class="count-nb">
              <img src="@/assets/images/icon/year-absent1.svg" alt="">
              
              {{this.monthly_absense_count_before_checkout}}
            </div>
            <div v-if="checkinstatusforabsentcount!=null" class="count-nb">
              <img src="@/assets/images/icon/year-absent1.svg" alt="">
              
              {{this.monthly_absense_count}}
            </div>
            <!-- <ul class="dtl">
              <li>without Leave</li>
            </ul> -->
          </div>
        </div>
        
      </div>
      <div class="table-section full-table">
        <div class="table-wapper">
          
          <div class="table-data">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header accord-title" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <h3>Attendance Record</h3>
                </button>
                </div>
              <div v-if="this.notifiedType != ''" >
                <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Date </th>
                          <th>From</th>
                          <th>To</th>
                          <th>Hrs</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in userList" :key="user">
                          <td :style="'background: ' + (this.holiday_array_formatted.includes(user.attendance_date)  ? 'rgb(231 209 191)' : '') ">{{moment(user.attendance_date).format('DD-MM-YYYY')}}</td>
                          <td>{{user.start_time}}</td>
                          <td>{{user.end_time}}</td>
                          <td>{{user.hourly_works}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-show="userList == 0">
                      <p class="no_record_div">No record found</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Date </th>
                          <th>From</th>
                          <th>To</th>
                          <th>Hrs</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="user in userList" :key="user">
                          <td :style="'background: ' + (this.holiday_array_formatted.includes(user.attendance_date)  ? 'rgb(231 209 191)' : '') ">{{moment(user.attendance_date).format('DD-MM-YYYY')}}</td>
                          <td>{{user.start_time}}</td>
                          <td>{{user.end_time}}</td>
                          <td>{{user.hourly_works}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-show="userList == 0">
                      <p class="no_record_div">No record found</p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="accordion-item">
                <div v-if="this.notifiedType == 'Medical%20Leave'" >
                    <div class="accordion-header accord-title" id="headingTwo">
                      <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      <h3>Leave Record</h3>
                      </button>
                    </div>
                    <div id="collapseTwo" class="accordion-collapse collapse show"  aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body answer">
                        <table cellspacing="0" cellpadding="0" border="0">
                          <thead>
                            <tr>
                              <th>Leave Type Applied</th>
                              <th>Leave Date</th>
                              <th>Leave Details</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody v-for="(row) in leaverecord" :key="row" :style="'background: ' + (this.notifiedType == 'Medical Leave'||'Casual Leave' && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                            <tr>
                              <td>{{row.leave}}</td>
                              <td>{{row.leave_date}}</td>
                              <td>
                                <table cellspacing="0" cellpadding="0" border="0">
                                <thead>
                                  <tr>
                                    <th>Rendered As</th>
                                    <th>Leave Date</th>
                                  </tr>
                                </thead>
                                <tbody  >
                                  <tr v-for="(data) in row.leavedetails" :key="data">
                                    <td>{{data.leavetype}}</td>
                                    <td>{{data.alldateleaves}}</td>
                                  </tr>
                                  <tr v-show="row.leavedetails == 0"><tr>Please wait for admin to respond.</tr></tr>

                                </tbody>
                              </table>
                            </td>
                              <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
                              <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
                              <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
                              <td v-if="row.leavestatus == 'Pending'"><button class="cancel-btn" type="button"  @click="cancelleave(row.id)">Cancel Leave</button></td>
                              <td v-else><button  type="button" :disabled="buttoncheckfordisable" >Cancel Leave</button></td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-show="leaverecord == 0">
                          <p class="no_record_div">
                            No Data Available
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
              <div v-else-if="this.notifiedType == 'Casual%20Leave'" >

    <div class="accordion-header accord-title" id="headingTwo">
    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
    <h3>Leave Record</h3>
    </button>
    </div>

    <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Leave Type Applied</th>
                          <th>Leave Date</th>
                          <th>Leave Details</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-for="(row) in leaverecord" :key="row" :style="'background: ' + (this.notifiedType == 'Medical Leave'||'Casual Leave' && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                        <tr>

                          <td>{{row.leave}}</td>
                          <td>{{row.leave_date}}</td>
                          <td>
                            <table cellspacing="0" cellpadding="0" border="0">
                            <thead>
                              <tr>
                                <th>Rendered As</th>
                                <th>Leave Date</th>
                              </tr>
                            </thead>
                            <tbody  >
                              <tr v-for="(data) in row.leavedetails" :key="data">
                                <td>{{data.leavetype}}</td>
                                <td>{{data.alldateleaves}}</td>
                              </tr>
                              <tr v-show="row.leavedetails == 0"><tr>Please wait for admin to respond.</tr></tr>

                            </tbody>
                          </table>
                        </td>
                          <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Pending'"><button class="cancel-btn" type="button"  @click="cancelleave(row.id)">Cancel Leave</button></td>
                          <td v-else><button  type="button" :disabled="buttoncheckfordisable" >Cancel Leave</button></td>
                        </tr>
                      </tbody>
                      <!-- <tbody v-show="leaverecord == 0"><tr>No Data Available</tr></tbody> -->
                    </table>
                    <div v-show="leaverecord == 0">
                      <p class="no_record_div">
                        No Data Available
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-else-if="this.notifiedType == 'Unpaid'" >

    <div class="accordion-header accord-title" id="headingTwo">
    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
    <h3>Leave Record</h3>
    </button>
    </div>

    <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
    <div class="accordion-body answer">
    <table cellspacing="0" cellpadding="0" border="0">
      <thead>
        <tr>
          <th>Leave Type</th>
          <th>Leave Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody v-for="(row) in leaverecord" :key="row" :style="'background: ' + (this.notifiedType == 'Medical Leave'||'Casual Leave' && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
        <tr>
          <td>{{row.leave}}</td>
          <td>{{row.leave_date}}</td>
          <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
          <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
          <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
        </tr>
      </tbody>
      <tbody v-show="leaverecord == 0"><tr>No Data Available</tr></tbody>
    </table>
    </div>
    </div>
              </div> -->

              <div v-else-if="this.notifiedType == 'Provisional'" >

    <div class="accordion-header accord-title" id="headingTwo">
    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
    <h3>Leave Record</h3>
    </button>
    </div>

    <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Leave Type Applied</th>
                          <th>Leave Date</th>
                          <th>Leave Details</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-for="(row) in leaverecord" :key="row" :style="'background: ' + (this.notifiedType == 'Medical Leave'||'Casual Leave' && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                        <tr>

                          <td>{{row.leave}}</td>
                          <td>{{row.leave_date}}</td>
                          <td>
                            <table cellspacing="0" cellpadding="0" border="0">
                            <thead>
                              <tr>
                                <th>Rendered As</th>
                                <th>Leave Date</th>
                              </tr>
                            </thead>
                            <tbody  >
                              <tr v-for="(data) in row.leavedetails" :key="data">
                                <td>{{data.leavetype}}</td>
                                <td>{{data.alldateleaves}}</td>
                              </tr>
                              <tr v-show="row.leavedetails == 0"><tr>Please wait for admin to respond.</tr></tr>

                            </tbody>
                          </table>
                        </td>
                          <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Pending'"><button class="cancel-btn" type="button"  @click="cancelleave(row.id)">Cancel Leave</button></td>
                          <td v-else><button  type="button" :disabled="buttoncheckfordisable" >Cancel Leave</button></td>
                        </tr>
                      </tbody>
                      <!-- <tbody v-show="leaverecord == 0"><tr>No Data Available</tr></tbody> -->
                    </table>
                    <div v-show="leaverecord == 0">
                      <p class="no_record_div">
                        No Data Available
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
                <div v-else >

                  <div class="accordion-header accord-title" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <h3>Leave Record</h3>
                  </button>
                  </div>

                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Leave Type Applied</th>
                          <th>Leave Date</th>
                          <th>Leave Details</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-for="(row) in leaverecord" :key="row" :style="'background: ' + (this.notifiedType == 'Medical Leave'||'Casual Leave' && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                        <tr>

                          <td>{{row.leave}}</td>
                          <td>{{row.leave_date}}</td>
                          <td>
                            <table cellspacing="0" cellpadding="0" border="0">
                            <thead>
                              <tr>
                                <th>Rendered As</th>
                                <th>Leave Date</th>
                              </tr>
                            </thead>
                            <tbody  >
                              <tr v-for="(data) in row.leavedetails" :key="data">
                                <td>{{data.leavetype}}</td>
                                <td>{{data.alldateleaves}}</td>
                              </tr>
                              <tr v-show="row.leavedetails == 0"><tr>Please wait for admin to respond.</tr></tr>

                            </tbody>
                          </table>
                        </td>
                          <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Pending'"><button class="cancel-btn" type="button"  @click="cancelleave(row.id)">Cancel Leave</button></td>
                          <td v-else><button  type="button" :disabled="buttoncheckfordisable" >Cancel Leave</button></td>
                        </tr>
                      </tbody>
                      <!-- <tbody v-show="leaverecord == 0"><tr>No Data Available</tr></tbody> -->
                    </table>
                    <div v-show="leaverecord == 0">
                      <p class="no_record_div">
                        No Data Available
                      </p>
                    </div>
                  </div>
                </div>
                </div>

                <!-- <div v-if="this.notifiedType != 'Casual%20Leave'" >

                  <div class="accordion-header accord-title" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <h3>Leave Record</h3>
                  </button>
                  </div>

                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Leave Type</th>
                          <th>Leave Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody v-for="(row) in leaverecord" :key="row" :style="'background: ' + (this.notifiedType == 'Medical Leave'||'Casual Leave' && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                        <tr>
                          <td>{{row.leave}}</td>
                          <td>{{row.leave_date}}</td>
                          <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
                        </tr>
                      </tbody>
                      <tbody v-show="leaverecord == 0"><tr>No Data Available</tr></tbody>
                    </table>
                  </div>
                </div>
                </div>
                <div v-else>

                  <div class="accordion-header accord-title" id="headingTwo">
                  <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  <h3>Leave Record</h3>
                  </button>
                  </div>

                  <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Leave Type</th>
                          <th>Leave Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody v-for="(row) in leaverecord" :key="row" :style="'background: ' + (this.notifiedType == 'Medical Leave'||'Casual Leave' && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                        <tr>
                          <td>{{row.leave}}</td>
                          <td>{{row.leave_date}}</td>
                          <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
                        </tr>
                      </tbody>
                      <tbody v-show="leaverecord == 0"><tr>No Data Available</tr></tbody>
                    </table>
                  </div>
                </div>
                </div> -->

              </div>
              <div class="accordion-item">

              <div v-if="this.notifiedType != 'WFH'" >
                <div class="accordion-header accord-title" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <h3>WFH Record</h3>
                  </button>
                </div>

                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                          <tr>
                            <th>WFH Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody v-for="(row) in wfhrecord" :key="row" :style="'background: ' + (this.notifiedType == 'WFH' && row.wfh_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                          <tr>
                            <td>{{row.wfh_date}}</td>
                            <td v-if="row.wfh_status == 'Pending'"> <span class="status pendin"></span> {{row.wfh_status}}</td>
                            <td v-if="row.wfh_status == 'Rejected'"> <span class="status pendin"></span> {{row.wfh_status}}</td>
                            <td v-if="row.wfh_status == 'Approved'"> <span class="status aprvd"></span> {{row.wfh_status}}</td>
                          </tr>
                        </tbody>
                        <!-- <tbody v-show="wfhrecord == 0"><tr>No Data Available</tr></tbody> -->
                      </table>
                      <div v-show="wfhrecord == 0">
                        <p class="no_record_div">
                          No Data Available
                        </p>
                      </div>
                  </div>
                </div>
                </div>
                <div v-else>

                  <div class="accordion-header accord-title" id="headingThree">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                    <h3>WFH Record</h3>
                  </button>
                  </div>

                  <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body answer">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                          <tr>
                            <th>WFH Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody v-for="(row) in wfhrecord" :key="row" :style="'background: ' + (this.notifiedType == 'WFH' && row.wfh_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                          <tr>
                            <td>{{row.wfh_date}}</td>
                            <td v-if="row.wfh_status == 'Pending'"> <span class="status pendin"></span> {{row.wfh_status}}</td>
                            <td v-if="row.wfh_status == 'Rejected'"> <span class="status pendin"></span> {{row.wfh_status}}</td>
                            <td v-if="row.wfh_status == 'Approved'"> <span class="status aprvd"></span> {{row.wfh_status}}</td>
                          </tr>
                        </tbody>
                        <!-- <tbody v-show="wfhrecord == 0"><tr>No Data Available</tr></tbody> -->
                      </table>
                      <div v-show="wfhrecord == 0">
                        <p class="no_record_div">
                          No Data Available
                        </p>
                      </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="accordion-item">

              <div v-if="this.notifiedType != 'Date%20Change'" >

                <div class="accordion-header accord-title" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <h3>Date Change Record</h3>
                    </button>
                  </div>

                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body answer">
                      <table cellspacing="0" cellpadding="0" border="0">
                        <thead>
                          <tr>
                            <th>Date To Be Changed</th>
                            <th>Requested Date To Work On</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody v-for="(row) in datechangerecord" :key="row" :style="'background: ' + (this.notifiedType == 'Date%20Change' && row.datetobechanged == this.notifiedDate ? 'rgb(198 213 222)' : '') ">
                          <tr>
                            <td>{{row.datetobechanged}}</td>
                            <td>{{row.changeddate}}</td>
                            <td v-if="row.status == 'Pending'"> <span class="status pendin"></span> {{row.status}}</td>
                            <td v-if="row.status == 'Rejected'"> <span class="status pendin"></span> {{row.status}}</td>
                            <td v-if="row.status == 'Approved'"> <span class="status aprvd"></span> {{row.status}}</td>
                          </tr>
                        </tbody>
                        <!-- <tbody v-show="datechangerecord == 0"><tr>No Data Available</tr></tbody> -->
                      </table>
                      <div v-show="datechangerecord == 0">
                        <p class="no_record_div">
                          No Data Available
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div v-else>

                <div class="accordion-header accord-title" id="headingFour">
                    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                      <h3>Date Change Record</h3>
                    </button>
                  </div>

                <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body answer">
                      <table cellspacing="0" cellpadding="0" border="0">
                        <thead>
                          <tr>
                            <th>Date To Be Changed</th>
                            <th>Requested Date To Work On</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody v-for="(row) in datechangerecord" :key="row" :style="'background: ' + (this.notifiedType == 'Date%20Change' && row.datetobechanged == this.notifiedDate ? 'rgb(198 213 222)' : '') ">
                          <tr>
                            <td>{{row.datetobechanged}}</td>
                            <td>{{row.changeddate}}</td>
                            <td v-if="row.status == 'Pending'"> <span class="status pendin"></span> {{row.status}}</td>
                            <td v-if="row.status == 'Rejected'"> <span class="status pendin"></span> {{row.status}}</td>
                            <td v-if="row.status == 'Approved'"> <span class="status aprvd"></span> {{row.status}}</td>
                          </tr>
                        </tbody>
                        <!-- <tbody v-show="datechangerecord == 0"><tr>No Data Available</tr></tbody> -->
                      </table>
                      <div v-show="datechangerecord == 0">
                        <p class="no_record_div">
                          No Data Available
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
                </div>
            
            </div>
          </div>
        </div>
      </div>
      <div>
        <ModalApplyForLeave :open="isOpen" @close="isOpen = ! isOpen">
                  <div class="popup-modal" id="popupModa1">
                          <div class="mp-content">
                            <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal1('close')">
                              <img src="@/assets/images/icon/x-lg.svg" alt="">
                            </button>
                            <div class="bdy-sect">
                              <h3>leave Application</h3>
                              <form  @submit.prevent="onSubmitLeave">
                                  <div v-if="errormessage != ''" class="errormessage">
                                      {{ errormessage }}
                                  </div>
                                  <div v-if="successmessage != ''" class="successmessage">
                                      {{ successmessage }}
                                  </div>
                                <div class="fr-grp" v-if="emp_status == 'Permanent'">
                                    <div class="leaveRightLabel">
                                        <label>Leave Type:</label>
                                    </div>
                                    <div class="leaveRightFill">
                                        <select v-model="leave">
                                            <option value="" >Select One</option>
                                            <option value="Casual Leave">Casual Leave</option>
                                            <option value="Medical Leave">Medical Leave</option>
                                        </select>
                                        <span v-if="errormessage_leave!=''" class="errormessage valid_msg">{{ errormessage_leave }}</span>
                                    </div>
                                </div>
                                <div class="fr-grp">
                                  <div class="ipm">
                                    <img src="@/assets/images/icon/calendar-plus.png" alt="">
                                  </div>
                                  <!-- <input type="text" class="date" placeholder="Pick the multiple dates"> -->
                                    <!-- <Datepicker v-model="leavedate"  class="date" placeholder="Pick the multiple dates"  multiDates></Datepicker> -->
                                    <Datepicker v-model="leavedate"  class="date" placeholder="Pick the multiple dates"  :disabledDates="(date) => date <= new Date(new Date().getTime() - 24*60*60*1000)" multiDates></Datepicker>
                                    
                                    <span v-if="errormessage_leavedate!=''" class="errormessage valid_msg">{{ errormessage_leavedate }}</span>
                                          
                                </div>
                                
                                
                                <div class="fr-grp">
                                  <textarea v-model="reason" name="" id="" placeholder="Reason" cols="30" rows="06"></textarea>
                                  <span v-if="errormessage_reason!=''" class="errormessage valid_msg" @keydown.space="preventLeadingSpace">{{ errormessage_reason }}</span>
                                </div>
                                <div class="fr-grp">
                                  <div class="containerFile">
                                    <label class="label" for="input" v-show="noneupload">Please upload your Document !</label>


                                    <label class="label" for="input" v-if="successmessage1 != ''"> {{ successmessage1 }}</label>

                                    <!-- <div v-if="successmessage1 != ''" class="successmessage">
                                        {{ successmessage1 }}
                                    </div> -->
                                  
                                    <div class="input">
                                      <input type="file" id="file"  @change="alldoc($event)" name="select_file" style="display:none;" />
                                      <a href="#" class="uploadBtn" @click="thisFileUpload();">Upload</a>
                                    </div> 
                                  </div>
                                </div>
                                <div class="frg-inline">
                                  <button type="button" class="btn clearBtns" @click="cancel()">Clear</button>
                                  <button  type="submit" class="btn submitBtns">Submit</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
        </ModalApplyForLeave>
        <!--  -->

        <ModalApplyForDateChange :opendatechange="isOpenDateChange" @close="isOpenDateChange = ! isOpenDateChange">
            <div class="popup-modal" id="popupModa1">
                    <div class="mp-content">
                      <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal3('close')">
                        <img src="@/assets/images/icon/x-lg.svg" alt="">
                      </button>
                      <div class="bdy-sect">
                        <h3>Date Change Application</h3>
                        <div class="item-cr">
                          <form  @submit.prevent="onSubmitDateChange">
                              <div v-if="errormessageDateChange != ''" class="errormessage">
                                  {{ errormessageDateChange }}
                              </div>
                              <div v-if="successmessageDateChange != ''" class="successmessage">
                                  {{ successmessageDateChange }}
                              </div>
                            <div class="fr-grp">
                              <!-- <div class="ipm">
                                <img src="@/assets/images/icon/calendar-plus.png" alt="">
                              </div> -->
                              <!-- <input type="text" class="date" placeholder="Pick the multiple dates"> -->
                                <Datepicker v-model="datetobechanged" class="date" placeholder="Pick the date you want to change" :disabledDates="(date) => date <= new Date(new Date().getTime() - 24*60*60*1000)"></Datepicker>
                                <span v-if="errormessage_datechange!=''" class="errormessage valid_msg">{{ errormessage_datechange }}</span>

                                <Datepicker v-model="changeddate" class="date" placeholder="Pick the new date you want to work on instead" :disabledDates="(date) => date <= new Date(new Date().getTime() - 24*60*60*1000)"></Datepicker>
                                
                                <span v-if="errormessage_datechange1!=''" class="errormessage valid_msg">{{ errormessage_datechange1 }}</span>
                                      
                            </div>

                            
                            <div class="fr-grp">
                              <textarea v-model="reasondatechange" name="" id="" placeholder="Reason" cols="30" rows="06"></textarea>
                              <span v-if="errormessage_reason_datechange!=''" class="errormessage valid_msg">{{ errormessage_reason_datechange }}</span>
                            </div>
                            <div class="fr-grp">
                              <div class="containerFile">
                                <label class="label" for="input" v-show="noneuploaddatechange">Please upload your Document !</label>


                                <label class="label" for="input" v-if="successmessageDateChange1 != ''"> {{ successmessageDateChange1 }}</label>

                                <!-- <div v-if="successmessage1 != ''" class="successmessage">
                                    {{ successmessage1 }}
                                </div> -->
                              
                                <div class="input">
                                  <input type="file" id="filedatechange"  @change="alldocdatechange($event)" name="select_file" style="display:none;" />
                                  <a href="#" class="uploadBtn" @click="thisFileUploaddatechange();">Upload</a>
                                </div> 
                              </div>
                            </div>
                            <div class="frg-inline">
                              <button type="button" class="btn clearBtns" @click="canceldatechange()">Clear</button>
                              <button  type="submit" class="btn submitBtns">Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
        </ModalApplyForDateChange>
        <!--  -->
        <ModalApplyForWfh :openwfh="isOpenWfh" @close="isOpenWfh = ! isOpenWfh">
          <div class="popup-modal" id="popupModa1">
            <div class="mp-content">
              <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal2('close')">
                <img src="@/assets/images/icon/x-lg.svg" alt="">
              </button>
              <div class="bdy-sect">
                <h3>WFH Application</h3>
                <form  @submit.prevent="onSubmitLeaveWfh">
                    <div v-if="errormessagewfh != ''" class="errormessage">
                        {{ errormessagewfh }}
                    </div>
                    <div v-if="successmessagewfh != ''" class="successmessage">
                        {{ successmessagewfh }}
                    </div>
                  <div class="fr-grp">
                    <div class="ipm">
                      <img src="@/assets/images/icon/calendar-plus.png" alt="">
                    </div>
                    <!-- <input type="text" class="date" placeholder="Pick the multiple dates"> -->
                      <Datepicker v-model="wfhdate" class="date" placeholder="Pick the multiple dates" :disabledDates="(date) => date <= new Date(new Date().getTime() - 24*60*60*1000)" multiDates></Datepicker>
                      
                  <span v-if="errormessage_wfhdate!=''" class="errormessage valid_msg">{{ errormessage_wfhdate }}</span>
                            
                  </div>
                  
                  <div class="fr-grp">
                    <textarea v-model="reasonwfh" name="" id="" placeholder="Reason" cols="30" rows="06"></textarea>
                    <span v-if="errormessage_reasonwfh!=''" class="errormessage valid_msg">{{ errormessage_reasonwfh }}</span>
                  </div>
                  <div class="fr-grp">
                    <div class="containerFile">
                      <label class="label" for="input" v-show="noneuploadwfh">Please upload your Document !</label>


                      <label class="label" for="input" v-if="successmessagewfh1 != ''"> {{ successmessagewfh1 }}</label>

                      <!-- <div v-if="successmessage1 != ''" class="successmessage">
                          {{ successmessage1 }}
                      </div> -->
                    
                      <div class="input">
                        <input type="file" id="filewfh"  @change="alldocwfh($event)" name="select_file" style="display:none;" />
                        <a href="#" class="uploadBtn" @click="thisFileUploadwfh();">Upload</a>
                      </div> 
                    </div>
                  </div>
                  <div class="frg-inline">
                    <button type="button" class="btn clearBtns" @click="cancelwfh()">Clear</button>
                    <button  type="submit" class="btn submitBtns">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ModalApplyForWfh>

        <ModalApplyForLeaveCarryforward :applycarryforward="isOpenCarryForward" @close="isOpenCarryForward = ! isOpenCarryForward">
            <div class="popup-modal" id="popupModa1">
                    <div class="mp-content">
                      <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal5('close')">
                        <img src="@/assets/images/icon/x-lg.svg" alt="">
                      </button>
                      <div v-if="this.carryforwardleavestatus == 'Approved'" class="bdy-sect">
                        <h3>Approved Leave Carry Forward Status</h3>
                        <div class="item-cr">
                          <form  @submit.prevent="applyforCarryForward">
                              <!-- <div v-if="errormessageCarryForward != ''" class="errormessage">
                                  {{ errormessageCarryForward }}
                              </div>
                              <div v-if="successmessageCarryForward != ''" class="successmessage">
                                  {{ successmessageCarryForward }}
                              </div> -->
                            <div class="fr-grp">
                              <div class="min_fild">
                                <div class="full">
                                  <div>No of leaves to be carried forward :{{ carryforwardstatus.carryforwardleave }}</div>

                                </div>
                              </div>
                              

                                <!-- <span v-if="errormessageCarryForward!=''" class="errormessage valid_msg">{{ errormessageCarryForward }}</span> -->

                                <div class="min_fild">
                                <div class="full">
                                  <div>No of leaves to be cashed in :{{ carryforwardstatus.leavetobeapproved }}</div>

                                </div>
                              </div>
                                      
                            </div>

                          </form>
                        </div>
                      </div>
                      <div v-else class="bdy-sect">
                        <h3>Leave Carry Forward Application</h3>
                        <div class="item-cr">
                          <form  @submit.prevent="applyforCarryForward">
                              <!-- <div v-if="errormessageCarryForward != ''" class="errormessage">
                                  {{ errormessageCarryForward }}
                              </div>
                              <div v-if="successmessageCarryForward != ''" class="successmessage">
                                  {{ successmessageCarryForward }}
                              </div> -->
                            <div class="fr-grp">
                              <!-- <div class="ipm">
                                <img src="@/assets/images/icon/calendar-plus.png" alt="">
                              </div> -->
                              <!-- <input type="text" class="date" placeholder="Pick the multiple dates"> -->
                              <div class="min_fild">
                                <div class="leave_carry_count">
                                  <label>Casual Leaves remaining for the financial year :</label>
                                  <span>{{this.yearly_leave_count}}</span>
                                  <!-- <input type="text" disabled v-model ="this.yearly_leave_count"/> -->
                                </div>
                              </div>
                              

                              <div class="min_fild">
                                <div class="full">
                                  <label>No of leaves you want to carry forward :</label>
                                    <input type="number" min="0" v-model="state3.leavetobecarried"  @input="filterNonNumeric" />
                                </div>
                              </div>
                              

                                <!-- <span v-if="errormessageCarryForward!=''" class="errormessage valid_msg">{{ errormessageCarryForward }}</span> -->

                                <div class="min_fild">
                                <div class="full">
                                  <label>No of leaves you want to cash in :</label>
                                    <input type="number" min="0" v-model="state3.leavetobecashedin" @input="filterNonNumeric" />
                                </div>
                              </div>
                                
                                
                                <span v-if="errormessageCarryForward!=''" class="errormessage valid_msg">{{ errormessageCarryForward }}</span>
                                      
                            </div>

                            <div class="frg-inline">
                              <button v-if="applylcfbtn == 'Submit'" type="submit" class="btn submitBtns">{{applylcfbtn}}</button>
                              <button v-else disabled class="btn submitBtns">{{applylcfbtn}}</button>
                            </div>
                          </form>
                        </div>
                      </div>


                    </div>
                  </div>
        </ModalApplyForLeaveCarryforward>
      </div>
    </div>
  </div>
</template>
<script>
import "../../assets/css/dashboard.css"
import { createLocal, createSession, createStorage } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import html2pdf from "html2pdf.js";


const mirror = createLocal()
const storage = mirror._prx

import Swal from 'sweetalert2'

import { ref, computed } from 'vue'
import moment from 'moment'
import ModalApplyForLeave from '../UserDashboard/ModalApplyForLeave.vue'
import ModalApplyForWfh from '../UserDashboard/ModalApplyForWfh.vue'
import ModalApplyForDateChange from '../UserDashboard/ModalApplyForDateChange.vue'
import ModalApplyForLeaveCarryforward from '../UserDashboard/ModalApplyForLeaveCarryforward.vue'
import emailjs from 'emailjs-com';
import HackTimer from "../../HackTimer";
import { AnyMap } from '@jridgewell/trace-mapping';
import { assertAnyTypeAnnotation } from '@babel/types';
import { jsPDF } from "jspdf";
// import CryptoJS from 'crypto-js';

//console.log(storage.USERLNAME);

//console.log(mirror);
// if (window.matchMedia('(min-width: 100px)').matches) {
// 		jQuery(".timer_log").appendTo(".header_count_log");
//     $(element_id).addClassName(newClassName);
// }

export default {
name: 'EmployeeDashboard',
components:{
ModalApplyForLeave,
ModalApplyForWfh,
ModalApplyForDateChange,
ModalApplyForLeaveCarryforward,
Datepicker
},
setup() {
//   document.addEventListener('DOMContentLoaded', function() {
// var timerLog = document.querySelector(".timer_log");
// var headerCountLog = document.querySelector(".header_count_log");
// headerCountLog.appendChild(timerLog);
// });

 const isOpen = ref(false)
 const isOpenWfh = ref(false)
 const isOpenDateChange = ref(false)
 const isOpenCarryForward = ref(false)

 return { isOpen,isOpenWfh,isOpenDateChange,isOpenCarryForward };

 const leavedate = ref();
 const wfhdate = ref();
 const datetobechanged = ref();
 const changeddate = ref();
 const state3 = reactive({
  leavetobecarried:'',
  leavetobecashedin:''
  })

  //return { v$: useVuelidate() };
 
 //return { v$: useVuelidate() };
 return {
    leavedate,
    wfhdate,
    datetobechanged,
    changeddate,
    state3
 }
},
data() {
  return {
      form: {
          leave : "",
          leavedate : "",
          reason: "",
          wfhdate: "",
          reasonwfh: "",
          datetobechanged: "",
          changeddate: "",
          reasondatechange:"",
          
        },
        moment: moment,
      storageData: mirror,
      storage: storage,
      noneupload : true,
      errormessage: "",
      successmessage: "",
      successmessage1: "",
      errormessage_leave: "",
      errormessage_leavedate: "",
      errormessage_reason: "",
      dayscount: "",
      leave: "",
      leavedate: "",
      reason: "",
      leavedataval: [],
      noneuploadwfh : true,
      errormessagewfh: "",
      successmessagewfh: "",
      successmessagewfh1: "",
      errormessage_wfhdate: "",
      errormessage_reasonwfh: "",
      wfhdate: "",
      reasonwfh: "",
      leaverecord:  {},
      clicked: false,
      text: 'Loading..',
      cin:[],
      cout:[],
      count:[],
      cinmsArr:[],
      dataVal:{},
      dataIn:{},
      userList:[],
      userDataIn:{},
      userDetails:'',
      timerId:'',
      time0:'',
      timerSelection : 'Work',
      startingMinutes : '',
      startingSeconds : '00',
      timerRunning:'',
      stopTimer:'',
      playing : false,
      currentMinutes:'',
      currentSeconds:'',
      updateCoundtdownIntervalHandle : null,
      initial_minutes: '',
      initial_seconds: '',
      index:'',
      idData:{},
      userCinCoutResponse:'',
      current_CinCoutStatus:'',
      monthly_attendance_count:'',
      monthly_hours:'',
      working_days:'',
      month_name:'',
      year:'',
      space:' ',
      position:'',
      remaining_leave : "",
      remaining_leave_ml: "",
      provisional_leave_count: "",
      comma:', ',
      holiday_list:'',
      holiday_array:[],
      count1:'',
      holiday_weekend_count:'',
      monthly_attendance_count_hourBased_halfday:'',
      monthly_attendance_count_hourBased_fullday:'',
      emp_status: '',
      doj:'',
      total_weekdays_monthly:'',
      commonDates:'',
      statusInputData:'',
      attendance_list:'',
      attendance_array:'',
      attendance_count:'',
      absentCount:'',
      wfhrecord:'',
      notification:'',
      notifiedType:'',
      notifiedDate:'',
      holiday_array_formatted:'',
      monthly_attendance_record_fullday:[],
      monthly_attendance_record_halfday:[],
      monthly_attendance_record_latest:'',
      monthly_absense_count:'',
      datetobechanged: "",
      changeddate: "",
      errormessageDateChange: "",
      successmessageDateChange: "",
      errormessage_datechange:"",
      errormessage_datechange1:"",
      errormessage_reason_datechange:"",
      successmessageDateChange1:"",
      noneuploaddatechange: true,
      reasondatechange:"",
      datechangerecord:'',
      currentdateandtime:"",
      checkinstatusforabsentcount:'',
      monthly_absense_count_before_checkout:'',
      buttoncheckfordisable:true,
      max_paid_leave:"",
      yearly_leave_count:"",
      monthly_leave_taken:"",
      yearly_medical_count:'',
      downloadstatus: null,
      salary_month_name:'',
      salary_year:'',

      month_slip:'',
      year_slip:'',
      empid_slip:'',
      empname_slip:'',
      doj_slip:'',
      address_slip:'',
      position_slip:'',
      dept_slip:'',
      basic_pay_slip:'',
      hra_pay_slip:'',
      edu_allow_pay_slip:'',
      other_allow_pay_slip:'',
      basic_payable_slip:'',
      hra_payable_slip:'',
      other_allow_payable_slip:'',
      edu_allow_payable_slip:'',
      pf_payable_slip:'',
      ptax_payable_slip:'',
      advance_payable_slip:'',
      totalhead_slip_payable:'',
      payablehead_slip_payable:'',
      deductionhead_slip_payable:'',
      total_days_salaryslip:'',
      lossofpay_salaryslip:'',
      paiddays_salaryslip:'',
      netpayhead_slip_payable:'',
      salaryintext_payable:'',
      cl_slip_payable:'',
      ml_slip_payable:'',
      salarydata:'',
      yearly_unpaid_count:'',
      state3:{},
      errormessageCarryForward:'',
      carryforwardstatus:'',
      carryforwardleavestatus:'',
      newstringtobeencrypted:'',
      encryptedstring:'',
      applylcfbtn:'Submit',
      doj:''
  }
},
computed: {
  isDesiredMonth() {
      const desiredMonth = 2; // Replace with your desired month (January is 0, December is 11)
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();

      return currentMonth === desiredMonth;
      // return true;
    }

},
async created(event){
  // this.text = "Loading..";
//   if (window.matchMedia('(min-width: 100px)').matches) {
//   var timerLog = document.querySelector(".timer_log");
//   var headerCountLog = document.querySelector(".header_count_log");
//   headerCountLog.appendChild(timerLog);

//   var element = document.getElementById(element_id);
//   element.classList.add(newClassName);
// }

// document.addEventListener('DOMContentLoaded', function() {
// var timerLog = document.querySelector(".timer_log");
// var headerCountLog = document.querySelector(".header_count_log");
// headerCountLog.appendChild(timerLog);
// });

// document.querySelector("#minutes").innerHTML = '00';
//           document.querySelector("#seconds").innerHTML = '00';
 this.userName = mirror.USERNAME;
 this.userlName = storage.USERLNAME;
 this.userEmail = mirror.USEREMAIL;
 this.empId = mirror.EMPID;
 this.casual_leave = mirror.CASUAL_LEAVE;
 this.sick_leave = mirror.SICK_LEAVE;
 this.emp_status = mirror.EMP_STATUS;

 this.ID = mirror.ID;

//  this.fetchkey();
 if (this.isDesiredMonth) {
    axios.post(BASE_URL + `/api/getUserCarryForwardDetails`,{id: this.ID})
              .then((response)=>{
              //console.log(response.data);
              this.carryforwardstatus = response.data.checkstatus;
              this.state3.leavetobecarried = this.carryforwardstatus.carryforwardleave;
              this.state3.leavetobecashedin = this.carryforwardstatus.leavetobeapproved;
              console.log("this.carryforwardstatus",this.carryforwardstatus.carryforwardleave);
              if (this.carryforwardstatus==null) {
                this.carryforwardleavestatus = null;
              } else {
                this.carryforwardleavestatus = this.carryforwardstatus.status;
                
              }
              console.log("carrryyyyyyyy",this.carryforwardleavestatus);
      })
 }



  // await axios.post(BASE_URL + '/api/checkedoutornot',{id:this.ID})          
  //     .then((response)=>{
  //             console.log("checkedoutornot_status",response.data.checkedoutornot_status);
  //             this.checkinstatusforabsentcount = response.data.checkedoutornot_status;

  // })

  await axios.post(BASE_URL + '/api/getleavedatamonthly',{id:this.ID})          
  .then((response)=>{
          console.log("leave_record",response.data.max_paid_leave);
          this.max_paid_leave = response.data.max_paid_leave;
          this.yearly_leave_count = response.data.yearly_leave_count;
          this.monthly_leave_taken = response.data.monthly_leave_taken;
          this.yearly_medical_count = response.data.yearly_medical_count;
          this.yearly_unpaid_count = response.data.yearly_unpaid_count;

          // this.checkinstatusforabsentcount = response.data.checkedoutornot_status;

  })

  // await axios.post(BASE_URL + '/api/downloadsalary',{id:this.ID})
  //         .then((response)=>{
  //             this.downloadstatus = response.data.salaryslip;
  //             console.log("downloadstatus",response.data.salaryslip);

  //     })

 
//  const notification_result =await axios.post(BASE_URL + '/api/getempnotification',{id:this.ID});
//  this.notification = notification_result.data.notificationdata;
//  clearInterval('00:00:00');

  // console.log("concatteddate",new Date());

  // var today0= new Date().toLocaleString();
  // console.log("today0",today0);


////////////////////////////////////////////////////////////////
  var currentTime1 = new Date();
  var currentOffset = currentTime1.getTimezoneOffset();
  var offsettobechanged = 330 + currentOffset;

  console.log("currentOffset",currentOffset);
  console.log("offsettobechanged",offsettobechanged);


  const now = new Date();
  const utcOffset = (offsettobechanged/60).toFixed(1); // IST UTC offset is 5 hours and 30 minutes
  console.log("utcOffset",utcOffset);
  // Calculate the IST time by adding the UTC offset
  const istTime = new Date(now.getTime() + utcOffset * 60 * 60 * 1000);
  var today0 = istTime.toLocaleString();
  console.log("istTime",istTime.toLocaleString());





  var today_date0 = today0.split(",");
  var today_date = today_date0[0].split("/");
  // this.currentdateandtime = today_date0[0];
  // console.log("today_date",today_date0);

  // var today_date_formatted = today_date[1].concat("/",today_date[0]).concat("/",today_date[2]);
  // var currentdate = today_date[1].concat("-",today_date[0]).concat("-",today_date[2]);
  // var currenttime = today_date0[1];
  // // this.currentdateandtime = currentdate.concat(",",currenttime);
  var current_month = new Date().getMonth()+1;
  var current_year = new Date().getFullYear();
  /////////////////////////////////////////////////////////

  const getempdata = {
              'id': this.ID,
              'month':current_month,
              'year':current_year
          };
  // this.getleavedata(getempdata);

  this.getleaveremaining(getempdata);

  this.getempdata = getempdata;
//startkp
  
  await axios.post(BASE_URL + '/api/getemployeeleaverecord',getempdata)
          .then((response)=>{
              //console.log(response.data);
            this.leaverecord = response.data.empdetails;
            this.datechangerecord = response.data.datechange_details;
            // this.leaverecord = response.data.empdetails;
            this.holiday_list = response.data.holiday_list;
            this.holiday_array = this.holiday_list.map(x => x.holiday_date);
            this.attendance_list = response.data.attendance_list;
            this.attendance_array = this.attendance_list.map(x => x.attendance_date);
            this.doj = response.data.details.doj;
            this.wfhrecord = response.data.wfh_details;
            this.monthly_attendance_record_fullday = response.data.attendance_array_updated;
            this.monthly_attendance_record_halfday = response.data.attendance_array_updated_halfday;

            console.log("this.attendance_array",this.attendance_array);
            console.log("this.attendance_list",this.attendance_list);
          })

    // console.log("leavedata",this.holiday_array);

    var sat1 = new Array();   
    var sun1 = new Array();

    this.holiday_array_formatted = new Array();

  for (let i = 0; i < this.holiday_array.length; i++) {
    var split_holiday = this.holiday_array[i].split("/");
    this.holiday_array_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]))
    
  }
  // console.log("Format holiday", this.holiday_array_formatted);

    for(i=0; i<=this.holiday_array_formatted.length; i++){
      if(Date.parse(this.holiday_array_formatted[i])<= Date.parse(today_date)){
        // console.log("asasasasa",this.holiday_array[i].length);
        this.count1 = this.holiday_array_formatted.length;
        // console.log("cccccc",this.count1);
        const [dd, mm, yyyy] = this.holiday_array_formatted[i].split("/"),
      
        date = new Date(yyyy, mm-1, dd)

        var newDate = date;
        if(newDate.getDay()==0){   //if Sunday
            sun1.push(i);
        }
        if(newDate.getDay()==6){   //if Saturday
            sat1.push(i);
        }

      }
    }

    this.holiday_weekend_count = this.count1 - sat1.length - sun1.length;
    //
    // console.log("holiday array",this.holiday_array_formatted);
    var attendance_array_formatted = new Array();
    for (let i = 0; i < this.attendance_array.length; i++) {
      var newDate = new Date(this.attendance_array[i]);
      var year = newDate.getFullYear()
      var month = String(newDate.getMonth() + 1);
      var day = String(newDate.getDate()).padStart(2, '0')
      // console.log("sgdygfy",year,month,day);
      attendance_array_formatted.push(month.concat("/",day).concat("/",year))
    }
    console.log("this.holiday_array_formatted",this.holiday_array_formatted);
    console.log("attendance_array_formatted",attendance_array_formatted);
    // console.log("attendance array formatted",attendance_array_formatted);
    attendance_array_formatted = attendance_array_formatted.filter( ( el ) => !this.holiday_array_formatted.includes( el ) );
    // console.log("attendance array",attendance_array_formatted);
    // console.log("holiday array",this.this.holiday_array_formatted);

    var sat_attendance = new Array();   
    var sun_attendance = new Array();

    for (let i = 0; i < attendance_array_formatted.length; i++) {
      var newDate = new Date(attendance_array_formatted[i]);
      // console.log("newDate",newDate);
      if(newDate.getDay()==6){   //if Sunday
              sat_attendance.push(i);
              console.log("sat",sat_attendance);
          }
          if(newDate.getDay()==0){   //if Saturday
              sun_attendance.push(i);
              console.log("sun",sun_attendance);

          }
    }
    this.attendance_count = attendance_array_formatted.length - sat_attendance.length - sun_attendance.length;
    // console.log("abcd",this.attendance_count);
    // console.log("djymfdfud",this.holiday_weekend_count);

  /////////////////////////////////////////////////////////
  // var todayTime_split = today_date0[1].split(':'); 
  // console.log("dgsfsgs",todayTime_split);

  // var ampm = todayTime_split[2].split(" ")[1];
  // if(ampm == undefined){
  //   if(todayTime_split[0]>=12){
  //     ampm = 'pm';
  //   }else{
  //     ampm = 'am';
  //   }
  // }
  // console.log("ampm",ampm);
  // console.log("todayTime_split",todayTime_split[0]);

  // if (ampm == 'AM'|| ampm == 'am') {
  //   if (todayTime_split[0]==12) {
  //     var todayTime_seconds = (+todayTime_split[0]-12) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
  //   } else {
  //     var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
  //   }
  //   console.log("am");
  // }
  // else{
  //   console.log("pm");

  //   if (todayTime_split[0]==12) {
  //     var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]);  
  //   console.log("pm if");

  //   }
  //   else if(todayTime_split[0]>12){
  //     var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]);  
      
  //     }else{
  //     var todayTime_seconds = (+todayTime_split[0]+12) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
  //   }
  // }

  // // var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
  // console.log("currtime", todayTime_seconds);
/////////////////////////////////////////////////////////
  var user_Id0 = this.ID;

  this.dataIn = {"userid":user_Id0 , "date":today_date0[0]};
  
  // const result0=await axios.post(BASE_URL + '/api/fetch_attendance',this.dataIn);
  // this.userCinCoutResponse = result0.data.data;
  //  console.log("hhh",result0.data.data);

  
    // var today = today_date0[0];

    var currdate = new Date();
    var currmonth = currdate.getMonth()+1;
    var currday = currdate.getDate();
    var curryear = currdate.getFullYear();
    var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear);

    var start_date1=new Date();
    var attendance_year1 =start_date1.getFullYear();
    var attendance_month1 =start_date1.getMonth();
    var month = attendance_month1;
   
    var uId = this.ID;
    // var today = today_date0[0];
    var month = attendance_month1;
    var today = concatteddate;
    // console.log("aaaaaaa",uId);
    // console.log("bbbbbbb",today);
    // console.log("ccccccc",month);
    this.abcde(uId, today, month);
////////////////////////////////////////////////////////////////////
    // this.statusInputData = {"id":uId, "date":today, "month":month};
    // const getInOutStatus= await axios.post(BASE_URL + '/api/getCheckinStatus',this.statusInputData);
    // console.log("ifcheckedin",getInOutStatus.data.statusInOut);
    // this.current_CinCoutStatus = (getInOutStatus.data.statusInOut==null)?'out':getInOutStatus.data.statusInOut.status;
    // var lastCheckinTime = (getInOutStatus.data.last_chekin_time==null)?'00:00:00':getInOutStatus.data.last_chekin_time.start_time;
    // this.checkinstatusforabsentcount = getInOutStatus.data.checkedoutornot_status;
//////////////////////////////////////////////////////////////////////


    // this.statusInputData = {"id":uId, "date":today, "month":month};
    // const getInOutStatus= await axios.post(BASE_URL + '/api/getInOutStatus',this.statusInputData);
    // console.log("ifcheckedin",getInOutStatus.data.statusInOut);
    // this.current_CinCoutStatus = (getInOutStatus.data.statusInOut==null)?'out':getInOutStatus.data.statusInOut.status;

    // this.userDataIn = {"userid":user_Id0 , "date":today};
    // var result1 =await axios.post(BASE_URL + '/api/getLastCheckinTime',this.userDataIn);
    // // console.log("wwwwww", result1.data.last_chekin_time.start_time); 
    // var lastCheckinTime = (result1.data.last_chekin_time==null)?'00:00:00':result1.data.last_chekin_time.start_time;

////////////////////////////////////////////////////////////////
    // console.log("lastCheckinTime",lastCheckinTime);
    // var lastCheckinTime_split = lastCheckinTime.split(':'); 
    // var ampmcheckin = lastCheckinTime_split[2].split(" ")[1];
    // console.log("lastCheckinTime",lastCheckinTime_split[0]);

    // if (ampmcheckin == 'AM'||ampmcheckin == 'am') {
    //   if (lastCheckinTime_split[0]==12) {
    //     var lastCheckinTime_seconds = (+lastCheckinTime_split[0]-12) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
        
    //   } else {
    //     var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
        
    //   }
    //   console.log("am");
    // } else {
    //   if (lastCheckinTime_split[0]==12) {
    //     var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
        
    //   }else{
    //     var lastCheckinTime_seconds = (+lastCheckinTime_split[0]+12) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 

    //   }
    // console.log("pm");
      
    // }
    // // var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
    // this.startingSeconds = todayTime_seconds - lastCheckinTime_seconds;
  
    // console.log("timeeeeeeee1", lastCheckinTime_seconds);
    // console.log("timeeeeeeee2", this.startingSeconds);
    // console.log("timeeeeeeee3", todayTime_seconds);
    // console.log("status", this.current_CinCoutStatus);
  


    // if (this.current_CinCoutStatus=="out") {
    //   this.text = "Check In";
    //   this.startingSeconds = "0";
    //   //this.chechinCheckOutStatus = false;
    //   console.log("IF");
      
    // }
    // else{
    //   this.text = "Check Out";
    //   this.chechinCheckOutStatus = true;
    //   this.clock(this.chechinCheckOutStatus,this.startingSeconds);
    //   console.log("ELSE IF");

    // } 
    
/////////////////////////////////
    const d = new Date();
    var split_d = d.toLocaleString().split(",")[0].split("/")[1];
  
    // console.log("day",split_d);
    var getTot = daysInMonth(d.getMonth(),d.getFullYear()); //Get total days in a month
    // console.log("total days",getTot);
//
  console.log("this.dojkjjjjjjjjjjjjjjj",this.doj);
  var doj_split = this.doj.split("-");
  var doj_formatted = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2]);

  var curr_month = d.getMonth();
  var curr_year = d.getFullYear();
  // console.log("split",split_date);
  // var initial_date = split_date[0] + '/' + '1' + '/' + split_date[2];
  var initial_date = curr_month+1 + '/' + '01' + '/' + curr_year;
  console.log("initial_date",initial_date);
  console.log("doj_formatted",doj_formatted);
  if (Date.parse(initial_date.replace(/-/g, ' '))>=Date.parse(doj_formatted.replace(/-/g, ' '))) {
    var d1 = new Date(initial_date);
  console.log("if",d1);
  }
  else{
  var d1 = new Date(doj_formatted);
  console.log("else",d1);
  
  }
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    var dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  var today_date = new Date();
  var date_current1 = today_date.toLocaleString().split(",")[0].split("/");
  var date_current2 = date_current1[2].concat("-",date_current1[0]).concat("-",date_current1[1]);
  var d2 = new Date(today_date);
  var workDayArray = getDatesInRange(d1, d2);
  console.log("d1",d1);
  console.log("d2",d2);
  console.log("workDayArray",workDayArray);
 

  var sat2 = new Array();   //Declaring array for inserting Saturdays
  var sun2 = new Array();   //Declaring array for inserting Sundays

  for(var i=0;i<workDayArray.length;i++){    //looping through days in month
      var newDate = workDayArray[i];
      // console.log("newDate",newDate);
      if(newDate.getDay()==0){   //if Sunday
          sun2.push(i);
          //  console.log("sun2",sun2.length);
      }
      if(newDate.getDay()==6){   //if Saturday
          sat2.push(i);
          //  console.log("sat2",sat2);
      }

  }
  var no_of_sat_and_sun_monthly = sat2.length + sun2.length;
  //  console.log("no_of_sat_and_sun",no_of_sat_and_sun_monthly);
  this.total_weekdays_monthly = workDayArray.length - no_of_sat_and_sun_monthly;
  console.log("working days monthly",this.total_weekdays_monthly);

  // console.log("holiday_array_monthly",this.holiday_array);
  var holiday_array_uptoDate = new Array();

  for (var i = 0; i < this.holiday_array.length; i++) {
    
    var d_split = this.holiday_array[i].split("/");
    var d_formatted = d_split[1].concat("/",d_split[0]).concat("/",d_split[2]);
    console.log("d_formatted",d_formatted);
    var today_date = new Date();
    var date_current1 = today_date.toLocaleString().split(",")[0];
    // console.log("d_formatted",doj_formatted);
    // console.log("date_current1",date_current1);
    if (Date.parse(d_formatted)<=today_date.getTime() && Date.parse(d_formatted)>=Date.parse(doj_formatted.replace(/-/g, ' '))) {

    this.holiday_array[i]= new Date(d_formatted)
    holiday_array_uptoDate.push(this.holiday_array[i])
    }
  }

  console.log("holiday_array_monthly1",holiday_array_uptoDate);

  // this.commonDates= holiday_array_uptoDate.filter(i => workDayArray.includes(i));
  var sat3 = new Array();   //Declaring array for inserting Saturdays
  var sun3 = new Array();   //Declaring array for inserting Sundays

  for(var i=0;i<holiday_array_uptoDate.length;i++){    //looping through days in month
      var newDate = holiday_array_uptoDate[i];
      // console.log("newDateeeeee",newDate);
      if(newDate.getDay()==0){   //if Sunday
          sun3.push(i);
          //  console.log("sun3",sun3.length);
      }
      if(newDate.getDay()==6){   //if Saturday
          sat3.push(i);
          //  console.log("sat3",sat3);
      }

  }

  var holiday_inweekend = sun3.length + sat3.length;
  this.commonDates = holiday_array_uptoDate.length- holiday_inweekend;
  // console.log("holiday_inweekend",holiday_inweekend);
  // console.log("ifcommon",this.commonDates);
//
  this.absentCount = this.total_weekdays_monthly - this.commonDates -this.attendance_count;

    function daysInMonth(month,year) {
        return new Date(year, month, 0).getDate();
    }
      
    const month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    this.month_name = month_name_array[d.getMonth()];
    this.year = d.getFullYear();

    if (d.getMonth() == 0) {
    this.salary_year = d.getFullYear()-1;
    this.salary_month_name = month_name_array[11];
    
    }else{
    this.salary_year = d.getFullYear();
    this.salary_month_name = month_name_array[d.getMonth()-1];

    }

    this.monthly_attendance_record_latest = this.monthly_attendance_record_fullday.length + this.monthly_attendance_record_halfday.length/2;
    // console.log("this.total_weekdays_monthly",this.total_weekdays_monthly);
    // console.log("this.commonDates",this.commonDates);
    // console.log("this.monthly_attendance_record_fullday.length",this.monthly_attendance_record_fullday.length);
    // console.log("this.monthly_attendance_record_halfday.length/2",this.monthly_attendance_record_halfday.length/2);
    
    this.monthly_absense_count =this.total_weekdays_monthly - this.commonDates - this.monthly_attendance_record_fullday.length - this.monthly_attendance_record_halfday.length/2;
    console.log("test101", this.monthly_absense_count)
    if(d.getDay() == 0 || d.getDay()== 6){
      this.monthly_absense_count_before_checkout = this.monthly_absense_count;

    }
    else{
      this.monthly_absense_count_before_checkout = this.monthly_absense_count - 1;

    }


//endkp
//startkp
    // this.clremaining = 
    // function calculateremainingCL(){

    // }
//endkp
},
methods : {
  preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0]==' ') e.target.value = e.target.value.replace(/^\s*/, "");
    },

    async fetchkey(){
      await axios.post(BASE_URL + '/api/fetchapikey',{id:this.ID})          
          .then((response)=>{
              console.log("responsekey",response.data.apikey.api_key);
              var decryptedString =  atob(response.data.apikey.api_key)
              console.log("decryptedString",decryptedString);
              let d = parseInt(decryptedString);
              //  console.log("newstringtobeencrypted",decryptedString[0]);
              var d1 = parseInt(decryptedString[0]) +  parseInt(decryptedString[5]);
              var d2 = parseInt(decryptedString[1]) +  parseInt(decryptedString[6]);
              var d3 = parseInt(decryptedString[2]) +  parseInt(decryptedString[7]);
              var d4 = parseInt(decryptedString[3]) +  parseInt(decryptedString[8]);
              var d5 = parseInt(decryptedString[4]) +  parseInt(decryptedString[9]);
              this.newstringtobeencrypted = d1.toString() + d2.toString() + d3.toString() +d4.toString() + d5.toString();
              console.log("newstringtobeencrypted",this.newstringtobeencrypted);
              this.encryptedstring = btoa(this.newstringtobeencrypted);
          })
    },
//startkp
    async abcde(uId,today,month){
      this.idData = {"id":uId, "date":today, "month":month};
      const attendance_data= await axios.post(BASE_URL + '/api/get_attendance',this.idData);
      this.userList = attendance_data.data.userList;
      this.userDetails = attendance_data.data.userDetails;
      this.monthly_attendance_count = attendance_data.data.monthly_attendance_count;
      // console.log("test1",this.monthly_attendance_count);
      // this.current_CinCoutStatus= (attendance_data.data.userDetails==null)?'out':attendance_data.data.userDetails.status;
      console.log("test2",this.current_CinCoutStatus);

      var monthly_hours_with_seconds_split = attendance_data.data.total_hours_monthly.split(":");
      this.monthly_hours = monthly_hours_with_seconds_split[0].concat(":", monthly_hours_with_seconds_split[1])
      // this.monthly_hours = attendance_data.data.total_hours_monthly;
      this.position = attendance_data.data.user_designation.position;

    // console.log("ABCD: ",monthly_hours)
    },

    // clock(status,startTime){

    //   this.playing = status; // true /false
      
    //   this.startingMinutes = this.initial_minutes;
      
    //   let time = startTime;
    //   console.log("uydtdt",startTime);
    //   if (this.updateCoundtdownIntervalHandle !== null) {
    //       clearInterval(this.updateCoundtdownIntervalHandle);
    //   }
    //   // if (this.updateCoundtdownIntervalHandle!="00:00:00") {
        
    //   // }
    //   // console.log("time1",time);
    //   this.updateCoundtdownIntervalHandle = setInterval(updateCoundtdown, 1000);
    //   // time++;
    //   // console.log("time2",time);
    //   function updateCoundtdown() {
    //       // if (this.playing) {
    //         console.log("showtime1",time);
    //         let hours = Math.floor(time / 3600);
    //         // console.log("hours",hours);
    //         var minutes = Math.floor(time / 60);
    //         minutes = minutes % 60;
    //         // console.log("minutes",minutes);
    //         let seconds = time % 60;
    //         // console.log("seconds",seconds);


    //         seconds = seconds < 10 ? '0' + seconds : seconds;
    //         minutes = minutes < 10 ? '0' + minutes : minutes;
    //         hours   = hours   < 10 ? '0' + hours   : hours;

    //         this.currentMinutes = minutes;
    //         this.currentSeconds = seconds;
    //         this.currentHours   = hours;
    //         // console.log("minutes,seconds",minutes,seconds);

    //         document.querySelector("#minutes").innerHTML = minutes;
    //         document.querySelector("#seconds").innerHTML = seconds;
    //         document.querySelector("#hours").innerHTML   = hours;
    //         time++;
    //         // console.log("time1",minutes + ':' + seconds);
        
    //   }
    // },

    // timer(type) {
    //   //console.log("ydctcty",type);
    //   if (type=="Check Out") {

        
    //     this.playing = true;
        
    //     this.startingMinutes = this.initial_minutes;
        
    //     let time = this.startingSeconds;
    //     //console.log("uydtdt",time);
    //     if (this.updateCoundtdownIntervalHandle !== null) {
    //         clearInterval(this.updateCoundtdownIntervalHandle);
    //     }
    //     this.updateCoundtdownIntervalHandle = setInterval(updateCoundtdown, 1000);
    //     time++;

    //     function updateCoundtdown() {
    //         // if (this.playing) {
    //           // console.log("showtime4",time);
    //         let hours = Math.floor(time / 3600);
    //         // console.log("showtime5",hours);
    //         var minutes = Math.floor(time / 60);
    //         minutes = minutes % 60;

    //         // console.log("showtime6",minutes);
    //         let seconds = time % 60;

    //         seconds = seconds < 10 ? '0' + seconds : seconds;
    //         minutes = minutes < 10 ? '0' + minutes : minutes;
    //         hours   = hours   < 10 ? '0' + hours   : hours;

    //         this.currentMinutes = minutes;
    //         this.currentSeconds = seconds;
    //         this.currentHours   = hours;
    //         // console.log("minutes,seconds",minutes,seconds);

    //         document.querySelector("#minutes").innerHTML = minutes;
    //         document.querySelector("#seconds").innerHTML = seconds;
    //         document.querySelector("#hours").innerHTML   = hours;
    //         time++;
    //             // console.log("time2",minutes + ':' + seconds);
          
    //     }
    //   } else {
    //     clearInterval(this.updateCoundtdownIntervalHandle);
    //   }
    // },

    // async added() {

    // this.clicked=!this.clicked;
    // localStorage.setItem('buttonClicked', JSON.stringify(this.clicked));
    // // this.clicked = true;
    // var last_time = new Date().toLocaleString();
    // var last_time_s = last_time.split(",");
    // const latest_time = "11:00:00 AM";
    // var latest_in = last_time_s[0].concat(", ",latest_time)

    // var today1= new Date().toLocaleString();
    // var today_date1 = today1.split(",");
    // var start_date_added=new Date();
    // var attendance_month_added =start_date_added.getMonth();
    // //////////////////////

    // ///////////////////////////

    // if (this.text == "Check In") {
    // if(confirm("Are you sure you want to check in?")){

    // this.startingSeconds = "00"
    // this.text = "Check Out";
    // var start_time1=new Date().toLocaleString();
    // var checkin_hrs = new Date().getHours();
    // console.log("checkin_hrs",checkin_hrs);
    // console.log("start_time1",start_time1);
    // var checkin_date = start_time1.split(",");
    // console.log("checkin_date",checkin_date[1]);
    // var checkin_ampm = checkin_date[1].split(" ")[2];
    // console.log("checkin_ampm",checkin_ampm);
    // if (checkin_ampm =='am') {
    //   checkin_ampm = 'AM';
    // } else if(checkin_ampm =='pm'){
    //   checkin_ampm = 'PM';
      
    // }else if(checkin_ampm == undefined){
    //   console.log("else if");
    //   checkin_ampm = checkin_hrs >= 12 ? 'PM' : 'AM';
    // }else{
    //   console.log("else");

    //   checkin_ampm = checkin_ampm
    // }
    // console.log("checkin_ampm1",checkin_ampm);

    // var checkin_time =  checkin_date[1].split(" ")[1].concat(" ",checkin_ampm);
    // console.log("checkin_time",checkin_time);

    // var start_date=new Date();
    // //  var curr_m = start_date.getMonth()+1;
    // //  var curr_d = start_date.getDate();
    // //  var curr_y = start_date.getFullYear();
    // //  var curr_date = curr_m.concat("/",curr_d).concat("/",curr_y);
    
    // var start_date1= new Date().toLocaleString();
    // var start_date2 = start_date1.split(",");

    // var attendance_year =start_date.getFullYear();
    // var attendance_month =start_date.getMonth();
    // // const ctime = start_time.split();
    // //   console.log("dhdugdyydg",ctime[0]);
    // //  var cin =this.cin.push(checkin_time);
    // var cinms = start_date.getTime();
    // var currdate = new Date();
    // var currmonth = currdate.getMonth()+1;
    // var currday = currdate.getDate();
    // var curryear = currdate.getFullYear();
    // var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear)
    // // console.log("checkin1 time",checkin_date[0],checkin_date[1],cinms,attendance_month);
    // // console.log(Date.parse(latest_in) > Date.parse(last_time));
    // var late_status =Date.parse(latest_in) < Date.parse(last_time);
    // // console.log("late",late_status);

    // var current_date = new Date();
    // var currday = String(current_date.getDate()).padStart(2, '0');
    // var current_day = current_date.getDay();
    // var currentmonth = current_date.getMonth()+1;
    // var current_year = current_date.getFullYear();
    // var concattedcurrdate = currentmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",current_year);
    // console.log("concatteddate",concattedcurrdate);
    //   await axios.post(BASE_URL + '/api/getemployeeleaverecord',{
    //               'id': this.ID,
    //               'month':currentmonth,
    //               'year':current_year
    //           })
    //           .then((response)=>{
    //               //console.log(response.data);

    //             // this.leaverecord = response.data.empdetails;
    //             this.holiday_list = response.data.holiday_list;
    //             this.holiday_array = this.holiday_list.map(x => x.holiday_date);
    //       })

    //       this.holiday_array_formatted = new Array();

    // for (let i = 0; i < this.holiday_array.length; i++) {
    //   var split_holiday = this.holiday_array[i].split("/");
    //   this.holiday_array_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]));
      
    // }
    // console.log("this.holiday_array_formatted",this.holiday_array_formatted);


    // var isWorkingDay;
    // if (current_day == 6 || current_day == 0) {
    //   console.log("if working");
    //   isWorkingDay = 0;
    // }else if(this.holiday_array_formatted.includes(concattedcurrdate)){
    //   console.log("else if working");

    //   isWorkingDay = 0;
    // }else{
    //   console.log("else working");

    //   isWorkingDay = 1;
    // }
    // console.log("isWorkingDay",isWorkingDay);
    // this.dataVal = {"checkin_time":checkin_time,"checkin_date":concatteddate,"checkout_time":'',"checkout_date":'',"cinms":cinms,"coutms":coutms,"empid":this.ID,"empname":this.userName,"emplname":this.userlName,"empemail":this.userEmail,"checkin_type":this.text,"late_status":late_status,"attendance_year":attendance_year,
    // "attendance_month":attendance_month,"today_date":concatteddate,"status":"in","isWorkingDay":isWorkingDay,"encryptedstring":this.encryptedstring};
    // const res=await axios.post(BASE_URL + '/api/getattendance1',this.dataVal);
    // this.fetchkey();
    // // this.abcd(this.ID,checkin_date[0]);
    // }


    // } else {
    // if(confirm("Are you sure you want to check out?")){

    // this.text = "Check In";
    // var checkin_hrs = new Date().getHours();
    // console.log("checkin_hrs",checkin_hrs);
    // var end_time1=new Date().toLocaleString();
    // var checkout_date = end_time1.split(",");
    // console.log("checkin_date",checkout_date[1]);
    // var checkout_ampm = checkout_date[1].split(" ")[2];
    // console.log("checkout_ampm",checkout_ampm);
    // if (checkout_ampm =='am') {
    //   checkout_ampm = 'AM';
    // } else if(checkout_ampm =='pm'){
    //   checkout_ampm = 'PM';
      
    // }else if(checkout_ampm == undefined){
    //   console.log("else if");
    //   checkout_ampm = checkin_hrs >= 12 ? 'PM' : 'AM';
    // }else{
    //   checkout_ampm = checkout_ampm;
    // }
    // console.log("checkout_ampm",checkout_ampm);

    // var checkout_time =  checkout_date[1].split(" ")[1].concat(" ",checkout_ampm);
    // console.log("checkout_time",checkout_time);
    // //  checkout_date = [];
    // var end_date=new Date();
    // //  var cinms = start_date.getTime();

    // var currdate = new Date();
    // var currmonth = currdate.getMonth()+1;
    // var currday = currdate.getDate();
    // var curryear = currdate.getFullYear();
    // var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear)
    // //  var cout =this.cout.push(checkout_time);
    // var coutms = end_date.getTime();

    // clearInterval(this.timerId);
    // // console.log("time2",this.timerId);
    // this.timerId = null;

    // this.dataVal = {"checkin_time":'',"checkin_date":'',"checkout_time":checkout_time,"checkout_date":concatteddate,"cinms":cinms,"coutms":coutms,"empid":this.ID,"empname":this.userName,"emplname":this.userlName,"empemail":this.userEmail,"checkin_type":this.text,"attendance_year":attendance_year,"attendance_month":attendance_month,"status":"out","encryptedstring":this.encryptedstring};
    // const res=await axios.post(BASE_URL + '/api/getattendance1',this.dataVal);
    // this.fetchkey();
    // // this.abcd(this.ID,checkout_date[0]);
    // }else{
    //   location.reload();
    // }
    // }

    // this.abcd(this.ID,concatteddate,attendance_month_added);

    // },

read() {
},
//endkp       
  closemodal1(event){
    this.isOpen = false;
  },
  closemodal2(event){
    this.isOpenWfh = false;
  },
  closemodal3(event){
    this.isOpenDateChange= false;
  },
  closemodal5(event){
    this.isOpenCarryForward = false;
  },
  thisFileUpload() {
      document.getElementById("file").click();
  },
  alldoc(event){
      this.files = event.target.files[0];

      //console.log(this.files.type);

      this.noneupload = false;

      this.successmessage1 = this.files.name;

      // if(this.files.type == 'image/jpeg'){
      //     this.successmessage1 = this.files.name;
      // }else if(this.files.type == 'image/png'){
      //     this.successmessage1 = this.files.name;
      // }else if(this.files.type == 'application/pdf'){
      //     this.successmessage1 = this.files.name;
      // }else if(this.files.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      //     this.successmessage1 = this.files.name;
      // }else{
      //     this.successmessage1 = "";
      // }

      
  },
  thisFileUploadwfh() {
      document.getElementById("filewfh").click();
  },
  alldocwfh(event){
      this.files = event.target.files[0];

      //console.log(this.files.type);

      this.noneuploadwfh = false;

      this.successmessagewfh1 = this.files.name;

      
  },
  cancel(){
      //console.log('cancel');
      this.leave = "";
      this.leavedate = "";
      this.dayscount = "";
      this.successmessage1 = "";
      this.reason = "";
  },

  cancelwfh(){
      //console.log('cancelwfh');
      this.wfhdate = "";
      this.successmessagewfh1 = "";
      this.reasonwfh = "";
  },

  thisFileUploaddatechange() {
      document.getElementById("filedatechange").click();
  },
  alldocdatechange(event){
      this.files = event.target.files[0];

      //console.log(this.files.type);

      this.noneuploaddatechange = false;

      this.successmessageDateChange1 = this.files.name;

      
  },
  canceldatechange(){
      //console.log('cancelwfh');
      this.datetobechanged= "",
      this.changeddate= "",
      this.successmessageDateChange1 = "";
      this.reasondatechange = "";
  },


 async onSubmitLeave(){
     
    if(this.emp_status == 'Permanent'){
          if(!this.leave){
              this.errormessage_leave = 'Please Enter Leave Type.';
              setTimeout(() => {
                      this.errormessage_leave = '';
                  },3000);
              this.errormessage_leavedate = "";
              this.errormessage_reason = "";
        }else if(!this.leavedate){
              this.errormessage_leavedate = 'Please Enter Leave Date.';
              setTimeout(() => {
                      this.errormessage_leavedate = '';
                  },3000);
              this.errormessage_leave = "";
              this.errormessage_reason = "";
        }else if(!this.reason || !this.reason.trim()){
              this.errormessage_reason = 'Please Enter Reason.';
              setTimeout(() => {
                      this.errormessage_reason = '';
                  },3000);
              this.errormessage_leave = "";
              this.errormessage_leavedate = "";
        }else{

              const getdata = {
                  userId: mirror.ID,
                  userName: mirror.USERNAME,
                  userlName: storage.USERLNAME,
                  userEmail: mirror.USEREMAIL,
                  empId : mirror.EMPID,
                  leave: this.leave, 
                  leavedate:this.leavedate.join(),
                  files: this.files,
                  reason:this.reason,
                  emp_status:mirror.EMP_STATUS
              };
              //console.log(getdata);
              // const applyleaveMailSend= await axios.post(BASE_URL + '/api/sendapplyleave',getdata);

              await axios.post(BASE_URL + '/api/applyleave',getdata,{
              headers: {
                  "Content-Type": "multipart/form-data",
              }})
              .then((response)=>{

                  if(response.data.success == true){

                    axios.post(BASE_URL + '/api/sendapplyleave',{
                                email: response.data.userEmail,
                                name:  response.data.userName,
                                lname:  response.data.userlName,
                                leavetype: response.data.leave,
                                leavedate: response.data.leavedate,
                                days : response.data.days
                            });
                    
                    axios.post(BASE_URL + '/api/storenotifications',{
                                empid: mirror.ID,
                                email: response.data.userEmail,
                                name:  response.data.userName,
                                lname:  response.data.userlName,
                                date: response.data.leavedate,
                                days : response.data.days,
                                type : response.data.leave,
                                status : 'unseen'
                            });
                  

                        setTimeout(() => {
                          this.isOpen = false;
                        },2000);

                        Swal.fire({
                                        title: 'Done',
                                        text:  response.data.message,
                                        icon: 'success',
                                    
                                    });

                            this.leave = "";
                            this.leavedate = "";
                            this.dayscount = "";
                            this.successmessage1 = "";
                            this.reason = "";
                            this.files = "";

                            this.getleavedata(this.getempdata);

                  }else{

                      Swal.fire({
                              title: 'OPPS',
                              text:   response.data.message,
                              icon: 'warning',
                          
                          });
                  }
                
              })
        }
    }else{
        if(!this.leavedate){
              this.errormessage_leavedate = 'Please Enter Leave Date.';
              setTimeout(() => {
                      this.errormessage_leavedate = '';
                  },3000);
              this.errormessage_leave = "";
              this.errormessage_reason = "";
        }else if(!this.reason){
              this.errormessage_reason = 'Please Enter Reason.';
              setTimeout(() => {
                      this.errormessage_reason = '';
                  },3000);
              this.errormessage_leave = "";
              this.errormessage_leavedate = "";
        }else{

              const getdata = {
                  userId: mirror.ID,
                  userName: mirror.USERNAME,
                  userlName: storage.USERLNAME,
                  userEmail: mirror.USEREMAIL,
                  empId : mirror.EMPID,
                  leave: 'Provisional', 
                  leavedate:this.leavedate.join(),
                  files: this.files,
                  reason:this.reason,
                  emp_status:mirror.EMP_STATUS
              };
              //console.log(getdata);

              await axios.post(BASE_URL + '/api/applyleave',getdata,{
              headers: {
                  "Content-Type": "multipart/form-data",
              }})
              .then((response)=>{

                  if(response.data.success == true){

                    axios.post(BASE_URL + '/api/sendapplyleave',{
                                email: response.data.userEmail,
                                name:  response.data.userName,
                                lname:  response.data.userlName,
                                leavetype: response.data.leave,
                                leavedate: response.data.leavedate,
                                days : response.data.days
                            });
                    
                    axios.post(BASE_URL + '/api/storenotifications',{
                                empid: mirror.ID,
                                email: response.data.userEmail,
                                name:  response.data.userName,
                                lname:  response.data.userlName,
                                date: response.data.leavedate,
                                days : response.data.days,
                                type : response.data.leave,
                                status : 'unseen'
                            });
                            
                        setTimeout(() => {
                          this.isOpen = false;
                        },2000);

                        Swal.fire({
                                        title: 'Done',
                                        text:  response.data.message,
                                        icon: 'success',
                                    
                                    });

                            this.leave = "";
                            this.leavedate = "";
                            this.dayscount = "";
                            this.successmessage1 = "";
                            this.reason = "";
                            this.files = "";

                            this.getleavedata(this.getempdata);

                  }else{

                      Swal.fire({
                              title: 'OPPS',
                              text:   response.data.message,
                              icon: 'warning',
                          
                          });
                  }
                
              })

              
        }
    }
 },

 async cancelleave(id){
  
  await axios.post(BASE_URL + '/api/cancelappliedleave',{id:id})
              .then((response)=>{
          // this.salaryslipdata = response.data.salaryslipdetails;
          // this.salaryslipclcount= response.data.clcountforsalaryslip;
          // this.salaryslipmlcount = response.data.mlcountforsalaryslip;
          // this.salaryslipfulldaycount = response.data.fulldaycount;
          // this.salarysliphalfdaycount = response.data.halfdaycount;
          // this.checkapprovedstatus = response.data.success;
          console.log("response",response.data);
          if (response.data.success == true) {

          Swal.fire({
              title: 'Done',
              text:  response.data.message,
              icon: 'success',
          });

          } else {

          Swal.fire({
              title: 'OPPS',
              text:   'Something went wrong',
              icon: 'warning',

          });

          }
          })
          this.getleavedata(this.getempdata);

 },

 async onSubmitLeaveWfh(){
      if(!this.wfhdate){
            this.errormessage_wfhdate = 'Please Enter Wfh Date.';
            setTimeout(() => {
                    this.errormessage_wfhdate = '';
                },3000);
            this.errormessage_reasonwfh = "";
      }else if(!this.reasonwfh || !this.reasonwfh.trim()){
            this.errormessage_reasonwfh = 'Please Enter Reason.';
            setTimeout(() => {
                    this.errormessage_reasonwfh = '';
                },3000);
            this.errormessage_wfhdate = "";
      }else{

            const getdata = {
                userId: mirror.ID,
                userName: mirror.USERNAME,
                userlName: storage.USERLNAME,
                userEmail: mirror.USEREMAIL,
                wfhdate:this.wfhdate.join(),
                files: this.files,
                reasonwfh:this.reasonwfh,
            };
            //console.log(getdata);

            await axios.post(BASE_URL + '/api/applywfh',getdata,{
            headers: {
                "Content-Type": "multipart/form-data",
            }})
            .then((response)=>{

                if(response.data.success == true){
                  axios.post(BASE_URL + '/api/sendapplywfh',{
                                email: response.data.userEmail,
                                name:  response.data.userName,
                                lname:  response.data.userlName,
                                wfhdate: response.data.wfhdate,
                                days : response.data.days
                            });

                  axios.post(BASE_URL + '/api/storenotifications',{
                                empid: mirror.ID,
                                email: response.data.userEmail,
                                name:  response.data.userName,
                                lname:  response.data.userlName,
                                date: response.data.wfhdate,
                                days : response.data.days,
                                type : 'WFH',
                                status : 'unseen'
                            });

                  setTimeout(() => {
                      this.isOpenWfh = false;
                    },2000);

                    Swal.fire({
                                title: 'Done',
                                text:  response.data.message,
                                icon: 'success',
                            
                            });

                    this.wfhdate = "";
                    this.dayscount = "";
                    this.successmessagewfh1 = "";
                    this.reasonwfh = "";
                    this.files = "";
                    this.getleavedata(this.getempdata);       
                }else{

                    Swal.fire({
                            title: 'OPPS',
                            text:   response.data.message,
                            icon: 'warning',
                        
                        });
                }
              
            })
   
      }
 },

async onSubmitDateChange(){
  if(!this.datetobechanged){
            this.errormessage_datechange = 'Please Enter Date You Want To Change.';
            setTimeout(() => {
                    this.errormessage_datechange = '';
                },3000);
            this.errormessage_reasonwfh = "";
            this.errormessage_datechange1 = "";
      }else if(!this.changeddate){
            this.errormessage_datechange1 = 'Please Enter Date You Want To Work On Instead.';
            setTimeout(() => {
                    this.errormessage_datechange1 = '';
                },3000);
            this.errormessage_reasonwfh = "";
            this.errormessage_datechange = "";

      }else if(!this.reasondatechange || !this.reasondatechange.trim()){
            this.errormessage_reason_datechange = 'Please Enter Reason.';
            setTimeout(() => {
                    this.errormessage_reason_datechange = '';
                },3000);
            this.errormessage_datechange = "";
            this.errormessage_datechange1 = "";
      }else{

            const getdata = {
                userId: mirror.ID,
                userEmail: mirror.USEREMAIL,
                datetobechanged:this.datetobechanged,
                changeddate:this.changeddate,
                files: this.files,
                reasondatechange:this.reasondatechange,
            };
            console.log(getdata);

            await axios.post(BASE_URL + '/api/applydatechange',getdata,{
            headers: {
                "Content-Type": "multipart/form-data",
            }})
            .then((response)=>{

                if(response.data.success == true){
                  // axios.post(BASE_URL + '/api/sendapplywfh',{
                  //               email: response.data.userEmail,
                  //               name:  response.data.userName,
                  //               lname:  response.data.userlName,
                  //               wfhdate: response.data.wfhdate,
                  //               days : response.data.days
                  //           });

                  axios.post(BASE_URL + '/api/storedatechangenotifications',{
                                empid: mirror.ID,
                                email: response.data.empid,
                                userName: mirror.USERNAME,
                                datetobechanged: response.data.datetobechanged,
                                changeddate: response.data.changeddate,
                                type : 'Date Change',
                                status : 'unseen'
                            });

                  setTimeout(() => {
                      this.isOpenDateChange = false;
                    },2000);

                    Swal.fire({
                                title: 'Done',
                                text:  response.data.message,
                                icon: 'success',
                            
                            });

                    this.datetobechanged = "";
                    this.changeddate = "";

                    this.successmessageDateChange1 = "";
                    this.reasondatechange = "";
                    this.files = "";
                    this.getleavedata(this.getempdata);
                }else{

                    Swal.fire({
                            title: 'OPPS',
                            text:   response.data.message,
                            icon: 'warning',
                        
                        });
                }
              
            })
   
      }
},

async applyforCarryForward(){
 console.log("leavetobecarried",this.state3.leavetobecarried);
 console.log("leavetobecashedin",this.state3.leavetobecashedin);
 var leavetobecarried = this.state3.leavetobecarried;
 var leavetobecashedin = this.state3.leavetobecashedin;
 if (leavetobecarried == undefined && leavetobecashedin == undefined) {
  this.errormessageCarryForward = 'Please enter no of leaves you want to carry forward or cash in';
 }
 else if(leavetobecarried>this.yearly_leave_count){
  this.errormessageCarryForward = 'Leaves to be carried forward can not be greater than remaining leave balance';
 }
 else if(leavetobecashedin>this.yearly_leave_count){
  this.errormessageCarryForward = 'Leaves to be cashed in can not be greater than remaining leave balance';
 }
 else if((leavetobecashedin + leavetobecarried)>this.yearly_leave_count){
  this.errormessageCarryForward = 'Sum of leaves to be cashed in and leaves to be carried forward can not be greater than remaining leave balance';  
 }
 else if((leavetobecashedin + leavetobecarried)>12){
  this.errormessageCarryForward = 'Sum of leaves to be cashed in and leaves to be carried forward can not be greater than 12';  
 }
 else{
  this.applylcfbtn = 'Please Wait..';
  if(leavetobecarried == null){
    leavetobecarried = 0;
  }
  if(leavetobecashedin == null){
    leavetobecashedin = 0;
  }
  await axios.post(BASE_URL + '/api/applyleavecarryforward',{id:this.ID, leavetobecarried:leavetobecarried, leavetobecashedin:leavetobecashedin, encryptedstring:this.encryptedstring})
          .then((response)=>{
            // console.log(response.data);

            if(response.data.success == true){
                    Swal.fire({
                                title: 'Done',
                                text:  response.data.message,
                                icon: 'success',
                            
                            });
                }else{

                    Swal.fire({
                            title: 'OPPS',
                            text : 'Something went wrong.',
                            icon : 'warning',
                        
                        });
                }

                setTimeout(() => {
                          this.isOpenCarryForward = false;
                        },2000);

                this.applylcfbtn = 'Submit';

  })
 this.fetchkey();

 }
 
},

  async getleavedata(getempdata){
     await axios.post(BASE_URL + '/api/getemployeeleaverecord',getempdata)
          .then((response)=>{
              //console.log(response.data);

            this.leaverecord = response.data.empdetails;
          //  console.log("leaverecord",this.Leave.getleavedetails);
            this.holiday_list = response.data.holiday_list;
            this.holiday_array = this.holiday_list.map(x => x.holiday_date);
            this.wfhrecord = response.data.wfh_details;
            this.datechangerecord = response.data.datechange_details;

            // this.holiday_list = response.data.holiday_list;
            // this.holiday_array = this.holiday_list.map(x => x.holiday_date);
            this.attendance_list = response.data.attendance_list;
            this.attendance_array = this.attendance_list.map(x => x.attendance_date);
            this.doj = response.data.details.doj;
            // this.wfhrecord = response.data.wfh_details;
            this.monthly_attendance_record_fullday = response.data.attendance_array_updated;
            this.monthly_attendance_record_halfday = response.data.attendance_array_updated_halfday;

      })
},

async getleaveremaining(getempdata){
     await axios.post(BASE_URL + '/api/getemployeeclml',getempdata)
          .then((response)=>{
              // console.log(response.data);

              this.remaining_leave = response.data.remaining_leave;
              this.remaining_leave_ml = response.data.remaining_leave_ml;
              this.provisional_leave_count = response.data.provisional_leave_count;

      })
},

async downloadsalaryslip(id){
  var image;
  await axios.post(BASE_URL + '/api/downloadsalary',{id:id})
          .then((response)=>{
            image = response.data.salaryslip.pdf;
              console.log(response.data.salaryslip.pdf);

      })
  var pdf = new jsPDF("1", "mm", "a2");      
  pdf.addImage(image, 'JPEG', 15, 40, 500, 2000);
  pdf.save(this.userName + '-salaryslip-' + this.salary_month_name + '-' + this.salary_year + '.pdf');
},

async toDataUrl(){

    
// Fetch the image
await fetch('https://cdn.logo.com/hotlink-ok/logo-social.png')
  .then(response => response.blob())
  .then(blob => {
    // Read the image data as a Data URL
    const reader = new FileReader();
    reader.onloadend = () => {
      // Convert the Data URL to base64
      const base64Data = reader.result.split(',')[1];
      console.log(base64Data);
    return base64Data;

    };
    reader.readAsDataURL(blob);
  })
  .catch(error => {
    console.error('Error fetching or converting the image:', error);
  });


    

    //   html2pdf(content, {
    //     margin: 1,
    //     filename: "document.pdf",
    //     image: { type: "jpeg", quality: 0.98 },
    //     html2canvas: { dpi: 192, letterRendering: true },
    //     jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
    //   });
},

async exportToPDF(id) {
    const toDataUrl = async function (url, callback) {
        //Convert to base64
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.send();
        });
    };

    var url = "https://employee.works-reddensoft.com/admin/public/assets/reddensoft_logo.svg";

    let urlBase64 = await toDataUrl(url);

    var month = 5;
    var year = 2023;
    var date_currently = new Date();
    var month_currently = date_currently.getMonth();
    var year_currently = date_currently.getFullYear();
    axios.post(BASE_URL + '/api/fetchsalarydetailsforpayslip',{ id:id, month:month_currently, year:year_currently})
    .then((response)=>{
        console.log(response.data.salary_details);
        this.month_slip = response.data.salary_details.month_name;
        this.year_slip = response.data.salary_details.year;
        this.empid_slip = response.data.salary_details.empid;
        this.empname_slip = response.data.salary_details.empname;
        this.doj_slip = response.data.salary_details.doj;
        this.address_slip = response.data.salary_details.address;
        this.position_slip = response.data.salary_details.position;
        this.dept_slip = response.data.salary_details.dept;
        this.basic_pay_slip = response.data.salary_details.basic_slip;
        this.hra_pay_slip = response.data.salary_details.hra_slip;
        this.edu_allow_pay_slip = response.data.salary_details.edu_allow_slip;
        this.other_allow_pay_slip = response.data.salary_details.other_allow_slip;
        this.basic_payable_slip = response.data.salary_details.basic_payable;
        this.hra_payable_slip = response.data.salary_details.hra_payable;
        this.other_allow_payable_slip = response.data.salary_details.other_allow_payable;
        this.edu_allow_payable_slip = response.data.salary_details.edu_allow_slip_payable;
        this.pf_payable_slip = response.data.salary_details.pf_payable;
        this.ptax_payable_slip = response.data.salary_details.ptax_payable;
        this.advance_payable_slip = response.data.salary_details.advance_payable;
        this.totalhead_slip_payable = response.data.salary_details.totalhead_slip;
        this.payablehead_slip_payable = response.data.salary_details.payablehead_slip;
        this.deductionhead_slip_payable = response.data.salary_details.deductionhead_slip;
        this.total_days_salaryslip = response.data.salary_details.total_days_slip;
        this.lossofpay_salaryslip = response.data.salary_details.lossofpay_slip;
        this.paiddays_salaryslip = response.data.salary_details.paiddays_slip;
        this.netpayhead_slip_payable = response.data.salary_details.netpayhead_slip;
        this.salaryintext_payable = response.data.salary_details.salaryintext;
        this.cl_slip_payable = response.data.salary_details.cl_slip;
        this.ml_slip_payable = response.data.salary_details.ml_slip;

        this.salarydata = response.data.salary_details;
        //this.htmltopdf(this.salarydata);
        //var html = `<h1>${this.cl_slip_payable}</h1>`;

    //html2pdf(html);

    this.htmltopdf(this.salarydata,urlBase64);

    })
   
},

htmltopdf(res,logo){
    console.log("res",res);
  const resvalue = Object.values(res);

 var htmldemo = `
 <style></style>
 <div class="col-12" id="pdfFormat">
    <table style="border-spacing:0px;margin:auto;font-family:'Montserrat',sans-serif;padding: 20px; " bgcolor="#ffffff"
    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
    <tbody>
        <tr>
            <td>
                <table style="border-spacing:0px;font-family:'Montserrat',sans-serif;border: 1px solid #b9b9b9;height:1110px"
                    bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="padding: 20px 20px;">
                                <table width="100%" align="center" style="border-spacing:0px;" cellspacing="0"
                                    cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="padding-right: 10px;">
                                                <img width="100" height="28.11"
                                                    style=" vertical-align: text-top;text-align: center;"
                                                    src="`+ logo +`" alt="">
                                            </td>
                                            <td align="right">
                                                <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 15px;font-weight: 700;color: #000;padding:0 0 4px;">
                                                                Reddensoft Infotech Pvt. Ltd.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 10px;font-weight: 400;color: #000;">
                                                                AG block AMP Baisakhi, AG-112, Sector II, Bidhannagar, Kolkata, West Bengal 700091
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 10px;font-weight: 400;color: #000;padding:0 0 4px;">
                                                                <strong> PAN:</strong> AAICR8901L
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td align="center" style="border-top: 1px solid #b9b9b9;padding: 10px 0;">
                                <table align="center" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                    border="0">
                                    <tbody>
                                        <tr>
                                            <td align="center"
                                                style="font-size: 16px;line-height: 31px;color: #5097a9;font-weight: 600;">
                                                Pay Slip For The Month Of ${resvalue[33]} ${resvalue[34]}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td bgcolor="#E1F1F2"
                                style="border-top: 1px solid #b9b9b9;padding: 20px 20px 0 20px;vertical-align: top;">
                                <table style="border-spacing:0px; padding:0;" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top; padding:0;">
                                                <table style="border-spacing:0px; padding: 0;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;">
                                                                Emp Code</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 15px 0;">
                                                                ${resvalue[3]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;">
                                                                Name</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 15px 0;">
                                                                ${resvalue[2]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px; padding-bottom: 15px;">
                                                                DOJ</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 15px 0;">
                                                                ${resvalue[4]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;">
                                                                Location</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030; padding: 0 10px 0 0;">
                                                                
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding: 0 0 0 110px;vertical-align: top;">
                                                <table style="border-spacing:0px; padding: 0;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;vertical-align: top;">
                                                                Designations</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                ${resvalue[5]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;vertical-align: top;">
                                                                Department</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                NA
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;vertical-align: top;">
                                                                PAN</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                NA
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;vertical-align: top;">
                                                                UAN</td>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 0 0;">
                                                                NA
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style="border-top: 1px solid #b9b9b9; padding: 20px; vertical-align: text-top;">
                                <table width="100%" style="border-spacing:0px;width: 100%;background-color:#f0f0f0;" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top;">
                                                <table style="border-spacing:0px;width: 100%;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <thead>
                                                        <tr>
                                                            <th bgcolor="#8079A0" align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                Earning Head
                                                            </th>
                                                            <th bgcolor="#8079A0" align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                Rate
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 0;vertical-align: middle;">
                                                                Basic</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 10px;vertical-align: top;">
                                                                ${resvalue[6]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                HRA</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                ${resvalue[7]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                Other Allow</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                ${resvalue[9]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                EDU Allow</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                                ${resvalue[8]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="vertical-align: top;border-left: 1px solid #b9b9b9;">
                                                <table width="100%" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2" bgcolor="#8079A0" align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                Payable
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding: 10px;padding-top: 14px;padding-top: 14px;vertical-align: top;">
                                                                ${resvalue[20]}</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 10px;vertical-align: top;">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                ${resvalue[21]}</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                ${resvalue[26]}</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                ${resvalue[22]}</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="vertical-align: top;border-left: 1px solid #b9b9b9;">
                                                <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <thead>
                                                        <tr>
                                                            <th bgcolor="#8079A0" align="left"
                                                                style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                Deduction Head
                                                            </th>
                                                            <th bgcolor="#8079A0" align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                Amount
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding: 10px;padding-top: 14px;padding-top: 14px;">
                                                                PF</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 10px;vertical-align: top;">
                                                                ${resvalue[23]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                P.Tax</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;">
                                                                ${resvalue[24]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                Advance</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                ${resvalue[25]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                            </td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="vertical-align: top;border-left: 1px solid #b9b9b9;">
                                                <table width="100%" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr style="vertical-align: top;">
                                                            <td style="padding-bottom: 10px;">
                                                                <table width="100%" style="border-spacing:0px;" cellspacing="0"
                                                                    cellpadding="0" border="0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th bgcolor="#8079A0" align="left"
                                                                                style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                                Attendance Details
                                                                            </th>
                                                                            <th bgcolor="#8079A0"
                                                                                style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px 27px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                                Days
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="left"
                                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;">
                                                                                Month Day</td>
                                                                            <td align="center"
                                                                                style="font-size: 13px;font-weight: 400;color: #303030; padding: 10px 0; vertical-align: top;">
                                                                                ${resvalue[19]}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left"
                                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                                Monthly CL</td>
                                                                            <td align="center"
                                                                                style="font-size: 13px;font-weight: 400;color: #303030; padding: 10px 0;vertical-align: top;">
                                                                                ${resvalue[10]}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left"
                                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                                Monthly ML</td>
                                                                            <td align="center"
                                                                                style="font-size: 13px;font-weight: 400;color: #303030;padding: 10px 0;vertical-align: top;">
                                                                                ${resvalue[11]}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left"
                                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;">
                                                                                LWP/Absent</td>
                                                                            <td align="center"
                                                                                style="font-size: 13px;font-weight: 400;color: #303030;padding: 10px 0;vertical-align: top;">
                                                                                ${resvalue[12]}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left"
                                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                                Days Paid</td>
                                                                            <td align="center"
                                                                                style="font-size: 13px;font-weight: 400; padding: 10px 0; color: #303030;vertical-align: top;">
                                                                                ${resvalue[13]}</td>
                                                                            <!-- <td align="center" style="font-size: 13px;font-weight: 400;color: #303030;vertical-align: top;">{{ this.paiddays_slip - ((this.salaryslipmlcount + this.salaryslipclcount + this.dayspresent) - (presentcount_slip + cl_slip + ml_slip))}}</td> -->
                                                                            <!-- <td align="center" style="font-size: 13px;font-weight: 400;color: #303030;vertical-align: top;">{{ this.daysinmonth - (this.working_days_slip - (presentcount_slip+ + cl_slip + ml_slip))}}</td> -->
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td style="border-top: 1px solid #b9b9b9;padding:15px 15px 15px 0;">
                                                <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding: 10px 10px 10px;vertical-align: top;">
                                                                Total Rs.</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 10px 10px 10px 0;">
                                                                ${resvalue[27]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td
                                                style="border-top: 1px solid #b9b9b9;padding: 15px;border-left: 1px solid #b9b9b9;">
                                                <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                ${resvalue[28]}</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td
                                                style="border-top: 1px solid #b9b9b9;padding: 15px;border-left: 1px solid #b9b9b9;">
                                                <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                ${resvalue[29]}</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td
                                                style="border-top: 1px solid #b9b9b9;padding: 15px;border-left: 1px solid #b9b9b9;">
                                                <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                    border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="left"
                                                                style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;white-space: nowrap;">
                                                                Net Salary Payable</td>
                                                            <td align="right"
                                                                style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 10px 10px 10px 0;">
                                                                ${resvalue[30]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4"
                                                style="border-top: 1px solid #b9b9b9;padding: 15px;font-size: 12px;font-weight: 400;color: #000;">
                                                <span style="font-weight: 700;padding-right: 5px;">Salary In Words</span>
                                                ${resvalue[31]}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
</div>`;

console.log(htmldemo);
htmldemo += ``;
html2pdf(htmldemo,{filename: resvalue[2].concat("-","payslip").concat("-",resvalue[33].concat("-",resvalue[34])) });
},

},
mounted(){
this.read();

  const clickedState = localStorage.getItem('buttonClicked');
  if (clickedState) {
    this.clicked = JSON.parse(clickedState);
  }
},
watch: {
         '$route.query'() { 
          //console.log('querydata',newId)
          // this.url_res = this.$route.params.res;
          // this.getdata.url_res = this.url_res;
          var hash = window.location.hash.split(/,(.*)/s);
          this.notifiedType = hash[0].split("#")[1];
          this.notifiedDate = hash[1];
          console.log("hash14",hash);
          // this.getsearchresult(this.getdata);
          // setInterval(function () {window.location.hash =''}, 5000);
        }
     }
}
</script>