<template>
   

    <div class="main-right">
        <div class="main-title">
          <h2>{{this.url_username}}’s Attendance Record for {{this.date.getFullYear()}}</h2>
        </div>

        <div class="head-bar">
          <ul>
            <li>
          <!-- this.month_name = month_name_array[d.getMonth()]; -->
              Attendance Record for <span>{{this.month_name_array[selected_month_index]}} </span>
            </li>
          </ul>
          <div class="fild-info">
            <button class="dwnlod-btn" @click="clickedattrecDownload()">
              download
              <img src="@/assets/images/icon/download.svg" alt="">
            </button>
            <button class="stl-btn" id="modal-tggl1" @click="isOpen()">
              Select a Month
            </button>
          </div>
        </div>

        <div class="divider-wap" >
          <div class="left-set-at" id="attrec">
            <div class="card-attendance">
              <div class="item">
                <p class="ttx" >Total Days Present – {{this.attendance_array_yearly_updated.length + this.attendance_array_yearly_updated_halfday.length/2}}</p>
                <!-- <p class="ttx" v-if="this.date.getFullYear()!=new Date().getFullYear()">Total Days Present – {{}}</p> -->
              </div>
              <div class="item">
                <p class="ttx" >{{this.month_name_array[this.selected_month_index]}} Present – {{this.monthly_attendance_array_updated.length + this.monthly_attendance_array_updated_halfday.length/2}} </p>
                <!-- <p class="ttx" v-if="this.date.getFullYear()!=new Date().getFullYear()">{{this.month_name_array[this.date.getMonth()]}} Present – {{}} </p> -->
              </div>
              <div class="item">
                <p class="ttx" >Total Days Absent - {{(this.working_days_without_holidays -this.yearly_holidays - this.attendance_array_yearly_updated.length - this.attendance_array_yearly_updated_halfday.length/2)}}</p>
                <!-- <p class="ttx" v-if="this.date.getFullYear()!=new Date().getFullYear()">Total Days Absent - {{}}</p> -->
              </div>
              <div class="item">
                <p class="ttx" v-if="todayPresent" >{{this.month_name_array[this.selected_month_index]}} Absent – {{this.total_weekdays_monthly- this.commonDates.length  - this.monthly_attendance_array_updated.length - this.monthly_attendance_array_updated_halfday.length/2}}</p>
                <p class="ttx" v-else >{{this.month_name_array[this.selected_month_index]}} Absent – {{this.total_weekdays_monthly- this.commonDates.length  - this.monthly_attendance_array_updated.length - this.monthly_attendance_array_updated_halfday.length/2 - 1}}</p>
                <!-- <p class="ttx" v-if="this.date.getFullYear()!=new Date().getFullYear()">{{this.month_name_array[this.date.getMonth()]}} Absent – {{}}</p> -->
              </div>
              <!-- <div class="item">
                <p class="ttx" v-if="this.date.getFullYear()==new Date().getFullYear()">Total WFH Days - 0 </p>
                <p class="ttx" v-if="this.date.getFullYear()!=new Date().getFullYear()">Total WFH Days - 0 </p>
              </div>
              <div class="item">
                <p class="ttx" v-if="this.date.getFullYear()==new Date().getFullYear()">{{this.month_name_array[this.date.getMonth()]}} WFH – 0 days </p>
                <p class="ttx" v-if="this.date.getFullYear()!=new Date().getFullYear()">{{this.month_name_array[this.date.getMonth()]}} WFH – 0 days </p>
              </div> -->
              
            </div>
            <div class="table-section">
              <div class="table-wapper">
                
                <div class="table-data table-w-100 p-0">
                  
                  <table cellspacing="0" cellpadding="0" border="0">
                    <thead>
                      <tr>
                        
                        <th>Date</th>
                        <th>Sign In</th>
                        <th>Sign Out</th> 
                        <th>Hours Worked</th> 
                        <th>Status</th> 
                        <th>Remarks</th> 
                        <!-- <th>Action</th>  -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="user in users" :key="user" :style="'background: ' + (this.holiday_array_formatted.includes(user.attendance_date) || this.weekendDaysFormatted.includes(user.attendance_date) ? 'rgb(231 209 191)'  : '') ">
                        <td >{{user.attendance_date}}</td>
                        <td>{{user.start_time}}</td>
                        <td>{{user.end_time}}</td>
                        <!-- <td>{{user.hourly_works}}</td> -->
                        <td :style="'color: ' + (user.hourly_works >= '08:00:00' ? 'rgb(70 205 72)' : user.hourly_works >= '04:00:00' && user.hourly_works <= '08:00:00' ? 'rgb(197 197 18)' : '#FF0000')">{{user.hourly_works}}</td>
                        <td>{{ user.attendance_status }}</td>
                        <td v-if="user.attendance_status == 'Manual'">-</td>
                        <td v-else-if="user.attendance_status == 'Automated' && user.remarks !=null" :title="user.remarks">
                          <span v-if="user.remarks.length > 50">{{ user.remarks.substring(0, 50) + '...' }}</span>
                          <span v-else>{{ user.remarks }}</span>
                        </td>
                        <td v-else >-</td>
                        
                        <!-- <td >
                        <div v-if="this.holiday_array_formatted.includes(user.attendance_date) || this.weekendDaysFormatted.includes(user.attendance_date)"><button>Approve</button></div>
                        <div v-else>---</div>
                        </td> -->
                        <!-- <td v-if="!user.hourly_works"><p style="background-color: brown;"></p></td> -->
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
    
              </div>
            </div>
          </div>
          <div class="popup-modal" id="popupModa1" v-if="seen">
          <div class="mp-content">
          <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
            <img src="@/assets/images/icon/x-lg.svg" alt="">
          </button>
          <div class="bdy-sect">
            <h3>Select a month</h3>

   <month-picker @change="fetchDate"  no-default></month-picker>
              

 
          </div>
          
        </div>
          </div>
          

        </div>

        
       
        
        
        

    </div>
    
</template>

<script>
import { createLocal, createSession, createStorage } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Datepicker from 'vue3-datepicker';
import { MonthPicker } from 'vue-month-picker'

const mirror = createLocal()
const storage = mirror._prx

import Swal from 'sweetalert2'

import { ref, computed } from 'vue'

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js";

// import ModalApplyForLeave from '../UserDashboard/ModalApplyForLeave.vue'
// import { AnyMap } from '@jridgewell/trace-mapping';
// import { assertAnyTypeAnnotation } from '@babel/types';
export default {
name: 'AdminAttendanceReport',
components:{
    Datepicker,
    MonthPicker

},
data() {
        return {
        
            storageData: mirror,
            storage: storage,
            users: [],
            searchQuery: null,
            dataVal:{},
            seen : false,
            date:new Date(),
            title:'',
            holidaydata:[],
            getHolidayData:{},
            submit_status:'',
            errormessage_date:'',
            errormessage_title:'',
            holiday_list:[],
            weekdays:[],
            weekdays1:[],
            getHolidayData:{},
            holiday_array:[],
            weekday_array:[],
            current_year:'',
            url_data:'',
            url_daysPresent:'',
            url_username:'',
            count:'',
            count1:'',
            holiday_weekend_count:'',
            index:'',
            month_name_array:'',
            commonDates:[],
            holiday_list_monthly:'',
            holiday_list_yearly:'',
            emp_doj:'',
            working_days_without_holidays:'',
            yearly_holidays:'',
            attendance_count_yearly:'',
            total_weekdays_monthly:'',
            days_UptoToday:'',
            days_UptoToday_array:[],
            commonholidays:'',
            newWeekdays:[],
            attendance_array:'',
            attendance_list:'',
            monthly_attendance_count:'',
            attendance_list_yearly:'',
            attendance_array_yearly:'',
            yearly_attendance_count:'',
            highlightedDate:'',
            holiday_array_formatted:'',
            weekendDays:'',
            weekendDaysFormatted:'',
            monthly_attendance_array_updated:[],
            monthly_attendance_array_updated_halfday:[],
            attendance_array_yearly_updated:[],
            attendance_array_yearly_updated_halfday:[],
            fetcheddate:{monthIndex: new Date().getMonth()+1, year: new Date().getFullYear()},
            selected_month_index:"",
            todayPresent:''
        }
    },
    async created(event){
      
      this.current_year= new Date().getFullYear();
      this.holidayData(this.current_year);
      this.url_data = this.$route.params.id;
      // this.url_daysPresent = this.$route.params.present;
      this.url_username = this.$route.params.uname;
      this.fetchDate(this.fetcheddate);



      // console.log("created",this.date);

      // console.log("ffffffff",this.url_data);
      this.month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  
      // console.log("dkbbd",this.holiday_array);
      // console.log("grdgrdg",date.parse(this.date));
      
      
    },
    methods : {

        // async clickedattrecDownload(){
        //   var pdf = new jsPDF();
        //   var element = document.getElementById('attrec');
        //   var width= element.style.width;
        //   var height = element.style.height;
        //       html2canvas(element).then(canvas => {
        //           //console.log(canvas);
        //           var image = canvas.toDataURL('image/png');
        //           pdf.addImage(image, 'JPEG', 15, 40, width, height);
        //           pdf.save('AdminAttendanceRecord' + '.pdf')
        //   });
        // },

        async clickedattrecDownload() {
          const element = document.getElementById('attrec');
          
          const opt = {
            margin: 0.5,
            filename: 'AdminAttendanceRecord.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
          };
          
          // New Promise-based API
          html2pdf().from(element).set(opt).save();
        },

       async fetchDate(date){
        console.log("vtyd5y",date);
        this.fetcheddate = date;

        var today_date = new Date();
        var date_current = today_date.toLocaleString().split(",")[0].split("/")[1];
        // console.log("date_current",date_current);
        var current_month = today_date.getMonth();
        // var dateStringified = date.split(",").split(" ");
        // console.log("dateStringified",dateStringified);
        var holidayMonth = date.monthIndex-1;
        // var dateFormatted = dateStringified[2]+'/'+holidayMonth+'/'+dateStringified[3];
        // var pickedDate = dateStringified[2];
        // pickedDate = pickedDate < 10 ? pickedDate.replace('0','') : pickedDate;
        // console.log("pickedDate",pickedDate);
        var pickedyear = date.year;
        // var pickedyear = dateStringified[3];
        console.log("pickedyear",pickedyear);
        var today_date = new Date();
        var date_current = today_date.toLocaleString().split(",")[0].split("/")[1];
        // this.highlightedDate = holidayMonth+1+'/'+pickedDate + '/'+dateStringified[3];




        // console.log("kdyugdugd",this.holiday_array.includes(dateFormatted));
   this.monthName = date.month;
   this.selected_year = date.year;
   console.log("month",date.month);
   console.log("year",date.year);
    console.log("date",date);
 var current_year = date.year;
 var current_month_monthpicker  = date.monthIndex;
 this.selected_month_index = date.monthIndex -1;
   console.log("year_curr",current_year);
  //  var holidayMonth = current_month -1;

//  var current_date = this.date.toLocaleString().split(",")[0].split("/")[1];
 this.getHolidayData = {"year":current_year, "selected_month":current_month_monthpicker-1,'id':this.url_data, 'date_current':date_current};

            // console.log(this.getHolidayData);

        await axios.post(BASE_URL + '/api/getMonthlyAttendanceData',this.getHolidayData)
                .then((response)=>{
                  this.users=  response.data.attendance_details;
                  this.count=  response.data.attendance_count;
                  this.holiday_list_monthly = response.data.holiday_list_monthly;
                  this.holiday_list_yearly = response.data.holiday_list_yearly;
                  this.attendance_count_yearly= response.data.attendance_count_yearly;
                  this.emp_doj = response.data.emp_doj;
                  this.holiday_array_monthly = this.holiday_list_monthly.map(x => x.holiday_date);
                  this.holiday_array_yearly = this.holiday_list_yearly.map(x => x.holiday_date);
                  this.weekdays = response.data.days;
                  this.weekendDays = response.data.dates_for_selected_month;
                  this.days_UptoToday = response.data.days_UptoToday;
                  this.days_UptoToday.forEach(formatFunction) ;
                  this.attendance_list = response.data.attendance_list;
                  this.attendance_array = this.attendance_list.map(x => x.attendance_date);
                  this.attendance_list_yearly = response.data.attendance_list_yearly;
                  this.attendance_array_yearly = this.attendance_list_yearly.map(x => x.attendance_date);
                  this.monthly_attendance_array_updated = response.data.attendance_array_updated;
                  this.monthly_attendance_array_updated_halfday = response.data.attendance_array_updated_halfday;
                  this.attendance_array_yearly_updated_halfday = response.data.attendance_array_yearly_updated_halfday;
                  this.attendance_array_yearly_updated = response.data.attendance_array_yearly_updated;
                  this.todayPresent = response.data.todayPresent;

                  console.log("weekendDays",this.weekendDays);
                  console.log("weekdays",this.weekdays);
                  // console.log("days_UptoToday",this.weekendDays);
                  console.log("attendance_count_yearly",this.attendance_count_yearly);
                  console.log("holiday_array_yearly",this.holiday_array_yearly);
                  console.log("holiday_array_monthly",this.holiday_array_monthly);
                  console.log("attendance_array_yearly",this.attendance_array_yearly);
                  console.log("attendance_array_updated",this.monthly_attendance_array_updated);
                  // console.log("attendance_count_yearly",this.attendance_count_yearly);
                  // this.weekday_array = this.weekdays.map()


                  this.seen = false;

            })
        this.weekendDaysFormatted = new Array();
        for (let i = 0; i < this.weekendDays.length; i++) {
        var split_weekendDays = this.weekendDays[i].split(" ")[0].split("-");
        split_weekendDays[2] = split_weekendDays[2] < 10 ? split_weekendDays[2].replace('0','') : split_weekendDays[2];
        console.log("dbgffvt",split_weekendDays[2]);
        
        this.weekendDaysFormatted.push(split_weekendDays[1].concat("/", split_weekendDays[2]).concat("/", split_weekendDays[0]))
        }

        console.log("weekend array",this.weekendDaysFormatted);
        console.log("week days",this.attendance_array_yearly);
        var attendance_array_formatted = new Array();
        for (let i = 0; i < this.attendance_array.length; i++) {
        var newDate = new Date(this.attendance_array[i]);
        var year = newDate.getFullYear()
        var month = String(newDate.getMonth() + 1);
        var day = String(newDate.getDate()).padStart(2, '0')
        // console.log("sgdygfy",year,month,day);
        attendance_array_formatted.push(month.concat("/",day).concat("/",year))
        }
        console.log("attendance_array_formatted",attendance_array_formatted);
        console.log("holiday_array_monthly",this.holiday_array_monthly);
          var holiday_array_formatted = new Array();

        for (let i = 0; i < this.holiday_array_monthly.length; i++) {
          var split_holiday = this.holiday_array_monthly[i].split("/");
          holiday_array_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]))
        }
        console.log("holiday_array_formatted",holiday_array_formatted);
        attendance_array_formatted = attendance_array_formatted.filter( ( el ) => !holiday_array_formatted.includes( el ) );
        console.log("attendance_array_formatted",attendance_array_formatted);
        var sat_attendance = new Array();   
        var sun_attendance = new Array();
     
        for (let i = 0; i < attendance_array_formatted.length; i++) {
        var newDate = new Date(attendance_array_formatted[i]);
        // console.log("newDate",newDate);
        if(newDate.getDay()==6){   //if Sunday
                sat_attendance.push(i);
                console.log("sat",sat_attendance);
            }
            if(newDate.getDay()==0){   //if Saturday
                sun_attendance.push(i);
                console.log("sun",sun_attendance);

            }
          }

        this.monthly_attendance_count = attendance_array_formatted.length - sat_attendance.length - sun_attendance.length;
        var holiday_array_check = new Array();
        for (let i = 0; i < holiday_array_formatted.length; i++) {
          var split_holiday = holiday_array_formatted[i].split("/");
          split_holiday[1] = split_holiday[1] < 10 ? split_holiday[1].replace('0','') : split_holiday[1];
          holiday_array_check.push(split_holiday[0].concat("/", split_holiday[1]).concat("/", split_holiday[2]))
        }
        this.holiday_array_formatted = holiday_array_check;
        console.log(" this.holiday_array_formatted",holiday_array_check);
        console.log("monthly_attendance_count",this.monthly_attendance_count);

//
        var attendance_array_yearly_formatted = new Array();
        for (let i = 0; i < this.attendance_array_yearly.length; i++) {
        var newDate = new Date(this.attendance_array_yearly[i]);
        var year = newDate.getFullYear()
        var month = String(newDate.getMonth() + 1);
        var day = String(newDate.getDate()).padStart(2, '0')
        // console.log("sgdygfy",year,month,day);
        attendance_array_yearly_formatted.push(month.concat("/",day).concat("/",year))
        }
        console.log("attendance_array_yearly_formatted",attendance_array_yearly_formatted);
        console.log("holiday_array_monthly",this.holiday_array_yearly);
          var holiday_array_yearly_formatted = new Array();

        for (let i = 0; i < this.holiday_array_yearly.length; i++) {
          var split_holiday = this.holiday_array_yearly[i].split("/");
          holiday_array_yearly_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]))
        }
        console.log("holiday_array_yearly_formatted",holiday_array_yearly_formatted);
        attendance_array_yearly_formatted = attendance_array_yearly_formatted.filter( ( el ) => !holiday_array_yearly_formatted.includes( el ) );
        console.log("attendance_array_yearly_formatted",attendance_array_yearly_formatted);
        var sat_attendance_yearly = new Array();   
        var sun_attendance_yearly = new Array();
     
        for (let i = 0; i < attendance_array_yearly_formatted.length; i++) {
        var newDate = new Date(attendance_array_yearly_formatted[i]);
        // console.log("newDate",newDate);
        if(newDate.getDay()==6){   //if Sunday
                sat_attendance_yearly.push(i);
                console.log("sat",sat_attendance);
            }
            if(newDate.getDay()==0){   //if Saturday
                sun_attendance_yearly.push(i);
                console.log("sun",sun_attendance);

            }
          }
        this.yearly_attendance_count = attendance_array_yearly_formatted.length - sat_attendance_yearly.length - sun_attendance_yearly.length;
        console.log("yearly_attendance_count",this.yearly_attendance_count);
//
        var doj = this.emp_doj.doj;
        this.weekdays.forEach(formatFunction) ;
        function formatFunction(item,index,arr){
          arr[index] = item.split(" ")[0].split("-");
          arr[index] = arr[index][2].concat("/",arr[index][1]).concat("/",arr[index][0])
        }
        console.log("weekdays",this.weekdays);



        this.weekdaysFormatted = new Array();
        for (let i = 0; i < this.weekdays.length; i++) {
        var split_weekdays = this.weekdays[i].split(" ")[0].split("/");
        split_weekdays[1] = split_weekdays[1] < 10 ? split_weekdays[1].replace('0','') : split_weekdays[1];
        console.log("dbgffvt",split_weekdays[2]);
        
        this.weekdaysFormatted.push(split_weekdays[0].concat("/", split_weekdays[1]).concat("/", split_weekdays[2]))
        }
        console.log("weekdaysFormatted",this.weekdaysFormatted);






        console.log("holiday_array_monthly",this.holiday_array_monthly);
        this.commonDates= this.holiday_array_monthly.filter(i => this.weekdaysFormatted.includes(i));
        console.log("common",this.commonDates);
        // console.log("commonLength",this.commonDates.length);

//      
        // console.log("date created", holidayMonth);
        // console.log("date current", current_month);
        // console.log("vtfvjyta", dateFormatted);
        var initial_date = holidayMonth+1 + '/' + '1' + '/' + current_year;
        // console.log("vtfvjyta", initial_date);
        var d = new Date();
        var curr_year = d.toLocaleString().split(",")[0].split("/")[2];
        console.log("current year",d.toLocaleString().split(",")[0].split("/")[2]);
        // console.log("picked year",pickedyear);
    if (pickedyear==curr_year) {
          if (holidayMonth==current_month) {
        // console.log("ininin");
        var doj_split = doj.split("-");
        var doj_formatted = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2]);

        if (Date.parse(initial_date.replace(/-/g, ' '))>=Date.parse(doj_formatted.replace(/-/g, ' '))) {
          var d1 = new Date(initial_date);
        console.log("if",d1);
        }
        else{
        var d1 = new Date(doj_formatted);
        console.log("else",d1);
        
        }
        function getDatesInRange(startDate, endDate) {
          const date = new Date(startDate.getTime());

          var dates = [];

          while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
          }

          return dates;
        }
        var today_date = new Date();
        var date_current1 = today_date.toLocaleString().split(",")[0].split("/");
        var date_current2 = date_current1[2].concat("-",date_current1[0]).concat("-",date_current1[1]);
        var d2 = new Date(today_date);
        console.log("d2",d2);
        var workDayArray = getDatesInRange(d1, d2);
        console.log("workDayArray",workDayArray);
       

        // var total_days_monthly = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        // console.log("dugffdrf",total_days_monthly);
          
        var sat2 = new Array();   //Declaring array for inserting Saturdays
        var sun2 = new Array();   //Declaring array for inserting Sundays

        for(var i=0;i<workDayArray.length;i++){    //looping through days in month
            var newDate = workDayArray[i];
            // console.log("newDate",newDate);
            if(newDate.getDay()==0){   //if Sunday
                sun2.push(i);
                //  console.log("sun2",sun2.length);
            }
            if(newDate.getDay()==6){   //if Saturday
                sat2.push(i);
                //  console.log("sat2",sat2);
            }

        }
        var no_of_sat_and_sun_monthly = sat2.length + sun2.length;
        //  console.log("no_of_sat_and_sun",no_of_sat_and_sun_monthly);
        this.total_weekdays_monthly = workDayArray.length - no_of_sat_and_sun_monthly;
        console.log("working days monthly",this.total_weekdays_monthly);

        // console.log("holiday_array_monthly",this.holiday_array_monthly);
        var holiday_array_uptoDate = new Array();

        for (var i = 0; i < this.holiday_array_monthly.length; i++) {
          
          var d_split = this.holiday_array_monthly[i].split("/");
          var d_formatted = d_split[1].concat("/",d_split[0]).concat("/",d_split[2]);
          var today_date = new Date();
          var date_current1 = today_date.toLocaleString().split(",")[0];
          console.log("d_formatted",d_formatted);
          console.log("date_current1",date_current1);
          if (Date.parse(d_formatted.replace(/-/g, ' '))<=today_date.getTime() && Date.parse(d_formatted.replace(/-/g, ' '))>=Date.parse(doj_formatted.replace(/-/g, ' '))) {

          this.holiday_array_monthly[i]= new Date(d_formatted)
          holiday_array_uptoDate.push(this.holiday_array_monthly[i])
          }
        }

        console.log("holiday_array_monthly101",holiday_array_uptoDate);

        // this.commonDates= holiday_array_uptoDate.filter(i => workDayArray.includes(i));
        var sat3 = new Array();   //Declaring array for inserting Saturdays
        var sun3 = new Array();   //Declaring array for inserting Sundays

        for(var i=0;i<holiday_array_uptoDate.length;i++){    //looping through days in month
            var newDate = holiday_array_uptoDate[i];
            // console.log("newDateeeeee",newDate);
            if(newDate.getDay()==0){   //if Sunday
                sun3.push(i);
                //  console.log("sun3",sun3.length);
            }
            if(newDate.getDay()==6){   //if Saturday
                sat3.push(i);
                //  console.log("sat3",sat3);
            }

        }

        var holiday_inweekend = sun3.length + sat3.length;
        this.commonDates.length = holiday_array_uptoDate.length- holiday_inweekend;
        // console.log("holiday_inweekend",holiday_inweekend);
        // console.log("ifcommon",this.commonDates);
      }

      else if (holidayMonth<current_month) {
        var doj_split = doj.split("-");
        var doj_formatted = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2])
        // console.log("elseesle");
        this.weekdays.forEach(newFormatFunction) ;

        function newFormatFunction(item,index,arr){
          arr[index] = item.split("/");
          arr[index] = arr[index][1].concat("/",arr[index][0]).concat("/",arr[index][2])
        }
        // console.log("weekdays",this.weekdays);

        var weekdaysNew = new Array();
        // console.log("doj_formatted",doj_formatted);

        for (var i = 0; i < this.weekdays.length; i++) {
          if (Date.parse(this.weekdays[i].replace(/-/g, ' '))>=Date.parse(doj_formatted.replace(/-/g, ' '))) {
            // console.log("in",doj_formatted);
              weekdaysNew.push(this.weekdays[i])
          }
          
        }
        this.weekdays = weekdaysNew;
        // console.log("weekdaysNew",this.weekdays);

        
        this.total_weekdays_monthly = this.weekdays.length;
        this.weekdays.forEach(latestFormatFunction) ;

        function latestFormatFunction(item,index,arr){
          arr[index] = item.split("/");
          arr[index] = arr[index][1].concat("/",arr[index][0]).concat("/",arr[index][2])
        }

        this.weekdaysFormatted = new Array();
        for (let i = 0; i < this.weekdays.length; i++) {
        var split_weekdays = this.weekdays[i].split(" ")[0].split("/");
        split_weekdays[1] = split_weekdays[1] < 10 ? split_weekdays[1].replace('0','') : split_weekdays[1];
        console.log("dbgffvt",split_weekdays[2]);
        
        this.weekdaysFormatted.push(split_weekdays[0].concat("/", split_weekdays[1]).concat("/", split_weekdays[2]))
        }
        console.log("weekdaysFormatted",this.weekdaysFormatted);


        this.commonDates= this.holiday_array_monthly.filter(i => this.weekdaysFormatted.includes(i));
        console.log("elsecommon",this.weekdays);
        console.log("elsecommon2",this.holiday_array_monthly);
        console.log("elsecommon3",this.commonDates);

      }
      else{
        this.total_weekdays_monthly='0';
        this.commonDates.length='0';
        this.count='0'
      }
        }
    else if(pickedyear<curr_year){

      var doj_split = doj.split("-");
        var doj_formatted = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2])
        // console.log("elseesle");
        this.weekdays.forEach(newFormatFunction) ;

        function newFormatFunction(item,index,arr){
          arr[index] = item.split("/");
          arr[index] = arr[index][1].concat("/",arr[index][0]).concat("/",arr[index][2])
        }
        // console.log("weekdays",this.weekdays);

        var weekdaysNew = new Array();
        // console.log("doj_formatted",doj_formatted);

        for (var i = 0; i < this.weekdays.length; i++) {
          if (Date.parse(this.weekdays[i].replace(/-/g, ' '))>=Date.parse(doj_formatted.replace(/-/g, ' '))) {
            // console.log("in",doj_formatted);
              weekdaysNew.push(this.weekdays[i])
          }
          
        }
        this.weekdays = weekdaysNew;
        // console.log("weekdaysNew",this.weekdays);

        
        this.total_weekdays_monthly = this.weekdays.length;
        this.weekdays.forEach(latestFormatFunction) ;

        function latestFormatFunction(item,index,arr){
          arr[index] = item.split("/");
          arr[index] = arr[index][1].concat("/",arr[index][0]).concat("/",arr[index][2])
        }
        this.commonDates= this.holiday_array_monthly.filter(i => this.weekdays.includes(i));
    }
    else{
      this.total_weekdays_monthly='0';
        this.commonDates.length='0';
        this.count='0'
    }
       
      
      


        

//
        var d = new Date();
        console.log("d",d);
        var running_year = d.getFullYear();
        console.log("running_year",running_year);
        console.log("pickedyear",pickedyear);
        // console.log("dojyear",doj_formatted.split("-")[2]);
        

        var initial_date = '01-01'.concat("-",pickedyear);
        console.log("initial_date",initial_date);
        var doj_split = doj.split("-");
        //  console.log("doj",doj_split[1]);
        var doj_formatted = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2])
        console.log("doj_formatted",doj_formatted);
        var doj_year = doj_formatted.split("-")[2];
        var total_days;

        if (running_year==pickedyear) {
          console.log("if1");
          if (Date.parse(doj_formatted.replace(/-/g, ' '))>Date.parse(initial_date.replace(/-/g, ' '))) {

            var diffTime = d.getTime()-Date.parse(doj_formatted.replace(/-/g, ' '));
            //  console.log("diffTime",d);
            total_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            console.log("total days if",total_days); 
            // return total_days;
            }
          else{
            // var diffTime = Date.parse(d)-Date.parse(initial_date);
            var diffTime = d.getTime()-Date.parse(initial_date.replace(/-/g, ' '));
             console.log("diffTime",d.getTime());
            total_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            console.log("total days else",total_days); 
            // return total_days;

          }
        } else if (running_year>pickedyear){
          console.log("if2");
          if (doj_year==pickedyear) {
            if (Date.parse(doj_formatted.replace(/-/g, ' '))>Date.parse(initial_date.replace(/-/g, ' '))) {

              var diffTime = Date.parse(('12-31'.concat("-",pickedyear)).replace(/-/g, ' '))-Date.parse(doj_formatted.replace(/-/g, ' '));
              //  console.log("diffTime",d);
              total_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))+1;
              console.log("total days if1",total_days); 
              // return total_days;
              }
              else{
              var diffTime = Date.parse(('12-31'.concat("-",pickedyear)).replace(/-/g, ' '))-Date.parse(initial_date.replace(/-/g, ' '));
              //  console.log("diffTime",diffTime);
              total_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))+1;
              console.log("total days else1",total_days); 
              // return total_days;

              }
            }else if(doj_year<pickedyear){
              total_days = 365;
            }else{
              total_days = 0;
            }
         
                      
        }else{
          console.log("if3");
          total_days=0;
        }
       
           console.log("total days ifelse",total_days); 
        
        var sat = new Array();   //Declaring array for inserting Saturdays
        var sun = new Array();   //Declaring array for inserting Sundays



        function getDatesInRange(startDate, endDate) {
          const date = new Date(startDate.getTime());

          var dates = [];

          while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
          }

          return dates;
        }
        
        var start_date = doj_split[2].concat("-",doj_split[1]).concat("-",doj_split[0]);
        console.log("start_date",start_date);
        var start_date1 = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2]);
        // console.log("start_date1",start_date1);
        if (Date.parse(start_date1.replace(/-/g, ' '))> Date.parse(('01/01'.concat("/",pickedyear)).replace(/-/g, ' '))) {
          console.log("d1 if");

          var d1 = new Date(start_date);

        }
        else{
          console.log("d1 else");
          var d1 = new Date(pickedyear.toString().concat("-","01-01"));
          console.log("d1 value",d1);
        }
        // const d1 = new Date(start_date);
        if (running_year==pickedyear) {
          var today_date = new Date();
          var date_current1 = today_date.toLocaleString().split(",")[0].split("/");
          var date_current2 = date_current1[2].concat("-",date_current1[1]).concat("-",date_current1[0]);
          console.log("date_current",today_date );
          var d2 = new Date(today_date);
        }
        else if(running_year>pickedyear){
          var d2 = new Date(pickedyear.toString().concat("-",'12-31'));
          console.log("d2 else if",d2);
        }
     
        var fff = getDatesInRange(d1, d2);
        console.log("d1,d2",d1, d2);
        console.log("fff",fff);
        var fff_updated = fff.concat(d2);
        console.log("fff_updated",fff_updated);


        for(var i=0;i<fff.length;i++){    //looping through days in month
            var newDate = fff[i];
            // console.log(newDate);
            if(newDate.getDay()==0){   //if Sunday
                sun.push(i);
                 console.log("sun",sun);
            }
            if(newDate.getDay()==6){   //if Saturday
                sat.push(i);
                 console.log("sat",sat);
            }

        }
        
        var no_of_sat_and_sun = sat.length + sun.length;
        //  console.log("no_of_sat_and_sun",no_of_sat_and_sun);
         console.log("total_days",total_days);
        this.working_days_without_holidays = total_days - no_of_sat_and_sun;
        console.log("working days",this.working_days_without_holidays);
//
        
//
        var holiday_array_formatted = new Array();

        for (let i = 0; i < this.holiday_array_yearly.length; i++) {
          var split_holiday = this.holiday_array_yearly[i].split("/");
          holiday_array_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]))
          
        }
        // console.log("Format holiday", holiday_array_formatted);
        var holiday_list_updated = new Array();   
        

        if (Date.parse(doj_formatted.replace(/-/g, ' '))>Date.parse(initial_date.replace(/-/g, ' '))) {
        
        for (var i = 0; i < holiday_array_formatted.length; i++) {
          // console.log(this.holiday_array[i]);
          if (Date.parse(holiday_array_formatted[i].replace(/-/g, ' '))>Date.parse(doj_formatted.replace(/-/g, ' '))) {
            // console.log("in",doj_formatted);
              holiday_list_updated.push(holiday_array_formatted[i])
          }
          
        }


        }
        else{
          var diffTime = Date.parse(d.getTime())-Date.parse(initial_date.replace(/-/g, ' '));
        //  console.log("diffTime",diffTime);
          holiday_list_updated = holiday_array_formatted;
        //  console.log("total days else",total_days); 
          // return total_days;

        }
        console.log("total holiday", holiday_list_updated);
        var holiday_list_updated1 = new Array();
        var today_day = new Date();
        var split_d = today_day.toLocaleString().split(",")[0].split("/");
        var formatted_day = split_d[0].concat("/",split_d[1]).concat("/",split_d[2]);
        // console.log("formatted_day",formatted_day);
        for (var i = 0; i < holiday_list_updated.length; i++) {
          // console.log("for",Date.parse(today_day.getTime()));
          if (Date.parse(holiday_list_updated[i])<=today_day.getTime()) {
            // console.log("in",doj_formatted);
              holiday_list_updated1.push(holiday_list_updated[i])
          }
          
        }
        console.log("total holiday1", holiday_list_updated1);

        var sat = new Array();   //Declaring array for inserting Saturdays
        var sun = new Array();   //Declaring array for inserting Sundays

        for(var i=0;i<holiday_list_updated1.length;i++){    //looping through days in month
            var newDate = new Date(holiday_list_updated1[i])
            console.log("newDate",newDate);
            if(newDate.getDay()==0){   //if Sunday
                sun.push(i);
                console.log("sunh",sun);
            }
            if(newDate.getDay()==6){   //if Saturday
                sat.push(i);
                console.log("sath",sat);
            }

        }
        this.yearly_holidays = holiday_list_updated1.length-sat.length-sun.length;
        console.log("yearly holidays",holiday_list_updated1.length);
        console.log("yearly holidays1",this.yearly_holidays);
       
       },
       async holidayData(year){
       
        //     })
       },
       closeDatepicker(event){
          this.seen = false;
        },

        isOpen(){
        //   console.log("rr",this.submit_status);
          this.seen = true;
       },
    },
    

   
}
</script>