<template>
 
  
  <div class="main-right">
      <div class="main-title title_tbtn_alin">
        <h2> Attendance Report for {{this.monthName}} {{this.comma}} {{ this.selected_year }}</h2>
          <button class="stl-btn modal_caldn_btn" id="modal-tggl1" @click="isOpen()">
            <img src="@/assets/images/icon/calendar-plus.png" alt=""> Select a month 
          </button>
      </div>
   
      <div class="popup-modal" id="popupModa1" v-if="seen">
            <div class="mp-content">
            <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
              <img src="@/assets/images/icon/x-lg.svg" alt="">
            </button>
            <div class="bdy-sect">
              <h3>Select a month</h3>

              <month-picker @change="showDate"  no-default></month-picker>
                

  
            </div>
            
          </div>
      </div>
      <div class="head-bar">
        <ul>
          <li>
            Number of Employees: <span>{{this.attendance_details.length}}</span>
          </li>
        </ul>
        <div class="fild-info">
          <form action="#">
            <div class="fr-gp">
              <!-- <input type="search" placeholder="Search..."> -->
              <input type="text" v-model="searchQuery" placeholder="Search...">

              <!-- <button type="submit">
                <img src="../../assets/images/icon/search.svg" alt="">
              </button> -->
            </div>
          </form>
          <button v-if="attendance_details.length == 0" disabled class="dwnlod-btn" @click="clickedDownload()">
            download
            <img src="@/assets/images/icon/download.svg" alt="">
          </button>
          <button v-else class="dwnlod-btn" @click="clickedDownload()">
            download
            <img src="@/assets/images/icon/download.svg" alt="">
          </button>
        </div>
      </div>
   
      <div class="table-section" >
        <div class="table-wapper" id="divToPrintAttendanceReportList">
          <div class="table-data table-w-100" >
            
            <table cellspacing="0" cellpadding="0" border="0" >
              <thead>
                <tr>
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Employee Name </th>
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Employee ID</th>
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Days Present</th>
                  <!-- <th>Working Days</th>
                  <th>Days Absent</th>
                  <th>Attendance Ratio</th> -->
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Medical Leaves taken</th>
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Casual Leaves Taken</th>
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Date of Joining</th>
                  
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Position</th>
                  <th style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Contact No</th>
                  <th class="actions-header" style="background-color: #54a0ff; border-right: 1px solid rgba(224, 225, 227, .5); border-bottom: 1px solid rgba(224, 225, 227, .5); padding: 7px 8px; font-size: 14px; color: #ededed; font-weight: 500;">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in resultQuery" :key="index" class="downloadable-row">

                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    <div class="btn-text">
                      <!-- <a href="#" class="edtl" id="modal-tggl1">view record</a> -->
                      <router-link :to="`/adminattendancerecordpage/${item.id}/${item.name}`" class="edtl" id="modal-tggl1"><a v-bind:="index" target="_blank">{{item.name}}</a></router-link>

                    </div>
                  </td>
                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    <a v-bind:="index" target="_blank">{{item.empid}}</a>
                  </td>
                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    <!-- <div v-if="item.dCount == null">{{ '0' }}</div> -->
                    <div v-if="item.dCount != null">{{item.dCount.length + item.dCountHalf.length/2}}</div>
                  </td>
                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    <!-- <div v-if="item.medicalcount == null">{{ '0' }}</div> -->
                    <div v-if="item.medicalcount != null">{{item.medicalcount}}</div>
                  </td>
                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    <!-- <div v-if="item.casualcount == null">{{ '0' }}</div> -->
                    <div v-if="item.casualcount != null">{{item.casualcount}}</div>
                  </td>
                  
                  <!-- <td>{{ item.doj}}</td> -->
                  <!-- <td>{{getWorkingDays(item.doj) }}</td> -->
                  <!-- <td>{{getWorkingDays(item.doj)-holidayCount(item.doj) }}</td>
                  <td>{{this.working_days-item.active_status-this.no_of_holidays}}</td>
                  <td>{{(item.active_status/(this.working_days-item.active_status-this.no_of_holidays)).toFixed(2)}}</td> -->
                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    {{item.doj}}
                  </td>
                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    <a v-bind:="index" target="_blank">{{item.position}}</a>
                  </td>
                  <td style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    {{item.phone}}
                  </td>
                  <td class="actions-column" style="border-bottom: 1px solid #e0e1e380; border-right: 1px solid #e0e1e380; font-size: 13px; padding: 10px 8px; color: #404a5a;">
                    <div class="btn-lps">
                      <!-- <a href="#" class="edtl" id="modal-tggl1">view record</a> -->
                      <router-link :to="`/adminattendancerecordpage/${item.id}/${item.name}`" class="edtl" id="modal-tggl1">View details</router-link>
                    </div>
                </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
  </div>


  

    

</template>



<script>
 
  import { createLocal, createSession, createStorage } from 'the-storages'
  import { BASE_URL } from "../../config";
  import axios from "axios";


  const mirror = createLocal()
  const storage = mirror._prx

  import Swal from 'sweetalert2'

  import { ref, computed } from 'vue'


  import { jsPDF } from "jspdf";
  import html2canvas from 'html2canvas';
  import { MonthPicker } from 'vue-month-picker'
  import html2pdf from "html2pdf.js";

  // import ModalApplyForLeave from '../UserDashboard/ModalApplyForLeave.vue'
  // import { AnyMap } from '@jridgewell/trace-mapping';
  // import { assertAnyTypeAnnotation } from '@babel/types';
  export default {
    name: 'AdminAttendanceReport',
    components:{
      MonthPicker
    },
    setup() {
      const isOpen = ref(false)
      return { isOpen };

      const leavedate = ref();
    //return { v$: useVuelidate() };
      return {
        leavedate,
      }
    },
    data() {
      return {
        storageData: mirror,
        storage: storage,
        seen : false,

        leavedate: null,
        AttendanceData:{},
        attendance_details:[],
        working_days:'',
        searchQuery: null,
        year:'',
        month:'',
        today_date:'',
        selected_year:'',
        monthName:'',
        month_name_array:'',
        comma:'  ',
        AttendanceDetailsData:{},
        holiday_list:[],
        holiday_list_toDate:[],
        HolidayData:{},
        holiday_array:[],
        no_of_holidays:'',
        date: {
            from: null,
            to: null,
            month: null,
            year: null
          }
      }
    },
    async created(event){
      //  this.userName = mirror.USERNAME;
      //  this.userlName = storage.USERLNAME;
      //  this.userEmail = mirror.USEREMAIL;

      //  this.ID = mirror.ID;
      //  var id = this.ID;
      //  console.log("svsv",id);
        var d = new Date();
        var split_d = d.toLocaleString().split(",")[0].split("/");        
        this.today_date = split_d[1].concat("/",split_d[0]).concat("/",split_d[2])
        var current_year = new Date().getFullYear();
        this.year = current_year;
        this.month = new Date().getMonth();
        this.month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        this.monthName = this.month_name_array[this.month];
        this.selected_year = current_year;
        var current_month = this.month;
        console.log("yvftcft",current_year,current_month );
        this.AttendanceData = {"year":current_year, "month":current_month+1};
        const attendance_report_data= await axios.post(BASE_URL + '/api/getAttendanceReport',this.AttendanceData);
        this.attendance_details = attendance_report_data.data.userList;
        console.log(" list",this.attendance_details);

        // console.log("monthpicked",this.date);
        // console.log("dtftdftfd",this.date);
        // this.getAttendanceReport(this.year,this.month,  this.today_date);
        // this.getHolidays(this.year,  this.today_date);
        // this.getAttendanceReport();

        console.log("result query",this.holiday_list);
        // this.getWorkingDays(doj2);
        // console.log("wrok days",this.holidayCount('07-11-2022'));
        this.HolidayData = {"year":this.year, "date":this.today_date}

        await axios.post(BASE_URL + '/api/fechtHolidayUptoDate',this.HolidayData)
                        .then((response)=>{
          this.holiday_list_toDate = response.data.holiday_list_toDate;
          this.holiday_array = this.holiday_list_toDate.map(x => x.holiday_date)                
                        })
        console.log("vvvvv",this.holiday_array);
        // this.holidayCount('7-11-2021');

  },
  methods: {

    async clickedDownload() {
      var element = document.getElementById('divToPrintAttendanceReportList');

      // Store the "Actions" header and cells temporarily
      var headerCell = element.querySelector('.actions-header');
      var actionsCells = [];
      var rows = element.querySelectorAll('.downloadable-row');
      rows.forEach(row => {
        var actionsCell = row.querySelector('.actions-column');
        if (actionsCell) {
          actionsCells.push(actionsCell);
          actionsCell.remove();
        }
      });

      if (headerCell) {
        headerCell.remove();
      }

      var opt = {
        margin: 0.1,
        filename: 'AttendanceReport'.concat("-", this.monthName).concat("-", this.selected_year).concat(".", 'pdf'),
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        html2canvas: { scale: 1 },
        pagebreak: { mode: 'avoid-all', before: '#page2el' }
      };

      // Generate PDF after removing the "Actions" column and header
      await html2pdf().set(opt).from(element).save();

      // Restore the "Actions" header and cells after generating the PDF
      if (headerCell) {
        var headerRow = element.querySelector('thead tr');
        headerRow.appendChild(headerCell);
      }

      actionsCells.forEach((actionsCell, index) => {
        var row = rows[index];
        row.appendChild(actionsCell);
      });
    },

  // async getAttendanceReport(date){
  //  console.log("yfdfdf",this.date);
  //  var current_year = this.date.toLocaleString().split(",")[0].split("/")[2];
  //  var current_month = this.date.toLocaleString().split(",")[0].split("/")[0];
  //  var current_date = this.date.toLocaleString().split(",")[0].split("/")[1];
  //  this.AttendanceData = {"year":current_year, "month":current_month, "date":current_date};
  //  const attendance_report_data= await axios.post(BASE_URL + '/api/getAttendanceReport',this.AttendanceData);
  //  this.attendance_details = attendance_report_data.data.attendance_details;
  //  this.holiday_list = attendance_report_data.data.holiday_list_uptoDate;
  //  console.log("aaaaa",this.holiday_list);
  //  return this.holiday_list;

  // },

  // getWorkingDays(doj){
  //  var d = new Date();
  //  var running_year = d.getFullYear();
  //  var initial_date = '01-01'.concat("-",running_year);
  // //  console.log("d",d);
  //  var doj_split = doj.split("-");
  //  console.log("doj",doj_split[1]);
  //  var doj_formatted = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2])
  // //  console.log("doj_formatted",doj_formatted);

  //  var total_days;
  //  if (Date.parse(doj_formatted)>Date.parse(initial_date)) {

  //    var diffTime = Date.parse(d)-Date.parse(doj_formatted);
  //   //  console.log("diffTime",diffTime);
  //    total_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   //  console.log("total days if",total_days); 
  //    // return total_days;
  //  }
  //  else{
  //    var diffTime = Date.parse(d)-Date.parse(initial_date);
  //   //  console.log("diffTime",diffTime);
  //    total_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   //  console.log("total days else",total_days); 
  //    // return total_days;

  //  }
  
  //  var sat = new Array();   //Declaring array for inserting Saturdays
  //  var sun = new Array();   //Declaring array for inserting Sundays

  //  for(var i=1;i<=total_days;i++){    //looping through days in month
  //      var newDate = new Date(d.getFullYear(),d.getMonth(),i)
  //      if(newDate.getDay()==0){   //if Sunday
  //          sun.push(i);
  //         //  console.log("sun",sun);
  //      }
  //      if(newDate.getDay()==6){   //if Saturday
  //          sat.push(i);
  //         //  console.log("sat",sat);
  //      }

  //  }
  
  //  var no_of_sat_and_sun = sat.length + sun.length;
  // //  console.log("no_of_sat_and_sun",no_of_sat_and_sun);
  //  this.working_days = total_days - no_of_sat_and_sun;
  //  return  this.working_days;
  // //  console.log("working days",this.working_days);
  // },


  // holidayCount(doj){
    

  //  var d = new Date();
  //  var running_year = d.getFullYear();
  //  var initial_date = '01-01'.concat("-",running_year);
  // //  console.log("d",d);
  //  var doj_split = doj.split("-");
  // //  console.log("doj",doj_split[1]);
  //  var doj_formatted = doj_split[1].concat("-",doj_split[0]).concat("-",doj_split[2]);
  //  var doj_formatted2 = doj_split[0].concat("/",doj_split[1]).concat("/",doj_split[2]);

  //  var total_holidays;

  //   console.log("countfunction",this.holiday_array);
  //   var holiday_array_formatted = new Array();

  //   for (let i = 0; i < this.holiday_array.length; i++) {
  //     var split_holiday = this.holiday_array[i].split("/");
  //     holiday_array_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]))
      
  //   }
  //   console.log("Format holiday", holiday_array_formatted);
  //   var holiday_list_updated = new Array();   
    

  //  if (Date.parse(doj_formatted)>Date.parse(initial_date)) {
    
  //     for (let j = 0; j < holiday_array_formatted.length; j++) {
  //       // console.log(this.holiday_array[i]);
  //       if (Date.parse(holiday_array_formatted[j])>Date.parse(doj_formatted)) {
  //         console.log("in",doj_formatted);
  //         holiday_list_updated.push(holiday_array_formatted[j])
  //       }
        
  //   } 


  //  }
  //  else{
  //    var diffTime = Date.parse(d)-Date.parse(initial_date);
  //   //  console.log("diffTime",diffTime);
  //    holiday_list_updated = holiday_array_formatted;
  //   //  console.log("total days else",total_days); 
  //    // return total_days;

  //  }
  //  console.log("total holiday", holiday_list_updated);
  //  var sat = new Array();   //Declaring array for inserting Saturdays
  //  var sun = new Array();   //Declaring array for inserting Sundays

  //  for(let k=1;k<holiday_array_formatted.length;k++){    //looping through days in month
  //      var newDate = new Date(holiday_array_formatted[k])
  //      if(newDate.getDay()==0){   //if Sunday
  //          sun.push(k);
  //          console.log("sun",sun);
  //      }
  //      if(newDate.getDay()==6){   //if Saturday
  //          sat.push(k);
  //          console.log("sat",sat);
  //      }

  //  }
  //  this.no_of_holidays = holiday_list_updated.length-sat.length-sun.length;
  // //  return holiday_array_formatted.length;
  //  return this.no_of_holidays;
  // },

  async showDate (date) {
    this.date = date;
    this.monthName = date.month;
    this.selected_year = date.year;
    console.log("month",date.month);
    console.log("year",date.year);
      console.log("date",date);
  var current_year = date.year;
  var current_month = date.monthIndex;
    console.log("year_curr",current_year);

  //  var current_date = this.date.toLocaleString().split(",")[0].split("/")[1];
  this.AttendanceData = {"year":current_year, "month":current_month};
  const attendance_report_data= await axios.post(BASE_URL + '/api/getAttendanceReport',this.AttendanceData);
  this.attendance_details = attendance_report_data.data.userList;
  console.log("showdate list",this.attendance_details);
  
  setTimeout(() => {
        this.seen = false;
      },500);
    },

  closeDatepicker(event){
            this.seen = false;
          },

  isOpen(){
          //   console.log("rr",this.submit_status);
            this.seen = true;
        },

  read(){

  }, 
  },

  computed: {
          resultQuery(){
          if(this.searchQuery){
          return this.attendance_details.filter((item)=>{
          
          return this.searchQuery.toLowerCase().split(' ').every( w => item.name.concat(item.empid).concat(item.position).toLowerCase().includes(w));
          //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
          })
          }else{
          //  console.log("details");
          return this.attendance_details;
          }
          },
  },
    

  mounted(){
    this.read();
  },


  }

</script>