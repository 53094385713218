<template>
  <HeaderAdmin />
  <div class="main_bottom_box">
    <SidebarAdmin />
    <AssignIncentivesTable />
  </div>
  gdygd7fdf
</template>

<script>
import HeaderAdmin from "../AdminDashboard/HeaderAdmin.vue";
import SidebarAdmin from "../AdminDashboard/SidebarAdmin.vue";
import AssignIncentivesTable from "./AssignIncentivesTable.vue";
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";

const mirror = createLocal()
const storage = mirror._prx
export default {
  name: "AssignIncentives",
  components: {
    HeaderAdmin,
    SidebarAdmin,
    AssignIncentivesTable,
  },
  data() {
        return {
            storageData: mirror,
            storage: storage,
        }
    },
    methods :{

    },
    async created(){
        this.userName = mirror.USERNAME
   },
    beforeRouteEnter(to, from, next) {
    //console.log(storage.get('TOKEN'));
        if (storage.get('TOKEN')) {
            next();
        }
        else {
            next('/');
        }
    }
};
</script>

