<template>

    <div class="main-right"> 
    
    <button class="sty-bbt" @click="resetpassword(url_data)">Reset Password</button>
       
    <form v-on:submit.prevent="updateForm" ref="form" name="form">
    <div class="leaveBox">
            <div class="leaveRightBox">
                <div class="leaveRightTop">
                    
                </div>
                <div v-if="errormessage1 != ''" class="errormessage">
                    {{ errormessage1 }}
                </div>
                <div v-if="successmessage1 != ''" class="successmessage">
                    {{ successmessage1 }}
                </div>
                <div class="main-title text-center">
                    <h2 class="fs-3">Edit Employee</h2>
    
                </div>
                
                <div class="addNewBox">
    
                    <label>Employee ID: </label> <span v-html="state3.empid"></span>
                            
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>First Name:</label>
                            <input class="addNewInput" type="text" :class="{ filderror: v3$.fname.$error }" v-model="state3.fname" placeholder="First Name">
                            <span class="error-span" v-if="v3$.fname.$error">Please Enter First Name</span>
                        </div>
                        <div class="addNewFirst adnleft">
                            <label>Last Name:</label>
                            <input class="addNewInput" type="text" :class="{ filderror: v3$.lname.$error }" v-model="state3.lname" placeholder="Last Name">
                            <span class="error-span" v-if="v3$.lname.$error">Please Enter Last Name</span>
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>Email Address:</label>
                            <!-- <input class="addNewInput" type="text" placeholder="Email Id"> -->
                            <input class="addNewInput" type="email" :class="{ filderror: v3$.email.$error }" v-model="state3.email" placeholder="Email Id" >
                            <span class="error-span" v-if="v3$.email.$error">Please Enter Email Address</span>
                        </div>
                        <div class="addNewFirst adnleft">
                            <label>Contact:</label>
                            <input class="addNewInput" type="text" :class="{ filderror: v3$.phone.$error }" v-model="state3.phone"  placeholder="Contact No">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <span class="error-span" v-if="v3$.phone.$error">Please Enter Contact Number</span>
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>Date of Joining:</label>
                             <!-- <input class="addNewInput" type="text" placeholder="DOJ"> -->
                             <input class="addNewInput" type="text" :class="{ filderror: v3$.doj.$error }" v-model="state3.doj" placeholder="DOJ" readonly>
                             <!-- <datepicker class="addNewInput"  :class="{ filderror: v3$.doj.$error }" v-model="state3.doj" placeholder="DOJ" readonly/> -->
                             <span class="error-span" v-if="v3$.doj.$error">Please Enter Date of Joining</span>
                        </div>
                        <div class="addNewFirst adnright">
                            <label>Date of Birth:</label>
                             <!-- <input class="addNewInput" type="text" placeholder="DOJ"> -->
                             <!-- <input class="addNewInput" type="date" :class="{ filderror: v3$.doj.$error }" v-model="state3.doj" placeholder="DOJ"> -->
                             <input class="addNewInput" type="text"  :class="{ filderror: v3$.dob.$error }" v-model="state3.dob" placeholder="DOB" readonly/>
                             <span class="error-span" v-if="v3$.dob.$error">Please Enter Date of Birth</span>
                        </div>
                        <div class="addNewFirst adnleft">
                            <label>Department:</label>
                                <select v-model="state3.dept">
                                    <option value="Project Management">Project Management</option>
                                    <option value="Web Design">Web Design</option>
                                    <option value="Front-end Development">Front-end Development</option>
                                    <option value="Back-end Development">Back-end Development</option>
                                    <option value="Email Marketing">Email Marketing</option>
                                    <option value="Business Development">Business Development</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="Human Resources (HR)">Human Resources (HR)</option>
                                    <option value="Administrative">Administrative</option>
                                </select>
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright" v-if="state3.dept == 'Email Marketing'">
                            <label>BWD Alias Name:</label>
                            <!-- <input class="addNewInput" type="text" placeholder="Designation"> -->
                            <input class="addNewInput" type="text" v-model="state3.marketing_name" placeholder="BWD Marketing Name">
                        </div>
                        <div class="addNewFirst adnright" v-if="state3.dept == 'Email Marketing'">
                            <label>LynkMe Alias Name:</label>
                            <!-- <input class="addNewInput" type="text" placeholder="Designation"> -->
                            <input class="addNewInput" type="text" v-model="state3.lynkme_marketing_name" placeholder="LynkMe Marketing Name">
                        </div>
                        <div class="addNewFirst adnright" v-if="state3.dept == 'Email Marketing'">
                            <label>BWD Title:</label>
                            <!-- <input class="addNewInput" type="text" placeholder="Designation"> -->
                            <input class="addNewInput" type="text" v-model="state3.bwd_title" placeholder="BWD Title">
                        </div>
                        <div class="addNewFirst adnright" v-if="state3.dept == 'Email Marketing'">
                            <label>LynkMe Title:</label>
                            <!-- <input class="addNewInput" type="text" placeholder="Designation"> -->
                            <input class="addNewInput" type="text" v-model="state3.lynkme_title" placeholder="LynkMe Title">
                        </div>
                        <div class="addNewFirst adnright">
                            <label>Designation:</label>
                            <!-- <input class="addNewInput" type="text" placeholder="Designation"> -->
                            <input class="addNewInput" type="text" :class="{ filderror: v3$.designation.$error }" v-model="state3.designation" placeholder="Designation">
                                <span class="error-span" v-if="v3$.designation.$error">Please Enter Designation</span>
                        </div>
                        <div class="addNewFirst adnleft">
                            <label>Casual Leave:</label>
                             <!-- <input class="smallBox" type="text" placeholder=""> -->
                             <input class="addNewInput" type="number" v-model="state3.casual" placeholder="Casual Leave" min="0">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                             <!-- <span class="error-span" v-if="v3$.casual.$error">Please Enter Casual Leave</span> -->
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>Sick Leave: </label>
                            <!-- <input class="smallBox" type="text" placeholder=""> -->
                            <input class="addNewInput" type="number"  v-model="state3.sick" placeholder="Sick Leave" min="0">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                            <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                        </div>
                        
                        <div class="addNewFirst adnright">
                            <label>Blood Group:</label>
                            <select class="addNewInput" v-model="state3.blood" placeholder="Select">
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB">AB</option>
                                <option value="A-">A-</option>
                                <option value="A+">A+</option>
                            </select>
                        </div>
    
                        
                        <div class="addNewFirst adnright">
                                <label>PAN: </label>
                                <!-- <input class="smallBox" type="text" placeholder=""> -->
                                <input class="addNewInput" type="text" v-model="state3.pan" placeholder="PAN" min="0">
                                    <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                    <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                            </div>
    
                            <div class="addNewFirst adnright">
                                <label>UAN: </label>
                                <!-- <input class="smallBox" type="text" placeholder=""> -->
                                <input class="addNewInput" type="text" v-model="state3.uan" placeholder="UAN" min="0">
                                    <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                    <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                            </div>
    
                            <div class="addNewFirst adnright">
                                <label>ESIC No: </label>
                                <!-- <input class="smallBox" type="text" placeholder=""> -->
                                <input class="addNewInput" type="text" v-model="state3.esic" placeholder="ESIC No" min="0">
                                    <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                    <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                            </div>
                    </div>
    
                    <div class="addNewSub">
                        <div class="addNewFirst adnleft addNewFlex">
                            <label>Address:</label>
                            <textarea class="addNewInText" rows="6"  v-model="state3.message" placeholder="Address"></textarea>
                            <!-- <span class="error-span" v-if="v3$.message.$error">Please Enter Address</span> -->
                        </div>
                    </div>
    
                    <div class="addNewSub">
                    <div class="addNewFirst adnleft">
                            <label>Upload Image:</label>
                             <!-- <input class="smallBox" type="text" placeholder=""> -->
                             <!-- <input class="addNewInput" type="number" v-model="state3.casual" placeholder="Casual Leave" min="0"> -->
                            <!-- <a href="{{$datas->logo}}" data-toggle="lightbox" data-title="Logo" data-gallery="gallery" /> -->
                            <div class="uploadimg-box">
                               
                            <img  v-if="!image && !imagefile" src="@/assets/no-image.png" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="white sample"/>
                            <img  v-else-if="imagefile" :src="imagefile" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="white sample"/>
                            <img  v-else v-bind:src="image" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="white sample"/>
                            <!-- <img src="@/assets/no-image.png" alt="" /> -->
                             
                            </div> <input style="margin-top: 15px;" type="file" accept="image/*" class="addNewInput" @change="updatePhoto">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.casual.$error">Please Enter Casual Leave</span> -->
                    </div>
                    <div class="addNewFirst adnleft" v-if="state3.dept == 'Email Marketing'">
                        <label>Digital Signature BWD:</label>
                        <div class="uploadimg-box">
                        <img v-if="!signature_file && !file" src="@/assets/no-image.png" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="white sample"/>
                        <img v-else-if="file" :src="file" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="signature preview"/>
                        <img v-else :src="signature_file" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="signature preview"/>
                        </div>
                        <input style="margin-top: 15px;" type="file" accept="image/*" class="addNewInput" @change="updateSignaturePhoto">
                    </div>
                    <div class="addNewFirst adnleft" v-if="state3.dept == 'Email Marketing'">
                        <label>Digital Signature LynkMe:</label>
                        <div class="uploadimg-box">
                        <img v-if="!signature_file1 && !file1" src="@/assets/no-image.png" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="white sample"/>
                        <img v-else-if="file1" :src="file1" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="signature preview"/>
                        <img v-else :src="signature_file1" width="30" style="border: 1px solid #000;" height="30" class="img-fluid mb-2" alt="signature preview"/>
                        </div>
                        <input style="margin-top: 15px;" type="file" accept="image/*" class="addNewInput" @change="updateSignaturePhotoLynkme">
                    </div>
                    </div>
    
                    <div class="frg__addNewSub">
                        <router-link to="/adminemplist" class="sty-bbt">Back</router-link>
                        <button class="sty-bbt" :disabled="buttonclick">Update</button>
                    </div>
    
                    <div class="main-error-msg" v-if="errormessage || successmessage" :class="errormessage ? '' : 'suce-area'">
                        <h5 :class="{show:errormessage}" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg> {{errormessage}}</h5> 
                        <h5 :class="{show:successmessage}" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                        </svg> {{successmessage}}</h5> 
                    </div>
                </div>
                
            </div>
        </div>
        </form>
    
    </div>    
    </template>
    
    <script>
    import { createLocal, createSession, createStorage } from 'the-storages'
    import { BASE_URL } from "../../config";
    import axios from "axios";
    const mirror = createLocal()
    const storage = mirror._prx
    
    import Datepicker from 'vue3-datepicker';
    
    import Swal from 'sweetalert2'
    
    import { defineComponent } from 'vue';
    import { reactive, computed } from "vue"
    import useValidate from "@vuelidate/core"
    import { required, email, minLength, maxLength } from "@vuelidate/validators"
    
    export default defineComponent({
      name: 'EditEmployeeDetails',
      setup() {
          const state3 = reactive({
              fname: '',
              lname:'',
              email: '',
              phone: '',
              doj: '',
              dob: '',
              designation:'',
              casual:'',
              sick:'',
              pan:'',
              uan:'',
              esic:'',
              marketing_name:'',
              lynkme_marketing_name:'',
              bwd_title:'',
              lynkme_title:''
          })
    
          const rules3 = computed(() => {
              return {
                  fname: { required },
                  lname: { required },
                  email: { required, email },
                  phone: { required, minLength: minLength(10), maxLength: maxLength(12) },
                //   message: { required },
                  doj:{ required },
                  dob:{ required },
                  designation:{ required },
                //   casual:{ required },
                //   sick:{ required }
              }
          })
          const v3$ = useValidate(rules3, state3)
    
          return {
              state3,
              v3$
          }
      },
      data() {
          return {
              form: {
                  fname: '',
                  lname:'',
                  email: '',
                  phone: '',
                  message:'',
                  doj:'',
                  dob:'',
                  designation:'',
                  casual:'',
                  sick:'',
                  photo: {},
                  pan:'',
                  uan:'',
                  esic:'',
                  marketing_name:'',
                  lynkme_marketing_name:'',
                  bwd_title:'',
                  lynkme_title:'',
                  signature: {}
              },
              errormessage: '',
              successmessage: '',
              errormessage1: '',
              successmessage1: '',
              files: "",
              type:"",
              state3: {},
              buttonclick:false,
              previewFile: null,
              previewUrl: null,
              dept: undefined,
              url_data: null,
              image:{},
              dept: undefined,
              fileSelected:'',
              signature_file:'',
              file:'',
              signature_file1:'',
              file1:'',
              imagefile:''
    
          }
      },
      methods:{ 
        async getResult(getempdata){
    
            await axios.post(BASE_URL + '/api/getadminempdata',getempdata)
                    .then((response)=>{
                        //console.log(response.data);
    
                        if(response.data.success == true){
    
                            this.state3.fname = response.data.fname;
                            this.state3.lname = response.data.lname;
                            this.state3.designation = response.data.position;
                            this.state3.email = response.data.email;
                            this.state3.phone = response.data.phone;
                            this.state3.doj = response.data.doj;
                            this.state3.dob = response.data.dob;
                            this.state3.dept = response.data.dept;
                            this.state3.empid = response.data.empid;
                            this.state3.casual = response.data.casual_leave;
                            this.state3.sick = response.data.sick_leave;
                            this.state3.message = response.data.address;
                            this.state3.blood = response.data.blood_group;
                            this.is_active = response.data.is_active;
                            this.image = response.data.image;
                            this.fileSelected = response.data.image;
                            this.signature_file = response.data.signature;
                            this.state3.pan = response.data.pan;
                            this.state3.uan = response.data.uan;
                            this.state3.esic = response.data.esic_num;
                            this.state3.marketing_name = response.data.alias;
                            this.state3.lynkme_marketing_name = response.data.alias_lynkme;
                            this.state3.bwd_title = response.data.bwd_title;
                            this.state3.lynkme_title = response.data.lynkme_title;
                            this.signature_file1 = response.data.signature_lynkme;
                            console.log("image",this.image);
                            // this.previewUrl = response.data.image_url;
                            // this.image = response.data.image;
    
                        }
    
                })
    
        },
    
        updatePhoto (event) {
            this.fileSelected = event.target.files[0];
            if (this.fileSelected) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.imagefile = e.target.result;
                };
                reader.readAsDataURL(this.fileSelected);
            } else {
                this.imagefile = '';
            }
        },


        updateSignaturePhoto(event) {
            this.signature_file = event.target.files[0];
            if (this.signature_file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.file = e.target.result;
                };
                reader.readAsDataURL(this.signature_file);
            } else {
                this.file = '';
            }
        },

        updateSignaturePhotoLynkme(event){
            this.signature_file1 = event.target.files[0];
            if (this.signature_file1) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.file1 = e.target.result;
                };
                reader.readAsDataURL(this.signature_file1);
            } else {
                this.file1 = '';
            }
        },
    
        async updateForm() {
            this.buttonclick = true;
            this.v3$.$validate()
            if (!this.v3$.$error) {
    
                //this.errormessage = '';
                this.buttonclick = false;
                //this.successmessage = 'Saved Successfully';
    
    
                    // const getdata = {
                    //     hiddenid: this.url_data,
                    //     fname: this.state3.fname, 
                    //     lname:this.state3.lname,
                    //     email:this.state3.email,
                    //     phone:this.state3.phone,
                    //     doj:this.state3.doj,
                    //     dob:this.state3.dob,
                    //     dept:this.state3.dept,
                    //     empid:this.state3.empid,
                    //     designation:this.state3.designation,
                    //     casual:this.state3.casual,
                    //     sick:this.state3.sick,
                    //     message:this.state3.message,
                    //     blood:this.state3.blood
                    // };
    
                    let formData = new FormData();
    
                    formData.append('hiddenid', this.url_data);
                    formData.append('fname', this.state3.fname);
                    formData.append('lname', this.state3.lname);
                    formData.append('email', this.state3.email);
                    formData.append('phone', this.state3.phone);
                    formData.append('doj', this.state3.doj);
                    formData.append('dob', this.state3.dob);
                    formData.append('dept', this.state3.dept);
                    formData.append('empid', this.state3.empid);
                    formData.append('designation', this.state3.designation);
                    formData.append('casual', this.state3.casual);
                    formData.append('sick', this.state3.sick);
                    formData.append('message', this.state3.message);
                    formData.append('blood', this.state3.blood);
                    formData.append('pan', this.state3.pan);
                    formData.append('uan', this.state3.uan);
                    formData.append('esic', this.state3.esic);
                    // if(this.fileSelected!=''){
                    formData.append('photo', this.fileSelected);
                    formData.append('alias', this.state3.marketing_name);
                    formData.append('alias_lynkme', this.state3.lynkme_marketing_name);
                    formData.append('bwd_title', this.state3.bwd_title);
                    formData.append('lynkme_title', this.state3.lynkme_title);
                    formData.append('signature', this.signature_file);
                    formData.append('signature_lynkme', this.signature_file1);
                    // }
                    //console.log(getdata);
    
                    await axios.post(BASE_URL + '/api/getadminupdateemp',formData)
                            .then((response)=>{
                                //console.log(response.data);
    
                                if(response.data.success == true){
    
                                    Swal.fire({
                                                title: 'Done',
                                                text:  response.data.message,
                                                icon: 'success',
                                            
                                            });
    
                                }else{
    
                                    Swal.fire({
                                            title: 'OPPS',
                                            text:   response.data.message,
                                            icon: 'warning',
                                        
                                        });
                                }
    
                        })
    
    
    
    
            } else {
              this.successmessage = '';
              this.errormessage = 'Please fill in mandatory fields';
              this.buttonclick = false;
            }
            //console.log("OUT:",this.v$.firstName.$error)
            console.log("email",this.state3.email);
            setTimeout(() => {
                this.successmessage = '';
                this.errormessage = '';
                //console.log("HI");
            }, 10000);
            
        },
    
        async resetpassword(id){
            console.log("hyjhg",id);
            await axios.post(BASE_URL + '/api/changeemppass',{id:id})
            .then((response)=>{
                                console.log(response.data);
    
                                if(response.data.success == true){
    
                                    Swal.fire({
                                                title: 'Done',
                                                text:  response.data.message,
                                                icon: 'success',
                                            
                                            });
    
                                }else{
    
                                    Swal.fire({
                                            title: 'OPPS',
                                            text:   response.data.message,
                                            icon: 'warning',
                                        
                                        });
                                }
    
                        })
    }
    
      },
      computed: {
    
     },
        async created(){
            this.userName = mirror.USERNAME
    
            this.url_data = this.$route.params.id;
    
            const getempdata = {
                    url_data: this.url_data
            };
            console.log(getempdata);
    
            this.getResult(getempdata);
            this.getempdata = getempdata;
       },
    });
    
    </script>
    
    <style scoped>
    
    </style>