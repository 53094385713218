<template>
    <HeaderAdmin />
    <div class="main_bottom_box">
        <SidebarAdmin />
        <AttendanceAdjustment />
    </div>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../config";
import axios from "axios";

const mirror = createLocal()
const storage = mirror._prx


import HeaderAdmin from "../components/AdminDashboard/HeaderAdmin.vue";

import SidebarAdmin from "../components/AdminDashboard/SidebarAdmin.vue";

import AttendanceAdjustment from "../components/AdminDashboard/AttendanceAdjustment.vue";


export default {
    name: 'AttendanceAdjustmentPage',
    components:{
        HeaderAdmin,
        SidebarAdmin,
        AttendanceAdjustment
    },
    data() {
        return {
            storageData: mirror,
            storage: storage,
        }
    },
    methods :{

    },
    async created(){
        this.userName = mirror.USERNAME
   },
    beforeRouteEnter(to, from, next) {
    //console.log(storage.get('TOKEN'));
        if (storage.get('TOKEN')) {
            next();
        }
        else {
            next('/');
        }
    }
}
</script>
<style scoped>
</style>