<template>
    <div class="main-right">
     <div class="main-title title_tbtn_alin">
       <h2>Employee List {{this.monthName}} {{this.comma}} {{ this.selected_year }}</h2>
       
       <!-- <button class="stl-btn modal_caldn_btn" id="modal-tggl1" @click="isOpen()">
        <img src="@/assets/images/icon/calendar-plus.png" alt=""> Select a month 
      </button> -->
     </div>
     
     <!-- <div class="popup-modal" id="popupModa1" v-if="seen">
            <div class="mp-content">
            <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
              <img src="@/assets/images/icon/x-lg.svg" alt="">
            </button>
            <div class="bdy-sect">
              <h3>Select a month</h3>
  
     <month-picker @change="showDate"  no-default></month-picker>
   
            </div>
            
          </div>
            </div> -->
     <div class="head-bar">
       <ul>
         <li>
           Number of Employees: <span>{{this.attendance_details.length}}</span>
         </li>
       </ul>
       <div class="fild-info">
         <form action="#">
           <div class="fr-gp">
             <!-- <input type="search" placeholder="Search..."> -->
             <input type="text" v-model="searchQuery" placeholder="Search...">
  
             <!-- <button type="submit">
               <img src="../../assets/images/icon/search.svg" alt="">
             </button> -->
           </div>
         </form>
         <button class="dwnlod-btn" @click="clickedDownload()">
           download
           <img src="@/assets/images/icon/download.svg" alt="">
         </button>
       </div>
     </div>
     
     <div class="table-section" id="divToPrintAttendanceReportList">
       <div class="table-wapper">
         <div class="table-data table-w-100">
           <form action="#">
            <table class="payroll-table" cellspacing="0" cellpadding="0" border="0">
             <thead>
               <tr>
                 <th>Employee ID</th>
                 <th>Employee Name</th>
                 
                 
                 <th>Basic Salary</th>
                 <th>HRA</th>
                 <th>Other Allowance</th>
                 <th>Educational Allowance</th>                 
                                
                 <th>EPF</th>
                 <th>Advance</th>
                 <th>Professional Tax</th>
                 <th>Action</th>
                 <th>Net Pay</th>
                 <th>Amount</th>
                 <th>Change</th>
               </tr>
             </thead>
             <tbody v-for="(row) in resultQuery" :key="row">
                <tr>
                  <td>
                    
                      {{row.id}}
                  </td>
                  <td>
                      {{row.empname}}
                      <!-- <div v-if="row.fname!=''">{{row.fname}} {{row.lname}}</div> -->
                  </td>
                  <td>
                    
                    <div v-if="row.id == rowtobeedited" >
                      <input type="number" v-model="state3.basic_salary" >
                    </div>
                    <div v-else > {{row.basic_salary}}</div>
                      
                  </td>
                  <td>
                    <div v-if="row.id == rowtobeedited" >
                      <input type="number"  v-model="state3.hra" >
                    </div>
                    <div v-else > {{row.hra}}</div>

                  </td>
                  <td>
                    <div v-if="row.id == rowtobeedited" >
                      <input type="number" v-model="state3.other_allow" >
                    </div>
                    <div v-else > {{row.other_allow}}</div>
                      
                  </td>                  
                  <td>
                    <div v-if="row.id == rowtobeedited" >
                      <input type="number" v-model="state3.edu_allow" >
                    </div>
                    <div v-else > {{row.edu_allow}}</div>
                      
                  </td>                  
                  <td>
                    <div v-if="row.id == rowtobeedited" >
                      <input type="number" v-model="state3.pf" >
                    </div>
                    <div v-else > {{row.pf}}</div>
                      
                  </td>                 
                  <td>
                    <div v-if="row.id == rowtobeedited" >
                      <input type="number" v-model="state3.ptax" >
                    </div>
                    <div v-else > {{row.ptax}}</div>
                      
                  </td>                  
                  <td>
                    <div v-if="row.id == rowtobeedited" >
                      <input type="number" v-model="state3.advance" >
                    </div>
                    <div v-else > {{row.advance}}</div>

                  </td>
                  <td>
                    <div class="btn-lps">
                      <button type="button" @click="editrow(row.id)" class="edtl">Edit</button>

                  </div>
                  </td>
                  <td>
                  <div class="btn-lps">
                    <button type="button" v-if="row.id == rowtobeedited" @click="calculaterow(row.id, state3.basic_salary,state3.hra,state3.other_allow,state3.edu_allow,state3.pf,state3.ptax,state3.advance)" class="edtl mx-auto">Calculate</button>
                    <button type="button" v-else disabled @click="calculaterow(row.id, state3.basic_salary,state3.hra,state3.other_allow,state3.edu_allow,state3.pf,state3.ptax,state3.advance)" class="edtl mx-auto">Calculate</button>
                  </div>
                </td>
                <td>
                  <div v-if="row.id == rowtobecalculated" >
                      {{netsalary}}
                    </div>
                    <div v-else > {{ row.basic_salary + row.hra + row.other_allow + row.edu_allow - row.pf - row.ptax - row.advance }}</div>
                  
                </td>
                <td>
                  <div class="btn-lps">
                    <button type="button" class="edtl mx-auto" v-if="row.id == rowtobeedited" @click="updaterow(row.id, row.empname, state3.basic_salary,state3.hra,state3.other_allow,state3.edu_allow,state3.pf,state3.ptax,state3.advance)">Update</button>
                    <button type="button" class="edtl mx-auto" v-else >Update</button>
                  </div>
                </td>
                  <!-- <td>
                      0
                  </td> -->
                  <td>

                    
                  </td>
                </tr>
                
              </tbody>
            </table>
           </form>
           
         </div>
  
       </div>
   </div>
  
  </div>
  </template>
  
  <script>
  import { createLocal, createSession, createStorage } from 'the-storages'
  import { BASE_URL } from "../../config";
  import axios from "axios";
  
  const mirror = createLocal()
  const storage = mirror._prx
  
  import Swal from 'sweetalert2'
  
  import { ref, computed } from 'vue'
  
  
  import { jsPDF } from "jspdf";
  import html2canvas from 'html2canvas';
  import { MonthPicker } from 'vue-month-picker'
  
  // import ModalApplyForLeave from '../UserDashboard/ModalApplyForLeave.vue'
  // import { AnyMap } from '@jridgewell/trace-mapping';
  // import { assertAnyTypeAnnotation } from '@babel/types';
  export default {
  name: 'GroupwiseSalaryPovisionAllocation',
  components:{
    MonthPicker
  },
  setup() {
  const isOpen = ref(false)
  return { isOpen };
  const state3 = reactive({
    basic_salary: '',
    hra:'',
    other_allow:'',
    edu_allow:'',
    pf:'',
    ptax:'',
    advance:''

 
          })
  const leavedate = ref();
  //return { v$: useVuelidate() };
  return {
    state3,
  }
  },
  data() {
  return {
   storageData: mirror,
   storage: storage,
   seen : false,
  
   leavedate: null,
   AttendanceData:{},
   attendance_details:[],
   working_days:'',
   searchQuery: null,
   year:'',
   month:'',
   today_date:'',
   selected_year:'',
   monthName:'',
   month_name_array:'',
   comma:'  ',
   AttendanceDetailsData:{},
   holiday_list:[],
   holiday_list_toDate:[],
   HolidayData:{},
   holiday_array:[],
   no_of_holidays:'',
   daysinmonth:'',
   userName :'',
   userlName :'',
   userEmail :'',
   ID :'',
   empdata:'',
   rowtobeedited:'',
   state3: {},
   netsalary:'',
   rowtobecalculated:'',
   date: {
      from: null,
      to: null,
      month: null,
      year: null
     }
  }
  },
  async created(event){
   this.userName = mirror.USERNAME;
   this.userlName = storage.USERLNAME;
   this.userEmail = mirror.USEREMAIL;
  
   this.ID = mirror.ID;
  //  var id = this.ID;
  //  console.log("svsv",id);
  // var d = new Date();
  // var split_d = d.toLocaleString().split(",")[0].split("/");        
  // this.today_date = split_d[1].concat("/",split_d[0]).concat("/",split_d[2])
  // var current_year = new Date().getFullYear();
  // this.year = current_year;
  // this.month = new Date().getMonth();
  // this.month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  // this.monthName = this.month_name_array[this.month];
  // this.selected_year = current_year;
  // var current_month = this.month;
  // console.log("yvftcft",current_year,current_month );
  // this.AttendanceData = {"year":current_year, "month":current_month+1};
  //  const attendance_report_data= await axios.post(BASE_URL + '/api/getAttendanceReport',this.AttendanceData);
  //  this.attendance_details = attendance_report_data.data.userList;
  //  console.log(" list",this.attendance_details);
  
  // console.log("monthpicked",this.date);
  // console.log("dtftdftfd",this.date);
  // this.getAttendanceReport(this.year,this.month,  this.today_date);
  // this.getHolidays(this.year,  this.today_date);
  // this.getAttendanceReport();
  
  // console.log("result query",this.holiday_list);
  // // this.getWorkingDays(doj2);
  // // console.log("wrok days",this.holidayCount('07-11-2022'));
  // this.HolidayData = {"year":this.year, "date":this.today_date}
  
  // await axios.post(BASE_URL + '/api/fechtHolidayUptoDate',this.HolidayData)
  //                 .then((response)=>{
  //   this.holiday_list_toDate = response.data.holiday_list_toDate;
  //   this.holiday_array = this.holiday_list_toDate.map(x => x.holiday_date)                
  //                 })
  // console.log("vvvvv",this.holiday_array);
  // // this.holidayCount('7-11-2021');
  // this.daysinmonth = new Date(current_year, current_month+1, 0).getDate();
  await axios.get(BASE_URL + '/api/getempsalary')
                .then((response)=>{
  this.empdata = response.data.record;
  this.state3.basic_salary = response.data.basic_salary;
  this.state3.hra = response.data.hra;
  this.state3.other_allow = response.data.other_allow;
  this.state3.edu_allow = response.data.edu_allow;
  this.state3.pf = response.data.pf;
  this.state3.ptax = response.data.ptax;
  this.state3.advance = response.data.advance;
  console.log("response",this.empdata);          
                })
  },
  methods: {
  
    async clickedDownload(){
       var pdf = new jsPDF('p', 'mm', [400, 980]);
       var element = document.getElementById('divToPrintAttendanceReportList');
       var width= element.style.width;
       var height = element.style.height;
           html2canvas(element,{ scale: 1, width: 2000, height: 9000 }).then(canvas => {
               //console.log(canvas);
               var image = canvas.toDataURL('image/png');
               pdf.addImage(image, 'JPEG', 15, 40, width, height);
               pdf.save('AttendanceReportList' + '.pdf')
       });
   },
  // async getAttendanceReport(date){
  //  console.log("yfdfdf",this.date);
  //  var current_year = this.date.toLocaleString().split(",")[0].split("/")[2];
  //  var current_month = this.date.toLocaleString().split(",")[0].split("/")[0];
  //  var current_date = this.date.toLocaleString().split(",")[0].split("/")[1];
  //  this.AttendanceData = {"year":current_year, "month":current_month, "date":current_date};
  //  const attendance_report_data= await axios.post(BASE_URL + '/api/getAttendanceReport',this.AttendanceData);
  //  this.attendance_details = attendance_report_data.data.attendance_details;
  //  this.holiday_list = attendance_report_data.data.holiday_list_uptoDate;
  //  console.log("aaaaa",this.holiday_list);
  //  return this.holiday_list;
  
  // },
  

  editrow(id){
    this.rowtobeedited = id;
    console.log("row",this.rowtobeedited);
  },

  calculaterow(id,basic,hra,other_allow,edu_allow,pf,ptax,advance){
    this.rowtobecalculated = id;
    console.log("calculated",hra);
    if (basic==undefined) {
      basic = 0;
    }
    if (hra==undefined) {
      hra = 0;
    };
    if (other_allow==undefined) {
      other_allow = 0;
    }    
    if (edu_allow==undefined) {
      edu_allow = 0;
    }    
    if (pf==undefined) {
      pf = 0;
    }    
    if (ptax==undefined) {
      ptax = 0;
    }
    if (advance==undefined) {
      advance = 0;
    }
    this.netsalary = basic + hra+ other_allow + edu_allow - pf -ptax - advance;
  },

  async updaterow(id,name,basic,hra,other_allow,edu_allow,pf,ptax,advance){
    let formData = new FormData();

    if (basic==undefined) {
      basic = 0;
    }
    if (hra==undefined) {
      hra = 0;
    };
    if (other_allow==undefined) {
      other_allow = 0;
    }    
    if (edu_allow==undefined) {
      edu_allow = 0;
    }    
    if (pf==undefined) {
      pf = 0;
    }    
    if (ptax==undefined) {
      ptax = 0;
    }
    if (advance==undefined) {
      advance = 0;
    }
    
formData.append('id', id);
formData.append('name', name);
formData.append('basic', basic);
formData.append('hra', hra);
formData.append('other_allow', other_allow);
formData.append('edu_allow', edu_allow);
formData.append('pf', pf);
formData.append('ptax', ptax);
formData.append('advance', advance);

// }
//console.log(getdata);

await axios.post(BASE_URL + '/api/updateempsalary',formData)
        .then((response)=>{
            console.log(response.data);

            if(response.data.success == true){

                Swal.fire({
                            title: 'Done',
                            text:  response.data.message,
                            icon: 'success',
                        
                        });

            }else{

                Swal.fire({
                        title: 'OPPS',
                        text:   response.data.message,
                        icon: 'warning',
                    
                    });
            }

    })

  },

  
  closeDatepicker(event){
            this.seen = false;
          },
  
  isOpen(){
          //   console.log("rr",this.submit_status);
            this.seen = true;
         },
  
  read(){
  
  }, 
  },
  
  computed: {
           resultQuery(){
           if(this.searchQuery){
           return this.empdata.filter((item)=>{
           
           return this.searchQuery.toLowerCase().split(' ').every( w => item.empname.concat(item.id).concat(item.position).toLowerCase().includes(w));
           //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
           })
           }else{
           return this.empdata;
           }
           },
           },
    
  
  mounted(){
    this.read();
  },
  
  
  }
  
  </script>