<template>
    <HeaderAdmin />
    <SidebarAdmin />
    <AssignIncentivesTableDetails />
</template>

<script>

import HeaderAdmin from "../AdminDashboard/HeaderAdmin.vue";
import SidebarAdmin from "../AdminDashboard/SidebarAdmin.vue";
import AssignIncentivesTableDetails from "../MarketingDashboard/AssignIncentivesTableDetails.vue";

export default {
    name: 'AssignIncentives',
    components:{
        HeaderAdmin,
        SidebarAdmin,
        AssignIncentivesTableDetails
    }
}
</script>