<template>
    <div class="main-right">
        <div class="main-title">
          <h2>Employee Inactive </h2>
        </div>
        <div class="head-bar">
          <ul>
            <li>
              No of Employees: <span>{{totalemp}}</span>
            </li>
          </ul>
          <div class="fild-info">
            <form action="#">
              <div class="fr-gp">
                <input type="search" v-model="searchText" placeholder="Search..." @input="onSearch">
                <button type="submit">
                  <img src="@/assets/images/icon/search.svg" alt="">
                </button>
              </div>
            </form>
            <button class="dwnlod-btn" @click="clickedDownload()">
              download
              <img src="@/assets/images/icon/download.svg" alt="">
            </button>
          </div>
        </div>

        <div class="head__btnGp">
           <router-link to="/adminemplist">Active Employee </router-link>
        </div>
        
        <div class="table-section" id="divToPrintEmpInactiveList">
          <div class="table-wapper">
            <div class="table-data table-w-100 mail_info_table">
              <table cellspacing="0" cellpadding="0" border="0">
                <thead>
                  <tr>
                    <th>Employee Name </th>
                    <th>Employee ID</th>
                    <th>Email Address</th>
                    <th>Phone No.</th>
                    <th>Position</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody v-show="message.data !== 0" v-for="(row) in message.data" :key="row">
                  <tr>
                    <td v-show="row.fname!=''">{{row.fname}} {{row.lname}}</td>
                    <td>{{row.employeeid}}</td>
                    <td>{{row.email}}</td>
                    <td>{{row.phone}}</td>
                    <td>{{row.position}}</td>
                    <td>
                      <div class="btn-lps">
                        <router-link :to="`/editemployeedetails/${row.id}`" class="edtl" >Edit </router-link>
                        <a href="#" class="gren" v-if="row.is_active == 0" @click="activeInactiveClick('inactive',row.id)" >Active</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-model="page" :records="totalData" :per-page="10" @paginate="getResults(page,this.searchText)"/>
          </div>
      </div>

    </div>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';

const mirror = createLocal()
const storage = mirror._prx

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

import Swal from 'sweetalert2';

export default {
    name: 'EmpinactiveList',
    components:{
        Pagination
    },
    data() {
        return {
            page: 1,
            storageData: mirror,
            storage: storage,
            is_show: false,
            message:  {},
            searchText:"",
            nofound:false,
            errormessage: "",
            totalData:'',
            searchData:'',
            dateSearch:'',
            flag:'',
            errormessage1:"",
            totalemp : ""
        }
    },
    methods :{
       onSearch(){
           this.getResults(this.page,this.searchText);
       },
        getResults(page,searchText='') {
            if (typeof page === "undefined") {
                page = 1;
            }
            if(searchText=='') {
                this.flag = 'list';
            }
            if(searchText!=''){
                this.flag = 'search';
                this.searchText = searchText;
            }
            
            const data ={
                userId: this.ID,
                flag: this.flag,
                searchText:this.searchText,

            }
            //console.log(data);
            
            axios.post(BASE_URL + `/api/getallinactiveemplist?page=${page}`,data)
                .then((response)=>{
                //console.log(response.data);

                this.message = response.data;
                this.totalData =  response.data.total;
                this.is_show= (this.message.last_page > 1)?true:false;

                this.totalemp = response.data.total;

                if(this.message.data?.length>0){
                    this.nofound=false;
                }else{
                    this.nofound=true;
                }

            })
        },
        async clickedDownload(){
            var pdf = new jsPDF();
            var element = document.getElementById('divToPrintEmpInactiveList');
            var width= element.style.width;
            var height = element.style.height;
                html2canvas(element).then(canvas => {
                    //console.log(canvas);
                    var image = canvas.toDataURL('image/png');
                    pdf.addImage(image, 'JPEG', 15, 40, width, height);
                    pdf.save('EmployeeInactiveList' + '.pdf')
            });
        },

        async activeInactiveClick(status,id){

            if(confirm("Are you sure you want to active this?")){

                const getdata = {
                    url_data: id,
                    status:status
                };
                //console.log(getdata);

                if(status=='inactive'){
                        axios.post(BASE_URL + '/api/getempstatus2',getdata)
                        .then((response)=>{
                            //console.log(response.data);

                            Swal.fire({
                                title: 'Done',
                                text:  response.data.message,
                                icon: 'success',
                            
                            });

                            this.getResults();

                        })
                    }

            }else{
                return false;
            }

            
        },
    },
    async created(){
        this.userName = mirror.USERNAME

        this.ID = mirror.AUTHID;
   },
    mounted(){
        this.getResults();
    },

}
</script>
<style scoped> 
</style>