<template>
  <router-view/>
</template>

<script>
import Login from './components/Login.vue'

export default {
  name: 'App',
  components: {
    Login
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
body  {
  margin: 0 !important;
}
.VuePagination nav ul {
    padding: 30px 0px 30px 0;
}

@media (max-width:991px)  {
  /* body,
  html  {
    height: 100%;
  }
  .leaveBox {
    flex-wrap: wrap;
  }
  #app {
    height: 100%;
  } */
}
</style>
