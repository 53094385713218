<template>
  <div class="main-right">
    <div class="main-title title_tbtn_alin d-flex flex-row justify-content-between align-items-center">       
        <div class="table_name">
          <h2>Team Report for {{month_proper_name}} {{ ',' }} {{ picked_year }}</h2>
        </div> 
        <!-- <button class="stl-btn modal_caldn_btn" id="modal-tggl1" @click="isOpen()">
          <img src="@/assets/images/icon/calendar-plus.png" alt=""> Select a month 
        </button> -->
        <div class="month_picker_boxes">
          <label>Select Month :</label>
            <!-- <month-picker-input placeholder="Select month year" @change="showDate" :no-default="true"></month-picker-input> -->
            <!-- <month-picker-input placeholder="Select month year" @change="showDate" :no-default="true" :show-year="true"></month-picker-input> -->
            <month-picker-input placeholder="Select Month Year" @change="showDate" :no-default="false" :show-year="true" :default-month="new Date().getMonth()+1"></month-picker-input>

        </div>
      </div>
   
      <!-- <div class="popup-modal" id="popupModa1" v-if="seen">
            <div class="mp-content">
            <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
              <img src="@/assets/images/icon/x-lg.svg" alt="">
            </button>
            <div class="bdy-sect">
              <h3>Select a month</h3>
              <month-picker @change="showDate"  no-default></month-picker>
            </div>            
          </div>
      </div> -->
    <!-- Form to create a group -->
    <!-- <div>
      <h2>Create Group</h2>
      <form @submit.prevent="createGroup">
        <label for="groupName">Group Name:</label>
        <input type="text" id="groupName" v-model="state3.teamname" required />
        <button type="submit">Create Group</button>
      </form>
    </div> -->
    <!-- <VueMultiselect 
                    v-model="selectedUsers"
                    :options= "options"
                    :multiple="true"
                  >
                  </VueMultiselect > -->

    <!-- <multiselect
      v-model="selectedUsers"
      :options="empdata"
      label="empname"
      track-by="id"
      placeholder="Select users"
      :multiple="true"
    ></multiselect> -->

    <!-- Table to display created groups -->
    <div class="table-section table_height team_report_table">
      <div class="table-wapper">
        <div class="table-data table-w-100">
          <!-- <h2>Team Report for {{month_proper_name}} {{ ',' }} {{ picked_year }}</h2> -->
          <!-- <button type="button" @click="addrow()" :class="btnClass">{{addBtn}}</button> -->
          <table class="payroll-table">
            <thead>
              <tr>
                <th>Emp Name</th>
                <th>Members</th>
                <th>Personal Target($)</th>
                <th>Team Target($)</th>
                <th>Target History($)</th>
                <th>Team Target Overview($)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              
            <tr v-for="(team, index) in userdata" :key="index">
              <td>{{ team.member_name }}({{ team.member_status }})</td>
              <!-- <td @mouseover="showMembers(index)" @mouseout="hideMembers" v-if="team.members != null">
                {{ team.members.length }} 
                <div v-if="hoveredIndex === index" class="member-tooltip">
                  {{ team.members.join(', ') }}
                </div>
              </td> -->
              <!-- <td v-else>
                  -
              </td> -->
              <td @mouseover="showMembers(index)" @mouseout="hideMembers" v-if="team.members != null">
                {{ getMembersCount(team.members) }} 
                <div v-if="hoveredIndex === index" class="member-tooltip">
                  <!-- <span v-for="(member, memberIndex) in JSON.parse(team.members)" :key="memberIndex">
                    {{ member.name }}{{ memberIndex < JSON.parse(team.members).length - 1 ? ', ' : '' }}
                  </span> -->
                  <span v-for="(member, memberIndex) in parseMembers(team.members).filter(member => member.active === 1)" :key="memberIndex">
                    {{ member.name }}{{ memberIndex < parseMembers(team.members).length - 1 ? ', ' : '' }}
                  </span>
                </div>
              </td>
              <td v-else>
                  -
              </td>
              <td>
                <div v-if="team.empid == rowtobeedited_id && showeditedrow == true" >
                  <input type="number" min="0" v-model="state3.self_target" @input="validateInput">
                </div>
                <div v-else-if="team.monthly_target == null " >
                  <input type="number" min="0" v-model="team.self_target" @input="validateInput">
                </div>

                <div v-else-if="team.monthly_target != null " class="hover-tool-tip">
                  <span v-on:mouseover="showTooltip = true" v-on:mouseout="showTooltip = false">
                    {{ team.monthlyTargets?.[monthAbbreviations[month_name]] || '-' }}
                  </span>
                  <span class="tooltip">
                    {{ generateTooltipContent(team.monthlyTargets) }}
                  </span>
                </div>
                <div v-else>
                  -
                </div>
              </td> 
              <td>
                  <div v-if="team.empid == rowtobeedited_id && showeditedrow == true &&  team.member_status == 'Team Leader'" >
                        <input type="number" min="0" v-model="state3.team_target" @input="validateInput">
                  </div>
                  <div v-else-if="team.team_monthly_target == null && team.member_status == 'Team Leader'" >
                        <input type="number" min="0" v-model="team.team_target" @input="validateInput">
                  </div>
                  <!-- <div v-else-if="team.team_monthly_target != null && team.member_status == 'Team Leader'" >{{team.monthlyTeamTargets?.[monthAbbreviations[month_name]] || '-'}}</div> -->
                  <div v-else-if="team.team_monthly_target != null && team.member_status == 'Team Leader'" class="hover-tool-tip">
                  <span v-on:mouseover="showTooltip = true" v-on:mouseout="showTooltip = false">
                    {{ team.monthlyTeamTargets?.[monthAbbreviations[month_name]] || '-' }}
                  </span>
                  <span class="tooltip">
                    {{ generateTooltipContent(team.monthlyTeamTargets) }}
                  </span>
                  </div>
                  <div v-else>-</div>
              </td>
              <td>
                  <!-- <div v-if="team.monthly_target_achieved == null" >
                      -
                  </div> -->
                  <!-- <div v-else >{{team.monthlyTargetsAchieved?.[monthAbbreviations[month_name]] || '-'}}</div> -->
                  <div  class="hover-tool-tip">
                  <!-- <div v-else class="hover-tool-tip"> -->
                  <span v-on:mouseover="showTooltip = true" v-on:mouseout="showTooltip = false">
                    {{ team.pastDataArray?.[monthAbbreviations[month_name]] || '-' }}
                  </span>
                  <span   class="tooltip">
                    {{ generateTooltipContent(team.pastDataArray) }}
                  </span>
                  </div>
              </td>               
              <td>
                  <div v-if="team.monthly_team_target_achieved != null && team.member_status == 'Team Leader'" class="hover-tool-tip">
                    <span v-on:mouseover="showTooltip = true" v-on:mouseout="showTooltip = false">
                    {{ team.pastTeamDataArray?.[monthAbbreviations[month_name]] || '-' }}
                  </span>
                  <span   class="tooltip">
                    {{ generateTooltipContent(team.pastTeamDataArray) }}
                  </span>
                  </div>
                  <div v-else>-</div>
              </td> 
              <td>
                <div class="btn-lps" >
                  <button v-if="team.empid == rowtobeedited_id && showeditedrow == true" @click="updateDetails(team.empid)" class="edtl">Update</button>
                  <button v-else @click="editDetails(team.empid, team.monthlyTargets?.[monthAbbreviations[month_name]], team.monthlyTeamTargets?.[monthAbbreviations[month_name]])" class="edtl">Edit</button>
                  <!-- <button @click="saveDetails(team)" class="edtl">Save</button> -->
                  <!-- <button @click="showDetails(index, team.empid)" class="edtl">Details</button> -->

                  <button @click="showDetails(index, team.empid)" type="button" class="edtl" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Details
                  </button>
                  

                </div>


                <!-- <div v-if="detailsIndex === index" class="details-popup">
                  <button class="close-button" @click="closePopup">✖</button>
                  <h3>{{memberdetails.member_name}} Details</h3>
                 
                  <p v-if="showRoleDropdown == false">Role: {{ memberdetails.member_status }} <button @click="editTeamLeader">Edit</button></p>
                  <div v-if="showRoleDropdown" class="job-role-dropdown">
                    <select v-model="selectedJobRole" id="roleSelect">
                      <option value="Team Leader">Team Leader</option>
                      <option value="Mentor">Mentor</option>
                      <option value="Member">Member</option>
                    </select>
                    <button @click="saveJobRole(team.empid,team.member_name)">Save</button>
                  </div>
                  <div>
                    <p>Personal target achieved {{monthsMetTarget}} out of 6 months (${{personalTargetAchievedTotal}}/${{personalTargetTotal}})</p>
                    <p>Team target achieved {{monthsMetTeamTarget}} out of 6 months (${{teamTargetAchievedTotal}}/${{teamTargetTotal}})</p>
                  </div>
                  <div>
                  <div v-if="memberdetails.member_status != 'Member'">
                    <button @click="openAddEmployeeModal">Add Employee</button>

                    <div v-if="showAddEmployeeModal" class="add-employee-modal">
                      <label for="employeeSelect">Select Employee:</label>
                      <select v-model="state3.selectedEmployee" id="employeeSelect" @change="addSelectedEmployee(team.empid)">
                        <option value="" disabled>Select an employee</option>
                        <option v-for="employee in membersList" :key="employee.id" :value="`${employee.member_name}-${employee.empid}`">{{ employee.member_name }}</option>
                      </select>
                      <button @click="closeAddEmployeeModal">Cancel</button>
                    </div>
                    <table >
                      <thead>
                        <tr>
                          <th>Employee</th>
                          <th>Jan</th>
                          <th>Feb</th>
                          <th>Mar</th>
                          <th>Apr</th>
                          <th>May</th>
                          <th>Jun</th>
                          <th>Jul</th>
                          <th>Aug</th>
                          <th>Sep</th>
                          <th>Oct</th>
                          <th>Nov</th>
                          <th>Dec</th>
                          <th>Total</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(employee, eIndex) in updatedMemberData" :key="eIndex">
                          <td>{{ employee.name }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Jan"  /> {{ employee.achieved.Jan }}</td>
                          <td v-else><input v-model="employee.targets.Jan"  readonly/> {{ employee.achieved.Jan }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Feb"  /> {{ employee.achieved.Feb }}</td>
                          <td v-else><input v-model="employee.targets.Feb"  readonly/> {{ employee.achieved.Feb }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Mar"  /> {{ employee.achieved.Mar }}</td>
                          <td v-else><input v-model="employee.targets.Mar"  readonly/> {{ employee.achieved.Mar }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Apr"  /> {{ employee.achieved.Apr }}</td>
                          <td v-else><input v-model="employee.targets.Apr"  readonly/> {{ employee.achieved.Apr }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.May"  /> {{ employee.achieved.May }}</td>
                          <td v-else><input v-model="employee.targets.May"  readonly/> {{ employee.achieved.May }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Jun"  /> {{ employee.achieved.Jun }}</td>
                          <td v-else><input v-model="employee.targets.Jun"  readonly/> {{ employee.achieved.Jun }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Jul"  /> {{ employee.achieved.Jul }}</td>
                          <td v-else><input v-model="employee.targets.Jul"  readonly/> {{ employee.achieved.Jul }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Aug"  /> {{ employee.achieved.Aug }}</td>
                          <td v-else><input v-model="employee.targets.Aug"  readonly/> {{ employee.achieved.Aug }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Sep"  /> {{ employee.achieved.Sep }}</td>
                          <td v-else><input v-model="employee.targets.Sep"  readonly/> {{ employee.achieved.Sep }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Oct"  /> {{ employee.achieved.Oct }}</td>
                          <td v-else><input v-model="employee.targets.Oct"  readonly/> {{ employee.achieved.Oct }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Nov"  /> {{ employee.achieved.Nov }}</td>
                          <td v-else><input v-model="employee.targets.Nov"  readonly/> {{ employee.achieved.Nov }}</td>
                          <td v-if="employee.active == '1'"><input v-model="employee.targets.Dec"  /> {{ employee.achieved.Dec }}</td>
                          <td v-else><input v-model="employee.targets.Dec"  readonly/> {{ employee.achieved.Dec }}</td>
                          <td>${{
                              parseInt(employee.targets.Jan) + parseInt(employee.targets.Feb) + parseInt(employee.targets.Mar) +
                              parseInt(employee.targets.Apr) + parseInt(employee.targets.May) + parseInt(employee.targets.Jun) +
                              parseInt(employee.targets.Jul) + parseInt(employee.targets.Aug) + parseInt(employee.targets.Sep) +
                              parseInt(employee.targets.Oct) + parseInt(employee.targets.Nov) + parseInt(employee.targets.Dec)
                            }} 
                            ${{ employee.achieved.Jan + employee.achieved.Feb + employee.achieved.Mar + employee.achieved.Apr + employee.achieved.May + employee.achieved.Jun + 
                            employee.achieved.Jul + employee.achieved.Aug + employee.achieved.Sep + employee.achieved.Oct + employee.achieved.Nov + employee.achieved.Dec  }}
                          </td>
                          <td>
                            <div v-if="employee.active == '1'" class="btn-lps" >
                              <button   @click="editpopupDetails(employee)" class="edtl">Set</button>
                              <button   @click="removemember(employee)" class="edtl">Remove</button>
                            </div>
                            <div v-else>
                              --
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                </div> -->
              </td>
            </tr>



            </tbody>
          </table>

          
        </div>
      </div>
    </div>

    <div class="modal details_modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="openModel">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{memberdetails.member_name}} Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closepopup()"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p v-if="showRoleDropdown == false" class="modal_role">Role: {{ memberdetails.member_status }} <button v-if="memberdetails.member_status == 'Member'" @click="editTeamLeader">Edit</button></p>
              </div>
              <div class="modal_set d-flex justify-content-between align-items-center">
                <input type="number" min="0" placeholder="Default individual target" v-model="target_individual" />
                <button @click="setIndividualTarget(memberdetails.empid)" >Individual Target</button>
                <!-- <button>Team Target</button> -->
              </div>
              <!-- <div class="modal_set d-flex justify-content-between align-items-center" v-if="memberdetails.member_status == 'Team Leader'">
                <input type="number" min="0" placeholder="Default team target" v-model="target_team" />
                <button @click="setTeamTarget(memberdetails.empid)" >Team Target</button>
              </div> -->
              <div class="modal_set d-flex justify-content-between align-items-center" v-if="memberdetails.member_status == 'Team Leader'">
                <input type="number" min="0" placeholder="Add buffer team target" v-model="target_buffer" />
                <button @click="setBufferTeamTarget(memberdetails.empid)" >Add Buffer</button>
              </div>
              <div class="modal_set" v-if="memberdetails.member_status == 'Team Leader'">
                <h2>Monthly Team Target: <span>{{ monthly_team_target }}</span></h2>
                <h2 v-if="monthly_team_buffer!=0">Monthly Buffer: <span>{{ monthly_team_buffer }}</span></h2>
                <!-- <input type="number" disabled v-model="monthly_team_target" /> -->
                <!-- <button @click="setTeamTarget(memberdetails.empid)" >Team Target</button> -->
              </div>
            </div>

            <div v-if="showRoleDropdown" class="job-role-dropdown">
              <select v-model="selectedJobRole" id="roleSelect">
                <option value="Team Leader">Team Leader</option>
                <!-- <option value="Mentor">Mentor</option> -->
                <option value="Member">Member</option>
              </select>
              <button @click="saveJobRole(memberdetails.empid,memberdetails.member_name)">Save</button>
              <!-- <button v-if="memberdetails.member_status == 'Member'" @click="saveJobRole(memberdetails.empid,memberdetails.member_name)">Save</button> -->
              <!-- <button @click="saveJobRole(team.empid,team.member_name)">Save</button> -->
            </div>
            <div class="modal_month">
              <p>Personal target achieved {{monthsMetTarget}} out of last 6 months (${{personalTargetAchievedTotal}}/${{personalTargetTotal}})</p>
              <p>Team target achieved {{monthsMetTeamTarget}} out of last 6 months (${{teamTargetAchievedTotal}}/${{teamTargetTotal}})</p>
            </div>
            <div>
              <div class="add_employe" v-if="memberdetails.member_status != 'Member'">
                <button class="add_employe_btn" @click="openAddEmployeeModal">Add Employee</button>
                <div v-if="showAddEmployeeModal" class="add-employee-modal">
                  <label for="employeeSelect">Select Employee:</label>
                  <select v-model="state3.selectedEmployee" id="employeeSelect" @change="addSelectedEmployee(memberdetails.empid)">
                    <option value="" disabled>Select an employee</option>
                    <option v-for="employee in membersList" :key="employee.id" :value="`${employee.member_name}-${employee.empid}`">{{ employee.member_name }}</option>
                  </select>
                  <button @click="closeAddEmployeeModal">Cancel</button>
                </div>
                <div class="table_scroll">
                  <div class="table-section table_height">
                    <div class="table-wapper">
                      <div class="table-data table-w-100">
                        <div class="table_scroll">
                          <table class="payroll-table">
                            <thead>
                              <tr>
                                <th>Employee</th>
                                <th>Jan</th>
                                <th>Feb</th>
                                <th>Mar</th>
                                <th>Apr</th>
                                <th>May</th>
                                <th>Jun</th>
                                <th>Jul</th>
                                <th>Aug</th>
                                <th>Sep</th>
                                <th>Oct</th>
                                <th>Nov</th>
                                <th>Dec</th>
                                <th>Total</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(employee, eIndex) in updatedMemberData" :key="eIndex">
                                <td>{{ employee.name }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Jan"  /> {{ employee.achieved.Jan }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Jan"  readonly/> {{ employee.achieved.Jan }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Feb"  /> {{ employee.achieved.Feb }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Feb"  readonly/> {{ employee.achieved.Feb }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Mar"  /> {{ employee.achieved.Mar }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Mar"  readonly/> {{ employee.achieved.Mar }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Apr"  /> {{ employee.achieved.Apr }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Apr"  readonly/> {{ employee.achieved.Apr }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.May"  /> {{ employee.achieved.May }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.May"  readonly/> {{ employee.achieved.May }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Jun"  /> {{ employee.achieved.Jun }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Jun"  readonly/> {{ employee.achieved.Jun }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Jul"  /> {{ employee.achieved.Jul }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Jul"  readonly/> {{ employee.achieved.Jul }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Aug"  /> {{ employee.achieved.Aug }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Aug"  readonly/> {{ employee.achieved.Aug }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Sep"  /> {{ employee.achieved.Sep }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Sep"  readonly/> {{ employee.achieved.Sep }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Oct"  /> {{ employee.achieved.Oct }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Oct"  readonly/> {{ employee.achieved.Oct }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Nov"  /> {{ employee.achieved.Nov }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Nov"  readonly/> {{ employee.achieved.Nov }}</td>
                                <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Dec"  /> {{ employee.achieved.Dec }}</td>
                                <td v-else><input type="number" min="0" v-model="employee.targets.Dec"  readonly/> {{ employee.achieved.Dec }}</td>
                                <!-- <td><input v-model="employee.targets.Jan" :readonly="employee.achieved.Jan !== null" /> {{ employee.achieved.Jan }}</td> -->
                                <td>${{
                                    parseInt(employee.targets.Jan) + parseInt(employee.targets.Feb) + parseInt(employee.targets.Mar) +
                                    parseInt(employee.targets.Apr) + parseInt(employee.targets.May) + parseInt(employee.targets.Jun) +
                                    parseInt(employee.targets.Jul) + parseInt(employee.targets.Aug) + parseInt(employee.targets.Sep) +
                                    parseInt(employee.targets.Oct) + parseInt(employee.targets.Nov) + parseInt(employee.targets.Dec)
                                  }} 
                                  ${{ employee.achieved.Jan + employee.achieved.Feb + employee.achieved.Mar + employee.achieved.Apr + employee.achieved.May + employee.achieved.Jun + 
                                  employee.achieved.Jul + employee.achieved.Aug + employee.achieved.Sep + employee.achieved.Oct + employee.achieved.Nov + employee.achieved.Dec  }}
                                </td>
                                <td>
                                  <div v-if="employee.active == '1'" class="btn-lps" >
                                    <!-- <button v-if="employee.id == detailsrowedit && showdetailseditedrow == true" @click="updatepopupDetails(employee.id)" class="edtl">Update</button> -->
                                    <button   @click="editpopupDetails(employee,memberdetails.empid)" class="edtl">Set</button>
                                    <button v-if="memberdetails.empid != employee.id"  @click="removemember(employee,memberdetails.empid)" class="edtl">Remove</button>
                                    <button v-if="memberdetails.empid == employee.id" data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="edtl" @click="removetl(memberdetails.empid, employee.id)">Remove</button>

                                  </div>
                                  <div v-else>
                                    --
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>  
                  </div>         
                </div>
              </div>

              <div class="add_employe" v-if="memberdetails.member_status == 'Member'">
                <div class="table-section table_height">
                  <div class="table-wapper">
                    <div class="table-data table-w-100">
                      <div class="table_scroll">
                        <table class="payroll-table">
                          <thead>
                            <tr>
                              <th>Employee</th>
                              <th>Jan</th>
                              <th>Feb</th>
                              <th>Mar</th>
                              <th>Apr</th>
                              <th>May</th>
                              <th>Jun</th>
                              <th>Jul</th>
                              <th>Aug</th>
                              <th>Sep</th>
                              <th>Oct</th>
                              <th>Nov</th>
                              <th>Dec</th>
                              <th>Total</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(employee, eIndex) in updatedMemberData" :key="eIndex">
                              <td>{{ employee.name }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Jan"  /> {{ employee.achieved.Jan }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Jan"  readonly/> {{ employee.achieved.Jan }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Feb"  /> {{ employee.achieved.Feb }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Feb"  readonly/> {{ employee.achieved.Feb }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Mar"  /> {{ employee.achieved.Mar }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Mar"  readonly/> {{ employee.achieved.Mar }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Apr"  /> {{ employee.achieved.Apr }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Apr"  readonly/> {{ employee.achieved.Apr }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.May"  /> {{ employee.achieved.May }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.May"  readonly/> {{ employee.achieved.May }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Jun"  /> {{ employee.achieved.Jun }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Jun"  readonly/> {{ employee.achieved.Jun }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Jul"  /> {{ employee.achieved.Jul }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Jul"  readonly/> {{ employee.achieved.Jul }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Aug"  /> {{ employee.achieved.Aug }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Aug"  readonly/> {{ employee.achieved.Aug }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Sep"  /> {{ employee.achieved.Sep }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Sep"  readonly/> {{ employee.achieved.Sep }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Oct"  /> {{ employee.achieved.Oct }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Oct"  readonly/> {{ employee.achieved.Oct }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Nov"  /> {{ employee.achieved.Nov }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Nov"  readonly/> {{ employee.achieved.Nov }}</td>
                              <td v-if="employee.active == '1'"><input type="number" min="0" v-model="employee.targets.Dec"  /> {{ employee.achieved.Dec }}</td>
                              <td v-else><input type="number" min="0" v-model="employee.targets.Dec"  readonly/> {{ employee.achieved.Dec }}</td>
                              <!-- <td><input v-model="employee.targets.Jan" :readonly="employee.achieved.Jan !== null" /> {{ employee.achieved.Jan }}</td> -->
                              <td>${{
                                  parseInt(employee.targets.Jan) + parseInt(employee.targets.Feb) + parseInt(employee.targets.Mar) +
                                  parseInt(employee.targets.Apr) + parseInt(employee.targets.May) + parseInt(employee.targets.Jun) +
                                  parseInt(employee.targets.Jul) + parseInt(employee.targets.Aug) + parseInt(employee.targets.Sep) +
                                  parseInt(employee.targets.Oct) + parseInt(employee.targets.Nov) + parseInt(employee.targets.Dec)
                                }} 
                                ${{ employee.achieved.Jan + employee.achieved.Feb + employee.achieved.Mar + employee.achieved.Apr + employee.achieved.May + employee.achieved.Jun + 
                                employee.achieved.Jul + employee.achieved.Aug + employee.achieved.Sep + employee.achieved.Oct + employee.achieved.Nov + employee.achieved.Dec  }}
                              </td>
                              <td>
                                <div v-if="employee.active == '1'" class="btn-lps" >
                                  <!-- <button v-if="employee.id == detailsrowedit && showdetailseditedrow == true" @click="updatepopupDetails(employee.id)" class="edtl">Update</button> -->
                                  <button   @click="editpopupDetails(employee,memberdetails.empid)" class="edtl">Set</button>
                                  <!-- <button   @click="removemember(employee,memberdetails.empid)" class="edtl">Remove</button> -->
                                </div>
                                <div v-else>
                                  --
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal fade" :class="{ 'show': openremovemodal }" style="display: block;" tabindex="-1" role="dialog"> -->
    <div class="modal remove_modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <!-- <div  v-if="openremovemodal == true" class="modal remove_modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"> -->
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Choose Team</h5>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <button type="button" class="btn-close" @click="showPopup = true"></button>

          </div>
          <div class="modal-body">
            <div class="remove_body">
              <div class="left">
                <div class="checkbox_div"  v-for="(member, eIndex) in memberList" :key="eIndex">
                  <label class="checkbox_inline">
                    <input type="checkbox" v-model="checkedMembers" :value="member.empid">{{member.member_name}}
                  </label>
                </div>
              </div>
              <div class="right">
                <div class="dropdown">
                    <button class="btn dropdown-toggle d-flex align-items-center" type="button"
                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <span v-if="leader_name_choose!=''">{{ leader_name_choose }}</span>
                        <span v-else>Select Team Leader</span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <div class="drop_padding">
                            <div class="drop_padding_height">
                                <li v-for="item in menuItems" :key="item.title" class="d-flex justify-content-between align-items-center" @click="handleItemClick(item.member_name, item.empid)">
                                    {{ item.member_name }}
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
            <!-- <button type="button" class="save_btn" data-bs-dismiss="modal" @click="allocatemembers()">Save</button> -->
            <button type="button" class="save_btn" @click="allocatemembers()">Save</button>
          </div>
          <div v-if="showPopup" class="modal_close_popup">
              <div class="close_body" v-if="memberList != []">
                <p >You have users which are not assigned to any team leader.<br>Still continue?</p>
                <div class="modal_flex">
                  <button class="no_btn" @click="closePopup">No</button>
                  <button class="yes_btn" @click="showPopup = false" data-bs-dismiss="modal">Yes</button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import { createLocal, createSession, createStorage } from 'the-storages'
    import { BASE_URL } from "../../config";
    import axios from "axios";
    import VueMultiselect from 'vue-multiselect'
    const mirror = createLocal()
    const storage = mirror._prx
    import 'vue-multiselect/dist/vue-multiselect.css';

    import Swal from 'sweetalert2'
    import { createToast } from 'mosha-vue-toastify';
    import 'mosha-vue-toastify/dist/style.css';
    import { ref, computed } from 'vue'
    
    import { reactive } from "vue"
    import { jsPDF } from "jspdf";
    import html2canvas from 'html2canvas';
    // import { MonthPicker } from 'vue-month-picker';
    import { MonthPickerInput } from 'vue-month-picker'

    import { toRaw } from 'vue';
    
export default {
  name: 'AssignUser',
  components: { VueMultiselect, MonthPickerInput },
  setup() {
          const state3 = reactive({
              team_target:'',
              self_target:'',
              selectedEmployee: '',
              teamLeader:'',
              monthlytarget:'',
              monthly_target_achieved:'',
              monthly_team_target_achieved:'',
              date: {
                from: null,
                to: null,
                month: null,
                year: null
              }
              
          })

          const state2 = reactive({
              teammate:'',
              selectedUsers: [],
              options: [],
              teamLeader:'',
              monthlytarget:'',
              date: {
                from: null,
                to: null,
                month: null,
                year: null
              }
          })

          const isOpen = ref(false)
          const isOpenNew = ref(false)
          
          return {
              state3,
              state2,
              isOpen,
              isOpenNew,
          }
      },
  data() {
    return {
      // newGroup: {
      //   name: "",
      // },
      // groups: [],
      // groupname:'',
      // allUsers: [
      //   { id: 1, name: "User 1" },
      //   { id: 2, name: "User 2" },
      //   { id: 3, name: "User 3" },
      //   // Add more users as needed
      // ],
      empdata:[],
      selectedUsers: null,
      options: [],
      monthlytarget:'',
      teamLeader:'',
      id:'',
      userList:null,
      btn: "Edit",
      openrow : false,
      seen : false,
      seenNew : false,
      comma : ',',
      addBtn:'Add',
      showeditedrow: false,
      btnClass:'edt3',
      teams: [
        {
          leader: 'Team Leader 1',
          members: ['Member 1', 'Member 2', 'Member 3'],
          leaderDetails: {
            name: 'Leader 1',
            designation: 'Manager',
            targetsMet: 4,
            employees: [
              { name: 'Employee 1', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 2', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 3', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 4', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 5', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 6', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 7', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 8', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 9', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 10',targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },

              // Add more employees as needed
            ], 
          },
        },
        // Add more teams as needed
      ],
      hoveredIndex: null,
      detailsIndex: null,
      teamLeaderDetails: {},
      showAddEmployeeModal: false,
      selectedEmployee: '',
      availableEmployees: ['Employee 1', 'Employee 2', 'Employee 3', /* Add more employees */],
      userdata:[],
      membersList: [],
      updatedMemberData:[],
      showRoleDropdown: false,
      selectedJobRole: "",
      memberdetails:'',
      month_name:'',
      picked_year:'',
      monthlyTargets: [],
      monthAbbreviations: {
        '1': 'Jan',
        '2': 'Feb',
        '3': 'Mar',
        '4': 'Apr',
        '5': 'May',
        '6': 'Jun',
        '7': 'Jul',
        '8': 'Aug',
        '9': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
      },
      showTooltip: false,
      monthlyTeamTargets: [],
      monthlyTargetsAchieved: [],
      monthlyTeamTargetsAchieved: [],
      Btn:'Edit',
      rowtobeedited_id:'',
      detailsrowedit:'',
      showdetailseditedrow:false,
      month_proper_name:'',
      pastDataArray:[],
      pastTeamDataArray:[],
      monthsMetTarget:'',
      monthsMetTeamTarget:'',
      personalTargetTotal:'',
      teamTargetTotal:'',
      personalTargetAchievedTotal:'',
      teamTargetAchievedTotal:'',
      openModel:false,
      target_individual:'',
      target_team:'',
      leader_name_choose:'',
      showPopup: false,
      menuItems: [],
      memberList:[],
      tlList:'',
      checkedMembers:[],
      selected_leader:[],
      leadertoremove:'',
      savedata:false,
      openremovemodal :true,
      monthly_team_target: '-',
      monthly_team_buffer:''
    };
  },
  async created(event){
    const date = new Date();
    this.picked_year = date.getFullYear();
    this.month_name = date.getMonth()+1;
    const month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    this.month_proper_name = month_name_array[date.getMonth()];

    this.fetchEMusers();
  },
  mounted() {

  },  
  methods: {

    // closePopup() {
    //   this.detailsIndex = null;
    //   this.fetchEMusers();

    // },
    handleItemClick(value, id) {
        this.leader_name_choose = value;
        this.selected_leader = id;
        console.log("value", value);
    },
    closePopup() {
      this.showPopup = false;
      this.openremovemodal = false;
    },

    validateInput() {
      if (this.state3.self_target < 0) {
        this.state3.self_target = ''; // Reset the value if it's negative
      }
      if (this.state3.team_target < 0) {
        this.state3.team_target = ''; // Reset the value if it's negative
      }
    },
    editTeamLeader() {
      this.showRoleDropdown = true;
      this.selectedJobRole = this.memberdetails.member_status;
    },
    async saveJobRole(id, name){
      // console.log("name",name);
      
      var tl_initial_record = {
          name: name,
          id: id,
          active: 1,
          targets: {
            Jan: 0,
            Feb: 0,
            Mar: 0,
            Apr: 0,
            May: 0,
            Jun: 0,
            Jul: 0,
            Aug: 0,
            Sep: 0,
            Oct: 0,
            Nov: 0,
            Dec: 0,
          },
          achieved: {
            Jan: 0,
            Feb: 0,
            Mar: 0,
            Apr: 0,
            May: 0,
            Jun: 0,
            Jul: 0,
            Aug: 0,
            Sep: 0,
            Oct: 0,
            Nov: 0,
            Dec: 0,
          },
        };
      await axios.post(BASE_URL + '/api/assignjobrole',{'id':id, 'role': this.selectedJobRole, 'tl_initial_record':tl_initial_record, 'month':this.month_name, 'year':this.picked_year})
        .then((response)=>{
          this.memberdetails = response.data.newmemberdata;
          this.updatedMemberData = response.data.updatedmemberdata;
          this.tostMessage(response.data.success, response.data.message);
        });
      this.showDetails(this.detailsIndex, id)
      this.showRoleDropdown = false;
      this.state3.selectedEmployee='';
      
    },
    openAddEmployeeModal() {
      this.showAddEmployeeModal = true;
    },
    closeAddEmployeeModal() {
      this.showAddEmployeeModal = false;
      this.selectedEmployee = '';
    },
    closepopup(){
      this.showRoleDropdown = false;
      this.selectedJobRole = '';
      this.fetchEMusers();
    },
    async addSelectedEmployee(id) {
      console.log(this.state3.selectedEmployee.split("-"));
      const [employeeName, employeeId] = this.state3.selectedEmployee.split('-');
      if (this.state3.selectedEmployee) {
        // Add the selected employee to the team leader details
        var added_teammember = {
          name: employeeName,
          id: employeeId,
          active: 1,
          targets: {
            Jan: 0,
            Feb: 0,
            Mar: 0,
            Apr: 0,
            May: 0,
            Jun: 0,
            Jul: 0,
            Aug: 0,
            Sep: 0,
            Oct: 0,
            Nov: 0,
            Dec: 0,
          },
          achieved: {
            Jan: 0,
            Feb: 0,
            Mar: 0,
            Apr: 0,
            May: 0,
            Jun: 0,
            Jul: 0,
            Aug: 0,
            Sep: 0,
            Oct: 0,
            Nov: 0,
            Dec: 0,
          },
        };

        await axios.post(BASE_URL + '/api/userstobeadded',{'id':id, 'added_teammember':added_teammember, 'empid':employeeId, 'month':this.month_name, 'year':this.picked_year})
        .then((response)=>{
          this.updatedMemberData = response.data.memberdata;
        });
        this.state3.selectedEmployee='';
        this.showDetails(this.detailsIndex,id);
        // Close the modal after adding the employee
        this.closeAddEmployeeModal();
      }
    },

    showMembers(index) {
      this.hoveredIndex = index;
    },
    hideMembers() {
      this.hoveredIndex = null;
    },
    async showDetails(index, id) {
      console.log("index",id);
      this.showeditedrow = false;

      // this.teamLeaderDetails = this.teams[index].leaderDetails;
      await axios.post(BASE_URL + '/api/fetchuserstobeadded',{'id':id, 'month':this.month_name, 'year':this.picked_year})
      .then((response)=>{
        this.membersList = response.data.teamList;
        this.updatedMemberData = response.data.memberdata;
        this.memberdetails = response.data.memberdetails;
        this.monthsMetTarget = response.data.monthsMetTarget;
        this.monthsMetTeamTarget = response.data.monthsMetTeamTarget;
        this.personalTargetTotal = response.data.personalTargetTotal;
        this.teamTargetTotal = response.data.teamTargetTotal;
        this.personalTargetAchievedTotal = response.data.personalTargetAchievedTotal;
        this.teamTargetAchievedTotal = response.data.teamTargetAchievedTotal;
        this.target_individual = response.data.defaultPersonalTarget;
        this.target_team = response.data.defaultTeamTarget;
        this.monthly_team_target = response.data.monthly_team_target;
        this.monthly_team_buffer = response.data.monthly_team_buffer;
      });
      this.detailsIndex = index;
      this.openModel = true;

    },

    isOpen(){
      this.seen = true;
    },

    isOpenNew(){
      this.seenNew = true;
    },

    closeDatepicker(event){
      this.seen = false;
    },

    async showDate (date) {

      this.month_name = date.monthIndex;
      this.month_proper_name = date.month;
      this.picked_year = date.year;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
        
      console.log("this.picked_year",this.picked_year);
      console.log("currentYear",currentYear);
      // if (this.picked_year != currentYear) {
        this.fetchEMusers();        
      // }
      // console.log("date Y",this.state3.date.year);
      setTimeout(() => {
       this.seen = false;
      },500);
    },

    addrow (){
      if (this.openrow == false) {
        this.openrow = true;
        this.addBtn = "Discard";
        this.btnClass ='edt2'
        this.btn = "Edit";
      } else {
        this.openrow = false;
        this.addBtn = "Add";
        this.btnClass ='edt3'

      }

    },
    // async createGroup() {
    //   // Assuming you have some logic to validate and handle the creation of a new group
    //   // For simplicity, just pushing the new group to the array
    //   // this.groups.push({ ...this.newGroup, users: [], teamLeader: null });
      
    //   await axios.post(BASE_URL + '/api/saveemailmarketingteamname',{'name': this.state2.teamname})
    //   .then((response)=>{
    //   this.empdata = response.data.teamList?.map(team => {
    //   const membersArray = JSON.parse(team.members);
    //   return {
    //     ...team,
    //     members: Object.assign({}, ...membersArray?.map((member, index) => ({ [index]: member })))
    //   };
    // });
    //   // this.id = response.data.latestId;
    //   // console.log("response id",this.id);   
    //   // this.selectedUsers = response.data.teamList.map(user => user.members);
    //   // console.log('selectedUsers',this.selectedUsers);       
    //   })
    //   // Reset the form fields
    //   // this.newGroup.name = "";
    // },

    async fetchuserDetails(){
      await axios.post(BASE_URL + '/api/fetchEmailMarketingUsers')
      .then((response)=>{
      this.empdata = response.data.teamList;
      console.log("response",this.empdata); 
      this.options = response.data.userList.map(user => user.empname);         
      this.selectedUsers = response.data.teamList.map(user => user.members); 
      this.userList = response.data.addedusers;
      this.empdata = this.empdata?.map(team => {
      const membersArray = JSON.parse(team.members);
      return {
        ...team,
        members: Object.assign({}, ...membersArray?.map((member, index) => ({ [index]: member })))
      };
      });
      // this.transformedData(this.userList);
      console.log("ggug",this.empdata);        
      })
    },

    // transformedData(list) {
    //   return list.map(item => {
    //     const membersArray = JSON.parse(item.members);
    //     return { members: this.convertToSelectFormat(membersArray) };
    //   });
    // },

    // convertToSelectFormat(membersArray) {
    //   return membersArray.reduce((obj, member, index) => {
    //     obj[index] = member;
    //     return this.userList;
    //   }, {});
    // },

    // async addteam(){
    //   console.log('selectedUsers',this.state2.selectedUsers);
    //   console.log('teamLeader',this.state2.teamLeader);

    //   await axios.post(BASE_URL + '/api/saveemailmarketingteam',{ 'name': this.state2.teamname, 'members':this.state2.selectedUsers, 'monthlytarget':this.state2.monthlytarget, 
    //   'tl': this.state2.teamLeader,  'month':this.state2.date.monthIndex, 'year':this.state2.date.year,   'monthName':this.state2.date.month})
    //   // await axios.post(BASE_URL + '/api/saveemailmarketingteam',{'name': this.newGroup.name, 'members':this.state3.selectedUsers, 'tl': this.teamLeader, 'monthlytarget':this.monthlytarget})
    //     .then((response)=>{
    //       this.fetchuserDetails();
    //       this.state2.teamname ='',
    //       this.state2.selectedUsers ='',
    //       this.state2.monthlytarget ='',
    //       this.state2.teamLeader ='',
    //       this.openrow = false;
    //       this.addBtn = 'Add';
    //       this.btnClass ='edt3';
    //       this.showeditedrow = false;
    //       console.log(response);
    //   })
    // },

    // async edit(id){
    //     this.openrow = false;
    //     this.addBtn = 'Add';
    //     this.btnClass ='edt3';
    //     this.showeditedrow = true;
    //     this.id = id;
    //     await axios.post(BASE_URL + '/api/editeamdetails',{'id': id})
    //     // await axios.post(BASE_URL + '/api/saveemailmarketingteam',{'name': this.newGroup.name, 'members':this.state3.selectedUsers, 'tl': this.teamLeader, 'monthlytarget':this.monthlytarget})
    //     .then((response)=>{
          
    //       this.state3.teamname = response.data.detailstobeeditted.teamname,
    //       this.state3.selectedUsers = JSON.parse(response.data.detailstobeeditted.members);
    //       // console.log("arr",Array.isArray(this.state3.selectedUsers));
    //       // this.state3.selectedUsers = Object.assign({}, ...this.state3.selectedUsers.map((item, index) => ({ [index]: item })));
    //       this.state3.monthlytarget = response.data.detailstobeeditted.monthly_target,
    //       this.state3.teamLeader = response.data.detailstobeeditted.tl,
    //       this.state3.date.monthIndex = response.data.detailstobeeditted.month,
    //       this.state3.date.year = response.data.detailstobeeditted.year,
    //       this.state3.date.month = response.data.detailstobeeditted.monthName,
    //       // this.state3.date = response.data.detailstobeeditted.JSON.parse(date),
    //       this.btn = 'Update';
    //       console.log("dydgu",this.state3.selectedUsers);
    //   })

    // },
    // async update(id){
    //   await axios.post(BASE_URL + '/api/updateteamdetails',{'id': id, 'name': this.state3.teamname,'members':this.state3.selectedUsers, 'tl': this.state3.teamLeader,
    //      'monthlytarget':this.state3.monthlytarget, 'month':this.state3.date.monthIndex, 'year':this.state3.date.year, 'monthName':this.state3.date.month })
    //     // await axios.post(BASE_URL + '/api/saveemailmarketingteam',{'name': this.newGroup.name, 'members':this.state3.selectedUsers, 'tl': this.teamLeader, 'monthlytarget':this.monthlytarget})
    //     .then((response)=>{
    //       this.fetchuserDetails();
    //       this.btn = 'Edit';
    //       this.showeditedrow = false;
    //     })
    // },
    // async del(id){
    //   await axios.post(BASE_URL + '/api/deleamdetails',{'id': id})
    //     .then((response)=>{
    //       this.fetchuserDetails();
    //   })
    // },

    async fetchEMusers(){
      await axios.post(BASE_URL + '/api/fetchEMusers',{'month':this.month_name, 'year':this.picked_year})
      .then((response)=>{
        this.userdata = response.data.teamList;
      });
      this.userdata.forEach((team) => {
        if (team.monthly_target !== null) {
          team.monthlyTargets = JSON.parse(team.monthly_target);
        }
      });

      this.userdata.forEach((team) => {
        if (team.team_monthly_target !== null) {
          team.monthlyTeamTargets = JSON.parse(team.team_monthly_target);
        }
      });

      this.userdata.forEach((team) => {
        if (team.monthly_target_achieved !== null) {
          team.monthlyTargetsAchieved = JSON.parse(team.monthly_target_achieved);
        }
      });      
      
      this.userdata.forEach((team) => {
        if (team.monthly_team_target_achieved !== null) {
          team.monthlyTeamTargetsAchieved = JSON.parse(team.monthly_team_target_achieved);
        }
      });

      this.userdata.forEach((team) => {
        if (team.pastData !== null) {
          team.pastDataArray = JSON.parse(team.pastData);
        }
      });

      this.userdata.forEach((team) => {
        if (team.pastTeamData !== null) {
          team.pastTeamDataArray = JSON.parse(team.pastTeamData);
        }
      });
      console.log("userdata",this.userdata);

    },

    getMembersCount(members) {
      console.log("members",members);
      // const parsedMembers = this.parseMembers(members);
      // return parsedMembers ? parsedMembers.length : 0;
      const activeMembers = this.parseMembers(members).filter(member => member.active === 1);
      console.log("activeMembers",activeMembers);

      return activeMembers ? activeMembers.length : 0
    },

    parseMembers(members) {
      try {
        return JSON.parse(members);
      } catch (error) {
        console.error('Error parsing members:', error);
        return [];
      }
    },

    showMembers(index) {
      this.hoveredIndex = index;
    },

    hideMembers() {
      this.hoveredIndex = null;
    },

    async saveDetails(team){
      console.log("team",team);
      await axios.post(BASE_URL + '/api/assignmonthlyteamtarget',{'id':team.empid, 'month':this.month_name, 'year':this.picked_year,
       'target':team.team_target, 'self_target':team.self_target})
        .then((response)=>{
          this.fetchEMusers();

        });
    },

    generateTooltipContent(monthlyTargets) {
      // Convert reactive object to plain JavaScript object
      const plainObject = toRaw(monthlyTargets);

      if (!plainObject || typeof plainObject !== 'object') {
        // console.log("bsygfusg");
        return ''; // or any default value
      }

      const monthData = Object.entries(plainObject);
      var data = monthData.map(([month, value]) => `${month}: ${value}`).join(', ');
      console.log("data",data);

      return data;
    },

    async editDetails(id, self_target, team_target){
      this.rowtobeedited_id = id;
      this.showeditedrow = true;
      console.log("self_target",self_target);
      this.state3.self_target = self_target;
      this.state3.team_target = team_target;
      // await axios.post(BASE_URL + '/api/targetdataforedit',{'id':id, 'month':this.month_name, 'year':this.picked_year})
      //   .then((response)=>{
      //     // this.fetchEMusers();
      //     this.state3.team_target = response.data.team_target;
      //     this.state3.self_target = response.data.target;
      //     this.tostMessage(response.data.success, response.data.message);
      //   });
    },

    async updateDetails(id){
      this.showeditedrow = false;
      console.log("state3.team_target",this.state3.date.monthIndex);
      console.log("state3.self_target",this.state3.self_target);
      await axios.post(BASE_URL + '/api/updateassignedtask',{'id':id, 'team_target':this.state3.team_target, 'self_target':this.state3.self_target,
       'month':this.month_name, 'year':this.picked_year})
        .then((response)=>{
          this.tostMessage(response.data.success, response.data.message);
          this.state3.team_target='';
          this.state3.self_target='';
        });
      this.fetchEMusers();
    },
     async updatepopupDetails(id){

     },

     async editpopupDetails(employee, status){
      // this.detailsrowedit = id;
      // this.showdetailseditedrow =true;
      console.log("id",employee.id);
      console.log("details Jan",employee.targets.Jan);
      console.log("details Feb",employee.targets.Feb);
      console.log("details Mar",employee.targets.Mar);
      // console.log("details Mar",employee.targets.Mar);
      // console.log("details Apr",employee.targets.Apr);
      // console.log("details May",employee.targets.May);
      // console.log("details Jun",employee.targets.Jun);
      await axios.post(BASE_URL + '/api/updatetargetdetails',{'id':employee.id, 'Jan':employee.targets.Jan, 'Feb':employee.targets.Feb, 'Mar':employee.targets.Mar, 
      'Apr':employee.targets.Apr, 'May':employee.targets.May, 'Jun':employee.targets.Jun,'Jul':employee.targets.Jul,'Aug':employee.targets.Aug,'Sep':employee.targets.Sep,
      'Oct':employee.targets.Oct, 'Nov':employee.targets.Nov, 'Dec':employee.targets.Dec, 'month':this.month_name, 'year':this.picked_year})
   
        .then((response)=>{
          this.tostMessage(response.data.success, response.data.message);
        });

        this.showDetails(this.detailsIndex, status);

     },

     async removemember(emp, status){

        // console.log("status",status);
        // console.log("emp",emp.id);
        if (status == emp.id) {
          if(confirm("Are you sure you want to remove the team leader?")){

            await axios.post(BASE_URL + '/api/removemember',{'id':emp.id})    
              .then((response)=>{
                this.tostMessage(response.data.success, response.data.message);

              });
          }
          else{
            console.log("tl not removed");
          }
        } else {

          if(confirm("Are you sure you want to remove this member?")){

            await axios.post(BASE_URL + '/api/removemember',{'id':emp.id})    
              .then((response)=>{
                this.tostMessage(response.data.success, response.data.message);

              });
          }
          else{
            console.log("member not removed");
          }
        }
        this.showDetails(this.detailsIndex, status);
        // await axios.post(BASE_URL + '/api/removemember',{'id':emp.id})    
        //   .then((response)=>{

        //   });
     },

     calculateTargetsMet() {
      let targetsMet = 0;
      for (const employee of this.updatedMemberData) {
        targetsMet += parseInt(employee.achieved.Jan) +
                      parseInt(employee.achieved.Feb) +
                      parseInt(employee.achieved.Mar) +
                      parseInt(employee.achieved.Apr) +
                      parseInt(employee.achieved.May) +
                      parseInt(employee.achieved.Jun);
      }
      return targetsMet;
    },

    calculatePercentageCompletion() {
      const totalTargets = 6 * this.updatedMemberData.length; // Assuming 6 months for all employees
      const percentageCompletion = (this.calculateTargetsMet() / totalTargets) * 100;
      return percentageCompletion.toFixed(2); // Adjust decimal places as needed
    },

    async setIndividualTarget(id){
      if (this.target_individual == null) {
        this.tostMessage('warning', 'Default target can not be blank');
        
      } else {
        await axios.post(BASE_URL + '/api/assignmonthlyteamtarget',{'id':id, 'month':this.month_name, 'year':this.picked_year,
       'target':'', 'self_target':this.target_individual})
        .then((response)=>{
          // this.fetchEMusers();
          this.tostMessage(response.data.success, response.data.message);
          this.showDetails(this.detailsIndex, id);
        });
      }

    },

    async setTeamTarget(id){

      if (this.target_team == null) {
        this.tostMessage('warning', 'Default target can not be blank');
        
      } else {
        await axios.post(BASE_URL + '/api/assignmonthlyteamtarget',{'id':id, 'month':this.month_name, 'year':this.picked_year,
       'target':this.target_team, 'self_target':''})
        .then((response)=>{
          // this.fetchEMusers();
          this.tostMessage(response.data.success, response.data.message);
          this.showDetails(this.detailsIndex, id);
        });
      }

    },

    async setBufferTeamTarget(id){
      if (this.target_buffer == null) {
        this.tostMessage('warning', 'Default target can not be blank');
        
      } else {
        await axios.post(BASE_URL + '/api/addbufferteamtarget',{'id':id, 'month':this.month_name, 'year':this.picked_year,
       'buffer':this.target_buffer})
        .then((response)=>{
          // this.fetchEMusers();
          this.target_buffer = '';
          this.tostMessage(response.data.success, response.data.message);
          this.showDetails(this.detailsIndex, id);
        });
      }
    },

    tostMessage(status,message){
            console.log("status",status);
                createToast({
                    // title: title,
                    description: message
                },
                {
                type: status,
                timeout: 4000,
                position: 'bottom-right',
                showIcon: 'true'
                })
    },

    async removetl(id, memberid){

      if(confirm("Are you sure you want to remove the team leader?")){

        if (id == memberid) {
          this.openremovemodal = true;
          console.log("tlid",this.openremovemodal);
          this.leadertoremove = id;
          await axios.post(BASE_URL + '/api/getmemberdatafornewteam',{'id':id, 'year':this.picked_year})
            .then((response)=>{
              this.memberList = response.data.memberList;
              this.menuItems = response.data.tlList;
              // this.updatedMemberData = response.data.updatedmemberdata;
          });
        }

      }

    },

    async allocatemembers(){

      
      console.log("ftff",this.selected_leader);
      console.log("Checked members:", this.checkedMembers);
      console.log("leadertoremove", this.leadertoremove);
      let teamMembers = []; // Array to store team members

      // Loop through the employeeIds and create added_teammember objects
      // Object.values(this.checkedMembers).forEach(id => {
      //   let added_teammember = {
      //     name: '',
      //     id: '',
      //     active: 1,
      //     targets: {
      //       Jan: 0,
      //       Feb: 0,
      //       Mar: 0,
      //       Apr: 0,
      //       May: 0,
      //       Jun: 0,
      //       Jul: 0,
      //       Aug: 0,
      //       Sep: 0,
      //       Oct: 0,
      //       Nov: 0,
      //       Dec: 0,
      //     },
      //     achieved: {
      //       Jan: 0,
      //       Feb: 0,
      //       Mar: 0,
      //       Apr: 0,
      //       May: 0,
      //       Jun: 0,
      //       Jul: 0,
      //       Aug: 0,
      //       Sep: 0,
      //       Oct: 0,
      //       Nov: 0,
      //       Dec: 0,
      //     },
      //   };

      //   teamMembers.push(added_teammember); // Push the created team member to the array
      // });
      var added_teammember = {
          name: '',
          id: '',
          active: 1,
          targets: {
            Jan: 0,
            Feb: 0,
            Mar: 0,
            Apr: 0,
            May: 0,
            Jun: 0,
            Jul: 0,
            Aug: 0,
            Sep: 0,
            Oct: 0,
            Nov: 0,
            Dec: 0,
          },
          achieved: {
            Jan: 0,
            Feb: 0,
            Mar: 0,
            Apr: 0,
            May: 0,
            Jun: 0,
            Jul: 0,
            Aug: 0,
            Sep: 0,
            Oct: 0,
            Nov: 0,
            Dec: 0,
          },
        };

      console.log("memeberList",this.memberList);
      const empidArray = this.memberList.map(member => member.empid);
      console.log("this.checkedMembers", empidArray);
      console.log("this.selected_leader", this.selected_leader);
      const remainingIds = empidArray.filter(empid => !this.checkedMembers.includes(empid));
      console.log("remainingIds",remainingIds);

      if (this.checkedMembers.length == 0 || this.selected_leader.length == 0) {
        console.log("ifffffffffffffffffff");
        this.tostMessage('warning', 'Please select an member and a teamleader to allocate the member');
      }
      else {
        await axios.post(BASE_URL + '/api/removetlandassignmembers',{'previd':this.leadertoremove, 'newid':this.selected_leader, 'memberids':this.checkedMembers, 'year':this.picked_year,
      'added_teammember': added_teammember, 'remainingIds':remainingIds})
        .then((response)=>{
          if (response.data.memberList == []) {
            console.log("inininininininin");
            this.showPopup = false;
          }
          this.memberList = response.data.memberList;
          console.log("outout", this.memeberList);
          // this.menuItems = response.data.tlList;
          // this.updatedMemberData = response.data.updatedmemberdata;

        });
        this.savedata = true;
      }


    }

  },
};
</script>

<!-- <style scoped>
/* Add your styling here */

/* Style for the form */
form {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input {
  padding: 8px;
  width: 200px;
}

button {
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

/* Style for the table */


select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* Optional: Add some spacing and alignment */
h2 {
  margin-bottom: 10px;
}

.membersList {
  padding: 5px;
    background-color:#54a0ff;
    border-radius: 5px;
    color: white;
    margin-right: 5px;
}
.edt3  {
  display: flex;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.edt2  {
  display: flex;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  background-color: #e92d2d;
}
.title_tbtn_alin .modal_caldn_btn {
    margin-left: unset;
}

.main-right .main-title  {
  display: flex;
  justify-content: center;
}

.member-tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 999;
}

/* .details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
} */

/* Adjust the width of the popup content as needed */
/* .details-popup-content {
  width: 80%;
  max-width: 600px; 
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
} */

/* Add styles for the employee details table */
.details-popup-content table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.details-popup-content th, .details-popup-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.details-popup-content th {
  background-color: #f2f2f2;
}

/* .details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
} */

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 100%; /* Adjust this value to position the tooltip below the triggering element */
  left: 0;   /* Adjust this value to position the tooltip horizontally */
  transition: 0.5s;
  z-index: 1;
  display: none;
}

.hover-tool-tip{
  position: relative;
  display: block;
}
.hover-tool-tip:hover .tooltip{
  opacity: 1 ;
  display: block;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  margin-top: 10px;
  position: relative;
}

.progress-bar div {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}


/******************** sumit 08-02-2024 ********************/

.modal  {
  --bs-modal-zindex: 9999 !important;
}

.details_modal  .modal-dialog {
  max-width: 1024px;
}

.details_modal  .modal-dialog .modal-header {
  padding: 12px 15px;
}

.details_modal  .modal-dialog .modal-header h5  {
  font-size: 21px;
  font-weight: 600;
  color: #212121;
}

.details_modal  .modal-dialog .modal_role  {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  display: flex;
  align-items: center;
}

.details_modal  .modal-dialog .modal_role button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 7px 28px;
  border-radius: 5px;
  margin-left: 10px;
}

.details_modal  .modal-dialog .job-role-dropdown  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px;
}

.details_modal  .modal-dialog .job-role-dropdown select  {
  border: none;
  background-color: #f5f5f5;
  padding: 12px 15px;
  display: block;
  width: 90%;
  font-size: 13px;
  color: #666;
  resize: none;
  font-weight: 500;
  border-radius: 4px;
}

.details_modal  .modal-dialog .job-role-dropdown button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 28px;
  border-radius: 5px;
}

.details_modal  .modal-dialog .modal_month  {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;
}

.details_modal  .modal-dialog .modal_month p  {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 6px;
}

.details_modal  .modal-dialog .add_employe  {
  padding-bottom: 15px;
}

.details_modal  .modal-dialog .add_employe .add_employe_btn  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal  {
  background: #f5f5f5;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal label  {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  white-space: nowrap;
  width: 142px;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal select  {
  border: none;
  background-color: #f5f5f5;
  padding: 12px 15px 12px 5px;
  display: block;
  width: 90%;
  font-size: 13px;
  color: #666;
  resize: none;
  font-weight: 500;
  border-radius: 4px;
  width: calc(100% - 240px);
}

.details_modal  .modal-dialog .add_employe .add-employee-modal button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
  width: 85px;
}

.details_modal  .modal-dialog .add_employe .table_scroll  {
  max-height: 400px;
  overflow: auto;
}

.details_modal .modal-dialog .modal_set  {
  column-gap: 6px;
}

.details_modal .modal-dialog .modal_set input  {
  max-width: 200px !important;
  height: 36px;
  text-align: left !important;
  padding: 8px 10px !important;
  border: 1px solid #f1f1f1 !important;
  font-size: 12px !important;
}

.details_modal .modal-dialog .modal_set button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
}

.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar{height: 8px; width: 8px; background: #000;border-radius: 10px;}
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-track {background: #f1f1f1;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb {background: #888;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb:hover {background: #555;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb:horizontal{background: #000;border-radius: 10px;}

.table-section .table-wapper .table-data table tbody tr+tr .tooltip  {
  bottom: 100%;
  top: auto;
}

.table-section .table-wapper .table-data table thead  {
  position: relative;
  z-index: 2;
}

.details_modal  .modal-dialog {
    max-width: 1300px !important;
}
</style> -->

<style scoped>
form {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input {
  padding: 8px;
  width: 200px;
}

button {
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

/* Style for the table */


select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* Optional: Add some spacing and alignment */
h2 {
  margin-bottom: 10px;
}

.membersList {
  padding: 5px;
    background-color:#54a0ff;
    border-radius: 5px;
    color: white;
    margin-right: 5px;
}
.edt3  {
  display: flex;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.edt2  {
  display: flex;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  background-color: #e92d2d;
}
.title_tbtn_alin .modal_caldn_btn {
    margin-left: unset;
}

.main-right .main-title  {
  display: flex;
  justify-content: center;
}

.member-tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 999;
}

/* .details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
} */

/* Adjust the width of the popup content as needed */
/* .details-popup-content {
  width: 80%;
  max-width: 600px; 
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
} */

/* Add styles for the employee details table */
.details-popup-content table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.details-popup-content th, .details-popup-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.details-popup-content th {
  background-color: #f2f2f2;
}

/* .details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
} */

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  top: 100%; /* Adjust this value to position the tooltip below the triggering element */
  left: 0;   /* Adjust this value to position the tooltip horizontally */
  transition: 0.5s;
  z-index: 1;
  display: none;
}

.hover-tool-tip{
  position: relative;
  display: block;
}
.hover-tool-tip:hover .tooltip{
  opacity: 1 ;
  display: block;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  margin-top: 10px;
  position: relative;
}

.progress-bar div {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}


/******************** sumit 08-02-2024 ********************/

.modal  {
  --bs-modal-zindex: 9999 !important;
}

.details_modal  .modal-dialog {
  max-width: 1024px;
}

.details_modal  .modal-dialog .modal-header {
  padding: 12px 15px;
}

.details_modal  .modal-dialog .modal-header h5  {
  font-size: 21px;
  font-weight: 600;
  color: #212121;
}

.details_modal  .modal-dialog .modal-header .btn-close  {
  background-color: #fff;
}

.details_modal  .modal-dialog .modal_role  {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  display: flex;
  align-items: center;
}

.details_modal  .modal-dialog .modal_role button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 7px 28px;
  border-radius: 5px;
  margin-left: 10px;
}

.details_modal  .modal-dialog .job-role-dropdown  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 10px 16px;
}

.details_modal  .modal-dialog .job-role-dropdown select  {
  border: none;
  background-color: #f5f5f5;
  padding: 12px 15px;
  display: block;
  width: 90%;
  font-size: 13px;
  color: #666;
  resize: none;
  font-weight: 500;
  border-radius: 4px;
}

.details_modal  .modal-dialog .job-role-dropdown button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 28px;
  border-radius: 5px;
}

.details_modal  .modal-dialog .modal_month  {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;
}

.details_modal  .modal-dialog .modal_month p  {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 6px;
}

.details_modal  .modal-dialog .add_employe  {
  padding-bottom: 15px;
}

.details_modal  .modal-dialog .add_employe .add_employe_btn  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal  {
  background: #f5f5f5;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal label  {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  white-space: nowrap;
  width: 142px;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal select  {
  border: none;
  background-color: #f5f5f5;
  padding: 12px 15px 12px 5px;
  display: block;
  width: 90%;
  font-size: 13px;
  color: #212121;
  resize: none;
  font-weight: 600;
  border-radius: 4px;
  width: calc(100% - 240px);
}

.details_modal  .modal-dialog .add_employe .add-employee-modal select option  {
  padding: 12px 0px;
  font-size: 13px;
  color: #212121;
  font-weight: 600;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
  width: 85px;
}

/* .details_modal  .modal-dialog .add_employe .table_scroll  {
  max-height: 400px;
  overflow: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
} */

.details_modal .modal-dialog .modal_set  {
  column-gap: 6px;
}

.details_modal .modal-dialog .modal_set h2  {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}

.details_modal .modal-dialog .modal_set h2 span  {
  color: #3cb89b;
  font-weight: 600;
  padding-left: 4px;
}
.details_modal .modal-dialog .modal_set input  {
  max-width: 200px !important;
  height: 36px;
  text-align: left !important;
  padding: 8px 10px !important;
  border: 1px solid #f1f1f1 !important;
  font-size: 12px !important;
}

.details_modal .modal-dialog .modal_set button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
}

.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar{height: 8px; width: 8px; background: #000;border-radius: 10px;}
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-track {background: #f1f1f1;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb {background: #888;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb:hover {background: #555;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb:horizontal{height: 8px; width: 8px; background: #000;border-radius: 10px;}

.table-section .table-wapper .table-data table tbody tr+tr .tooltip  {
  bottom: 100%;
  top: auto;
}

.table-section .table-wapper .table-data table thead  {
  position: relative;
  z-index: 1;
}

.details_modal  .modal-dialog {
    max-width: 1300px !important;
}

.remove_modal .modal-dialog {
  max-width: 650px;
}

.remove_modal  .modal-dialog .modal-header {
  padding: 12px 15px;
}

.remove_modal  .modal-dialog .modal-header h5  {
  font-size: 21px;
  font-weight: 600;
  color: #212121;
}
.remove_modal  .modal-dialog .modal-header .btn-close  {
  background-color: #fff;
}

.remove_modal .remove_body  {
  display: flex;
  justify-content: space-between;
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.remove_modal .remove_body::-webkit-scrollbar{height: 8px; width: 8px; background: #000;border-radius: 10px;}
.remove_modal .remove_body::-webkit-scrollbar-track {background: #f1f1f1;border-radius: 10px; }
.remove_modal .remove_body::-webkit-scrollbar-thumb {background: #888;border-radius: 10px; }
.remove_modal .remove_body::-webkit-scrollbar-thumb:hover {background: #555;border-radius: 10px; }
.remove_modal .remove_body::-webkit-scrollbar-thumb:horizontal{height: 8px; width: 8px; background: #000;border-radius: 10px;}

.remove_modal .remove_body .left .checkbox_div:not(:last-child)  {
  padding-bottom: 12px;
}

.remove_modal .remove_body .left .checkbox_div .checkbox_inline  {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  display: flex;
  align-items: center;
  margin: 0;
}

.remove_modal .remove_body .left .checkbox_div .checkbox_inline  input  {
  width: 14px;
  height: 14px;
  border: 1px solid #212121;
  margin-right: 10px;
  margin-left: 0;
}

.remove_modal .remove_body .right .dropdown {
  padding-right: 0px;
}
.remove_modal .remove_body .right .dropdown button {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  width: 205px;
  background: #f1f1f1;
}
.remove_modal .remove_body .right .dropdown .dropdown-toggle.show svg  {
  transform: rotate(180deg);
}
.remove_modal .remove_body .right .dropdown button::after {
  content: unset;
}
.remove_modal .remove_body .right .dropdown button svg {
  width: 12px;
  height: 12px;
  margin-left: 6px;
  margin-top: 2px;
  transition: all ease-in-out .3s;
}
.remove_modal .remove_body .right .dropdown .dropdown-menu {
  width: 190px;
  padding: 0;
  border: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.remove_modal .remove_body .right .dropdown .dropdown-menu .drop_padding {
  padding: 6px 0px;
}
.remove_modal .remove_body .right .dropdown .dropdown-menu .drop_padding li {
  font-size: 13px;
  padding: 10px 10px;
  font-weight: 500;
  color: #212121;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.remove_modal .remove_body .right .dropdown .dropdown-menu .drop_padding li:not(:last-child)  {
  border-bottom: 1px dashed #dfdfdf;
}
.remove_modal .remove_body .right .dropdown .dropdown-menu .drop_padding li:hover {
  background: #f1f1f1;
}
.remove_modal .modal-footer .save_btn  {
  background: #54a0ff;
  padding: 7px 26px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}
.remove_modal .modal_close_popup  {
  position: absolute;
  background: #ffffff00;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.remove_modal .modal_close_popup .close_body  {
  width: 470px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 50px 30px 20px 30px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.remove_modal .modal_close_popup p  {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  line-height: 21px;
  margin: auto;
  text-align: center;
  padding-bottom: 30px;
}

.remove_modal .modal_close_popup .modal_flex  {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove_modal .modal_close_popup .modal_flex .no_btn  {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  padding: 7px 26px;
  background: #fff;
}

.remove_modal .modal_close_popup .modal_flex .yes_btn  {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  border-radius: 3px;
  background: #54a0ff;
  padding: 8px 26px;
  color: #fff;
}
.asign_user_height  {
  height: calc(100vh - 60px);
  padding: 0 40px;
  margin-top: 60px;
}

.remove_modal .left{position: sticky; top: 0;}

</style>

<!-- <style>
.month_picker_boxes  {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.month_picker_boxes .month-picker-input  {
  width: 100% !important;
  padding: 10px 10px !important;
}

.month_picker_boxes .month-picker__container  {
  right: 0 !important;
  z-index: 3 !important;
}

.month_picker_boxes .month-picker__year button  {
  height: auto;
}
</style> -->

<style>
.month_picker_boxes  {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.month_picker_boxes .month-picker-input  {
  width: 100% !important;
  padding: 10px 10px !important;
}

.month_picker_boxes .month-picker__container  {
  right: 0 !important;
  z-index: 3 !important;
}

.month_picker_boxes .month-picker__year button {
    height: auto;
    background-color: #f6f6f6 !important;
    color: #212121 !important;
    padding: 0px !important;
    display: unset !important;
    font-size: 28px !important;
}
</style>