<template>
  <div class="main-right">
    <div class="main-title title_tbtn_alin d-flex flex-row justify-content-between align-items-center">       
        <div class="table_name">
          <h2>Task Report for {{month_proper_name}} {{ ',' }} {{ picked_year }}</h2>
        </div> 
        <!-- <button class="stl-btn modal_caldn_btn" id="modal-tggl1" @click="isOpen()">
          <img src="@/assets/images/icon/calendar-plus.png" alt=""> Select a month 
        </button> -->
        <div class="month_picker_boxes">
          <label>Select Month :</label>
            <!-- <month-picker-input placeholder="Select month year" @change="showDate" :no-default="true"></month-picker-input> -->
            <month-picker-input placeholder="Select Month Year" @change="showDate" :no-default="false" :show-year="true" :default-month="new Date().getMonth()+1"></month-picker-input>

        </div>
      </div>
   
      <!-- <div class="popup-modal" id="popupModa1" v-if="seen">
            <div class="mp-content">
            <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
              <img src="@/assets/images/icon/x-lg.svg" alt="">
            </button>
            <div class="bdy-sect">
              <h3>Select a month</h3>
              <month-picker @change="showDate"  no-default></month-picker>
            </div>            
          </div>
      </div> -->
    <!-- Form to create a group -->
    <!-- <div>
      <h2>Create Group</h2>
      <form @submit.prevent="createGroup">
        <label for="groupName">Group Name:</label>
        <input type="text" id="groupName" v-model="state3.teamname" required />
        <button type="submit">Create Group</button>
      </form>
    </div> -->
    <!-- <VueMultiselect 
                    v-model="selectedUsers"
                    :options= "options"
                    :multiple="true"
                  >
                  </VueMultiselect > -->

    <!-- <multiselect
      v-model="selectedUsers"
      :options="empdata"
      label="empname"
      track-by="id"
      placeholder="Select users"
      :multiple="true"
    ></multiselect> -->

    <!-- Table to display created groups -->
    <div class="table-section">
      <div class="table-wapper">
        <div class="table-data table-w-100">
          <!-- <h2>Team Report for {{month_proper_name}} {{ ',' }} {{ picked_year }}</h2> -->
          <!-- <button type="button" @click="addrow()" :class="btnClass">{{addBtn}}</button> -->
          <table class="payroll-table">
            <thead>
              <tr>
                <th>Emp Name</th>
                <th>Mails Sent(/day)</th>
                <th>Positive Responses(/day)</th>
                <th>Total Responses(/day)</th>
                <th>Total Leads Generated</th>
                <th>Total Deals Closed</th>
                <th>Team Leader</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in dealReportdata" :key="index">
                   
                    <td>
                        <span>{{ row.empname }}</span>
                        <!-- <input v-else v-model="row.client" type="text" /> -->
                    </td>
                    <td>
                        
                        <!-- <input v-if="row.id == rowtobeedited_id && showeditedrow == true" v-model="row.amount" type="text" /> -->
                        <span>{{ row.totalMailSentPerDay.toFixed(1) }}</span>
                    </td>
                    <td>
                        {{ row.totalpositive_responsePerDay.toFixed(1) }}
                    </td>
                    <td>
                        <span>{{ row.total_responsePerDay.toFixed(1) }}</span>
                    </td>
                    <td>
                        <span>{{ row.total_lead }}</span>
                    </td>
                    <td>
                        <span>{{ row.lead_closed }}</span>
                        <!-- <input v-else v-model="row.empname" type="text" /> -->
                    </td>
                    <td>
                        <span>{{ row.teamLeaderName }}</span>
                        <!-- <input v-else v-model="row.teamLeaderName" type="text" /> -->
                    </td>
                    <!-- <td>
                        <div class="table_flex d-flex">

                            <button v-if="row.id == rowtobeedited_id && showeditedrow == true" @click="updateDetails(row.id,row.amount,row.payment_status)" class="edtl btn-lps">Update</button>
                            <button v-else @click="editDetails(row.id)" class="edtl btn-lps">Edit</button>
                        </div>

                        
                    </td> -->
                </tr>
            </tbody>
          </table>

          
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import { createLocal, createSession, createStorage } from 'the-storages'
    import { BASE_URL } from "../../config";
    import axios from "axios";
    import VueMultiselect from 'vue-multiselect'
    const mirror = createLocal()
    const storage = mirror._prx
    import 'vue-multiselect/dist/vue-multiselect.css';

    import Swal from 'sweetalert2'
    
    import { ref, computed } from 'vue'
    
    import { reactive } from "vue"
    import { jsPDF } from "jspdf";
    import html2canvas from 'html2canvas';
    // import { MonthPicker } from 'vue-month-picker';
    import { MonthPickerInput } from 'vue-month-picker'

    import { toRaw } from 'vue';
    
export default {
  name: 'TaskReport',
  components: { VueMultiselect, MonthPickerInput },
  setup() {
          const state3 = reactive({
              team_target:'',
              self_target:'',
              selectedEmployee: '',
              teamLeader:'',
              monthlytarget:'',
              monthly_target_achieved:'',
              monthly_team_target_achieved:'',
              date: {
                from: null,
                to: null,
                month: null,
                year: null
              }
              
          })

          const state2 = reactive({
              teammate:'',
              selectedUsers: [],
              options: [],
              teamLeader:'',
              monthlytarget:'',
              date: {
                from: null,
                to: null,
                month: null,
                year: null
              }
          })

          const isOpen = ref(false)
          const isOpenNew = ref(false)
          
          return {
              state3,
              state2,
              isOpen,
              isOpenNew,
          }
      },
  data() {
    return {
      // newGroup: {
      //   name: "",
      // },
      // groups: [],
      // groupname:'',
      // allUsers: [
      //   { id: 1, name: "User 1" },
      //   { id: 2, name: "User 2" },
      //   { id: 3, name: "User 3" },
      //   // Add more users as needed
      // ],
      empdata:[],
      selectedUsers: null,
      options: [],
      monthlytarget:'',
      teamLeader:'',
      id:'',
      userList:null,
      btn: "Edit",
      openrow : false,
      seen : false,
      seenNew : false,
      comma : ',',
      addBtn:'Add',
      showeditedrow: false,
      btnClass:'edt3',
      teams: [
        {
          leader: 'Team Leader 1',
          members: ['Member 1', 'Member 2', 'Member 3'],
          leaderDetails: {
            name: 'Leader 1',
            designation: 'Manager',
            targetsMet: 4,
            employees: [
              { name: 'Employee 1', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 2', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 3', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 4', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 5', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 6', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 7', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 8', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 9', targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },
              { name: 'Employee 10',targets: { Jan: 5, Feb: 4, Mar: 6, Apr:5, May: 7, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 }, achieved: { Jan: 4, Feb: 3, Mar: 5, Apr:4, May: 6, Jun: 2, Jul: 6, Aug: 8, Sep: 7, Oct: 5, Nov: 4, Dec: 5 } },

              // Add more employees as needed
            ], 
          },
        },
        // Add more teams as needed
      ],
      hoveredIndex: null,
      detailsIndex: null,
      teamLeaderDetails: {},
      showAddEmployeeModal: false,
      selectedEmployee: '',
      availableEmployees: ['Employee 1', 'Employee 2', 'Employee 3', /* Add more employees */],
      dealReportdata:[],
      updatedMemberData:[],
      showRoleDropdown: false,
      selectedJobRole: "",
      memberdetails:'',
      month_name:'',
      picked_year:'',
      monthlyTargets: [],
      monthAbbreviations: {
        '1': 'Jan',
        '2': 'Feb',
        '3': 'Mar',
        '4': 'Apr',
        '5': 'May',
        '6': 'Jun',
        '7': 'Jul',
        '8': 'Aug',
        '9': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
      },
      showTooltip: false,
      monthlyTeamTargets: [],
      monthlyTargetsAchieved: [],
      monthlyTeamTargetsAchieved: [],
      Btn:'Edit',
      rowtobeedited_id:'',
      detailsrowedit:'',
      showdetailseditedrow:false,
      month_proper_name:'',
      pastDataArray:[],
      pastTeamDataArray:[],
      monthsMetTarget:'',
      monthsMetTeamTarget:'',
      personalTargetTotal:'',
      teamTargetTotal:'',
      personalTargetAchievedTotal:'',
      teamTargetAchievedTotal:'',
      openModel:false,
      target_individual:'',
      target_team:'',
    };
  },
  async created(event){
    const date = new Date();
    this.picked_year = date.getFullYear();
    this.month_name = date.getMonth()+1;
    const month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    this.month_proper_name = month_name_array[date.getMonth()];
    this.getTaskReport()

  },
  mounted() {

  },  

  methods:{
    isOpen(){
          this.seen = true;
      },
      closeDatepicker(event){
          this.seen = false;
      },
      async showDate (date) {
          this.month_name = date.monthIndex;
          this.month_proper_name = date.month;
          this.picked_year = date.year;
          this.getTaskReport()
          console.log("date m",date);
          // console.log("date Y",this.state3.date.year);
          setTimeout(() => {
          this.seen = false;
          },500);
      },
      async editDetails(id){
          this.rowtobeedited_id = id;
          this.showeditedrow = true;
      },
      async getTaskReport(){
      await axios.post(BASE_URL + '/api/getTaskReport',{'month':this.month_name, 'year':this.picked_year})
      
      .then((response)=>{
      this.dealReportdata = response.data.taskReportList;

      });
    },
  }
};
</script>

<style scoped>
/* Add your styling here */

/* Style for the form */
form {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input {
  padding: 8px;
  width: 200px;
}

button {
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

/* Style for the table */


select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* Optional: Add some spacing and alignment */
h2 {
  margin-bottom: 10px;
}

.membersList {
  padding: 5px;
    background-color:#54a0ff;
    border-radius: 5px;
    color: white;
    margin-right: 5px;
}
.edt3  {
  display: flex;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.edt2  {
  display: flex;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  background-color: #e92d2d;
}
.title_tbtn_alin .modal_caldn_btn {
    margin-left: unset;
}

.main-right .main-title  {
  display: flex;
  justify-content: center;
}

.member-tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 999;
}

/* .details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
} */

/* Adjust the width of the popup content as needed */
/* .details-popup-content {
  width: 80%;
  max-width: 600px; 
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
} */

/* Add styles for the employee details table */
.details-popup-content table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.details-popup-content th, .details-popup-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.details-popup-content th {
  background-color: #f2f2f2;
}

/* .details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
} */

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 0;
  top: 100%; /* Adjust this value to position the tooltip below the triggering element */
  left: 0;   /* Adjust this value to position the tooltip horizontally */
  transition: 0.5s;
  z-index: 0;
  display: none;
}

.hover-tool-tip{
  position: relative;
  display: block;
}
.hover-tool-tip:hover .tooltip{
  opacity: 1 ;
  display: block;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  margin-top: 10px;
  position: relative;
}

.progress-bar div {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}


/******************** sumit 08-02-2024 ********************/

.modal  {
  --bs-modal-zindex: 9999 !important;
}

.details_modal  .modal-dialog {
  max-width: 1024px;
}

.details_modal  .modal-dialog .modal-header {
  padding: 12px 15px;
}

.details_modal  .modal-dialog .modal-header h5  {
  font-size: 21px;
  font-weight: 600;
  color: #212121;
}

.details_modal  .modal-dialog .modal_role  {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  display: flex;
  align-items: center;
}

.details_modal  .modal-dialog .modal_role button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 7px 28px;
  border-radius: 5px;
  margin-left: 10px;
}

.details_modal  .modal-dialog .job-role-dropdown  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px;
}

.details_modal  .modal-dialog .job-role-dropdown select  {
  border: none;
  background-color: #f5f5f5;
  padding: 12px 15px;
  display: block;
  width: 90%;
  font-size: 13px;
  color: #666;
  resize: none;
  font-weight: 500;
  border-radius: 4px;
}

.details_modal  .modal-dialog .job-role-dropdown button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 28px;
  border-radius: 5px;
}

.details_modal  .modal-dialog .modal_month  {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;
}

.details_modal  .modal-dialog .modal_month p  {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 6px;
}

.details_modal  .modal-dialog .add_employe  {
  padding-bottom: 15px;
}

.details_modal  .modal-dialog .add_employe .add_employe_btn  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal  {
  background: #f5f5f5;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal label  {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  white-space: nowrap;
  width: 142px;
}

.details_modal  .modal-dialog .add_employe .add-employee-modal select  {
  border: none;
  background-color: #f5f5f5;
  padding: 12px 15px 12px 5px;
  display: block;
  width: 90%;
  font-size: 13px;
  color: #666;
  resize: none;
  font-weight: 500;
  border-radius: 4px;
  width: calc(100% - 240px);
}

.details_modal  .modal-dialog .add_employe .add-employee-modal button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
  width: 85px;
}

.details_modal  .modal-dialog .add_employe .table_scroll  {
  max-height: 400px;
  overflow: auto;
}

.details_modal .modal-dialog .modal_set  {
  column-gap: 6px;
}

.details_modal .modal-dialog .modal_set input  {
  max-width: 200px !important;
  height: 36px;
  text-align: left !important;
  padding: 8px 10px !important;
  border: 1px solid #f1f1f1 !important;
  font-size: 12px !important;
}

.details_modal .modal-dialog .modal_set button  {
  background-color: #3cb89b;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 18px;
  border-radius: 5px;
}

.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar{height: 8px; width: 8px; background: #000;border-radius: 10px;}
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-track {background: #f1f1f1;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb {background: #888;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb:hover {background: #555;border-radius: 10px; }
.details_modal  .modal-dialog .add_employe .table_scroll::-webkit-scrollbar-thumb:horizontal{background: #000;border-radius: 10px;}

.table-section .table-wapper .table-data table tbody tr+tr .tooltip  { bottom: 100%; top: auto;}

.details_modal  .modal-dialog {  max-width: 1300px !important;}
</style>
