<template>
  <div class="emp_dashboard_section">
    <div class="emp_dashboard_left">
      <EmpDashboardLeftside />
    </div>
    <div class="emp_dashboard_right">
      <EmpDashboardHeader @dealAdded="newDealAdded" />
      <div class="emp_dashboard_content" v-if="dealaddprop">
        <DashboardTeamLead :dealAddProp="dealaddedstatus" :key="dealKey" />
      </div>
      <div class="emp_dashboard_content" v-else>
        <DashboardTeamLead />
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/empcss/empcss.css";
import EmpDashboardHeader from "@/components/EmpDashboard/EmpDashboardHeader.vue";
import EmpDashboardLeftside from "@/components/EmpDashboard/EmpDashboardLeftside.vue";
import DashboardTeamLead from "@/components/EmpDashboard/DashboardTeamLead.vue";
import { createLocal } from "the-storages";
const mirror = createLocal();

export default {
  name: 'LmsEmpDashboard',
  components: {
    EmpDashboardHeader,
    EmpDashboardLeftside,
    DashboardTeamLead,
  },
  data() {
    return {
      dealaddedstatus: false,
      dealaddprop: false,
      dealKey: 0, // Unique key to force re-render
    };
  },
  created() {
    if (mirror.ID == '') {
      localStorage.clear();
      this.$router.push('/');
    } else if (mirror.marketing_status == 'non_member') {
      localStorage.clear();
      this.$router.push('/');
    }
  },
  methods: {
    newDealAdded(dealstatus) {
      this.dealaddedstatus = false;
      this.dealKey++; // Increment the key to force re-render
      this.dealaddedstatus = dealstatus;
      console.log("New deal status:", this.dealaddedstatus);
    },
  },
  watch: {
    dealaddedstatus(newVal) {
      this.dealaddprop = true;
      console.log("Deal added status updated:", newVal);
    },
  },
};
</script>
