<template>
    <div class="main-right">
        <div class="main-title">
          <h2>Employee List </h2>
        </div>
        <div class="head-bar">
          <ul>
            <li>
              No of Employees: <span>{{totalemp}}</span>
            </li>
          </ul>
          <div class="fild-info">
            <form action="#">
              <div class="fr-gp">
                <input type="search" v-model="searchText" placeholder="Search..." @input="onSearch">

              </div>
            </form>
            <button class="dwnlod-btn"  @click="clickedDownload()">
              download
              <img src="@/assets/images/icon/download.svg" alt="">
            </button>
          </div>
        </div>

        <div class="head__btnGp">
           <router-link to="/admininactiveemplist">Inactive Employee </router-link>
        </div>
        
        <div class="table-section" >
          <div class="table-wapper">
            <div class="table-data table-w-100 mail_info_table">
              <table cellspacing="0" cellpadding="0" border="0">
                <thead>
                  <tr>
                    <th>Employee Name </th>
                    <th>Employee ID</th>
                    <th>Email Address</th>
                    <th>Phone No.</th>
                    <th>Position</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody v-show="message.data !== 0" v-for="(row) in message.data" :key="row">
                  <tr>
                    <td v-show="row.fname!=''">{{row.fname}} {{row.lname}}</td>
                    <td>{{row.employeeid}}</td>
                    <td>{{row.email}}</td>
                    <td>{{row.phone}}</td>
                    <td>{{row.position}}</td>
                    <td>
                      <div class="btn-lps">
                        <router-link :to="`/editemployeedetails/${row.id}`" class="edtl" >Edit </router-link>
                        <a href="#" class="rept" v-if="row.is_active == 1" @click="activeInactiveClick('active',row.id)" >Inactive</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
                 <!-- <tbody v-show="message.data == 0"><tr>No Data Available</tr></tbody> -->
              </table>
              <div class="no_data_div" v-show="message.data == 0">
                <p>No Data Available</p>
              </div>
            </div>
            <pagination v-model="page" :records="totalData" :per-page="10" @paginate="getResults(page,this.searchText)"/>
          </div>
      </div>

      <div class="table-section" id="divToPrintEmpList" v-if="showTable">
          <div class="table-wapper">
            <div class="table-data table-w-100 mail_info_table">
              <table cellspacing="0" cellpadding="0" border="0">
                <thead>
                  <tr>
                    <th>Employee Name </th>
                    <th>Employee ID</th>
                    <th>Email Address</th>
                    <th>Phone No.</th>
                    <th>Position</th>
                  </tr>
                </thead>
                <tbody v-for="(row) in record" :key="row" class="downloadable-row">
                  <tr>
                    <td>{{row.empname}}</td>
                    <td>{{row.employeeid}}</td>
                    <td>{{row.email}}</td>
                    <td>{{row.phone}}</td>
                    <td>{{row.position}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>

    </div>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';

const mirror = createLocal()
const storage = mirror._prx

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js";

import Swal from 'sweetalert2';

export default {
    name: 'EmployeeList',
    components:{
        Pagination
    },
    data() {
        return {
            page: 1,
            storageData: mirror,
            storage: storage,
            is_show: false,
            message:  {},
            searchText:"",
            nofound:false,
            errormessage: "",
            totalData:'',
            searchData:'',
            dateSearch:'',
            flag:'',
            errormessage1:"",
            totalemp : "",
            showTable: false,
            record:''
        }
    },
    methods :{
       onSearch(){
           this.getResults(this.page,this.searchText);
       },
        getResults(page,searchText='') {
            if (typeof page === "undefined") {
                page = 1;
            }
            if(searchText=='') {
                this.flag = 'list';
            }
            if(searchText!=''){
                this.flag = 'search';
                this.searchText = searchText;
            }
            
            const data ={
                userId: this.ID,
                flag: this.flag,
                searchText:this.searchText,

            }
            //console.log(data);
            
            axios.post(BASE_URL + `/api/getallemplist?page=${page}`,data)
                .then((response)=>{
                console.log(response.data);

                this.message = response.data;
                this.totalData =  response.data.total;
                this.is_show= (this.message.last_page > 1)?true:false;

                this.totalemp = response.data.total;

                if(this.message.data?.length>0){
                    this.nofound=false;
                }else{
                    this.nofound=true;
                }

            })
        },
        // async clickedDownload(){
        //     var pdf = new jsPDF("1", "mm", "a1");
        //     var element = document.getElementById('divToPrintEmpList');
        //     var width= element.style.width;
        //     var height = element.style.height;
        //         html2canvas(element).then(canvas => {
        //             //console.log(canvas);
        //             var image = canvas.toDataURL('image/png');
        //             pdf.addImage(image, 'JPEG', 15, 40, width, height);
        //             pdf.save('EmployeeList' + '.pdf')
        //     });
        // },


        // async clickedDownload(){
        //         // var pdf = new jsPDF("1", "mm", "a1");
        //         // var element = document.getElementById('divToPrintLeaveReportList');
        //         // var width= element.style.width;
        //         // var height = element.style.height;
        //         //     html2canvas(element).then(canvas => {
        //         //         //console.log(canvas);
        //         //         var image = canvas.toDataURL('image/png');
        //         //         pdf.addImage(image, 'JPEG', 15, 40, width, height);
        //         //         pdf.save('LeaveReportList' + '.pdf')
        //         // });
        //         // var element = this.generateTableHTML(data);
        //         // console.log("element",element);
        //         var element = document.getElementById('divToPrintEmpList');
        //         console.log("element1",element);

        //         var headerCell = element.querySelector('.actions-header');
        //     if (headerCell) {
        //       headerCell.remove();
        //     }

        //     // Remove the "Actions" column from each row
        //     var rows = element.querySelectorAll('.downloadable-row');
        //     rows.forEach(row => {
        //       var actionsCell = row.querySelector('.actions-column');
        //       if (actionsCell) {
        //         actionsCell.remove();
        //       }
        //     });

        //     var opt = {
        //       margin: 0.1,
        //       filename: 'LeaveReport'.concat(".", 'pdf'),
        //       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        //       html2canvas: { scale: 1 },
        //       pagebreak: { mode: 'avoid-all', before: '#page2el' }
        //     };

        //     // New Promise-based usage:
        //     html2pdf().set(opt).from(element).save();
        // },

        async clickedDownload() {
          var data = this.record;

          try {
            if (!data || data.length === 0) {
              throw new Error('Data is empty or undefined.');
            }

            // Generate HTML content for the table
            const tableHtml = this.generateTableHTMLWithPageBreaks(data);

            // Define PDF generation options
            const opt = {
              margin:       0.1,
              filename:     'EmployeeList.pdf',
              jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
              html2canvas:  { scale: 1.1 },
              pagebreak:    { avoid: '.downloadable-row' }
            };

            // Generate the PDF
            await html2pdf().set(opt).from(tableHtml).save();
          } catch (error) {
            console.error('Error generating PDF:', error);
          }
        },

        generateTableHTMLWithPageBreaks(data) {
          // Create a temporary <div> element to build the table HTML
          const tempDiv = document.createElement('div');

          // Add table HTML content with page breaks and CSS classes for spacing
          if (data && data.length > 0) {
            tempDiv.innerHTML = `
              <style>
                .table-section {
                  width: 100%;
                }
                .table-wrapper {
                  margin: 0 auto;
                  padding: 10px;
                }
                .table-data {
                  width: 100%;
                  border-collapse: collapse;
                }
                .table-data th, .table-data td {
                  padding: 8px 12px;
                  text-align: left;
                  border: 1px solid #ddd;
                }
                .table-data th {
                  background-color: #f2f2f2;
                }
                .downloadable-row td {
                  word-wrap: break-word;
                  white-space: pre-line;
                }
              </style>
              <div class="table-section">
                <div class="table-wrapper">
                  <table cellspacing="0" cellpadding="0" border="0" class="table-data print-table">
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Employee ID</th>
                        <th>DOJ</th>
                        <th>Email Address</th>
                        <th>Phone No.</th>
                        <th>Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${data.map(row => `
                        <tr class="downloadable-row">
                          <td>${row.empname}</td>
                          <td>${row.employeeid}</td>
                          <td>${row.doj}</td>
                          <td>${row.email}</td>
                          <td>${row.phone}</td>
                          <td>${row.position}</td>
                        </tr>
                      `).join('')}
                    </tbody>
                  </table>
                </div>
              </div>
            `;
          } else {
            console.warn('No data provided to generate table HTML.');
          }

          // Log the generated HTML to the console
          console.log('Generated HTML:', tempDiv.innerHTML);

          // Return the temporary <div> element
          return tempDiv;
        },




        async activeInactiveClick(status,id){

            if(confirm("Are you sure you want to inactive this?")){

                const getdata = {
                    url_data: id,
                    status:status
                };
                //console.log(getdata);

                if(status=='active'){
                        axios.post(BASE_URL + '/api/getempstatus',getdata)
                        .then((response)=>{
                            //console.log(response.data);

                            Swal.fire({
                                title: 'Done',
                                text:  response.data.message,
                                icon: 'success',
                            
                            });

                            this.getResults();

                        })
                    }

            }else{
                return false;
            }

            
        },
    },
    async created(){
        this.userName = mirror.USERNAME
        
        this.ID = mirror.AUTHID;
        axios.post(BASE_URL + `/api/getAllEmpDetails`,this.ID)
                .then((response)=>{
                // console.log(response.data);

                this.record = response.data.record;
                console.log("this.record",this.record);
        })
   },
    mounted(){
        this.getResults();
    },

}
</script>
<style scoped> 
.print-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px; /* Adjust the font size as needed */
}

.print-table th, .print-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

@media print {
  .table-section {
    page-break-before: always;
  }
}


</style>