import { createRouter, createWebHistory } from "vue-router";

import Login from "./components/Login.vue";
// import Dashboard from './components/Dashboard.vue'
// import Leave from './components/Leave.vue'
// import EmployeeDashboard from './components/EmployeeDashboard.vue'
import AddNewEmployee from "./components/AdminDashboard/AddNewEmployee.vue";
// import AdminEmployeeDetails from './components/AdminEmployeeDetails'
// import EditEmployeeDetails from './components/EditEmployeeDetails'
// import Report from './components/Report.vue'
// import AdminEmployeeAttendance from './components/AdminEmployeeAttendance.vue'
// import LeaveReport from './components/LeaveReport.vue'
// import AdminEmployeeLeaveReport from './components/AdminEmployeeLeaveReport.vue'
import AdminDashboardPage from "./pages/AdminDashboardPage.vue";
import AdminAddNewEmpPage from "./pages/AdminAddNewEmpPage.vue";
import AdminEditEmpPage from "./pages/AdminEditEmpPage.vue";
import AdminLeaveStatus from "./pages/AdminLeaveStatus.vue";
import AdminEmpList from "./pages/AdminEmpList.vue";
import AdminInactiveEmpList from "./pages/AdminInactiveEmpList.vue";
import AdminWfhDetailsPage from "./pages/AdminWfhDetailsPage.vue";
import AdminAttendanceReportPage from "./pages/AdminAttendanceReportPage.vue";
import AdminAttendanceRecordPage from "./pages/AdminAttendanceRecordPage.vue";
import AdminHolidayListPage from "./pages/AdminHolidayListPage.vue";

import AdminLeaveReport from "./pages/AdminLeaveReport.vue";

import AdminLeaveRecord from "./pages/AdminLeaveRecord.vue";

import EmpDashboardPage from "./pages/EmpDashboardPage.vue";
import AdminDateChangeStatusPage from "./pages/AdminDateChangeStatusPage.vue";
import SalaryReportPage from "./pages/SalaryReportPage.vue";
import SalarySlipPage from "./pages/SalarySlipPage.vue";
import AttendanceAdjustmentPage from "./pages/AttendanceAdjustmentPage.vue";
import ChromeNotificationPage from "./pages/ChromeNotificationPage.vue";
import GroupwiseSalaryPovisionAllocationPage from "./pages/GroupwiseSalaryPovisionAllocationPage.vue";
import LeaveCarryForwardStatusPage from "./pages/LeaveCarryForwardStatusPage.vue";
import TaskAssignmentPage from "./pages/TaskAssignmentPage.vue";
import AssignUserPage from "./pages/AssignUserPage.vue";
import TaskReportPage from "./pages/TaskReportPage.vue";

import Dashboard from "./pages/Dashboard.vue";
import MonthData from "./pages/MonthData.vue";
import HolidayList from "./pages/HolidayList.vue";
import DealReportPage from "./pages/DealReportPage.vue";
import AssignIncentives from "../src/components/MarketingDashboard/AssignIncentives.vue";
import AssignIncentivesDetails from "../src/components/MarketingDashboard/AssignIncentivesDetails.vue";

import LmsEmpDashboard from "./pages/LmsEmpDashboard.vue";
import EmpDashboardNewPage from "./pages/EmpDashboardNewPage.vue";



const routes = [
  {
    name: "Login",
    component: Login,
    path: "/",
  },
  // {
  //     name: 'Dashboard',
  //     component: Dashboard,
  //     path: '/dashboard'
  // },
  // {
  //     name: 'Leave',
  //     component: Leave,
  //     path: '/leave'
  // },
  // {
  //     name: 'EmployeeDashboard',
  //     component: EmployeeDashboard,
  //     path: '/employeedashboard'
  // },
  {
    name: "AddNewEmployee",
    component: AddNewEmployee,
    path: "/addnewemployee",
  },
  {
    name: "AdminAddNewEmpPage",
    component: AdminAddNewEmpPage,
    path: "/adminaddnewemppage",
  },
  {
    name: "AdminEditEmpPage",
    component: AdminEditEmpPage,
    path: "/editemployeedetails/:id",
  },
  {
    name: "AdminLeaveStatus",
    component: AdminLeaveStatus,
    path: "/adminleavestatus",
  },
  {
    name: "AdminLeaveReport",
    component: AdminLeaveReport,
    path: "/adminleavereport",
  },
  {
    name: "AdminLeaveRecord",
    component: AdminLeaveRecord,
    path: "/adminleaverecord/:id/:nid/:cid/:sid",
  },
  {
    name: "AdminEmpList",
    component: AdminEmpList,
    path: "/adminemplist",
  },
  {
    name: "AdminInactiveEmpList",
    component: AdminInactiveEmpList,
    path: "/admininactiveemplist",
  },
  {
    name: "AdminAttendanceReportPage",
    component: AdminAttendanceReportPage,
    path: "/adminattendancereportpage",
  },
  {
    name: "AdminAttendanceRecordPage",
    component: AdminAttendanceRecordPage,
    path: "/adminattendancerecordpage/:id/:uname",
  },
  // {
  //   name: "AdminAttendanceRecordPage",
  //   component: AdminAttendanceRecordPage,
  //   path: "/adminattendancerecordpage/:id/:present/:uname",
  // },
  // {
  //     name: 'AdminEmployeeDetails',
  //     component: AdminEmployeeDetails,
  //     path: '/adminemployeedetails/:id/:sid/:pid/:eid'
  // },
  // {
  //     name: 'EditEmployeeDetails',
  //     component: EditEmployeeDetails,
  //     path: '/editemployeedetails/:id'
  // },
  // {
  //     name: 'Report',
  //     component: Report,
  //     path: '/report'
  // },
  // {
  //     name: 'AdminEmployeeAttendance',
  //     component: AdminEmployeeAttendance,
  //     path: '/adminemployeeattendance/:id/:fid'
  // },
  // {
  //     name: 'LeaveReport',
  //     component: LeaveReport,
  //     path: '/leavereport'
  // },
  // {
  //     name: 'AdminEmployeeLeaveReport',
  //     component: AdminEmployeeLeaveReport,
  //     path: '/adminemployeeleavereport/:id/:fid'
  // },
  {
    name: "AdminDashboardPage",
    component: AdminDashboardPage,
    path: "/adminDashboard",
  },
  {
    name: "EmpDashboardPage",
    component: EmpDashboardPage,
    path: "/empdashboardpage",
    beforeEnter: (to, from, next) => {
      next({ name: 'Login' });
    },
  },
  {
    name: "AdminWfhDetailsPage",
    component: AdminWfhDetailsPage,
    path: "/adminWfhDetailsPage",
  },
  {
    name: "AdminHolidayListPage",
    component: AdminHolidayListPage,
    path: "/adminHolidayListPage",
  },
  {
    name: "AdminDateChangeStatusPage",
    component: AdminDateChangeStatusPage,
    path: "/adminDateChangeStatusPage",
  },
  {
    name: "SalaryReportPage",
    component: SalaryReportPage,
    path: "/salaryreport",
  },
  {
    name: "SalarySlipPage",
    component: SalarySlipPage,
    path: "/salaryslip",
  },
  {
    name: "AttendanceAdjustmentPage",
    component: AttendanceAdjustmentPage,
    path: "/attendanceAdjustment",
  },
  {
    name: "ChromeNotificationPage",
    component: ChromeNotificationPage,
    path: "/chromeNotificationPage",
  },
  {
    name: "GroupwiseSalaryPovisionAllocationPage",
    component: GroupwiseSalaryPovisionAllocationPage,
    path: "/groupwiseSalaryPovisionAllocationPage",
  },
  {
    name: "LeaveCarryForwardStatusPage",
    component: LeaveCarryForwardStatusPage,
    path: "/leaveCarryForwardStatusPage",
  },
  {
    name: "TaskAssignmentPage",
    component: TaskAssignmentPage,
    path: "/taskAssignmentPage",
  },
  {
    name: "AssignUserPage",
    component: AssignUserPage,
    path: "/assignUserPage",
  },
  {
    name: "Dashboard",
    component: Dashboard,
    path: "/dashboard",
  },
  {
    name: "MonthData",
    component: MonthData,
    path: "/deal-record",
  },
  {
    name: "HolidayList",
    component: HolidayList,
    path: "/holiday-list",
  },
  {
    name: "TaskReportPage",
    component: TaskReportPage,
    path: "/taskReport",
  },
  {
    name: "DealReportPage",
    component: DealReportPage,
    path: "/deal-report",
  },
  {
    name: "AssignIncentives",
    component: AssignIncentives,
    path: "/assign-incentives",
  },
  {
    name: "AssignIncentivesDetails",
    component: AssignIncentivesDetails,
    path: "/assign-incentives-details/:id/:uname/:status",
  },
  {
    name: "LmsEmpDashboard",
    component: LmsEmpDashboard,
    path: "/lms-emp-dashboard",
  },
  {
    name: "EmpDashboardNewPage",
    component: EmpDashboardNewPage,
    path: "/emp-dashboard-new",
  },
  
  
];
// const router = createRouter({
//     history:createWebHistory(),
//     routes
// })

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
});

export default router;