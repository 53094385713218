<template>
  <div class="DashboardTeamLead">
    <div class="three_box">
      <div class="row">
        <div class="col-lg-4">
          <div class="box bluebackground">
            <div class="name">
              <h3>Your Target for {{ month_proper_name }}</h3>
            </div>

            <!-- <div class="t_all">
                        <div class="">
                                <div class="graph_div">
                                    <h4>Individual</h4>
                                    <div class="graph_box">
                                        <apexchart
                                            width="300"
                                            type="radialBar"
                                            :series="series"
                                            :options="personalChartOptions"
                                            
                                        ></apexchart>
                                        <img src="../../assets/empdashboardimages/batchone.png" alt="batch">
                                    </div>
                                </div>
                        </div>
                        <div class="" v-if="marketing_status == 'Team Leader'">
                            <div class="graph_div">
                                <h4>Team</h4>
                                <div class="graph_box">
                                    <apexchart
                                        width="300"
                                        type="radialBar"
                                        :series="series"
                                        :options="teamChartOptions"
                                    ></apexchart>
                                    <img src="../../assets/empdashboardimages/batchtwo.png" alt="batch">
                                </div>
                            </div>
                        </div>
                      </div> -->
            <!-- <template v-if="personCompletionPercentage==''">
            <div class="skeleton-loader"></div>
            <div class="skeleton-loader"></div>
            <div class="skeleton-loader"></div>
            <div class="skeleton-loader"></div>
            <div class="skeleton-loader"></div>
            </template>
            <template v-else> -->
                <div class="row justify-content-center">
                <div class="col-6">
                    <div class="graph_div">
                    <h4>Individual</h4>
                    <div class="graph_box">
                        <apexchart
                        width="300"
                        type="radialBar"
                        :series="series"
                        :options="personalChartOptions"
                        ></apexchart>
                        <img
                        src="../../assets/empdashboardimages/batchone.png"
                        alt="batch"
                        />
                    </div>
                    </div>
                </div>
                <div class="col-6" v-if="marketing_status == 'Team Leader'">
                    <div class="graph_div">
                    <h4>Team</h4>
                    <div class="graph_box">
                        <apexchart
                        width="300"
                        type="radialBar"
                        :series="series"
                        :options="teamChartOptions"
                        ></apexchart>
                        <img
                        src="../../assets/empdashboardimages/batchtwo.png"
                        alt="batch"
                        />
                    </div>
                    </div>
                </div>
                </div>
            <!-- </template> -->
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box orangebackground">
            <div class="name">
              <h3>Your Target for {{ month_proper_name }}</h3>
            </div>
            <template v-if="loading == false">
              <div class="skeleton-loader"></div>
              <div class="skeleton-loader"></div>
              <div class="skeleton-loader"></div>
              <div class="skeleton-loader"></div>
              <div class="skeleton-loader"></div>
            </template>
            <template v-else>
              <p
                v-if="
                  personalTarget != '' &&
                  PersonalPaymentRecievedTotal >= personalTarget
                "
              >
                <span>Congratulations!</span>{{ message }}
              </p>
              <p
                v-if="
                  personalTarget != '' &&
                  PersonalPaymentRecievedTotal < personalTarget
                "
              >
                {{ message }}
              </p>
              <h2>{{ daysLeft }} Days Left</h2>
              <h5>
                Your Previous Overdue is of
                <span>${{ lastQuarterOverdue }}</span>
              </h5>
            </template>
          </div>
        </div>
        <div class="col-lg-4">
            <div class="box bluebackground">
                <div class="name">
                    <h3>Your Prize</h3>
                    <!-- <h2>${{ monthValue }}</h2> -->
                </div>
                <template v-if="loading == false">
                    <div class="skeleton-loader"></div>
                    <div class="skeleton-loader"></div>
                    <div class="skeleton-loader"></div>
                    <div class="skeleton-loader"></div>
                    <div class="skeleton-loader"></div>
                </template>
                <template v-else>

                <div class="row">
                    <div class="col-7">
                        <div class="name">
                            <!-- <h3>Your Prize</h3> -->
                            <h2>${{ monthValue }}</h2>
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="prize_image">
                            <img src="../../assets/empdashboardimages/trophy.png" alt="prize">
                        </div>
                    </div>
                </div>

                    <p v-if="PersonalPaymentRecievedTotal != '' && personalTarget != '' && PersonalPaymentRecievedTotal >= personalTarget">
                        Congratulations! You’ve met your monthly target. Well done! Let’s Keep Pushing for more.
                    </p>
                    <p v-else>Once you meet your monthly target.</p>
                </template>
            </div>
        </div>
      </div>
    </div>
    <div class="two_box">
      <div class="row">
        <div class="col-lg-6 color-box-deshboard">
          <div class="two_boxes">
            <div class="two_boxes_heading">
              <div class="left">
                <h3>
                  <span>Deals</span>
                  (Personal)
                </h3>
              </div>

              <div class="right">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="this_month_selected_PersonalDeal != ''">{{
                      this_month_selected_PersonalDeal
                    }}</span>
                    <span v-else>This Month</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="drop_padding">
                      <li
                        v-for="item in menuPersonalDealItems"
                        :key="item.title"
                        class="d-flex justify-content-between align-items-center"
                        @click="handlePersonalDealItemClick(item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <div class="two_boxes_body">
            <template v-if="loading == false">
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-xxl-4 col-lg-3">
                  <div class="heading">
                    <h2>Closed</h2>
                  </div>
                  <div class="number">
                    <h2>{{ closedPersonalDealCount }}</h2>
                  </div>
                </div>
                <div class="col-xxl-8 col-lg-9">
                  <div class="heading">
                    <h2>Deal Valuation</h2>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <div class="deal_box blue">
                        <p>Finalize</p>
                        <h4>${{ closedPersonalDeal }}</h4>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="deal_box green">
                        <p>Received</p>
                        <h4>${{ PersonalPaymentRecievedTotal }}</h4>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="deal_box red">
                        <p>Due</p>
                        <h4>
                          ${{
                            closedPersonalDeal - PersonalPaymentRecievedTotal
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 color-box-deshboard"
          v-if="marketing_status == 'Team Leader'"
        >
          <div class="two_boxes">
            <div class="two_boxes_heading">
              <div class="left">
                <h3>
                  <span>Deals</span>
                  (Team)
                </h3>
              </div>
              <div class="right">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="this_month_selected_TeamDeals != ''">{{
                      this_month_selected_TeamDeals
                    }}</span>
                    <span v-else>This Month</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="drop_padding">
                      <li
                        v-for="item in menuTeamDealsItems"
                        :key="item.title"
                        class="d-flex justify-content-between align-items-center"
                        @click="handleTeamDealsItemClick(item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div class="two_boxes_body">
                <template v-if="loading == false">
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                </template>
                <template v-else>
                <div class="row">
                    <div class="col-xxl-4 col-lg-3">
                    <div class="heading">
                        <h2>Closed</h2>
                    </div>
                    <div class="number">
                        <h2>{{ closedTeamDealCount }}</h2>
                    </div>
                    </div>
                    <div class="col-xxl-8 col-lg-9">
                    <div class="heading">
                        <h2>Deal Valuation</h2>
                    </div>
                    <div class="row">
                        <div class="col-4">
                        <div class="deal_box blue">
                            <p>Finalize</p>
                            <h4>${{ teamPaymentTotal }}</h4>
                        </div>
                        </div>
                        <div class="col-4">
                        <div class="deal_box green">
                            <p>Received</p>
                            <h4>${{ closedTeamDealTotal }}</h4>
                        </div>
                        </div>
                        <div class="col-4">
                        <div class="deal_box red">
                            <p>Due</p>
                            <h4>${{ teamPaymentTotal - closedTeamDealTotal }}</h4>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </template>
            </div>
          </div>
        </div>
        <div class="col-lg-6 color-box-deshboard green_box">
          <div class="two_boxes">
            <div class="two_boxes_heading">
              <div class="left">
                <h3>
                  <span>Payments</span>
                  (Personal)
                </h3>
              </div>
              <div class="right">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="this_month_selected_PersonalPayment != ''">{{
                      this_month_selected_PersonalPayment
                    }}</span>
                    <span v-else>This Month</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="drop_padding">
                      <li
                        v-for="item in menuPersonalPaymentItems"
                        :key="item.title"
                        class="d-flex justify-content-between align-items-center"
                        @click="handlePersonalPaymentItemClick(item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div class="two_boxes_body">
            <template v-if="loading == false">
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-4">
                  <div class="heading">
                    <h2>Closed</h2>
                  </div>
                  <div class="number">
                    <h2>{{ paymentCompletionCount }}</h2>
                  </div>
                </div>
                <div class="col-4">
                  <div class="heading">
                    <h2>Valuation</h2>
                  </div>
                  <div class="list">
                    <ul>
                      <li v-for="(row, index) in dealpaidfor" :key="index">
                        {{ row.client }}<span>${{ row.amount }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-4">
                  <div class="heading">
                    <h2>Received</h2>
                  </div>
                  <div class="digit">
                    <h2>${{ paymentCompletionTotal }}</h2>
                  </div>
                </div>
              </div>
            </template>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 color-box-deshboard green_box"
          v-if="marketing_status == 'Team Leader'"
        >
          <div class="two_boxes">
            <div class="two_boxes_heading">
              <div class="left">
                <h3>
                  <span>Payments</span>
                  (Team)
                </h3>
              </div>
              <div class="right">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="this_month_selected_TeamPayment != ''">{{
                      this_month_selected_TeamPayment
                    }}</span>
                    <span v-else>This Month</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="drop_padding">
                      <li
                        v-for="item in menuTeamPaymentItems"
                        :key="item.title"
                        class="d-flex justify-content-between align-items-center"
                        @click="handleTeamPaymentItemClick(item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div class="two_boxes_body">
            <template v-if="loading == false">
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
                <div class="skeleton-loader"></div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-4">
                  <div class="heading">
                    <h2>Closed</h2>
                  </div>
                  <div class="number">
                    <h2>{{ teamPaymentCompletionCount }}</h2>
                  </div>
                </div>
                <div class="col-4">
                  <div class="heading">
                    <h2>Valuation</h2>
                  </div>
                  <div class="list">
                    <ul>
                      <li v-for="(row, index) in teamDealpaidfor" :key="index">
                        {{ row.client }}<span>${{ row.amount }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-4">
                  <div class="heading">
                    <h2>Received</h2>
                  </div>
                  <div class="digit">
                    <h2>${{ teamPaymentCompletionTotal }}</h2>
                  </div>
                </div>
              </div>
            </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="two_box green_box">
            <div class="row">
                <div class="col-lg-6 padding">
                    <div class="two_boxes">
                        <div class="two_boxes_heading">
                            <div class="left">
                                <h3>
                                    <span>Payments</span>
                                    (Personal)
                                </h3>
                            </div>
                            <div class="right">
                                <div class="dropdown">
                                    <button class="btn dropdown-toggle d-flex align-items-center" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span v-if="this_month_selected_PersonalPayment!=''">{{ this_month_selected_PersonalPayment }}</span>
                                        <span v-else>This Month</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <div class="drop_padding">
                                            <li v-for="item in menuPersonalPaymentItems" :key="item.title" class="d-flex justify-content-between align-items-center" @click="handlePersonalPaymentItemClick(item.title)">
                                                {{ item.title }}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="two_boxes_body">
                            <div class="row">
                                <div class="col-4">
                                    <div class="heading">
                                        <h2>Closed</h2>
                                    </div>
                                    <div class="number">
                                        <h2>{{ paymentCompletionCount }}</h2>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="heading">
                                        <h2>Valuation</h2>
                                    </div>
                                    <div class="list">
                                        <ul>
                                            <li v-for="(row, index) in dealpaidfor" :key="index">{{ row.client }}<span>${{ row.amount }}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="heading">
                                        <h2>Received</h2>
                                    </div>
                                    <div class="digit">
                                        <h2>${{ paymentCompletionTotal }} </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 padding" v-if="marketing_status == 'Team Leader'">
                    <div class="two_boxes">
                        <div class="two_boxes_heading">
                            <div class="left">
                                <h3>
                                    <span>Payments</span>
                                    (Team)
                                </h3>
                            </div>
                            <div class="right">
                                <div class="dropdown">
                                    <button class="btn dropdown-toggle d-flex align-items-center" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span v-if="this_month_selected_TeamPayment!=''">{{ this_month_selected_TeamPayment }}</span>
                                        <span v-else>This Month</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <div class="drop_padding">
                                            <li v-for="item in menuTeamPaymentItems" :key="item.title" class="d-flex justify-content-between align-items-center" @click="handleTeamPaymentItemClick(item.title)">
                                                {{ item.title }}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="two_boxes_body">
                            <div class="row">
                                <div class="col-4">
                                    <div class="heading">
                                        <h2>Closed</h2>
                                    </div>
                                    <div class="number">
                                        <h2>{{ teamPaymentCompletionCount }}</h2>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="heading">
                                        <h2>Valuation</h2>
                                    </div>
                                    <div class="list">
                                        <ul>
                                            <li v-for="(row, index) in teamDealpaidfor" :key="index">{{ row.client }}<span>${{ row.amount }}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="heading">
                                        <h2>Received</h2>
                                    </div>
                                    <div class="digit">
                                        <h2>${{ teamPaymentCompletionTotal }}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="dashboard_table">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-Deals-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Deals"
            type="button"
            role="tab"
            aria-controls="pills-Deals"
            aria-selected="true"
          >
            Latest Deals
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-Reports-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Reports"
            type="button"
            role="tab"
            aria-controls="pills-Reports"
            aria-selected="false"
          >
            Latest Reports
          </button>
        </li>
        <!-- <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-Leads-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Leads"
            type="button"
            role="tab"
            aria-controls="pills-Leads"
            aria-selected="false"
          >
            Scheduled Leads
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-Contracts-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-Contracts"
            type="button"
            role="tab"
            aria-controls="pills-Contracts"
            aria-selected="false"
          >
            All Contracts
          </button>
        </li> -->
      </ul>
      <div class="tab-content lmsTeamTable" id="pills-tabContent">
        <div
          class="tab-pane orange_table fade show active"
          id="pills-Deals"
          role="tabpanel"
          aria-labelledby="pills-Deals-tab"
        >
          <div class="tab_heading">
            <div class="left">
              <h2>Latest Deals</h2>
            </div>

            <div class="right">
              <div class="right">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="table_filter_selected_value_payment != ''">{{
                      table_filter_selected_value_payment
                    }}</span>
                    <span v-else>All Payments</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="drop_padding">
                      <li
                        v-for="item in menuTableFilterItemsPayment"
                        :key="item.title"
                        class="d-flex justify-content-between align-items-center"
                        @click="handleTableFilterItemClickPayments(item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="right">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="table_filter_selected_value_deal != ''">{{
                      table_filter_selected_value_deal
                    }}</span>
                    <span v-else>All Deals</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="drop_padding">
                      <li
                        v-for="item in menuTableFilterItemsDeal"
                        :key="item.title"
                        class="d-flex justify-content-between align-items-center"
                        @click="handleTableFilterItemClickDeals(item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="right">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span v-if="table_filter_selected_value != ''">{{
                      table_filter_selected_value
                    }}</span>
                    <span v-else>Latest Deals</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      width="14"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                      />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="drop_padding">
                      <li
                        v-for="item in menuTableFilterItems"
                        :key="item.title"
                        class="d-flex justify-content-between align-items-center"
                        @click="handleTableFilterItemClick(item.title)"
                      >
                        {{ item.title }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="search">
                <button>
                  <img
                    src="../../assets/empdashboardimages/search-icon.png"
                    alt="search-icon"
                  />
                </button>
                <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search by name"
                />
              </div>
            </div>
          </div>
          <div class="tab_table">
            <table>
              <thead>
                <tr>
                  <th>Member Name</th>
                 
                  <th>Client Name</th>
                  <th>Amount</th>
                  <th>Deal Status</th>
                  <th>Payment Status</th>
                  <!-- <th>Payment Date</th> -->
                  <th>Payment Method</th>
                  <th>Beneficiary Details</th>
                  <th>Date</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(transaction, index) in resultQuery" :key="index">
                  <td v-if="marketing_status == 'Team Leader'">
                    {{ transaction.empname }}
                  </td>
                  <td v-else>
                    {{ transaction.empname }}
                  </td>
                  <td>
                    <div v-if="transaction.id == editrow">
                      <input type="text" v-model="transaction.client" />
                    </div>
                    <div v-else>
                      {{ transaction.client }}
                    </div>
                  </td>
                  <td>
                    <div v-if="transaction.id == editrow">
                      <input
                        type="number"
                        min="0"
                        v-model="transaction.amount"
                        @input="validateAmount"
                      />
                    </div>
                    <div v-else>
                      {{ transaction.amount }}
                    </div>
                  </td>
                  <td>
                    <div v-if="transaction.id == editrow">
                      <select
                        class="addNewInput"
                        v-model="transaction.payment_status"
                        placeholder="Select"
                      >
                        <option :value="blood" disabled>Select One</option>
                        <option value="Pending">Pending</option>
                        <option value="Closed">Closed</option>
                        <option value="Failed">Failed</option>
                      </select>
                    </div>
                    <div v-else>
                      {{ transaction.payment_status }}
                    </div>
                  </td>
                  <td v-if="transaction.payment_status == 'Closed'">
                    <div v-if="transaction.id == editrow">
                      <select
                        class="addNewInput"
                        v-model="transaction.payment_completion_status"
                        placeholder="Select"
                      >
                        <option :value="blood" disabled>Select One</option>
                        <option value="Pending">Pending</option>
                        <option value="Paid">Paid</option>
                      </select>
                    </div>
                    <div v-else>
                      {{ transaction.payment_completion_status }}
                    </div>
                  </td>
                  
                  <td v-else>
                    <div v-if="transaction.id == editrow">
                      <select
                        class="addNewInput"
                        v-model="transaction.payment_completion_status"
                        placeholder="Select"
                      >
                        <option :value="blood" disabled>Select One</option>
                        <option value="Pending">Pending</option>
                        <option value="Paid">Paid</option>
                      </select>
                    </div>
                    <div v-else>
                      {{ transaction.payment_completion_status }}
                    </div>
                  </td>

                 

                  <!-- <td v-if="transaction.payment_completion_status == 'Paid' ">
                                                            <div v-if="transaction.id == editrow">
                                                                <input v-model="transaction.payment_date" type="date" placeholder="Enter your date" />
                                                                <span v-if="v3$.date.$error" class="field-error">
                                                                    {{ v3$.date.$errors[0].$message }}
                                                                </span>
                                                            </div>
                                                            <div v-else>
                                                                {{ transaction.payment_date }}
                                                            </div>  
                                                        </td>
                                                        <td v-else>
                                                            NA
                                                        </td> -->
                  <td v-if="transaction.payment_completion_status == 'Paid'">
                    <div v-if="transaction.id == editrow">
                      <select
                        class="addNewInput"
                        v-model="transaction.payment_method"
                        placeholder="Select"
                      >
                        <option :value="blood" disabled>Select One</option>
                        <option value="Banking">Bank Transfer</option>
                        <option value="Crypto">Crypto</option>
                        <option value="Paypal">Paypal</option>
                      </select>
                    </div>
                    <div v-else>
                      <div v-if="transaction.payment_method != null">
                        {{ transaction.payment_method }}
                      </div>
                      <div v-else>-</div>
                    </div>
                  </td>
                  <td v-else>NA</td>
                  <td>
                    <!-- <td v-if="transaction.payment_status == 'Closed'"> -->
                    <div v-if="transaction.id == editrow">
                      <select
                        class="addNewInput"
                        v-model="transaction.beneficiary"
                        placeholder="Select"
                      >
                        <option :value="blood" disabled>Select One</option>
                        <option value="Reddensoft">Reddensoft</option>
                        <option value="Lynkme">Lynkme 360</option>
                        <option value="Bigwave">Bigwave</option>
                      </select>
                    </div>
                    <div v-else>
                      <div v-if="transaction.beneficiary != null">
                        {{ transaction.beneficiary }}
                      </div>
                      <div v-else>-</div>
                    </div>
                    <!-- </td> -->
                  </td>
                  <td>{{ transaction.date }}</td>
                  <!-- <td>{{ transaction.invoice }}</td> -->
                  <td>
                    <div class="table_flex d-flex">
                      <button
                        v-if="transaction.id == editrow"
                        class="btn-lps"
                        @click="updatedeal(transaction)"
                        title="Update"
                      >
                        <img
                          src="../../assets/empdashboardimages/Update.png"
                          alt="edit-icon"
                        />
                      </button>
                      <button
                        v-else
                        class="btn-lps"
                        @click="editdeal(transaction.id)"
                        title="Edit"
                      >
                        <img
                          src="../../assets/empdashboardimages/lms-edit.png"
                          alt="edit-icon"
                        />
                      </button>
                      <button
                        class="btn-lps"
                        @click="
                          openiopopupBtn(
                            transaction.id,
                            transaction.payment_status
                          )
                        "
                        title="Details"
                      >
                        <img
                          src="../../assets/empdashboardimages/Details.png"
                          alt="details-icon"
                        />
                      </button>
                      <button
                        class="btn-lps"
                        @click="
                          exportToPDFInvoice(
                            transaction.io_status,
                            transaction.payment_status,
                            transaction.beneficiary,
                            transaction.id
                          )
                        "
                        title="Download IO"
                      >
                        <img
                          src="../../assets/empdashboardimages/lms-download.png"
                          alt="download-icon"
                        />
                      </button>
                      <button
                        class="btn-lps"
                        @click="
                          exportToPDFInvoice1(
                            transaction.io_status,
                            transaction.payment_status,
                            transaction.beneficiary,
                            transaction.id
                          )
                        "
                        title="Download Invoice"
                      >
                        <img
                          src="../../assets/empdashboardimages/lms_download.png"
                          alt="pdf-icon"
                        />
                      </button>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <!-- <tr>
                              <td>Abc Digital</td>
                              <td>test</td>
                              <td>$2000</td>
                              <td>Closed</td>
                              <td>Paid</td>
                              <td>Paypal</td>
                              <td>Reddensoft</td>
                              <td>22-3-2024</td>
                              <td>
                                <div class="table_btn">
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms-edit.png" alt="edit-icon" />
                                  </button>
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms-download.png" alt="download-icon" />
                                  </button>
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms_download.png" alt="pdf-icon" />
                                  </button>
                                </div>
                            </td>
                          </tr> -->
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    Total: <span>${{ overallDealsTotal }}</span>
                  </td>
                  <td></td>
                  <td v-if="table_filter_selected_value_payment == 'Paid'">
                    Paid Sales: <span>{{ overallDealsCount }}</span>
                  </td>
                  <td
                    v-else-if="table_filter_selected_value_payment == 'Pending'"
                  >
                    Pending Sales: <span>{{ overallDealsCount }}</span>
                  </td>
                  <td v-else>
                    All Sales: <span>{{ overallDealsCount }}</span>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    Received: <span>${{ closedDealsTotal }}</span>
                  </td>
                  <td></td>
                  <td>
                    Due: <span>${{ pendingDealsTotal }}</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <div class="modal_background" v-if="openiopopup == true">
              <!-- <div class="modal_background" v-if="openiopopup ==true && transaction.payment_status == 'Closed' "> -->
              <div class="modal_content">
                <div class="modal_box">
                  <div class="modal_heading">
                    <h2>Insertion Order</h2>
                    <button @click="closeiopopup()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path
                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="modal_scroll">
                    <div class="modal_name">
                      <h3>Advertiser Details</h3>
                    </div>
                    <div class="d-flex">
                      <div class="col modal_input">
                        <label>Company Name :</label>
                        <input
                          type="text"
                          placeholder="Enter company name"
                          v-model="io.company_name"
                        />
                      </div>
                      <div class="col modal_input">
                        <label>Address :</label>
                        <input
                          type="text"
                          placeholder="Enter company address"
                          v-model="io.company_address"
                        />
                      </div>
                    </div>
                    <div class="modal_name">
                      <h3>Contact person</h3>
                    </div>
                    <div class="d-flex flex-wrap">
                      <div class="col-4 modal_input">
                        <label>Name :</label>
                        <input
                          type="text"
                          placeholder="Enter name"
                          v-model="io.contact_person_name"
                        />
                      </div>
                      <div class="col-4 modal_input">
                        <label>Registration number :</label>
                        <input
                          type="text"
                          placeholder="Enter registration number"
                          v-model="io.contact_person_reg_no"
                        />
                      </div>
                      <div class="col-4 modal_input">
                        <label>Email :</label>
                        <input
                          type="text"
                          placeholder="Enter company email"
                          v-model="io.contact_person_email"
                        />
                      </div>
                    </div>
                    <div class="modal_name">
                      <h3>Campaign Details</h3>
                    </div>
                    <div class="modal_campaign">
                      <div class="row">
                        <div class="col details_label">
                          <label>Site / Application</label>
                          <input
                            type="text"
                            placeholder="Enter site"
                            v-model="io.web_app_name"
                          />
                        </div>
                        <div class="col details_label">
                          <label>Start Date</label>
                          <input
                            type="date"
                            placeholder="Enter start date"
                            v-model="io.start_date"
                          />
                        </div>
                        <div class="col details_label">
                          <label>End Date</label>
                          <input
                            type="date"
                            placeholder="Enter end date"
                            v-model="io.end_date"
                          />
                        </div>
                        <div class="col details_label">
                          <label>Placement</label>
                          <input
                            type="text"
                            placeholder="Enter placement"
                            v-model="io.placemnet"
                          />
                        </div>
                        <div class="col details_label">
                          <label>Total Budget</label>
                          <input
                            type="number"
                            placeholder="Enter total budget"
                            v-model="io.total_budget"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal_name">
                      <h3>Campaign Type</h3>
                    </div>
                    <div class="campaing_select">
                      <div class="col-6">
                        <div class="input_box">
                          <input
                            type="text"
                            placeholder="Enter category"
                            v-model="newCategory.name"
                          />
                          <button type="submit" @click="addName">Add</button>
                        </div>
                      </div>
                      <div
                        v-for="(category, index) in categories"
                        :key="index"
                        class="soloemail_details  col-6 edit_soloemail_details"
                      >
                        <div
                          class="modal_name modal_div d-flex justify-content-between align-items-center"
                        >
                          <input
                            v-if="
                              catNameedit == true &&
                              catNameToBeEdited == category.name
                            "
                            type="text"
                            placeholder="Enter Category Name"
                            v-model="catNameEdited"
                          />
                          <h3
                            v-if="
                              catNameedit == false ||
                              catNameToBeEdited != category.name
                            "
                          >
                            {{ category.name }}
                          </h3>

                          <div class="modal_btn">
                            <button
                              v-if="
                                catNameedit == false ||
                                catNameToBeEdited != category.name
                              "
                              @click="editName(category.name)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                width="16px"
                                height="16px"
                                fill="#212121"
                              >
                                <path
                                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                />
                              </svg>
                            </button>
                            <button
                              v-if="
                                catNameedit == true &&
                                catNameToBeEdited == category.name
                              "
                              @click="updateName(catNameEdited)"
                            >
                              <svg
                                version="1.1"
                                width="20px"
                                height="20px"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 32 32"
                                enable-background="new 0 0 32 32"
                                xml:space="preserve"
                              >
                                <path
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="3"
                                  stroke-miterlimit="10"
                                  d="M25.7,10.9C23.9,7.4,20.2,5,16,5
                                                                                        c-4.7,0-8.6,2.9-10.2,7"
                                />
                                <path
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="3"
                                  stroke-miterlimit="10"
                                  d="M6.2,21c1.8,3.5,5.5,6,9.8,6c4.7,0,8.6-2.9,10.2-7"
                                />
                                <polyline
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="3"
                                  stroke-miterlimit="10"
                                  points="26,5 26,11 20,11 "
                                />
                                <polyline
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="3"
                                  stroke-miterlimit="10"
                                  points="6,27 6,21 12,21 "
                                />
                              </svg>
                            </button>
                            <button @click="delName(category.name)">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                width="16px"
                                height="16px"
                                fill="#212121"
                              >
                                <path
                                  d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div class="modal_campaign modal_campaign2">
                          <div class="row">
                            <div class="col details_label">
                              <label>Model</label>
                              <input
                                type="text"
                                placeholder="Enter model"
                                v-model="category.model"
                              />
                            </div>
                            <div class="col details_label">
                              <label>Rate</label>
                              <input
                                type="text"
                                placeholder="Enter rate"
                                v-model="category.rate"
                              />
                            </div>
                            <div class="col details_label">
                              <label>Quantity</label>
                              <input
                                type="text"
                                placeholder="Enter quantity"
                                v-model="category.quantity"
                              />
                            </div>
                            <div class="col details_label">
                              <label>Total</label>
                              <input
                                type="text"
                                placeholder="Enter total"
                                v-model="category.total"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal_button">
                    <button @click="generateIO(io)">Generate</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane green_table fade"
          id="pills-Reports"
          role="tabpanel"
          aria-labelledby="pills-Reports-tab"
        >
          <div class="tab_heading">
            <div class="left">
              <h2>Latest Reports</h2>
            </div>

            <div class="right">
              <div class="select_months">
                <month-picker-input
                  v-model="selectedDate"
                  placeholder="Select Month Year"
                  @change="showDate"
                  :no-default="true"
                  :show-year="true"
                  :default-month="month_name"
                ></month-picker-input>
              </div>
              <div class="search">
                <button>
                  <img
                    src="../../assets/empdashboardimages/search-icon.png"
                    alt="search-icon"
                  />
                </button>
                <input
                  type="text"
                  v-model="searchReportQuery"
                  placeholder="Search by date"
                />
              </div>
            </div>
          </div>
          <div class="tab_table">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Mail Sent</th>
                  <th>Total Response</th>
                  <th>Positive Response</th>
                  <th>Leads Generated</th>
                  <th>Deals Closed</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for="(row, index) in reportData" :key="index"> -->
                <tr v-for="(row, index) in resultReportQuery" :key="index">
                  <td>
                    <span v-if="row.id != reporttablerowtobeedited">{{
                      row.date
                    }}</span>
                    <input
                      v-else
                      v-model="daily_report_details.date"
                      type="date"
                    />
                  </td>
                  <td>
                    <span v-if="row.id != reporttablerowtobeedited">{{
                      row.total_mail_sent
                    }}</span>
                    <input
                      v-else
                      v-model="daily_report_details.total_mail_sent"
                      type="number"
                      min="0"
                    />
                  </td>
                  <td>
                    <span v-if="row.id != reporttablerowtobeedited">{{
                      row.total_response
                    }}</span>
                    <input
                      v-else
                      v-model="daily_report_details.total_response"
                      type="number"
                      min="0"
                    />
                  </td>
                  <td>
                    <span v-if="row.id != reporttablerowtobeedited">{{
                      row.total_positive_response
                    }}</span>
                    <input
                      v-else
                      v-model="daily_report_details.total_positive_response"
                      type="number"
                      min="0"
                    />
                  </td>

                  <td>
                    <span v-if="row.id != reporttablerowtobeedited">{{
                      row.total_lead
                    }}</span>
                    <input
                      v-else
                      v-model="daily_report_details.total_lead"
                      type="number"
                      min="0"
                    />
                  </td>
                  <td>
                    <span v-if="row.id != reporttablerowtobeedited">{{
                      row.lead_closed
                    }}</span>
                    <input
                      v-else
                      v-model="daily_report_details.lead_closed"
                      type="number"
                      min="0"
                    />
                  </td>
                  <td>
                    <div class="table_flex d-flex">
                      <!-- <button class="btn-lps" @click="toggleEdit(row)">
                                                                        {{ row.id == reporttablerowtobeedited ? 'Update' : 'Edit' }}
                                                                    </button> -->
                      <button
                        class="btn-lps"
                        @click="updateReportTable(row)"
                        v-if="row.id == reporttablerowtobeedited"
                      >
                        <img
                          src="../../assets/empdashboardimages/Update.png"
                          alt="edit-icon"
                        />
                      </button>
                      <button class="btn-lps" @click="toggleEdit(row)" v-else>
                        <img
                          src="../../assets/empdashboardimages/lms-edit.png"
                          alt="edit-icon"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                              <td>26-3-2024</td>
                              <td>Alan Duke</td>
                              <td>2000</td>
                              <td>500</td>
                              <td>400</td>
                              <td>350</td>
                              <td>
                                <div class="table_btn">
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms-edit.png" alt="edit-icon" />
                                  </button>
                                </div>
                              </td>
                            </tr> -->
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    Total Response: <span>{{ Total_response_count }}</span>
                  </td>
                  <td></td>
                  <td>
                    Positive Response:
                    <span>{{ Total_positive_response_count }}</span>
                  </td>
                  <td></td>
                  <td>
                    Leads Generated: <span>{{ Total_leads_generated }}</span>
                  </td>
                  <td>
                    Deal Closed: <span>{{ Total_leads_closed }}</span>
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <!-- <div class="tab-pane yellow_table fade" id="pills-Leads" role="tabpanel" aria-labelledby="pills-Leads-tab">
                    <div class="tab_heading">
                      <div class="left">
                          <h2>Latest Reports</h2>
                      </div>
                      
                      <div class="right">
                          <div class="select_months">
                            <month-picker-input placeholder="Select Month Year" @change="showDate" :no-default="true" :show-year="true"></month-picker-input>
                          </div>
                          <div class="search">
                            <button>
                                <img src="../../assets/empdashboardimages/search-icon.png" alt="search-icon">
                            </button>
                            <input type="text" placeholder="Search by member name">
                          </div>
                      </div>
                    </div>
                    <div class="tab_table">
                        <table>
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Amount</th>
                              <th>Deal Status</th>
                              <th>Payment Status</th>
                              <th>Payment Method</th>
                              <th>Beneficiary Details</th>
                              <th>Date</th>
                              <th>Action</th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Abc Digital</td>
                              <td>$2000</td>
                              <td>Closed</td>
                              <td>Paid</td>
                              <td>Paypal</td>
                              <td>Reddensoft</td>
                              <td>22-3-2024</td>
                              <td>
                                <div class="table_btn">
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms-edit.png" alt="edit-icon" />
                                  </button>
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms-download.png" alt="download-icon" />
                                  </button>
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms_download.png" alt="pdf-icon" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total: <span>$14000</span></td>
                              <td></td>
                              <td>Sales: <span>7</span></td>
                              <td></td>
                              <td>Received: <span>$14000</span></td>
                              <td></td>
                              <td>Due: <span>0</span></td>
                              <td></td>
                            </tr>
                          </tfoot>
                      </table>
                    </div>
                </div> -->
        <!-- <div class="tab-pane blue_table fade" id="pills-Contracts" role="tabpanel" aria-labelledby="pills-Contracts-tab">
                    <div class="tab_heading">
                      <div class="left">
                          <h2>Scheduled Leads</h2>
                      </div>
                      
                      <div class="right">
                          <div class="select_months">
                            <month-picker-input placeholder="Select Month Year" @change="showDate" :no-default="true" :show-year="true"></month-picker-input>
                          </div>
                          <div class="search">
                            <button>
                                <img src="../../assets/empdashboardimages/search-icon.png" alt="search-icon">
                            </button>
                            <input type="text" placeholder="Search by member name">
                          </div>
                      </div>
                    </div>
                    <div class="tab_table">
                        <table>
                          <thead>
                            <tr>
                              <th>Member Name</th>
                              <th>Client Name</th>
                              <th>Amount</th>
                              <th>Deal Status</th>
                              <th>Payment Status</th>
                              <th>Payment Method</th>
                              <th>Beneficiary Details</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Alan Cole</td>
                              <td>Abc Digital</td>
                              <td>$2000</td>
                              <td>Closed</td>
                              <td>Paid</td>
                              <td>Paypal</td>
                              <td>Reddensoft</td>
                              <td>22-3-2024</td>
                              <td>
                                <div class="table_btn">
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms-edit.png" alt="edit-icon" />
                                  </button>
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms-download.png" alt="download-icon" />
                                  </button>
                                  <button>
                                    <img src="../../assets/empdashboardimages/lms_download.png" alt="pdf-icon" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total: <span>$14000</span></td>
                              <td></td>
                              <td>Sales: <span>7</span></td>
                              <td></td>
                              <td></td>
                              <td>Received: <span>$14000</span></td>
                              <td></td>
                              <td></td>
                              <td>Due: <span>0</span></td>
                            </tr>
                          </tfoot>
                      </table>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { MonthPickerInput } from "vue-month-picker";
import { createLocal, createSession, createStorage } from "the-storages";
import { BASE_URL } from "../../config";
import axios from "axios";
import { reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { required, email } from "@vuelidate/validators";
const mirror = createLocal();
const storage = mirror._prx;
import { ref } from "vue";
import html2pdf from "html2pdf.js";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "DashboardTeamLead",
  components: {
    apexchart: VueApexCharts,
    MonthPickerInput,
  },
  props: {
    dealAddProp: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const newTransaction = reactive({
      clientName: "",
      amount: "",
      paymentStatus: "",
      date: "",
      invoice: "",
      beneficiary: "",
    });

    const io = reactive({
      company_name: "",
      company_address: "",
      contact_person_name: "",
      contact_person_reg_no: "",
      contact_person_email: "",
      web_app_name: "",
      start_date: "",
      end_date: "",
      placemnet: "",
      total_budget: "",
      solo_email_model: "",
      solo_email_rate: "",
      solo_email_quantity: "",
      solo_email_total: "",
      email_template_model: "",
      email_template_rate: "",
      email_template_quantity: "",
      email_template_total: "",
    });

    const daily_report = reactive({
      date: "",
      total_mail_sent: 0,
      total_positive_response: 0,
      total_response: 0,
      total_lead: 0,
      lead_closed: 0,
    });

    const rules3 = computed(() => {
      return {
        clientName: { required },
        amount: { required },
        paymentStatus: { required },
        date: { required },
        beneficiary: { required },
        //     orgname: { required },
        //     email: { required, email },
        //     phone: { required, minLength: minLength(10), maxLength: maxLength(12) },
        //     country:{ required },
        // //   timezone:{ required },
        //     states:{ required },
        // //   dateformat:{ required },
        //     password:{ required, minLength: minLength(8)},
        //     confirmpassword:{ required, sameAsPassword: sameAs(state3.password),},
      };
    });
    const v3$ = useValidate(rules3, newTransaction);

    const selectedData = ref([]);

    function handleItemClick(title) {
      // console.log("chgrdtr");
      if (!selectedData.value.includes(title)) {
        selectedData.value.push(title);
      } else {
        selectedData.value = selectedData.value.filter(
          (item) => item !== title
        );
      }
    }

    const menuItems = [{ title: "Solo Email" }, { title: "Email Template" }];

    const daily_report_details = reactive({
      date: "",
      total_mail_sent: "",
      total_positive_response: "",
      total_response: "",
      total_lead: "",
      lead_closed: "",
    });

    return {
      selectedData,
      handleItemClick,
      menuItems,
      v3$,
      newTransaction,
      io,
      daily_report,
      daily_report_details,
    };
  },
  data: function () {
    return {
      this_month_selected: "",
      this_month_selected_PersonalDeal: "",
      this_month_selected_TeamDeals: "",
      this_month_selected_PersonalPayment: "",
      this_month_selected_TeamPayment: "",
      table_filter_selected_value_deal: "",
      table_filter_selected_value_payment: "",
      menuPersonalDealItems: [
        {
          title: "This Month",
        },
        {
          title: "This Week",
        },
        {
          title: "This Year",
        },
      ],
      menuTeamDealsItems: [
        {
          title: "This Month",
        },
        {
          title: "This Week",
        },
        {
          title: "This Year",
        },
      ],
      menuPersonalPaymentItems: [
        {
          title: "This Month",
        },
        {
          title: "This Week",
        },
        {
          title: "This Year",
        },
      ],
      menuTeamPaymentItems: [
        {
          title: "This Month",
        },
        {
          title: "This Week",
        },
        {
          title: "This Year",
        },
      ],
      menuTableFilterItems: [
        {
          title: "Latest Deals",
        },
        {
          title: "This Month",
        },
        {
          title: "Last 3 Months",
        },
        {
          title: "This Year",
        },
      ],
      menuTableFilterItemsDeal: [
        {
          title: "All Deals",
        },
        {
          title: "Pending",
        },
        {
          title: "Closed",
        },
        {
          title: "Failed",
        },
      ],
      menuTableFilterItemsPayment: [
        {
          title: "All Payments",
        },
        {
          title: "Pending",
        },
        {
          title: "Paid",
        },
      ],
      dealdetails: [],
      editrow: "",
      btnName: "Edit",
      marketing_status: "",
      month_name: "",
      picked_year: "",
      month_proper_name: "",
      teamTarget: "",
      personalTarget: "",
      closedPersonalDeal: "",
      pendingPersonalDeal: "",
      closedPersonalDealCount: "",
      closedTeamDealCount: "",
      closedTeamDealDetails: "",
      personCompletionPercentage: "",
      teamCompletionPercentage: "",
      empname: "",
      openiopopup: false,
      reportData: "",
      reporttablerowtobeedited: "",
      newCategory: {
        name: "",
        model: "",
        rate: "",
        quantity: "",
        total: "",
      },
      PersonalPaymentRecievedTotal: 0,
      PersonalPaymentDueTotal: "",
      categories: [],
      invoiceid: "",
      campaign_categories: [],
      // campaign_data:[]
      pdfdata: "",
      paymentCompletionCount: "",
      paymentCompletionTotal: "",
      dealpaidfor: "",
      monthValue: "",
      lastQuarterOverdue: "",
      submittingDailyReport: false,
      catNameedit: false,
      catNameEdited: "",
      catNameToBeEdited: "",
      teamPaymentCompletionCount: "",
      teamDealpaidfor: "",
      teamPaymentCompletionTotal: "",
      teamPaymentTotal: "",
      series: [0],
      seriesPersonal: [0],
      seriesTeam: [0],
      // personalChartOptions: {
      //     chart: {
      //         height: 250,
      //         type: 'radialBar',
      //         toolbar: {
      //             show: false,
      //         }
      //     },
      //     plotOptions: {
      //         radialBar: {
      //             startAngle: -135,
      //             endAngle: 225,
      //             hollow: {
      //                 margin: 0,
      //                 size: '50%',
      //                 background: '#D4E3FB',
      //                 image: undefined,
      //                 imageOffsetX: 0,
      //                 imageOffsetY: 0,
      //                 position: 'front',
      //                 // dropShadow: {
      //                 //     enabled: true,
      //                 //     top: 3,
      //                 //     left: 0,
      //                 //     blur: 4,
      //                 //     opacity: 0.24
      //                 // }
      //             },
      //             track: {
      //                 background: '#83AEF5',
      //                 strokeWidth: '100%',
      //                 margin: 0,
      //                 // dropShadow: {
      //                 //     enabled: true,
      //                 //     top: -3,
      //                 //     left: 0,
      //                 //     blur: 4,
      //                 //     opacity: 0.35
      //                 // }
      //             },
      //             dataLabels: {
      //                 show: true,
      //                 value: {
      //                     formatter: function(val) {
      //                         return parseInt(val);
      //                     },
      //                     color: '#355893',
      //                     fontSize: '24px',
      //                     show: true,
      //                 },
      //                 name: {
      //                     offsetY: 0,
      //                     show: true,
      //                     color: '#413C3C',
      //                     fontSize: '12px'
      //                 },
      //             }
      //         }
      //     },
      //     stroke: {
      //         lineCap: '',
      //     },
      //     labels: ['Out of ' + this.personalTarget],
      // },
      // teamChartOptions: {
      //     chart: {
      //         height: 250,
      //         type: 'radialBar',
      //         toolbar: {
      //             show: false,
      //         }
      //     },
      //     plotOptions: {
      //         radialBar: {
      //             startAngle: -135,
      //             endAngle: 225,
      //             hollow: {
      //                 margin: 0,
      //                 size: '50%',
      //                 background: '#D4E3FB',
      //                 image: undefined,
      //                 imageOffsetX: 0,
      //                 imageOffsetY: 0,
      //                 position: 'front',
      //                 // dropShadow: {
      //                 //     enabled: true,
      //                 //     top: 3,
      //                 //     left: 0,
      //                 //     blur: 4,
      //                 //     opacity: 0.24
      //                 // }
      //             },
      //             track: {
      //                 background: '#83AEF5',
      //                 strokeWidth: '100%',
      //                 margin: 0,
      //                 // dropShadow: {
      //                 //     enabled: true,
      //                 //     top: -3,
      //                 //     left: 0,
      //                 //     blur: 4,
      //                 //     opacity: 0.35
      //                 // }
      //             },
      //             dataLabels: {
      //                 show: true,
      //                 value: {
      //                     formatter: function(val) {
      //                         return parseInt(val);
      //                     },
      //                     color: '#355893',
      //                     fontSize: '24px',
      //                     show: true,
      //                 },
      //                 name: {
      //                     offsetY: 0,
      //                     show: true,
      //                     color: '#413C3C',
      //                     fontSize: '12px'
      //                 },
      //             }
      //         }
      //     },
      //     stroke: {
      //         lineCap: '',
      //     },
      //     labels: ['Out of '],
      // },
      searchQuery: null,
      searchReportQuery: null,
      table_filter_selected_value: "",
      selectedDate: null,
      loading: false,
      ID:''
    };
  },
  async created(event) {
    this.marketing_status = mirror.marketing_status;
    this.empname = mirror.USERNAME;
    const date = new Date();
    this.picked_year = date.getFullYear();
    this.month_name = date.getMonth() + 1;
    this.ID = mirror.ID;

    const month_name_array = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month_proper_name = month_name_array[date.getMonth()];

    // Create a new object without reactivity
    // this.selectedDate = {
    //     from: new Date(date.getFullYear(), date.getMonth(), 1),
    //     to: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    //     month: this.month_proper_name,
    //     monthIndex: this.month_name,
    //     year: this.picked_year,
    //     rangeFrom: null,
    //     rangeFromMonth: null,
    //     rangeTo: null,
    //     rangeToMonth: null,
    // };

    // console.log("this.selectedDate", this.selectedDate);
    // this.showDate(this.selectedDate)
    this.showdealdetails();
    this.showdailyreport();
  },

  computed: {
    closedDealsTotal() {
      let total = 0;
      // Iterate through dealdetails and sum the amount where payment_status is "Closed"
      this.dealdetails.forEach((transaction) => {
        if (transaction.payment_completion_status === "Paid") {
          total += parseInt(transaction.amount);
        }
      });
      return total; // You can format the total as needed
    },
    pendingDealsTotal() {
      let total = 0;
      // Iterate through dealdetails and sum the amount where payment_status is "Closed"
      this.dealdetails.forEach((transaction) => {
        if (
          transaction.payment_completion_status === "Pending" &&
          transaction.payment_status == "Closed"
        ) {
          total += parseInt(transaction.amount);
        }
      });
      return total; // You can format the total as needed
    },
    overallDealsTotal() {
      let total = 0;
      // console.log("reportObj", this.dealdetails);

      if (Array.isArray(this.dealdetails)) {
        console.log("myVariable is an array 1");
      } else {
        console.log("myVariable is not an array");
      }
      // Iterate through dealdetails and sum the amount where payment_status is "Closed"
      this.dealdetails.forEach((transaction) => {
        // console.log('transaction',transaction);
        if (transaction.payment_status == "Closed") {
          // console.log('transaction transaction transaction transaction',transaction);

          total += parseInt(transaction.amount);
        }
      });
      return total; // You can format the total as needed
    },
    overallDealsCount() {
      let count = 0;

      // Iterate through dealdetails and count the deals where payment_status is "Pending" or "Closed"
      this.dealdetails.forEach((transaction) => {
        if (
          transaction.payment_status === "Pending" ||
          transaction.payment_status === "Closed"
        ) {
          count++;
        }
      });

      return count;
    },
    Total_response_count() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.total_response);
      }, 0); // Starting with an initial value of 0
    },

    Total_positive_response_count() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.total_positive_response);
      }, 0); // Starting with an initial value of 0
    },
    Total_leads_generated() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.total_lead);
      }, 0); // Starting with an initial value of 0
    },
    Total_leads_closed() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.lead_closed);
      }, 0); // Starting with an initial value of 0
    },
    currentWeek() {
      const currentDate = new Date();
      const daysPassed = currentDate.getDate();
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      const weeksInMonth = Math.ceil(daysInMonth / 7);

      // Calculate the week number in the context of the entire month
      let currentWeekOfMonth = Math.ceil(daysPassed / 7);
      if (currentWeekOfMonth > weeksInMonth) {
        currentWeekOfMonth = weeksInMonth; // Cap at the maximum number of weeks in the month
      }
      return currentWeekOfMonth;
    },

    daysLeft() {
      const currentDate = new Date();
      const daysPassed = currentDate.getDate();
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();

      // Calculate days left in the month
      const daysLeft = daysInMonth - daysPassed;

      return daysLeft + 1;
    },
    message() {
      const progress = this.PersonalPaymentRecievedTotal;
      console.log("progress", progress);
      const targetSet = this.personalTarget;
      console.log("targetSet", targetSet);
      const weeklyTarget = targetSet / 4;

      if (this.currentWeek === 1) {
        if (progress >= weeklyTarget)
          return "Off to a great start! You're well ahead of schedule.";
        else if (progress >= weeklyTarget * 0.75)
          return "You're making good progress. Keep it up!";
        else if (progress < weeklyTarget * 0.75)
          return "You're slightly behind. Let's pick up the pace.";
        else {
          return "You've surpassed your target. Great Job! lets aim even higher now!";
        }
      } else if (this.currentWeek === 2) {
        if (progress >= weeklyTarget * 2)
          return "Excellent work! You're halfway there already.";
        else if (progress >= weeklyTarget * 1.5)
          return "You're on track to meet your target. Keep pushing!";
        else if (progress < weeklyTarget * 1.5)
          return "You're falling behind a bit. Let's aim to catch up this week.";
        else {
          return "You've surpassed your target. Great Job! lets aim even higher now!";
        }
      } else if (this.currentWeek === 3) {
        if (progress >= weeklyTarget * 3)
          return "You're almost there! Just one more week to go.";
        else if (progress >= weeklyTarget * 2.25)
          return "You're making good progress, but there's still room for improvement.";
        else if (progress < weeklyTarget * 2.25)
          return "You're cutting it close. Let's focus and finish strong.";
        else {
          return "You've surpassed your target. Great Job! lets aim even higher now!";
        }
      } else if (this.currentWeek === 4) {
        if (progress >= targetSet)
          return "You've met your monthly target. Well done! lets keep pushing for more";
        else if (progress >= weeklyTarget * 3)
          return "You're on the verge of success. Keep pushing until the end.";
        else if (progress < weeklyTarget * 3)
          return "You're almost there, but not quite. Let's give it our all in the final stretch.";
      } else if (this.currentWeek === 5) {
        if (progress >= targetSet)
          return "You've surpassed your target. Great Job! lets aim even higher now!";
        else if (progress >= weeklyTarget * 3.75)
          return "You're very close to reaching your monthly target. Keep pushing!";
        else return "You need to hurry up, otherwise you will miss the goal.";
      } else {
        return "Target completed!";
      }
    },
    personalChartOptions() {
      return {
        chart: {
          height: 250,
          type: "radialBar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "50%",
              background: "#D4E3FB",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
            },
            track: {
              background: "#83AEF5",
              strokeWidth: "100%",
              margin: 0,
            },
            dataLabels: {
              show: true,
              value: {
                formatter: function (val) {
                  return parseInt(val)+ '%';
                },
                color: "#355893",
                fontSize: "24px",
                show: true,
              },
              name: {
                offsetY: 0,
                show: true,
                color: "#413C3C",
                fontSize: "12px",
              },
            },
          },
        },
        stroke: {
          lineCap: "",
        },
        series: [this.personCompletionPercentage],
        labels: ["Out of $" + this.personalTarget], // Access personalTarget from the component's data
      };
    },
    teamChartOptions() {
      return {
        chart: {
          height: 250,
          type: "radialBar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "50%",
              background: "#D4E3FB",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
            },
            track: {
              background: "#83AEF5",
              strokeWidth: "100%",
              margin: 0,
            },
            dataLabels: {
              show: true,
              value: {
                formatter: function (val) {
                  return parseInt(val)+ "%";
                },
                color: "#355893",
                fontSize: "24px",
                show: true,
              },
              name: {
                offsetY: 0,
                show: true,
                color: "#413C3C",
                fontSize: "12px",
              },
            },
          },
        },
        stroke: {
          lineCap: "",
        },
        series: [this.teamCompletionPercentage],
        labels: ["Out of $" + this.teamTarget], // Access personalTarget from the component's data
      };
    },
    // onChartLoaded() {
    //   this.loading = true; // Set loading to false once the charts are loaded
    // },
    resultQuery() {
      if (this.searchQuery) {
        return this.dealdetails.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((w) =>
              item.empname
                .concat(item.client)
                .concat(item.empid)
                .concat(item.payment_status)
                .concat(item.payment_completion_status)
                .toLowerCase()
                .includes(w)
            );
          //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
        });
      } else {
        //  console.log("details");
        return this.dealdetails;
      }
    },
    resultReportQuery() {
      if (this.searchReportQuery) {
        return this.reportData.filter((item) => {
          return this.searchReportQuery
            .toLowerCase()
            .split(" ")
            .every((w) => item.date.toLowerCase().includes(w));
          //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
        });
      } else {
        //  console.log("details");
        return this.reportData;
      }
    },
  },
  methods: {
    validateAmount(event) {
      // Ensure that the value is non-negative
      const amount = parseFloat(event.target.value);
      if (!isNaN(amount) && amount < 0) {
        event.target.value = 0; // Set value to 0 if negative
        // this.transaction.amount = 0; // Update data property
      }
    },
    async handleTableFilterItemClickPayments(value) {
      this.table_filter_selected_value_payment = value;

      await axios
        .post(BASE_URL + "/api/dealdetailstablefilter", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: this.table_filter_selected_value,
          deal_status_value: this.table_filter_selected_value_deal,
          payment_status_value: this.table_filter_selected_value_payment,
        })
        .then((response) => {
          this.dealdetails = response.data.dealdetails;
        });
    },

    async handleTableFilterItemClickDeals(value) {
      this.table_filter_selected_value_deal = value;

      await axios
        .post(BASE_URL + "/api/dealdetailstablefilter", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: this.table_filter_selected_value,
          deal_status_value: this.table_filter_selected_value_deal,
          payment_status_value: this.table_filter_selected_value_payment,
        })
        .then((response) => {
          this.dealdetails = response.data.dealdetails;
        });
    },
    async handleTableFilterItemClick(value) {
      this.table_filter_selected_value = value;

      await axios
        .post(BASE_URL + "/api/dealdetailstablefilter", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: this.table_filter_selected_value,
          deal_status_value: this.table_filter_selected_value_deal,
          payment_status_value: this.table_filter_selected_value_payment,
        })
        .then((response) => {
          this.dealdetails = response.data.dealdetails;
        });
    },
    async handlePersonalDealItemClick(value) {
      this.this_month_selected_PersonalDeal = value;
      await axios
        .post(BASE_URL + "/api/personaldealshowdealdetails", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: value,
        })
        .then((response) => {
          this.closedPersonalDealCount = response.data.closedPersonalDealCount;
          this.closedPersonalDeal = response.data.closedPersonalDeal;
          this.PersonalPaymentRecievedTotal =
            response.data.PersonalPaymentRecievedTotal;
          this.PersonalPaymentDueTotal = response.data.PersonalPaymentDueTotal;
        });
    },
    async handleTeamDealsItemClick(value) {
      this.this_month_selected_TeamDeals = value;
      await axios
        .post(BASE_URL + "/api/teamdealshowdealdetails", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: value,
        })
        .then((response) => {
          this.closedTeamDealCount = response.data.closedTeamDealCount;
          this.teamPaymentTotal = response.data.teamPaymentTotal;
          this.closedTeamDealTotal = response.data.closedTeamDealTotal;
        });
    },
    async handlePersonalPaymentItemClick(value) {
      this.this_month_selected_PersonalPayment = value;
      await axios
        .post(BASE_URL + "/api/personalpaymentshowdealdetails", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: value,
        })
        .then((response) => {
          this.paymentCompletionCount = response.data.paymentCompletionCount;
          this.dealpaidfor = response.data.dealpaidfor;
          this.paymentCompletionTotal = response.data.paymentCompletionTotal;
        });
    },
    async handleTeamPaymentItemClick(value) {
      this.this_month_selected_TeamPayment = value;
      await axios
        .post(BASE_URL + "/api/teampaymentshowdealdetails", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: value,
        })
        .then((response) => {
          this.teamPaymentCompletionCount =
            response.data.teamPaymentCompletionCount;
          this.teamDealpaidfor = response.data.teamDealpaidfor;
          this.teamPaymentCompletionTotal =
            response.data.teamPaymentCompletionTotal;
        });
    },
    async updateboxes() {
      await axios
        .post(BASE_URL + "/api/dealdetailstablefilter", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
          value: this.table_filter_selected_value,
          deal_status_value: this.table_filter_selected_value_deal,
          payment_status_value: this.table_filter_selected_value_payment,
        })
        .then((response) => {
          this.dealdetails = response.data.dealdetails;
        });
    },
    async showDate(date) {
      this.month_name = date.monthIndex;
      this.month_proper_name = date.month;
      this.picked_year = date.year;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      console.log("datedatedate", date);

      console.log("this.picked_year", this.picked_year);
      console.log("this.month_name", this.month_name);
      // if (this.picked_year != currentYear) {
      this.showdailyreport();
      // }
      // console.log("date Y",this.state3.date.year);
      setTimeout(() => {
        this.seen = false;
      }, 500);
    },
    async showdealdetails() {
      await axios
        .post(BASE_URL + "/api/showdealdetails", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
        })
        .then((response) => {
          this.dealdetails = response.data.dealdetails;
          this.teamPaymentTotal = response.data.teamPaymentTotal;
          this.teamTarget = response.data.teamTarget;
          this.closedPersonalDeal = response.data.closedPersonalDeal;
          this.pendingPersonalDeal = response.data.pendingPersonalDeal;
          this.closedPersonalDealCount = response.data.closedPersonalDealCount;
          this.closedTeamDealCount = response.data.closedTeamDealCount;
          this.closedTeamDealDetails = response.data.closedTeamDealDetails;
          this.closedTeamDealTotal = response.data.closedTeamDealTotal;
          // console.log("personCompletionPercentage",this.paymentCompletionTotal);
          this.teamCompletionPercentage = parseInt(
            (this.closedTeamDealTotal * 100) / this.teamTarget
          );
          this.PersonalPaymentRecievedTotal =
            response.data.PersonalPaymentRecievedTotal;
          this.personalTarget = response.data.personalTarget;
          this.paymentCompletionCount = response.data.paymentCompletionCount;
          this.paymentCompletionTotal = response.data.paymentCompletionTotal;
          this.dealpaidfor = response.data.dealpaidfor;
          this.monthValue = response.data.monthValue;
          this.lastQuarterOverdue = response.data.lastQuarterOverdue;
          this.PersonalPaymentDueTotal = response.data.PersonalPaymentDueTotal;
          this.personCompletionPercentage = parseInt(
            (this.PersonalPaymentRecievedTotal * 100) / this.personalTarget
          );
          // this.personCompletionPercentage = this.personCompletionPercentage.concat("%")
          this.teamPaymentCompletionCount =
            response.data.teamPaymentCompletionCount;
          this.teamDealpaidfor = response.data.teamDealpaidfor;
          this.teamPaymentCompletionTotal =
            response.data.teamPaymentCompletionTotal;
          this.loading = true;
          console.log("this.personalTarget", this.personalTarget);

          console.log(
            "this.PersonalPaymentRecievedTotal",
            this.PersonalPaymentRecievedTotal
          );
        });
    },
    editdeal(id) {
      this.btnName = "Update";
      this.editrow = id;
    },

    async updatedeal(details) {
      console.log("details", details);
      if (details.payment_completion_status == "Paid") {
        if (details.payment_status != "Closed") {
          this.tostMessage(
            "warning",
            "Payment Status can not be paid for an unclosed deal!"
          );
          this.showdealdetails();
        } else if (details.payment_method == null) {
          details.payment_completion_status == "Pending";
          this.tostMessage("warning", "Please add payment method first!");
          this.showdealdetails();
        } else if (details.beneficiary == null) {
          details.payment_completion_status == "Pending";
          this.tostMessage("warning", "Please add beenficiary details first!");
          this.showdealdetails();
        }
        else if (details.amount < 0) {
          this.tostMessage("warning", "Amount can not be negative!");
          this.showdealdetails();
        }
        else {
          this.editrow = "";

          await axios
            .post(BASE_URL + "/api/updatedealdetails", {
              id: mirror.ID,
              client: details.client,
              amount: details.amount,
              status: details.payment_status,
              deal_id: details.id,
              payment_status: details.payment_completion_status,
              payment_method: details.payment_method,
              month: details.month,
              year: details.year,
              payment_date: details.payment_date,
              beneficiary: details.beneficiary,
            })
            .then((response) => {
              //   this.showdealdetails();
              // this.handleTableFilterItemClick(this.table_filter_selected_value);
              this.showdealdetails();

              this.this_month_selected_PersonalDeal = "";
              this.this_month_selected_TeamDeals = "";
              this.this_month_selected_PersonalPayment = "";
              this.this_month_selected_TeamPayment = "";
            });
          // this.updateboxes()
        }
      } else {
        this.editrow = "";

        await axios
          .post(BASE_URL + "/api/updatedealdetails", {
            id: mirror.ID,
            client: details.client,
            amount: details.amount,
            status: details.payment_status,
            deal_id: details.id,
            payment_status: details.payment_completion_status,
            payment_method: details.payment_method,
            month: details.month,
            year: details.year,
            payment_date: details.payment_date,
            beneficiary: details.beneficiary,
          })
          .then((response) => {
            // this.showdealdetails();
            // this.handleTableFilterItemClick(this.table_filter_selected_value);
            this.showdealdetails();

            this.this_month_selected_PersonalDeal = "";
            this.this_month_selected_TeamDeals = "";
            this.this_month_selected_PersonalPayment = "";
            this.this_month_selected_TeamPayment = "";
          });
      }
    },

    async openiopopupBtn(id, deal_status) {
      if (deal_status == "Closed") {
        this.openiopopup = true;
        this.invoiceid = id;
        await axios
          .post(BASE_URL + "/api/getinvoicedataEmp", {
            id: id,
          })
          .then((response) => {
            console.log("ressssss", response.data);
            this.io.company_name = response.data.iodetails.company_name;
            this.io.company_address = response.data.iodetails.company_address;
            this.io.contact_person_name =
              response.data.iodetails.contact_person_name;
            this.io.contact_person_reg_no =
              response.data.iodetails.contact_person_reg_no;
            this.io.contact_person_email =
              response.data.iodetails.contact_person_email;
            this.io.web_app_name = response.data.iodetails.web_app_name;
            this.io.start_date = response.data.iodetails.start_date;
            this.io.end_date = response.data.iodetails.end_date;
            this.io.placemnet = response.data.iodetails.placemnet;
            this.io.total_budget = response.data.iodetails.total_budget;
            this.campaign_categories = response.data.iocampaigndetails;
            this.categories = response.data.iocampaigndetails;
          });

        console.log("cat", this.campaign_categories);
      } else {
        this.openiopopup = false;
        this.tostMessage(
          "warning",
          "This deal is not close yet. Close the deal first before updating details."
        );
      }
    },

    async generateIO(io) {
      //   console.log("id", id);
      console.log("io", io);
      console.log("newCategory", this.newCategory);
      console.log("categories", this.categories);
      if (this.categories != null) {
        await axios
          .post(BASE_URL + "/api/saveioDetails", {
            io: io,
            id: this.invoiceid,
            category: this.categories,
          })
          .then((response) => {
            this.io.company_name = "";
            this.io.company_address = "";
            this.io.contact_person_name = "";
            this.io.contact_person_reg_no = "";
            this.io.contact_person_email = "";
            this.io.web_app_name = "";
            this.io.start_date = "";
            this.io.end_date = "";
            this.io.placemnet = "";
            this.io.total_budget = "";
            this.io.solo_email_model = "";
            this.io.solo_email_rate = "";
            this.io.solo_email_quantity = "";
            this.io.solo_email_total = "";
            this.io.email_template_model = "";
            this.io.email_template_rate = "";
            this.io.email_template_quantity = "";
            this.io.email_template_total = "";
            this.catNameToBeEdited = "";
            this.catNameEdited = "";
            this.closeiopopup();
            this.showdealdetails();
          });
        this.tostMessage("success", "IO generated successfully");
      } else {
        this.tostMessage("warning", "Category deatils can not be blank!");
      }
    },
    closeiopopup() {
      this.openiopopup = false;
    },
    addName() {
      if (!this.categories) {
        this.categories = []; // Initialize it as an empty array if it's null
      }

      const categoryName = this.newCategory.name.trim();

      if (categoryName !== "") {
        // Check if a category with the same name already exists
        const nameExists = this.categories.some(
          (category) => category.name === categoryName
        );

        if (!nameExists) {
          this.categories.push({ ...this.newCategory });
          this.newCategory = {
            name: "",
            model: "",
            rate: "",
            quantity: "",
            total: "",
          };
        } else {
          // Handle case where category name already exists
          console.log("Category with this name already exists.");
          this.tostMessage(
            "warning",
            "Category with this name already exists."
          );

          // You can display a message to the user or perform any other action
        }
      }
    },

    tostMessage(status, message) {
      console.log("status", status);
      createToast(
        {
          // title: title,
          description: message,
        },
        {
          type: status,
          timeout: 2500,
          position: "bottom-right",
          showIcon: "true",
        }
      );
    },

    async exportToPDFInvoice(io, status, beneficiary, id) {
      console.log("beneficiary", beneficiary);
      if (beneficiary == null) {
        this.tostMessage("warning", "Please add benificiary details first");
      } else if (beneficiary == "Reddensoft") {
        this.tostMessage("warning", "IO for Reddensoft not available");
      } else if (io == "1") {
        this.tostMessage("info", "Preparing download...");
        const toDataUrl = async function (url, callback) {
          //Convert to base64
          return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
              var reader = new FileReader();
              reader.onloadend = function () {
                resolve(reader.result);
              };
              reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = () => {
              reject({
                status: this.status,
                statusText: xhr.statusText,
              });
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
          });
        };

        // var url =
        //   "https://works-reddensoft.com/lms/admin/public/assets/signature.png";

        var url_bwd_head_logo = BASE_URL + "/public/assets/bwd-head-logo.svg";
        let bwd_head_logo = await toDataUrl(url_bwd_head_logo);

        var url_bwd_head_round = BASE_URL + "/public/assets/bwd-head-round.svg";
        let bwd_head_round = await toDataUrl(url_bwd_head_round);

        var url_Polygon1 = BASE_URL + "/public/assets/Polygon1.png";
        let Polygon1 = await toDataUrl(url_Polygon1);

        var url_sing = BASE_URL + "/public/assets/sing.png";
        let sing = await toDataUrl(url_sing);

        var url = BASE_URL + "/public/assets/signature.png";
        let urlBase64 = await toDataUrl(url);

        var url_lynkme_logo2 = BASE_URL + "/public/assets/lynkme360-logo2.svg";
        let lynkme_logo2 = await toDataUrl(url_lynkme_logo2);

        var url_lynkme_head_right =
          BASE_URL + "/public/assets/lynkme-head-right.svg";
        let lynkme_head_right = await toDataUrl(url_lynkme_head_right);

        await axios
          .post(BASE_URL + "/api/fetchDealDataForIO", { id: id , empid:this.ID})
          .then((response) => {
            console.log("dgdggdg", response.data.iodetails);

            this.pdfdata = response.data.iodetails;
            var email = response.data.email;
            var empname = response.data.empname;
            var position = response.data.position;
            var date = response.data.date;
            var signature_img = response.data.signature;
            var lynkme_alias = response.data.lynkme_alias;
            var bwd_title = response.data.bwd_title;
            var lynkme_title = response.data.lynkme_title;
            var signature_lynkme = response.data.signature_lynkme;
            //this.htmltopdf(this.salarydata);
            console.log('signature_img',signature_img);
            //var html = `<h1>${this.cl_slip_payable}</h1>`;

            //html2pdf(html);


            let signature =  toDataUrl(signature_img);
            let signature_lynkMe = toDataUrl(signature_lynkme);
            console.log('signature',signature);
            console.log('url_sing',url_sing);
            console.log('sing',sing);


            this.htmltopdfInvoice(
              this.pdfdata,
              urlBase64,
              email,
              bwd_head_logo,
              bwd_head_round,
              Polygon1,
              sing,
              lynkme_logo2,
              lynkme_head_right,
              empname,
              position,
              date,
              signature_img,
              lynkme_alias,
              bwd_title,
              lynkme_title,
              signature_lynkme

            );
          });
      } else if (status == "Closed") {
        this.tostMessage(
          "warning",
          "IO deatils has not been updated for this deal"
        );
      } else {
        this.tostMessage("warning", "This deal is not close yet");
      }
    },

    async htmltopdfInvoice(
      res,
      logo,
      email,
      bwd_head_logo,
      bwd_head_round,
      Polygon1,
      sing,
      lynkme_logo2,
      lynkme_head_right,
      empname,
      position,
      date,
      signature_img,
      lynkme_alias,
      bwd_title,
      lynkme_title,
      signature_lynkme
      ) 
      {

      const toDataUrl = async function (url, callback) {
          //Convert to base64
          return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
              var reader = new FileReader();
              reader.onloadend = function () {
                resolve(reader.result);
              };
              reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = () => {
              reject({
                status: this.status,
                statusText: xhr.statusText,
              });
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
          });
        };
      let signature = await toDataUrl(signature_img);
      let signature_lynkMe = await toDataUrl(signature_lynkme);


      console.log("res", res);
      const resvalue = Object.values(res);
      console.log("resvalue", resvalue);
      const items = JSON.parse(resvalue[25]);
      console.log("items", items);
      var ioid = String(resvalue[0]).padStart(3, "0");

      // var htmldemo = `
      //         <style></style>
      //         <div style="width:100%; padding:20px " id="pdfFormat">

      //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                 <tbody>
      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
      //                                 Big Wave Development LLC
      //                             </p>
      //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
      //                                 www.bigwavedevelopment.com
      //                             </a>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#5032687</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <thead>
      //                                     <tr>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
      //                                         </th>
      //                                     </tr>
      //                                 </thead>
      //                                 <tbody>

      //                                     ${items.map(item => `
      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
      //                                         </td>
      //                                     </tr>
      //                                     `).join('')}

      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
      //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span>
      //                             </p>
      //                         </td>
      //                     </tr>

      //                 </tbody>
      //             </table>
      //         `;
      var htmldemo =
        `
                                  <style></style>
                                  <div style="width:100%; padding:20px " id="pdfFormat">
                                  
                                      <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                          <tbody>
                                              <tr>
                                                  <td>
                                                      <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                                          Insertion Order
                                                      </p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[15]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[16]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[17]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[18]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[19]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">big Wave Development LLC</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[2]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 (727) 217-5552</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <thead>
                                                              <tr>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                                  </th>
                                                                  <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                                  </th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[20]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[21]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[22]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[23]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                        resvalue[24]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <thead>
                                                              <tr>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                                  </th>
                                                                  <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                                  </th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              ${items
                                                                .map(
                                                                  (item) => `
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                                  </td>
                                                              </tr>
                                                              `
                                                                )
                                                                .join("")}
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                                      </p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;width: 28%;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                        resvalue[24]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;width: 28%;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                                  </td>
                                                                  <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                                          This Insertion Order ("IO") serves as a binding agreement between 
                                                                          Big Wave Development and “Advertiser” Signature on this Insertion 
                                                                          signifies acceptance by Advertiser of this agreement</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0 350px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td style="padding: 0 50px 10px 50px;">
                                                      <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                                          <li>
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                                  The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                                  payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                                  unless otherwise specified.
                                                              </p>
                                                          </li>
                                                          <li>
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                                  Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                                  Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                                  pornographic content on any of the creative materials
                                                              </p>
                                                          </li>
                                                          <li>
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                                  Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                              </p>
                                                          </li>
                                                      </ul>
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                                      <tr>
                                                          <td style="width: 50%;padding: 0 0 0 50px;">
                                                              <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                              <ul style="margin: 0; padding: 0;">
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Signature: 
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                              <img width="200" height="35"
                                                                              style=" vertical-align: text-top;text-align: center;"
                                                                              src="` +
        logo +
        `" alt="">   
                                                                          </span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Name:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;"> 
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Title:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Date:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                                      </p>
                                                                  </li>
                                                              </ul>
                                                          </td>
                                                          <td style="width: 50%;padding: 0 50px 0 0;">
                                                              <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                              <ul style="margin: 0; padding: 0;">
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Signature: 
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                              <img width="200" height="35"
                                                                              style=" vertical-align: text-top;text-align: center;"
                                                                              src="` +
        logo +
        `" alt="">   
                                                                          </span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Name:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Title:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Date:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                                                                      </p>
                                                                  </li>
                                                              </ul>
                                                          </td>
                                                      </tr>
                                                  </table>
                                              </tr>
                                              
                                          </tbody>
                                      </table>
                                  `;

      var htmldemo_lynkme =
        `
                                  <style></style>
                                  <div style="width:100%; padding:20px " id="pdfFormat">
                                  
                                      <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                          <tbody>
                                              <tr>
                                                  <td>
                                                      <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                                          Insertion Order
                                                      </p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[15]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[16]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[17]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[18]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[19]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">LynkMe360 LLC</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                        resvalue[2]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, 218 Datura St. West Palm Beach, FL 33401-5679</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 727 440 5867</p>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                                  </td>
                                                                  <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                                      <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <thead>
                                                              <tr>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                                  </th>
                                                                  <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                                  </th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[20]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[21]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[22]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                        resvalue[23]
                                                                      }</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                        resvalue[24]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <thead>
                                                              <tr>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                                  </th>
                                                                  <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                                  </th>
                                                                  <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                                  </th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              ${items
                                                                .map(
                                                                  (item) => `
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                                  </td>
                                                              </tr>
                                                              `
                                                                )
                                                                .join("")}
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                                      </p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;width: 28%;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                        resvalue[24]
                                                                      }</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                                  </td>
                                                                  <td style="border-bottom: 1px solid #000;width: 28%;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                          <tbody>
                                                              <tr>
                                                                  <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                                  </td>
                                                                  <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                                      <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                                          This Insertion Order ("IO") serves as a binding agreement between 
                                                                          Big Wave Development and “Advertiser” Signature on this Insertion 
                                                                          signifies acceptance by Advertiser of this agreement</p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>        
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0 350px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <td style="padding: 0 50px 10px 50px;">
                                                      <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                                          <li>
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                                  The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                                  payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                                  unless otherwise specified.
                                                              </p>
                                                          </li>
                                                          <li>
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                                  Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                                  Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                                  pornographic content on any of the creative materials
                                                              </p>
                                                          </li>
                                                          <li>
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                                  Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                              </p>
                                                          </li>
                                                      </ul>
                                                  </td>
                                              </tr>

                                              <tr>
                                                  <td style="padding: 15px 0;"></td>
                                              </tr>
                                              <tr>
                                                  <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                                      <tr>
                                                          <td style="width: 50%;padding: 0 0 0 50px;">
                                                              <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                              <ul style="margin: 0; padding: 0;">
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Signature: 
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                              <img width="200" height="35"
                                                                              style=" vertical-align: text-top;text-align: center;"
                                                                              src="` +
        logo +
        `" alt="">   
                                                                          </span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Name:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;"> 
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Title:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                                      </p>
                                                                  </li>
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Date:
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                                      </p>
                                                                  </li>
                                                              </ul>
                                                          </td>
                                                          <td style="width: 50%;padding: 0 50px 0 0;">
                                                              <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                              <ul style="margin: 0; padding: 0;">
                                                                  <li style="list-style: none;">
                                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                          Signature: 
                                                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                              <img width="200" height="35"
                                                                              style=" vertical-align: text-top;text-align: center;"
                                                                              src="` +
        logo +
        `" alt="">   
                                          </span>
                                      </p>
                                  </li>
                                  <li style="list-style: none;">
                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                          Name:
                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                                      </p>
                                  </li>
                                  <li style="list-style: none;">
                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                          Title:
                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                                      </p>
                                  </li>
                                  <li style="list-style: none;">
                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                          Date:
                                          <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                                      </p>
                                  </li>
                              </ul>
                          </td>
                      </tr>
                  </table>
                  </tr>

                  </tbody>
                  </table>
                  `;

      //   console.log(htmldemo);
      //   htmldemo += ``;
      //   html2pdf(htmldemo, {
      //     filename: resvalue[3]
      //       .concat("-", resvalue[27])
      //       .concat("-", "IO")
      //       .concat("-", ioid),
      //   });

      var htmlBW =
              `<html>
    
    
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;;padding:00 20px 40px 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:15px; padding-top:30px;"><img src="` +bwd_head_logo +`" alt="" /></td>
                                        <td style="padding-bottom:15px; padding-top:30px;" align="right"><img src="` +bwd_head_round +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                
                        <tr>
                            <td style="font-size: 25px; font-weight: 700; color: #353535; padding: 11px 0 22px;">Insertion Order</td>
                        </tr>
                
                        <tr>
                            <td style="padding-bottom:20px">
                                <table style="border-spacing:0px; width: 100%; padding-bottom: 12px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top;">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" width="270px" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Advertiser Details :</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Name:</b> &nbsp; &nbsp;      Big Wave Development LLC</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Address:</b>     7901 4th ST. N & St. Petersburg, <br>
                                                                &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  FL.UN 33702 </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:0 3px 30px 10px;"><b>Website:</b>      www.bigwavedevelopment.com </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Publisher Details:</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td align="right" style="vertical-align: top;">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Contact Person:</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:7px 10px 3px 10px;"><b>Contact Name:</b> ${resvalue[17]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Email:</b>   ${resvalue[19]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Registration Number:</b>  ${resvalue[18]}</td>
                                                        </tr>
                
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </td>
                        </tr>
                
                        <tr>
                            <td bgcolor="#EAEAEA" style="padding: 20px 15px;">
                            <table style="border-spacing:0px; width: 100%; border-radius: 4px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Company Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">Big Wave Development LLC</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Contact Person:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[17]}</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Address: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">7901 4th ST. N St. Petersburg, FL.UN 33702 </td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">City and Country:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">United States</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Telephone: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">+1 (727) 217 5552</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Email:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[19]}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </td> 
                        </tr>
                
                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Details:</td>
                        </tr>
                
                        <tr>
                            <td>
                            <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word; border-radius: 4px 0 0 4px;">
                                                            Site / Application
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word;">
                                                            Start Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word;">
                                                            End Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word;">
                                                            Placement
                                                        </th>
                                                        <th bgcolor="#D9D9D9" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 4px 4px 0;">
                                                            Total Budget (inclusive all taxes)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[20]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[21]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[22]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[23]}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                
                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Type:</td>
                        </tr>
                
                        <tr>
                            <td>
                            <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Category
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Model
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Rate
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Quantity
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="160px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 4px 4px 0;">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                    .map(
                                                        (item) => `
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="120px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.name}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.model}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.rate}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.quantity}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.total}</td>
                                                    </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                    <tr>
                                                        <td bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Grand Total</td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                
                        <tr>
                            <td style="padding-top: 15px;">
                            <table width: 100%; style="border-spacing:0px; page-break-after: always" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Payment Terms
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Prepayment
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="300px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Further comments</td>
                                                        <td bgcolor="#E6E6E6" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">This insertion order(“IO”) serves as a binding <br>
                                                            agreement between  Big Wave  Development <br>
                                                            and “Advertiser” signature on this insertion <br>
                                                            signifies acceptance by Advertiser of this agreement
                                                            </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                        
                    
                    </tbody>
                </table>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;;padding:0 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:15px"><img src="` +bwd_head_logo +`" alt="" /></td>
                                        <td style="padding-bottom:15px" align="right"><img src="` +bwd_head_round +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>

                        <tr>
                            <td style="font-size: 14px; color: #242424; font-weight: 700; padding-top: 25px;">
                                Additional Details:  
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`"alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                The total cost must be paid in full before rendering the service unless otherwise specified.
                All payments must be made by bank Wire/Crypto. All advertising purchases are 
                non-refundable unless otherwise specified.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                Advertiser must not allow illegal content, spa, spyware illegal drugs, medicine, ROM, EMU’s
                Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads) obscener or pornographic content or any of creative materials
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                Bigwave Development is not responsible for the success or failure of the advertiser’s
                campaign
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr>
                        <td>
                            <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                            <td>
                                <table align="right" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px; color: #4F77BA; line-height: 16px; font-weight: 400; padding-top: 27px;">
                                                Big wave Development LLC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; color: #000; line-height: 16px; font-weight: 700; padding-top: 8px;">
                                                Signature::
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 10px;">
                                                <img src="` +signature +`" alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 25px; font-size: 12px; line-height: 26px; color: #242424; font-weight: 600;">
                                                Name: ${empname} <br>
                                                Tittle: ${bwd_title} <br>
                                                Date:${date}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </td>  

                            </tr>
                            </table>
                            </td>
                        </tr>
                        
                    
                    </tbody>
                </table>
                
                </html>`;

            
                var htmlLynkMe =
                `<html>

                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;;padding:0 20px 40px 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td style="padding-top:20px">
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%; border-bottom: 1px solid #D9D9D9;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:20px; padding-top:30px;"><img src="` +lynkme_logo2 +`" alt="" /></td>
                                        <td style="padding-bottom:20px; padding-top:30px;" align="right"><img src="` +lynkme_head_right +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>

                        <tr>
                            <td style="font-size: 25px; font-weight: 700; color: #353535; padding: 11px 0 22px;">Insertion Order</td>
                        </tr>

                        <tr>
                            <td>
                                <table width="100%" style="border-spacing:0px; padding-bottom: 12px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top; padding-bottom:30px">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" width="250px" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Advertiser Details:</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:7px 10px;"><b>Name:</b> &nbsp; &nbsp;     ${resvalue[15]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:0 10px 30px 10px;"><b>Address:</b>     ${resvalue[16]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Publisher Details:</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td align="right" style="vertical-align: top;">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td bgcolor="#D9D9D9" width="220px" style="border-radius: 4px; padding:7px 10px; font-size: 12px; font-weight: 700; color: #000;">Contact Person:</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:7px 10px 3px 10px;"><b>Contact Name:</b> ${resvalue[17]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Email:</b>     ${resvalue[19]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; font-weight: 400; color: #000; padding:3px 10px;"><b>Registration Number:</b> ${resvalue[18]}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </td>
                        </tr>

                        <tr>
                            <td style="padding:20px 15px;" bgcolor="#EAEAEA">
                            <table width="100%" style="border-spacing:0px; padding: 20px 15px; border-radius: 4px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Company Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">LinkMe360 LLC</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Contact Person:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[17]}</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Address: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">PNC Bank, 218 Datura St. West Palm Beach,FL-33401-5679 </td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">City and Country:</td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">United States</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Telephone: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">+1 (727) 440 5687</td>
                                    </tr>
                                    <tr>
                                        <td width="165px" valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 700;">Email:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 20px; font-weight: 600;">${resvalue[19]}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </td> 
                        </tr>

                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Details:</td>
                        </tr>

                        <tr>
                            <td>
                            <table width="600px" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word; border-radius: 4px 0 0 4px;">
                                                            Site / Application
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;">
                                                            Start Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;">
                                                            End Date
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;">
                                                            Placement
                                                        </th>
                                                        <th bgcolor="#D9D9D9" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;word-wrap:break-word;border-radius: 0 4px 4px 0;">
                                                            Total Budget (inclusive all taxes)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px; word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[20]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[21]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[22]}</td>
                                                        <td bgcolor="#E6E6E6" width="80px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">${resvalue[23]}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;word-wrap:break-word; border-radius: 0 0 0 4px;">$${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>

                        <tr>
                            <td style="font-size: 14px; line-height: 18px; font-weight: 700; color: #242424; padding-top: 20px; padding-bottom: 10px;">Campaign Type:</td>
                        </tr>

                        <tr>
                            <td>
                            <table width="100%" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Category
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Model
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Rate
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Quantity
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="160px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 4px 4px 0;">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                    .map(
                                                        (item) => `
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="120px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.name}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.model}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.rate}</td>
                                                        <td bgcolor="#E6E6E6" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">${item.quantity}</td>
                                                        <td bgcolor="#E6E6E6" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${item.total}</td>
                                                    </tr>
                                                </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                    <tr>
                                                        <td bgcolor="#D9D9D9" width="120px" align="left" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Grand Total</td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" width="100px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;"></td>
                                                        <td bgcolor="#D9D9D9" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">$${resvalue[24]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>

                        <tr>
                            <td style="padding-top: 15px;">
                            <table width="100%" style="border-spacing:0px;; page-break-after: always" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0 4px;">
                                                            Payment Terms 
                                                        </th>
                                                        <th bgcolor="#D9D9D9" width="300px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Prepayment
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td bgcolor="#E6E6E6" width="300px" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">Further comments</td>
                                                        <td bgcolor="#E6E6E6" align="left" style="font-size: 10px;font-weight: 400;color: #000; padding: 10px;white-space: nowrap; border-radius: 0 0 0 4px;">This insertion order(“IO”) serves as a binding <br>
                                                            agreement between  Big Wave  Development <br>
                                                            and “Advertiser” signature on this insertion <br>
                                                            signifies acceptance by Advertiser of this agreement
                                                            </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>


                        
                    
                    </tbody>
                </table>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;padding:0 20px; width: 600px;  bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:20px"><img src="` +lynkme_logo2 +`" alt="" /></td>
                                        <td style="padding-bottom:20px" align="right"><img src="` +lynkme_head_right +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>

                        <tr>
                            <td style="font-size: 14px; color: #242424; font-weight: 700; padding-top: 25px;">
                                Additional Details: 
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                The total cost must be paid in full before rendering the service unless otherwise specified. 
                All
                payments must be made by Bank Wire/Crypto. All advertising purchases are
                non-refundable
                unless otherwise specified.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's,
                                                Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)
                                                obscene or
                                                pornographic content on any of the creative materials.                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top; padding-top: 14px;">
                                                <img src="` +Polygon1 +`" alt="" />
                                            </td>
                                            <td style="font-size: 12px; line-height: 15px; color: #000000; vertical-align: top; padding-left: 10px; padding-top: 14px;">
                                                LynkMe360 LLC is not responsible for the success or failure of the advertiser's campaign.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr>
                        <td>
                            <table style="border-spacing:0px; width: 100%; padding-top: 27px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tr>
                            <td>
                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px; color: #5DB533; line-height: 16px; font-weight: 400; padding:30px 0 0">
                                                ${resvalue[15]}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; color: #000; line-height: 16px; font-weight: 700; padding-top: 8px;">
                                                Signature:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 10px; height: 50px;">
                                                                        
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 25px; font-size: 12px; line-height: 26px; color: #242424; font-weight: 600;">
                                                Name:  <br>
                                                Title:  <br>
                                                Date:
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </td>
                            <td>
                                <table align="right" style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px; color: #5DB533; line-height: 16px; font-weight: 400; padding:30px 0 0">
                                                Big wave Development LLC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; color: #000; line-height: 16px; font-weight: 700; padding-top: 8px;">
                                                Signature:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 10px;">
                                                <img src="` +signature_lynkMe +`" alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 25px; font-size: 12px; line-height: 26px; color: #242424; font-weight: 600;">
                                                Name: ${lynkme_alias} <br>
                                                Tittle: ${lynkme_title} <br>
                                                Date:${date}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </td>  

                            </tr>
                            </table>
                            </td>
                        </tr>
                        
                    
                    </tbody>
                </table>

                </html>`

      if (resvalue[27] == "Bigwave") {
        // console.log(htmldemo);
        htmlBW += ``;
        html2pdf(htmlBW, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "IO")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Lynkme") {
        htmlLynkMe += ``;
        html2pdf(htmlLynkMe, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "IO")
            .concat("-", ioid),
        });
      } else {
        this.tostMessage("warning", "Can not download IO for Reddensoft.");
      }
      this.tostMessage("success", "Downloaded");
      // axios.post(BASE_URL + '/api/savesalarypdf',formData)
      //     .then((response)=>{
      //         console.log("uyertfgeusyyr",response.data.salary_details);
      //     })
    },

    async exportToPDFInvoice1(io, status, beneficiary, id) {
      if (beneficiary == null) {
        this.tostMessage("warning", "Please add benificiary details first");
      } else if (io == "1") {
        this.tostMessage("info", "Preparing download...");

        const toDataUrl = async function (url, callback) {
          //Convert to base64
          return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
              var reader = new FileReader();
              reader.onloadend = function () {
                resolve(reader.result);
              };
              reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = () => {
              reject({
                status: this.status,
                statusText: xhr.statusText,
              });
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
          });
        };
        console.log("this.invoice.id", this.invoiceid);

        // var url = "https://works-reddensoft.com/lms/admin/public/assets/signature.png";
        // var url =
        //   "https://works-reddensoft.com/lms/admin/public/assets/reddensoft_logo.svg";
        var url = BASE_URL + "/public/assets/reddensoft_logo.svg";
        let urlBase64 = await toDataUrl(url);

        var url_bwd_head_logo = BASE_URL + "/public/assets/bwd-head-logo.svg";
        let bwd_head_logo = await toDataUrl(url_bwd_head_logo);

        var url_bwd_head_round = BASE_URL + "/public/assets/bwd-head-round.svg";
        let bwd_head_round = await toDataUrl(url_bwd_head_round);

        var url_Polygon1 = BASE_URL + "/public/assets/Polygon1.png";
        let Polygon1 = await toDataUrl(url_Polygon1);

        var url_sing = BASE_URL + "/public/assets/sing.png";
        let sing = await toDataUrl(url_sing);

        var url_lynkme_logo2 = BASE_URL + "/public/assets/lynkme360-logo2.svg";
        let lynkme_logo2 = await toDataUrl(url_lynkme_logo2);

        var url_lynkme_head_right =
          BASE_URL + "/public/assets/lynkme-head-right.svg";
        let lynkme_head_right = await toDataUrl(url_lynkme_head_right);

        var url_lynkme_head_logo =
          BASE_URL + "/public/assets/lynkme-head-logo.svg";
        let lynkme_head_logo = await toDataUrl(url_lynkme_head_logo);

        var url_Rectangle1 = BASE_URL + "/public/assets/Rectangle1.svg";
        let Rectangle1 = await toDataUrl(url_Rectangle1);

        var url_Rectangle2 = BASE_URL + "/public/assets/Rectangle2.svg";
        let Rectangle2 = await toDataUrl(url_Rectangle2);

        axios
          .post(BASE_URL + "/api/fetchDealDataForIO", { id: id })
          .then((response) => {
            console.log(response.data.iodetails);

            this.pdfdata = response.data.iodetails;
            //this.htmltopdf(this.salarydata);
            //var html = `<h1>${this.cl_slip_payable}</h1>`;

            //html2pdf(html);

            this.htmltopdfInvoice1(
              this.pdfdata,
              urlBase64,
              bwd_head_logo,
              bwd_head_round,
              Polygon1,
              sing,
              lynkme_logo2,
              lynkme_head_right,
              lynkme_head_logo,
              Rectangle1,
              Rectangle2
            );
          });
      } else if (status == "Closed") {
        this.tostMessage(
          "warning",
          "IO deatils has not been updated for this deal"
        );
      } else {
        this.tostMessage("warning", "This deal is not close yet");
      }
      console.log("vdgvd");
    },

    async htmltopdfInvoice1(
      res,
      logo,
      bwd_head_logo,
      bwd_head_round,
      Polygon1,
      sing,
      lynkme_logo2,
      lynkme_head_right,
      lynkme_head_logo,
      Rectangle1,
      Rectangle2
    ) {
      console.log("res", res);
      const resvalue = Object.values(res);
      console.log("resvalue", resvalue);
      const items = JSON.parse(resvalue[25]);
      console.log("items", items);
      var ioid = String(resvalue[0]).padStart(3, "0");

      // var htmldemo = `
      //         <style></style>
      //         <div style="width:100%; padding:20px " id="pdfFormat">

      //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                 <tbody>
      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
      //                                 Big Wave Development LLC
      //                             </p>
      //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
      //                                 www.bigwavedevelopment.com
      //                             </a>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#${ioid}</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <thead>
      //                                     <tr>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
      //                                         </th>
      //                                     </tr>
      //                                 </thead>
      //                                 <tbody>

      //                                     ${items.map(item => `
      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
      //                                         </td>
      //                                     </tr>
      //                                     `).join('')}

      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
      //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span>
      //                             </p>
      //                         </td>
      //                     </tr>

      //                 </tbody>
      //             </table>
      //         `;

      var htmllynkmedemo = `    <div style="padding: 0;">
                      <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                              <tr>
                                  <td>
                                      <span style="width: 100%;height: 60px;background: #7ed956;display: block;"></span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                              <tr>
                                  <td style="padding-right: 20px;">
                                      <span style="width: 490px;height: 15px;background: #008235;display: block;margin-left: auto;"></span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>    
                      <div style="padding: 0 20px;">
                          <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                              <tbody>
                                  <tr>
                                      <td>
                                          <span style="width: 100%;height: 40px;background: #fff;display: block;"></span>
                                      </td>
                                  </tr>
                              </tbody>
                          </table> 
                          <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                              <tbody>
                                  <tr>
                                      <td style="padding-top: 10px;">
                                          <h2 style="font-family: sans-serif;color: #000;font-size: 24px;margin: 0;letter-spacing: 4px;">
                                              LYNKME
                                              <span style="padding: 0 2px;">360</span>
                                              LLC
                                          </h2>
                                      </td>
                                      <td style="padding-top: 10px;">
                                          <h2 style="font-family: sans-serif;color: #000;font-size: 40px;text-align: right;margin: 0;">
                                              INVOICE
                                          </h2>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="padding-top: 30px;">
                                          <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                              BILLED TO:
                                          </p>
                                          <h2 style="font-family: sans-serif;color: #000;font-size: 20px;margin: 0;font-weight: 600;padding-bottom: 4px;">
                                              ${resvalue[15]}

                                          </h2>
                                          <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                              ${resvalue[16]}

                                          </p>
                                          <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                              Email: ${resvalue[19]}
                                          </p>
                                      </td>
                                      <td style="padding-top: 30px;">
                                          <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;text-align: right;">
                                              Invoice Number: ${ioid}
                                          </p>
                                          <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;text-align: right;">
                                              ${resvalue[8]}
                                          </p>
                                      </td>
                                  </tr>
                              </tbody>
                              <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <tr>
                                          <td>
                                              <span style="width: 100%;height: 20px;background: #fff;display: block;"></span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table> 
                              <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <thead>
                                          <tr>
                                              <th style="background: #008036;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                                  ITEM DESCRIPTION
                                              </th>
                                              <th style="background: #7ed956;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                                  VOLUME
                                              </th>
                                              <th style="background: #7ed956;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                                  Total($)
                                              </th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                      ${items
                                        .map(
                                          (item) => `

                                          <tr>
                                              <td style="border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                                  ${item.name}
                                              </td>
                                              <td style="border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                                  ${item.quantity}
                                              </td>
                                              <td style="background: #ede9e8; border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                                  ${item.total}
                                              </td>
                                          </tr>
                                      `
                                        )
                                        .join("")}

                                          <tr>
                                              <td style="border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 700;text-align: left;letter-spacing: 2px;">
                                              TOTAL AMOUNT:
                                              </td>
                                              <td style="border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                              </td>
                                              <td style="background: #ede9e8; border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 700;text-align: left;letter-spacing: 2px;">
                                                  $${resvalue[24]}
                                              </td>
                                          </tr>
                                      </tbody>
                                  </tbody>
                              </table>
                              <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <tr>
                                          <td style="padding-top: 20px; font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;text-decoration: underline;padding-bottom: 10px;">
                                              Please make payments to:
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                              Wire Transfer Details:
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                              BENEFICIARY NAME: LynkMe360 LLC
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                              BANK NAME: PNC Bank
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                              Account Number: 1251348057
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                              Routing Number: 267084199
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                              Swift Code: PNCCUS33
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                              BANK ADDRESS: PNC Bank, 218 Datura St. West Palm Beach, FL 33401-5679
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>    
                              <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;padding-bottom: 40px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <tr>
                                          <td style="padding-top: 40px; font-family: sans-serif;color: #000;font-size: 20px;margin: 0;font-weight: 700;padding-bottom: 4px;text-align: right;">
                                              LynkMe 360 LLC
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 3px;text-align: right;">
                                              2630 W Broward BLVD Suite 203 - 1713 Fort Lauderdale,
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;text-align: right;">
                                              FL 33312 United States, Tel: +1 727 440 5867
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>            
                          </table>   
                          <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                              <tbody>
                                  <tr>
                                      <td>
                                          <span style="width: 100%;height: 40px;background: #fff;display: block;"></span>
                                      </td>
                                  </tr>
                              </tbody>
                          </table> 
                      </div>
                      <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                              <tr>
                                  <td style="padding: 0 20px;">
                                      <span style="width: 100%;height: 15px;background: #008235;display: block;margin-left: auto;padding: 0 20px;"></span>
                                  </td>
                              </tr>
                          </tbody>
                      </table> 
                      <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                              <tr>
                                  <td>
                                      <span style="width: 100%;height: 60px;background: #7ed956;display: block;"></span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>  
                          </div>`;

      var htmlreddensoftdemo =
        `    <div style="padding: 20px;">
                      <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                              <tr>
                                  <td style="text-align: center;">
                                      <a href="#" style="font-size: 16px;font-weight: 700;color: #000;padding:3px; margin: 0;">
                                                  <img src="` +
        logo +
        `" alt="logo" style="width: 200px;margin: auto;">
                                              </a>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>  
                              <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <tr>
                                          <td style="padding-top: 40px;width: 60%;">
                                              <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 18px;margin: 0;letter-spacing: 1px;">
                                                  INVOICE: #${ioid}
                                              </h2>
                                          </td>
                                          <td style="padding-top: 10px;width: 40%;">
                                              <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 18px;margin: 0;letter-spacing: 1px;">
                                                  DATE: ${resvalue[8]}
                                              </h2>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="padding-top: 30px;padding-bottom: 30px;width: 60%;">
                                              <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;padding-right: 15px;">
                                                  Reddensoft Infotech Pvt. Ltd.
                                              </p>
                                              <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;padding-right: 15px;">
                                                  AMP Baishaki, 7th floor, Room 705, AG-112, Sec-II, Salt Lake, Kolkata -700091
                                              </p>
                                              <p style="padding-bottom: 6px;margin: 0;">
                                                  <a href="#" style="color: #6767ff;font-family: sans-serif;font-size: 18px;margin: 0;font-weight: 400;margin: 0;">info@reddensoft.com</a>
                                              </p>
                                          </td>
                                          <td style="padding-top: 30px;padding-bottom: 30px;width: 40%;">
                                              <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                                                  To:
                                              </p>
                                              <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                                  ${resvalue[15]}
                                              </p>
                                              <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                                  ${resvalue[16]}
                                              </p>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>   
                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #d2d2d2; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                  <thead>
                                      <tr>
                                          <th style="width: 50%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Item Description</p>
                                          </th>
                                          <th style="width: 15%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Quantity</p>
                                          </th>
                                          <th style="width: 20%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Unit Price($)</p>
                                          </th>
                                          <th style="width: 15%;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Total($)</p>
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      ${items
                                        .map(
                                          (item) => `

                                      <tr>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.name}</p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.quantity}</p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.rate}</p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.total}</p>
                                          </td>
                                      </tr>
                                      `
                                        )
                                        .join("")}

                                      <tr>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Subtotal</p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${
                                                resvalue[24]
                                              }</p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Tax - 0%</p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;background: #e6e6e6;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">0.00</p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">TOTAL</p>
                                          </td>
                                          <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${
                                                resvalue[24]
                                              }</p>
                                          </td>
                                      </tr>
                                  </tbody>
                                  </table>  
                                  <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                      
                                      <tbody>
                                          <tr>
                                              <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;padding-top: 30px;">
                                                  Payment Option: 
                                                  <span style="font-weight: 600;">Wise.com</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                                  Company Name:
                                                  <span style="font-weight: 600;">Reddensoft Infotech Pvt Ltd</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                                  Bank Name: AXIS BANK
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                                  Account Number: 1010101010101
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                                  IFSC Code: UTIB0000000
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                                  Swift code: AXIS5555555
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                                  Bank Address: Kolkata, West Bengal, India
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>  
                                  <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                      <tbody>
                                          <tr>
                                              <td>
                                                  <p style="text-align: center;font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;letter-spacing: 1px;padding-bottom: 8px;padding-top: 40px;">
                                                      If you have any questions or concerns regarding this invoice, contact via email.
                                                  </p>
                                                  <p style="text-align: center;font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;letter-spacing: 1px;">
                                                      Thank you!
                                                  </p>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>  
                              </div>`;

      //   var htmlbigwavedemo = `    <div style="padding: 20px;">
      //           <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //               <tbody>
      //                   <tr>
      //                       <td style="font-size: 30px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
      //                           Big Wave Development LLC
      //                       </td>
      //                   </tr>
      //                   <tr>
      //                       <td style="text-align: center;">
      //                           <a href="https://www.bigwavedevelopment.com/" style="font-size: 16px;font-weight: 700;color: #000;padding:3px; margin: 0;">
      //                               www.bigwavedevelopment.com
      //                           </a>
      //                       </td>
      //                   </tr>
      //                   <tr>
      //                       <td style="padding-top: 40px;">
      //                           <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;">
      //                               INVOICE/DATE ${resvalue[8]}
      //                           </h2>
      //                       </td>
      //                   </tr>
      //                   <tr>
      //                       <td style="padding-top: 10px;">
      //                           <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;">
      //                               #${ioid}
      //                           </h2>
      //                       </td>
      //                   </tr>
      //               </tbody>
      //           </table>
      //           <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //               <tbody>
      //                   <tr>
      //                       <td style="width: 50%; padding-right:10px; padding-top: 40px;">
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
      //                               Form:
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Company Name: Big Wave Development LLC
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Company Address: 7901 4th ST. N St. Petersburg, FL. UN 33702
      //                           </p>
      //                       </td>
      //                       <td style="width: 50%; padding-top: 40px;">
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
      //                               To:
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Company Name: ${resvalue[15]}
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Company Address: ${resvalue[16]}
      //                           </p>
      //                       </td>
      //                   </tr>
      //               </tbody>
      //           </table>
      //           <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //               <tbody>
      //                   <tr>
      //                       <td style="padding-top: 40px;">
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
      //                               WireTransfer Details:
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               BENIFICIARY NAME: Big Wave Development LLC
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               BANK NAME: PNC Bank
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Account Number: 1242641948
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Routing Number: 267084199
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Swift Code: PNCCUS33
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               BANK ADDRESS: PNC Bank, National Association
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Pittsburgh 00115
      //                           </p>
      //                       </td>
      //                       <td style="padding-top: 40px;vertical-align: bottom;">
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
      //                               Contact Person:
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Contact name: ${resvalue[17]}
      //                           </p>
      //                           <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
      //                               Email: ${resvalue[19]}
      //                           </p>
      //                       </td>
      //                   </tr>
      //               </tbody>
      //           </table>
      //           <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //               <tbody>
      //                   <tr>
      //                       <td>
      //                           <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;margin: 0;padding-top: 40px;padding-bottom: 20px;">
      //                               Sub: Invoice
      //                           </h2>
      //                       </td>
      //                   </tr>
      //               </tbody>
      //           </table>
      //           <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //               <thead>
      //                   <tr>
      //                       <th style="width: 40%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Item Description</p>
      //                       </th>
      //                       <th style="width: 15%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Quantity</p>
      //                       </th>
      //                       <th style="width: 30%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">PRICE($)</p>
      //                       </th>
      //                       <th style="width: 15%;border-bottom: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Total($)</p>
      //                       </th>
      //                   </tr>
      //               </thead>
      //               <tbody>
      //               ${items
      //                 .map(
      //                   (item) => `

      //                   <tr>
      //                       <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.name}</p>
      //                       </td>
      //                       <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.quantity}</p>
      //                       </td>
      //                       <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.rate}</p>
      //                       </td>
      //                       <td style="border-bottom: 1px solid #000;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.total}</p>
      //                       </td>
      //                   </tr>

      //               `
      //                 )
      //                 .join("")}

      //                   <tr>
      //                       <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
      //                       </td>
      //                       <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Total</p>
      //                       </td>
      //                       <td style="border-bottom: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${
      //                             resvalue[24]
      //                           }</p>
      //                       </td>
      //                       <td style="border-bottom: 1px solid #000;background: #ebebeb;">
      //                           <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
      //                       </td>
      //                   </tr>
      //               </tbody>
      //           </table>
      //           <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //               <tbody>
      //                   <tr>
      //                       <td>
      //                           <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;;margin: 0;padding-top: 20px;padding-bottom: 20px;">
      //                               If you have any questions or concerns regarding this invoice, contact via email.
      //                               <span style="font-weight: 700;">Thank you!</span>
      //                           </h2>
      //                       </td>
      //                   </tr>
      //               </tbody>
      //           </table>
      //           </div>`;

                var invoice_bw = `<html>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif;padding:0 20px; width: 600px;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px;border-bottom: 1px solid #D9D9D9; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding-bottom:15px; padding-top:30px;"><img src="` +bwd_head_logo +`" alt="" /></td>
                                        <td align="right" style="padding-bottom:15px; padding-top:30px;"><img src="` +bwd_head_round +`" alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                        <tr>
                            <td>
                                <table style="border-spacing:0px; width: 100%; padding-top: 15px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td valign="top" style="font-size: 24px; line-height: 30px; font-weight: 700; padding-top:30px">INVOICE</td>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 600; padding-top:30px">${resvalue[8]} <br> #${ioid}</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 700; padding-top: 30px;">TO:</td>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 700; padding-top: 30px;">Contact Person:</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 400; padding-top: 4px;"><b> Name: </b> Big Wave Development LLC</td>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 700; padding-top: 4px;">Contact Name: ${resvalue[17]}</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 18px; font-weight: 400; padding-top: 4px;"><b> Address: </b> 7901 4th ST. N St. Petersburg, <br>
                                                &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; FL.UN 33702 </td>
                                            <td valign="top" style="font-size: 12px; line-height: 15px; font-weight: 700; padding-top: 4px;">Email: ${resvalue[19]}</td>
                                        </tr>
                                        <tr>
                                            <td valign="top" style="font-size: 12px; line-height: 15px; font-weight: 400; padding-top: 4px;"><b> Website: </b>  www.bigwavedevelopment.com</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; font-weight: 700; line-height: 18px; padding-top: 24px; padding-bottom: 15px;">Wire Transfer Details:</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                        <td style="padding:20px 15px" bgcolor="#E0E0E0">
                            <table style="border-spacing:0px; width: 100%; padding: 20px 15px; border-radius: 4px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Beneficiary Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">Big Wave Development LLC</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Bank Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Account Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">1242641948</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Routing Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">267084199</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Swift Code:  </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNCCUS33</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Bank Address:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank, National Association <br> Pittsburgh 00115</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td> 
                        </tr>
                        <tr>
                            <td style="font-size: 14px; line-height: 20px; color: #242424; padding: 18px 0 12px; font-weight: 700;">Sub: Invoice:</td>
                        </tr>
                        <tr>
                        <td>
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#B2CBF4" width="220px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0;">
                                                            Item Description
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="80px" align="center" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Quantity
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="80px" align="center" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            Rate
                                                        </th>
                                                        <th bgcolor="#B2CBF4" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                                                            Price
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody bgcolor="#E9E8E8">
                                                    ${items
                                                    .map(
                                                    (item) => `
                                                    <tr>
                                                        <td align="left" width="220px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            ${item.name}
                                                        </td>
                                                        <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                                                            ${item.quantity}
                                                        </td>
                                                        <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                                                            ${item.rate}
                                                        </td>
                                                        <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                                                            $${item.total}
                                                        </td>
                                                    </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                </tbody>
                                                <tfoot width="100%">
                                                    <tr>
                                                        <th bgcolor="#B2CBF4" width="600px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0;">
                                                            Grand Total
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="300px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">
                                                            
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="300px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">
                                                            
                                                        </th>
                                                        <th bgcolor="#B2CBF4" width="300px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                                                            $${resvalue[24]}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                        </td>
                        </tr>
                        <tr>
                            <td style="font-size: 8px; color: #515151; font-weight: 400; font-style: italic; padding-top: 6px;">If you have any questions or concerns regarding this invoice, contact via email. <b style="color: 515151;"> Thank you! </b></td>
                        </tr>
                    </tbody>
                </table>            
                </html>`

                var invoice_lynkme =`<html>
                <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif; width: 600px; border-bottom: 54px #242424 solid;" bgcolor="#ffffff"
                    cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                        <tr>
                        <td>
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="vertical-align: bottom; padding-bottom:30px; padding-top:30px;"><img src="` +lynkme_head_logo +`"  alt="" /></td>
                                        <td style="vertical-align: bottom; padding-bottom:30px; padding-top:30px;">
                                            <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="color: #41BE0A; font-size: 24px; line-height: 28px; font-weight: 700; padding-bottom: 15px;">INVOICE</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 14px; font-weight: 700; line-height: 18px;">Invoice Number:</td> <td style="font-size: 14px; font-weight: 400; line-height: 18px;">&nbsp; #${ioid}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 14px; font-weight: 700; line-height: 18px;">Invoice Date</td> <td style="font-size: 14px; font-weight: 400; line-height: 18px;">&nbsp; ${resvalue[8]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                
                        <tr>
                            <td style="padding-bottom: 30px;">
                                <table style="border-spacing:0px; padding-bottom: 15px; width: 100%; padding-top: 15px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="font-size: 11px; line-height: 15px; color: #41BE0A;">Billed To</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 16px; line-height: 20px; color: #242424; font-weight: 700; padding-bottom: 5px;">${resvalue[15]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Address: </b> ${resvalue[16]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Email: </b> ${resvalue[19]}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td align="right">
                                                <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" a cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="font-size: 11px; line-height: 15px; color: #41BE0A;">Billed From</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 16px; line-height: 20px; color: #242424; font-weight: 700; padding-bottom: 5px;">Lynk Me 360 LLC </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Address: </b> 2630 W Broward BLVD Suite 203-1713 <br>
                                                                Fort Lauderdale, FL 3312 United States, </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 12px; line-height: 20px; color: #242424; font-weight: 400;"><b style="font-weight: 700;"> Tel: </b> +1 727 440 5867</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                
                        <tr>
                            <td style="padding-bottom: 30px;">
                            <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table style="border-spacing:0px; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th bgcolor="#41BE0A" width="220px" align="left" style="font-size: 10px;font-weight: 600;color: #fff; padding: 10px;white-space: nowrap;">
                                                            Item Description
                                                        </th>
                                                        <th bgcolor="#242424" width="140px" align="center" style="font-size: 10px;font-weight: 600;color: #fff; padding: 10px;white-space: nowrap; border-left: 5px solid #fff;">
                                                            Volume
                                                        </th>
                                                        <th bgcolor="#242424" align="left" style="font-size: 10px;font-weight: 600;color: #fff; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 5px solid #fff;">
                                                            Total ($)
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                    .map(
                                                    (item) => `
                                                    <tr>
                                                        <td align="left" width="220px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            ${item.name}
                                                        </td>
                                                        <td align="center" width="140px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                                                            ${item.quantity}
                                                        </td>
                                                        <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap;">
                                                            ${item.total}
                                                        </td>
                                                    </tr>
                                                    `
                                                    )
                                                    .join("")}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th width="220px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-top: 1px solid #000000; border-bottom: 1px solid #000;">
                                                            Total Amount
                                                        </th>
                                                        <th width="140px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap; border-top: 1px solid #000000; border-bottom: 1px solid #000;">
                                                            
                                                        </th>
                                                        <th align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-top: 1px solid #000000; border-bottom: 1px solid #000;">
                                                            $${resvalue[24]}
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            </td>
                        </tr>
                
                        <tr>
                            <td>
                            <table style="border-spacing:0px; width: 100%; padding-top: 34px; padding-bottom: 50px;" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="font-size: 16px; line-height: 20px; color: #000000; font-weight: 700; padding-bottom: 15px;">Transaction Details :</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 16px; line-height: 20px; color: #000000; font-weight: 600; padding-bottom: 15px;">Wire Transfer Details:</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Beneficiary Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">LynkMe360 LLC</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Bank Name: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Account Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">1251348057</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Routing Number: </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">267084199</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700;">Swift Code:  </td>
                                        <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNCCUS33</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom:60px">Bank Address:</td>
                                        <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600;">PNC Bank, 218 Datura St. <br> West Palm Beach,FL-33401-5679</td>
                                    </tr>
                                </tbody>
                            </table>
                            </td> 
                        </tr>
                        
                    </tbody>
                </table>
                
                </html>`

                var invoice_rs = `<html>
                    <table style="border-spacing:0px;margin:auto;font-family:'Poppins', sans-serif; width: 600px;" cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                    <tbody>
                    <tr>
                    <td>
                    <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td width="30px" style="vertical-align: bottom;"><img src="`+Rectangle2+`" alt="" /></td>
                    <td width="540px" style="vertical-align: top; padding: 0 15px;">
                    <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td>
                    <table style="border-spacing:0px; padding-bottom: 10px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="vertical-align: bottom;">
                    <table style="border-spacing:0px; padding-bottom: 20px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="padding-top:30px;"><img src="`+logo+`" alt=""/></td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; padding-top:30px; font-weight: 400; line-height: 18px; padding-top: 20px;">Reddensoft Infotech Private Limited <br>
                    AMP Baishakhi, 7th Floor, Room 705, AG-112, Sec-II <br>
                    Saltlake, Kolkata-700091</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; font-weight: 700; line-height: 18px; color: #5976B5;">info@reddensoft.com</td>
                    </tr>
                    </tbody>
                    </table>
                    </td>
                    <td style="vertical-align: bottom;">
                    <table style="border-spacing:0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="color: #353535; font-size: 24px; line-height: 28px; font-weight: 700; padding-bottom: 15px;">INVOICE</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight:400; line-height: 18px;">${
                    resvalue[8]
                    }</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight: 700; line-height: 18px;">#${ioid}</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight: 700; line-height: 18px; padding-top: 12px;">To:</td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; font-weight: 700; line-height: 18px; padding-top: 5px;">${resvalue[15]}</td>
                    </tr>

                    </tbody>
                    </table>
                    </td>
                    </tr>
                    <tr>
                    <td style="font-size: 14px; line-height: 18px; color: #242424; font-weight: 700; padding-top: 30px; padding-bottom: 30px;">Sub: Invoice:</td>
                    </tr>
                    </tbody>
                    </table>
                    </td>
                    </tr>

                    <tr>
                    <td>
                    <table style="border-spacing:0px; width: 100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td valign="top">
                    <table style="border-spacing:0px; width: 100%;" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                    <th bgcolor="#DCDCDC" width="200px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-radius: 4px 0 0;">
                    Item Description
                    </th>
                    <th bgcolor="#DCDCDC" width="80px" align="center" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                    Quantity
                    </th>
                    <th bgcolor="#DCDCDC" width="108px" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                    Unit Price ($)
                    </th>
                    <th bgcolor="#DCDCDC" align="left" style="font-size: 10px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-radius: 0 4px 0 0;">
                    Total ($)
                    </th>
                    </tr>
                    </thead>
                    <tbody>
                    ${items
                    .map(
                    (item) => `
                    <tr>
                    <td align="left" width="200px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">
                    ${item.name}
                    </td>
                    <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${item.quantity}
                    </td>
                    <td align="left" width="108px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${item.rate}
                    </td>
                    <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${item.total}
                    </td>
                    </tr>
                    `
                    )
                    .join("")}

                    <tr>
                    <td bgcolor="#BBBDC0" align="left" width="200px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">

                    </td>
                    <td bgcolor="#BBBDC0" align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">

                    </td>
                    <td bgcolor="#BBBDC0" align="left" width="108px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    Subtotal
                    </td>
                    <td bgcolor="#BBBDC0" align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    ${resvalue[24]}
                    </td>
                    </tr>

                    <tr>
                    <td align="left" width="200px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap;">

                    </td>
                    <td align="center" width="80px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px;white-space: nowrap; border-left: 1px solid #CDCDCD;">

                    </td>
                    <td align="left" width="108px" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    Tax-0%
                    </td>
                    <td align="left" style="font-size: 12px;font-weight: 600;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap; border-left: 1px solid #CDCDCD;">
                    0.00
                    </td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr>
                    <th bgcolor="#BCBDC0" width="200px" align="left" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">

                    </th>
                    <th bgcolor="#BCBDC0" width="80px" align="center" style="font-size: 16px;font-weight: 700;color: #000; padding: 10px;white-space: nowrap;">

                    </th>
                    <th bgcolor="#BCBDC0" width="108px" align="left" style="font-size: 10px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap;">
                    TOTAL
                    </th>
                    <th bgcolor="#BCBDC0" align="left" style="font-size: 14px;font-weight: 700;color: #000; padding: 10px 10px 10px 50px;white-space: nowrap;">
                    ${resvalue[24]}
                    </th>
                    </tr>
                    </tfoot>
                    </table>
                    </td>
                    </tr>
                    </tbody>
                    </table>  
                    </td>
                    </tr>

                    <tr>
                    <td style="padding:30px 0">
                    <table style="border-spacing:0px; width: 100%; padding-top: 25px; padding-bottom: 15px;" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                    <td style="font-size: 16px; line-height: 20px; color: #000000; font-weight: 700; padding-bottom: 15px;">Transaction Details :</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Payment Option: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Wise.com</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Company Name: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Reddensoft Infotech PVT Ltd</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Bank Name: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Axis Bank</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Account Number: </td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">1010101010</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">IFSC Code:</td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">UTIB00000</td>
                    </tr>
                    <tr>
                    <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Swift Code:</td>
                    <td valign="top" style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">AXIS55555</td>
                    </tr>
                    <tr>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 700; padding-bottom: 5px;">Bank Address:</td>
                    <td style="font-size: 12px; color: #242424; line-height: 18px; font-weight: 600; padding-bottom: 5px;">Kolkata, West Bengal, India</td>
                    </tr>
                    </tbody>
                    </table>
                    </td> 
                    </tr>

                    <tr>
                    <td align="center" style="font-size: 8px; color: #515151; font-weight: 400; font-style: italic; padding: 10px 0px; border-top: 1px solid #CBCBCB;">If you have any questions or concerns regarding this invoice, contact via email. <b style="color: 515151;"> Thank you! </b></td>
                    </tr>
                    </tbody>
                    </table> 
                    </td>
                    <td width="30px" style="vertical-align: top;"><img src="` +Rectangle1 +`" alt="" /></td>
                    </tr>
                    </tbody>
                    </table>
                    </td>
                    </tr>





                    </tbody>
                    </table>
                                
                </html>`

      if (resvalue[27] == "Bigwave") {
        // console.log(htmldemo);
        invoice_bw += ``;
        html2pdf(invoice_bw, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Lynkme") {
        invoice_lynkme += ``;
        html2pdf(invoice_lynkme, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Reddensoft") {
        invoice_rs += ``;
        html2pdf(invoice_rs, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else {
        console.log("error");
      }
      this.tostMessage("success", "Downloaded");

      // axios.post(BASE_URL + '/api/savesalarypdf',formData)
      //     .then((response)=>{
      //         console.log("uyertfgeusyyr",response.data.salary_details);
      //     })
    },

    async showdailyreport() {
      await axios
        .post(BASE_URL + "/api/showdailyreport", {
          id: mirror.ID,
          month: this.month_name,
          year: this.picked_year,
        })
        .then((response) => {
          this.reportData = response.data.reportdetails;
          // console.log("reportData", this.reportData);
        });
    },

    toggleEdit(row) {
      this.reporttablerowtobeedited = row.id;
      this.daily_report_details.total_mail_sent = row.total_mail_sent;
      this.daily_report_details.total_positive_response =
        row.total_positive_response;
      this.daily_report_details.total_response = row.total_response;
      this.daily_report_details.total_lead = row.total_lead;
      this.daily_report_details.lead_closed = row.lead_closed;
      this.daily_report_details.date = row.date;
    },

    async updateReportTable(row) {
      console.log("daily_report_details", this.daily_report_details);
      var concatted_date = this.daily_report_details.date.split("-");
      // console.log("concatted_date",concatted_date);
      var month = concatted_date[1];
      var year = concatted_date[0];
      // this.editrow = '';
      if(this.daily_report_details.total_mail_sent<0 || this.daily_report_details.total_positive_response<0 || this.daily_report_details.total_response<0 ||this.daily_report_details.total_lead<0
        ||this.daily_report_details.lead_closed<0)
      {
        this.tostMessage("warning", "Value can not be negative");

      }
      else{
      await axios
        .post(BASE_URL + "/api/updateemreportdetails", {
          id: row.id,
          total_mail_sent: this.daily_report_details.total_mail_sent,
          total_positive_response:
            this.daily_report_details.total_positive_response,
          total_response: this.daily_report_details.total_response,
          date: this.daily_report_details.date,
          total_lead: this.daily_report_details.total_lead,
          lead_closed: this.daily_report_details.lead_closed,
          month: month,
          year: year,
        })
        .then((response) => {
          this.reporttablerowtobeedited = "";
          this.showdailyreport();
        });
      }
    },

    delName(name) {
      console.log("cat", this.categories);
      console.log("cat name", name);
      const index = this.categories.findIndex(
        (category) => category.name === name
      );
      if (index !== -1) {
        this.categories.splice(index, 1);
      }
      // return this.categories;
      console.log("this.categories", this.categories);
    },

    editName(name) {
      this.catNameedit = true;
      this.catNameToBeEdited = name;
      this.catNameEdited = name;
    },

    updateName(newName) {
      const isNameAlreadyExists = this.categories.some(
        (category) => category.name === newName
      );

      if (!isNameAlreadyExists) {
        // Find the category to edit
        const categoryToEdit = this.categories.find(
          (category) => category.name === this.catNameToBeEdited
        );

        if (categoryToEdit) {
          // Update the name of the category
          categoryToEdit.name = newName;
        } else {
          console.error("Category not found for editing.");
        }
      } else {
        console.error("New name already exists in another category.");
      }

      this.catNameToBeEdited = "";
      this.catNameEdited = "";
    },
  },
  watch: {
    dealAddProp() {
      const tableElement = document.getElementById("pills-Deals");
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
      this.dealAddProp = true;
      this.showdealdetails();
      this.showdailyreport();
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  height: 20px;
  margin: 10px 0;
  opacity: 0.4;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
