<template>
    <div class="leftside">
        <div class="logo" v-if="marketing_status != 'non_member'">
            <router-link to="/lms-emp-dashboard">
                <img src="../../assets/empdashboardimages/reddensoft-logo.png" alt="logo" />
            </router-link>
        </div>
        <div class="logo" v-else>
            <router-link to="/emp-dashboard-new">
                <img src="../../assets/empdashboardimages/reddensoft-logo.png" alt="logo" />
            </router-link>
        </div>
        <div class="menuber">
            <ul>
                <li v-if="marketing_status != 'non_member'">
                    <router-link to="/lms-emp-dashboard">
                        <img src="../../assets/empdashboardimages/home-icon.png" alt="home-icon" />
                    </router-link>
                </li>
                <li>
                    <router-link to="/emp-dashboard-new">
                        <img src="../../assets/empdashboardimages/dashboard-icon.png" alt="dashboard-icon" />
                    </router-link>
                </li>
                <!-- <li>
                    <router-link to="/lms-emp-dashboard">
                        <img src="../../assets/empdashboardimages/calender-icon.png" alt="calcender-icon" />
                    </router-link>
                </li>
                <li>
                    <router-link to="/holiday-list">
                        <img src="../../assets/empdashboardimages/holidaylist-icon.png" alt="holiday-icon" />
                    </router-link>
                </li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import { createLocal, createSession, createStorage } from "the-storages";
const mirror = createLocal();
const storage = mirror._prx;
    export default {
        name: 'EmpDashboardLeftside',
        data: function() {
            return {
                marketing_status:''
            };
        },
        async created(event) {
          this.marketing_status = mirror.marketing_status;
          console.log("this.marketing_status",this.marketing_status);
        },
    }
</script>