<template>
  <div class="main-right">
  <div class="main-title">
    <h2>Leave Status for {{new Date().getFullYear()}}</h2>
  </div>

 
  <div class="head-bar">
    <ul>
      <li>
        Number of Leaves Pending: <span> {{pending_leave}} </span>
      </li>
    </ul>
    <div class="fild-info">
      <form action="#">
        <div class="fr-gp">
          <!-- <input type="search" placeholder="Search..."> -->
          <input type="search" v-model="searchText" placeholder="Search..." @input="onSearch">
        </div>
      </form>
      <button class="dwnlod-btn"  @click="clickedleavestatusDownload()">
        <!-- <img src="@/assets/images/icon/search.svg" alt=""> -->
        download
        <img src="@/assets/images/icon/download.svg" alt="">
      </button>
      
    </div>
  </div>

  <div v-if="errormessage1 != ''" class="errormessage">
      {{ errormessage1 }}
  </div>
  <div v-if="successmessage1 != ''" class="successmessage">
      {{ successmessage1 }}
  </div>
  
  <div class="table-section" id="leavestatusdownload1">
    <div class="table-wapper">
      
      <div class="table-data table-w-100">
        
        <table cellspacing="0" cellpadding="0" border="0">
          <thead>
            <tr>
              <th>Employee Name </th>
              <th>Employee ID</th>
              <th>Leave Type</th>
              <th>Leave Duration</th>
              <th>Leave Applied On</th>
              <th>date</th>
              <th>position</th>
              <th>Status</th>
              <th>Approved By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-show="message.data !== 0" v-for="(row) in message.data" :key="row" :style="'background: ' + (row.empid == this.notifiedId && row.leave_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
            <tr>
              <td v-show="row.empname!=''">{{row.empname}}</td>
              <td>{{row.employeeid}}</td>
              <td>{{row.leave}}</td>
              <td>{{row.days == '' ? '0' : row.days}} days</td>
              <td>{{row.created_at}}</td>
              <td v-html="formatDates(row.leave_date)"></td>
              <!-- <td><div class="leave_tatus_w" v-html="HandelDate(row.leave_date)"> {{ }} </div></td> -->
              <td>{{row.position}}</td>
              <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
              <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
              <td v-if="row.leavestatus == 'Approved'"> <div class="approved_status"> <span class="status aprvd"></span> {{row.leavestatus}} </div></td>
              <td v-if="row.leavestatus == 'Pending'">--</td>
              <td v-else>{{ row.adminstatus }}</td>
              <td>
                <div class="btn-lps">
                  <a href="#" v-if="row.leavestatus =='Rejected' || row.leavestatus =='Pending'" @click="leaveapprove(row.id,row.empid,row.leave,row.days,row.leave_date,row.leavestatus)" class="gren">Approve</a>
                  <a href="#" v-if="row.leavestatus =='Approved' || row.leavestatus =='Pending'" @click="leavereject(row.id,row.empid,row.leave,row.days,row.leave_date,row.leavestatus)" class="rept">Reject</a>
                  <!-- <a href="leave-record-page.html" class="edtl" >view record</a> -->
                  <button @click="isOpen(row.id)" class="edtl">view record</button>


                </div>
              </td>
            </tr>
          </tbody>
          <!-- <tbody v-show="message.data == 0"><tr>No Data Available</tr></tbody> -->
        </table>
        <div class="no_data_div" v-show="message.data == 0">
          <p>No Data Available</p>
        </div>
      </div>

      <pagination v-model="page" :records="totalData" :per-page="50" @paginate="getResults(page,this.searchText)"/>

    </div>
</div>
</div>
                      <!-- <ModalViewLeaveStatus> -->
                        <div class="popup-modal wfhDetailsModal" id="popupModa1" v-if="seen">
                        <div class="mp-content">
                          <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal('close')">
                            <img src="@/assets/images/icon/x-lg.svg" alt="">
                          </button>
                          <div class="bdy-sect">
                            <h3>Employee Leave Application Details</h3>
                            <div class="item-cr">
                              <div class="list-sr">
                                <div class="ttx">
                                  <img src="@/assets/images/icon/person.png" alt="">
                                    Employee :
                                  </div>
                                <div class="vl">{{empname}}</div>
                              </div>
                              <div class="list-sr">
                                <div class="ttx">
                                    <img src="@/assets/images/icon/calendar-plus.png" alt="">
                                    Dates :
                                </div>
                                <div class="vl">
                                  <ul>
                                    <li>{{leavedate}}</li>
                                  </ul>
                              </div>
                              </div>
                              <div class="list-sr">
                                <div class="ttx">
                                    <img src="@/assets/images/icon/person-workspace.png" alt="">
                                    Leave Type :
                                </div>
                                <div class="vl">{{leavetype}}</div>
                                <div class="idc">
                                 {{days}}
                                </div>
                              </div>
                              <div class="list-sr">
                                " {{reason}} "
                              </div>
                              <div class="item-cr-inline">
                                <p>Document: {{docname}} </p>
                                <button @click="onClick()">Download</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  <!-- </ModalViewLeaveStatus> -->
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';

const mirror = createLocal();
const storage = mirror._prx;

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js";


import Swal from 'sweetalert2';

import ModalViewLeaveStatus from '../AdminDashboard/ModalViewLeaveStatus.vue'


export default {
name: 'LeaveStatus',
components:{
  ModalViewLeaveStatus,
  Pagination
},
setup() {
//  const isOpen = ref(false)

//  return { isOpen };

},
data() {
  return {
      page: 1,
      storageData: mirror,
      storage: storage,
      is_show: false,
      message:  {},
      searchText:"",
      nofound:false,
      errormessage: "",
      totalData:'',
      searchData:'',
      dateSearch:'',
      flag:'',
      errormessage1:"",
      leaveidapprove: '',
      leavestatusapprove: '',
      pending_leave : '',
      seen : false,
      empname : "",
      leavedate : "",
      leavetype : "",
      days : "",
      reason : "",
      docname : "",
      docurl : "",
      leavestatus : "",
      notifiedType:'',
      notifiedId:'',
      notifiedDate:'',
      admin_status:''
  }
},
methods :{

  formatDates(dates) {
    // Split the dates by comma
    const dateArray = dates.split(',');
    
    // Return each date wrapped in a <div> tag
    return dateArray.map(date => `<div class="td-width-control">${date.trim()}</div>`).join('');
  },

  async hidePagination() {
    // Hide the pagination component
    var pagination = document.querySelector('.pagination');
    if (pagination) {
        pagination.style.display = 'none';
        await this.$nextTick();
    }
},

async showPagination() {
    // Show the pagination component
    var pagination = document.querySelector('.pagination');
    if (pagination) {
        pagination.style.display = 'block';
        await this.$nextTick();
    }
},

// async clickedleavestatusDownload(){
//     // Hide the pagination component
//     await this.hidePagination();

//     // Get the table element
//     var element = document.getElementById('leavestatusdownload1');
//     var width = element.offsetWidth;

//     // Make sure all rows are rendered by scrolling to the bottom of the table
//     await this.scrollToBottom(element);

//     // Take a screenshot and download the PDF
//     html2canvas(element).then(canvas => {
//         var pdf = new jsPDF("1", "mm", "a1");
//         var height = canvas.height * width / canvas.width;
//         var image = canvas.toDataURL('image/jpeg', 1.0);
//         pdf.addImage(image, 'JPEG', 0, 0, width, height);
//         pdf.save('LeaveStatus' + '.pdf');

//         // Show the pagination component again after downloading the PDF
//         this.showPagination();
//     });
// },

async clickedleavestatusDownload() {
  var data = this.message.data;
  try {
    if (!data || data.length === 0) {
      throw new Error('Data is empty or undefined.');
    }

    // Generate HTML content for the table
    const tableHtml = this.generateTableHTMLWithPageBreaks(data);

    // Define PDF generation options
    const opt = {
      margin: 0.1,
      filename: 'EmployeeLeaveStatus.pdf',
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      html2canvas: { scale: 1.1 },
      pagebreak: { avoid: '.downloadable-row' }
    };

    // Generate the PDF
    await html2pdf().set(opt).from(tableHtml).save();
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
},

generateTableHTMLWithPageBreaks(data) {
  // Create a temporary <div> element to build the table HTML
  const tempDiv = document.createElement('div');

  // Add table HTML content with page breaks and CSS classes for spacing
  if (data && data.length > 0) {
    tempDiv.innerHTML = `
      <style>
        .table-section {
          width: 100%;
        }
        .table-wrapper {
          margin: 0 auto;
          padding: 10px;
        }
        .table-data {
          width: 100%;
          border-collapse: collapse;
        }
        .table-data th, .table-data td {
          padding: 8px 12px;
          text-align: left;
          border: 1px solid #ddd;
        }
        .table-data th {
          background-color: #f2f2f2;
        }
        .downloadable-row td {
          word-wrap: break-word;
          white-space: pre-line;
        }
      </style>
      <div class="table-section">
        <div class="table-wrapper">
          <table cellspacing="0" cellpadding="0" border="0" class="table-data print-table">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Type</th>
                <th>Dates</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              ${data.map(row => `
                <tr class="downloadable-row">
                  <td>${row.empname}</td>
                  <td>${row.leave}</td>
                  <td>${row.leave_date.split(',').join('<br>')}</td>
                  <td>${row.leavestatus}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      </div>
    `;
  } else {
    console.warn('No data provided to generate table HTML.');
  }

  // Log the generated HTML to the console
  console.log('Generated HTML:', tempDiv.innerHTML);

  // Return the temporary <div> element
  return tempDiv;
},



async scrollToBottom(element) {
    // Scroll to the bottom of the table
    element.scrollTop = element.scrollHeight;
},




 async isOpen(event){
   //console.log(event);
   this.seen = true;

   const getid = {
      id : event
   }; 
   //console.log(getid);


      await axios.post(BASE_URL + '/api/getmodalleavestatus',getid)
              .then((response)=>{
                  //console.log(response.data.record.empname);

                  this.empname = response.data.empname;
                  this.leavedate = response.data.leavedate;
                  this.leavetype = response.data.leavetype;
                  this.days = response.data.days;
                  this.reason = response.data.reason;
                  this.docname = response.data.docname;
                  this.docurl = response.data.docurl;
                  this.leavestatus = response.data.leavestatus;

          })

 },
  onClick() {
    //console.log(this.docurl);

      if(this.docurl === ''){
              Swal.fire({
                          title: 'OPPS',
                          text:  'No Data Available',
                          icon: 'warning',
                      
                      });
        }else{

              axios({
                      url: this.docurl,
                      method: 'GET',
                      responseType: 'blob',
                  }).then((response) => {
                      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                      var fileLink = document.createElement('a');
  
                      fileLink.href = fileURL;

                      if(response.data.type == 'image/jpeg'){
                          fileLink.setAttribute('download', 'file.jpeg');
                      }else if(response.data.type == 'image/jpg'){
                          fileLink.setAttribute('download', 'file.jpg');
                      }else if(response.data.type == 'image/png'){
                          fileLink.setAttribute('download', 'file.png');
                      }else if(response.data.type == 'application/pdf'){
                          fileLink.setAttribute('download', 'file.pdf');
                      }else if(response.data.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                          fileLink.setAttribute('download', 'file.docx');
                      }else{
                          fileLink.setAttribute('download', 'file.txt');
                      }

                      document.body.appendChild(fileLink);
  
                      fileLink.click();
                  });

        }

  },
  closemodal(event){
    this.seen = false;
  },
 onSearch(){
     this.getResults(this.page,this.searchText);
 },
  getResults(page,searchText='') {
      if (typeof page === "undefined") {
          page = 1;
      }
      if(searchText=='') {
          this.flag = 'list';
      }
      if(searchText!=''){
          this.flag = 'search';
          this.searchText = searchText;
      }
      
      const data ={
          userId: this.ID,
          flag: this.flag,
          searchText:this.searchText,

      }
      //console.log(data);
      
      axios.post(BASE_URL + `/api/getallLeaveData?page=${page}`,data)
          .then((response)=>{
          //console.log(response.data);

          this.message = response.data;
          this.totalData =  response.data.total;
          this.is_show= (this.message.last_page > 1)?true:false;

          if(this.message.data?.length>0){
              this.nofound=false;
          }else{
              this.nofound=true;
          }

      })
  },
  async leaveapprove(id,empid,leave,days,leave_date,leavestatus){

    if(confirm("Are you sure you want to approve this?")){
            const getempid = {
                      empleaveid : id,                                                                                                                                                                                                                                                                                                                                                                                                                         
                      empid: empid,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                      leave: leave,
                      days: days,
                      leave_date : leave_date,
                      leavestatus: leavestatus,
                      admin_status: this.admin_status
                  };

                  //console.log(getempid);

                  await axios.post(BASE_URL + '/api/getempid',getempid)
                          .then((response)=>{
                              //console.log(response.data);

                              if(response.data.success == true){
                                  const res= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: response.data.userId, date: response.data.alldate, type: response.data.leavetype, status:response.data.leavestatus, seenstatus: 'unseen'});
                                  axios.post(BASE_URL + '/api/sendapproveleave',{
                                  email: response.data.empemail.email,
                                  fname:  response.data.fname.fname,
                                  lname:  response.data.lname.lname,
                                  alldateleaves: response.data.alldate,
                                  daysleave : response.data.daysleave,
                                  leavetype : response.data.leavetype,
                                  leavestatus : response.data.leavestatus,
                              });
                                  //console.log(response.data);

                                  Swal.fire({
                                              title: 'Done',
                                              text:  response.data.message,
                                              icon: 'success',
                                          
                                          });

                                  this.getResults();
                                  this.gettotalpendingleave();

                                  this.leaveidapprove = response.data.leaveid;
                                  this.leavestatusapprove = response.data.leavestatus;
                                    

                              }else{

                                  Swal.fire({
                                          title: 'OPPS',
                                          text:   response.data.message,
                                          icon: 'warning',
                                      
                                      });

                                  this.leaveidapprove = '';
                                  this.leavestatusapprove = '';
                                  
                              }

                      })
    }else{
      return false;
    }

  },
  async leavereject(id,empid,leave,days,leave_date,leavestatus){

    if(confirm("Are you sure you want to reject this?")){

          const getempid = {
                empleaveid : id,                                                                                                                                                                                                                                                                                                                                                                                                                         
                empid: empid,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                leave: leave,
                days: days,
                leave_date : leave_date,
                leavestatus: leavestatus
            };

            //console.log(getempid);
                await axios.post(BASE_URL + '/api/getempidreject',getempid)
                    .then((response)=>{
                        //console.log(response.data);

                        if(response.data.success == true){
                          axios.post(BASE_URL + '/api/sendrejectleave',{
                          email: response.data.empemail.email,
                          fname:  response.data.fname.fname,
                          lname:  response.data.lname.lname,
                          alldateleaves: response.data.alldate,
                          daysleave : response.data.daysleave,
                          leavetype : response.data.leavetype,
                          leavestatus : response.data.leavestatus,
                      });
                          const res= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: response.data.userId, date: response.data.alldate, type: response.data.leavetype, status:response.data.leavestatus, seenstatus: 'unseen'});

                            Swal.fire({
                                        title: 'Done',
                                        text:  response.data.message,
                                        icon: 'success',
                                    
                                    });

                            this.getResults();
                            this.gettotalpendingleave();

                        }else{
                            Swal.fire({
                                    title: 'OPPS',
                                    text:   response.data.message,
                                    icon: 'warning',
                                
                                });
                        }

                })

    }else{
      return false;
    }
      
  },
  async gettotalpendingleave(){

      await axios.post(BASE_URL + '/api/gettotalpendingleave')
                .then((response)=>{
                    //console.log(response.data.pending_leave.count);

                    if(response.data.success == true){
                        this.pending_leave = response.data.pending_leave.count;

                    }

            })

  }
  

},
mounted(){
  this.getResults();
  this.gettotalpendingleave();
},
async created(){
this.userName = mirror.USERNAME
this.userEmail = mirror.USEREMAIL;
this.admin_status = mirror.ADMIN_STATUS;
console.log("admin_status",this.admin_status);


this.ID = mirror.AUTHID;
var hash = window.location.hash.split(/,(.*)/s);
this.notifiedId = hash[0].split("#")[1];
this.notifiedDate = hash[1];
console.log("hash",hash);

},
watch: {
   '$route.query'() { 
    //console.log('querydata',newId)
    // this.url_res = this.$route.params.res;
    // this.getdata.url_res = this.url_res;
    var hash = window.location.hash.split(",");
    this.notifiedId = hash[0].split("#")[1];
    this.notifiedDate = hash[1];
    console.log("hash14",hash);
    // this.getsearchresult(this.getdata);
    // setInterval(function () {window.location.hash =''}, 5000);
  }
}
}
</script>

<style scoped>
.print-table th, .print-table td {
  padding: 8px 12px; /* Adjust padding as needed */
  text-align: left;
  border: 1px solid #ddd; /* Optional: Add borders for better visibility */
}

.print-table th {
  background-color: #f2f2f2; /* Optional: Add background color to header */
}

.print-table {
  border-collapse: collapse; /* Ensure borders do not collapse */
  width: 100%;
}


.downloadable-row {
  page-break-inside: avoid;
}

</style>