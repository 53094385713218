<template>
  <div class="dashboard_box">
    <EmployeLeftbar />
    <div class="dashboard_right">
      <EmployeHeader />
      <div class="dashboard_body">
        <div class="main_box">
          <div class="table_box month_data">
            <div
              class="month_data_top d-flex justify-content-between align-items-center"
            >
              <div class="heading">
                <h2>All Contract For This Month</h2>
              </div>
              <div class="search_picker d-flex align-items-center">
                <div class="month_picker_box">
                  <month-picker-input
                    placeholder="Select month year"
                    @change="showDate"
                    :no-default="true"
                  ></month-picker-input>
                </div>
                <!-- <div class="picker">
                                    <button class="stl-btn modal_caldn_btn" id="modal-tggl1" @click="isOpen()">
                                        <img src="@/assets/images/icon/calendar-plus.png" alt=""> Select a month 
                                    </button>
                                </div>
                                <div class="popup-modal" id="popupModa1" v-if="seen">
                                        <div class="mp-content">
                                        <button class="modalOut" type="button" id="modalOutIn1" @click="closeDatepicker('close')">
                                            <img src="@/assets/images/icon/x-lg.svg" alt="">
                                        </button>
                                        <div class="bdy-sect">
                                        <h3>Select a month</h3>
                                            <month-picker @change="showDate"  no-default></month-picker>
                                        </div>
                                    </div>
                                </div> -->
                <div class="search">
                  <input
                    type="text"
                    v-model="searchQuery"
                    placeholder="Search by member name"
                  />
                </div>
              </div>
            </div>
            <div class="table_div">
              <table>
                <thead>
                  <tr>
                    <th>Member Name</th>
                    <th>Client Name</th>
                    <th>Amount</th>
                    <th>Deal Status</th>
                    <th>Payment Status</th>
                    <th>Payment Method</th>
                    <th>Beneficiary Details</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, index) in monthData" :key="index">
                    <td>{{ transaction.empname }}</td>
                    <td>{{ transaction.client }}</td>
                    <td>
                      <input
                        v-if="
                          transaction.id == rowtobeedited_id &&
                          showeditedrow == true
                        "
                        v-model="transaction.amount"
                        type="number"
                        min="0"
                      />
                      <span v-else>{{ transaction.amount }}</span>
                    </td>
                    <!-- <td>{{ transaction.payment_status }}</td> -->
                    <td>
                      <select
                        v-if="
                          transaction.id == rowtobeedited_id &&
                          showeditedrow == true
                        "
                        v-model="transaction.payment_status"
                      >
                        <option value="Pending">Pending</option>
                        <option value="Closed">Closed</option>
                        <option value="Failed">Failed</option>
                      </select>
                      <span v-else>{{ transaction.payment_status }}</span>
                      <!-- <div class="modal_background" v-if="openiopopup == true"> -->
                      <div
                        class="modal_background"
                        v-if="
                          openiopopup == true &&
                          transaction.payment_status == 'Closed'
                        "
                      >
                        <div class="modal_content">
                          <div class="modal_box">
                            <div class="modal_heading">
                              <h2>Insertion Order</h2>
                              <button @click="closeiopopup()">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  <path
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div class="modal_scroll">
                              <div class="modal_name">
                                <h3>Advertiser Details</h3>
                              </div>
                              <div class="d-flex">
                                <div class="col modal_input">
                                  <label>Company Name :</label>
                                  <!-- <input type="text" placeholder="Enter company name" disabled v-model="io.company_name"/> -->
                                  <p class="modal_labelp">
                                    {{ io.company_name }}
                                  </p>
                                </div>
                                <div class="col modal_input">
                                  <label>Address :</label>
                                  <!-- <input type="text" placeholder="Enter company address" v-model="io.company_address"/> -->
                                  <p class="modal_labelp">
                                    {{ io.company_address }}
                                  </p>
                                </div>
                              </div>
                              <div class="modal_name">
                                <h3>Contact person</h3>
                              </div>
                              <div class="d-flex flex-wrap">
                                <div class="col-4 modal_input">
                                  <label>Name :</label>
                                  <!-- <input type="text" placeholder="Enter name" v-model="io.contact_person_name"/> -->
                                  <p class="modal_labelp">
                                    {{ io.contact_person_name }}
                                  </p>
                                </div>
                                <div class="col-4 modal_input">
                                  <label>Registration number :</label>
                                  <!-- <input type="text" placeholder="Enter registration number" v-model="io.contact_person_reg_no"/> -->
                                  <p class="modal_labelp">
                                    {{ io.contact_person_reg_no }}
                                  </p>
                                </div>
                                <div class="col-4 modal_input">
                                  <label>Email :</label>
                                  <!-- <input type="text" placeholder="Enter company email" v-model="io.contact_person_email"/> -->
                                  <p class="modal_labelp">
                                    {{ io.contact_person_email }}
                                  </p>
                                </div>
                              </div>
                              <div class="modal_name">
                                <h3>Campaign Details</h3>
                              </div>
                              <div class="modal_campaign">
                                <div class="row">
                                  <div class="col details_label">
                                    <label>Site / Application</label>
                                    <!-- <input type="text" placeholder="Enter site" v-model="io.web_app_name"/> -->
                                    <p class="modal_labelp">
                                      {{ io.web_app_name }}
                                    </p>
                                  </div>
                                  <div class="col details_label">
                                    <label>Start Date</label>
                                    <!-- <input type="date" placeholder="Enter start date" v-model="io.start_date"/> -->
                                    <p class="modal_labelp">
                                      {{ io.start_date }}
                                    </p>
                                  </div>
                                  <div class="col details_label">
                                    <label>End Date</label>
                                    <!-- <input type="date" placeholder="Enter end date" v-model="io.end_date"/> -->
                                    <p class="modal_labelp">
                                      {{ io.end_date }}
                                    </p>
                                  </div>
                                  <div class="col details_label">
                                    <label>Placement</label>
                                    <!-- <input type="text" placeholder="Enter placement" v-model="io.placemnet"/> -->
                                    <p class="modal_labelp">
                                      {{ io.placemnet }}
                                    </p>
                                  </div>
                                  <div class="col details_label">
                                    <label>Total Budget</label>
                                    <!-- <input type="number" placeholder="Enter total budget" v-model="io.total_budget"/> -->
                                    <p class="modal_labelp">
                                      {{ io.total_budget }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="modal_name">
                                <h3>Campaign Type</h3>
                              </div>
                              <div
                                class="campaing_select"
                                v-if="campaign_categories == null"
                              >
                                <div class="col-6">
                                  <div class="input_box">
                                    <input
                                      type="text"
                                      placeholder="Enter category"
                                      v-model="newCategory.name"
                                    />
                                    <button type="submit" @click="addName">
                                      Add
                                    </button>
                                  </div>
                                </div>
                                <div
                                  v-for="(category, index) in categories"
                                  :key="index"
                                  class="soloemail_details"
                                >
                                  <div class="modal_name">
                                    <h3>{{ category.name }}</h3>
                                  </div>
                                  <div class="modal_campaign">
                                    <div class="row">
                                      <div class="col details_label">
                                        <label>Model</label>
                                        <input
                                          type="text"
                                          placeholder="Enter model"
                                          v-model="category.model"
                                        />
                                      </div>
                                      <div class="col details_label">
                                        <label>Rate</label>
                                        <input
                                          type="text"
                                          placeholder="Enter rate"
                                          v-model="category.rate"
                                        />
                                      </div>
                                      <div class="col details_label">
                                        <label>Quantity</label>
                                        <input
                                          type="text"
                                          placeholder="Enter quantity"
                                          v-model="category.quantity"
                                        />
                                      </div>
                                      <div class="col details_label">
                                        <label>Total</label>
                                        <input
                                          type="text"
                                          placeholder="Enter total"
                                          v-model="category.total"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="campaing_select" v-else>
                                <!-- <div class="campaing_select" v-if="campaign_categories != null"> -->
                                <div class="email_template_tables">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>PRICE</th>
                                        <th>TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          row, index
                                        ) in campaign_categories"
                                        :key="index"
                                      >
                                        <td>
                                          {{ row.name }}
                                        </td>
                                        <td>
                                          {{ row.quantity }}
                                        </td>
                                        <td>
                                          {{ row.rate }}
                                        </td>
                                        <td>
                                          {{ row.total }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div class="table_total">
                                    <ul>
                                      <li>TOTAL</li>
                                      <li>
                                        <span class="green"
                                          >${{ totalAmount }}</span
                                        >
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="modal_button">
                              <!-- <button @click="generateIO(io, transaction.id)">
                                                                Generate
                                                            </button> -->
                              <button
                                v-if="campaign_categories != null"
                                @click="exportToPDFInvoice(transaction)"
                              >
                                Download
                              </button>
                            </div>
                            <!-- <div class="modal_button" v-if="transaction.io_status == '1'">
                                                            <button @click="exportToPDFInvoice(transaction.id)">
                                                                Download
                                                            </button>
                                                        </div> -->
                          </div>
                        </div>
                      </div>

                      <div
                        class="modal_background"
                        v-if="
                          openiopopup1 == true &&
                          transaction.payment_status == 'Closed'
                        "
                      >
                        <div class="modal_content">
                          <div class="modal_box">
                            <div class="modal_heading">
                              <h2>Invoice</h2>
                              <button @click="closeiopopup1()">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  <path
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div class="modal_scroll">
                              <div class="modal_name">
                                <h3>Advertiser Details</h3>
                              </div>
                              <div class="d-flex">
                                <div class="col modal_input">
                                  <label>Company Name :</label>
                                  <!-- <input type="text" placeholder="Enter company name" v-model="invoice.cName"/> -->
                                  <p class="modal_labelp">
                                    {{ invoice.cName }}
                                  </p>
                                </div>
                                <div class="col modal_input">
                                  <label>Address :</label>
                                  <!-- <input type="text" placeholder="Enter company address" v-model="invoice.cAddress"/> -->
                                  <p class="modal_labelp">
                                    {{ invoice.cAddress }}
                                  </p>
                                </div>
                              </div>
                              <div class="modal_name">
                                <h3>Contact person</h3>
                              </div>
                              <div class="d-flex flex-wrap">
                                <div class="col-4 modal_input">
                                  <label>Name :</label>
                                  <!-- <input type="text" placeholder="Enter name" v-model="invoice.contactName"/> -->
                                  <p class="modal_labelp">
                                    {{ invoice.contactName }}
                                  </p>
                                </div>
                                <div class="col-4 modal_input">
                                  <label>Registration number :</label>
                                  <!-- <input type="text" placeholder="Enter registration number" v-model="invoice.regNo"/> -->
                                  <p class="modal_labelp">
                                    {{ invoice.regNo }}
                                  </p>
                                </div>
                                <div class="col-4 modal_input">
                                  <label>Email :</label>
                                  <!-- <input type="text" placeholder="Enter company email" v-model="invoice.contactEmail"/> -->
                                  <p class="modal_labelp">
                                    {{ invoice.contactEmail }}
                                  </p>
                                </div>
                              </div>
                              <!-- <div class="modal_name">
                                                                <h3>Campaign Details</h3>
                                                            </div>
                                                            <div class="modal_campaign">
                                                                <div class="row">
                                                                    <div class="col details_label">
                                                                        <label>Site / Application</label>
                                                                        <input type="text" placeholder="Enter site"/>
                                                                    </div>
                                                                    <div class="col details_label">
                                                                        <label>Start Date</label>
                                                                        <input type="date" placeholder="Enter start date"/>
                                                                    </div>
                                                                    <div class="col details_label">
                                                                        <label>End Date</label>
                                                                        <input type="date" placeholder="Enter end date"/>
                                                                    </div>
                                                                    <div class="col details_label">
                                                                        <label>Placement</label>
                                                                        <input type="text" placeholder="Enter placement"/>
                                                                    </div>
                                                                    <div class="col details_label">
                                                                        <label>Total Budget</label>
                                                                        <input type="number" placeholder="Enter total budget"/>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                              <div class="modal_name">
                                <h3>Campaign Type</h3>
                              </div>
                              <div class="campaing_select">
                                <div class="email_template_tables">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>PRICE</th>
                                        <th>TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          row, index
                                        ) in campaign_data_invoice"
                                        :key="index"
                                      >
                                        <td>
                                          {{ row.name }}
                                        </td>
                                        <td>
                                          {{ row.quantity }}
                                        </td>
                                        <td>
                                          {{ row.rate }}
                                        </td>
                                        <td>
                                          {{ row.total }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div class="table_total">
                                    <ul>
                                      <li>TOTAL</li>
                                      <li>
                                        <span class="green"
                                          >${{ totalInvoiceAmount }}</span
                                        >
                                      </li>
                                      <!-- <li><span class="green">${{totalInvoiceAmount}}</span></li> -->
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="modal_button">
                              <button @click="exportToPDFInvoice1()">
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td v-if="transaction.payment_status == 'Closed'">
                      <select
                        v-if="
                          transaction.id == rowtobeedited_id &&
                          showeditedrow == true
                        "
                        v-model="transaction.payment_completion_status"
                      >
                        <option value="Pending">Pending</option>
                        <option value="Paid">Paid</option>
                      </select>
                      <span v-else>{{
                        transaction.payment_completion_status
                      }}</span>
                    </td>
                    <td v-else>NA</td>
                    <td v-if="transaction.payment_completion_status == 'Paid'">
                      <select
                        v-if="
                          transaction.id == rowtobeedited_id &&
                          showeditedrow == true
                        "
                        v-model="transaction.payment_method"
                      >
                        <!-- <select class="addNewInput" v-model="transaction.payment_method" placeholder="Select"> -->
                        <option :value="blood" disabled>Select One</option>
                        <option value="Banking">Bank Transfer</option>
                        <option value="Crypto">Crypto</option>
                        <option value="Paypal">Paypal</option>
                      </select>
                      <!-- </div> -->
                      <div v-else>
                        {{ transaction.payment_method }}
                      </div>
                    </td>
                    <td v-else>NA</td>
                    <td>
                      <select
                        v-if="
                          transaction.id == rowtobeedited_id &&
                          showeditedrow == true
                        "
                        v-model="transaction.beneficiary"
                      >
                        <!-- <select class="addNewInput" v-model="transaction.payment_method" placeholder="Select"> -->
                        <option :value="blood" disabled>Select One</option>
                        <option value="Reddensoft">Reddensoft</option>
                        <option value="Lynkme">Lynkme 360</option>
                        <option value="Bigwave">Bigwave</option>
                      </select>
                      <!-- </div> -->
                      <div v-else>
                        {{ transaction.beneficiary }}
                      </div>
                    </td>
                    <!-- <td v-else>NA</td> -->
                    <td>
                      <div class="date_div">
                        {{ transaction.date }}
                      </div>
                    </td>
                    <td>
                      <div class="table_flex d-flex">
                        <button
                          v-if="
                            transaction.id == rowtobeedited_id &&
                            showeditedrow == true
                          "
                          @click="
                            updateDetails(
                              transaction.id,
                              transaction.amount,
                              transaction.payment_status,
                              transaction.payment_completion_status,
                              transaction.payment_method,
                              transaction.beneficiary
                            )
                          "
                          class="edtl btn-lps"
                        >
                          Update
                        </button>
                        <button
                          v-else
                          @click="editDetails(transaction.id)"
                          class="edtl btn-lps"
                        >
                          Edit
                        </button>
                        <button
                          class="edtl btn-lps"
                          @click="
                            openiopopupBtn(
                              transaction.id,
                              transaction.payment_status,
                              transaction.beneficiary
                            )
                          "
                        >
                          IO
                        </button>
                        <button
                          class="btn-lps"
                          @click="
                            openiopopupBtn1(
                              transaction.id,
                              transaction.payment_status,
                              transaction.beneficiary
                            )
                          "
                        >
                          Invoice
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table_total">
              <ul>
                <li>TOTAL</li>
                <li></li>
                <li>
                  Sales:
                  <span v-if="TeamTotalAmount > 0">${{ TeamTotalAmount }}</span>
                  <span v-else>$0</span>
                </li>
                <li>
                  Received:
                  <span class="green" v-if="closedTeamTotalAmount > 0"
                    >${{ closedTeamTotalAmount }}</span
                  >
                  <span class="green" v-else>$0</span>
                </li>
                <li></li>
                <li>
                  Due:
                  <span class="red" v-if="pendingTeamTotalAmount > 0"
                    >${{ pendingTeamTotalAmount }}</span
                  >
                  <span class="red" v-else>$0</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
// import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from "vue-month-picker";

import "../assets/css/dashboard.css";
import EmployeHeader from "@/components/EmployeDashboard/EmployeHeader.vue";
import EmployeLeftbar from "@/components/EmployeDashboard/EmployeLeftbar.vue";
import { createLocal, createSession, createStorage } from "the-storages";
import { BASE_URL } from "../config";
import axios from "axios";
import { reactive } from "vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
const mirror = createLocal();
const storage = mirror._prx;
import html2pdf from "html2pdf.js";

export default {
  name: "MonthDate",
  components: {
    //   MonthPicker,
    MonthPickerInput,
    EmployeHeader,
    EmployeLeftbar,
  },
  setup() {
    const row = reactive({
      amount: "",
      payment_status: "",
    });
    const isOpen = ref(false);
    //   return { isOpen };

    const io = reactive({
      company_name: "",
      company_address: "",
      contact_person_name: "",
      contact_person_reg_no: "",
      contact_person_email: "",
      web_app_name: "",
      start_date: "",
      end_date: "",
      placemnet: "",
      total_budget: "",
      solo_email_model: "",
      solo_email_rate: "",
      solo_email_quantity: "",
      solo_email_total: "",
      email_template_model: "",
      email_template_rate: "",
      email_template_quantity: "",
      email_template_total: "",
    });

    const invoice = reactive({
      id: "",
      cName: "",
      cAddress: "",
      contactName: "",
      contactEmail: "",
      regNo: "",
    });

    const leavedate = ref();
    //return { v$: useVuelidate() };
    return {
      // leavedate,
      io,
      invoice,
    };
  },
  data() {
    return {
      isClassAdded: false,
      isClassAdded1: false,
      isClassAdded2: false,
      clockHide: true,
      buttonBackground: "#3cb89b",
      currentTime: "00:00:00",
      buttonText: "Check In",
      checkInTime: null,
      checkOutTime: null,
      showPopup: true,
      monthData: [],
      TeamTotalAmount: 0,
      closedTeamTotalAmount: 0,
      pendingTeamTotalAmount: 0,
      rowtobeedited_id: "",
      showeditedrow: false,
      searchQuery: null,

      seen: false,
      monthlytarget: "",
      month_name: "",
      picked_year: "",
      monthlyTargets: [],
      monthAbbreviations: {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },

      monthlyTeamTargets: [],
      monthlyTargetsAchieved: [],
      monthlyTeamTargetsAchieved: [],
      month_proper_name: "",
      pastDataArray: [],
      pastTeamDataArray: [],

      // modal data

      newTransaction: {
        clientName: "",
        amount: "",
        paymentStatus: "",
        date: "",
        invoice: "",
      },

      monthIndex: "",
      openiopopup: false,
      openiopopup1: false,
      invoiceid: "",
      invoiceid1: "",
      campaign_categories: "",
      newCategory: {
        name: "",
        model: "",
        rate: "",
        quantity: "",
        total: "",
      },
      categories: [],
      pdfdata: "",
      io_status: "",
      invoice_status: "",
      campaign_data_invoice: [],
    };
  },

  async created(event) {
    const date = new Date();
    this.picked_year = date.getFullYear();
    this.month_name = date.getMonth() + 1;
    const month_name_array = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month_proper_name = month_name_array[date.getMonth()];

    this.getMonthData();
  },
  // computed: {
  //     totalAmount() {
  //     return this.campaign_categories.reduce((total, row) => total + parseFloat(row.total), 0).toFixed(2);
  //     },
  //     totalInvoiceAmount() {
  //     console.log("computed");
  //     // return 100;
  //     return this.campaign_data_invoice.reduce((total, row) => total + parseFloat(row.total), 0).toFixed(2);
  //     }
  // },

  methods: {
    addName() {
      if (this.newCategory.name.trim() !== "") {
        this.categories.push({ ...this.newCategory });
        this.newCategory = {
          name: "",
          model: "",
          rate: "",
          quantity: "",
          total: "",
        };
      }
    },

    async openiopopupBtn(id, status, beneficiary) {
      // this.openiopopup = true;
      this.invoiceid = id;

      await axios
        .post(BASE_URL + "/api/getinvoicedataEmp", {
          id: id,
        })
        .then((response) => {
          console.log("ressssss", response.data);
          this.io.company_name = response.data.iodetails.company_name;
          this.io.company_address = response.data.iodetails.contact_person_name;
          this.io.contact_person_name = response.data.iodetails.company_name;
          this.io.contact_person_reg_no =
            response.data.iodetails.contact_person_reg_no;
          this.io.contact_person_email =
            response.data.iodetails.contact_person_email;
          this.io.web_app_name = response.data.iodetails.web_app_name;
          this.io.start_date = response.data.iodetails.start_date;
          this.io.end_date = response.data.iodetails.end_date;
          this.io.placemnet = response.data.iodetails.placemnet;
          this.io.total_budget = response.data.iodetails.total_budget;
          this.io_status = response.data.iodetails.io_status;
          this.campaign_categories = response.data.iocampaigndetails;
          console.log("cat", this.io_status);
        });

      if (beneficiary == null) {
        this.tostMessage("warning", "Please add benificiary details first");
      } else if (this.io_status == "1") {
        this.openiopopup = true;
        // this.deal_status = status
      } else if (status == "Closed") {
        this.tostMessage(
          "warning",
          "IO deatils has not been updated for this deal"
        );
      } else {
        this.tostMessage("warning", "This deal is not close yet");
      }
    },
    closeiopopup() {
      this.openiopopup = false;
    },
    toggleClass() {
      this.isClassAdded = !this.isClassAdded;
    },
    toggleClass1() {
      this.isClassAdded1 = !this.isClassAdded1;
    },
    toggleClass2() {
      this.isClassAdded2 = !this.isClassAdded2;
    },

    toggleClock() {
      if (this.clockHide) {
        // Check In
        this.checkInTime = new Date();
        this.timer = setInterval(() => {
          // Update currentTime every second
          this.currentTime = this.formatTime(new Date() - this.checkInTime);
        }, 1000);
      } else {
        // Check Out
        this.checkOutTime = new Date();
        clearInterval(this.timer);
        this.currentTime = "00:00:00"; // Reset timer display
      }

      this.clockHide = !this.clockHide;
      this.buttonBackground =
        this.buttonBackground === "#3cb89b" ? "#e95b6d" : "#3cb89b";
      this.buttonText = this.clockHide ? "Check In" : "Check Out";
    },
    formatTime(ms) {
      // Format milliseconds to HH:mm:ss
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / (1000 * 60)) % 60);
      const hours = Math.floor(ms / (1000 * 60 * 60));

      const formattedTime = `${this.padZero(hours)}:${this.padZero(
        minutes
      )}:${this.padZero(seconds)}`;
      return formattedTime;
    },
    padZero(value) {
      // Add leading zero if needed
      return value < 10 ? `0${value}` : value;
    },
    closePopup() {
      this.showPopup = false;
    },

    tostMessage(status, message) {
      console.log("status", status);
      createToast(
        {
          // title: title,
          description: message,
        },
        {
          type: status,
          timeout: 2500,
          position: "bottom-right",
          showIcon: "true",
        }
      );
    },

    async fetchData() {
      await axios
        .post(BASE_URL + "/api/fetchEMdealEmp", {
          month: this.month_name,
          year: this.picked_year,
          id: mirror.ID,
        })
        .then((response) => {
          this.userdata = response.data.dealList;
        });
    },

    async getMonthData() {
      await axios
        .post(BASE_URL + "/api/getMonthData", {
          month: this.month_name,
          year: this.picked_year,
          id: mirror.ID,
          status: mirror.marketing_status,
        })
        .then((response) => {
          this.monthData = response.data.monthDataList;
          this.TeamTotalAmount = response.data.TeamTotalAmount;
          this.closedTeamTotalAmount = response.data.closedTeamTotalAmount;
          this.pendingTeamTotalAmount = response.data.pendingTeamTotalAmount;
          // console.log('222',this.TeamTotalAmount);
        });
    },

    async showDate(date) {
      this.month_name = date.monthIndex;
      this.month_proper_name = date.month;
      this.picked_year = date.year;
      this.getMonthData();
      console.log("date m", this.month_name);

      setTimeout(() => {
        this.seen = false;
      }, 500);
    },
    async editDetails(id) {
      this.rowtobeedited_id = id;
      this.showeditedrow = true;
    },
    async updateDetails(
      id,
      amount,
      payment_status,
      payment_completion_status,
      payment_method,
      beneficiary
    ) {
      if (payment_completion_status == "Paid") {
        if (payment_method == null) {
          payment_completion_status == "Pending";
          this.tostMessage("warning", "Please add payment method first!");
          // this.getMonthData();
        } else if (beneficiary == null) {
          payment_completion_status == "Pending";
          this.tostMessage("warning", "Please add beenficiary details first!");
          // this.getMonthData();
        } else {
          this.showeditedrow = false;
          console.log("row.amount", amount);
          console.log("row.payment_status", payment_status);
          await axios
            .post(BASE_URL + "/api/updateMonthData", {
              id: mirror.ID,
              amount: amount,
              status: payment_status,
              payment_status: payment_completion_status,
              payment_method: payment_method,
              deal_id: id,
              beneficiary: beneficiary,
            })
            .then((response) => {
              this.tostMessage(response.data.success, response.data.message);
            });
          this.getMonthData();
        }
      } else {
        this.showeditedrow = false;
        console.log("row.amount", amount);
        console.log("row.payment_status", payment_status);
        await axios
          .post(BASE_URL + "/api/updateMonthData", {
            id: mirror.ID,
            amount: amount,
            status: payment_status,
            payment_status: payment_completion_status,
            payment_method: payment_method,
            deal_id: id,
            beneficiary: beneficiary,
          })
          .then((response) => {
            this.tostMessage(response.data.success, response.data.message);
          });
        this.getMonthData();
      }
    },

    closeDatepicker(event) {
      this.seen = false;
    },

    isOpen() {
      this.seen = true;
    },

    async htmltopdf(res, logo) {
      console.log("res", res);
      const resvalue = Object.values(res);
      console.log("resvalue", resvalue);
      const items = JSON.parse(resvalue[20]);

      var htmldemo =
        `
                    <style></style>
                    <div style="width:100%; padding:20px " id="pdfFormat">
                    
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                            Insertion Order
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Reddensoft Infotech PVT LTD</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Amp Mall</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[12]
                                                        }</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[13]
                                                        }</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[14]
                                                        }</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">big Wave Development LLC</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Ricky Bell</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 (727) 217-5552</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Ricky@bigwavedevelopment.com</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <thead>
                                                <tr>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                    </th>
                                                    <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[15]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[16]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[17]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[18]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[19]
                                                        }</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <thead>
                                                <tr>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                    </th>
                                                    <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                ${items
                                                  .map(
                                                    (item) => `
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${item.name}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.rate}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.total}</p>
                                                    </td>
                                                    </tr>
                                                `
                                                  )
                                                  .join("")}

                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                        </p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;width: 28%;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;"></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;width: 28%;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                    </td>
                                                    <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                            This Insertion Order ("IO") serves as a binding agreement between 
                                                            Big Wave Development and “Advertiser” Signature on this Insertion 
                                                            signifies acceptance by Advertiser of this agreement</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0 350px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="padding: 0 50px 10px 50px;">
                                        <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                    payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                    unless otherwise specified.
                                                </p>
                                            </li>
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                    Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                    pornographic content on any of the creative materials
                                                </p>
                                            </li>
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                </p>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                        <tr>
                                            <td style="width: 50%;padding: 0 0 0 50px;">
                                                <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                <ul style="margin: 0; padding: 0;">
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Signature: 
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                <img width="200" height="35"
                                                                style=" vertical-align: text-top;text-align: center;"
                                                                src="` +
        logo +
        `" alt="">   
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Name:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;"> 
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Title:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Date:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td style="width: 50%;padding: 0 50px 0 0;">
                                                <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                <ul style="margin: 0; padding: 0;">
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Signature: 
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                <img width="200" height="35"
                                                                style=" vertical-align: text-top;text-align: center;"
                                                                src="` +
        logo +
        `" alt="">   
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Name:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Title:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Date:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </table>
                                </tr>
                                
                            </tbody>
                        </table>
                    `;

      console.log(htmldemo);
      htmldemo += ``;
      html2pdf(htmldemo, {
        filename: "hh"
          .concat("-", "payslip")
          .concat("-", "mm".concat("-", "nn")),
      });

      // axios.post(BASE_URL + '/api/savesalarypdf',formData)
      //     .then((response)=>{
      //         console.log("uyertfgeusyyr",response.data.salary_details);
      //     })
    },

    async exportToPDFInvoice(id) {
      const toDataUrl = async function (url, callback) {
        //Convert to base64
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.send();
        });
      };

      // var url =
      //   "https://works-reddensoft.com/lms/admin/public/assets/signature.png";

      var url = BASE_URL + "/public/assets/signature.png";
      let urlBase64 = await toDataUrl(url);

      axios
        .post(BASE_URL + "/api/fetchDealDataForIO", { id: this.invoiceid })
        .then((response) => {
          console.log(response.data.iodetails);

          this.pdfdata = response.data.iodetails;
          var email = response.data.email;

          //this.htmltopdf(this.salarydata);
          //var html = `<h1>${this.cl_slip_payable}</h1>`;

          //html2pdf(html);

          this.htmltopdfInvoice(this.pdfdata, urlBase64, email);
        });
    },

    async htmltopdfInvoice(res, logo, email) {
      console.log("res", res);
      const resvalue = Object.values(res);
      console.log("resvalue", resvalue);
      const items = JSON.parse(resvalue[25]);
      console.log("items", items);
      var ioid = String(resvalue[0]).padStart(3, "0");

      // var htmldemo = `
      //         <style></style>
      //         <div style="width:100%; padding:20px " id="pdfFormat">

      //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                 <tbody>
      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
      //                                 Big Wave Development LLC
      //                             </p>
      //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
      //                                 www.bigwavedevelopment.com
      //                             </a>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#5032687</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <thead>
      //                                     <tr>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
      //                                         </th>
      //                                     </tr>
      //                                 </thead>
      //                                 <tbody>

      //                                     ${items.map(item => `
      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
      //                                         </td>
      //                                     </tr>
      //                                     `).join('')}

      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
      //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span>
      //                             </p>
      //                         </td>
      //                     </tr>

      //                 </tbody>
      //             </table>
      //         `;
      var htmldemo =
        `
                    <style></style>
                    <div style="width:100%; padding:20px " id="pdfFormat">
                    
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                            Insertion Order
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[15]
                                                        }</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[16]
                                                        }</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[17]
                                                        }</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[18]
                                                        }</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                          resvalue[19]
                                                        }</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">big Wave Development LLC</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[2]}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 (727) 217-5552</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                    </td>
                                                    <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <thead>
                                                <tr>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                    </th>
                                                    <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[20]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[21]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[22]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                          resvalue[23]
                                                        }</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                          resvalue[24]
                                                        }</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <thead>
                                                <tr>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                    </th>
                                                    <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                    </th>
                                                    <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                ${items
                                                  .map(
                                                    (item) => `
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                    </td>
                                                </tr>
                                                `
                                                  )
                                                  .join("")}
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                        </p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;width: 28%;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                          resvalue[24]
                                                        }</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #000;width: 28%;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                    </td>
                                                    <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                        <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                            This Insertion Order ("IO") serves as a binding agreement between 
                                                            Big Wave Development and “Advertiser” Signature on this Insertion 
                                                            signifies acceptance by Advertiser of this agreement</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>        
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0 350px 0;"></td>
                                </tr>
                                <tr>
                                    <td style="padding: 0 50px 10px 50px;">
                                        <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                    payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                    unless otherwise specified.
                                                </p>
                                            </li>
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                    Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                    pornographic content on any of the creative materials
                                                </p>
                                            </li>
                                            <li>
                                                <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                    Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                </p>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding: 15px 0;"></td>
                                </tr>
                                <tr>
                                    <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                        <tr>
                                            <td style="width: 50%;padding: 0 0 0 50px;">
                                                <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                <ul style="margin: 0; padding: 0;">
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Signature: 
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                <img width="200" height="35"
                                                                style=" vertical-align: text-top;text-align: center;"
                                                                src="` +
        logo +
        `" alt="">   
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Name:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;"> 
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Title:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Date:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td style="width: 50%;padding: 0 50px 0 0;">
                                                <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                <ul style="margin: 0; padding: 0;">
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Signature: 
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                <img width="200" height="35"
                                                                style=" vertical-align: text-top;text-align: center;"
                                                                src="` +
        logo +
        `" alt="">   
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Name:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Title:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                                                        </p>
                                                    </li>
                                                    <li style="list-style: none;">
                                                        <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                            Date:
                                                            <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </table>
                                </tr>
                                
                            </tbody>
                        </table>
                    `;
      console.log(htmldemo);

      var htmldemo_lynkme =
          `
                          <style></style>
                          <div style="width:100%; padding:20px " id="pdfFormat">
                          
                              <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                  <tbody>
                                      <tr>
                                          <td>
                                              <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                                  Insertion Order
                                              </p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <tbody>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                resvalue[15]
                                                              }</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                resvalue[16]
                                                              }</p>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <tbody>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                resvalue[17]
                                                              }</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                resvalue[18]
                                                              }</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                                resvalue[19]
                                                              }</p>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td style="padding: 15px 0;"></td>
                                      </tr>
                                      <tr>
                                          <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <tbody>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">LynkMe360 LLC</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[2]}</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, 218 Datura St. West Palm Beach, FL 33401-5679</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 727 440 5867</p>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                          </td>
                                                          <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td style="padding: 15px 0;"></td>
                                      </tr>
                                      <tr>
                                          <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                              <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <thead>
                                                      <tr>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                          </th>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                          </th>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                          </th>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                          </th>
                                                          <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                          </th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                resvalue[20]
                                                              }</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                resvalue[21]
                                                              }</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                resvalue[22]
                                                              }</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                                resvalue[23]
                                                              }</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                resvalue[24]
                                                              }</p>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <thead>
                                                      <tr>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                          </th>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                          </th>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                          </th>
                                                          <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                          </th>
                                                          <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                          </th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      ${items
                                                        .map(
                                                          (item) => `
                                                      <tr>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                          </td>
                                                      </tr>
                                                      `
                                                        )
                                                        .join("")}
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <tbody>
                                                      <tr>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                              </p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;width: 28%;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                                resvalue[24]
                                                              }</p>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td style="padding: 15px 0;"></td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <tbody>
                                                      <tr>
                                                          <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                          </td>
                                                          <td style="border-bottom: 1px solid #000;width: 28%;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                  <tbody>
                                                      <tr>
                                                          <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                          </td>
                                                          <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                              <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                                  This Insertion Order ("IO") serves as a binding agreement between 
                                                                  Big Wave Development and “Advertiser” Signature on this Insertion 
                                                                  signifies acceptance by Advertiser of this agreement</p>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>        
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td style="padding: 15px 0 350px 0;"></td>
                                      </tr>
                                      <tr>
                                          <td style="padding: 0 50px 10px 50px;">
                                              <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>
                                              <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                                  <li>
                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                          The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                          payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                          unless otherwise specified.
                                                      </p>
                                                  </li>
                                                  <li>
                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                          Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                          Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                          pornographic content on any of the creative materials
                                                      </p>
                                                  </li>
                                                  <li>
                                                      <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                          Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                      </p>
                                                  </li>
                                              </ul>
                                          </td>
                                      </tr>
  
                                      <tr>
                                          <td style="padding: 15px 0;"></td>
                                      </tr>
                                      <tr>
                                          <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                              <tr>
                                                  <td style="width: 50%;padding: 0 0 0 50px;">
                                                      <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                      <ul style="margin: 0; padding: 0;">
                                                          <li style="list-style: none;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                  Signature: 
                                                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                      <img width="200" height="35"
                                                                      style=" vertical-align: text-top;text-align: center;"
                                                                      src="` +
          logo +
          `" alt="">   
                                                                  </span>
                                                              </p>
                                                          </li>
                                                          <li style="list-style: none;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                  Name:
                                                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                              </p>
                                                          </li>
                                                          <li style="list-style: none;"> 
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                  Title:
                                                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                              </p>
                                                          </li>
                                                          <li style="list-style: none;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                  Date:
                                                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                              </p>
                                                          </li>
                                                      </ul>
                                                  </td>
                                                  <td style="width: 50%;padding: 0 50px 0 0;">
                                                      <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                      <ul style="margin: 0; padding: 0;">
                                                          <li style="list-style: none;">
                                                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                  Signature: 
                                                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                      <img width="200" height="35"
                                                                      style=" vertical-align: text-top;text-align: center;"
                                                                      src="` +
          logo +
          `" alt="">   
                                  </span>
                              </p>
                          </li>
                          <li style="list-style: none;">
                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                  Name:
                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                              </p>
                          </li>
                          <li style="list-style: none;">
                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                  Title:
                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                              </p>
                          </li>
                          <li style="list-style: none;">
                              <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                  Date:
                                  <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                              </p>
                          </li>
                      </ul>
                  </td>
              </tr>
          </table>
          </tr>
  
          </tbody>
          </table>
          `;
          if (resvalue[27] == "Bigwave") {
          // console.log(htmldemo);
          htmldemo += ``;
          html2pdf(htmldemo, {
            filename: resvalue[3]
              .concat("-", resvalue[27])
              .concat("-", "IO")
              .concat("-", ioid),
          });
        } else if (resvalue[27] == "Lynkme") {
          htmldemo_lynkme += ``;
          html2pdf(htmldemo_lynkme, {
            filename: resvalue[3]
              .concat("-", resvalue[27])
              .concat("-", "IO")
              .concat("-", ioid),
          });
        }
        else{
          this.tostMessage("warning", "Can not download IO for Reddensoft.");
  
        }

      // axios.post(BASE_URL + '/api/savesalarypdf',formData)
      //     .then((response)=>{
      //         console.log("uyertfgeusyyr",response.data.salary_details);
      //     })
    },

    async generateIO(io, id) {
      console.log("id", id);
      console.log("io", io);
      console.log("newCategory", this.newCategory);
      console.log("categories", this.categories);
      await axios
        .post(BASE_URL + "/api/saveioDetails", {
          io: io,
          id: this.invoiceid,
          category: this.categories,
        })
        .then((response) => {
          this.io.company_name = "";
          this.io.company_address = "";
          this.io.contact_person_name = "";
          this.io.contact_person_reg_no = "";
          this.io.contact_person_email = "";
          this.io.web_app_name = "";
          this.io.start_date = "";
          this.io.end_date = "";
          this.io.placemnet = "";
          this.io.total_budget = "";
          this.io.solo_email_model = "";
          this.io.solo_email_rate = "";
          this.io.solo_email_quantity = "";
          this.io.solo_email_total = "";
          this.io.email_template_model = "";
          this.io.email_template_rate = "";
          this.io.email_template_quantity = "";
          this.io.email_template_total = "";
          this.closeiopopup();
          // this.showdealdetails();
          this.getMonthData();
        });
    },

    async openiopopupBtn1(id, status, beneficiary) {
      // this.openiopopup1 = true;
      this.invoiceid1 = id;

      await axios
        .post(BASE_URL + "/api/iopopupdetails", { id: id })
        .then((response) => {
          // console.log("test",response.data.iodetails.solo_email_model);
          (this.invoice.id = response.data.iodetails.id),
            (this.invoice.cName = response.data.iodetails.client),
            (this.invoice.cAddress = response.data.iodetails.company_address),
            (this.invoice.contactName =
              response.data.iodetails.contact_person_name),
            (this.invoice.contactEmail =
              response.data.iodetails.contact_person_email),
            (this.invoice.regNo =
              response.data.iodetails.contact_person_reg_no),
            (this.invoice_status = response.data.iodetails.io_status),
            (this.campaign_data_invoice = response.data.iocampaigndetails);
        });

      if (beneficiary == null) {
        this.tostMessage("warning", "Please add benificiary details first");
      } else if (this.invoice_status == "1") {
        this.openiopopup1 = true;
        // this.deal_status = status
      } else if (status == "Closed") {
        this.tostMessage(
          "warning",
          "Invoice deatils has not been updated for this deal"
        );
      } else {
        this.tostMessage("warning", "This deal is not close yet");
      }
    },

    closeiopopup1() {
      this.openiopopup1 = false;
      // this.deal_status = '';
    },

    async exportToPDFInvoice1() {
      const toDataUrl = async function (url, callback) {
        //Convert to base64
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.send();
        });
      };

      // var url = "https://works-reddensoft.com/lms/admin/public/assets/signature.png";
      // var url =
      //   "https://works-reddensoft.com/lms/admin/public/assets/reddensoft_logo.svg";
      var url = BASE_URL + "/public/assets/reddensoft_logo.svg";
      let urlBase64 = await toDataUrl(url);
      console.log("this.invoice.id", this.invoice.id);
      axios
        .post(BASE_URL + "/api/fetchDealDataForIO", { id: this.invoice.id })
        .then((response) => {
          console.log(response.data.iodetails);

          this.pdfdata = response.data.iodetails;
          //this.htmltopdf(this.salarydata);
          //var html = `<h1>${this.cl_slip_payable}</h1>`;

          //html2pdf(html);

          this.htmltopdfInvoice1(this.pdfdata, urlBase64);
        });
    },

    async htmltopdfInvoice1(res, logo) {
      console.log("res", res);
      const resvalue = Object.values(res);
      console.log("resvalue", resvalue);
      const items = JSON.parse(resvalue[25]);
      console.log("items", items);
      var ioid = String(resvalue[0]).padStart(3, "0");

      // var htmldemo = `
      //         <style></style>
      //         <div style="width:100%; padding:20px " id="pdfFormat">

      //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                 <tbody>
      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
      //                                 Big Wave Development LLC
      //                             </p>
      //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
      //                                 www.bigwavedevelopment.com
      //                             </a>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#${ioid}</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <thead>
      //                                     <tr>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
      //                                         </th>
      //                                     </tr>
      //                                 </thead>
      //                                 <tbody>

      //                                     ${items.map(item => `
      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
      //                                         </td>
      //                                     </tr>
      //                                     `).join('')}

      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
      //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span>
      //                             </p>
      //                         </td>
      //                     </tr>

      //                 </tbody>
      //             </table>
      //         `;

      var htmllynkmedemo = `    <div style="padding: 0;">
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td>
                        <span style="width: 100%;height: 60px;background: #7ed956;display: block;"></span>
                    </td>
                </tr>
            </tbody>
        </table>
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td style="padding-right: 20px;">
                        <span style="width: 490px;height: 15px;background: #008235;display: block;margin-left: auto;"></span>
                    </td>
                </tr>
            </tbody>
        </table>    
        <div style="padding: 0 20px;">
            <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td>
                            <span style="width: 100%;height: 40px;background: #fff;display: block;"></span>
                        </td>
                    </tr>
                </tbody>
            </table> 
            <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="padding-top: 10px;">
                            <h2 style="font-family: sans-serif;color: #000;font-size: 24px;margin: 0;letter-spacing: 4px;">
                                LYNKME
                                <span style="padding: 0 2px;">360</span>
                                LLC
                            </h2>
                        </td>
                        <td style="padding-top: 10px;">
                            <h2 style="font-family: sans-serif;color: #000;font-size: 40px;text-align: right;margin: 0;">
                                INVOICE
                            </h2>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 30px;">
                            <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                BILLED TO:
                            </p>
                            <h2 style="font-family: sans-serif;color: #000;font-size: 20px;margin: 0;font-weight: 600;padding-bottom: 4px;">
                                ${resvalue[15]}

                            </h2>
                            <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                ${resvalue[16]}

                            </p>
                            <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                Email: ${resvalue[19]}
                            </p>
                        </td>
                        <td style="padding-top: 30px;">
                            <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;text-align: right;">
                                Invoice Number: ${ioid}
                            </p>
                            <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;text-align: right;">
                                ${resvalue[8]}
                            </p>
                        </td>
                    </tr>
                </tbody>
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td>
                                <span style="width: 100%;height: 20px;background: #fff;display: block;"></span>
                            </td>
                        </tr>
                    </tbody>
                </table> 
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <thead>
                            <tr>
                                <th style="background: #008036;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                    ITEM DESCRIPTION
                                </th>
                                <th style="background: #7ed956;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                    VOLUME
                                </th>
                                <th style="background: #7ed956;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                    Total($)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        ${items
                          .map(
                            (item) => `

                            <tr>
                                <td style="border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                    ${item.name}
                                </td>
                                <td style="border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                    ${item.quantity}
                                </td>
                                <td style="background: #ede9e8; border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                    ${item.total}
                                </td>
                            </tr>
                        `
                          )
                          .join("")}

                            <tr>
                                <td style="border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 700;text-align: left;letter-spacing: 2px;">
                                TOTAL AMOUNT:
                                </td>
                                <td style="border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                </td>
                                <td style="background: #ede9e8; border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 700;text-align: left;letter-spacing: 2px;">
                                    $${resvalue[24]}
                                </td>
                            </tr>
                        </tbody>
                    </tbody>
                </table>
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="padding-top: 20px; font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;text-decoration: underline;padding-bottom: 10px;">
                                Please make payments to:
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                Wire Transfer Details:
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                BENEFICIARY NAME: LynkMe360 LLC
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                BANK NAME: PNC Bank
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                Account Number: 1251348057
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                Routing Number: 267084199
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                Swift Code: PNCCUS33
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                BANK ADDRESS: PNC Bank, 218 Datura St. West Palm Beach, FL 33401-5679
                            </td>
                        </tr>
                    </tbody>
                </table>    
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;padding-bottom: 40px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="padding-top: 40px; font-family: sans-serif;color: #000;font-size: 20px;margin: 0;font-weight: 700;padding-bottom: 4px;text-align: right;">
                                LynkMe 360 LLC
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 3px;text-align: right;">
                                2630 W Broward BLVD Suite 203 - 1713 Fort Lauderdale,
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;text-align: right;">
                                FL 33312 United States, Tel: +1 727 440 5867
                            </td>
                        </tr>
                    </tbody>
                </table>            
            </table>   
            <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td>
                            <span style="width: 100%;height: 40px;background: #fff;display: block;"></span>
                        </td>
                    </tr>
                </tbody>
            </table> 
        </div>
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td style="padding: 0 20px;">
                        <span style="width: 100%;height: 15px;background: #008235;display: block;margin-left: auto;padding: 0 20px;"></span>
                    </td>
                </tr>
            </tbody>
        </table> 
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td>
                        <span style="width: 100%;height: 60px;background: #7ed956;display: block;"></span>
                    </td>
                </tr>
            </tbody>
        </table>  
            </div>`;

      var htmlreddensoftdemo =
        `    <div style="padding: 20px;">
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td style="text-align: center;">
                        <a href="#" style="font-size: 16px;font-weight: 700;color: #000;padding:3px; margin: 0;">
                                    <img src="` +
        logo +
        `" alt="logo" style="width: 200px;margin: auto;">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>  
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="padding-top: 40px;width: 60%;">
                                <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 18px;margin: 0;letter-spacing: 1px;">
                                    INVOICE: #${ioid}
                                </h2>
                            </td>
                            <td style="padding-top: 10px;width: 40%;">
                                <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 18px;margin: 0;letter-spacing: 1px;">
                                    DATE: ${resvalue[8]}
                                </h2>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-top: 30px;padding-bottom: 30px;width: 60%;">
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;padding-right: 15px;">
                                    Reddensoft Infotech Pvt. Ltd.
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;padding-right: 15px;">
                                    AMP Baishaki, 7th floor, Room 705, AG-112, Sec-II, Salt Lake, Kolkata -700091
                                </p>
                                <p style="padding-bottom: 6px;margin: 0;">
                                    <a href="#" style="color: #6767ff;font-family: sans-serif;font-size: 18px;margin: 0;font-weight: 400;margin: 0;">info@reddensoft.com</a>
                                </p>
                            </td>
                            <td style="padding-top: 30px;padding-bottom: 30px;width: 40%;">
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                                    To:
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    ${resvalue[15]}
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    ${resvalue[16]}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>   
                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #d2d2d2; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                        <tr>
                            <th style="width: 50%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Item Description</p>
                            </th>
                            <th style="width: 15%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Quantity</p>
                            </th>
                            <th style="width: 20%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Unit Price($)</p>
                            </th>
                            <th style="width: 15%;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Total($)</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        ${items
                          .map(
                            (item) => `

                        <tr>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.name}</p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.quantity}</p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.rate}</p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.total}</p>
                            </td>
                        </tr>
                        `
                          )
                          .join("")}

                        <tr>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Subtotal</p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${
                                  resvalue[24]
                                }</p>
                            </td>
                        </tr>
                        <tr>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Tax - 0%</p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;background: #e6e6e6;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">0.00</p>
                            </td>
                        </tr>
                        <tr>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">TOTAL</p>
                            </td>
                            <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${
                                  resvalue[24]
                                }</p>
                            </td>
                        </tr>
                    </tbody>
                    </table>  
                    <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                        
                        <tbody>
                            <tr>
                                <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;padding-top: 30px;">
                                    Payment Option: 
                                    <span style="font-weight: 600;">Wise.com</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                    Company Name:
                                    <span style="font-weight: 600;">Reddensoft Infotech Pvt Ltd</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                    Bank Name: AXIS BANK
                                </td>
                            </tr>
                            <tr>
                                <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                    Account Number: 1010101010101
                                </td>
                            </tr>
                            <tr>
                                <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                    IFSC Code: UTIB0000000
                                </td>
                            </tr>
                            <tr>
                                <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                    Swift code: AXIS5555555
                                </td>
                            </tr>
                            <tr>
                                <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                    Bank Address: Kolkata, West Bengal, India
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                    <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td>
                                    <p style="text-align: center;font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;letter-spacing: 1px;padding-bottom: 8px;padding-top: 40px;">
                                        If you have any questions or concerns regarding this invoice, contact via email.
                                    </p>
                                    <p style="text-align: center;font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;letter-spacing: 1px;">
                                        Thank you!
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                </div>`;

      var htmlbigwavedemo = `    <div style="padding: 20px;">  
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td style="font-size: 30px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                        Big Wave Development LLC
                    </td>
                </tr>
                <tr>
                    <td style="text-align: center;">
                        <a href="https://www.bigwavedevelopment.com/" style="font-size: 16px;font-weight: 700;color: #000;padding:3px; margin: 0;">
                            www.bigwavedevelopment.com
                        </a>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 40px;">
                        <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;">
                            INVOICE/DATE ${resvalue[8]}
                        </h2>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 10px;">
                        <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;">
                            #${ioid}
                        </h2>
                    </td>
                </tr>
            </tbody>
        </table>     
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td style="width: 50%; padding-right:10px; padding-top: 40px;">
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                            Form:
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Company Name: Big Wave Development LLC
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Company Address: 7901 4th ST. N St. Petersburg, FL. UN 33702
                        </p>
                    </td>
                    <td style="width: 50%; padding-top: 40px;">
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                            To:
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Company Name: ${resvalue[15]}
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Company Address: ${resvalue[16]}
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>  
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td style="padding-top: 40px;">
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                            WireTransfer Details:
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            BENIFICIARY NAME: Big Wave Development LLC
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            BANK NAME: PNC Bank
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Account Number: 1242641948
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Routing Number: 267084199
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Swift Code: PNCCUS33
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            BANK ADDRESS: PNC Bank, National Association
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Pittsburgh 00115
                        </p>
                    </td>
                    <td style="padding-top: 40px;vertical-align: bottom;">
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                            Contact Person:
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Contact name: ${resvalue[17]}
                        </p>
                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                            Email: ${resvalue[19]}
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>    
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td>
                        <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;margin: 0;padding-top: 40px;padding-bottom: 20px;">
                            Sub: Invoice
                        </h2>
                    </td>
                </tr>
            </tbody>
        </table>   
        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <thead>
                <tr>
                    <th style="width: 40%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Item Description</p>
                    </th>
                    <th style="width: 15%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Quantity</p>
                    </th>
                    <th style="width: 30%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">PRICE($)</p>
                    </th>
                    <th style="width: 15%;border-bottom: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Total($)</p>
                    </th>
                </tr>
            </thead>
            <tbody>
            ${items
              .map(
                (item) => `

                <tr>
                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.name}</p>
                    </td>
                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.quantity}</p>
                    </td>
                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.rate}</p>
                    </td>
                    <td style="border-bottom: 1px solid #000;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.total}</p>
                    </td>
                </tr>

            `
              )
              .join("")}

                <tr>
                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                    </td>
                    <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Total</p>
                    </td>
                    <td style="border-bottom: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${
                          resvalue[24]
                        }</p>
                    </td>
                    <td style="border-bottom: 1px solid #000;background: #ebebeb;">
                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                    </td>
                </tr>
            </tbody>
        </table>   
        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td>
                        <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;;margin: 0;padding-top: 20px;padding-bottom: 20px;">
                            If you have any questions or concerns regarding this invoice, contact via email. 
                            <span style="font-weight: 700;">Thank you!</span>
                        </h2>
                    </td>
                </tr>
            </tbody>
        </table>   
    </div>`;

      if (resvalue[27] == "Bigwave") {
        // console.log(htmldemo);
        htmlbigwavedemo += ``;
        html2pdf(htmlbigwavedemo, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Lynkme") {
        htmllynkmedemo += ``;
        html2pdf(htmllynkmedemo, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Reddensoft") {
        htmlreddensoftdemo += ``;
        html2pdf(htmlreddensoftdemo, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else {
        console.log("error");
      }

      // axios.post(BASE_URL + '/api/savesalarypdf',formData)
      //     .then((response)=>{
      //         console.log("uyertfgeusyyr",response.data.salary_details);
      //     })
    },
  },
  computed: {
    monthData() {
      if (this.searchQuery) {
        return this.monthData.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((w) =>
              item.empname
                .concat(item.empid)
                .concat(item.position)
                .toLowerCase()
                .includes(w)
            );
        });
      } else {
        //    console.log("details");
        return this.monthData;
      }
    },

    totalAmount() {
      return this.campaign_categories
        .reduce((total, row) => total + parseFloat(row.total), 0)
        .toFixed(2);
    },
    totalInvoiceAmount() {
      console.log("computed");
      // return 100;
      return this.campaign_data_invoice
        .reduce((total, row) => total + parseFloat(row.total), 0)
        .toFixed(2);
    },
  },
};
</script>
