<template>
  <div class="dashboard_box">
    <EmployeLeftbar />
    <div class="dashboard_right">
      <EmployeHeader />
      <div class="dashboard_body">
        <div class="main_box">
          <div class="top">
            <div class="top_box">
              <div class="name">
                <!-- <h2>Hi {{empname}}</h2> -->
              </div>
              <div class="btn_deal d-flex">
                <button @click="toggleClass2">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20"
                      width="18"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      />
                    </svg>
                  </span>
                  New Deal
                </button>
                <button @click="toggleClass3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20"
                      width="18"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      />
                    </svg>
                  </span>
                  New Report
                </button>

                <div :class="{ active_class: isClassAdded2 }" class="modal_div">
                  <div class="modal_width">
                    <div class="header">
                      <div class="name">
                        <h2>Add Deal</h2>
                      </div>
                      <div class="close">
                        <button @click="toggleClass2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="12"
                            viewBox="0 0 384 512"
                          >
                            <path
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="modal_body">
                      <div class="input_box">
                        <label>Client Name</label>
                        <input
                          v-model="newTransaction.clientName"
                          type="text"
                          placeholder="Enter your client name"
                        />
                        <span v-if="v3$.clientName.$error" class="field-error">
                          {{ v3$.clientName.$errors[0].$message }}
                        </span>
                      </div>
                      <div class="input_box">
                        <label>Amount</label>
                        <input
                          v-model="newTransaction.amount"
                          type="number"
                          placeholder="Enter your amount"
                        />
                        <span v-if="v3$.amount.$error" class="field-error">
                          {{ v3$.amount.$errors[0].$message }}
                        </span>
                      </div>
                      <div class="input_box">
                        <label>Deal Status</label>
                        <!-- <input v-model="newTransaction.paymentStatus" type="text" placeholder="Enter your payment status" /> -->
                        <select
                          class="addNewInput"
                          v-model="newTransaction.paymentStatus"
                        >
                          <option value="" disabled selected>
                            -Select One-
                          </option>
                          <option value="Pending">Pending</option>
                          <option value="Closed">Closed</option>
                          <option value="Failed">Failed</option>
                        </select>
                        <span
                          v-if="v3$.paymentStatus.$error"
                          class="field-error"
                        >
                          {{ v3$.paymentStatus.$errors[0].$message }}
                        </span>
                      </div>
                      <div class="input_box">
                        <label>Beneficiary Status</label>
                        <!-- <input v-model="newTransaction.paymentStatus" type="text" placeholder="Enter your payment status" /> -->
                        <select
                          class="addNewInput"
                          v-model="newTransaction.beneficiary"
                        >
                          <option value="" disabled>Select One</option>
                          <option value="Reddensoft">Reddensoft</option>
                          <option value="Lynkme">Lynkme 360</option>
                          <option value="Bigwave">Bigwave</option>
                        </select>
                        <span v-if="v3$.beneficiary.$error" class="field-error">
                          {{ v3$.beneficiary.$errors[0].$message }}
                        </span>
                      </div>
                      <div class="input_box">
                        <label>Date</label>
                        <input
                          v-model="newTransaction.date"
                          type="date"
                          placeholder="Enter your date"
                          @input="validateDate"
                        />
                        <!-- <Datepicker v-model="newTransaction.date" placeholder="Enter your date"></Datepicker> -->
                        <span v-if="v3$.date.$error" class="field-error">
                          {{ v3$.date.$errors[0].$message }}
                        </span>
                      </div>
                      <!-- <div class="input_box">
                                                <label>Invoice</label>
                                                <input v-model="newTransaction.invoice" type="text" placeholder="Enter your invoice" />
                                            </div> -->
                      <div class="input_box">
                        <button @click="submitForm">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :class="{ modal_background: isClassAdded2 }"
                  @click="toggleClass2"
                ></div>

                <div :class="{ active_class: isClassAdded3 }" class="modal_div">
                  <div class="modal_width">
                    <div class="header">
                      <div class="name">
                        <h2>Add Report</h2>
                      </div>
                      <div class="close">
                        <button @click="toggleClass3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="12"
                            viewBox="0 0 384 512"
                          >
                            <path
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="modal_body">
                      <!-- <div class="input_box">
                                                <label>Name</label>
                                                <input type="text" placeholder="Enter your name" />
                                            </div> -->
                      <div class="input_box">
                        <label>Date</label>
                        <input
                          type="date"
                          placeholder="Enter your date"
                          v-model="daily_report.date"
                        />
                        <!-- <datepicker v-model="daily_report.date" placeholder="Enter your date"/> -->
                        <!-- <Datepicker v-model="daily_report.date" placeholder="Enter your date"></Datepicker> -->
                      </div>
                      <div class="input_box">
                        <label>No. of Mails Sent</label>
                        <input
                          type="number"
                          placeholder="Total mails sent"
                          min="0"
                          v-model="daily_report.total_mail_sent"
                        />
                      </div>
                      <div class="input_box">
                        <label>No. of Positive Responses</label>
                        <input
                          type="number"
                          placeholder="Total positive responses"
                          min="0"
                          v-model="daily_report.total_positive_response"
                        />
                      </div>
                      <div class="input_box">
                        <label>No. of Total Responses</label>
                        <input
                          type="number"
                          placeholder="Total responses"
                          min="0"
                          v-model="daily_report.total_response"
                        />
                      </div>
                      <div class="input_box">
                        <label>No. of Leads Generated</label>
                        <input
                          type="number"
                          placeholder="Total leads generated"
                          min="0"
                          v-model="daily_report.total_lead"
                        />
                      </div>
                      <div class="input_box">
                        <label>Deals Closed</label>
                        <input
                          type="number"
                          placeholder="Total leads closed"
                          min="0"
                          v-model="daily_report.lead_closed"
                        />
                      </div>
                      <div class="input_box">
                        <button
                          v-if="submittingDailyReport == false"
                          @click="submitDailyReport()"
                        >
                          Submit
                        </button>
                        <button v-else disabled>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :class="{ modal_background: isClassAdded3 }"
                  @click="toggleClass3"
                ></div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="row">
              <div class="col-4 colpadding">
                <div class="weget">
                  <div class="heading">
                    <h2>Your Target for {{ month_proper_name }}</h2>
                  </div>
                  <div class="weget_box">
                    <div class="row justify-content-center">
                      <div class="col-6 padding">
                        <div class="box">
                          <span> ${{ personalTarget }} </span>
                          <p>Individual</p>
                        </div>
                      </div>
                      <div
                        class="col-6 padding"
                        v-if="marketing_status == 'Team Leader'"
                      >
                        <div class="box">
                          <span> ${{ teamTarget }} </span>
                          <p>Team</p>
                        </div>
                      </div>
                      <div class="col-12 padding">
                        <div class="progress_div">
                          <div class="batch">
                            <!-- <img src="../assets/dashboard/indivisual.png" alt="batch"> -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                              width="24px"
                              height="24px"
                            >
                              <path
                                d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                              />
                            </svg>
                          </div>
                          <div class="progress_ber">
                            <div class="progress-bars">
                              <span
                                v-if="personCompletionPercentage <= 100"
                                class="percentage c"
                                :style="{
                                  width: personCompletionPercentage + '%',
                                }"
                              ></span>
                              <span
                                v-else-if="personCompletionPercentage > 100"
                                class="percentage c"
                                :style="{ width: 100 + '%' }"
                              ></span>
                              <span
                                v-else
                                class="percentage c"
                                :style="{ width: 0 + '%' }"
                              ></span>
                            </div>
                          </div>
                          <div class="batch">
                            <img
                              src="../assets/dashboard/batch.png"
                              alt="batch"
                            />
                          </div>
                        </div>
                        <div
                          class="progress_div"
                          v-if="marketing_status == 'Team Leader'"
                        >
                          <div class="batch">
                            <!-- <img src="../assets/dashboard/team.jpeg" alt="batch"> -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                              width="24px"
                              height="24px"
                            >
                              <path
                                d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"
                              />
                            </svg>
                          </div>
                          <div class="progress_ber">
                            <div class="progress-bars">
                              <span
                                v-if="teamCompletionPercentage <= 100"
                                class="percentage c"
                                :style="{
                                  width: teamCompletionPercentage + '%',
                                }"
                              ></span>
                              <span
                                v-else-if="teamCompletionPercentage > 100"
                                class="percentage c"
                                :style="{ width: 100 + '%' }"
                              ></span>
                              <span
                                v-else
                                class="percentage c"
                                :style="{ width: 0 + '%' }"
                              ></span>
                            </div>
                          </div>
                          <div class="batch">
                            <img
                              src="../assets/dashboard/batch.png"
                              alt="batch"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 colpadding">
                <div class="weget">
                  <div class="heading">
                    <h2>
                      This is <span>{{ currentWeek }}</span> Week running
                    </h2>
                  </div>
                  <div class="weget_box">
                    <div class="row">
                      <div class="col-12 padding running">
                        <div class="box">
                          <p>{{ message }}</p>
                          <h3>{{ daysLeft }} days left</h3>

                          <h2 class="previous_text">
                            Your previous overdue is of
                            <span>${{ lastQuarterOverdue }}</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 colpadding">
                <div class="weget">
                  <div class="heading">
                    <h2>Your Prize</h2>
                  </div>
                  <div class="weget_box">
                    <div class="row">
                      <div class="col-12 padding box-height">
                        <div class="box">
                          <h4>${{ monthValue }}</h4>
                          <p>Once you hit target</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 colpadding">
                <div class="weget">
                  <div class="heading">
                    <h2>Personal</h2>
                  </div>
                  <div class="weget_box">
                    <div class="row">
                      <div class="col-4 padding">
                        <h2>Deal Closed</h2>
                        <div class="box">
                          <p>{{ closedPersonalDealCount }} Closed this month</p>
                        </div>
                      </div>
                      <div class="col-8 padding">
                        <h2>Deal Valuation</h2>
                        <div class="row">
                          <div class="col-4 box_padding">
                            <div class="box">
                              <span>${{ closedPersonalDeal }} </span>
                              <p>Deal Finalized</p>
                            </div>
                          </div>

                          <div class="col-4 box_padding">
                            <div class="box">
                              <span>${{ PersonalPaymentRecievedTotal }} </span>
                              <p>Received</p>
                            </div>
                          </div>
                          <div class="col-4 box_padding">
                            <div class="box">
                              <span>${{ PersonalPaymentDueTotal }}</span>
                              <p>Due</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-6 colpadding"
                v-if="marketing_status == 'Team Leader'"
              >
                <div class="weget">
                  <div class="heading">
                    <h2>Team</h2>
                  </div>
                  <div class="weget_box">
                    <div class="row">
                      <div class="col-6 padding">
                        <h2>Deal Closed</h2>
                        <div class="box">
                          <p>{{ closedTeamDealCount }} Closed this month</p>
                        </div>
                      </div>
                      <div class="col-6 padding">
                        <h2>Deal Valuation</h2>
                        <div class="row">
                          <div class="col-12">
                            <!-- <div class="box team_box" > -->
                            <div
                              class="box team_box"
                              v-if="closedTeamDealDetails != []"
                            >
                              <div
                                class=""
                                v-for="(deal, index) in closedTeamDealDetails"
                                :key="index"
                              >
                                <p>
                                  {{ deal.empname }}- ${{
                                    deal.total_amount
                                  }}({{ deal.deal_count }})
                                </p>
                              </div>
                            </div>

                            <div
                              class="box team_box"
                              v-if="closedTeamDealDetails == []"
                            >
                              <p>No closed deals this month</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 colpadding">
                <div class="weget">
                  <div class="heading">
                    <h2>Personal</h2>
                  </div>
                  <div class="weget_box">
                    <div class="row">
                      <div class="col-4 padding">
                        <h2>Payment Closed</h2>
                        <div class="box">
                          <p>
                            {{ paymentCompletionCount }} payment completed this
                            month
                          </p>
                        </div>
                      </div>
                      <div class="col-8 padding">
                        <h2>Payment Valuation</h2>
                        <div class="row">
                          <div class="col-6">
                            <div class="box team_box_heights">
                              <!-- <span>${{5}} </span> -->
                              <p
                                v-for="(row, index) in dealpaidfor"
                                :key="index"
                              >
                                Closed Deal of {{ getMonthName(row.month) }} :
                                ${{ row.amount }}
                              </p>
                            </div>
                          </div>

                          <div class="col-6 box_padding">
                            <div class="box">
                              <span>${{ paymentCompletionTotal }} </span>
                              <p>Received</p>
                            </div>
                          </div>
                          <!-- <div class="col-4 box_padding">
                                                        <div class="box">
                                                            <span>${{PersonalPaymentDueTotal}}</span>
                                                            <p>Due</p>
                                                        </div>
                                                    </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-6 colpadding"
                v-if="marketing_status == 'Team Leader'"
              >
                <div class="weget">
                  <div class="heading">
                    <h2>Team</h2>
                  </div>
                  <div class="weget_box">
                    <div class="row">
                      <div class="col-4 padding">
                        <h2>Payment Closed</h2>
                        <div class="box">
                          <p>
                            {{ teamPaymentCompletionCount }} payment completed
                            this month
                          </p>
                        </div>
                      </div>
                      <div class="col-8 padding">
                        <h2>Payment Valuation</h2>
                        <div class="row">
                          <div class="col-6">
                            <div class="box team_box_heights">
                              <!-- <span>${{5}} </span> -->
                              <p
                                v-for="(row, index) in teamDealpaidfor"
                                :key="index"
                              >
                                Closed Deal of {{ getMonthName(row.month) }} :
                                ${{ row.amount }}({{ row.client }})
                              </p>
                            </div>
                          </div>

                          <div class="col-6 box_padding">
                            <div class="box">
                              <span>${{ teamPaymentCompletionTotal }} </span>
                              <p>Received</p>
                            </div>
                          </div>
                          <!-- <div class="col-4 box_padding">
                                                        <div class="box">
                                                            <span>${{PersonalPaymentDueTotal}}</span>
                                                            <p>Due</p>
                                                        </div>
                                                    </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table_box table_accordion">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button heading"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <h2>Latest Deals</h2>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div class="table_div">
                      <table>
                        <thead>
                          <tr>
                            <th v-if="marketing_status == 'Team Leader'">
                              Member Name
                            </th>
                            <th>Client Name</th>
                            <th>Amount</th>
                            <th>Deal Status</th>
                            <th>Payment Status</th>
                            <!-- <th>Payment Date</th> -->
                            <th>Payment Method</th>
                            <th>Beneficiary Details</th>
                            <th>Date</th>
                            <!-- <th>Invoice</th> -->
                            <th>Action</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(transaction, index) in dealdetails"
                            :key="index"
                          >
                            <td v-if="marketing_status == 'Team Leader'">
                              {{ transaction.empname }}
                            </td>
                            <td>
                              <div v-if="transaction.id == editrow">
                                <input
                                  type="text"
                                  v-model="transaction.client"
                                />
                              </div>
                              <div v-else>
                                {{ transaction.client }}
                              </div>
                            </td>
                            <td>
                              <div v-if="transaction.id == editrow">
                                <input
                                  type="number"
                                  min="0"
                                  v-model="transaction.amount"
                                />
                              </div>
                              <div v-else>
                                {{ transaction.amount }}
                              </div>
                            </td>
                            <!-- <td>{{ transaction.payment_status }}</td> -->
                            <td>
                              <div v-if="transaction.id == editrow">
                                <select
                                  class="addNewInput"
                                  v-model="transaction.payment_status"
                                  placeholder="Select"
                                >
                                  <option :value="blood" disabled>
                                    Select One
                                  </option>
                                  <option value="Pending">Pending</option>
                                  <option value="Closed">Closed</option>
                                  <option value="Failed">Failed</option>
                                </select>
                              </div>
                              <div v-else>
                                {{ transaction.payment_status }}
                              </div>
                              <div
                                class="modal_background"
                                v-if="
                                  openiopopup == true &&
                                  transaction.id == invoiceid
                                "
                              >
                                <!-- <div class="modal_background" v-if="openiopopup ==true && transaction.payment_status == 'Closed' "> -->
                                <div class="modal_content">
                                  <div class="modal_box">
                                    <div class="modal_heading">
                                      <h2>Insertion Order</h2>
                                      <button @click="closeiopopup()">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 384 512"
                                        >
                                          <path
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                    <div class="modal_scroll">
                                      <div class="modal_name">
                                        <h3>Advertiser Details</h3>
                                      </div>
                                      <div class="d-flex">
                                        <div class="col modal_input">
                                          <label>Company Name :</label>
                                          <input
                                            type="text"
                                            placeholder="Enter company name"
                                            v-model="io.company_name"
                                          />
                                        </div>
                                        <div class="col modal_input">
                                          <label>Address :</label>
                                          <input
                                            type="text"
                                            placeholder="Enter company address"
                                            v-model="io.company_address"
                                          />
                                        </div>
                                      </div>
                                      <div class="modal_name">
                                        <h3>Contact person</h3>
                                      </div>
                                      <div class="d-flex flex-wrap">
                                        <div class="col-4 modal_input">
                                          <label>Name :</label>
                                          <input
                                            type="text"
                                            placeholder="Enter name"
                                            v-model="io.contact_person_name"
                                          />
                                        </div>
                                        <div class="col-4 modal_input">
                                          <label>Registration number :</label>
                                          <input
                                            type="text"
                                            placeholder="Enter registration number"
                                            v-model="io.contact_person_reg_no"
                                          />
                                        </div>
                                        <div class="col-4 modal_input">
                                          <label>Email :</label>
                                          <input
                                            type="text"
                                            placeholder="Enter company email"
                                            v-model="io.contact_person_email"
                                          />
                                        </div>
                                      </div>
                                      <div class="modal_name">
                                        <h3>Campaign Details</h3>
                                      </div>
                                      <div class="modal_campaign">
                                        <div class="row">
                                          <div class="col details_label">
                                            <label>Site / Application</label>
                                            <input
                                              type="text"
                                              placeholder="Enter site"
                                              v-model="io.web_app_name"
                                            />
                                          </div>
                                          <div class="col details_label">
                                            <label>Start Date</label>
                                            <input
                                              type="date"
                                              placeholder="Enter start date"
                                              v-model="io.start_date"
                                            />
                                          </div>
                                          <div class="col details_label">
                                            <label>End Date</label>
                                            <input
                                              type="date"
                                              placeholder="Enter end date"
                                              v-model="io.end_date"
                                            />
                                          </div>
                                          <div class="col details_label">
                                            <label>Placement</label>
                                            <input
                                              type="text"
                                              placeholder="Enter placement"
                                              v-model="io.placemnet"
                                            />
                                          </div>
                                          <div class="col details_label">
                                            <label>Total Budget</label>
                                            <input
                                              type="number"
                                              placeholder="Enter total budget"
                                              v-model="io.total_budget"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="modal_name">
                                        <h3>Campaign Type</h3>
                                      </div>
                                      <div class="campaing_select">
                                        <div class="col-6">
                                          <div class="input_box">
                                            <input
                                              type="text"
                                              placeholder="Enter category"
                                              v-model="newCategory.name"
                                            />
                                            <button
                                              type="submit"
                                              @click="addName"
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          v-for="(
                                            category, index
                                          ) in categories"
                                          :key="index"
                                          class="soloemail_details"
                                        >
                                          <div
                                            class="modal_name modal_div d-flex justify-content-between align-items-center"
                                          >
                                            <input
                                              v-if="
                                                catNameedit == true &&
                                                catNameToBeEdited ==
                                                  category.name
                                              "
                                              type="text"
                                              placeholder="Enter Category Name"
                                              v-model="catNameEdited"
                                            />
                                            <h3
                                              v-if="
                                                catNameedit == false ||
                                                catNameToBeEdited !=
                                                  category.name
                                              "
                                            >
                                              {{ category.name }}
                                            </h3>

                                            <div class="modal_btn">
                                              <button
                                                v-if="
                                                  catNameedit == false ||
                                                  catNameToBeEdited !=
                                                    category.name
                                                "
                                                @click="editName(category.name)"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 512 512"
                                                  width="16px"
                                                  height="16px"
                                                  fill="#212121"
                                                >
                                                  <path
                                                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                                  />
                                                </svg>
                                              </button>
                                              <button
                                                v-if="
                                                  catNameedit == true &&
                                                  catNameToBeEdited ==
                                                    category.name
                                                "
                                                @click="
                                                  updateName(catNameEdited)
                                                "
                                              >
                                                <svg
                                                  version="1.1"
                                                  width="20px"
                                                  height="20px"
                                                  id="Layer_1"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                                  viewBox="0 0 32 32"
                                                  enable-background="new 0 0 32 32"
                                                  xml:space="preserve"
                                                >
                                                  <path
                                                    fill="none"
                                                    stroke="#212121"
                                                    stroke-width="3"
                                                    stroke-miterlimit="10"
                                                    d="M25.7,10.9C23.9,7.4,20.2,5,16,5
                                                                                                c-4.7,0-8.6,2.9-10.2,7"
                                                  />
                                                  <path
                                                    fill="none"
                                                    stroke="#212121"
                                                    stroke-width="3"
                                                    stroke-miterlimit="10"
                                                    d="M6.2,21c1.8,3.5,5.5,6,9.8,6c4.7,0,8.6-2.9,10.2-7"
                                                  />
                                                  <polyline
                                                    fill="none"
                                                    stroke="#212121"
                                                    stroke-width="3"
                                                    stroke-miterlimit="10"
                                                    points="26,5 26,11 20,11 "
                                                  />
                                                  <polyline
                                                    fill="none"
                                                    stroke="#212121"
                                                    stroke-width="3"
                                                    stroke-miterlimit="10"
                                                    points="6,27 6,21 12,21 "
                                                  />
                                                </svg>
                                              </button>
                                              <button
                                                @click="delName(category.name)"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 448 512"
                                                  width="16px"
                                                  height="16px"
                                                  fill="#212121"
                                                >
                                                  <path
                                                    d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                                                  />
                                                </svg>
                                              </button>
                                            </div>
                                          </div>
                                          <div class="modal_campaign">
                                            <div class="row">
                                              <div class="col details_label">
                                                <label>Model</label>
                                                <input
                                                  type="text"
                                                  placeholder="Enter model"
                                                  v-model="category.model"
                                                />
                                              </div>
                                              <div class="col details_label">
                                                <label>Rate</label>
                                                <input
                                                  type="text"
                                                  placeholder="Enter rate"
                                                  v-model="category.rate"
                                                />
                                              </div>
                                              <div class="col details_label">
                                                <label>Quantity</label>
                                                <input
                                                  type="text"
                                                  placeholder="Enter quantity"
                                                  v-model="category.quantity"
                                                />
                                              </div>
                                              <div class="col details_label">
                                                <label>Total</label>
                                                <input
                                                  type="text"
                                                  placeholder="Enter total"
                                                  v-model="category.total"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- <div class="campaing_select" v-else>
                                                                                <div class="email_template_tables">
                                                                                    <table>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>
                                                                                                    Name
                                                                                                </th>
                                                                                                <th>
                                                                                                    Quantity
                                                                                                </th>
                                                                                                <th>
                                                                                                    PRICE
                                                                                                </th>
                                                                                                <th>
                                                                                                    TOTAL
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody >
                                                                                            <tr v-for="(row, index) in campaign_categories" :key="index">
                                                                                                <td>
                                                                                                    {{row.name}}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {{row.quantity}}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {{row.rate}}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {{row.total}}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div class="table_total">
                                                                                        <ul>
                                                                                            <li>TOTAL</li>
                                                                                            <li><span class="green">${{}}</span></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div> -->
                                    </div>

                                    <div class="modal_button">
                                      <button
                                        @click="generateIO(io, transaction.id)"
                                      >
                                        Generate
                                      </button>
                                      <!-- <button v-if="campaign_categories != null" @click="exportToPDFInvoice(transaction)">
                                                                                Download
                                                                            </button> -->
                                    </div>
                                    <!-- <div class="modal_button" v-if="transaction.io_status == '1'">
                                                                            <button @click="exportToPDFInvoice(transaction.id)">
                                                                                Download
                                                                            </button>
                                                                        </div> -->
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td v-if="transaction.payment_status == 'Closed'">
                              <div v-if="transaction.id == editrow">
                                <select
                                  class="addNewInput"
                                  v-model="
                                    transaction.payment_completion_status
                                  "
                                  placeholder="Select"
                                >
                                  <option :value="blood" disabled>
                                    Select One
                                  </option>
                                  <option value="Pending">Pending</option>
                                  <option value="Paid">Paid</option>
                                </select>
                              </div>
                              <div v-else>
                                {{ transaction.payment_completion_status }}
                              </div>
                            </td>
                            <td v-else>NA</td>
                            <!-- <td v-if="transaction.payment_completion_status == 'Paid' ">
                                                            <div v-if="transaction.id == editrow">
                                                                <input v-model="transaction.payment_date" type="date" placeholder="Enter your date" />
                                                                <span v-if="v3$.date.$error" class="field-error">
                                                                    {{ v3$.date.$errors[0].$message }}
                                                                </span>
                                                            </div>
                                                            <div v-else>
                                                                {{ transaction.payment_date }}
                                                            </div>  
                                                        </td>
                                                        <td v-else>
                                                            NA
                                                        </td> -->
                            <td
                              v-if="
                                transaction.payment_completion_status == 'Paid'
                              "
                            >
                              <div v-if="transaction.id == editrow">
                                <select
                                  class="addNewInput"
                                  v-model="transaction.payment_method"
                                  placeholder="Select"
                                >
                                  <option :value="blood" disabled>
                                    Select One
                                  </option>
                                  <option value="Banking">Bank Transfer</option>
                                  <option value="Crypto">Crypto</option>
                                  <option value="Paypal">Paypal</option>
                                </select>
                              </div>
                              <div v-else>
                                <div v-if="transaction.payment_method != null">
                                  {{ transaction.payment_method }}
                                </div>
                                <div v-else>-</div>
                              </div>
                            </td>
                            <td v-else>NA</td>
                            <td>
                              <!-- <td v-if="transaction.payment_status == 'Closed'"> -->
                              <div v-if="transaction.id == editrow">
                                <select
                                  class="addNewInput"
                                  v-model="transaction.beneficiary"
                                  placeholder="Select"
                                >
                                  <option :value="blood" disabled>
                                    Select One
                                  </option>
                                  <option value="Reddensoft">Reddensoft</option>
                                  <option value="Lynkme">Lynkme 360</option>
                                  <option value="Bigwave">Bigwave</option>
                                </select>
                              </div>
                              <div v-else>
                                <div v-if="transaction.beneficiary != null">
                                  {{ transaction.beneficiary }}
                                </div>
                                <div v-else>-</div>
                              </div>
                              <!-- </td> -->
                            </td>
                            <td>{{ transaction.date }}</td>
                            <!-- <td>{{ transaction.invoice }}</td> -->
                            <td>
                              <div class="table_flex d-flex">
                                <button
                                  v-if="transaction.id == editrow"
                                  class="btn-lps"
                                  @click="updatedeal(transaction)"
                                >

                                  Update
                                </button>
                                <button
                                  v-else
                                  class="btn-lps"
                                  @click="editdeal(transaction.id)"
                                >
                                  <!-- <span>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="15" width="12" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                                                                    </span> -->
                                  Edit
                                </button>
                                <button
                                  class="btn-lps"
                                  @click="
                                    openiopopupBtn(
                                      transaction.id,
                                      transaction.payment_status
                                    )
                                  "
                                >
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                                    </span>
                                    IO
                                </button>
                                <button
                                  class="btn-lps"
                                  @click="exportToPDFInvoice(transaction.io_status,transaction.payment_status,transaction.beneficiary,transaction.id)"
                                >
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                                    </span>
                                   IO
                                </button>
                                <button
                                  class="btn-lps"
                                  @click="exportToPDFInvoice1(transaction.io_status,transaction.payment_status,transaction.beneficiary,transaction.id)"
                                >
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                                    </span>
                                    Invoice
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="table_total">
                      <ul>
                        <li>TOTAL</li>
                        <li></li>
                        <li>
                          Sales:
                          <span>${{ overallDealsTotal }}</span>
                        </li>
                        <li>
                          Received:
                          <span class="green">${{ closedDealsTotal }}</span>
                        </li>
                        <li></li>
                        <li>
                          Due:
                          <span class="red">${{ pendingDealsTotal }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button heading collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <h2>Latest Report</h2>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div class="table_div">
                      <table>
                        <thead>
                          <tr>
                            <!-- <th>Name</th> -->
                            <th>Date</th>
                            <th>Mail Sent</th>
                            <th>total Response</th>
                            <th>Positive Response</th>
                            <th>Leads Generated</th>
                            <th>Deals Closed</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in reportData" :key="index">
                            <td>
                              <span v-if="row.id != reporttablerowtobeedited">{{
                                row.date
                              }}</span>
                              <input
                                v-else
                                v-model="daily_report_details.date"
                                type="date"
                              />
                            </td>
                            <td>
                              <span v-if="row.id != reporttablerowtobeedited">{{
                                row.total_mail_sent
                              }}</span>
                              <input
                                v-else
                                v-model="daily_report_details.total_mail_sent"
                                type="number"
                              />
                            </td>
                            <td>
                              <span v-if="row.id != reporttablerowtobeedited">{{
                                row.total_response
                              }}</span>
                              <input
                                v-else
                                v-model="daily_report_details.total_response"
                                type="number"
                              />
                            </td>
                            <td>
                              <span v-if="row.id != reporttablerowtobeedited">{{
                                row.total_positive_response
                              }}</span>
                              <input
                                v-else
                                v-model="
                                  daily_report_details.total_positive_response
                                "
                                type="number"
                              />
                            </td>

                            <td>
                              <span v-if="row.id != reporttablerowtobeedited">{{
                                row.total_lead
                              }}</span>
                              <input
                                v-else
                                v-model="daily_report_details.total_lead"
                                type="number"
                              />
                            </td>
                            <td>
                              <span v-if="row.id != reporttablerowtobeedited">{{
                                row.lead_closed
                              }}</span>
                              <input
                                v-else
                                v-model="daily_report_details.lead_closed"
                                type="number"
                              />
                            </td>
                            <td>
                              <div class="table_flex d-flex">
                                <!-- <button class="btn-lps" @click="toggleEdit(row)">
                                                                    {{ row.id == reporttablerowtobeedited ? 'Update' : 'Edit' }}
                                                                </button> -->
                                <button
                                  class="btn-lps"
                                  @click="updateReportTable(row)"
                                  v-if="row.id == reporttablerowtobeedited"
                                >
                                  Update
                                </button>
                                <button
                                  class="btn-lps"
                                  @click="toggleEdit(row)"
                                  v-else
                                >
                                  Edit
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="table_total">
                      <ul>
                        <li>TOTAL</li>
                        <li></li>
                        <li>
                          Total Response:
                          <span>{{ Total_response_count }}</span>
                        </li>
                        <li>
                          Positive Response:
                          <span class="green">{{
                            Total_positive_response_count
                          }}</span>
                        </li>
                        <li>
                          Leads Generated:
                          <span class="red">{{ Total_leads_generated }}</span>
                        </li>
                        <li>
                          Deals Closed:
                          <span class="red">{{ Total_leads_closed }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/css/dashboard.css";
import EmployeHeader from "@/components/EmployeDashboard/EmployeHeader.vue";
import EmployeLeftbar from "@/components/EmployeDashboard/EmployeLeftbar.vue";
import { createLocal, createSession, createStorage } from "the-storages";
import { BASE_URL } from "../config";
import axios from "axios";
import { reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { required, email } from "@vuelidate/validators";
const mirror = createLocal();
const storage = mirror._prx;
import { ref } from "vue";
import html2pdf from "html2pdf.js";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "Dashboard",
  components: {
    EmployeHeader,
    EmployeLeftbar,
    Datepicker,
  },
  setup() {
    const newTransaction = reactive({
      clientName: "",
      amount: "",
      paymentStatus: "",
      date: "",
      invoice: "",
      beneficiary: "",
    });

    const io = reactive({
      company_name: "",
      company_address: "",
      contact_person_name: "",
      contact_person_reg_no: "",
      contact_person_email: "",
      web_app_name: "",
      start_date: "",
      end_date: "",
      placemnet: "",
      total_budget: "",
      solo_email_model: "",
      solo_email_rate: "",
      solo_email_quantity: "",
      solo_email_total: "",
      email_template_model: "",
      email_template_rate: "",
      email_template_quantity: "",
      email_template_total: "",
    });

    const daily_report = reactive({
      date: "",
      total_mail_sent: 0,
      total_positive_response: 0,
      total_response: 0,
      total_lead: 0,
      lead_closed: 0,
    });

    const rules3 = computed(() => {
      return {
        clientName: { required },
        amount: { required },
        paymentStatus: { required },
        date: { required },
        beneficiary: { required },
        //     orgname: { required },
        //     email: { required, email },
        //     phone: { required, minLength: minLength(10), maxLength: maxLength(12) },
        //     country:{ required },
        // //   timezone:{ required },
        //     states:{ required },
        // //   dateformat:{ required },
        //     password:{ required, minLength: minLength(8)},
        //     confirmpassword:{ required, sameAsPassword: sameAs(state3.password),},
      };
    });
    const v3$ = useValidate(rules3, newTransaction);

    const selectedData = ref([]);

    function handleItemClick(title) {
      console.log("chgrdtr");
      if (!selectedData.value.includes(title)) {
        selectedData.value.push(title);
      } else {
        selectedData.value = selectedData.value.filter(
          (item) => item !== title
        );
      }
    }

    const menuItems = [{ title: "Solo Email" }, { title: "Email Template" }];

    const daily_report_details = reactive({
      date: "",
      total_mail_sent: "",
      total_positive_response: "",
      total_response: "",
      total_lead: "",
      lead_closed: "",
    });

    return {
      selectedData,
      handleItemClick,
      menuItems,
      v3$,
      newTransaction,
      io,
      daily_report,
      daily_report_details,
    };
  },

  data() {
    return {
      isClassAdded: false,
      isClassAdded1: false,
      isClassAdded2: false,
      isClassAdded3: false,
      clockHide: true,
      buttonBackground: "#3cb89b",
      currentTime: "00:00:00",
      buttonText: "Check In",
      checkInTime: null,
      checkOutTime: null,
      showPopup: true,
      categories: [],

      newName: "",
      names: [],

      // modal data

      // newTransaction: {
      //         clientName: "",
      //         amount: "",
      //         paymentStatus: "",
      //         date: "",
      //         invoice: ""
      // },
      transactions: [],

      // table data

      transactions: [
        {
          memberName: "Dipak",
          clientName: "AB Enterprize",
          amount: "$1500",
          paymentStatus: "Completed Via Bank",
          date: "14/01/24",
          invoice: "Link (12/01/24)",
        },
        {
          memberName: "Dipak",
          clientName: "AB Enterprize",
          amount: "$1500",
          paymentStatus: "Completed Via Bank",
          date: "14/01/24",
          invoice: "Link (12/01/24)",
        },
        {
          memberName: "Dipak",
          clientName: "AB Enterprize",
          amount: "$1500",
          paymentStatus: "Completed Via Bank",
          date: "14/01/24",
          invoice: "Link (12/01/24)",
        },
        {
          memberName: "Dipak",
          clientName: "AB Enterprize",
          amount: "$1500",
          paymentStatus: "Completed Via Bank",
          date: "14/01/24",
          invoice: "Link (12/01/24)",
        },
        {
          memberName: "Dipak",
          clientName: "AB Enterprize",
          amount: "$1500",
          paymentStatus: "Completed Via Bank",
          date: "14/01/24",
          invoice: "Link (12/01/24)",
        },
        {
          memberName: "Dipak",
          clientName: "AB Enterprize",
          amount: "$1500",
          paymentStatus: "Completed Via Bank",
          date: "14/01/24",
          invoice: "Link (12/01/24)",
        },
      ],

      tableData: [
        {
          Name: "Kuntal Patra",
          Date: "2024-02-15",
          MailSent: 10,
          Response: 5,
          Leads: 5,
          Closed: 2,
        },
      ],

      dealdetails: [],
      editrow: "",
      btnName: "Edit",
      marketing_status: "",
      month_name: "",
      picked_year: "",
      month_proper_name: "",
      teamTarget: "",
      personalTarget: "",
      closedPersonalDeal: "",
      pendingPersonalDeal: "",
      closedPersonalDealCount: "",
      closedTeamDealCount: "",
      closedTeamDealDetails: "",
      personCompletionPercentage: "",
      teamCompletionPercentage: "",
      empname: "",
      openiopopup: false,
      reportData: "",
      reporttablerowtobeedited: "",
      newCategory: {
        name: "",
        model: "",
        rate: "",
        quantity: "",
        total: "",
      },
      PersonalPaymentRecievedTotal: "",
      PersonalPaymentDueTotal: "",
      // categories: []
      invoiceid: "",
      campaign_categories: [],
      // campaign_data:[]
      pdfdata: "",
      paymentCompletionCount: "",
      paymentCompletionTotal: "",
      dealpaidfor: "",
      monthValue: "",
      lastQuarterOverdue: "",
      submittingDailyReport: false,
      catNameedit: false,
      catNameEdited: "",
      catNameToBeEdited: "",
      teamPaymentCompletionCount: "",
      teamDealpaidfor: "",
      teamPaymentCompletionTotal: "",
    };
  },
  async created(event) {
    this.marketing_status = mirror.marketing_status;
    this.empname = mirror.USERNAME;
    const date = new Date();
    this.picked_year = date.getFullYear();
    this.month_name = date.getMonth() + 1;
    const month_name_array = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month_proper_name = month_name_array[date.getMonth()];
    this.showdealdetails();
    this.showdailyreport();
  },

  computed: {
    closedDealsTotal() {
      let total = 0;
      // Iterate through dealdetails and sum the amount where payment_status is "Closed"
      this.dealdetails.forEach((transaction) => {
        if (transaction.payment_completion_status === "Paid") {
          total += parseInt(transaction.amount);
        }
      });
      return total; // You can format the total as needed
    },
    pendingDealsTotal() {
      let total = 0;
      // Iterate through dealdetails and sum the amount where payment_status is "Closed"
      this.dealdetails.forEach((transaction) => {
        if (transaction.payment_completion_status === "Pending") {
          total += parseInt(transaction.amount);
        }
      });
      return total; // You can format the total as needed
    },
    overallDealsTotal() {
      let total = 0;
      console.log("reportObj", this.reportData);

      if (Array.isArray(this.dealdetails)) {
        console.log("myVariable is an array 1");
      } else {
        console.log("myVariable is not an array");
      }
      // Iterate through dealdetails and sum the amount where payment_status is "Closed"
      this.dealdetails.forEach((transaction) => {
        if (transaction.payment_status == "Pending" || "Closed") {
          total += parseInt(transaction.amount);
        }
      });
      return total; // You can format the total as needed
    },
    Total_response_count() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.total_response);
      }, 0); // Starting with an initial value of 0
    },

    Total_positive_response_count() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.total_positive_response);
      }, 0); // Starting with an initial value of 0
    },
    Total_leads_generated() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.total_lead);
      }, 0); // Starting with an initial value of 0
    },
    Total_leads_closed() {
      if (this.reportData.length === 0) {
        return 0; // Return 0 if reportData is empty
      }

      // Using reduce to sum up the total_mail_sent values
      return this.reportData.reduce((total, row) => {
        // Parsing the value to integer and adding it to the total
        return total + parseInt(row.lead_closed);
      }, 0); // Starting with an initial value of 0
    },
    currentWeek() {
      const currentDate = new Date();
      const daysPassed = currentDate.getDate();
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      const weeksInMonth = Math.ceil(daysInMonth / 7);

      // Calculate the week number in the context of the entire month
      let currentWeekOfMonth = Math.ceil(daysPassed / 7);
      if (currentWeekOfMonth > weeksInMonth) {
        currentWeekOfMonth = weeksInMonth; // Cap at the maximum number of weeks in the month
      }
      return currentWeekOfMonth;
    },

    daysLeft() {
      const currentDate = new Date();
      const daysPassed = currentDate.getDate();
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();

      // Calculate days left in the month
      const daysLeft = daysInMonth - daysPassed;

      return daysLeft;
    },
    message() {
      const progress = this.PersonalPaymentRecievedTotal;
      console.log("progress", progress);
      const targetSet = this.personalTarget;
      console.log("targetSet", targetSet);
      const weeklyTarget = targetSet / 4;

      if (this.currentWeek === 1) {
        if (progress > weeklyTarget)
          return "Off to a great start! You're well ahead of schedule.";
        else if (progress > weeklyTarget * 0.75)
          return "You're making good progress. Keep it up!";
        else return "You're slightly behind. Let's pick up the pace.";
      } else if (this.currentWeek === 2) {
        if (progress > weeklyTarget * 2)
          return "Excellent work! You're halfway there already.";
        else if (progress > weeklyTarget * 1.5)
          return "You're on track to meet your target. Keep pushing!";
        else
          return "You're falling behind a bit. Let's aim to catch up this week.";
      } else if (this.currentWeek === 3) {
        if (progress > weeklyTarget * 3)
          return "You're almost there! Just one more week to go.";
        else if (progress > weeklyTarget * 2.25)
          return "You're making good progress, but there's still room for improvement.";
        else return "You're cutting it close. Let's focus and finish strong.";
      } else if (this.currentWeek === 4) {
        if (progress >= targetSet)
          return "Congratulations! You've met your monthly target. Well done! lets keep pushing for more";
        else if (progress > weeklyTarget * 3)
          return "You're on the verge of success. Keep pushing until the end.";
        else
          return "You're almost there, but not quite. Let's give it our all in the final stretch.";
      } else if (this.currentWeek === 5) {
        if (progress >= targetSet)
          return "Congratulations! You've surpassed your target. Great Job! lets aim even higher now!";
        else if (progress > weeklyTarget * 3.75)
          return "You're very close to reaching your monthly target. Keep pushing!";
        else return "You need to hurry up, otherwise you will miss the goal.";
      } else {
        return "Target completed!";
      }
    },
  },

  methods: {
    // addName() {
    //     console.log("cat name",this.newCategory.name);
    //     console.log("cat",this.categories);
    //     if (!this.categories) {
    //         this.categories = []; // Initialize it as an empty array if it's null
    //     }
    //     if (this.newCategory.name.trim() !== '') {
    //         this.categories.push({ ...this.newCategory });
    //         this.newCategory = {
    //         name: '',
    //         model: '',
    //         rate: '',
    //         quantity: '',
    //         total: ''
    //         };
    //     }
    // },
    validateDate() {
      const enteredDate = new Date(this.newTransaction.date);
      const currentDate = new Date();

      if (enteredDate > currentDate) {
        this.tostMessage("warning", "You can not add a deal on a future date.");
        this.newTransaction.date = "";
        // If the entered date is in the future, reset the date to today's date
        // this.newTransaction.date = currentDate.toISOString().slice(0, 10); // Set to YYYY-MM-DD format
        // You can also display a message to the user indicating that future dates are not allowed
        // For example: alert("Please select a date that is not in the future.");
      }
    },
    addName() {
      if (!this.categories) {
        this.categories = []; // Initialize it as an empty array if it's null
      }

      const categoryName = this.newCategory.name.trim();

      if (categoryName !== "") {
        // Check if a category with the same name already exists
        const nameExists = this.categories.some(
          (category) => category.name === categoryName
        );

        if (!nameExists) {
          this.categories.push({ ...this.newCategory });
          this.newCategory = {
            name: "",
            model: "",
            rate: "",
            quantity: "",
            total: "",
          };
        } else {
          // Handle case where category name already exists
          console.log("Category with this name already exists.");
          this.tostMessage(
            "warning",
            "Category with this name already exists."
          );

          // You can display a message to the user or perform any other action
        }
      }
    },

    async openiopopupBtn(id, deal_status) {
      if (deal_status == "Closed") {
        this.openiopopup = true;
        this.invoiceid = id;
        await axios
          .post(BASE_URL + "/api/getinvoicedataEmp", {
            id: id,
          })
          .then((response) => {
            console.log("ressssss", response.data);
            this.io.company_name = response.data.iodetails.company_name;
            this.io.company_address = response.data.iodetails.company_address;
            this.io.contact_person_name =
              response.data.iodetails.contact_person_name;
            this.io.contact_person_reg_no =
              response.data.iodetails.contact_person_reg_no;
            this.io.contact_person_email =
              response.data.iodetails.contact_person_email;
            this.io.web_app_name = response.data.iodetails.web_app_name;
            this.io.start_date = response.data.iodetails.start_date;
            this.io.end_date = response.data.iodetails.end_date;
            this.io.placemnet = response.data.iodetails.placemnet;
            this.io.total_budget = response.data.iodetails.total_budget;
            this.campaign_categories = response.data.iocampaigndetails;
            this.categories = response.data.iocampaigndetails;
          });

        console.log("cat", this.campaign_categories);
      } else {
        this.openiopopup = false;
      }
    },
    closeiopopup() {
      this.openiopopup = false;
    },
    toggleClass() {
      this.isClassAdded = !this.isClassAdded;
    },
    toggleClass1() {
      this.isClassAdded1 = !this.isClassAdded1;
    },
    toggleClass2() {
      this.isClassAdded2 = !this.isClassAdded2;
    },
    toggleClass3() {
      this.isClassAdded3 = !this.isClassAdded3;
    },
    toggleEdit(row) {
      this.reporttablerowtobeedited = row.id;
      this.daily_report_details.total_mail_sent = row.total_mail_sent;
      this.daily_report_details.total_positive_response =
        row.total_positive_response;
      this.daily_report_details.total_response = row.total_response;
      this.daily_report_details.total_lead = row.total_lead;
      this.daily_report_details.lead_closed = row.lead_closed;
    },

    addRow() {
      const newRow = {
        Name: "",
        Date: "",
        MailSent: 0,
        Response: 0,
        Leads: 0,
        Closed: 0,
        isEditing: false,
      };
      this.tableData.push(newRow);
    },

    toggleClock() {
      if (this.clockHide) {
        // Check In
        this.checkInTime = new Date();
        this.timer = setInterval(() => {
          // Update currentTime every second
          this.currentTime = this.formatTime(new Date() - this.checkInTime);
        }, 1000);
      } else {
        // Check Out
        this.checkOutTime = new Date();
        clearInterval(this.timer);
        this.currentTime = "00:00:00"; // Reset timer display
      }

      this.clockHide = !this.clockHide;
      this.buttonBackground =
        this.buttonBackground === "#3cb89b" ? "#e95b6d" : "#3cb89b";
      this.buttonText = this.clockHide ? "Check In" : "Check Out";
    },
    formatTime(ms) {
      // Format milliseconds to HH:mm:ss
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / (1000 * 60)) % 60);
      const hours = Math.floor(ms / (1000 * 60 * 60));

      const formattedTime = `${this.padZero(hours)}:${this.padZero(
        minutes
      )}:${this.padZero(seconds)}`;
      return formattedTime;
    },
    padZero(value) {
      // Add leading zero if needed
      return value < 10 ? `0${value}` : value;
    },
    closePopup() {
      this.showPopup = false;
    },

    // async submitForm() {
    //     this.v3$.$validate(this.v3$.$error);
    //     console.log("ftftft",this.v3$.$error);

    //     if (!this.v3$.$error) {
    //         var concatted_date = this.newTransaction.date.split("-")
    //         console.log("concatted_date",concatted_date);
    //         var month = concatted_date[1];
    //         var year = concatted_date[0];
    //         this.transactions.push({
    //             memberName: "Example Member", // You can set a default value or fetch it from somewhere
    //             clientName: this.newTransaction.clientName,
    //             amount: this.newTransaction.amount,
    //             paymentStatus: this.newTransaction.paymentStatus,
    //             date: this.newTransaction.date,
    //             invoice: this.newTransaction.invoice,

    //         });
    //         console.log("date",this.newTransaction.date);

    //         await axios.post(BASE_URL + '/api/submitnewdeal',{
    //             'id': mirror.ID,
    //             'empname': mirror.ID,
    //             'clientName': this.newTransaction.clientName,
    //             'amount': this.newTransaction.amount,
    //             'paymentStatus': this.newTransaction.paymentStatus,
    //             'date': this.newTransaction.date,
    //             'invoice': this.newTransaction.invoice,
    //             'year': year,
    //             'month': month,
    //         })
    //         .then((response)=>{
    //         this.tostMessage(response.data.success, response.data.message);
    //         this.dealdetails = response.data.dealdetails;
    //         });

    //         // Clear the form fields after submission
    //         this.newTransaction = {
    //             clientName: "",
    //             amount: "",
    //             paymentStatus: "",
    //             date: "",
    //             invoice: ""
    //         };
    //         this.toggleClass2();
    //         this.showdealdetails();
    //     }
    //     else{
    //         console.log("error");
    //     }

    // },

    async submitForm() {
      this.v3$.$validate(this.v3$.$error);

      if (!this.v3$.$error) {
        var concatted_date = this.newTransaction.date.split("-");
        var month = concatted_date[1];
        var year = concatted_date[0];

        // Check if the deal already exists
        const dealExists = this.transactions.some((deal) => {
          return (
            deal.clientName === this.newTransaction.clientName &&
            deal.amount === this.newTransaction.amount &&
            deal.paymentStatus === this.newTransaction.paymentStatus &&
            deal.date === this.newTransaction.date &&
            deal.invoice === this.newTransaction.invoice &&
            deal.beneficiary === this.newTransaction.beneficiary
          );
        });

        // If the deal does not exist, add it
        if (!dealExists) {
          this.transactions.push({
            memberName: "Example Member",
            clientName: this.newTransaction.clientName,
            amount: this.newTransaction.amount,
            paymentStatus: this.newTransaction.paymentStatus,
            date: this.newTransaction.date,
            invoice: this.newTransaction.invoice,
            beneficiary: this.newTransaction.beneficiary,
          });

          // Make the axios request
          await axios
            .post(BASE_URL + "/api/submitnewdeal", {
              id: mirror.ID,
              empname: mirror.ID,
              clientName: this.newTransaction.clientName,
              amount: this.newTransaction.amount,
              paymentStatus: this.newTransaction.paymentStatus,
              date: this.newTransaction.date,
              invoice: this.newTransaction.invoice,
              beneficiary: this.newTransaction.beneficiary,
              year: year,
              month: month,
            })
            .then((response) => {
              this.tostMessage(response.data.success, response.data.message);
              this.dealdetails = response.data.dealdetails;
            });

          // Clear the form fields after submission
          this.newTransaction = {
            clientName: "",
            amount: "",
            paymentStatus: "",
            date: "",
            invoice: "",
            beneficiary: "",
          };
          this.toggleClass2();
          this.showdealdetails();
        } else {
          console.log("Deal already exists.");
        }
      } else {
        console.log("Error in form validation.");
      }
    },

    async showdealdetails() {
      await axios
        .post(BASE_URL + "/api/showdealdetails", {
          id: mirror.ID,
          status: mirror.marketing_status,
          month: this.month_name,
          year: this.picked_year,
        })
        .then((response) => {
          this.dealdetails = response.data.dealdetails;
          this.personalTarget = response.data.personalTarget;
          this.teamTarget = response.data.teamTarget;
          this.closedPersonalDeal = response.data.closedPersonalDeal;
          this.pendingPersonalDeal = response.data.pendingPersonalDeal;
          this.closedPersonalDealCount = response.data.closedPersonalDealCount;
          this.closedTeamDealCount = response.data.closedTeamDealCount;
          this.closedTeamDealDetails = response.data.closedTeamDealDetails;
          this.closedTeamDealTotal = response.data.closedTeamDealTotal;
          // console.log("personCompletionPercentage",this.paymentCompletionTotal);
          this.teamCompletionPercentage = parseInt(
            (this.closedTeamDealTotal * 100) / this.teamTarget
          );
          this.PersonalPaymentRecievedTotal =
            response.data.PersonalPaymentRecievedTotal;
          this.paymentCompletionCount = response.data.paymentCompletionCount;
          this.paymentCompletionTotal = response.data.paymentCompletionTotal;
          this.dealpaidfor = response.data.dealpaidfor;
          this.monthValue = response.data.monthValue;
          this.lastQuarterOverdue = response.data.lastQuarterOverdue;
          this.PersonalPaymentDueTotal = response.data.PersonalPaymentDueTotal;
          this.personCompletionPercentage = parseInt(
            (this.paymentCompletionTotal * 100) / this.personalTarget
          );
          this.teamPaymentCompletionCount =
            response.data.teamPaymentCompletionCount;
          this.teamDealpaidfor = response.data.teamDealpaidfor;
          this.teamPaymentCompletionTotal =
            response.data.teamPaymentCompletionTotal;

          console.log(
            "teamCompletionPercentage",
            this.teamCompletionPercentage
          );
        });
    },

    tostMessage(status, message) {
      console.log("status", status);
      createToast(
        {
          // title: title,
          description: message,
        },
        {
          type: status,
          timeout: 2500,
          position: "bottom-right",
          showIcon: "true",
        }
      );
    },

    editdeal(id) {
      this.btnName = "Update";
      this.editrow = id;
    },

    async updatedeal(details) {
      console.log("details", details);
      if (details.payment_completion_status == "Paid") {
        if (details.payment_method == null) {
          details.payment_completion_status == "Pending";
          this.tostMessage("warning", "Please add payment method first!");
          this.showdealdetails();
        } else if (details.beneficiary == null) {
          details.payment_completion_status == "Pending";
          this.tostMessage("warning", "Please add beenficiary details first!");
          this.showdealdetails();
        } else {
          this.editrow = "";

          await axios
            .post(BASE_URL + "/api/updatedealdetails", {
              id: mirror.ID,
              client: details.client,
              amount: details.amount,
              status: details.payment_status,
              deal_id: details.id,
              payment_status: details.payment_completion_status,
              payment_method: details.payment_method,
              month: details.month,
              year: details.year,
              payment_date: details.payment_date,
              beneficiary: details.beneficiary,
            })
            .then((response) => {
              this.showdealdetails();
            });
        }
      } else {
        this.editrow = "";

        await axios
          .post(BASE_URL + "/api/updatedealdetails", {
            id: mirror.ID,
            client: details.client,
            amount: details.amount,
            status: details.payment_status,
            deal_id: details.id,
            payment_status: details.payment_completion_status,
            payment_method: details.payment_method,
            month: details.month,
            year: details.year,
            payment_date: details.payment_date,
            beneficiary: details.beneficiary,
          })
          .then((response) => {
            this.showdealdetails();
          });
      }
    },

    async generateIO(io, id) {
      console.log("id", id);
      console.log("io", io);
      console.log("newCategory", this.newCategory);
      console.log("categories", this.categories);
      if (this.categories != null) {
        await axios
          .post(BASE_URL + "/api/saveioDetails", {
            io: io,
            id: this.invoiceid,
            category: this.categories,
          })
          .then((response) => {
            this.io.company_name = "";
            this.io.company_address = "";
            this.io.contact_person_name = "";
            this.io.contact_person_reg_no = "";
            this.io.contact_person_email = "";
            this.io.web_app_name = "";
            this.io.start_date = "";
            this.io.end_date = "";
            this.io.placemnet = "";
            this.io.total_budget = "";
            this.io.solo_email_model = "";
            this.io.solo_email_rate = "";
            this.io.solo_email_quantity = "";
            this.io.solo_email_total = "";
            this.io.email_template_model = "";
            this.io.email_template_rate = "";
            this.io.email_template_quantity = "";
            this.io.email_template_total = "";
            this.catNameToBeEdited = "";
            this.catNameEdited = "";
            this.closeiopopup();
            this.showdealdetails();
          });
        this.tostMessage("success", "IO generated successfully");
      } else {
        this.tostMessage("warning", "Category deatils can not be blank!");
      }
    },

    async submitDailyReport() {
      // Disable the button to prevent multiple submissions
      this.submittingDailyReport = true;

      var concatted_date = this.daily_report.date.split("-");
      console.log("concatted_date", this.daily_report.date);
      var month = concatted_date[1];
      var year = concatted_date[0];

      if (this.daily_report.date == "") {
        this.tostMessage("warning", "Please provide the date!");
      } else {
        try {
          // Make the axios request
          const response = await axios.post(
            BASE_URL + "/api/submitdailyreport",
            {
              id: mirror.ID,
              empname: mirror.USERNAME.concat(" ", mirror.USERLNAME),
              total_mail_sent: this.daily_report.total_mail_sent,
              total_positive_response:
                this.daily_report.total_positive_response,
              total_response: this.daily_report.total_response,
              date: this.daily_report.date,
              year: year,
              month: month,
              total_lead: this.daily_report.total_lead,
              lead_closed: this.daily_report.lead_closed,
            }
          );

          this.tostMessage(response.data.success, response.data.message);
          // Clear the form fields after successful submission
          this.daily_report = {
            total_mail_sent: "",
            total_positive_response: "",
            total_response: "",
            date: "",
            total_lead: "",
            lead_closed: "",
          };
          this.toggleClass3();
        } catch (error) {
          console.error("Error submitting daily report:", error);
          this.tostMessage(
            "error",
            "An error occurred while submitting the daily report."
          );
        } finally {
          // Re-enable the button after submission (whether successful or failed)
          this.submittingDailyReport = false;
        }
      }

      this.showdailyreport();
    },

    async showdailyreport() {
      await axios
        .post(BASE_URL + "/api/showdailyreport", {
          id: mirror.ID,
          month: this.month_name,
          year: this.picked_year,
        })
        .then((response) => {
          this.reportData = response.data.reportdetails;
          console.log("reportData", this.reportData);
        });
    },

    async updateReportTable(row) {
      console.log("daily_report_details", this.daily_report_details);
      var concatted_date = this.daily_report_details.date.split("-");
      // console.log("concatted_date",concatted_date);
      var month = concatted_date[1];
      var year = concatted_date[0];
      // this.editrow = '';
      await axios
        .post(BASE_URL + "/api/updateemreportdetails", {
          id: row.id,
          total_mail_sent: this.daily_report_details.total_mail_sent,
          total_positive_response:
            this.daily_report_details.total_positive_response,
          total_response: this.daily_report_details.total_response,
          date: this.daily_report_details.date,
          total_lead: this.daily_report_details.total_lead,
          lead_closed: this.daily_report_details.lead_closed,
          month: month,
          year: year,
        })
        .then((response) => {
          this.reporttablerowtobeedited = "";
          this.showdailyreport();
        });
    },

    async toDataUrl() {
      await fetch("https://cdn.logo.com/hotlink-ok/logo-social.png")
        .then((response) => response.blob())
        .then((blob) => {
          // Read the image data as a Data URL
          const reader = new FileReader();
          reader.onloadend = () => {
            // Convert the Data URL to base64
            const base64Data = reader.result.split(",")[1];
            console.log(base64Data);
            return base64Data;
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error("Error fetching or converting the image:", error);
        });
    },

    getMonthName(monthIndex) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // Ensure monthIndex is within valid range
      if (monthIndex >= 1 && monthIndex <= 12) {
        return months[monthIndex - 1]; // Adjust index to start from 0
      } else {
        return "Invalid Month";
      }
    },

    delName(name) {
      console.log("cat", this.categories);
      console.log("cat name", name);
      const index = this.categories.findIndex(
        (category) => category.name === name
      );
      if (index !== -1) {
        this.categories.splice(index, 1);
      }
    },

    editName(name) {
      this.catNameedit = true;
      this.catNameToBeEdited = name;
      this.catNameEdited = name;
    },

    updateName(newName) {
      const isNameAlreadyExists = this.categories.some(
        (category) => category.name === newName
      );

      if (!isNameAlreadyExists) {
        // Find the category to edit
        const categoryToEdit = this.categories.find(
          (category) => category.name === this.catNameToBeEdited
        );

        if (categoryToEdit) {
          // Update the name of the category
          categoryToEdit.name = newName;
        } else {
          console.error("Category not found for editing.");
        }
      } else {
        console.error("New name already exists in another category.");
      }

      this.catNameToBeEdited = "";
      this.catNameEdited = "";
    },

    async exportToPDFInvoice1(io, status, beneficiary, id) {

        if (beneficiary == null) {
        this.tostMessage("warning", "Please add benificiary details first");
      } else if (io == "1") {
            const toDataUrl = async function (url, callback) {
            //Convert to base64
            return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = () => {
                reject({
                status: this.status,
                statusText: xhr.statusText,
                });
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
            });
        };
        console.log("this.invoice.id", this.invoiceid);

        // var url = "https://works-reddensoft.com/lms/admin/public/assets/signature.png";
        // var url =
        //   "https://works-reddensoft.com/lms/admin/public/assets/reddensoft_logo.svg";
        var url = BASE_URL + "/public/assets/reddensoft_logo.svg";
        let urlBase64 = await toDataUrl(url);
        axios
            .post(BASE_URL + "/api/fetchDealDataForIO", { id: id })
            .then((response) => {
            console.log(response.data.iodetails);

            this.pdfdata = response.data.iodetails;
            //this.htmltopdf(this.salarydata);
            //var html = `<h1>${this.cl_slip_payable}</h1>`;

            //html2pdf(html);

            this.htmltopdfInvoice1(this.pdfdata, urlBase64);
            });
      } else if (status == "Closed") {
        this.tostMessage(
          "warning",
          "IO deatils has not been updated for this deal"
        );
      } else {
        this.tostMessage("warning", "This deal is not close yet");
      }
      console.log("vdgvd");

    },

    async htmltopdfInvoice1(res, logo) {
      console.log("res", res);
      const resvalue = Object.values(res);
      console.log("resvalue", resvalue);
      const items = JSON.parse(resvalue[25]);
      console.log("items", items);
      var ioid = String(resvalue[0]).padStart(3, "0");

      // var htmldemo = `
      //         <style></style>
      //         <div style="width:100%; padding:20px " id="pdfFormat">

      //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                 <tbody>
      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
      //                                 Big Wave Development LLC
      //                             </p>
      //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
      //                                 www.bigwavedevelopment.com
      //                             </a>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#${ioid}</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <thead>
      //                                     <tr>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
      //                                         </th>
      //                                     </tr>
      //                                 </thead>
      //                                 <tbody>

      //                                     ${items.map(item => `
      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
      //                                         </td>
      //                                     </tr>
      //                                     `).join('')}

      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
      //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span>
      //                             </p>
      //                         </td>
      //                     </tr>

      //                 </tbody>
      //             </table>
      //         `;

      var htmllynkmedemo = `    <div style="padding: 0;">
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td>
                                <span style="width: 100%;height: 60px;background: #7ed956;display: block;"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="padding-right: 20px;">
                                <span style="width: 490px;height: 15px;background: #008235;display: block;margin-left: auto;"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>    
                <div style="padding: 0 20px;">
                    <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td>
                                    <span style="width: 100%;height: 40px;background: #fff;display: block;"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td style="padding-top: 10px;">
                                    <h2 style="font-family: sans-serif;color: #000;font-size: 24px;margin: 0;letter-spacing: 4px;">
                                        LYNKME
                                        <span style="padding: 0 2px;">360</span>
                                        LLC
                                    </h2>
                                </td>
                                <td style="padding-top: 10px;">
                                    <h2 style="font-family: sans-serif;color: #000;font-size: 40px;text-align: right;margin: 0;">
                                        INVOICE
                                    </h2>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding-top: 30px;">
                                    <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                        BILLED TO:
                                    </p>
                                    <h2 style="font-family: sans-serif;color: #000;font-size: 20px;margin: 0;font-weight: 600;padding-bottom: 4px;">
                                        ${resvalue[15]}

                                    </h2>
                                    <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                        ${resvalue[16]}

                                    </p>
                                    <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;">
                                        Email: ${resvalue[19]}
                                    </p>
                                </td>
                                <td style="padding-top: 30px;">
                                    <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;text-align: right;">
                                        Invoice Number: ${ioid}
                                    </p>
                                    <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 4px;text-align: right;">
                                        ${resvalue[8]}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <span style="width: 100%;height: 20px;background: #fff;display: block;"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table> 
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <thead>
                                    <tr>
                                        <th style="background: #008036;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                            ITEM DESCRIPTION
                                        </th>
                                        <th style="background: #7ed956;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                            VOLUME
                                        </th>
                                        <th style="background: #7ed956;padding: 14px 18px;font-family: sans-serif;color: #fff;font-size: 18px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                            Total($)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                ${items
                                  .map(
                                    (item) => `

                                    <tr>
                                        <td style="border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                            ${item.name}
                                        </td>
                                        <td style="border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                            ${item.quantity}
                                        </td>
                                        <td style="background: #ede9e8; border-bottom: 3px solid #a7a7a7 ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;letter-spacing: 2px;">
                                            ${item.total}
                                        </td>
                                    </tr>
                                `
                                  )
                                  .join("")}

                                    <tr>
                                        <td style="border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 700;text-align: left;letter-spacing: 2px;">
                                        TOTAL AMOUNT:
                                        </td>
                                        <td style="border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 600;text-align: left;letter-spacing: 2px;">
                                        </td>
                                        <td style="background: #ede9e8; border-bottom: 5px solid #14703d ;padding: 14px 18px;font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 700;text-align: left;letter-spacing: 2px;">
                                            $${resvalue[24]}
                                        </td>
                                    </tr>
                                </tbody>
                            </tbody>
                        </table>
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="padding-top: 20px; font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;text-decoration: underline;padding-bottom: 10px;">
                                        Please make payments to:
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                        Wire Transfer Details:
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                        BENEFICIARY NAME: LynkMe360 LLC
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                        BANK NAME: PNC Bank
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                        Account Number: 1251348057
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                        Routing Number: 267084199
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                        Swift Code: PNCCUS33
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 700;text-align: left;padding-bottom: 4px;padding-left: 3px;">
                                        BANK ADDRESS: PNC Bank, 218 Datura St. West Palm Beach, FL 33401-5679
                                    </td>
                                </tr>
                            </tbody>
                        </table>    
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;padding-bottom: 40px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="padding-top: 40px; font-family: sans-serif;color: #000;font-size: 20px;margin: 0;font-weight: 700;padding-bottom: 4px;text-align: right;">
                                        LynkMe 360 LLC
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 3px;text-align: right;">
                                        2630 W Broward BLVD Suite 203 - 1713 Fort Lauderdale,
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;text-align: right;">
                                        FL 33312 United States, Tel: +1 727 440 5867
                                    </td>
                                </tr>
                            </tbody>
                        </table>            
                    </table>   
                    <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td>
                                    <span style="width: 100%;height: 40px;background: #fff;display: block;"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                </div>
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="padding: 0 20px;">
                                <span style="width: 100%;height: 15px;background: #008235;display: block;margin-left: auto;padding: 0 20px;"></span>
                            </td>
                        </tr>
                    </tbody>
                </table> 
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td>
                                <span style="width: 100%;height: 60px;background: #7ed956;display: block;"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>  
                    </div>`;

      var htmlreddensoftdemo =
        `    <div style="padding: 20px;">
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="text-align: center;">
                                <a href="#" style="font-size: 16px;font-weight: 700;color: #000;padding:3px; margin: 0;">
                                            <img src="` +
        logo +
        `" alt="logo" style="width: 200px;margin: auto;">
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>  
                        <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="padding-top: 40px;width: 60%;">
                                        <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 18px;margin: 0;letter-spacing: 1px;">
                                            INVOICE: #${ioid}
                                        </h2>
                                    </td>
                                    <td style="padding-top: 10px;width: 40%;">
                                        <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 18px;margin: 0;letter-spacing: 1px;">
                                            DATE: ${resvalue[8]}
                                        </h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-top: 30px;padding-bottom: 30px;width: 60%;">
                                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;padding-right: 15px;">
                                            Reddensoft Infotech Pvt. Ltd.
                                        </p>
                                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;padding-right: 15px;">
                                            AMP Baishaki, 7th floor, Room 705, AG-112, Sec-II, Salt Lake, Kolkata -700091
                                        </p>
                                        <p style="padding-bottom: 6px;margin: 0;">
                                            <a href="#" style="color: #6767ff;font-family: sans-serif;font-size: 18px;margin: 0;font-weight: 400;margin: 0;">info@reddensoft.com</a>
                                        </p>
                                    </td>
                                    <td style="padding-top: 30px;padding-bottom: 30px;width: 40%;">
                                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                                            To:
                                        </p>
                                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                            ${resvalue[15]}
                                        </p>
                                        <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                            ${resvalue[16]}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                        <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #d2d2d2; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                            <thead>
                                <tr>
                                    <th style="width: 50%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Item Description</p>
                                    </th>
                                    <th style="width: 15%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Quantity</p>
                                    </th>
                                    <th style="width: 20%;border-right: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Unit Price($)</p>
                                    </th>
                                    <th style="width: 15%;border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Total($)</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                ${items
                                  .map(
                                    (item) => `

                                <tr>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.name}</p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.quantity}</p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.rate}</p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${item.total}</p>
                                    </td>
                                </tr>
                                `
                                  )
                                  .join("")}

                                <tr>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Subtotal</p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${
                                          resvalue[24]
                                        }</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">Tax - 0%</p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;background: #e6e6e6;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">0.00</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;"></p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">TOTAL</p>
                                    </td>
                                    <td style="border-bottom: 1px solid #d2d2d2;border-right: 1px solid #d2d2d2;background: #cccccc;">
                                        <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: center;">${
                                          resvalue[24]
                                        }</p>
                                    </td>
                                </tr>
                            </tbody>
                            </table>  
                            <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                
                                <tbody>
                                    <tr>
                                        <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;padding-top: 30px;">
                                            Payment Option: 
                                            <span style="font-weight: 600;">Wise.com</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                            Company Name:
                                            <span style="font-weight: 600;">Reddensoft Infotech Pvt Ltd</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                            Bank Name: AXIS BANK
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                            Account Number: 1010101010101
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                            IFSC Code: UTIB0000000
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                            Swift code: AXIS5555555
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-family: sans-serif;color: #000;font-size: 16px;margin: 0;font-weight: 500;text-align: left;padding-bottom: 6px;">
                                            Bank Address: Kolkata, West Bengal, India
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                            <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style="text-align: center;font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;letter-spacing: 1px;padding-bottom: 8px;padding-top: 40px;">
                                                If you have any questions or concerns regarding this invoice, contact via email.
                                            </p>
                                            <p style="text-align: center;font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;letter-spacing: 1px;">
                                                Thank you!
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>  
                        </div>`;

      var htmlbigwavedemo = `    <div style="padding: 20px;">  
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="font-size: 30px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                Big Wave Development LLC
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: center;">
                                <a href="https://www.bigwavedevelopment.com/" style="font-size: 16px;font-weight: 700;color: #000;padding:3px; margin: 0;">
                                    www.bigwavedevelopment.com
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-top: 40px;">
                                <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;">
                                    INVOICE/DATE ${resvalue[8]}
                                </h2>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-top: 10px;">
                                <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;">
                                    #${ioid}
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </table>     
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="width: 50%; padding-right:10px; padding-top: 40px;">
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                                    Form:
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Company Name: Big Wave Development LLC
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Company Address: 7901 4th ST. N St. Petersburg, FL. UN 33702
                                </p>
                            </td>
                            <td style="width: 50%; padding-top: 40px;">
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                                    To:
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Company Name: ${resvalue[15]}
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Company Address: ${resvalue[16]}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>  
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="padding-top: 40px;">
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                                    WireTransfer Details:
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    BENIFICIARY NAME: Big Wave Development LLC
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    BANK NAME: PNC Bank
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Account Number: 1242641948
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Routing Number: 267084199
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Swift Code: PNCCUS33
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    BANK ADDRESS: PNC Bank, National Association
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Pittsburgh 00115
                                </p>
                            </td>
                            <td style="padding-top: 40px;vertical-align: bottom;">
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 600;padding-bottom: 6px;">
                                    Contact Person:
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Contact name: ${resvalue[17]}
                                </p>
                                <p style="font-family: sans-serif;color: #000;font-size: 18px;margin: 0;font-weight: 400;padding-bottom: 6px;">
                                    Email: ${resvalue[19]}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>    
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td>
                                <h2 style="font-family: sans-serif;color: #000;font-weight: 600; font-size: 20px;margin: 0;letter-spacing: 1px;margin: 0;padding-top: 40px;padding-bottom: 20px;">
                                    Sub: Invoice
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </table>   
                <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                        <tr>
                            <th style="width: 40%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Item Description</p>
                            </th>
                            <th style="width: 15%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Quantity</p>
                            </th>
                            <th style="width: 30%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">PRICE($)</p>
                            </th>
                            <th style="width: 15%;border-bottom: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 16px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Total($)</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    ${items
                      .map(
                        (item) => `

                        <tr>
                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.name}</p>
                            </td>
                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.quantity}</p>
                            </td>
                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.rate}</p>
                            </td>
                            <td style="border-bottom: 1px solid #000;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${item.total}</p>
                            </td>
                        </tr>

                    `
                      )
                      .join("")}

                        <tr>
                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                            </td>
                            <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">Total</p>
                            </td>
                            <td style="border-bottom: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;">${
                                  resvalue[24]
                                }</p>
                            </td>
                            <td style="border-bottom: 1px solid #000;background: #ebebeb;">
                                <p style="font-size: 14px;font-weight: 700;color: #000;padding:20px 10px; margin: 0;text-align: left;"></p>
                            </td>
                        </tr>
                    </tbody>
                </table>   
                <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td>
                                <h2 style="font-family: sans-serif;color: #000;font-weight: 500; font-size: 16px;margin: 0;;margin: 0;padding-top: 20px;padding-bottom: 20px;">
                                    If you have any questions or concerns regarding this invoice, contact via email. 
                                    <span style="font-weight: 700;">Thank you!</span>
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </table>   
            </div>`;

      if (resvalue[27] == "Bigwave") {
        // console.log(htmldemo);
        htmlbigwavedemo += ``;
        html2pdf(htmlbigwavedemo, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Lynkme") {
        htmllynkmedemo += ``;
        html2pdf(htmllynkmedemo, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Reddensoft") {
        htmlreddensoftdemo += ``;
        html2pdf(htmlreddensoftdemo, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "Invoice")
            .concat("-", ioid),
        });
      } else {
        console.log("error");
      }

      // axios.post(BASE_URL + '/api/savesalarypdf',formData)
      //     .then((response)=>{
      //         console.log("uyertfgeusyyr",response.data.salary_details);
      //     })
    },

    async exportToPDFInvoice(io, status, beneficiary, id) {
        if (beneficiary == null) {
            this.tostMessage("warning", "Please add benificiary details first");
        } else if (io == "1") {
            const toDataUrl = async function (url, callback) {
            //Convert to base64
            return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = () => {
                reject({
                status: this.status,
                statusText: xhr.statusText,
                });
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
            });
        };

        // var url =
        //   "https://works-reddensoft.com/lms/admin/public/assets/signature.png";

        var url = BASE_URL + "/public/assets/signature.png";
        let urlBase64 = await toDataUrl(url);

        axios
            .post(BASE_URL + "/api/fetchDealDataForIO", { id: id })
            .then((response) => {
            console.log("dgdggdg",response.data.iodetails);

            this.pdfdata = response.data.iodetails;
            var email = response.data.email;
            //this.htmltopdf(this.salarydata);
            //var html = `<h1>${this.cl_slip_payable}</h1>`;

            //html2pdf(html);

            this.htmltopdfInvoice(this.pdfdata, urlBase64, email);
            });
        } else if (status == "Closed") {
            this.tostMessage(
            "warning",
            "IO deatils has not been updated for this deal"
            );
        } else {
            this.tostMessage("warning", "This deal is not close yet");
        }




    },

    async htmltopdfInvoice(res, logo, email) {
      console.log("res", res);
      const resvalue = Object.values(res);
      console.log("resvalue", resvalue);
      const items = JSON.parse(resvalue[25]);
      console.log("items", items);
      var ioid = String(resvalue[0]).padStart(3, "0");

      // var htmldemo = `
      //         <style></style>
      //         <div style="width:100%; padding:20px " id="pdfFormat">

      //             <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                 <tbody>
      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 20px;font-weight: 700;color: #538138;padding:6px; margin: 0;text-align: center;">
      //                                 Big Wave Development LLC
      //                             </p>
      //                             <a href="https://www.bigwavedevelopment.com/" style="font-size: 14px;font-weight: 700;color: #538138; margin: 0;text-align: center; text-decoration: none;padding-bottom: 20px; display: block;">
      //                                 www.bigwavedevelopment.com
      //                             </a>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">Date: ${resvalue[8]}</p>
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:6px; margin: 0;">#5032687</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Reciver Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[15]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[16]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[17]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[19]}</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration No.</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[18]}</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>
      //                     <tr>
      //                         <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
      //                             <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">WireTransfer Details</p>
      //                         </td>
      //                     </tr>
      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <tbody>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Benificiary Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Big Wave Development LLC</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Name</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Account Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">1242641948</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Routing Number</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">267084199</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Swift Code</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNCCUS33</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Bank Address</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, National Association</p>
      //                                         </td>
      //                                     </tr>
      //                                     <tr>
      //                                         <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Pittsburgh</p>
      //                                         </td>
      //                                         <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">00115</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
      //                                 <thead>
      //                                     <tr>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Name</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Quantity</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Rate</p>
      //                                         </th>
      //                                         <th>
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-bottom: 1px solid #000;text-align: left;background: #a8d08d;">Total</p>
      //                                         </th>
      //                                     </tr>
      //                                 </thead>
      //                                 <tbody>

      //                                     ${items.map(item => `
      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.name}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.quantity}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">${item.rate}</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">${item.total}</p>
      //                                         </td>
      //                                     </tr>
      //                                     `).join('')}

      //                                     <tr>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;"></p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;border-right: 1px solid #000;">Total</p>
      //                                         </td>
      //                                         <td style="border-bottom: 1px solid #000;">
      //                                             <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">1000</p>
      //                                         </td>
      //                                     </tr>
      //                                 </tbody>
      //                             </table>
      //                         </td>
      //                     </tr>

      //                     <tr>
      //                         <td style="padding: 15px 0;"></td>
      //                     </tr>

      //                     <tr>
      //                         <td>
      //                             <p style="font-size: 12px;font-weight: 400;color: #000;padding:4px; margin: 0;">
      //                                 If you have any questions or concerns regarding this invoice, contact via email. <span style="font-size: 13px;font-weight: 600;color: #000;">Thank you!</span>
      //                             </p>
      //                         </td>
      //                     </tr>

      //                 </tbody>
      //             </table>
      //         `;
      var htmldemo =
        `
                        <style></style>
                        <div style="width:100%; padding:20px " id="pdfFormat">
                        
                            <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                                Insertion Order
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[15]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[16]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[17]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[18]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[19]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">big Wave Development LLC</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[2]}</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">7901 4th ST. N St. Petersburg, FL. UN 33702</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 (727) 217-5552</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                        </th>
                                                        <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[20]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[21]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[22]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[23]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                              resvalue[24]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                        </th>
                                                        <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                      .map(
                                                        (item) => `
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                        </td>
                                                    </tr>
                                                    `
                                                      )
                                                      .join("")}
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                            </p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;width: 28%;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                              resvalue[24]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;width: 28%;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                        </td>
                                                        <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                                This Insertion Order ("IO") serves as a binding agreement between 
                                                                Big Wave Development and “Advertiser” Signature on this Insertion 
                                                                signifies acceptance by Advertiser of this agreement</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0 350px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 0 50px 10px 50px;">
                                            <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                                <li>
                                                    <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                        The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                        payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                        unless otherwise specified.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                        Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                        Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                        pornographic content on any of the creative materials
                                                    </p>
                                                </li>
                                                <li>
                                                    <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                        Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                    </p>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                            <tr>
                                                <td style="width: 50%;padding: 0 0 0 50px;">
                                                    <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                    <ul style="margin: 0; padding: 0;">
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Signature: 
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                    <img width="200" height="35"
                                                                    style=" vertical-align: text-top;text-align: center;"
                                                                    src="` +
        logo +
        `" alt="">   
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Name:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;"> 
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Title:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Date:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td style="width: 50%;padding: 0 50px 0 0;">
                                                    <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                    <ul style="margin: 0; padding: 0;">
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Signature: 
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                    <img width="200" height="35"
                                                                    style=" vertical-align: text-top;text-align: center;"
                                                                    src="` +
        logo +
        `" alt="">   
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Name:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Title:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Date:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </table>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        `;



                        var htmldemo_lynkme =
        `
                        <style></style>
                        <div style="width:100%; padding:20px " id="pdfFormat">
                        
                            <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style="font-size: 20px;font-weight: 700;color: #538138;padding:10px; margin: 0;text-align: center;">
                                                Insertion Order
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Advertiser Details</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[15]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[16]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;">Contact person</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Name</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[17]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Registration number</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[18]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Email</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${
                                                              resvalue[19]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Publisher Details</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Company Name</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">LynkMe360 LLC</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Contact Person</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${resvalue[2]}</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Address</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">PNC Bank, 218 Datura St. West Palm Beach, FL 33401-5679</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">City and Country</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">United States</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000; border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">Telephone</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">+1 727 440 5867</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 200px; border-right: 1px solid #000;border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">E-mail</p>
                                                        </td>
                                                        <td style="width: calc(100% - 200px);border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${email}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #000; border-bottom: none; background: #a8d08d;">
                                            <p style="font-size: 14px;font-weight: 700;color: #000;padding:3px; margin: 0;text-align: center;">Campaign Details</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Site / Application</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Start Date</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">End Date</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Placement</p>
                                                        </th>
                                                        <th style="width: 32%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total Budget (inclusive of all taxes)</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[20]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[21]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[22]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">${
                                                              resvalue[23]
                                                            }</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                              resvalue[24]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Campaign Type</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Model</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Rate</p>
                                                        </th>
                                                        <th style="width: 17%;border-right: 1px solid #000;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Quantity</p>
                                                        </th>
                                                        <th style="width: 32%;border-bottom: 1px solid #000;background: #a8d08d;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;text-align: center;">Total</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${items
                                                      .map(
                                                        (item) => `
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 700;color: #000;padding:4px; margin: 0;">${item.name}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.model}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.rate}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">${item.quantity}</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${item.total}</p>
                                                        </td>
                                                    </tr>
                                                    `
                                                      )
                                                      .join("")}
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-top: none; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Grand Total
                                                            </p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;width: 28%;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">$${
                                                              resvalue[24]
                                                            }</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 72%;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Payment Terms</p>
                                                        </td>
                                                        <td style="border-bottom: 1px solid #000;width: 28%;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Prepayment</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style="border-spacing:0px;font-family: sans-serif; border: 1px solid #000; border-bottom: none;" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 47%;border-bottom: 1px solid #000;border-right: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;text-align: center;">Further Comments</p>
                                                        </td>
                                                        <td style="width: 53%;border-bottom: 1px solid #000;background: #e2efd9;">
                                                            <p style="font-size: 11px;font-weight: 600;color: #000;padding:14px 6px; margin: 0;">
                                                                This Insertion Order ("IO") serves as a binding agreement between 
                                                                Big Wave Development and “Advertiser” Signature on this Insertion 
                                                                signifies acceptance by Advertiser of this agreement</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0 350px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 0 50px 10px 50px;">
                                            <p style="font-size: 13px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">Additional Notes:</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul style="margin: 0;padding: 0 100px 10px 100px;">
                                                <li>
                                                    <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                        The total cost must be paid in full before rendering the service unless otherwise specified. All 
                                                        payments must be made by Bank Wire/Crypto. All advertising purchases are non-refundable 
                                                        unless otherwise specified.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                        Advertiser must not allow illegal content, spa, spyware, illegal drugs, medicine, ROM, EMU's, 
                                                        Child Pornography, phishing, pirated media (such as illegal MP3 or movie downloads)obscene or 
                                                        pornographic content on any of the creative materials
                                                    </p>
                                                </li>
                                                <li>
                                                    <p style="font-size: 12px;font-weight: 600;color: #000;padding:4px 0; margin: 0;">
                                                        Big Wave Development is not responsible for the success or failure of the advertiser's campaign.
                                                    </p>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="padding: 15px 0;"></td>
                                    </tr>
                                    <tr>
                                        <table width="100%" style="border-spacing:0px;font-family: sans-serif;" cellspacing="0" cellpadding="0" border="0">
                                            <tr>
                                                <td style="width: 50%;padding: 0 0 0 50px;">
                                                    <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Advertiser’s Org Name</p>
                                                    <ul style="margin: 0; padding: 0;">
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Signature: 
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                    <img width="200" height="35"
                                                                    style=" vertical-align: text-top;text-align: center;"
                                                                    src="` +
        logo +
        `" alt="">   
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Name:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">vvsvsv</span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;"> 
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Title:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                            </p>
                                                        </li>
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Date:
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:4px; margin: 0;">vvsvsv</span>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td style="width: 50%;padding: 0 50px 0 0;">
                                                    <p style="font-size: 13px;font-weight: 600;color: #a8d08d;padding:6px 0; margin: 0;">Big Wave Development LLC</p>
                                                    <ul style="margin: 0; padding: 0;">
                                                        <li style="list-style: none;">
                                                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                                                Signature: 
                                                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;display: block;">
                                                                    <img width="200" height="35"
                                                                    style=" vertical-align: text-top;text-align: center;"
                                                                    src="` +
        logo +
        `" alt="">   
                                </span>
                            </p>
                        </li>
                        <li style="list-style: none;">
                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                Name:
                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Ricky Bel</span>
                            </p>
                        </li>
                        <li style="list-style: none;">
                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                Title:
                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">Account Manager</span>
                            </p>
                        </li>
                        <li style="list-style: none;">
                            <p style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">
                                Date:
                                <span style="font-size: 12px;font-weight: 600;color: #000;padding:6px; margin: 0;">02-08-2024</span>
                            </p>
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
        </tr>

        </tbody>
        </table>
        `;

    //   console.log(htmldemo);
    //   htmldemo += ``;
    //   html2pdf(htmldemo, {
    //     filename: resvalue[3]
    //       .concat("-", resvalue[27])
    //       .concat("-", "IO")
    //       .concat("-", ioid),
    //   });

      if (resvalue[27] == "Bigwave") {
        // console.log(htmldemo);
        htmldemo += ``;
        html2pdf(htmldemo, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "IO")
            .concat("-", ioid),
        });
      } else if (resvalue[27] == "Lynkme") {
        htmldemo_lynkme += ``;
        html2pdf(htmldemo_lynkme, {
          filename: resvalue[3]
            .concat("-", resvalue[27])
            .concat("-", "IO")
            .concat("-", ioid),
        });
      }
      else{
        this.tostMessage("warning", "Can not download IO for Reddensoft.");

      }

      // axios.post(BASE_URL + '/api/savesalarypdf',formData)
      //     .then((response)=>{
      //         console.log("uyertfgeusyyr",response.data.salary_details);
      //     })
    },
  },
};
</script>
