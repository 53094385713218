<template>
  <div class="main-right">
      <div class="main-title">
        <h2>WFH Employee List</h2>
      </div>
      <div class="head-bar">
        <ul>
          <li>
            No of WFH Requests: <span>{{resultQuery.length}}</span> <span> ({{new Date().toLocaleString()}})</span>
          </li>
        </ul>
        <div class="fild-info">
          <form action="#">
            <div class="fr-gp">
              <div class="search-wrapper panel-heading col-sm-12">
              <input type="text" v-model="searchQuery" placeholder="Search...">
              </div>
            </div>
          </form>
          <button class="dwnlod-btn" @click="clickedDownload()">
            download
            <img src="@/assets/images/icon/download.svg" alt="">
          </button>
        </div>
      </div>
      <div class="table-section" id="divToPrintWfhList">
        <div class="table-wapper">
          
          <div class="table-data table-w-100">
            
            <table cellspacing="0" cellpadding="0" border="0">
              <thead>
                <tr>
                  <th>Employee Name </th>
                  <th>Employee Id </th>
                  <th>WFH Applied On </th>
                  <th>Days for WFH </th>
                  <th>No of WFH Days</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg> -->
              <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="12px" height="12px" fill="#fff"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg> -->
              <tbody>
                <!-- <tr v-for="(users, index) in users" :key="index"> -->
                  <tr v-for="(item, index) in resultQuery" :key="index" :style="'background: ' + (item.empid == this.notifiedId && item.wfh_date == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
                  <td><a v-bind:="index" target="_blank">{{item.empname}}</a></td>
                  <!-- <td>{{item.empid}}</td> -->
                  <td><a v-bind:="index" target="_blank">{{item.employeeid}}</a></td>
                  <td><a v-bind:="index" target="_blank">{{item.created_at}}</a></td>
                  
                  <td v-html="formatDates(item.wfh_date)"></td>
                  <td><a  v-bind:="index" target="_blank">{{item.days}}</a></td>
                  
                  <!-- <td>{{item.days}}</td> -->
                  <td v-if="item.wfh_status=='Approved'"> <div class="approved_status"> <span class="status aprvd" ></span>{{item.wfh_status}} </div></td>
                  <td v-if="item.wfh_status=='Rejected'"> <div class="approved_status"> <span class="status rejected"></span>{{item.wfh_status}} </div></td>
                  <td v-if="item.wfh_status=='Pending'"> <div class="approved_status"> <span class="status pendin"></span>{{item.wfh_status}} </div></td> 
                  <td>
                    <!-- <div class="btn-lps" v-if="item.wfh_status=='Pending'">
                      <a  @click="approveRejectStatus(item.id,item.wfh_status,'approve')" href="#" class="gren">approve</a>&nbsp;
                      <a  @click="approveRejectStatus(item.id,item.wfh_status,'reject')" href="#" class="rept">reject</a>&nbsp;
                      <a href="#" class="edtl">view details</a>
                    </div> -->
                    <div class="btn-lps">
                        <!-- {{item.wfh_status}} -->
                        <a v-if="item.wfh_status == 'Approved'||item.wfh_status == 'Pending'"   @click="approveRejectStatus(item.id,item.wfh_status,'reject')" href="#" class="rept">Reject</a>
                        <a v-if="item.wfh_status == 'Rejected'||item.wfh_status == 'Pending'"   @click="approveRejectStatus(item.id,item.wfh_status,'approve')" href="#" class="gren">Approve</a>
                        <!-- <a v-if="item.wfh_status == 'Pending'"   @click="approveRejectStatus(item.id,item.wfh_status,'approve'),approveRejectStatus(item.id,item.wfh_status,'approve')" href="#" class="gren">Approve</a> -->
                        <!-- <a href="#" class="edtl">view details</a> -->
                        <button @click="isOpen(item.id)" class="edtl">view details</button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tbody v-show="resultQuery == 0"><tr>No Data Available</tr></tbody> -->
            </table>
            
            <div class="no_data_div" v-show="resultQuery == 0">
              <p>No Data Available</p>
            </div>
          </div>
        </div>
      </div>

    <!-- modal(popup) -->
      <div class="popup-modal wfhDetailsModal" id="popupModa1" v-if="seen">
        <div class="mp-content">
          <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal('close')">
            <img src="@/assets/images/icon/x-lg.svg" alt="">
          </button>
          <div class="bdy-sect">
            <h3 >Employee WFH Application Details</h3>
            <div class="item-cr">
              
              <div class="list-sr">
                <div class="ttx">
                  <img src="@/assets/images/icon/person.png" alt="">
                    Employee :
                  </div>
                <div class="vl">{{empname}}</div>
              </div>
              <div class="list-sr">
                <div class="ttx">
                    <img src="@/assets/images/icon/calendar-plus.png" alt="">
                    Dates :
                </div>
                <div class="vl">
                  <ul>
                      <li>{{wfh_date}}</li>
                  </ul>
              </div>
              </div>
              <div class="list-sr">
                <div class="ttx">
                    <img src="@/assets/images/icon/person-workspace.png" alt="">
                    Application Type :
                </div>
                <div class="vl">{{type}}</div>
                <div class="idc">
                  {{days}}
                </div>
              </div>
              <div class="list-sr">
                " {{reason}} "
              </div>
              <div class="item-cr-inline">
                <p>Document: {{doc}} </p>
                <button @click="onClick()">Download</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- modal(popup) -->
  </div>
  </template>
  
  <script>
  import { createLocal } from 'the-storages'
  import { BASE_URL } from "../../config";
  import axios from "axios";
  import Pagination from 'v-pagination-3';
  
  import { ref, computed } from 'vue';
  
  const mirror = createLocal()
  const storage = mirror._prx
  
  import { jsPDF } from "jspdf";
  import html2canvas from 'html2canvas';
  import html2pdf from "html2pdf.js";
  
  import Swal from 'sweetalert2';
  
  export default {
      name: 'AdminWFHDetails',
      components:{
          Pagination
      },
      data() {
          return {
              storageData: mirror,
              storage: storage,
              users: [],
              searchQuery: null,
              dataVal:{},
              seen : false,
              empname : "",
              empemail : "",
              ip : "",
              wfh_date : "",
              days : "",
              reason : "",
              doc : "",
              doc_url : "",
              wfh_status : "",
              type : "",
              notifiedType:'',
              notifiedId:'',
              notifiedDate:'',
          }
      },
      methods :{

        formatDates(dates) {
    // Split the dates by comma
    const dateArray = dates.split(',');
    
    // Return each date wrapped in a <div> tag
    return dateArray.map(date => `<div class="td-width-control">${date.trim()}</div>`).join('');
  },


        async isOpen(event){
           //console.log(event);
           this.seen = true;
  
           const getid = {
              id : event
           }; 
           //console.log(getid);
  
              await axios.post(BASE_URL + '/api/getmodalwfhstatus',getid)
                      .then((response)=>{
                          //console.log(response.data);
  
                          this.empname = response.data.empname;
                          this.empemail = response.data.empemail;
                          this.ip = response.data.ip;
                          this.wfh_date = response.data.wfh_date;
                          this.days = response.data.days;
                          this.reason = response.data.reason;
                          this.doc = response.data.doc;
                          this.doc_url = response.data.doc_url;
                          this.wfh_status = response.data.wfh_status;
                          this.type = response.data.type;
  
                  })
  
         },
          onClick() {
            //console.log(this.docurl);
  
              if(this.doc_url === ''){
                      Swal.fire({
                                  title: 'OPPS',
                                  text:  'No Data Available',
                                  icon: 'warning',
                              
                              });
                }else{
  
                      axios({
                              url: this.doc_url,
                              method: 'GET',
                              responseType: 'blob',
                          }).then((response) => {
                              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                              var fileLink = document.createElement('a');
          
                              fileLink.href = fileURL;
  
                              if(response.data.type == 'image/jpeg'){
                                  fileLink.setAttribute('download', 'file.jpeg');
                              }else if(response.data.type == 'image/jpg'){
                                  fileLink.setAttribute('download', 'file.jpg');
                              }else if(response.data.type == 'image/png'){
                                  fileLink.setAttribute('download', 'file.png');
                              }else if(response.data.type == 'application/pdf'){
                                  fileLink.setAttribute('download', 'file.pdf');
                              }else if(response.data.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                                  fileLink.setAttribute('download', 'file.docx');
                              }else{
                                  fileLink.setAttribute('download', 'file.txt');
                              }
  
                              document.body.appendChild(fileLink);
          
                              fileLink.click();
                          });
  
                }
  
          },
          closemodal(event){
            this.seen = false;
          },
          // async clickedDownload() {
          //     var pdf = new jsPDF("p", "mm", "a4");
          //     var element = document.getElementById('divToPrintWfhList');
          //     var width = element.offsetWidth;
          //     var height = element.offsetHeight;
          //     var rows = element.querySelectorAll('tr'); // Select all table rows
          //     var totalColumns = rows[0].querySelectorAll('td, th').length;
          //     var columnWidths = []; // Array to store column widths
          //     var totalWidth = 0;
          //     var totalPages = Math.ceil(rows.length / 40); // Assuming 40 rows per page

          //     // Calculate column widths based on content
          //     rows[0].querySelectorAll('td, th').forEach((cell, index) => {
          //         var cellWidth = cell.offsetWidth;
          //         columnWidths.push(cellWidth);
          //         totalWidth += cellWidth;
          //     });

          //     // Adjust column widths to fit within PDF width
          //     var ratio = width / totalWidth;
          //     columnWidths = columnWidths.map(width => width * ratio);

          //     for (let i = 0; i < totalPages; i++) {
          //         if (i > 0) {
          //             pdf.addPage();
          //         }
          //         let startY = 40; // Initial y-position for the PDF content on each page

          //         // Adding table headers
          //         if (i === 0) {
          //             let headerRow = rows[0];
          //             let headerCells = headerRow.querySelectorAll('th');
          //             let startX = 15; // Initial x-position for the PDF content on each page
          //             for (let k = 0; k < headerCells.length; k++) {
          //                 let cell = headerCells[k];
          //                 pdf.text(cell.innerText, startX, startY);
          //                 startX += columnWidths[k];
          //             }
          //             startY += 10; // Adjust startY after header row
          //         }

          //         for (let j = i * 40; j < Math.min((i + 1) * 40, rows.length); j++) {
          //             let row = rows[j];
          //             let cells = row.querySelectorAll('td, th');
          //             let startX = 15; // Initial x-position for the PDF content on each page
          //             for (let k = 0; k < cells.length; k++) {
          //                 let cell = cells[k];
          //                 pdf.text(cell.innerText, startX, startY);
          //                 startX += columnWidths[k];
          //             }
          //             startY += 10; // Adjust startY for the next row
          //         }
          //     }

          //     pdf.save('WfhList' + '.pdf');
          // },

          async clickedDownload() {
            var data = this.resultQuery; // Ensure this points to your data source
            try {
              if (!data || data.length === 0) {
                throw new Error('Data is empty or undefined.');
              }

              // Generate HTML content for the table
              const tableHtml = this.generateWFHTableHTMLWithPageBreaks(data);

              // Define PDF generation options
              const opt = {
                margin: 0.1,
                filename: 'WfhList.pdf',
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 1.1 },
                pagebreak: { avoid: '.downloadable-row' }
              };

              // Generate the PDF
              await html2pdf().set(opt).from(tableHtml).save();
            } catch (error) {
              console.error('Error generating PDF:', error);
            }
          },

          generateWFHTableHTMLWithPageBreaks(data) {
            // Create a temporary <div> element to build the table HTML
            const tempDiv = document.createElement('div');

            // Add table HTML content with page breaks and CSS classes for spacing
            if (data && data.length > 0) {
              tempDiv.innerHTML = `
                <style>
                  .table-section {
                    width: 100%;
                  }
                  .table-wrapper {
                    margin: 0 auto;
                    padding: 10px;
                  }
                  .table-data {
                    width: 100%;
                    border-collapse: collapse;
                  }
                  .table-data th, .table-data td {
                    padding: 8px 12px;
                    text-align: left;
                    border: 1px solid #ddd;
                  }
                  .table-data th {
                    background-color: #f2f2f2;
                  }
                  .downloadable-row {
                    page-break-inside: avoid;
                  }
                  .downloadable-row td {
                    word-wrap: break-word;
                    white-space: pre-line;
                  }
                </style>
                <div class="table-section">
                  <div class="table-wrapper">
                    <table cellspacing="0" cellpadding="0" border="0" class="table-data print-table">
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Employee Id</th>
                          <th>WFH Applied On</th>
                          <th>Days for WFH</th>
                          <th>No of WFH Days</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        ${data.map(row => `
                          <tr class="downloadable-row">
                            <td>${row.empname}</td>
                            <td>${row.employeeid}</td>
                            <td>${row.created_at}</td>
                            <td>${row.wfh_date.split(',').join('<br>')}</td>
                            <td>${row.days}</td>
                            <td>${row.wfh_status}</td>
                          </tr>
                        `).join('')}
                      </tbody>
                    </table>
                  </div>
                </div>
              `;
            } else {
              console.warn('No data provided to generate table HTML.');
            }

            // Log the generated HTML to the console
            console.log('Generated HTML:', tempDiv.innerHTML);

            // Return the temporary <div> element
            return tempDiv;
          },


        read() {
              axios.get(BASE_URL + '/api/fetch_wfh_data').then(({ data }) => {
                  // console.log("test",data[1])
                  // const date = new Date().toLocaleString();
                  // console.log("test",new Date().toLocaleString())
                  //console.log(this.users);
                  this.users= data;
                  //console.log(this.users);
  
  
              })
                  .catch((err) => console.error(err));
          },
          async approveRejectStatus(id,wfh_status,type){
            // console.log(id);
            // console.log(type);
            //console.log(wfh_status);
            if(wfh_status == 'Rejected'){
  
              if(confirm("Are you sure you want to approve this?")){
  
                  this.dataVal = {"id":id,"type":type,"wfh_status":wfh_status};
  
                  // this.data.wfh_status=Approved;
                  const res=await axios.post(BASE_URL + '/api/add_data',this.dataVal);
                  //console.log("res", res.data);
                  if (res.data.status==200) {
                    axios.post(BASE_URL + '/api/sendapprovewfh',{
                                  email: res.data.email.empemail,
                                  name:  res.data.name.empname,
                                  wfhdate: res.data.wfh_date.wfh_date,
                                  wfh_status : res.data.wfh_status,
                                  dayswfh : res.data.days.days,
                              });
                    const res2= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: res.data.empid.empid, date: res.data.wfh_date.wfh_date, type: 'WFH', status:res.data.wfh_status, seenstatus: 'unseen'});
                    
                      Swal.fire({
                                  title: 'Done',
                                  text:  'Wfh is Approved Successfully',
                                  icon: 'success',
                              
                              });
                      this.read();
                  }
  
              }else{
                return false;
              }
  
            }else if(wfh_status == 'Approved'){
  
              if(confirm("Are you sure you want to reject this?")){
  
                  this.dataVal = {"id":id,"type":type,"wfh_status":wfh_status};
  
                  // this.data.wfh_status=Approved;
                  const res=await axios.post(BASE_URL + '/api/add_data',this.dataVal);
                  //console.log("res", res.data);
                  if (res.data.status==200) {
                    axios.post(BASE_URL + '/api/sendrejectwfh',{
                                  email: res.data.email.empemail,
                                  name:  res.data.name.empname,
                                  wfhdate: res.data.wfh_date.wfh_date,
                                  wfh_status : res.data.wfh_status,
                                  dayswfh : res.data.days.days,
                              });
                    const res2= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: res.data.empid.empid, date: res.data.wfh_date.wfh_date, type: 'WFH', status:res.data.wfh_status, seenstatus: 'unseen'});
                      
                      Swal.fire({
                                    title: 'Done',
                                    text:  'Wfh is Rejected Successfully',
                                    icon: 'success',
                                
                                });
                        this.read();
                  }
  
                  }else{
                    return false;
                  }
  
            }else if(wfh_status == 'Pending'){
              if(type == 'approve'){
  
  if(confirm("Are you sure you want to approve this?")){
  
      this.dataVal = {"id":id,"type":type,"wfh_status":wfh_status};
  
      // this.data.wfh_status=Approved;
      const res=await axios.post(BASE_URL + '/api/add_data',this.dataVal);
      //console.log("res", res.data);
      if (res.data.status==200) {
        axios.post(BASE_URL + '/api/sendapprovewfh',{
                                  email: res.data.email.empemail,
                                  name:  res.data.name.empname,
                                  wfhdate: res.data.wfh_date.wfh_date,
                                  wfh_status : res.data.wfh_status,
                                  dayswfh : res.data.days.days,
                              });
        const res2= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: res.data.empid.empid, date: res.data.wfh_date.wfh_date, type: 'WFH', status:res.data.wfh_status, seenstatus: 'unseen'});
  
          Swal.fire({
                      title: 'Done',
                      text:  'Wfh is Approved Successfully',
                      icon: 'success',
                  
                  });
          this.read();
      }
  
  }else{
    return false;
  }
  
  }else if(type == 'reject'){
  
  if(confirm("Are you sure you want to reject this?")){
  
      this.dataVal = {"id":id,"type":type,"wfh_status":wfh_status};
  
      // this.data.wfh_status=Approved;
      const res=await axios.post(BASE_URL + '/api/add_data',this.dataVal);
      //console.log("res", res.data);
      if (res.data.status==200) {
        axios.post(BASE_URL + '/api/sendrejectwfh',{
                                  email: res.data.email.empemail,
                                  name:  res.data.name.empname,
                                  wfhdate: res.data.wfh_date.wfh_date,
                                  wfh_status : res.data.wfh_status,
                                  dayswfh : res.data.days.days,
                              });
        const res3= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: res.data.empid.empid, date: res.data.wfh_date.wfh_date, type: 'WFH', status:res.data.wfh_status, seenstatus: 'unseen'});
          
          Swal.fire({
                        title: 'Done',
                        text:  'Wfh is Rejected Successfully',
                        icon: 'success',
                    
                    });
            this.read();
      }
  
      }else{
        return false;
      }
  
  }
            }
  
          }
      },
      async created(){
          this.userName = mirror.USERNAME;
          // this.notifiedId = this.$route.params.id;
          // this.notifiedDate = this.$route.params.date;
          // this.notifiedType = this.$route.params.type;
          var hash = window.location.hash.split(/,(.*)/s);
          this.notifiedId = hash[0].split("#")[1];
          this.notifiedDate = hash[1];
          console.log("hash",this.notifiedDate);
          // var interval = setInterval(function () {location.hash =''}, 10000);
          // clearInterval(interval);
          this.ID = mirror.AUTHID;
     },
     computed: {
                  resultQuery(){
                    if(this.searchQuery){
                    return this.users.filter((item)=>{
                    
                    return this.searchQuery.toLowerCase().split(' ').every( w => item.days.concat(item.empname).concat(item.wfh_status).concat(item.empid).toLowerCase().includes(w));
                    //  this.searchQuery.toLowerCase().split(' ').every(v => item.wfh_status.toLowerCase().includes(v)))
                    })
                    }else{
                    return this.users;
                    }
                  },
            },
            mounted() {
                  this.read();
            },
            watch: {
           '$route.query'() { 
            //console.log('querydata',newId)
            // this.url_res = this.$route.params.res;
            // this.getdata.url_res = this.url_res;
            var hash = window.location.hash.split(",");
            this.notifiedId = hash[0].split("#")[1];
            this.notifiedDate = hash[1];
            console.log("hash14",hash);
            // this.getsearchresult(this.getdata);
            // setInterval(function () {window.location.hash =''}, 5000);
          }
       }
  
  }
  </script>
  <style scoped>
  .table-section {
    width: 100%;
  }
  .table-wrapper {
    margin: 0 auto;
    padding: 10px;
  }
  .table-data {
    width: 100%;
    border-collapse: collapse;
  }
  .table-data th, .table-data td {
    padding: 8px 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  .table-data th {
    background-color: #f2f2f2;
  }
  .downloadable-row td {
    word-wrap: break-word;
    white-space: pre-line;
  }
  </style>