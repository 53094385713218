<template>
  <div class="main-right new_emp">

    <!-- <div class="date-sect">
      <datepicker  v-model="seldate"  @input="getadmindashboardlvl" placeholder=""/>
    </div> -->
<!-- 
  <label
  >Pick date
      <datepicker v-model="seldate" @input="choseDate" />
      <datepicker v-model="picked" v-on:dateChanged="choseDate" />
    <Datepicker  v-model="picked"  yearPicker :clearable="false"   :yearRange="[startyear, endyear]" autoApply  :maxDate="new Date()" />
  </label> -->
  <div style="width: 100%; display: flex; justify-content: flex-end;">
    <label class="main_data_clrs date_picker_div main_dashboard_datepicker" style="width: 300px;">
      <span style="white-space: nowrap;">Pick date</span>
      <datepicker v-model="picked" />  
    </label>
  </div>

  <v-row justify="center">
  <v-date-picker
    v-model="date"
    :allowed-dates="allowedDates"
    class="mt-4"
    v-on:dateChanged="getDate"
   
  ></v-date-picker>
  </v-row>
        <div class="list-card">
          <div class="">
            <div class="list-content">
              <ul>
                <li>
                  <div class="card">
                    <div class="value">{{this.attendance_list.length}}</div>
                      <div class="ttx">Present</div>
                    </div>
                  </li>
                <li>
                  <div class="card">
                    <div class="value">{{this.wfhemplist.length}}</div>
                      <div class="ttx">WFH</div>
                  </div>
                  </li>
                <li>
                  <div class="card">
                    <div class="value">{{this.emp_array_fullname.length}}</div>
                      <div class="ttx">Absent</div>
                  </div>  
                  </li>
                <li>
                  <div class="card">
                    <div class="value">{{casualleavetaken}}</div>
                      <div class="ttx">Casual Leave</div>
                  </div>  
                  </li>
                <li>
                  <div class="card">
                    <div class="value">{{medicalleavetaken}}</div>
                      <div class="ttx">Medical Leave</div> 
                  </div> 
                  </li>
                <li>
                  <div class="card">
                    <div class="value">{{provisional_leave}}</div>
                      <div class="ttx">Provisional Leave/WL</div>
                  </div>  
                  </li>
              </ul>
            </div>
          </div>
        </div>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="employe-list-tab" data-bs-toggle="tab" data-bs-target="#employe-list" type="button" role="tab" aria-controls="employe-list" aria-selected="true">Employee List</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="leave-applications-tab" data-bs-toggle="tab" data-bs-target="#leave-applications" type="button" role="tab" aria-controls="leave-applications" aria-selected="false">Leave Applications</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">

          <div class="tab-pane fade show active" id="employe-list" role="tabpanel" aria-labelledby="employe-list-tab">
            <div class="table-section full-table">
                <div class="table-wapper">
                  
                  <div class="table-data px-0">
                    
                    <div class="table-title">
                      
                      <button class="dwnlod-btn ms-auto" @click="clickedDownload()">
                        download
                        <img src="@/assets/images/icon/download.svg" alt="">
                      </button>
                    </div>
                    <!-- accordian start -->
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <div class="accordion-header accord-title" id="headingOne">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h3>present</h3>
                          </button>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body answer">
                                <table cellspacing="0" cellpadding="0" border="0">
                                  <thead>
                                    <tr>
                                      <th>Employer Name</th>
                                      <th>start</th>
                                      <th>end</th>
                                      <th>working hour</th>
                                      <th>status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="user in attendance_list" :key="user">
                                      <td>{{user.empname}}</td>
                                      <td>{{user.start_time}}</td>
                                      <td>{{user.end_time}}</td>
                                      <!-- <td>{{user.hourly_works}}</td> -->
                                      <td :style="'color: ' + (user.hourly_works >= '09:00:00' ? 'rgb(89 234 92)' : '#FF0000')">{{user.hourly_works}}</td>
                                      <td>{{ user.attendance_status}}</td>

                                    </tr>
                                  </tbody>
                                </table>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <div class="accordion-header accord-title" id="headingTwo">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <h3>work from home</h3>
                          </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body answer">
                                <table cellspacing="0" cellpadding="0" border="0">
                                  <thead>
                                    <tr>
                                      <th>Employer Name</th>
                                      <th>start</th>
                                      <th>end</th>
                                      <th>working hour</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="user in wfhemplist" :key="user">
                                      <td>{{user.empname}}</td>
                                      <td>{{user.start_time}}</td>
                                      <td>{{user.end_time}}</td>
                                      <td>{{user.hourly_works}}</td>
                                    </tr>
                                    
                                  </tbody>
                                </table>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <div class="accordion-header accord-title" id="headingThree">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h3>Absent </h3>
                          </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div class="accordion-body answer">
                              <table cellspacing="0" cellpadding="0" border="0">
                                <thead>
                                  <tr v-for="user in emp_array_fullname" :key="user">
                                    <th colspan="2">{{user}}</th>
                                  </tr>
                                </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <div class="accordion-header accord-title" id="headingFour">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                            <h3>Provisional Candidate</h3>
                          </button>
                        </div>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                          <div class="accordion-body answer">
                              <table cellspacing="0" cellpadding="0" border="0">
                                <thead>
                                  <tr>
                                    <th>Employer Name</th>
                                  </tr>
                                </thead>
                                <tbody v-show="provisional_emp_leave !== 0" v-for="(row) in provisional_emp_leave" :key="row">
                                  <tr>
                                    <td v-show="row.empname!=''">{{row.empname}}</td>
                                  </tr>
                                </tbody>
                                <!-- <tbody v-show="provisional_emp_leave == 0"><tr>No Data Available</tr></tbody> -->
                              </table>
                              <div class="no_data_div" v-show="provisional_emp_leave == 0">
                                <p>No Data Available</p>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <div class="accordion-header accord-title" id="headingFive">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                            <h3>Employees on leave</h3>
                          </button>
                        </div>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                          <div class="accordion-body answer">
                              <table cellspacing="0" cellpadding="0" border="0">
                                <thead>
                                  <tr>
                                    <th>Employer Name</th>
                                    <th>Leave Type</th>
                                  </tr>
                                </thead>
                                <tbody v-show="employees_on_leave !== 0" v-for="(row) in employees_on_leave" :key="row">
                                  <tr>
                                    <td v-show="row.empname!=''">{{row.empname}}</td>
                                    <td v-show="row.leave!=''">{{row.leave}}</td>
                                  </tr>
                                </tbody>
                                <!-- <tbody v-show="employees_on_leave == 0"><tr>No Data Available</tr></tbody> -->
                              </table>
                              <div class="no_data_div" v-show="employees_on_leave == 0">
                                <p>No Data Available</p>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- acoordian end -->

                  </div>
                  <div v-if="errormessage1 != ''" class="errormessage">
                      {{ errormessage1 }}
                  </div>
                  <div v-if="successmessage1 != ''" class="successmessage">
                      {{ successmessage1 }}
                  </div>
        
                </div>
            </div>
          </div>
          <div class="tab-pane fade" id="leave-applications" role="tabpanel" aria-labelledby="leave-applications-tab">
            <div class="table-section full-table">
                <div class="table-wapper">
                  <div class="table-data px-0">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <thead>
                        <tr>
                          <th>Leave Applicant</th>
                          <th>Leave Type</th>
                          <th>Status</th>
                          <th>Date Requested</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody v-show="message.data !== 0" v-for="(row) in message" :key="row">
                        <tr>
                          <td v-show="row.empname!=''">{{row.empname}}</td>
                          <td>{{row.leave}}</td>
                          <td v-if="row.leavestatus == 'Pending'"> <span class="status pendin"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Rejected'"> <span class="status rejected"></span> {{row.leavestatus}}</td>
                          <td v-if="row.leavestatus == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatus}}</td>
                          <td>{{row.leave_date}}</td>
                          <td>
                            <div class="btn-lps">
                              <a href="#" v-if="row.leavestatus =='Rejected' || row.leavestatus =='Pending'"  class="gren">Approve</a>
                              <a href="#" v-if="row.leavestatus =='Approved' || row.leavestatus =='Pending'"  class="rept">Reject</a>
                              <!-- <a href="#" v-if="row.leavestatus =='Rejected' || row.leavestatus =='Pending'" @click="leaveapprove(row.id,row.empid,row.leave,row.days,row.leave_date,row.leavestatus)" class="gren">Approve</a>
                              <a href="#" v-if="row.leavestatus =='Approved' || row.leavestatus =='Pending'" @click="leavereject(row.id,row.empid,row.leave,row.days,row.leave_date,row.leavestatus)" class="rept">Reject</a> -->
                              <!-- <a href="leave-record-page.html" class="edtl" >view record</a> -->
                              <button @click="isOpen(row.id)" class="edtl">view record</button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>

        </div>

        <!-- <ModalViewLeaveStatus> -->
          <div class="popup-modal wfhDetailsModal" id="popupModa1" v-if="seen">
            <div class="mp-content">
              <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal('close')">
                <img src="@/assets/images/icon/x-lg.svg" alt="">
              </button>
              <div class="bdy-sect">
                <h3>Employee Leave Application Details</h3>
                <div class="item-cr">
                  <div class="list-sr">
                    <div class="ttx">
                      <img src="@/assets/images/icon/person.png" alt="">
                        Employee :
                      </div>
                    <div class="vl">{{empname}}</div>
                  </div>
                  <div class="list-sr">
                    <div class="ttx">
                        <img src="@/assets/images/icon/calendar-plus.png" alt="">
                        Dates :
                    </div>
                    <div class="vl">
                      <ul>
                        <li>{{leavedate}}</li>
                      </ul>
                  </div>
                  </div>
                  <div class="list-sr">
                    <div class="ttx">
                        <img src="@/assets/images/icon/person-workspace.png" alt="">
                        Leave Type :
                    </div>
                    <div class="vl">{{leavetype}}</div>
                    <div class="idc">
                    {{days}}
                    </div>
                  </div>
                  <div class="list-sr">
                    " {{reason}} "
                  </div>
                  <div class="item-cr-inline">
                    <p>Document: {{docname}} </p>
                    <button @click="onClick()">Download</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- </ModalViewLeaveStatus> -->

  </div>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";

// import Datepicker from 'vue3-datepicker';
import Datepicker from 'vuejs3-datepicker';

import { ref } from 'vue';
const date = ref(new Date());

const mirror = createLocal()
const storage = mirror._prx
// import VCalendar from 'v-calendar';
import Swal from 'sweetalert2';

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


export default {
  name: 'MainDashboard',
  components:{
    Datepicker
  },
  data() {
      return {
          storageData: mirror,
          storage: storage,
          message:  {},
          seen : false,
          empname : "",
          leavedate : "",
          leavetype : "",
          days : "",
          reason : "",
          docname : "",
          docurl : "",
          leavestatus : "",
          leaveidapprove: '',
          leavestatusapprove: '',
          casualleavetaken: '',
          medicalleavetaken: '',
          wfhtaken: '',
          provisional_leave: '',
          provisional_emp_leave: '',
          date :new Date(),
          attendance_list:'',
          dateFormatted:'',
          wfhemplist:'',
          wfhiddata:'',
          attendance_array:'',
          emplist:'',
          emp_array_fname:'',
          emp_array_lname:'',
          emp_array_fullname:'',
          absent_list:'',
          picked: new Date(),
          employees_on_leave:""
         

      }
  },
  async created(){
      this.userName = mirror.USERNAME
      this.getResult();
        console.log("sgvsvfs",this.picked);
      var date = new Date(this.picked);
      console.log("date",date);
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0');
      var day = String(date.getDate()).padStart(2, '0');

      var year1 = date.getFullYear();
      var month1 = String(date.getMonth() + 1);
      var day1 = String(date.getDate());
      var pickedDate = month1.concat("/",day1).concat("/",year);
      console.log("year,month,date",pickedDate);
      console.log("dateselected",this.picked.toLocaleString());
      var dateFormatted = day.concat("-",month).concat("-",year);
      // var pickedDate = this.picked.toLocaleString().split(",")[0];

      console.log("pickedDate",pickedDate);
      console.log("formatted",dateFormatted);

      const getDateData = {'date':pickedDate,'dateFormatted':dateFormatted}
      await axios.post(BASE_URL + '/api/getadmindashboard', getDateData)
      .then((response)=>{
          console.log("response",response.data);
          this.attendance_list = response.data.attendance_list;
          console.log("this.attendance_list",this.attendance_list);
            this.wfhemplist = response.data.wfhemplist;
          console.log("this.wfhemplist",this.wfhemplist);

            this.attendance_array = this.attendance_list.map(x => x.empname);
          console.log("this.attendance_array",this.attendance_array);

            this.emplist = response.data.absent_list; 
          console.log("this.emplist",this.emplist);

            this.emp_array_fullname = this.emplist.map(x => x.fname +" "+ x.lname);
          console.log("this.emp_array_fullname1",this.emp_array_fullname);

            this.emp_array_fullname = this.emp_array_fullname .filter( ( el ) => !this.attendance_array.includes( el ) );
          console.log("this.emp_array_fullname",this.emp_array_fullname);

          this.casualleavetaken = response.data.casual_leave.count;

          this.medicalleavetaken = response.data.medical_leave.count;

          this.provisional_emp_leave = response.data.provisional_emp_leave;

          this.provisional_leave = response.data.provisional_leave;

          this.employees_on_leave = response.data.leaveapproved;

          

  })
    


      
      
     
 },
  methods:{
      async getadmindashboardlvl(){
      var date = new Date(this.picked);
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0');
      var day = String(date.getDate()).padStart(2, '0')
      console.log("dateselected",this.picked.toLocaleString());
      var dateFormatted = day.concat("-",month).concat("-",year);
      // var pickedDate = this.picked.toLocaleString().split(",")[0];
      
      var year1 = date.getFullYear();
      var month1 = String(date.getMonth() + 1);
      var day1 = String(date.getDate());
      var pickedDate = month1.concat("/",day1).concat("/",year);
      console.log("year,month,date",pickedDate);

      console.log("pickedDate",pickedDate);
      console.log("formatted",dateFormatted);

      const getDateData = {'date':pickedDate,'dateFormatted':dateFormatted}
      await axios.post(BASE_URL + '/api/getadmindashboard', getDateData)
      .then((response)=>{
                  console.log("response",response.data);
          this.attendance_list = response.data.attendance_list;
          console.log("this.attendance_list",this.attendance_list);
            this.wfhemplist = response.data.wfhemplist;
          console.log("this.wfhemplist",this.wfhemplist);

            this.attendance_array = this.attendance_list.map(x => x.empname);
          console.log("this.attendance_array",this.attendance_array);

            this.emplist = response.data.absent_list; 
          console.log("this.emplist",this.emplist);

            this.emp_array_fullname = this.emplist.map(x => x.fname +" "+ x.lname);
            this.emp_array_fullname = this.emp_array_fullname .filter( ( el ) => !this.attendance_array.includes( el ) );
          console.log("this.emp_array_fullname",this.emp_array_fullname);

          this.casualleavetaken = response.data.casual_leave.count;

          this.medicalleavetaken = response.data.medical_leave.count;

          this.provisional_emp_leave = response.data.provisional_emp_leave;

          this.provisional_leave = response.data.provisional_leave;

          this.employees_on_leave = response.data.leaveapproved;
          

  })
      },

      async clickedDownload(){
        var pdf = new jsPDF("1", "mm", "a1");
        var element = document.getElementById('accordionExample');
        var width= element.style.width;
        var height = element.style.height;
            html2canvas(element).then(canvas => {
                //console.log(canvas);
                var image = canvas.toDataURL('image/png');
                pdf.addImage(image, 'JPEG', 15, 40, width, height);
                pdf.save('AdminDashboard' + '.pdf')
        });
    },
     
     async isOpen(event){
       //console.log(event);
       this.seen = true;

       const getid = {
          id : event
       };
       //console.log(getid);
          await axios.post(BASE_URL + '/api/getmodalleavestatus',getid)
                  .then((response)=>{
                      //console.log(response.data.record.empname);

                      this.empname = response.data.empname;
                      this.leavedate = response.data.leavedate;
                      this.leavetype = response.data.leavetype;
                      this.days = response.data.days;
                      this.reason = response.data.reason;
                      this.docname = response.data.docname;
                      this.docurl = response.data.docurl;
                      this.leavestatus = response.data.leavestatus;

              })

     },
      onClick() {
        //console.log(this.docurl);

          if(this.docurl === ''){
                  Swal.fire({
                              title: 'OPPS',
                              text:  'No Data Available',
                              icon: 'warning',
                          
                          });
            }else{

                  axios({
                          url: this.docurl,
                          method: 'GET',
                          responseType: 'blob',
                      }).then((response) => {
                          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                          var fileLink = document.createElement('a');
      
                          fileLink.href = fileURL;

                          if(response.data.type == 'image/jpeg'){
                              fileLink.setAttribute('download', 'file.jpeg');
                          }else if(response.data.type == 'image/jpg'){
                              fileLink.setAttribute('download', 'file.jpg');
                          }else if(response.data.type == 'image/png'){
                              fileLink.setAttribute('download', 'file.png');
                          }else if(response.data.type == 'application/pdf'){
                              fileLink.setAttribute('download', 'file.pdf');
                          }else if(response.data.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                              fileLink.setAttribute('download', 'file.docx');
                          }else{
                              fileLink.setAttribute('download', 'file.txt');
                          }

                          document.body.appendChild(fileLink);
      
                          fileLink.click();
                      });

            }

      },
      closemodal(event){
        this.seen = false;
      },
      async leaveapprove(id,empid,leave,days,leave_date,leavestatus){

          if(confirm("Are you sure you want to approve this?")){

              const getempid = {
                  empleaveid : id,                                                                                                                                                                                                                                                                                                                                                                                                                         
                  empid: empid,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                  leave: leave,
                  days: days,
                  leave_date : leave_date,
                  leavestatus: leavestatus,
                  
              };

              //console.log(getempid);

              await axios.post(BASE_URL + '/api/getempid',getempid)
                      .then((response)=>{
                          //console.log(response.data);

                          if(response.data.success == true){

                              //console.log(response.data);
                              axios.post(BASE_URL + '/api/sendapproveleave',{
                                email: response.data.empemail.email,
                                fname:  response.data.fname.fname,
                                lname:  response.data.lname.lname,
                                alldateleaves: response.data.alldate,
                                daysleave : response.data.daysleave,
                                leavetype : response.data.leavetype,
                                leavestatus : response.data.leavestatus,
                            });
                            const res= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: response.data.userId, date: response.data.alldate, type: response.data.leavetype, status:response.data.leavestatus, seenstatus: 'unseen'});

                              Swal.fire({
                                          title: 'Done',
                                          text:  response.data.message,
                                          icon: 'success',
                                      
                                      });

                              this.getResult();
                              this.getadmindashboardlvl();

                              this.leaveidapprove = response.data.leaveid;
                              this.leavestatusapprove = response.data.leavestatus;
                                

                          }else{

                              Swal.fire({
                                      title: 'OPPS',
                                      text:   response.data.message,
                                      icon: 'warning',
                                  
                                  });

                              this.leaveidapprove = '';
                              this.leavestatusapprove = '';
                              
                          }

                  })

          }else{
            return false;
          }


      },
      async leavereject(id,empid,leave,days,leave_date,leavestatus){

        if(confirm("Are you sure you want to reject this?")){

              const getempid = {
                        empleaveid : id,                                                                                                                                                                                                                                                                                                                                                                                                                         
                        empid: empid,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                        leave: leave,
                        days: days,
                        leave_date : leave_date,
                        leavestatus: leavestatus
                    };

                    //console.log(getempid);
                        await axios.post(BASE_URL + '/api/getempidreject',getempid)
                            .then((response)=>{
                                //console.log(response.data);

                                if(response.data.success == true){
                                axios.post(BASE_URL + '/api/sendrejectleave',{
                                email: response.data.empemail.email,
                                fname:  response.data.fname.fname,
                                lname:  response.data.lname.lname,
                                alldateleaves: response.data.alldate,
                                daysleave : response.data.daysleave,
                                leavetype : response.data.leavetype,
                                leavestatus : response.data.leavestatus,
                            });
                            const res= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: response.data.userId, date: response.data.alldate, type: response.data.leavetype, status:response.data.leavestatus, seenstatus: 'unseen'});

                                    Swal.fire({
                                                title: 'Done',
                                                text:  response.data.message,
                                                icon: 'success',
                                            
                                            });

                                  this.getResult();
                                  this.getadmindashboardlvl();

                                }else{
                                    Swal.fire({
                                            title: 'OPPS',
                                            text:   response.data.message,
                                            icon: 'warning',
                                        
                                        });
                                }

                        })
          
              }else{
                return false;
              }

      },
      async getResult(){

        await axios.post(BASE_URL + '/api/getleavedetailsadmindashboard')
                      .then((response)=>{
                          console.log(response.data);

                          this.message = response.data;

                  })
      },
  },

  watch:{
        async picked(date){
          var date = new Date(this.picked);
          console.log("date",this.picked);
          var year = date.getFullYear();
          var month = String(date.getMonth() + 1).padStart(2, '0');
          var day = String(date.getDate()).padStart(2, '0')
          console.log("dateselected",this.picked.toLocaleString());
          var dateFormatted = day.concat("-",month).concat("-",year);

          var year1 = date.getFullYear();
          var month1 = String(date.getMonth() + 1);
          var day1 = String(date.getDate());
          var pickedDate = month1.concat("/",day1).concat("/",year);
          console.log("year,month,date",pickedDate);

          // var pickedDate = this.picked.toLocaleString().split(",")[0];
          console.log("pickedDate",pickedDate);
          // var split_date = pickedDate.split('/');
          // var dateFormatted = split_date[1].concat("-",split_date[0]).concat("-",split_date[2]);
          console.log("formatted",dateFormatted);
          const getDateData = {'date':pickedDate,'dateFormatted':dateFormatted}
          await axios.post(BASE_URL + '/api/getadmindashboard', getDateData)
                      .then((response)=>{
                          console.log("response",response.data);
                          this.attendance_list = response.data.attendance_list;
                          console.log("this.attendance_list",this.attendance_list);
                            this.wfhemplist = response.data.wfhemplist;
                          console.log("this.wfhemplist",this.wfhemplist);

                            this.attendance_array = this.attendance_list.map(x => x.empname);
                          console.log("this.attendance_array",this.attendance_array);

                            this.emplist = response.data.absent_list; 
                          console.log("this.emplist",this.emplist);

                            this.emp_array_fullname = this.emplist.map(x => x.fname +" "+ x.lname);
                            this.emp_array_fullname = this.emp_array_fullname .filter( ( el ) => !this.attendance_array.includes( el ) );
                          console.log("this.emp_array_fullname",this.emp_array_fullname);

                          this.casualleavetaken = response.data.casual_leave.count;

                          this.medicalleavetaken = response.data.medical_leave.count;

                          this.provisional_emp_leave = response.data.provisional_emp_leave;

                           this.provisional_leave = response.data.provisional_leave;

                           this.employees_on_leave = response.data.leaveapproved;
                         

                  })

        }
  },
  

}
</script>

<style scoped>
</style>