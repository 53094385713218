<template>
    <div class="emp_dashboard_header">
        <div class="left_sides">
            <div class="profile">
                <div class="images">
                    <img v-if="empimage!=''" v-bind:src="empimage" alt="">
                    <img v-else src="../../assets/empdashboardimages/User.png" alt="profile-image">
                    <span v-if="current_CinCoutStatus == 'in'" class="image_dots green"></span>
                    <span v-else class="image_dots red"></span>
                </div>
            </div>
            <div class="text">
                <h3>{{userName}} </h3>
                <p>Welcome Back to Reddensoft</p>
            </div>
        </div>
        <div class="right_side">

            <div class="timer_date">
                <button v-if="text==='Loading..'" class="timer_div" disabled @click="added(),timer(text, false)" >
                    <div class="clock_box">
                        <img src="@/assets/dashboard/clock1.gif" alt="clock-gif" v-if = "text == 'Check Out'" />
                        <img src="../../assets/empdashboardimages/clock1.png" alt="clock-icon" v-else />
                    </div>
                    <div class="times_box">
                        <h3>
                            <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                        </h3>
                        <div>
                            <p v-if="text==='Loading..'" >{{ text}}</p>
                            <p v-else >{{ text}}</p>
                        </div>
                    </div>
                </button>
                <button v-else-if="text=='Check In' && checkTimer" class="timer_div" @click="checkTimer = false,this.updatestatus = true,this.updatestatusOnload = false,added(),timer(text, true)" >
                    <div class="clock_box">
                        <img src="@/assets/dashboard/clock1.gif" alt="clock-gif" v-if = "text == 'Check Out'" />
                        <img src="../../assets/empdashboardimages/clock1.png" alt="clock-icon" v-else />
                    </div>
                    <div class="times_box">
                        <h3>
                            <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                        </h3>
                        <div>
                            <p v-if="text==='Loading..'" >{{ text}}</p>
                            <p v-else >{{ text}}</p>
                        </div>
                    </div>
                </button>
                <button v-else-if="text=='Check Out' && checkTimer" class="timer_div timer_div_out" @click="checkTimer = false,added()" >
                    <div class="clock_box">
                        <img src="@/assets/dashboard/clock1.gif" alt="clock-gif" v-if = "text == 'Check Out'" />
                        <img src="../../assets/empdashboardimages/clock1.png" alt="clock-icon" v-else />
                    </div>
                    <div class="times_box">
                        <h3>
                            <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                        </h3>
                        <div>
                            <p v-if="text==='Loading..'" >{{ text}}</p>
                            <p v-else >{{ text}}</p>
                        </div>
                    </div>
                </button>
                <button v-else class="timer_div" disabled @click="added(),timer(text, false)" >
                    <div class="clock_box">
                        <img src="@/assets/dashboard/clock1.gif" alt="clock-gif" v-if = "text == 'Check Out'" />
                        <img src="../../assets/empdashboardimages/clock1.png" alt="clock-icon" v-else />
                    </div>
                    <div class="times_box">
                        <h3>
                            <span class="hour" id="hours">00</span>:<span class="min" id="minutes">00</span>:<span class="sec" id="seconds">00</span>
                        </h3>
                        <div>
                            <p v-if="text==='Loading..'" >{{ text}}</p>
                            <p v-else >{{ text}}</p>
                        </div>
                    </div>
                </button>
                <div class="date_div">
                    <p>Date: {{currentDate}}</p>
                </div>
            </div>

            <div class="btn_box" v-if="marketing_status != 'non_member'">
                <button class="new_btn" @click="toggleClass3">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="18" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                    </span>
                    New Deal
                </button>

                <button class="new_btn" @click="toggleClass4">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="18" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                    </span>
                    New Report
                </button>
            </div>

            <div :class="{ 'active_class': isClassAdded3 }" class="modal_div modal_height">
                <div class="modal_width">
                    <div class="header">
                        <div class="name">
                            <h2>New Deal</h2>
                        </div>
                        <div class="close">
                            <button @click="toggleClass3">
                                <img src="../../assets/empdashboardimages/close-icon.png" alt="close-icon">
                            </button>
                        </div>
                    </div>
                    <div class="modal_body">
                        <div class="box">
                            <div class="background">
                                <div class="tittle">
                                    <h3>Deal Details</h3>
                                </div>
                                <div class="input_div">
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-8">
                                                <label>Client Name</label>
                                                <input type="text" v-model="newTransaction.clientName" placeholder="Enter Client Name" />
                                                <span v-if="v3$.clientName.$error" class="field-error">
                                                {{ v3$.clientName.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>Amount</label>
                                                <input type="number" v-model="newTransaction.amount" placeholder="Enter Amount" />
                                                <span v-if="v3$.amount.$error" class="field-error">
                                                {{ v3$.amount.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Deal Status</label>
                                                <select name="" id="" v-model="newTransaction.paymentStatus">
                                                    <option value="">Select One</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Closed">Closed</option>
                                                    <option value="Wait">Wait</option>
                                                </select>
                                                <span v-if="v3$.paymentStatus.$error" class="field-error">
                                                {{ v3$.paymentStatus.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>Beneficiary Status</label>
                                                <select name="" id="" v-model="newTransaction.beneficiary">
                                                    <option value="">Select One</option>
                                                    <option value="Reddensoft">Reddensoft</option>
                                                    <option value="Lynkme">Lynkme 360</option>
                                                    <option value="Bigwave">Bigwave</option>
                                                </select>
                                                <span v-if="v3$.beneficiary.$error" class="field-error">
                                                {{ v3$.beneficiary.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>Date</label>
                                                <input type="date" placeholder="yyyy-mm-dd" v-model="newTransaction.date" :max="maxDate"/>
                                                <span v-if="v3$.date.$error" class="field-error">
                                                {{ v3$.date.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-6" v-if="newTransaction.paymentStatus == 'Closed'">
                                                <label>Payment Status</label>
                                                <select name="" id="" v-model="newTransaction.payment_status">
                                                    <option value="">Select One</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Paid">Paid</option>
                                                </select>
                                            </div>
                                            <div class="col-6" v-if="newTransaction.paymentStatus == 'Closed'">
                                                <label>Payment Method</label>
                                                <select name="" id="" v-model="newTransaction.payment_method">
                                                    <option value="">Select One</option>
                                                    <option value="Banking">Bank Transfer</option>
                                                    <option value="Crypto">Crypto</option>
                                                    <option value="Paypal">Paypal</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="background">
                                <div class="tittle">
                                    <h3>Advertiser Details</h3>
                                </div>
                                <div class="input_div">
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-12">
                                                <label>Name</label>
                                                <input type="text" placeholder="Enter Company Name" v-model="newTransaction.cName" />
                                                <span v-if="v3$.cName.$error" class="field-error">
                                                {{ v3$.cName.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-12">
                                                <label>Address</label>
                                                <textarea placeholder="Enter Company Address" v-model="newTransaction.cAddress"></textarea>
                                                <span v-if="v3$.cAddress.$error" class="field-error">
                                                {{ v3$.cAddress.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Contact Person</label>
                                                <input type="text" placeholder="Name" v-model="newTransaction.contact_person_name"/>
                                                <span v-if="v3$.contact_person_name.$error" class="field-error">
                                                {{ v3$.contact_person_name.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>Registration Number</label>
                                                <input type="number" placeholder="Enter Registration Number" v-model="newTransaction.contact_person_reg_no"/>
                                                <span v-if="v3$.contact_person_reg_no.$error" class="field-error">
                                                {{ v3$.contact_person_reg_no.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>Email</label>
                                                <input type="text" placeholder="Enter Company Email" v-model="newTransaction.contact_person_email"/>
                                                <span v-if="v3$.contact_person_email.$error" class="field-error">
                                                {{ v3$.contact_person_email.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="background">
                                <div class="tittle">
                                    <h3>Campaign Details</h3>
                                </div>
                                <div class="input_div">
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Site /Application</label>
                                                <input type="text" placeholder="Enter Site" v-model="newTransaction.web_app_name"/>
                                                <span v-if="v3$.web_app_name.$error" class="field-error">
                                                {{ v3$.web_app_name.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>Start Date</label>
                                                <input type="date" placeholder="yyyy-mm-dd" v-model="newTransaction.campaign_start_date"/>
                                                <span v-if="v3$.campaign_start_date.$error" class="field-error">
                                                {{ v3$.campaign_start_date.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>End Date</label>
                                                <input type="date" placeholder="yyyy-mm-dd" v-model="newTransaction.campaign_end_date"/>
                                                <span v-if="v3$.campaign_end_date.$error" class="field-error">
                                                {{ v3$.campaign_end_date.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Placement</label>
                                                <input type="text" placeholder="Enter Placement" v-model="newTransaction.campaign_placement"/>
                                                <span v-if="v3$.campaign_placement.$error" class="field-error">
                                                {{ v3$.campaign_placement.$errors[0].$message }}
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <label>Total Budget</label>
                                                <input type="number" placeholder="Enter Total Budget" v-model="newTransaction.campaign_budget"/>
                                                <span v-if="v3$.campaign_budget.$error" class="field-error">
                                                {{ v3$.campaign_budget.$errors[0].$message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="background">
                                <div class="tittle">
                                    <h3>Campaign Type</h3>
                                </div>
                                <div class="input_div">
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-8">
                                                <input type="text" placeholder="Enter Categories" v-model="newCategory.name" />
                                            </div>
                                            <div class="col-4">
                                                <button class="button_plus" @click="addBox">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="18" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box" v-for="(box, index) in boxes" :key="index">
                            <div class="background background_padding" :class="{ active: isArrowClicked[index] }">
                                <div class="tittle sub_tittle">
                                    <h3>{{ box.name }}</h3>
                                    <div class="delete_div">
                                        <div class="arrow">
                                            <!-- <img src="../../assets/empdashboardimages/down-arrow.png" alt="down-arrow"> -->
                                            <img @click="toggleBackground(index)" src="../../assets/empdashboardimages/down-arrow.png" alt="down-arrow">  
                                        </div>
                                        <div class="delt">
                                            <!-- <img src="../../assets/empdashboardimages/delete-icon.png" @click="deleteBox(index)" alt="delete-icon"> -->
                                            <img @click="deleteBox(index)" src="../../assets/empdashboardimages/delete-icon.png" alt="delete-icon">
                                        </div>
                                    </div>
                                </div>
                                <div class="input_div sub_input_div">
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-12">
                                                <label>Model</label>
                                                <input type="text" placeholder="Enter Model" v-model="box.model"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Rate</label>
                                                <input type="number" placeholder="Enter Rate" v-model="box.rate"/>
                                            </div>
                                            <div class="col-4">
                                                <label>Quantity</label>
                                                <input type="number" placeholder="Enter Quantity" v-model="box.quantity"/>
                                            </div>
                                            <div class="col-4">
                                                <label>Total</label>
                                                <input type="number" placeholder="Total" v-model="box.total"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input_button">
                            <button @click="generate()">Generate</button>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'modal_background': isClassAdded3 }" @click="toggleClass3"></div>

            <div :class="{ 'active_class': isClassAdded4 }" class="modal_div modal_height">
                <div class="modal_width">
                    <div class="header">
                        <div class="name">
                            <h2>Add Report</h2>
                        </div>
                        <div class="close">
                            <button @click="toggleClass4">
                                <img src="../../assets/empdashboardimages/close-icon.png" alt="close-icon">
                            </button>
                        </div>
                    </div>
                    <div class="modal_body">
                        <div class="box">
                            <div class="background">
                                <div class="tittle">
                                    <h3>Add Report</h3>
                                </div>
                                <div class="input_div">
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Date</label>
                                                <input type="date" placeholder="yyyy-mm-dd" v-model="daily_report.date" :max="maxDate"/>
                                            </div>
                                            <div class="col-4">
                                                <label>No of Mails Sent</label>
                                                <input type="number" placeholder="No of Mails Sent" v-model="daily_report.total_mail_sent"/>
                                            </div>
                                            <div class="col-4">
                                                <label>No of Positive Responses</label>
                                                <input type="number" placeholder="No of Positive Responses" v-model="daily_report.total_positive_response"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_box">
                                        <div class="row">
                                            <div class="col-4">
                                                <label>No of Total Responses</label>
                                                <input type="number" placeholder="No of Total Responses" v-model="daily_report.total_response"/>
                                            </div>
                                            <div class="col-4">
                                                <label>No of Leads Generated</label>
                                                <input type="number" placeholder="No of Leads Generated" v-model="daily_report.total_lead"/>
                                            </div>
                                            <div class="col-4">
                                                <label>Deals Closed</label>
                                                <input type="number" placeholder="Deals Closed" v-model="daily_report.lead_closed"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input_button">
                            <button @click="submitReport()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'modal_background': isClassAdded4 }" @click="toggleClass4"></div>

            <!-- <div class="search_div">
                <div class="search">
                    <input type="text" placeholder="Search Everything">
                    <button>
                        <img src="../../assets/empdashboardimages/search-icon.png" alt="search-icon">
                    </button>
                </div>
            </div> -->

            <div class="announcement">
                <div class="announcement_btn">
                    <button class="modal_button" @click="toggleClass1">
                        <img src="../../assets/empdashboardimages/Announcement.png" alt="announcement-icon">
                    </button>
                    <span>
                        0
                    </span>
                </div>
                <div :class="{ 'active_class': isClassAdded1 }" class="modal_divs">
                    <div class="modal_width">
                        <div class="header">
                            <div class="name">
                                <h2>Announcements</h2>
                            </div>
                            <div class="close">
                                <button @click="toggleClass1">
                                    <img src="../../assets/empdashboardimages/close-icon.png" alt="close-icon">
                                </button>
                            </div>
                        </div>
                        <div class="modal_body">
                            <div class="box">
                                <div class="text">
                                    <h3>
                                        After the task is completed or ongoing, please submit the detailed report Not just like done/completed comments, please provide a detailed report. You can discuss the PM team.
                                        <span></span> 
                                    </h3>
                                    <p>
                                        <span></span>
                                        Reddensoft Team
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{ 'modal_background': isClassAdded1 }" @click="toggleClass1"></div>
            </div>

            <div class="notifiaction">
                <div class="notifaction_btn">
                    <button class="modal_button" @click="toggleClass5">
                        <img src="../../assets/empdashboardimages/notification.png" alt="notification-icon">
                    </button>
                    <span>
                        {{notification_count}}
                    </span>
                </div>
                <div :class="{ 'active_class': isClassAdded2 }" class="modal_div">
                    <div class="modal_width">
                        <div class="header">
                            <div class="name">
                                <h2>Notifiaction</h2>
                            </div>
                            <div class="close">
                                <button @click="toggleClass2">
                                    <img src="../../assets/empdashboardimages/close-icon.png" alt="close-icon">
                                </button>
                            </div>
                        </div>
                        <div class="modal_body" >
                            <div v-if="notification !=''" v-for="user in notification">
                                <div class="box" v-if="user.type == 'WFH' ">
                                    <div class="image">
                                        <img  src="@/assets/empdashboardimages/work-from-home.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Casual Leave' ">
                                    <div class="image">
                                        <img  src="@/assets/empdashboardimages/work-from-home.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Medical Leave' ">
                                    <div class="image">
                                        <img  src="@/assets/empdashboardimages/work-from-home.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Provisional' ">
                                    <div class="image">
                                        <img  src="@/assets/empdashboardimages/work-from-home.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Date Change' ">
                                    <div class="image">
                                        <img  src="@/assets/empdashboardimages/work-from-home.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Unpaid' ">
                                    <div class="image">
                                        <img  src="@/assets/empdashboardimages/work-from-home.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                                <div class="box" v-if="user.type == 'Carry forward' ">
                                    <div class="image">
                                        <img src="@/assets/empdashboardimages/work-from-home.png" alt="">
                                    </div>
                                    <div class="text">
                                        <h3>
                                            <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " >Your {{ user.type }} request for this financial year has been {{ user.status }} <br></button>                   
                                            <!-- <button :style="'background: ' + (user.seenstatus == 'seen' ? '' : 'rgb(198 213 222)') " @click="onWfhClick(user.empid, user.date, user.type)">Your {{ user.type }} request for {{ user.date }} has been {{ user.status }} <br></button>                    -->
                                        </h3>
                                        <!-- <p>
                                            <span></span>
                                            HRMS
                                        </p> -->
                                    </div>
                                </div>
                            </div>
                            <div v-else class="notification_div">
                                <p>No new notification to show</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{ 'modal_background': isClassAdded2 }" @click="toggleClass2"></div>
            </div>

            <div class="settings">
                <div class="dropdown">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../../assets/empdashboardimages/setting.png" alt="profile-image">
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <div class="profile_box">
                            <div class="profile_image">
                                <!-- <img src="../../assets/empdashboardimages/User.png" alt="profile-image"> -->
                                <span>
                                    <img v-if="empimage!=''" v-bind:src="empimage" alt="">
                                    <!-- <span class="image_name" v-else >{{first_name_letter}}{{ last_name_letter }}</span> -->
                                    <img v-else src="../../assets/empdashboardimages/User.png" alt=""/>
                                </span>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                                </button>
                            </div>
                            <div class="name">
                                <h2>{{userName}} {{userlName}}</h2>
                                <p>{{position}}</p>
                            </div>
                        </div>
                        <div class="profile_on">
                            <ul>
                                <li>
                                    <a class="dropdown-item" href="#" v-on:click="logout">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"/></svg>
                                        </span>
                                        Log Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade profile_modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Profile Picture</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="paragraph">
            <p>A picture helps people recognize you and lets you know when you’re signed in to your account</p>
          </div>
          <div class="modal_image">
            <input type="file" @change="previewImage"  accept="image/png, image/jpeg, image/jpg" ref="fileInput" id="fileInput" />
            <label for="fileInput">
              <img v-if="imagePreview" :src="imagePreview" alt="profile-image">
              <img v-else-if="empimage!=''" v-bind:src="empimage" alt="">
              <img v-else src="../../assets/empdashboardimages/User.png" alt="default-profile-image">
            </label>
          </div>
          <div class="modal_btn">
            <!-- Button to trigger image upload -->
            <button @click="uploadImage" class="btn btn-primary">Add Profile Picture</button>
          </div>
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import { createLocal, createSession, createStorage } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
// import HackTimer from "../../HackTimer";
const mirror = createLocal()
const storage = mirror._prx
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import { reactive, computed } from "vue";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { ref } from "vue";
    export default {
        name: 'EmpDashboardHeader',
        beforeRouteLeave(to, from, next) {
                // Clean up resources or stop timers here
            clearInterval(this.updateCoundtdownIntervalHandleTimer);
            next();
        },
        setup() {
            const newTransaction = reactive({
                clientName: "",
                amount: "",
                paymentStatus: "",
                date: "",
                // invoice: "",
                beneficiary: "",
                // payment_status:"",
                payment_method:"",
                payment_status:"",
                cName:"",
                cAddress:"",
                contact_person_name:"",
                contact_person_reg_no:"",
                contact_person_email:"",
                web_app_name:"",
                campaign_start_date:"",
                campaign_end_date:"",
                campaign_placement:"",
                campaign_budget:"",
            });

            const io = reactive({
            company_name: "",
            company_address: "",
            contact_person_name: "",
            contact_person_reg_no: "",
            contact_person_email: "",
            web_app_name: "",
            start_date: "",
            end_date: "",
            placemnet: "",
            total_budget: "",
            solo_email_model: "",
            solo_email_rate: "",
            solo_email_quantity: "",
            solo_email_total: "",
            email_template_model: "",
            email_template_rate: "",
            email_template_quantity: "",
            email_template_total: "",
            });

            const daily_report = reactive({
            date: "",
            total_mail_sent: 0,
            total_positive_response: 0,
            total_response: 0,
            total_lead: 0,
            lead_closed: 0,
            });

            const rules3 = computed(() => {
            return {
                clientName: { required },
                amount: { required },
                paymentStatus: { required },
                date: { required },
                beneficiary: { required },
                // payment_status: { required },
                // payment_method: { required },
                cName: { required },
                cAddress: { required },
                contact_person_name: { required },
                contact_person_reg_no: { required },
                contact_person_email: { required },
                web_app_name: { required },
                campaign_start_date: { required },
                campaign_end_date: { required },
                campaign_placement: { required },
                campaign_budget: { required },
                //     orgname: { required },
                //     email: { required, email },
                //     phone: { required, minLength: minLength(10), maxLength: maxLength(12) },
                //     country:{ required },
                // //   timezone:{ required },
                //     states:{ required },
                // //   dateformat:{ required },
                //     password:{ required, minLength: minLength(8)},
                //     confirmpassword:{ required, sameAsPassword: sameAs(state3.password),},
            };
            });
            const v3$ = useValidate(rules3, newTransaction);

            const selectedData = ref([]);

            function handleItemClick(title) {
            //   console.log("chgrdtr");
            if (!selectedData.value.includes(title)) {
                selectedData.value.push(title);
            } else {
                selectedData.value = selectedData.value.filter(
                (item) => item !== title
                );
            }
            }

            const menuItems = [{ title: "Solo Email" }, { title: "Email Template" }];

            const daily_report_details = reactive({
            date: "",
            total_mail_sent: "",
            total_positive_response: "",
            total_response: "",
            total_lead: "",
            lead_closed: "",
            });

            return {
            selectedData,
            handleItemClick,
            menuItems,
            v3$,
            newTransaction,
            io,
            daily_report,
            daily_report_details,
            };
        },
        data() {
            return {
                isClassAdded1: false,
                isClassAdded2: false,
                isClassAdded3: false,
                isClassAdded4: false,
                // newCategory: '',
                boxes: [],
                isArrowClicked: [],

                marketing_status: "",

                isClassAdded: false,
                clockHide: true,
                buttonBackground: "#3cb89b",
                currentTime: "00:00:00",
                buttonText: "Check In",
                checkInTime: null,
                checkOutTime: null,
                showPopup: true,
                text: 'Loading..',
                transactions: [],
                timerId:'',
                time0:'',
                timerSelection : 'Work',
                startingMinutes : '',
                startingSeconds : '00',
                timerRunning:'',
                stopTimer:'',
                playing : false,
                currentMinutes:'',
                currentSeconds:'',
                updateCoundtdownIntervalHandle : null,
                updateCoundtdownIntervalHandleTimer : null,
                initial_minutes: '',
                initial_seconds: '',
                buttoncheckfordisable:true,
                storageData: mirror,
                storage: storage,
                isHovering: false,
                isHovering1 : false,
                notification: '',
                countn:0,
                empimage:'',
                fetchednotificationcount:'',
                empname:'',
                notification_count:0,
                first_name_letter:'',
                last_name_letter:'',
                statusInputData:'',
                current_CinCoutStatus:'',
                categories: [],
                newCategory: {
                    name: "",
                    model: "",
                    rate: "",
                    quantity: "",
                    total: "",
                },
                userfullname:'',
                imagePreview: null,
                timerClocktoggle: false,
                updatestatus:false,
                timerInitialized:false,
                updatestatusOnload: false,
                updateCoundtdownIntervalHandleTimerLoad: null,
                currentDate: '',
                userList:[],
                checkTimer : true,
                clockinoutstatus:'',
                curentDateFormatted:'',
                position:'',
                deviceType:''

            }
        },

        // beforeRouteLeave(to, from, next) {
        //     console.log("htttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt");
        //     clearInterval(this.updateCoundtdownIntervalHandle);
        //     next();
        // },
        beforeRouteLeave(to, from, next) {
            console.log("htttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt");

            clearInterval(this.updateCoundtdownIntervalHandleTimer);
            clearInterval(this.updateCoundtdownIntervalHandleTimerLoad);
            next();
        },

        async created(event){

            if (!mirror.ID) {
                localStorage.clear();
                this.$router.push('/');
            }
            else{
                // // Reset timer variables
                // this.currentSeconds = '00';
                // this.currentMinutes = '00';
                // this.currentHours = '00';

                // // Update timer display elements
                // document.querySelector("#minutes").innerHTML = this.currentMinutes;
                // document.querySelector("#seconds").innerHTML = this.currentSeconds;
                // document.querySelector("#hours").innerHTML = this.currentHours;
            clearInterval(this.updateCoundtdownIntervalHandleTimer);
            clearInterval(this.updateCoundtdownIntervalHandleTimerLoad);
            this.timerClocktoggle = false;
            // console.log("created created created created",this.timerInitialized);
            // clearInterval(this.updateCoundtdownIntervalHandleTimer);
            this.marketing_status = mirror.marketing_status;
            console.log("alpha@test.com",this.marketing_status);
            this.userName = mirror.USERNAME;
            this.userlName = storage.USERLNAME;
            this.userfullname = this.userName.concat(" ", this.userlName);
            this.userEmail = mirror.USEREMAIL;
            this.empId = mirror.EMPID;
            this.casual_leave = mirror.CASUAL_LEAVE;
            this.sick_leave = mirror.SICK_LEAVE;
            this.emp_status = mirror.EMP_STATUS;
            this.first_name_letter = this.userName.charAt(0);
            this.last_name_letter = this.userlName.charAt(0);
            this.ID = mirror.ID;
            this.position = mirror.position;

            const notification_result =await axios.post(BASE_URL + '/api/getempnotification',{id:this.ID});
            this.notification = notification_result.data.notificationdata;
            this.fetchednotificationcount = notification_result.data.fetchednotificationcount;
            // this.countn = notification_result.data.count.length;
            this.empimage = notification_result.data.empimage.image;
            this.notification_count = notification_result.data.empnotifcation_count;
            this.fetchkey();
        
            var currentTime1 = new Date();
            var currentOffset = currentTime1.getTimezoneOffset();
            var offsettobechanged = 330 + currentOffset;
        
            // console.log("currentOffset",currentOffset);
            // console.log("offsettobechanged",offsettobechanged);
        
        
            const now = new Date();
            const utcOffset = (offsettobechanged/60).toFixed(1); // IST UTC offset is 5 hours and 30 minutes
            // console.log("utcOffset",utcOffset);
            // Calculate the IST time by adding the UTC offset
            const istTime = new Date(now.getTime() + utcOffset * 60 * 60 * 1000);
            var today0 = istTime.toLocaleString();
            // console.log("istTime",istTime.toLocaleString());
        
        
        
        
        
            var today_date0 = today0.split(",");
            var today_date = today_date0[0].split("/");
            this.currentdateandtime = today_date0[0];
            // console.log("today_date",today_date0);
        
            var today_date_formatted = today_date[1].concat("/",today_date[0]).concat("/",today_date[2]);
            var currentdate = today_date[1].concat("-",today_date[0]).concat("-",today_date[2]);
            var currenttime = today_date0[1];
        
            var todayTime_split = today_date0[1].split(':'); 
            // console.log("dgsfsgs",todayTime_split);
        
            var ampm = todayTime_split[2].split(" ")[1];
            if(ampm == undefined){
                if(todayTime_split[0]>=12){
                ampm = 'pm';
                }else{
                ampm = 'am';
                }
            }
            // console.log("ampm",ampm);
            // console.log("todayTime_split",todayTime_split[0]);
        
            if (ampm == 'AM'|| ampm == 'am') {
                if (todayTime_split[0]==12) {
                var todayTime_seconds = (+todayTime_split[0]-12) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
                } else {
                var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
                }
                // console.log("am");
            }
            else{
                // console.log("pm");
        
                if (todayTime_split[0]==12) {
                var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]);  
                // console.log("pm if");
        
                }
                else if(todayTime_split[0]>12){
                var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]);  
                
                }else{
                var todayTime_seconds = (+todayTime_split[0]+12) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
                }
            }
        
            // var todayTime_seconds = (+todayTime_split[0]) * 60 * 60 + (+todayTime_split[1]) * 60 + (+todayTime_split[2].split(" ")[0]); 
            // console.log("currtime", todayTime_seconds);
        
            var currdate = new Date();
            var currmonth = currdate.getMonth()+1;
            var currday = currdate.getDate();
            var curryear = currdate.getFullYear();
            var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear);
            this.curentDateFormatted = concatteddate;
            var start_date1=new Date();
            var attendance_year1 =start_date1.getFullYear();
            var attendance_month1 =start_date1.getMonth();
            var month = attendance_month1;
        
            var uId = this.ID;
            // var today = today_date0[0];
            var month = attendance_month1;
            var today = concatteddate;
            // console.log("aaaaaaa",uId);
            // console.log("bbbbbbb",today);
            // console.log("ccccccc",month);
            // this.abcd(uId, today, month);
        
            this.statusInputData = {"id":uId, "date":today, "month":month};
            const getInOutStatus= await axios.post(BASE_URL + '/api/getCheckinStatus',this.statusInputData);
            // console.log("ifcheckedin",getInOutStatus.data.statusInOut);
            this.current_CinCoutStatus = (getInOutStatus.data.statusInOut==null)?'out':getInOutStatus.data.statusInOut.status;
            var lastCheckinTime = (getInOutStatus.data.last_chekin_time==null)?'00:00:00':getInOutStatus.data.last_chekin_time.start_time;
        
            // console.log("lastCheckinTime",lastCheckinTime);
            var lastCheckinTime_split = lastCheckinTime.split(':'); 
            var ampmcheckin = lastCheckinTime_split[2].split(" ")[1];
            // console.log("lastCheckinTime",lastCheckinTime_split[0]);
        
            if (ampmcheckin == 'AM'||ampmcheckin == 'am') {
            if (lastCheckinTime_split[0]==12) {
                var lastCheckinTime_seconds = (+lastCheckinTime_split[0]-12) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
                
            } else {
                var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
                
            }
            // console.log("am");
            } else {
            if (lastCheckinTime_split[0]==12) {
                var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
                
            }else{
                var lastCheckinTime_seconds = (+lastCheckinTime_split[0]+12) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
        
            }
            // console.log("pm");
            
            }
            // var lastCheckinTime_seconds = (+lastCheckinTime_split[0]) * 60 * 60 + (+lastCheckinTime_split[1]) * 60 + (+lastCheckinTime_split[2].split(" ")[0]); 
            this.startingSeconds = todayTime_seconds - lastCheckinTime_seconds;
        
            // console.log("timeeeeeeee1", lastCheckinTime_seconds);
            // console.log("timeeeeeeee2", this.startingSeconds);
            // console.log("timeeeeeeee3", todayTime_seconds);
            // console.log("status", this.current_CinCoutStatus);
        
        
        
            if (this.current_CinCoutStatus=="out") {
            this.text = "Check In";
            this.startingSeconds = "0";
            //this.chechinCheckOutStatus = false;
            // console.log("IF");
            
            }
            else{
            this.text = "Check Out";
            this.chechinCheckOutStatus = true;
            // clearInterval(this.updateCoundtdownIntervalHandle);
            // this.clock(this.chechinCheckOutStatus,this.startingSeconds);
            var statustext = "Check Out";
            // this.timerLoad(this.text, false);
            if(this.updatestatus == false && this.updatestatusOnload == false){
                // this.updatestatus = false;
                // this.updatestatusOnload = true;
                // clearInterval(this.updateCoundtdownIntervalHandleTimer);
                clearInterval(this.updateCoundtdownIntervalHandleTimer);
                this.timer(statustext, true);
                // this.timer(statustext, false);
                // console.log("this.updatestatus elseeeeeeee");

            }
            
            } 
        }
        },
        computed: {
            maxDate() {
                // Get today's date in the format yyyy-mm-dd
                const today = new Date().toISOString().split('T')[0];
                return today;
            }
        },
        mounted() {
        // Check the user's check-in status and start the appropriate timer function
            // if (this.text == "Check Out") {
            //     this.timerLoad(this.text);
            //     // this.timer(this.text, false);

            // },
            
            this.updateCurrentDate();
        },

        beforeUnmount() {
            // Clear the interval when the component is unmounted
            clearInterval(this.updateCoundtdownIntervalHandleTimer);
        },

        methods: {
                updateCurrentDate() {
                    const date = new Date();
                    const options = { year: 'numeric', month: 'short', day: '2-digit' };
                    this.currentDate = date.toLocaleDateString('en-US', options).replace(/,/g, '');
                },
                async buttonClicked() {
                    // console.log("button clicked");
                    var month = currdate.getMonth();
                    const idData = {"id":this.ID, "date":this.curentDateFormatted, "month":month};
                    const attendance_data= await axios.post(BASE_URL + '/api/get_attendance',idData);
                    this.current_CinCoutStatus = attendance_data.data.clockinstatuscheck;

                    this.userList = attendance_data.data.userList;
                    // const userList = ['user1', 'user2', 'user3'];
                    const userList = this.userList;
                    this.$emit('userListUpdated', userList);
                    // this.$router.push({ name: 'EmpDashboardNewPage', params: { userList } });
                // Other actions you want to perform on button click
                },
                previewImage(event) {
                    const file = event.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = () => {
                        this.imagePreview = reader.result;
                        };
                        reader.readAsDataURL(file);
                    }
                },
                async uploadImage() {
                    // Check if an image has been selected for upload
                    if (this.imagePreview) {
                        // Assuming you have access to the user ID
                        const userId = 'user_id_here';
                        
                        // Prepare form data
                        const formData = new FormData();
                        formData.append('photo', this.$refs.fileInput.files[0]);
                        formData.append('id', this.ID);
                        
                        // Make API call to upload image
                        axios.post(BASE_URL + '/api/uploadImageEmp', formData)
                        .then(response => {
                            // Handle success

                            this.fetchnotification()
                            // this.isClassAdded2 = !this.isClassAdded2;
                            this.tostMessage('success','Profile Picture updated successfully');
                            var image = 'right';
                            this.$emit('imageUpdated', image);

                        })

                    } else {
                        this.tostMessage('warning','No image selected for upload');
                        // console.error('No image selected for upload');
                    }
                },
                async fetchnotification(){
                    const notification_result =await axios.post(BASE_URL + '/api/getempnotification',{id:this.ID});
                    // this.notification = notification_result.data.notificationdata;
                    // this.fetchednotificationcount = notification_result.data.fetchednotificationcount;
                    // this.countn = notification_result.data.count.length;
                    this.empimage = notification_result.data.empimage.image;
                    // this.notification_count = notification_result.data.empnotifcation_count;
                },
                toggleClass1(){
                    this.isClassAdded1 = !this.isClassAdded1;
                },
                toggleClass2(){
                    this.isClassAdded2 = !this.isClassAdded2;
                },
                toggleClass5(){
                    this.isClassAdded2 = !this.isClassAdded2;
                    this.notification_count = 0;
                    this.isClassAdded = !this.isClassAdded;
                    const emp_image = axios.post(BASE_URL + '/api/updateusernotifiction',{id:this.ID});
                },
                toggleClass3(){
                    this.isClassAdded3 = !this.isClassAdded3;
                },
                toggleClass4(){
                    this.isClassAdded4 = !this.isClassAdded4;
                },
                addBox() {
                    if (this.newCategory.name.trim() !== '') {
                        this.boxes.push({ ...this.newCategory });
                        Vue.set(this.isArrowClicked, this.isArrowClicked.length, false);
                        this.newCategory.name = '';
                    }
                },
                deleteBox(index) {
                    this.boxes.splice(index, 1);
                    this.isArrowClicked.splice(index, 1);
                },
                toggleBackground(index) {
                    Vue.set(this.isArrowClicked, index, !this.isArrowClicked[index]);
                },

                toggleClass() {
                    this.isClassAdded = !this.isClassAdded;
                },


            // async toggleClass2(){
            //     this.notification_count = 0;
            //     this.isClassAdded = !this.isClassAdded;
            //     const emp_image =await axios.post(BASE_URL + '/api/updateusernotifiction',{id:this.ID});
            // },
            
            toggleClock() {
                if (this.clockHide) {
                    // Check In
                    this.checkInTime = new Date();
                    this.timer = setInterval(() => {
                    // Update currentTime every second
                    this.currentTime = this.formatTime(new Date() - this.checkInTime);
                    }, 1000);
                } else {
                    // Check Out
                    this.checkOutTime = new Date();
                    clearInterval(this.timer);
                    this.currentTime = "00:00:00"; // Reset timer display
                }

                this.clockHide = !this.clockHide;
                this.buttonBackground =
                    this.buttonBackground === "#3cb89b" ? "#e95b6d" : "#3cb89b";
                this.buttonText = this.clockHide ? "Check In" : "Check Out";
            },

            formatTime(ms) {
                // Format milliseconds to HH:mm:ss
                const seconds = Math.floor((ms / 1000) % 60);
                const minutes = Math.floor((ms / (1000 * 60)) % 60);
                const hours = Math.floor(ms / (1000 * 60 * 60));

                const formattedTime = `${this.padZero(hours)}:${this.padZero(
                    minutes
                )}:${this.padZero(seconds)}`;
                return formattedTime;
            },

            padZero(value) {
                // Add leading zero if needed
                return value < 10 ? `0${value}` : value;
            },

            closePopup() {
                this.showPopup = false;
            },

            detectDeviceType() {
                const userAgent = navigator.userAgent.toLowerCase();
                const isMobile = /mobile|android|iphone|ipad|tablet/.test(userAgent);
                const screenWidth = window.innerWidth;

                if (screenWidth < 1000) {
                    this.deviceType = 'mobile';
                } else {
                    this.deviceType = 'desktop';
                }

            },
            async added() {
                this.detectDeviceType();
                console.log('this.deviceType',this.deviceType);
                // this.timerClocktoggle = true;
                if (this.deviceType == 'mobile') {
                    this.tostMessageTop('danger','Unauthorized Clockin attempt detected from a mobile device. Your user ID has been recorded and the administrator has been notified');                    
                }
                this.$emit('check-in-out');
                this.clicked=!this.clicked;
                // this.clicked = true;
                var last_time = new Date().toLocaleString();
                var last_time_s = last_time.split(",");
                const latest_time = "11:00:00 AM";
                var latest_in = last_time_s[0].concat(", ",latest_time)

                var today1= new Date().toLocaleString();
                var today_date1 = today1.split(",");
                var start_date_added=new Date();
                var attendance_month_added =start_date_added.getMonth();
                //////////////////////

                ///////////////////////////

                if (this.text == "Check In") {
                if(confirm("Are you sure you want to check in?")){

                this.startingSeconds = "00"
                this.text = "Check Out";
                var start_time1=new Date().toLocaleString();
                var checkin_hrs = new Date().getHours();
                // console.log("checkin_hrs",checkin_hrs);
                // console.log("start_time1",start_time1);
                var checkin_date = start_time1.split(",");
                // console.log("checkin_date",checkin_date[1]);
                var checkin_ampm = checkin_date[1].split(" ")[2];
                // console.log("checkin_ampm",checkin_ampm);
                if (checkin_ampm =='am') {
                checkin_ampm = 'AM';
                } else if(checkin_ampm =='pm'){
                checkin_ampm = 'PM';
                
                }else if(checkin_ampm == undefined){
                // console.log("else if");
                checkin_ampm = checkin_hrs >= 12 ? 'PM' : 'AM';
                }else{
                // console.log("else");

                checkin_ampm = checkin_ampm
                }
                // console.log("checkin_ampm1",checkin_ampm);

                var checkin_time =  checkin_date[1].split(" ")[1].concat(" ",checkin_ampm);
                // console.log("checkin_time",checkin_time);

                var start_date=new Date();
                //  var curr_m = start_date.getMonth()+1;
                //  var curr_d = start_date.getDate();
                //  var curr_y = start_date.getFullYear();
                //  var curr_date = curr_m.concat("/",curr_d).concat("/",curr_y);
                
                var start_date1= new Date().toLocaleString();
                var start_date2 = start_date1.split(",");

                var attendance_year =start_date.getFullYear();
                var attendance_month =start_date.getMonth();
                // const ctime = start_time.split();
                //   console.log("dhdugdyydg",ctime[0]);
                //  var cin =this.cin.push(checkin_time);
                var cinms = start_date.getTime();
                var currdate = new Date();
                var currmonth = currdate.getMonth()+1;
                var currday = currdate.getDate();
                var curryear = currdate.getFullYear();
                var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear)
                // console.log("checkin1 time",checkin_date[0],checkin_date[1],cinms,attendance_month);
                // console.log(Date.parse(latest_in) > Date.parse(last_time));
                var late_status =Date.parse(latest_in) < Date.parse(last_time);
                // console.log("late",late_status);

                var current_date = new Date();
                var currday = String(current_date.getDate()).padStart(2, '0');
                var current_day = current_date.getDay();
                var currentmonth = current_date.getMonth()+1;
                var current_year = current_date.getFullYear();
                var concattedcurrdate = currentmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",current_year);
                // console.log("concatteddate",concattedcurrdate);
                await axios.post(BASE_URL + '/api/getemployeeleaverecord',{
                            'id': this.ID,
                            'month':currentmonth,
                            'year':current_year
                        })
                        .then((response)=>{
                            //console.log(response.data);

                            // this.leaverecord = response.data.empdetails;
                            this.holiday_list = response.data.holiday_list;
                            this.holiday_array = this.holiday_list.map(x => x.holiday_date);
                    })

                    this.holiday_array_formatted = new Array();

                for (let i = 0; i < this.holiday_array.length; i++) {
                var split_holiday = this.holiday_array[i].split("/");
                this.holiday_array_formatted.push(split_holiday[1].concat("/", split_holiday[0]).concat("/", split_holiday[2]));
                
                }
                // console.log("this.holiday_array_formatted",this.holiday_array_formatted);


                var isWorkingDay;
                if (current_day == 6 || current_day == 0) {
                // console.log("if working");
                isWorkingDay = 0;
                }else if(this.holiday_array_formatted.includes(concattedcurrdate)){
                // console.log("else if working");

                isWorkingDay = 0;
                }else{
                // console.log("else working");

                isWorkingDay = 1;
                }
                // console.log("isWorkingDay",isWorkingDay);
                this.dataVal = {"checkin_time":checkin_time,"checkin_date":concatteddate,"checkout_time":'',"checkout_date":'',"cinms":cinms,"coutms":coutms,"empid":this.ID,"empname":this.userName,"emplname":this.userlName,"empemail":this.userEmail,"checkin_type":this.text,"late_status":late_status,"attendance_year":attendance_year,
                "attendance_month":attendance_month,"today_date":concatteddate,"status":"in","isWorkingDay":isWorkingDay,"encryptedstring":this.encryptedstring,'device':this.deviceType};
                const res=await axios.post(BASE_URL + '/api/getattendance1',this.dataVal);
                if (res.data.status == 200) {
                    this.checkTimer = false;
                    var currdate = new Date();
                    var month = currdate.getMonth();
                    const idData = {"id":this.ID, "date":this.curentDateFormatted, "month":month};
                    const attendance_data= await axios.post(BASE_URL + '/api/get_attendance',idData);
                    this.current_CinCoutStatus = attendance_data.data.clockinstatuscheck;
                    this.userList = attendance_data.data.userList;
                    // const userList = ['user1', 'user2', 'user3'];
                    const userList = this.userList;
                    this.$emit('userListUpdated', userList);
                    this.checkTimer = true;
                }
                this.fetchkey();
                // this.abcd(this.ID,checkin_date[0]);
                }
                else{
                    this.checkTimer = true;

                }

                } else {
                if(confirm("Are you sure you want to check out?")){
                    
                    this.timer(this.text, false);
                    // this.timerLoad(this.text, false);
                    // this.timerLoad(this.text, false);
                    this.text = "Check In";
                    var checkin_hrs = new Date().getHours();
                    // console.log("checkin_hrs",checkin_hrs);
                    var end_time1=new Date().toLocaleString();
                    var checkout_date = end_time1.split(",");
                    // console.log("checkin_date",checkout_date[1]);
                    var checkout_ampm = checkout_date[1].split(" ")[2];
                    // console.log("checkout_ampm",checkout_ampm);
                    if (checkout_ampm =='am') {
                    checkout_ampm = 'AM';
                    } else if(checkout_ampm =='pm'){
                    checkout_ampm = 'PM';
                    
                    }else if(checkout_ampm == undefined){
                    // console.log("else if");
                    checkout_ampm = checkin_hrs >= 12 ? 'PM' : 'AM';
                    }else{
                    checkout_ampm = checkout_ampm;
                    }
                    // console.log("checkout_ampm",checkout_ampm);

                    var checkout_time =  checkout_date[1].split(" ")[1].concat(" ",checkout_ampm);
                    // console.log("checkout_time",checkout_time);
                    //  checkout_date = [];
                    var end_date=new Date();
                    //  var cinms = start_date.getTime();

                    var currdate = new Date();
                    var currmonth = currdate.getMonth()+1;
                    var currday = currdate.getDate();
                    var curryear = currdate.getFullYear();
                    var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear)
                    //  var cout =this.cout.push(checkout_time);
                    var coutms = end_date.getTime();

                    clearInterval(this.timerId);
                    // console.log("time2",this.timerId);
                    this.timerId = null;

                    this.dataVal = {"checkin_time":'',"checkin_date":'',"checkout_time":checkout_time,"checkout_date":concatteddate,"cinms":cinms,"coutms":coutms,"empid":this.ID,"empname":this.userName,"emplname":this.userlName,"empemail":this.userEmail,"checkin_type":this.text,"attendance_year":attendance_year,"attendance_month":attendance_month,"status":"out","encryptedstring":this.encryptedstring,'device':this.deviceType};
                    const res=await axios.post(BASE_URL + '/api/getattendance1',this.dataVal);
                    if (res.data.status == 200) {
                        this.checkTimer = false;
                        var currdate = new Date();
                        var month = currdate.getMonth();
                        const idData = {"id":this.ID,"date":this.curentDateFormatted,  "month":month};
                        const attendance_data= await axios.post(BASE_URL + '/api/get_attendance',idData);
                        this.current_CinCoutStatus = attendance_data.data.clockinstatuscheck;

                        this.userList = attendance_data.data.userList;
                        // const userList = ['user1', 'user2', 'user3'];
                        const userList = this.userList;
                        this.$emit('userListUpdated', userList);
                        this.checkTimer = true;

                    }
                    this.fetchkey();

                // this.abcd(this.ID,checkout_date[0]);
                }else{
                // location.reload();
                this.checkTimer = true;

                }
                }

                // this.abcd(this.ID,concatteddate,attendance_month_added);

            },

            // timer(type) {

            //     if (type=="Check Out") {
            //         // this.timerClocktoggle = true;
            //         console.log("timer status",this.updatestatus );
            //         console.log("ydctcxgrsessrty if",this.updateCoundtdownIntervalHandleTimer);

            //         // this.playing = true;
                    
            //         this.startingMinutes = this.initial_minutes;
                    
            //         let time = this.startingSeconds;
            //         console.log("uydtdtfytfyyftftfyftyft",time);
            //         if (this.updateCoundtdownIntervalHandleTimer !== null) {
            //             clearInterval(this.updateCoundtdownIntervalHandleTimer);
            //         }
            //         // if(this.timerClocktoggle == true){
            //             var statusClock = this.updatestatus;
            //             this.updateCoundtdownIntervalHandleTimer = setInterval(updateCoundtdownTimer(statusClock), 1000);
            //             console.log("this.updateCoundtdownIntervalHandleTimer",this.updateCoundtdownIntervalHandleTimer);
            //             time++;
            //             console.log("shfbjbfugfydgy");
            //             function updateCoundtdownTimer(statusClock) {
            //                 // Check if the timer should be running
            //                 console.log("timer status updateCoundtdownTimer",statusClock );

            //                 if (statusClock) {
            //                     // Calculate time
            //                     let hours = Math.floor(time / 3600);
            //                     // console.log("showtime5",hours);
            //                     var minutes = Math.floor(time / 60);
            //                     minutes = minutes % 60;

            //                     // console.log("showtime6",minutes);
            //                     let seconds = time % 60;

            //                     seconds = seconds < 10 ? '0' + seconds : seconds;
            //                     minutes = minutes < 10 ? '0' + minutes : minutes;
            //                     hours   = hours   < 10 ? '0' + hours   : hours;

            //                     this.currentMinutes = minutes;
            //                     this.currentSeconds = seconds;
            //                     this.currentHours   = hours;
            //                     // console.log("minutes,seconds",minutes,seconds);

            //                     document.querySelector("#minutes").innerHTML = minutes;
            //                     document.querySelector("#seconds").innerHTML = seconds;
            //                     document.querySelector("#hours").innerHTML   = hours;
            //                     time++;
            //                 }
            //             }

            //         // }
            //     } else {

            //         console.log("ydctcxgrsessrty else",this.updateCoundtdownIntervalHandleTimer);
            //         clearInterval(this.updateCoundtdownIntervalHandleTimer);
            //     }
            // },
            timer(type, status) {
                // Clear any existing interval
                clearInterval(this.updateCoundtdownIntervalHandleTimer);
                clearInterval(this.updateCoundtdownIntervalHandleTimerLoad);

                let time = this.startingSeconds;
                console.log("timer status",status);
                if (type === "Check Out" && status) {
                    // Start new interval only if the status is true
                    this.updateCoundtdownIntervalHandleTimer = setInterval(() => {
                        let hours = Math.floor(time / 3600);
                        let minutes = Math.floor(time / 60) % 60;
                        let seconds = time % 60;

                        seconds = seconds < 10 ? '0' + seconds : seconds;
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                        hours = hours < 10 ? '0' + hours : hours;

                        this.currentMinutes = minutes;
                        this.currentSeconds = seconds;
                        this.currentHours = hours;
                        console.log("timer  seconds", seconds);
                        document.querySelector("#minutes").innerHTML = minutes;
                        document.querySelector("#seconds").innerHTML = seconds;
                        document.querySelector("#hours").innerHTML = hours;

                        time++;
                    }, 1000);
                }
            },

            // Define a single timerLoad method
            timerLoad(type, status) {
                console.log("timerload status", status);
                this.updatestatusOnload = status;
                clearInterval(this.updateCoundtdownIntervalHandleTimerLoad);
                clearInterval(this.updateCoundtdownIntervalHandleTimer);

                let time = this.startingSeconds;

                if (type === "Check Out" && status) {
                    this.updateCoundtdownIntervalHandleTimerLoad = setInterval(() => {
                        if (this.updatestatusOnload) {
                            let hours = Math.floor(time / 3600);
                            let minutes = Math.floor(time / 60) % 60;
                            let seconds = time % 60;

                            seconds = seconds < 10 ? '0' + seconds : seconds;
                            minutes = minutes < 10 ? '0' + minutes : minutes;
                            hours = hours < 10 ? '0' + hours : hours;

                            this.currentMinutes = minutes;
                            this.currentSeconds = seconds;
                            this.currentHours = hours;
                            console.log("timer load seconds", seconds);

                            document.querySelector("#minutes").innerHTML = minutes;
                            document.querySelector("#seconds").innerHTML = seconds;
                            document.querySelector("#hours").innerHTML = hours;

                            time++;
                        }
                    }, 1000);
                }
            },

            async fetchkey(){
                await axios.post(BASE_URL + '/api/fetchapikey',{id:this.ID})          
                    .then((response)=>{
                        // console.log("responsekey",response.data.apikey.api_key);
                        var decryptedString =  atob(response.data.apikey.api_key)
                        // console.log("decryptedString",decryptedString);
                        let d = parseInt(decryptedString);
                        //  console.log("newstringtobeencrypted",decryptedString[0]);
                        var d1 = parseInt(decryptedString[0]) +  parseInt(decryptedString[5]);
                        var d2 = parseInt(decryptedString[1]) +  parseInt(decryptedString[6]);
                        var d3 = parseInt(decryptedString[2]) +  parseInt(decryptedString[7]);
                        var d4 = parseInt(decryptedString[3]) +  parseInt(decryptedString[8]);
                        var d5 = parseInt(decryptedString[4]) +  parseInt(decryptedString[9]);
                        this.newstringtobeencrypted = d1.toString() + d2.toString() + d3.toString() +d4.toString() + d5.toString();
                        // console.log("newstringtobeencrypted",this.newstringtobeencrypted);
                        this.encryptedstring = btoa(this.newstringtobeencrypted);
                    })
            },

            
            async getleavedata(getempdata){
                await axios.post(BASE_URL + '/api/getemployeeleaverecord',getempdata)
                    .then((response)=>{
                        //console.log(response.data);

                        this.leaverecord = response.data.empdetails;
                        //  console.log("leaverecord",this.Leave.getleavedetails);
                        this.leaverecord2 = response.data.empdetails;
                        this.holiday_list = response.data.holiday_list;
                        this.holiday_array = this.holiday_list.map(x => x.holiday_date);
                        this.wfhrecord = response.data.wfh_details;
                        this.datechangerecord = response.data.datechange_details;

                })
            },

            async getleaveremaining(getempdata){
                await axios.post(BASE_URL + '/api/getemployeeclml',getempdata)
                    .then((response)=>{
                        // console.log(response.data);

                        this.remaining_leave = response.data.remaining_leave;
                        this.remaining_leave_ml = response.data.remaining_leave_ml;
                        this.provisional_leave_count = response.data.provisional_leave_count;

                })
            },

            async abcd(uId,today,month){
                this.idData = {"id":uId, "date":today, "month":month};
                // console.log("header attendance");
                const attendance_data= await axios.post(BASE_URL + '/api/get_attendance',this.idData);
                this.userList = attendance_data.data.userList;
                this.userDetails = attendance_data.data.userDetails;
                this.monthly_attendance_count = attendance_data.data.monthly_attendance_count;
                // console.log("test1",this.monthly_attendance_count);
                // this.current_CinCoutStatus= (attendance_data.data.userDetails==null)?'out':attendance_data.data.userDetails.status;
                // console.log("test2",this.current_CinCoutStatus);

                var monthly_hours_with_seconds_split = attendance_data.data.total_hours_monthly.split(":");
                this.monthly_hours = monthly_hours_with_seconds_split[0].concat(":", monthly_hours_with_seconds_split[1])
                // this.monthly_hours = attendance_data.data.total_hours_monthly;
                this.position = attendance_data.data.user_designation.position;

            // console.log("ABCD: ",monthly_hours)
            },

            clock(status,startTime){
                // console.log("status statusstatusstatusstatusstatusstatus",status);
                // console.log("startTime startTimestartTimestartTimestartTime",startTime);
                this.playing = status; // true /false

                this.startingMinutes = this.initial_minutes;

                let time = startTime;
                // console.log("uydtdt",startTime);
                if (this.updateCoundtdownIntervalHandle !== null) {
                    // console.log("if clock",time);
                    clearInterval(this.updateCoundtdownIntervalHandle);
                }
                else{
                    // console.log("else clock",time);
                }
                // if (this.updateCoundtdownIntervalHandle!="00:00:00") {
                
                // }
                // console.log("time1",time);
                this.updateCoundtdownIntervalHandle = setInterval(updateCoundtdown, 1000);
                // time++;
                // console.log("time2",time);
                function updateCoundtdown() {
                    // if (this.playing) {
                    // console.log("showtime1",time);
                    let hours = Math.floor(time / 3600);
                    // console.log("hours",hours);
                    var minutes = Math.floor(time / 60);
                    minutes = minutes % 60;
                    // console.log("minutes",minutes);
                    let seconds = time % 60;
                    // console.log("seconds",seconds);


                    seconds = seconds < 10 ? '0' + seconds : seconds;
                    minutes = minutes < 10 ? '0' + minutes : minutes;
                    hours   = hours   < 10 ? '0' + hours   : hours;

                    this.currentMinutes = minutes;
                    this.currentSeconds = seconds;
                    this.currentHours   = hours;
                    // console.log("minutes,seconds",minutes,seconds);

                    document.querySelector("#minutes").innerHTML = minutes;
                    document.querySelector("#seconds").innerHTML = seconds;
                    document.querySelector("#hours").innerHTML   = hours;
                    time++;
                    // console.log("time1",minutes + ':' + seconds);
                
                }
            },

            async showMoreNotification(){
                const notificationdata = {
                            count: this.fetchednotificationcount,
                            id:this.ID
                        };

                const res=await axios.post(BASE_URL + '/api/getempnotification',notificationdata);
                this.notification = res.data.notificationdata;
                this.fetchednotificationcount = res.data.fetchednotificationcount;
                // console.log("count",this.fetchednotificationcount);
            },

            async viewAllNotification(){
                const allnotificationdata = {
                            id:this.ID
                        };

                const res=await axios.post(BASE_URL + '/api/getallempnotification',allnotificationdata);
                this.notification = res.data.notificationdata;
                this.fetchednotificationcount = res.data.fetchednotificationcount;
                // console.log("count",this.fetchednotificationcount);
            },

            async logout(){
                var today0= new Date().toLocaleString();
                var today_date0 = today0.split(",");
                // var today = today_date0[0];

                var currdate = new Date();
                var currmonth = currdate.getMonth()+1;
                var currday = currdate.getDate();
                var curryear = currdate.getFullYear();
                var concatteddate = currmonth.toLocaleString().concat("/",currday.toLocaleString()).concat("/",curryear);
                var today = concatteddate;
                // console.log(concatteddate);

                this.statusInputData = {"id":this.ID, "date":today};
                const getInOutStatus= await axios.post(BASE_URL + '/api/getInOutStatus',this.statusInputData);
                this.current_CinCoutStatus = (getInOutStatus.data.statusInOut==null)?'out':getInOutStatus.data.statusInOut.status;
                // console.log("idlogout",this.ID);

                // console.log("ininoutout",this.current_CinCoutStatus);
                    if (this.current_CinCoutStatus=="out") {
                    localStorage.clear();
                    this.$router.push('/');
                    }if (this.current_CinCoutStatus=="in") {
                    if(confirm("You are still checked in! Please check out beforhand")){
                    }
                    }
        
            },

            checkInOut() {
            // Emit an event to notify the parent component
            // console.log("emitt");
            this.$emit('check-in-out');
            },

            async generate(){
                this.v3$.$validate(this.v3$.$error);

                if (!this.v3$.$error) {
                    // console.log("boxes",this.boxes);
                    // console.log("newTransaction",this.newTransaction);
                    var concatted_date = this.newTransaction.date.split("-");
                    var month = concatted_date[1];
                    var year = concatted_date[0];
                    if (this.boxes.length != 0) {
                        await axios
                        .post(BASE_URL + "/api/addNewDealDetails", {
                            id: this.ID,
                            details: this.newTransaction,
                            category: this.boxes,
                            year: year,
                            month: month,
                            name: this.userfullname
                        })
                        .then((response) => {
                            this.toggleClass3();
                            this.boxes = [];
                            this.newTransaction.clientName = '';
                            this.newTransaction.amount = '';
                            this.newTransaction.paymentStatus = '';
                            this.newTransaction.date = '';
                            this.newTransaction.beneficiary = '';
                            this.newTransaction.payment_status = '';
                            this.newTransaction.payment_method = '';
                            this.newTransaction.cName = '';
                            this.newTransaction.cAddress = '';
                            this.newTransaction.contact_person_name = '';
                            this.newTransaction.contact_person_reg_no = '';
                            this.newTransaction.contact_person_email = '';
                            this.newTransaction.web_app_name = '';
                            this.newTransaction.campaign_start_date = '';
                            this.newTransaction.campaign_end_date = '';
                            this.newTransaction.campaign_placement = '';
                            this.newTransaction.campaign_budget = '';

                        });
                        var dealstatus = true;
                        this.$emit('dealAdded', dealstatus);
                        this.tostMessage("success", "Deal added successfully");

                    } else {
                        this.tostMessage("warning", "Category deatils can not be blank!");
                    }
                }
                else{
                    this.tostMessage("warning", "Please fill all the required fileds!");
                }
            },

            async submitReport(){
                var concatted_date = this.daily_report.date.split("-");
                // console.log("concatted_date", this.daily_report.date);
                var month = concatted_date[1];
                var year = concatted_date[0];
                if (this.daily_report.date == "") {
                    this.tostMessage("warning", "Please provide the date!");
                }else if(this.daily_report.total_mail_sent<0 || this.daily_report.total_positive_response<0 || this.daily_report.total_response<0 || this.daily_report.total_lead<0||this.daily_report.lead_closed<0){
                    this.tostMessage("warning", "Input value can not be negative!");
                } else {
                    try {
                    // Make the axios request
                    const response = await axios.post(
                        BASE_URL + "/api/submitdailyreport",
                        {
                        id: mirror.ID,
                        empname: mirror.USERNAME.concat(" ", mirror.USERLNAME),
                        total_mail_sent: this.daily_report.total_mail_sent,
                        total_positive_response:
                            this.daily_report.total_positive_response,
                        total_response: this.daily_report.total_response,
                        date: this.daily_report.date,
                        year: year,
                        month: month,
                        total_lead: this.daily_report.total_lead,
                        lead_closed: this.daily_report.lead_closed,
                        }
                    );
                    var dealstatus = true;
                    this.$emit('dealAdded', dealstatus);
                    this.tostMessage(response.data.success, response.data.message);
                    // Clear the form fields after successful submission
                    this.toggleClass4();
                    this.daily_report = {
                        total_mail_sent: "",
                        total_positive_response: "",
                        total_response: "",
                        date: "",
                        total_lead: "",
                        lead_closed: "",
                    };
                    //   this.toggleClass3();
                    } catch (error) {
                    console.error("Error submitting daily report:", error);
                    this.tostMessage(
                        "error",
                        "An error occurred while submitting the daily report."
                    );
                    } finally {
                    // Re-enable the button after submission (whether successful or failed)
                    //   this.submittingDailyReport = false;
                    }
                }
            },

            tostMessage(status,message){
                // console.log("status",status);
                    createToast({
                        // title: title,
                        description: message
                    },
                    {
                    type: status,
                    timeout: 2500,
                    position: "bottom-right",
                    showIcon: 'true'
                })
            },

            tostMessageTop(status,message){
                // console.log("status",status);
                    createToast({
                        // title: title,
                        description: message
                    },
                    {
                    type: status,
                    timeout: 10000,
                    position: 'top-center',
                    showIcon: 'true'
                })
            },

            

        },

        // beforeRouteEnter(to, from, next) {
        //     console.log(storage.get('ID'));
        //     if (storage.get('ID')) {
        //         next();
        //     }
        //     else {
        //         next('/');
        //     }
        // },
    }
</script>