export const BASE_URL = "https://employee.works-reddensoft.com/admin";
// export const BASE_URL = "https://works-reddensoft.com/lms/admin";

export const API_IMAGE_PATH =
  "https://employee.works-reddensoft.com/admin/public/Pdf/";
  // "https://works-reddensoft.com/lms/admin/public/Pdf/";

// export const BASE_URL = "https://employee.works-reddensoft.com/admin";

// export const API_IMAGE_PATH =
//   "https://employee.works-reddensoft.com/admin/public/Pdf/";



//export const BASE_URL = 'https://phpstack-721645-2691151.cloudwaysapps.com/admin';

//export const BASE_URL = 'http://127.0.0.1/lmsadmin';
