<template>
    <div>
      <button @click="requestPermission">Request permission </button>
      <button @click="createNotification">Create notification </button>
    </div>
  </template>
  
  <script>

  export default {
    data(){
      return {
        notification:'',
        visibility:''
      }
    },
    created(){
      //this.onloadfunction();
      this.createNotification();
    },
    methods: {
      requestPermission() {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            console.log('Permission granted');
          } else {
            console.log('Permission denied');
          }
        });
      },
      createNotification() {
        console.log("NOTIFICATION: ",Notification.permission);
        if (Notification.permission === 'granted') {
          this.notification = new Notification('Granted Notification', {
            body: 'This is an created notification!',
            icon: "http://executiveprotectionlifestyle.epspecialist.com/favicon.ico",
            image: "https://i.ytimg.com/vi/kRaKXh5qF9k/maxresdefault.jpg",
            lang: "",
            onclick: "http://executiveprotectionlifestyle.epspecialist.com/"
          });
          console.log("granted: ",this.notification);
          //this.onloadfunction();
        } 
        else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              this.notification = new Notification('Denied Notification', {
                body: 'This is an not denied notification!'
              });
              console.log("granted: ",this.notification);
            }
          });
        }
      },
      onloadfunction() {
        this.visibility = document.visibilityState;
        console.log("VISIBILITY: ",this.visibility);
        if(this.visibility === 'hidden'){
          this.notification = new Notification('Come back please', {
            body: 'This is an come back notification!'
          });
          console.log("hidden: ",this.notification);
        }
      }
    },
    watch: {
      visibility(){
        this.onloadfunction();
        // if(this.visibility === 'hidden'){
        //   this.notification = new Notification('Come back please', {
        //     body: 'This is an come back notification!',
        //     tag: 'come back please'
        //   });
        //   console.log("Watch: ",this.notification);
        // }
      }
      
    }
  }
  </script>