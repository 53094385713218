import { createApp } from 'vue'
import App from './App.vue'

import  VueHtmlToPaper from './plugins/VueHtmlToPaper'

import router from './routes'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store';
// import 'vue-multiselect/dist/vue-multiselect.min.css';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


createApp(App).use(router,VueAxios, axios,VueHtmlToPaper,Datepicker, store).mount('#app')