<template>
    <div class="vue-modal" v-show="opendatechange">
        <div class="vue-modal-inner">
            <div class="vue-modal-content">
                    <slot/>
                    <!-- <button class="modal-closs" type="button" @click="$emit('close')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                        </svg>
                    </button> -->
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props:{
        opendatechange:{
           type : Boolean,
           required: true
       }
   },
}
</script>
<style scoped>

</style>