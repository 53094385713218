<template>
    <div class="dashboard_box deal_dashboard Incentive_Report_right">
        <div class="dashboard_right">
            <div class="dashboard_body">
                <div class="main_box">
                    <div class="top">
                        <div class="top_box">
                            <div class="name">
                                <h2>Incentive Report</h2>
                            </div>
                            <!-- <div class="year_div">
                                <h3>Year:</h3>
                                <div class="dropdown">
                                    <button class="btn dropdown-toggle d-flex align-items-center" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span v-if="year_selected!=''">{{ year_selected }}</span>
                                        <span v-else>Sort By Year</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <div class="dropdownli">
                                            <li v-for="item in menuItems" :key="item.title" class="d-flex justify-content-between align-items-center" @click="handleItemClick(item.title)">
                                                {{ item.title }}
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="table_box">
                        <!-- <div class="heading">
                            <h2>Previous Quarter Report ({{quarter}})</h2>
                        </div>
                        <div class="fild-info">
                            <form action="#">
                                <div class="fr-gp">
                                <input type="search" v-model="searchText" placeholder="Search..." @input="onSearch">
                                </div>
                            </form>
                            <button class="dwnlod-btn"  @click="clickedleavestatusDownload()">
                                download
                                <img src="@/assets/images/icon/download.svg" alt="">
                            </button>
                        
                        </div> -->
                        <div class="head-bar">
                            <ul>
                            <li >
                                <h2>Previous Quarter Report ({{quarter}})</h2>
                            </li>
                            </ul>
                            <div class="fild-info">
                                <form action="#">
                                    <div class="fr-gp">
                                    <!-- <input type="search" placeholder="Search..."> -->
                                    <input type="text" v-model="searchQuery" placeholder="Search by name...">

                                     <button type="submit">
                                        <img src="../../assets/images/icon/search.svg" alt="">
                                    </button> 
                                    </div>
                                </form>

                            
                            </div>
                        </div>
                        <div class="table_div">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>Employe Name</th>
                                        <!-- <th>Previous Quarter</th> -->
                                        <th>Target Amount</th>
                                        <th>Target Achived</th>
                                        <th>Prev. Overdue</th>
                                        <th>Incentive Eligibility</th>
                                        <th>Set Incentive</th>
                                        <th>Team Target</th>
                                        <th>Team Target Achived</th>
                                        <th>Prev. Team Overdue</th>
                                        <th>Team Incentive Eligibility</th>
                                        <th>Set TL Incentive</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in resultQuery" :key="index">
                                        <td>
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            {{ row.name }}
                                        </td>
                                        <!-- <td>
                                            {{ row.quarter_summary }}
                                        </td> -->
                                        <td>
                                            <div class="target_div">
                                                <template v-for="(target_set_row,target_set_index) in row.target_set" :key="target_set_index">
                                                    <p>{{ target_set_index+' : '+target_set_row }}</p>
                                                </template>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="target_div">
                                                <!-- {{ row.target_achieved }} -->
                                                <template v-for="(target_achieved_row,target_achieved_index) in row.target_achieved" :key="target_achieved_index">
                                                    <p>{{ target_achieved_index+' : '+target_achieved_row }}</p>
                                                </template>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{{ row.overdue }}</span>
                                        </td>
                                        <td>
                                            <!-- <span v-if="!row.isEditing">{{ row.eligibleForIncentive }}</span> -->
                                            <!-- <select > -->
                                                <div v-if=" (row.total_target_set + row.overdue)*2/3 <= row.total_target_achieved " value="Yes">Yes[{{ row.total_target_achieved }}/{{(row.total_target_set + row.overdue) }}]</div>
                                                <div v-else value="No">No[{{ row.total_target_achieved }}/{{(row.total_target_set + row.overdue) }}]</div>
                                            <!-- </select> -->
                                        </td>
                                        <td>
                                            <div>
                                                <input v-for="(month, index) in calculateMonths(calculateQuarter())" 
                                                    :key="index" 
                                                    type="number" 
                                                    v-model="row['month_' + month]" 
                                                    :placeholder="getMonthName(month)">
                                            </div>
                                        </td>
                                        <td v-if="row.status == 'Team Leader'">
                                            <div class="target_div">
                                                <!-- {{ row.team_target_set }} -->
                                                <template v-for="(team_target_set_row,team_target_set_index) in row.team_target_set" :key="team_target_set_index">
                                                    <p>{{ team_target_set_index+' : '+team_target_set_row }}</p>
                                                </template>
                                            </div>
                                        </td>
                                        <td v-else>
                                            -
                                        </td>
                                        <td v-if="row.status == 'Team Leader'">
                                            <!-- <div class="tabletd_box">{{ row.team_target_achieved }}</div> -->
                                            <div class="target_div">
                                                <template v-for="(team_target_achieved_row,team_target_achieved_index) in row.team_target_achieved" :key="team_target_achieved_index">
                                                    <p>{{ team_target_achieved_index+' : '+team_target_achieved_row }}</p>
                                                </template>
                                            </div>
                                        </td>
                                        <td v-else>
                                            -
                                        </td>
                                        <td v-if="row.status == 'Team Leader'">
                                            <span>{{ row.teamoverdue }}</span>
                                        </td>
                                        <td v-else>
                                            -
                                        </td>
                                        <td v-if="row.status == 'Team Leader'">
                                            <!-- <span v-if="!row.isEditing">{{ row.eligibleForIncentive }}</span> -->
                                            <!-- <select > -->
                                                <div v-if=" (row.total_team_target_set  + row.teamoverdue)*2/3 <= row.total_team_target_achieved " value="Yes">Yes[{{ row.total_team_target_achieved }}/{{(row.total_team_target_set + row.teamoverdue) }}]</div>
                                                <div v-else value="No">No[{{ row.total_team_target_achieved }}/{{(row.total_team_target_set+ row.teamoverdue)}}]</div>
                                            <!-- </select> -->
                                        </td>
                                        <td v-else>
                                            -
                                        </td>
                                        <td v-if="row.status == 'Team Leader'">
                                            <div>
                                                <input v-for="(mon, index) in calculateMonths(calculateQuarter())" 
                                                    :key="index" 
                                                    type="number" 
                                                    v-model="row['mon_' + mon]" 
                                                    :placeholder="getMonthName(mon)">
                                            </div>
                                        </td>
                                        <td v-else>
                                            -
                                        </td>
                                        <td>
                                            <div class="table_flex d-flex">
                                                <!-- <button class="btn-lps" @click="toggleEdit(row)">
                                                    {{ row.isEditing ? 'Save' : 'Edit' }}
                                                </button> -->
                                                <button class="btn-lps" @click="setIncentives(row, row.id)">
                                                    Set
                                                </button>
                                                <!-- <router-link to="/assign-incentives-details" class="btn-lps">Details</router-link> -->
                                                <!-- <router-link :to="`/assign-incentives-details/${row.id}/${row.name}/${row.total_target_set <= (row.total_target_achieved - row.overdue) ? 'Yes' : 'No'}`" class="btn-lps">Details</router-link> -->
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import "@/assets/css/dashboard.css"
import { createLocal, createSession, createStorage } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import VueMultiselect from 'vue-multiselect'
const mirror = createLocal()
const storage = mirror._prx
import Swal from 'sweetalert2'
import { ref, computed } from 'vue'
import { reactive } from "vue"
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    name: 'DealReport',
    setup() {
        const state3 = reactive({
            incentive:'',   
        })

        return {
            state3,
        }
      },
    data() {
        return {
            tableData: [
                {
                    employeName: 'Kuntal Patra',
                    targetAmount: "$8000",
                    targetAchived: '$9000',
                    incentiveAmount: "100",
                    eligibleForIncentive: "yes",
                },
                {
                    employeName: 'Kuntal Patra',
                    targetAmount: "$8000",
                    targetAchived: '$9000',
                    incentiveAmount: "100",
                    eligibleForIncentive: "yes",
                },
                {
                    employeName: 'Kuntal Patra',
                    targetAmount: "$8000",
                    targetAchived: '$9000',
                    incentiveAmount: "100",
                    eligibleForIncentive: "yes",
                },
                {
                    employeName: 'Kuntal Patra',
                    targetAmount: "$8000",
                    targetAchived: '$9000',
                    incentiveAmount: "100",
                    eligibleForIncentive: "yes",
                },
                {
                    employeName: 'Kuntal Patra',
                    targetAmount: "$8000",
                    targetAchived: '$9000',
                    incentiveAmount: "100",
                    eligibleForIncentive: "yes",
                },
                {
                    employeName: 'Kuntal Patra',
                    targetAmount: "$8000",
                    targetAchived: '$9000',
                    incentiveAmount: "100",
                    eligibleForIncentive: "yes",
                },
                {
                    employeName: 'Kuntal Patra',
                    targetAmount: "$8000",
                    targetAchived: '$9000',
                    incentiveAmount: "100",
                    eligibleForIncentive: "yes",
                },
            ],

            year_selected: new Date().getFullYear(),
            from_selected:'',
            to_selected:'',

            // menuItems: [
            //     {
            //         title: '2020',
            //     },
            //     {
            //         title: '2021',
            //     },
            //     {
            //         title: '2022',
            //     },
            //     {
            //         title: '2023',
            //     },
            //     {
            //         title: '2024',
            //     },

            // ],
            frommenuItems: [
                {
                    title: 'January',
                },
                {
                    title: 'February',
                },
                {
                    title: 'March',
                },
                {
                    title: 'April',
                },
                {
                    title: 'May',
                },
                {
                    title: 'June',
                },
                {
                    title: 'July',
                },
                {
                    title: 'August',
                },
                {
                    title: 'September',
                },
                {
                    title: 'October',
                },
                {
                    title: 'November',
                },
                {
                    title: 'December',
                }
            ],
            tomenuItems: [
            {
                    title: 'January',
                },
                {
                    title: 'February',
                },
                {
                    title: 'March',
                },
                {
                    title: 'April',
                },
                {
                    title: 'May',
                },
                {
                    title: 'June',
                },
                {
                    title: 'July',
                },
                {
                    title: 'August',
                },
                {
                    title: 'September',
                },
                {
                    title: 'October',
                },
                {
                    title: 'November',
                },
                {
                    title: 'December',
                }
            ],
            menuItems: [],
            userdata:'',
            quarter:'',
            searchQuery: null,

        }
    },
    async created(event){
         this.fetchDataforIncentive();
         this.generateMenuItems();
         
    },
    methods: {
        generateMenuItems() {
            const currentYear = new Date().getFullYear();
            for (let i = 0; i < 5; i++) {
            this.menuItems.push({
                title: (currentYear - i).toString()
            });
            }
        },
        toggleEdit(row) {
            row.isEditing = !row.isEditing;
        },
        handleItemClick(value) {
            this.year_selected = value;
        },
        handleItemClick1(value) {
            this.from_selected = value;
        },
        handleItemClick2(value) {
            this.to_selected = value;
        },
        async fetchDataforIncentive(){
            await axios.post(BASE_URL + '/api/fetchEMUsersIncentive',{'year':this.year_selected })
            .then((response)=>{
                this.userdata = response.data.lastQuarterTargetAchieved;
                console.log("bfjgugur",this.userdata);
                this.populateInputFields();
                this.quarter = response.data.firstLastQuarterSummary
            });
        },
        calculateMonths(quarter) {
            const currentMonth = new Date().getMonth() + 1; // Get current month (1-12)
            const startMonth = (quarter - 1) * 3 + 1; // Calculate the starting month of the quarter
            const months = [];

            for (let i = 0; i < 3; i++) {
                const month = startMonth + i;
                // if (month <= currentMonth) {
                    months.push(month);
                // }
            }
            console.log("months",months);
            return months;
        },

        calculateQuarter() {
            const currentMonth = new Date().getMonth() + 1; // Get current month (1-12)
            // console.log("Math.ceil(currentMonth / 3)",Math.ceil(currentMonth / 3));
            return Math.ceil(currentMonth / 3); // Calculate the quarter
        },

        getMonthName(month) {
            // const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return months[month - 1]; // Adjust month index to start from 0
        },

        async setIncentives(row, id){
            const incentiveData = {
                Jan: row.month_1 || 0,
                Feb: row.month_2 || 0,
                Mar: row.month_3 || 0,
                Apr: row.month_4 || 0,
                May: row.month_5 || 0,
                Jun: row.month_6 || 0,
                Jul: row.month_7 || 0,
                Aug: row.month_8 || 0,
                Sep: row.month_9 || 0,
                Oct: row.month_10 || 0,
                Nov: row.month_11 || 0,
                Dec: row.month_12 || 0
            };

            const teamIncentiveData = {
                Jan: row.mon_1 || 0,
                Feb: row.mon_2 || 0,
                Mar: row.mon_3 || 0,
                Apr: row.mon_4 || 0,
                May: row.mon_5 || 0,
                Jun: row.mon_6 || 0,
                Jul: row.mon_7 || 0,
                Aug: row.mon_8 || 0,
                Sep: row.mon_9 || 0,
                Oct: row.mon_10 || 0,
                Nov: row.mon_11 || 0,
                Dec: row.mon_12 || 0
            };

            // Example: Sending data to the backend using Axios
            axios.post(BASE_URL + '/api/setEMIncentives', {'incentiveData': incentiveData, 'teamIncentiveData': teamIncentiveData, 'id': id})
                .then(response => {
                    // Handle success
                    console.log('Incentives set successfully:', response.data);
                    if(response.data.success == 'success'){
                        this.tostMessage("success", "Incentives set successfully");
                    }
                })
        },

        populateInputFields() {
            this.userdata.forEach(row => {
                const incentive = row.incentive; // Access the incentive object
                // console.log('Incentive:', incentive); // Log incentive object
                if (incentive !== null) {
                    for (let month = 1; month <= 12; month++) {
                        console.log('Month:', month); // Log month
                        // Check if the month exists in the incentive object
                        if (incentive.hasOwnProperty(this.getMonthName(month))) {
                            row['month_' + month] = incentive[this.getMonthName(month)];
                            // console.log('Assigned value:', row['month_' + month]); // Log assigned value
                        }
                    }
                }
                const teamincentive = row.teamincentive; // Access the incentive object
                // console.log('Incentive:', teamincentive); // Log incentive object
                if (teamincentive !== null) {
                    for (let month = 1; month <= 12; month++) {
                        console.log('Month:', month); // Log month
                        // Check if the month exists in the incentive object
                        if (teamincentive.hasOwnProperty(this.getMonthName(month))) {
                            row['mon_' + month] = teamincentive[this.getMonthName(month)];
                            // console.log('Assigned value:', row['mon_' + month]); // Log assigned value
                        }
                    }
                }

            });
        },

        tostMessage(status,message){
                // console.log("status",status);
                    createToast({
                        // title: title,
                        description: message
                    },
                    {
                    type: status,
                    timeout: 2500,
                    position: 'bottom-right',
                    showIcon: 'true'
                })
        },

    },

    computed: {
          resultQuery(){
          if(this.searchQuery){
          return this.userdata.filter((item)=>{
          
          return this.searchQuery.toLowerCase().split(' ').every( w => item.name.toLowerCase().includes(w));
          //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
          })
          }else{
          //  console.log("details");
          return this.userdata;
          }
          },
    },
}

</script>