<template>
<div class="main-right">
        <div class="main-title">
          <h2 v-if="currentMonthShow== ''"> {{emp_name}} Leave Record for {{new Date().getFullYear()}}</h2>
          <h2 v-if="currentMonthShow!= ''"> {{emp_name}} Leave Record for {{currentYearShow}}</h2>
        </div>

        <div class="head-bar">
          <ul>
            <li v-if="currentMonthShow== ''">
              Leave Record for <span>{{this.month_name_array[currentDate()]}} {{new Date().getFullYear()}} </span>
            </li>
            <li v-if="currentMonthShow!= ''">
              Leave Record for <span>{{currentMonthShow}} {{currentYearShow}} </span>
            </li>
          </ul>
          <div class="fild-info">
            <button class="dwnlod-btn" @click="clickedDownloadRecord()">
              download
              <img src="@/assets/images/icon/download.svg" alt="">
            </button>
          </div>
        </div>

        <div class="divider-wap" id="divToPrintLeaveRecord">
          <div class="left-set">
            <div class="card-attendance">
              <div class="item">
                <p class="ttx">Casual Leave Balance : {{remaining_casual_leave}}</p>
              </div>
              <div class="item">
                <p class="ttx" v-if="currentMonthShow== ''">{{this.month_name_array[currentDate()]}} Casual Leave : {{casualleavepermonth < 1 ? '0' : casualleavepermonth}} {{casualleavepermonth > 1 ? 'days' : 'day'}}</p>
                <p class="ttx" v-if="currentMonthShow!= ''">{{currentMonthShow}} Casual Leave : {{casualleavepermonth < 1 ? '0' : casualleavepermonth}} {{casualleavepermonth > 1 ? 'days' : 'day'}}</p>
              </div>
              <div class="item">
                <p class="ttx">Medical Leave Balance : {{remaining_sick_leave}}</p>
              </div>
              <div class="item">
                <p class="ttx" v-if="currentMonthShow== ''">{{this.month_name_array[currentDate()]}} Medical Leave : {{medicalleaverpermonth < 1 ? '0' : medicalleaverpermonth}} {{medicalleaverpermonth > 1 ? 'days' : 'day'}}</p>
                <p class="ttx" v-if="currentMonthShow!= ''">{{currentMonthShow}} Medical Leave : {{medicalleaverpermonth < 1 ? '0' : medicalleaverpermonth}} {{medicalleaverpermonth > 1 ? 'days' : 'day'}}</p>
              </div>
             
            </div>
            <div class="table-section">
              <div class="table-wapper">
                
                <div class="table-data table-w-100 p-0">

                  <table cellspacing="0" cellpadding="0" border="0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Leave Type</th>
                        <th>Status</th> 
                      </tr>
                    </thead>
                    <tbody v-show="message !== 0" v-for="(row) in message" :key="row">
                      <tr>
                        <td>{{row.alldateleaves}}</td>
                        <td>{{row.leavetype}}</td>
                        <td v-if="row.leavestatusdetail == 'Pending'"> <span class="status pendin"></span> {{row.leavestatusdetail}}</td>
                        <td v-if="row.leavestatusdetail == 'Rejected'"> <span class="status pendin"></span> {{row.leavestatusdetail}}</td>
                        <td v-if="row.leavestatusdetail == 'Approved'"> <span class="status aprvd"></span> {{row.leavestatusdetail}}</td>
                      </tr>
                    </tbody>
                    <!-- <tbody v-show="message == 0"><tr>No Data Available</tr></tbody> -->
                  </table>
                  <div class="no_data_div" v-show="message == 0">
                    <p>No Data Available</p>
                  </div>
                </div>
    
              </div>
            </div>
          </div>
          
          <div class="calendar-wrapper">
              <div id="divCal"></div>
              <div class="cutom-table-tab">
                  <section class="cal-head">
                      <button @click="prev"><img src="@/assets/images/icon/chevron-white-left.svg" alt=""></button>
                      <h2>{{currentMonthName}} {{currentYear}}</h2>
                      <button @click="next"> <img src="@/assets/images/icon/chevron-white-right.svg" alt=""></button>{{weekend}}
                  </section>
                  <section>
                      <div class="cutom-table-days">
                          <p class="text-center" v-for="(day,index) in days" :key="index">{{day}}</p>
                      </div>
                  </section>
                  <section>
                      <div class="cutom-table-dates">
                        
                          <p class="text-center" v-for="day in firstDay" :key="day">&nbsp;</p>

                          <p v-show="this.approvedleavedata==''" :class="(todayDate(date)) ? 'text-primary':''"   class="text-center" v-for="date in lastDateOfMonth" :key="date" >
                                    {{date}}
                                </p>

                          <p v-show="this.approvedleavedata!=''" :class="(leaveDate(date)) ? 'text-primary':''"   class="text-center" v-for="date in lastDateOfMonth" :key="date" >
                              {{date}}
                          </p>
                          
                          
                      </div>
                  </section>
              </div>
          </div>
        </div>

    </div>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';

const mirror = createLocal();
const storage = mirror._prx;

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


import Swal from 'sweetalert2';


export default {
name: 'AdminEmployeeLeaveRecord',
components:{
    Pagination
},
setup() {
  //  const isOpen = ref(false)

  //  return { isOpen };

},
data() {
    return {
        storageData: mirror,
        storage: storage,
        emp_id : null,
        emp_name : null,
        remaining_casual_leave : null,
        remaining_sick_leave : null,
        casualleavepermonth : '',
        medicalleaverpermonth : '',
        message : {},
        days:["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
        currentMonthInNumber: new Date().getMonth(),
        
        
        currentYear: new Date().getFullYear(),
        approvedleavedata: '',
        month_name_array:'',
        getval : [],
        dateformatted : "",
        currentMonthInNumberdetail : "",
        currentMonthShow : "",
        currentYearShow : ""
        
    }
},
  computed:{
      currentMonthName(){
          return new Date(this.currentYear,this.currentMonthInNumber).toLocaleString("default",{month:"long"})
      },
      lastDateOfMonth(){
          return new Date(this.currentYear,this.currentMonthInNumber+1,0).getDate();
      },
      firstDay(){
          return new Date(this.currentYear,this.currentMonthInNumber,1).getDay()
      },
  },
methods :{

        async clickedDownloadRecord(){
            var pdf = new jsPDF("1", "mm", "a1");
            var element = document.getElementById('divToPrintLeaveRecord');
            var width= element.style.width;
            var height = element.style.height;
                html2canvas(element).then(canvas => {
                    //console.log(canvas);
                    var image = canvas.toDataURL('image/png');
                    pdf.addImage(image, 'JPEG', 15, 40, width, height);
                    pdf.save('LeaveRecordList' + '.pdf')
            });
        },
        prev(){
           //console.log(this.currentMonthInNumber);
           if(this.currentMonthInNumber === 0){
               this.currentYear--;
               this.currentMonthInNumber = 11;
               //console.log(this.currentMonthInNumber);
               this.currentMonthInNumberdetail = this.currentMonthInNumber;
              this.getempleaverecordpermonth();
              this.getResults();
              this.currentMonthShow = this.currentMonthName;
              this.currentYearShow = this.currentYear;
           }else{
               this.currentMonthInNumber--;
               //console.log(this.currentMonthInNumber);
                this.currentMonthInNumberdetail = this.currentMonthInNumber;
                this.getempleaverecordpermonth();
                this.getResults();
                this.currentMonthShow = this.currentMonthName;
                this.currentYearShow = this.currentYear;
           }
       },
       next(){
           //console.log(this.currentMonthInNumber);
           if(this.currentMonthInNumber === 11){
               this.currentYear++;
               this.currentMonthInNumber = 0;
              //  console.log(this.currentMonthInNumber);
               this.currentMonthInNumberdetail = this.currentMonthInNumber;
               this.getempleaverecordpermonth();
               this.getResults();
               this.currentMonthShow = this.currentMonthName;
               this.currentYearShow = this.currentYear;
           }else{
               this.currentMonthInNumber++;
              //  console.log(this.currentMonthInNumber);
               this.currentMonthInNumberdetail = this.currentMonthInNumber;
               this.getempleaverecordpermonth();
               this.getResults();
               this.currentMonthShow = this.currentMonthName;
               this.currentYearShow = this.currentYear;
           }
           
       },
      leaveDate(date){
            //console.log(date);
           //console.log(this.currentYear);
           //console.log(this.currentMonthInNumber);

           let calendardate = new Date(this.currentYear,this.currentMonthInNumber,date).toDateString();
           //console.log(calendardate);

           //console.log(this.approvedleavedata);

            let today = new Date();

            function isWeekend(date = new Date()) {
                return date.getDay() === 6 || date.getDay() === 0;
            }

            const d1 = new Date(calendardate);

           if(this.approvedleavedata.includes(calendardate) || isWeekend(d1)){
              return true;
           } else {
               return false;
           }

       },
       todayDate(date){

           let calenderdate = new Date(this.currentYear,this.currentMonthInNumber,date).toDateString();

           //console.log(calenderdate);

           let today = new Date();

            function isWeekend(date = new Date()) {
                return date.getDay() === 6 || date.getDay() === 0;
            }

            const d1 = new Date(calenderdate);

            if(isWeekend(d1)){
              return true;
            } else {
               return false;
           }

       },

    currentDate() {
      const current = new Date();

      var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];

      var month_number = `${current.getMonth()}`;

      //var date = monthNames[parseInt(month_number)+1];
      return month_number;
    },

    async getempleaverecordpermonth(){

            console.log("testtttetsttst",this.currentMonthInNumberdetail);


            const data ={
                userId: this.emp_id,
                curmonth : this.currentMonthInNumberdetail,
                // curmonth : (this.currentMonthInNumberdetail !='')?(this.currentMonthInNumberdetail):(''),
                curyear : this.currentYear

            }
            //console.log(data);

            await axios.post(BASE_URL + '/api/getempleaverecordpermonthview',data)
                    .then((response)=>{
                    //console.log(response.data);

                        if(response.data.success == true){

                            //this.approvedleavedata = response.data.approvedleave.map(x => x.leave_date);

                            this.approvedleavedata = response.data.approvedleave;
                            
                            this.casualleavepermonth = response.data.casual;
                            this.medicalleaverpermonth = response.data.medical;
                        }

                })
               console.log(this.casualleavepermonth);

    },

    getResults() {
        const data ={
                userId: this.emp_id,
                curmonth : this.currentMonthInNumberdetail,
                // curmonth : this.currentMonthInNumberdetail == ''?'':this.currentMonthInNumberdetail,
                curyear : this.currentYear
            }
        //console.log(data);
        
        axios.post(BASE_URL + '/api/getallLeaveDataindivisual',data)
            .then((response)=>{
            //console.log(response.data);

            this.message = response.data;

        })

    },

},
mounted(){
    this.getempleaverecordpermonth();
    this.getResults();
    this.leaveDate();
},
async created(){
   this.userName = mirror.USERNAME
   this.userEmail = mirror.USEREMAIL;

   this.ID = mirror.AUTHID;

   this.emp_id = this.$route.params.id;
   this.emp_name = this.$route.params.nid;
   this.remaining_casual_leave = this.$route.params.cid;
   this.remaining_sick_leave = this.$route.params.sid;
  //  console.log("this.remaining_casual_leave",this.remaining_casual_leave);
   this.month_name_array = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  //this.week_array = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

},
}
</script>

<style scoped>
        .text-primary {
            color: #ff0000 !important;
            font-weight: 700;
        }
        .text-weekend {
            color: #0000ff;
            font-weight: 700;
        }
</style>