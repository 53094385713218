<template>
  <div class="main-right">
  <div class="main-title">
    <h2>Leave Status for {{new Date().getFullYear()}}</h2>
  </div>

 
  <div class="head-bar">
    <ul>
      <li>
        Number of Leaves Pending: <span> {{pending_leave}} </span>
      </li>
    </ul>
    <div class="fild-info">
      <form action="#">
        <div class="fr-gp">
          <!-- <input type="search" placeholder="Search..."> -->
          <input type="search" v-model="searchText" placeholder="Search..." @input="onSearch">
        </div>
      </form>
      <button class="dwnlod-btn"  @click="clickedleavestatusDownload()">
        <!-- <img src="@/assets/images/icon/search.svg" alt=""> -->
        download
        <img src="@/assets/images/icon/download.svg" alt="">
      </button>
      
    </div>
  </div>

  <div v-if="errormessage1 != ''" class="errormessage">
      {{ errormessage1 }}
  </div>
  <div v-if="successmessage1 != ''" class="successmessage">
      {{ successmessage1 }}
  </div>
  
  <div class="table-section" id="leavestatusdownload1">
    <div class="table-wapper">
      
      <div class="table-data table-w-100">
        
        <table cellspacing="0" cellpadding="0" border="0">
          <thead>
            <tr>
              <th>Employee Name </th>
              <th>Employee ID</th>
              <th>Leaves to be Carried Forward</th>
              <th>Leaves to be Cashed In</th>
              <th>Applied On</th>
              <th>Year</th>
              <th>Status</th>
              <th>Approved By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody  v-for="(row) in carryforwarddata" :key="row">
            <tr>
              <td v-show="row.empid!=''">{{row.name}}</td>
              <td>{{row.empid}}</td>
              <td>{{row.carryforwardleave}}</td>
              <td>{{row.leavetobeapproved}}</td>
              <td>{{row.created_at}}</td>
              <td>{{row.year}}</td>
              <td v-if="row.status == 'Pending'"> <span class="status pendin"></span> {{row.status}}</td>
              <td v-if="row.status == 'Rejected'"> <span class="status rejected"></span> {{row.status}}</td>
              <td v-if="row.status == 'Approved'"> <span class="status aprvd"></span> {{row.status}}</td>
              <td>{{ row.admin_mail }}</td>
              <td>
                  <div class="btn-lps">
                  <a href="#" v-if="row.status =='Rejected' || row.status =='Pending'" @click="carryforwardapprove(row.id,row.empid,row.carryforwardleave,row.leavetobeapproved)" class="gren">Approve</a>
                  <a href="#" v-if="row.status =='Approved' || row.status =='Pending'" @click="carryforwardreject(row.id,row.empid,row.carryforwardleave,row.leavetobeapproved)" class="rept">Reject</a>
                  </div>
              </td>
            </tr>
          </tbody>
          <!-- <tbody v-show="carryforwarddata == 0"><tr>No Data Available</tr></tbody> -->
        </table>
        <div class="no_data_div" v-show="carryforwarddata == 0">
          <p>No Data Available</p>
        </div>
      </div>


    </div>
</div>
</div>

</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';

const mirror = createLocal();
const storage = mirror._prx;

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


import Swal from 'sweetalert2';

import ModalViewLeaveStatus from '../AdminDashboard/ModalViewLeaveStatus.vue'


export default {
name: 'LeaveCarryForwardStatus',
components:{
  ModalViewLeaveStatus,
  Pagination
},
setup() {
//  const isOpen = ref(false)

//  return { isOpen };

},
data() {
  return {
      page: 1,
      storageData: mirror,
      storage: storage,
      is_show: false,
      message:  {},
      searchText:"",
      nofound:false,
      errormessage: "",
      totalData:'',
      searchData:'',
      dateSearch:'',
      flag:'',
      errormessage1:"",
      leaveidapprove: '',
      leavestatusapprove: '',
      pending_leave : '',
      seen : false,
      empname : "",
      leavedate : "",
      leavetype : "",
      days : "",
      reason : "",
      docname : "",
      docurl : "",
      leavestatus : "",
      notifiedType:'',
      notifiedId:'',
      notifiedDate:'',
      admin_status:'',
      carryforwarddata:''
  }
},
async created(){
this.userName = mirror.USERNAME
this.userEmail = mirror.USEREMAIL;
this.admin_status = mirror.ADMIN_STATUS;
//   console.log("admin_status",this.admin_status);
this.ID = mirror.AUTHID;

  await axios.get(BASE_URL + `/api/getLeaveCarryForwardDetails`)
          .then((response)=>{
          //console.log(response.data);
          this.carryforwarddata = response.data.getcarryforwarddetails;
  })


},
methods :{

  async clickedleavestatusDownload(){
    //console.log('leavestatus');
      var pdf = new jsPDF("1", "mm", "a1");
      var element = document.getElementById('leavestatusdownload1');
      var width= element.style.width;
      var height = element.style.height;
          html2canvas(element).then(canvas => {
              //console.log(canvas);
              var image = canvas.toDataURL('image/png');
              pdf.addImage(image, 'JPEG', 15, 40, width, height);
              pdf.save('LeaveStatus' + '.pdf')
      });
  },
 async isOpen(event){
   //console.log(event);
   this.seen = true;

   const getid = {
      id : event
   }; 
   //console.log(getid);


      await axios.post(BASE_URL + '/api/getmodalleavestatus',getid)
              .then((response)=>{
                  //console.log(response.data.record.empname);

                  this.empname = response.data.empname;
                  this.leavedate = response.data.leavedate;
                  this.leavetype = response.data.leavetype;
                  this.days = response.data.days;
                  this.reason = response.data.reason;
                  this.docname = response.data.docname;
                  this.docurl = response.data.docurl;
                  this.leavestatus = response.data.leavestatus;

          })

 },
  onClick() {
    //console.log(this.docurl);

      if(this.docurl === ''){
              Swal.fire({
                          title: 'OPPS',
                          text:  'No Data Available',
                          icon: 'warning',
                      
                      });
        }else{

              axios({
                      url: this.docurl,
                      method: 'GET',
                      responseType: 'blob',
                  }).then((response) => {
                      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                      var fileLink = document.createElement('a');
  
                      fileLink.href = fileURL;

                      if(response.data.type == 'image/jpeg'){
                          fileLink.setAttribute('download', 'file.jpeg');
                      }else if(response.data.type == 'image/jpg'){
                          fileLink.setAttribute('download', 'file.jpg');
                      }else if(response.data.type == 'image/png'){
                          fileLink.setAttribute('download', 'file.png');
                      }else if(response.data.type == 'application/pdf'){
                          fileLink.setAttribute('download', 'file.pdf');
                      }else if(response.data.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                          fileLink.setAttribute('download', 'file.docx');
                      }else{
                          fileLink.setAttribute('download', 'file.txt');
                      }

                      document.body.appendChild(fileLink);
  
                      fileLink.click();
                  });

        }

  },
  closemodal(event){
    this.seen = false;
  },
 onSearch(){
     this.getResults(this.page,this.searchText);
 },

  async carryforwardapprove(id,empid,carryforward,cashin){

    if(confirm("Are you sure you want to approve this?")){

                  //console.log(getempid);

                  await axios.post(BASE_URL + '/api/approvecarryforward',{id:id, empid:empid, carryforward:carryforward, cashin:cashin, email:this.userEmail})
                          .then((response)=>{
                              //console.log(response.data);

                              if(response.data.success == true){
                                  //console.log(response.data);

                                  Swal.fire({
                                              title: 'Done',
                                              text:  response.data.message,
                                              icon: 'success',
                                          
                                          });

                                          axios.get(BASE_URL + `/api/getLeaveCarryForwardDetails`)
                                                  .then((response)=>{
                                                  //console.log(response.data);
                                                  this.carryforwarddata = response.data.getcarryforwarddetails;
                                          })
                                    

                              }else{

                                  Swal.fire({
                                          title: 'OPPS',
                                          text:   response.data.message,
                                          icon: 'warning',
                                      
                                      });                                    
                              }

                      })
    }else{
      return false;
    }

  },
  async carryforwardreject(id,empid,carryforward,cashin){

    if(confirm("Are you sure you want to reject this?")){
            //console.log(getempid);
                await axios.post(BASE_URL + '/api/rejectcarryforward',{id:id, empid:empid, email:this.userEmail})
                    .then((response)=>{
                        //console.log(response.data);

                        if(response.data.success == true){
                                  //console.log(response.data);

                                  Swal.fire({
                                              title: 'Done',
                                              text:  response.data.message,
                                              icon: 'success',
                                          
                                          });

                                          axios.get(BASE_URL + `/api/getLeaveCarryForwardDetails`)
                                                  .then((response)=>{
                                                  //console.log(response.data);
                                                  this.carryforwarddata = response.data.getcarryforwarddetails;
                                          })
                                    

                              }else{

                                  Swal.fire({
                                          title: 'OPPS',
                                          text:   response.data.message,
                                          icon: 'warning',
                                      
                                      });                                    
                              }

                })

    }else{
      return false;
    }
      
  },
  async gettotalpendingleave(){

      await axios.post(BASE_URL + '/api/gettotalpendingleave')
                .then((response)=>{
                    //console.log(response.data.pending_leave.count);

                    if(response.data.success == true){
                        this.pending_leave = response.data.pending_leave.count;

                    }

            })

  }

},
mounted(){
},

watch: {
   '$route.query'() { 
    //console.log('querydata',newId)
    // this.url_res = this.$route.params.res;
    // this.getdata.url_res = this.url_res;
    var hash = window.location.hash.split(",");
    this.notifiedId = hash[0].split("#")[1];
    this.notifiedDate = hash[1];
    console.log("hash14",hash);
    // this.getsearchresult(this.getdata);
    // setInterval(function () {window.location.hash =''}, 5000);
  }
}
}
</script>

<style scoped>

</style>