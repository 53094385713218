<template>
  <div class="main-right">
  <div class="main-title">
    <h2>Date Change Application Status for {{new Date().getFullYear()}}</h2>
  </div>

 
  <div class="head-bar">
    <div class="fild-info">
      <form action="#">
        <div class="fr-gp">
          <!-- <input type="search" placeholder="Search..."> -->
          <input type="search" v-model="searchText" placeholder="Search..." @input="onSearch">
        </div>
      </form>
      <button class="dwnlod-btn"  @click="clickedleavestatusDownload()">
        <!-- <img src="@/assets/images/icon/search.svg" alt=""> -->
        download
        <img src="@/assets/images/icon/download.svg" alt="">
      </button>
      
    </div>
  </div>

  <div v-if="errormessage1 != ''" class="errormessage">
      {{ errormessage1 }}
  </div>
  <div v-if="successmessage1 != ''" class="successmessage">
      {{ successmessage1 }}
  </div>
  
  <div class="table-section" id="leavestatusdownload1">
    <div class="table-wapper">
      
      <div class="table-data table-w-100">
        
        <table cellspacing="0" cellpadding="0" border="0">
          <thead>
            <tr>
              <th>Employee Name </th>
              <th>Employee ID</th>
              <th>Working Date To Be Changed</th>
              <th>Requested Date To Work On</th>
              <th>Applied On</th>
              <!-- <th>Position</th> -->
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-show="message.data !== 0" v-for="(row) in message.data" :key="row" :style="'background: ' + (row.empid == this.notifiedId && row.datetobechanged == this.notifiedDate  ? 'rgb(198 213 222)' : '') ">
            <tr>
              <td v-show="row.empname!=''">{{row.empname}}</td>
              <td>{{row.empid}}</td>
              <td>{{row.datetobechanged}}</td>
              <td>{{row.changeddate}}</td>
              <td>{{row.created_at}}</td>
              <td v-if="row.status == 'Pending'"> <span class="status pendin"></span> {{row.status}}</td>
              <td v-if="row.status == 'Rejected'"> <span class="status rejected"></span> {{row.status}}</td>
              <td v-if="row.status == 'Approved'"> <span class="status aprvd"></span> {{row.status}}</td>
              <td>
                <div class="btn-lps">
                  <a href="#" v-if="row.status =='Rejected' || row.status =='Pending'" @click="leaveapprove(row.id,row.empid,row.status,row.datetobechanged,row.changeddate,row.empname)" class="gren">Approve</a>
                  <a href="#" v-if="row.status =='Approved' || row.status =='Pending'" @click="leavereject(row.id,row.empid,row.status,row.datetobechanged,row.changeddate,row.empname)" class="rept">Reject</a>
                  <!-- <a href="leave-record-page.html" class="edtl" >view record</a> -->
                  <button @click="isOpen(row.id,row.empname)" class="edtl">view record</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="no_data_div" v-show="message.data == 0">
          <p>No Data Available</p>
        </div>
      </div>

      <pagination v-model="page" :records="totalData" :per-page="50" @paginate="getResults(page,this.searchText)"/>

    </div>
    
    <!-- <ModalViewLeaveStatus> -->
      <div class="popup-modal wfhDetailsModal" id="popupModa1" v-if="seen">
        <div class="mp-content">
          <button class="modalOut" type="button" id="modalOutIn1" @click="closemodal('close')">
            <img src="@/assets/images/icon/x-lg.svg" alt="">
          </button>
          <div class="bdy-sect">
            <h3>Employee Date Change Application Details</h3>
            <div class="item-cr">
              <div class="list-sr">
                <div class="ttx">
                  <img src="@/assets/images/icon/person.png" alt="">
                    Employee :
                  </div>
                <div class="vl">{{empname}}</div>
              </div>
              <div class="list-sr">
                <div class="ttx">
                    <img src="@/assets/images/icon/calendar-plus.png" alt="">
                    Date to be changed :
                </div>
                <div class="vl">
                  <ul>
                    <li>{{datetobechanged}}</li>
                  </ul>
              </div>
              </div>
              <div class="list-sr">
                <div class="ttx">
                    <img src="@/assets/images/icon/calendar-plus.png" alt="">
                    Requested Date :
                </div>
                <div class="vl">
                  <ul>
                    <li>{{changeddate}}</li>
                  </ul>
              </div>
              </div>
              <div class="list-sr">
                " {{reason}} "
              </div>
              <div class="item-cr-inline">
                <p>Document: {{docurl}} </p>
                <button @click="onClick()">Download</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </ModalViewLeaveStatus> -->

</div>
</div>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';

const mirror = createLocal();
const storage = mirror._prx;

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


import Swal from 'sweetalert2';

import ModalViewLeaveStatus from '../AdminDashboard/ModalViewLeaveStatus.vue'


export default {
name: 'LeaveStatus',
components:{
  ModalViewLeaveStatus,
  Pagination
},
setup() {
//  const isOpen = ref(false)

//  return { isOpen };

},
data() {
  return {
      page: 1,
      storageData: mirror,
      storage: storage,
      is_show: false,
      message:  {},
      searchText:"",
      nofound:false,
      errormessage: "",
      totalData:'',
      searchData:'',
      dateSearch:'',
      flag:'',
      errormessage1:"",
      leaveidapprove: '',
      leavestatusapprove: '',
      pending_leave : '',
      seen : false,
      empname : "",
      leavedate : "",
      leavetype : "",
      days : "",
      reason : "",
      docname : "",
      docurl : "",
      leavestatus : "",
      notifiedType:'',
      notifiedId:'',
      notifiedDate:'',
      changeddate:'',
      datetobechanged:''

  }
},
methods :{

  async clickedleavestatusDownload(){
    //console.log('leavestatus');
      var pdf = new jsPDF("1", "mm", "a1");
      var element = document.getElementById('leavestatusdownload1');
      var width= element.style.width;
      var height = element.style.height;
          html2canvas(element).then(canvas => {
              //console.log(canvas);
              var image = canvas.toDataURL('image/png');
              pdf.addImage(image, 'JPEG', 15, 40, width, height);
              pdf.save('LeaveStatus' + '.pdf')
      });
  },
 async isOpen(event,name){
   //console.log(event);
   this.seen = true;

   const getid = {
      id : event
   }; 
   //console.log(getid);


      await axios.post(BASE_URL + '/api/getmodaldatechangestatus',getid)
              .then((response)=>{
                  //console.log(response.data.record.empname);

                  this.empname = name;
                  this.datetobechanged = response.data.datetobechanged;
                  this.changeddate = response.data.changeddate;
                  this.reason = response.data.reason;
                  this.docurl = response.data.docurl;

          })

 },
  onClick() {
    //console.log(this.docurl);

      if(this.docurl === ''){
              Swal.fire({
                          title: 'OPPS',
                          text:  'No Data Available',
                          icon: 'warning',
                      
                      });
        }else{

              axios({
                      url: this.docurl,
                      method: 'GET',
                      responseType: 'blob',
                  }).then((response) => {
                      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                      var fileLink = document.createElement('a');
  
                      fileLink.href = fileURL;

                      if(response.data.type == 'image/jpeg'){
                          fileLink.setAttribute('download', 'file.jpeg');
                      }else if(response.data.type == 'image/jpg'){
                          fileLink.setAttribute('download', 'file.jpg');
                      }else if(response.data.type == 'image/png'){
                          fileLink.setAttribute('download', 'file.png');
                      }else if(response.data.type == 'application/pdf'){
                          fileLink.setAttribute('download', 'file.pdf');
                      }else if(response.data.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                          fileLink.setAttribute('download', 'file.docx');
                      }else{
                          fileLink.setAttribute('download', 'file.txt');
                      }

                      document.body.appendChild(fileLink);
  
                      fileLink.click();
                  });

        }

  },
  closemodal(event){
    this.seen = false;
  },
 onSearch(){
     this.getResults(this.page,this.searchText);
 },
  getResults(page,searchText='') {
      if (typeof page === "undefined") {
          page = 1;
      }
      if(searchText=='') {
          this.flag = 'list';
      }
      if(searchText!=''){
          this.flag = 'search';
          this.searchText = searchText;
      }
      
      const data ={
          userId: this.ID,
          flag: this.flag,
          searchText:this.searchText,

      }
      //console.log(data);
      
      axios.post(BASE_URL + `/api/getalldatechangeData?page=${page}`,data)
          .then((response)=>{
          //console.log(response.data);

          this.message = response.data;
          this.totalData =  response.data.total;
          this.is_show= (this.message.last_page > 1)?true:false;

          if(this.message.data?.length>0){
              this.nofound=false;
          }else{
              this.nofound=true;
          }

      })
  },
  async leaveapprove(id,empid,status,datetobechanged,changeddate,empname){

    if(confirm("Are you sure you want to approve this?")){
            const getempid = {
                      id : id,                                                                                                                                                                                                                                                                                                                                                                                                                         
                      empid: empid,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                      status: status
                  };

                  //console.log(getempid);

                  await axios.post(BASE_URL + '/api/approvedatechange',getempid)
                          .then((response)=>{
                              console.log(response.data);

                              if(response.data.success == true){
                                  const res= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: empid, date: datetobechanged.concat(",", changeddate), type: 'Date Change', status:'Approved', seenstatus: 'unseen', empname: empname});
                              //     axios.post(BASE_URL + '/api/sendapproveleave',{
                              //     email: response.data.empemail.email,
                              //     fname:  response.data.fname.fname,
                              //     lname:  response.data.lname.lname,
                              //     alldateleaves: response.data.alldate,
                              //     daysleave : response.data.daysleave,
                              //     leavetype : response.data.leavetype,
                              //     leavestatus : response.data.leavestatus,
                              // });
                                  // console.log(response.data);

                                  Swal.fire({
                                              title: 'Done',
                                              text:  response.data.message,
                                              icon: 'success',
                                          
                                          });

                                  this.getResults();
                                  // this.gettotalpendingleave();

                                  // this.leaveidapprove = response.data.leaveid;
                                  // this.leavestatusapprove = response.data.leavestatus;
                                    

                              }else{

                                  Swal.fire({
                                          title: 'OPPS',
                                          text:   response.data.message,
                                          icon: 'warning',
                                      
                                      });

                                  // this.leaveidapprove = '';
                                  // this.leavestatusapprove = '';
                                  
                              }

                      })
    }else{
      return false;
    }

  },
  async leavereject(id,empid,status,datetobechanged,changeddate,empname){

    if(confirm("Are you sure you want to reject this?")){

          const getempid = {
                id : id,                                                                                                                                                                                                                                                                                                                                                                                                                         
                empid: empid,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                status: status
            };

            //console.log(getempid);
                await axios.post(BASE_URL + '/api/rejectdatechange',getempid)
                    .then((response)=>{
                      //   console.log(response.data);

                        if(response.data.success == true){
                      //     axios.post(BASE_URL + '/api/sendrejectleave',{
                      //     email: response.data.empemail.email,
                      //     fname:  response.data.fname.fname,
                      //     lname:  response.data.lname.lname,
                      //     alldateleaves: response.data.alldate,
                      //     daysleave : response.data.daysleave,
                      //     leavetype : response.data.leavetype,
                      //     leavestatus : response.data.leavestatus,
                      // });
                          const res= axios.post(BASE_URL + '/api/storeresponsenotifications',{id: empid, date: datetobechanged.concat(",", changeddate), type: 'Date Change', status:'Rejected', seenstatus: 'unseen', empname: empname});

                            Swal.fire({
                                        title: 'Done',
                                        text:  response.data.message,
                                        icon: 'success',
                                    
                                    });

                            this.getResults();
                          //   this.gettotalpendingleave();

                        }else{
                            Swal.fire({
                                    title: 'OPPS',
                                    text:   response.data.message,
                                    icon: 'warning',
                                
                                });
                        }

                })

    }else{
      return false;
    }
      
  },
  // async gettotalpendingleave(){

  //     await axios.post(BASE_URL + '/api/gettotalpendingleave')
  //               .then((response)=>{
  //                   //console.log(response.data.pending_leave.count);

  //                   if(response.data.success == true){
  //                       this.pending_leave = response.data.pending_leave.count;

  //                   }

  //           })

  // }

},
mounted(){
  this.getResults();
  // this.gettotalpendingleave();
},
async created(){
this.userName = mirror.USERNAME
this.userEmail = mirror.USEREMAIL;

this.ID = mirror.AUTHID;
var hash = window.location.hash.split(/,(.*)/s);
this.notifiedId = hash[0].split("#")[1];
this.notifiedDate = hash[1];
//   console.log("hash",hash);

},
watch: {
   '$route.query'() { 
    //console.log('querydata',newId)
    // this.url_res = this.$route.params.res;
    // this.getdata.url_res = this.url_res;
    var hash = window.location.hash.split(",");
    this.notifiedId = hash[0].split("#")[1];
    this.notifiedDate = hash[1];
    console.log("hash14",window.location.hash);
    // this.getsearchresult(this.getdata);
    // setInterval(function () {window.location.hash =''}, 5000);
  }
}
}
</script>

<style scoped>

</style>