<template>
  <div class="main-right">
      <div class="main-title">
        <h2>Leave Report for {{new Date().getFullYear()}} </h2>
      </div>

     
      <div class="head-bar">
        <ul>
          <li>
            Number of Leaves taken: <span> {{totalleavetaken}}</span>
          </li>
        </ul>
        <div class="fild-info">
          <form action="#">
            <div class="fr-gp">
              <input type="search" v-model="searchQuery" placeholder="Search...">
              <button type="submit">
                <img src="@/assets/images/icon/search.svg" alt="">
              </button>
            </div>
          </form>
          <button v-if="messagedata.length > 0" class="dwnlod-btn"   @click="clickedDownload(resultQuery)">
            download
            <img src="@/assets/images/icon/download.svg" alt="">
          </button>
          <button class="dwnlod-btn" disabled v-else @click="clickedDownload(resultQuery)">
            download
            <img src="@/assets/images/icon/download.svg" alt="">
          </button>
          
        </div>
      </div>
      
      <div class="table-section" >
      <div class="table-wapper" id="divToPrintLeaveReportList">
        <!-- <div class="table-wapper"> -->
          
          <div class="table-data table-w-100">
            
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Employee ID</th>
                  <th>Medical Leave Left</th>
                  <th>Casual Leave Left</th>
                  <!-- <th>Number of Leaves Applied</th> -->
                  <th class="actions-header">Actions</th>
                </tr>
              </thead>
              <tbody v-for="(row) in resultQuery" :key="row" class="downloadable-row">
                <tr>
                  <td>
                      <div v-if="row.empname!=''"><a v-bind:="row" target="_blank">{{row.empname}}</a></div>
                      <!-- <div v-if="row.fname!=''">{{row.fname}} {{row.lname}}</div> -->
                  </td>
                  <td>
                      <div v-if="row.employeeid!=''">{{row.employeeid}}</div>
                  </td>
                  <td align="center">
                      <div v-if="row.remaining_leave_ml_leave!=''">{{row.remaining_leave_ml_leave}}</div>
                      <div v-if="row.sick_leave!=''">{{row.sick_leave}}</div>
                  </td>
                  <td align="center">
                      <div v-if="row.remaining_leave_casual!=''">{{row.remaining_leave_casual}}</div>
                      <div v-if="row.casual_leave!=''">{{row.casual_leave}}</div>  
                  </td>
                  <!-- <td>
                      0
                  </td> -->
                  <td align="center" class="actions-column">
                    <!-- <div class="btn-lps" v-if="row.empid!=''">\
                       <a href="leave-status.html" class="edtl" id="modal-tggl1" >view record</a>
                      <router-link :to="`/adminleaverecord/${row.empid}/${row.empname}/${row.remaining_leave}/${row.remaining_leave_ml}`" class="edtl" id="modal-tggl1">view record</router-link>
                    </div>
                    <div class="btn-lps" v-else>
                      <a href="leave-status.html" class="edtl" id="modal-tggl1" >view record</a>
                      <router-link :to="`/adminleaverecord/${row.id}/${row.fname + row.lname}/${row.casual_leave}/${row.sick_leave}`" class="edtl" id="modal-tggl1">view record</router-link>
                    </div> -->

                    <div class="btn-lps" v-if="!row.casual_leave">
                      <router-link :to="`/adminleaverecord/${row.empleaveid}/${row.empname}/${row.remaining_leave_casual}/${row.remaining_leave_ml_leave}`" class="edtl" id="modal-tggl1">view record</router-link>
                    </div>

                    <div class="btn-lps" v-if="row.casual_leave">
                      <!-- <router-link v-if="row.id!=''" :to="`/adminleaverecord/${row.id}/${row.fname + row.lname}/${row.casual_leave}/${row.sick_leave}`" class="edtl" id="modal-tggl1">view record</router-link> -->
                      <router-link v-if="row.id!=''" :to="`/adminleaverecord/${row.id}/${row.empname}/${row.casual_leave}/${row.sick_leave}`" class="edtl" id="modal-tggl1">view record</router-link>
                    </div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <!-- </div> -->

  </div>
</template>
<script>
import { createLocal } from 'the-storages'
import { BASE_URL } from "../../config";
import axios from "axios";
import Pagination from 'v-pagination-3';

import { ref, computed } from 'vue';

const mirror = createLocal();
const storage = mirror._prx;

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js";


import Swal from 'sweetalert2';


export default {
name: 'LeaveReport',
components:{
  Pagination
},
setup() {
//  const isOpen = ref(false)

//  return { isOpen };

},
data() {
  return {
      page: 1,
      storageData: mirror,
      storage: storage,
      is_show: false,
      messagedata:  {},
      searchText:"",
      nofound:false,
      errormessage: "",
      totalData:'',
      searchData:'',
      dateSearch:'',
      flag:'',
      errormessage1:"",
      totalleavetaken: "",
      searchQuery: null,

  }
},
methods :{
     onSearch(){
         //this.getResults(this.searchText);
     },
      getResults() {
          
          const data ={
              userId: this.ID,
              flag: this.flag,
              searchText:this.searchText,

          }
          //console.log(data);
          
          axios.post(BASE_URL + `/api/getallLeaveReport`,data)
              .then((response)=>{

                console.log("l",response.data.length);

              this.messagedata = response.data;
              

          })
      },

      async gettotalleavetaken(){
          await axios.post(BASE_URL + '/api/gettotalleavetaken')
                    .then((response)=>{
                        //console.log(response.data.pending_leave.count);
                        if(response.data.success == true){
                            this.totalleavetaken = response.data.taken_leave.count;

                        }

                })
      },
      async clickedDownload() {
        var element = document.getElementById('divToPrintLeaveReportList');
        
        // Temporarily hide the "Actions" column
        var headerCell = element.querySelector('.actions-header');
        if (headerCell) {
          headerCell.classList.add('hidden');
        }

        // Hide the "Actions" column from each row
        var rows = element.querySelectorAll('.downloadable-row');
        rows.forEach(row => {
          var actionsCell = row.querySelector('.actions-column');
          if (actionsCell) {
            actionsCell.classList.add('hidden');
          }
        });

        var opt = {
          margin: [0.5, 0.5, 0.5, 0.5], // Adjust margins
          filename: 'LeaveReport.pdf',
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
          html2canvas: { scale: 2 }, // Increase scale for better quality
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

        // New Promise-based usage:
        await html2pdf().set(opt).from(element).save();

        // Remove the hidden class to make the "Actions" column visible again
        if (headerCell) {
          headerCell.classList.remove('hidden');
        }
        rows.forEach(row => {
          var actionsCell = row.querySelector('.actions-column');
          if (actionsCell) {
            actionsCell.classList.remove('hidden');
          }
        });
      },



//       async clickedDownload(data) {
//   const doc = new jsPDF();

//   try {
//     // Create a temporary <div> element to append the table HTML
//     const tempDiv = document.createElement('div');
//     tempDiv.innerHTML = this.generateTableHTML(data);

//     // Wait for a brief moment to ensure all elements are rendered
//     await new Promise(resolve => setTimeout(resolve, 500));

//     // Convert HTML to canvas
//     const canvas = await html2canvas(tempDiv, { useCORS: true });

//     // Convert canvas to an image
//     const imgData = canvas.toDataURL('image/png');

//     // Add image to PDF
//     doc.addImage(imgData, 'PNG', 10, 10);

//     // Save the PDF
//     doc.save('table.pdf');
//   } catch (error) {
//     console.error('Error generating PDF:', error);
//   }
// },

      generateTableHTML(data) {
          let html = `
            <div class="table-section">
              <div class="table-wapper" id="divToPrintLeaveReportList">
                <div class="table-data table-w-100">
                  <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Employee ID</th>
                        <th>Medical Leave Left</th>
                        <th>Casual Leave Left</th>
                      </tr>
                    </thead>
                    <tbody>`;

          data.forEach(row => {
            html += `
              <tr>
                <td>
                  <div>${row.empname}</div>
                </td>
                <td>
                  <div>${row.employeeid}</div>
                </td>
                <td align="center">
                  <div>${row.remaining_leave_ml_leave || row.sick_leave}</div>
                </td>
                <td align="center">
                  <div>${row.remaining_leave_casual || row.casual_leave}</div>
                </td>
              </tr>`;
          });

          html += `
                    </tbody>
                  </table>
                </div>
              </div>
            </div>`;

          return html;
      },


},
computed: {
       resultQuery(){
       if(this.searchQuery){
       return this.messagedata.filter((row)=>{
       
       return this.searchQuery.toLowerCase().split(' ').every( w => row.empname.concat(row.employeeid).toLowerCase().includes(w));
       //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
       })
       }else{
       return this.messagedata;
       }
       },
},
mounted(){
  this.getResults();
  this.gettotalleavetaken();
},
async created(){
 this.userName = mirror.USERNAME
 this.userEmail = mirror.USEREMAIL;

 this.ID = mirror.AUTHID;
 

},
}
</script>

<style scoped>
.hidden {
  display: none;
}
.downloadable-row {
  page-break-inside: avoid;
}
</style>