<template>
  <div class="main-left">
    <div class="title-nav">
      <h4>navigation</h4>
    </div>
    <div class="nav_row_list">
      <ul>
        <li :class="{ active: isRouteActive('/adminDashboard') }">
          <router-link to="/adminDashboard">
            <span class="icon-nv">
              <img src="@/assets/images/icon/dashboard.png" alt="">
            </span>
            <div class="nav-ttx">Dashboard</div>
          </router-link>
        </li>
      </ul>
      <ul :class="{ open: isAccordionOpen('Hr-list') }">
        <li @click="toggleAccordion('Hr-list')" class="openNav-unlist">
          <a href="#">
            <span class="icon-nv">
              <img src="@/assets/images/icon/down-chevron-color.png" alt="">
            </span>
            <div class="nav-ttx">HR management</div>
          </a>
        </li>
        <div v-show="isAccordionOpen('Hr-list')">
          <li class="toggle-li" :class="{ active: isRouteActive('/adminWfhDetailsPage') }">
            <router-link to="/adminWfhDetailsPage">
              <span class="icon-nv">
                <img src="@/assets/images/icon/person-workspace.png" alt="">
              </span>
              <div class="nav-ttx">WFH</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/adminHolidayListPage') }">
            <router-link to="/adminHolidayListPage">
              <span class="icon-nv">
                <img src="@/assets/images/icon/icon-holiday.png" alt="">
              </span>
              <div class="nav-ttx">Holiday List</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/adminattendancereportpage') }">
            <router-link to="/adminattendancereportpage">
              <span class="icon-nv">
                <img src="@/assets/images/icon/attendance-rp.png" alt=""> 
              </span>
              <div class="nav-ttx">Attendance Report</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/adminleavestatus') }">
            <router-link to="/adminleavestatus">
              <span class="icon-nv">
                <img src="@/assets/images/icon/retirement-1.png" alt="">
              </span>
              <div class="nav-ttx">Leave Status</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/adminleavereport') }">
            <router-link to="/adminleavereport">
              <span class="icon-nv">
                <img src="@/assets/images/icon/lev-report-4.png" alt="">
              </span>
              <div class="nav-ttx">Leave Report</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/adminDateChangeStatusPage') }">
            <router-link to="/adminDateChangeStatusPage">
              <span class="icon-nv">
                <img src="@/assets/images/icon/change.png" alt="">
              </span>
              <div class="nav-ttx">Date Change Status</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/adminemplist') }">
            <router-link to="/adminemplist">
              <span class="icon-nv">
                <img src="@/assets/images/icon/employees-64.png" alt="">
              </span>
              <div class="nav-ttx">Employee List</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/attendanceAdjustment') }">
            <router-link to="/attendanceAdjustment">
              <span class="icon-nv">
                <img src="@/assets/images/icon/payment-dtl.png" alt="">
              </span>
              <div class="nav-ttx">Attendance Adjustment</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/leaveCarryForwardStatusPage') }">
            <router-link to="/leaveCarryForwardStatusPage">
              <span class="icon-nv">
                <img src="@/assets/images/icon/payment-dtl.png" alt="">
              </span>
              <div class="nav-ttx">Leave Carry Forward Status</div>
            </router-link>
          </li>
        </div>
      </ul>
      <ul :class="{ open: isAccordionOpen('Side-list') }">
        <li @click="toggleAccordion('Side-list')" class="openNav-unlist">
          <a href="#">
            <span class="icon-nv">
              <img src="@/assets/images/icon/down-chevron-color.png" alt="">
            </span>
            <div class="nav-ttx">Payroll management</div>
          </a>
        </li>
        <div v-show="isAccordionOpen('Side-list')">
          <li class="toggle-li" :class="{ active: isRouteActive('/salaryreport') }">
            <router-link to="/salaryreport">
              <span class="icon-nv">
                <img src="@/assets/images/icon/credit-card.png" alt="">
              </span>
              <div class="nav-ttx">Calculate Net Pay</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/salaryslip') }">
            <router-link to="/salaryslip">
              <span class="icon-nv">
                <img src="@/assets/images/icon/payment-dtl.png" alt="">
              </span>
              <div class="nav-ttx">Salary Slip and Approval</div>
            </router-link>
          </li>
        </div>
      </ul>
      <ul :class="{ open: isAccordionOpen('Marketing-list') }">
        <li @click="toggleAccordion('Marketing-list')" class="openNav-unlist">
          <a href="#">
            <span class="icon-nv">
              <img src="@/assets/images/icon/down-chevron-color.png" alt="">
            </span>
            <div class="nav-ttx">Email Marketing Management</div>
          </a>
        </li>
        <div v-show="isAccordionOpen('Marketing-list')">
          <li class="toggle-li" :class="{ active: isRouteActive('/assignUserPage') }">
            <router-link to="/assignUserPage">
              <span class="icon-nv">
                <img src="@/assets/images/icon/payment-dtl.png" alt="">
              </span>
              <div class="nav-ttx">Team Report</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/deal-report') }">
            <router-link to="/deal-report">
              <span class="icon-nv">
                <img src="@/assets/images/icon/credit-card.png" alt="">
              </span>
              <div class="nav-ttx">Deal Report</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/assign-incentives') }">
            <router-link to="/assign-incentives">
              <span class="icon-nv">
                <img src="@/assets/images/icon/credit-card.png" alt="">
              </span>
              <div class="nav-ttx">Assign Incentives</div>
            </router-link>
          </li>
          <li class="toggle-li" :class="{ active: isRouteActive('/taskReport') }">
            <router-link to="/taskReport">
              <span class="icon-nv">
                <img src="@/assets/images/icon/payment-dtl.png" alt="">
              </span>
              <div class="nav-ttx">Task Report</div>
            </router-link>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openAccordions: [],
    };
  },
  methods: {
    isRouteActive(route) {
      return this.$route.path === route;
    },
    isAccordionOpen(accordionId) {
      return this.openAccordions.includes(accordionId);
    },
    toggleAccordion(accordionId) {
      if (this.openAccordions.includes(accordionId)) {
        this.openAccordions = this.openAccordions.filter(id => id !== accordionId);
      } else {
        this.openAccordions.push(accordionId);
      }
    },
    setOpenAccordionsBasedOnRoute() {
      const currentRoute = this.$route.path;
      if (
        currentRoute.startsWith('/adminWfhDetailsPage') || 
        currentRoute.startsWith('/adminHolidayListPage') || 
        currentRoute.startsWith('/adminattendancereportpage') ||
        currentRoute.startsWith('/adminleavestatus') ||
        currentRoute.startsWith('/adminleavereport') ||
        currentRoute.startsWith('/adminDateChangeStatusPage') ||
        currentRoute.startsWith('/adminemplist') ||
        currentRoute.startsWith('/attendanceAdjustment') ||
        currentRoute.startsWith('/leaveCarryForwardStatusPage')
      ) {
        this.openAccordions.push('Hr-list');
      }
      if (
        currentRoute.startsWith('/salaryreport') || 
        currentRoute.startsWith('/salaryslip')
      ) {
        this.openAccordions.push('Side-list');
      }
      if (
        currentRoute.startsWith('/assignUserPage') || 
        currentRoute.startsWith('/deal-report') || 
        currentRoute.startsWith('/assign-incentives') || 
        currentRoute.startsWith('/taskReport')
      ) {
        this.openAccordions.push('Marketing-list');
      }
    }
  },
  watch: {
    $route() {
      this.setOpenAccordionsBasedOnRoute();
    }
  },
  mounted() {
    this.setOpenAccordionsBasedOnRoute();
  }
};
</script>

<style>
.side-dropdown {
  display: none;
}
.side-dropdown.open {
  display: block;
}
</style>
