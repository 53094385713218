<template>

    <div class="main-right">    
    <form v-on:submit.prevent="submitForm" ref="form" name="form">
    <div class="leaveBox new_emp">
            <div class="leaveRightBox">
                <div class="leaveRightTop">
                    
                </div>
                <div v-if="errormessage1 != ''" class="errormessage">
                    {{ errormessage1 }}
                </div>
                <div v-if="successmessage1 != ''" class="successmessage">
                    {{ successmessage1 }}
                </div>
                <div class="main-title text-center">
                    <h2 class="fs-3">Add New Employee</h2>
                </div>
                
                <div class="addNewBox addNewBox_n">
                    
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>First Name: <span>*</span></label>
                            <input class="addNewInput" type="text" :class="{ filderror: v3$.fname.$error }" v-model="state3.fname" placeholder="First Name">
                            <span class="error-span" v-if="v3$.fname.$error">Please Enter First Name</span>
                        </div>
                        <div class="addNewFirst adnleft">
                            <label>Last Name: <span>*</span></label>
                            <input class="addNewInput" type="text" :class="{ filderror: v3$.lname.$error }" v-model="state3.lname" placeholder="Last Name">
                            <span class="error-span" v-if="v3$.lname.$error">Please Enter Last Name</span>
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>Email Address: <span>*</span></label>
                            <!-- <input class="addNewInput" type="text" placeholder="Email Id"> -->
                            <input class="addNewInput" type="email" :class="{ filderror: v3$.email.$error }" v-model="state3.email" placeholder="Email Id">
                            <span class="error-span" v-if="v3$.email.$error">Please Enter Email Address</span>
                        </div>
                        <div class="addNewFirst adnleft">
                            <label>Contact:<span>*</span></label>
                            <input class="addNewInput" type="number" :class="{ filderror: v3$.phone.$error }" v-model="state3.phone"  placeholder="Contact No">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <span class="error-span" v-if="v3$.phone.$error">Please Enter Contact Number</span>
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>Date of Joining:</label>
                             <!-- <input class="addNewInput" type="text" placeholder="DOJ"> -->
                             <!-- <input class="addNewInput" type="date" :class="{ filderror: v3$.doj.$error }" v-model="state3.doj" placeholder="DOJ"> -->
                             <datepicker :full-month-name="true" :class="{ filderror: v3$.doj.$error }" v-model="state3.doj" placeholder="DOJ"/>
                             
                             <span class="error-span" v-if="v3$.doj.$error">Please Enter Date of Joining</span>
                        </div>
                        <div class="addNewFirst adnright">
                            <label>Date of Birth:</label>
                             <!-- <input class="addNewInput" type="text" placeholder="DOJ"> -->
                             <!-- <input class="addNewInput" type="date" :class="{ filderror: v3$.doj.$error }" v-model="state3.doj" placeholder="DOJ"> -->
                             <!-- <datepicker   :class="{ filderror: v3$.dob.$error }" v-model="state3.dob" :disabledDates="(date) => date <= new Date()" placeholder="DOB"/> -->
                            <datepicker :full-month-name="true" :class="{ filderror: v3$.dob.$error }" v-model="state3.dob" placeholder="DOB"/>
                             <span class="error-span" v-if="v3$.dob.$error">Please Enter Date of Birth</span>
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnleft">
                            <label>Department:<span>*</span></label>
                                <select v-model="state3.dept" :class="{ filderror: v3$.dept.$error }">
                                    <option value="undefined" disabled>-Select One-</option>
                                    <option value="Project Management">Project Management</option>
                                    <option value="Web Design">Web Design</option>
                                    <option value="Front-end Development">Front-end Development</option>
                                    <option value="Back-end Development">Back-end Development</option>
                                    <option value="Email Marketing">Email Marketing</option>
                                    <option value="Business Development">Business Development</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="Human Resources (HR)">Human Resources (HR)</option>
                                    <option value="Administrative">Administrative</option>
                                </select>
                                <span class="error-span" v-if="v3$.dept.$error">Please Enter Designation</span>

                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright" v-if="state3.dept == 'Email Marketing'">
                            <label>Alias Name:</label>
                            <!-- <input class="addNewInput" type="text" placeholder="Designation"> -->
                            <input class="addNewInput" type="text" v-model="state3.marketing_name" placeholder="Marketing Name">
                        </div>
                        <div class="addNewFirst adnright">
                            <label>Designation:<span>*</span></label>
                            <!-- <input class="addNewInput" type="text" placeholder="Designation"> -->
                            <input class="addNewInput" type="text" :class="{ filderror: v3$.designation.$error }" v-model="state3.designation" placeholder="Designation">
                                <span class="error-span" v-if="v3$.designation.$error">Please Enter Designation</span>
                        </div>
                        <div class="addNewFirst adnleft">
                            <label>Casual Leave:</label>
                             <!-- <input class="smallBox" type="text" placeholder=""> -->
                             <input class="addNewInput" type="number" v-model="state3.casual" placeholder="Casual Leave" min="0">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.casual.$error">Please Enter Casual Leave</span> -->
                        </div>
                    </div>
                    <div class="addNewSub">
                        <div class="addNewFirst adnright">
                            <label>Sick Leave:</label>
                            <!-- <input class="smallBox" type="text" placeholder=""> -->
                            <input class="addNewInput" type="number" v-model="state3.sick" placeholder="Sick Leave" min="0">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                        </div>
                        
                        <div class="addNewFirst adnright">
                            <label>Blood Group:</label>
                            <select class="addNewInput" v-model="state3.blood" placeholder="Select">
                                <option :value="blood" disabled>-Select One-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB">AB</option>
                                <option value="A-">A-</option>
                                <option value="A+">A+</option>
                            </select>
                        </div>

                        <div class="addNewFirst adnright">
                            <label>PAN: </label>
                            <!-- <input class="smallBox" type="text" placeholder=""> -->
                            <input class="addNewInput" type="text" v-model="state3.pan" placeholder="PAN" min="0">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                        </div>

                        <div class="addNewFirst adnright">
                            <label>UAN: </label>
                            <!-- <input class="smallBox" type="text" placeholder=""> -->
                            <input class="addNewInput" type="text" v-model="state3.uan" placeholder="UAN" min="0">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                        </div>

                        <div class="addNewFirst adnright">
                            <label>ESIC No: </label>
                            <!-- <input class="smallBox" type="text" placeholder=""> -->
                            <input class="addNewInput" type="text" v-model="state3.esic" placeholder="ESIC No" min="0">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.sick.$error">Please Enter Sick Leave</span> -->
                        </div>
                    </div>
    
                    <div class="addNewSub">
                        <div class="addNewFirst adnleft addNewFlex">
                            <label>Address:</label>
                            <textarea class="addNewInText" rows="6"  v-model="state3.message" placeholder="Address"></textarea>
                            <!-- <span class="error-span" v-if="v3$.message.$error">Please Enter Address</span> -->
                        </div>
                    </div>
    
                    <div class="addNewSub">
                    <div class="addNewFirst adnleft">
                            <label>Upload Image:</label>
                             <!-- <input class="smallBox" type="text" placeholder=""> -->
                             <!-- <input class="addNewInput" type="number" v-model="state3.casual" placeholder="Casual Leave" min="0"> -->
                             <input type="file" accept="image/*" class="addNewInput" @change="updatePhoto">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.casual.$error">Please Enter Casual Leave</span> -->
                    </div>
                    <div class="addNewFirst adnleft"  v-if="state3.dept == 'Email Marketing'">
                            <label>Digital Signature:</label>
                             
                            <input style="margin-top: 15px;" type="file" accept="image/*" class="addNewInput" @change="updateSignaturePhoto">
                                <!-- <span style="font-size: 12px;color: gray;">Example-12345</span> -->
                                <!-- <span class="error-span" v-if="v3$.casual.$error">Please Enter Casual Leave</span> -->
                    </div>
                    </div>
    
                    <div class="frg__addNewSub">
                        <router-link to="/adminDashboard" class="sty-bbt">Back</router-link>
                        <button class="sty-bbt" v-if="buttonclick == false" >Save</button>
                        <button class="sty-bbt" v-else disabled>Saving...</button>
                        <!-- <input class="sty-bbt" v-if="buttonclick == false" type="submit" value="submit">
                        <input class="sty-bbt" v-else disabled type="submit" value="submit"> -->
                    </div>
    
                    <div class="main-error-msg" v-if="errormessage || successmessage" :class="errormessage ? '' : 'suce-area'">
                        <h5 :class="{show:errormessage}" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg> {{errormessage}}</h5> 
                        <h5 :class="{show:successmessage}" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                        </svg> {{successmessage}}</h5> 
                    </div>
                </div>
                
            </div>
        </div>
        </form>
    
    </div>    
    </template>
    
    <script>
    import { createLocal, createSession, createStorage } from 'the-storages'
    import { BASE_URL } from "../../config";
    import axios from "axios";
    const mirror = createLocal()
    const storage = mirror._prx
    
    // import Datepicker from 'vue3-datepicker';
    import Datepicker from 'vuejs3-datepicker';

    import Swal from 'sweetalert2'
    import { ref } from 'vue'
    const picked = ref(new Date())
    
    import { defineComponent } from 'vue';
    import { reactive, computed } from "vue"
    import useValidate from "@vuelidate/core"
    import { required, email, minLength, maxLength } from "@vuelidate/validators"
    
    export default defineComponent({
      name: 'AddNewEmployee',
      components:{
        Datepicker
      },
      setup() {
          const state3 = reactive({
              fname: '',
              lname:'',
              email: '',
              phone: '',
              doj: new Date(),
              dob: new Date(),
              designation:'',
              casual:'',
              sick:'',
              blood:'',
              address:'',
              message:'',
              pan:'',
              uan:'',
              esic:'',
              marketing_name:'',

          })
    
          const rules3 = computed(() => {
              return {
                  fname: { required },
                  lname: { required },
                  email: { required, email },
                  phone: { required, minLength: minLength(10), maxLength: maxLength(12) },
                  dept: { required },
                  doj:{ required },
                  dob:{ required },
                  designation:{ required },
                //   casual:{ required },
                //   sick:{ required }
              }
          })
          const v3$ = useValidate(rules3, state3)
    
          return {
              state3,
              v3$
          }
      },
      data() {
          return {
              form: {
                  fname: '',
                  lname:'',
                  email: '',
                  phone: '',
                  message:'',
                  doj:'',
                  dob:'',
                  designation:'',
                  casual:'',
                  sick:'',
                  photo: {},
                  pan:'',
                  uan:'',
                  esic:'',
                  marketing_name:'',
                  signature: {}

              },
              errormessage: '',
              successmessage: '',
              errormessage1: '',
              successmessage1: '',
              files: "",
              type:"",
              state3: {},
              buttonclick:false,
              previewFile: null,
              previewUrl: null,
              dept: undefined,
              picked: new Date(),
              fileSelected:'',
              signature_file:''
          }
      },
      methods:{  
        updatePhoto (event) {
            this.fileSelected = event.target.files[0];
            console.log("fileSelected",this.fileSelected);
        },
        updateSignaturePhoto(event){
            this.signature_file = event.target.files[0];

        },
        async submitForm() {
            this.buttonclick = true;
            this.v3$.$validate()
            if (!this.v3$.$error) {
    
                //this.errormessage = '';
                //this.successmessage = 'Saved Successfully';
                
                console.log("dob-date",this.state3.dob);
                //console.log(this.$refs.file.files[0]);
                console.log("doj-date",this.state3.doj);
               
                var doj_month = this.state3.doj.getMonth()+1;
                var doj_year = this.state3.doj.getFullYear();
                var doj_date = this.state3.doj.getDate();
                var doj_month_string = doj_month.toLocaleString().padStart(2, '0');
                // var doj_year_string = doj_year.toLocaleString();
                var doj_date_string = doj_date.toLocaleString().padStart(2, '0');
    
                console.log("doj-date1",doj_month_string);
                // console.log("doj-date2",doj_year_string );
                console.log("doj-date3",doj_date_string);
                var formatted_doj = doj_date_string.concat("-",doj_month_string).concat("-",doj_year);
                console.log("formatted_doj",formatted_doj);  
                
                  
                var dob_month = this.state3.dob.getMonth()+1;
                var dob_year = this.state3.dob.getFullYear();
                var dob_date = this.state3.dob.getDate();
                var dob_month_string = dob_month.toLocaleString().padStart(2, '0');
                // var dob_year_string = dob_year.toLocaleString();
                var dob_date_string = dob_date.toLocaleString().padStart(2, '0');
    
                console.log("dob-date1",dob_month_string);
                // console.log("dob-date2",dob_year_string );
                console.log("dob-date3",dob_date_string);
                var formatted_dob = dob_date_string.concat("-",dob_month_string).concat("-",dob_year);
                console.log("formatted_dob",formatted_dob); 
                const getdata = {
                    fname: this.state3.fname, 
                    lname:this.state3.lname,
                    email:this.state3.email,
                    phone:this.state3.phone,
                    doj:formatted_doj,
                    dob:formatted_dob,
                    dept:this.state3.dept,
                    designation:this.state3.designation,
                    casual:this.state3.casual,
                    sick:this.state3.sick,
                    message:this.state3.message,
                    blood:this.state3.blood,
                    photo:this.fileSelected,
                    pan:this.state3.pan,
                    uan:this.state3.uan,
                    esic:this.state3.esic,

                };
    
                let formData = new FormData();
    
                formData.append('fname', this.state3.fname);
                formData.append('lname', this.state3.lname);
                formData.append('email', this.state3.email);
                formData.append('phone', this.state3.phone);
                formData.append('doj', formatted_doj);
                formData.append('dob', formatted_dob);
                formData.append('dept', this.state3.dept);
                formData.append('designation', this.state3.designation);
                formData.append('casual', this.state3.casual);
                formData.append('sick', this.state3.sick);
                formData.append('message', this.state3.message);
                formData.append('blood', this.state3.blood);
                formData.append('photo', this.fileSelected);
                formData.append('pan', this.state3.pan);
                formData.append('uan', this.state3.uan);
                formData.append('esic', this.state3.esic);
                formData.append('alias', this.state3.marketing_name);
                formData.append('signature', this.signature_file);
                // console.log(getdata.concat(this.photo));
    
                await axios.post(BASE_URL + '/api/registration',formData)
                    .then((response)=>{
                    console.log(response.data);
                    if(response.data.success == true){
    
                        Swal.fire({
                                    title: 'Done',
                                    text:  response.data.message,
                                    icon: 'success',
                                
                                });
    
    
    
                        this.state3 = "";
    
                        this.$router.push('/adminemplist');
    
    
                    }else{
    
                        Swal.fire({
                                title: 'OPPS',
                                text:   response.data.message,
                                icon: 'warning',
                            
                            });
    
    
                    }
                })
    
    
    
            } else {
                this.successmessage = '';
                this.errormessage = 'Please fill in mandatory fields';
            }
            //console.log("OUT:",this.v$.firstName.$error)
            //console.log("End3");
            setTimeout(() => {
                this.successmessage = '';
                this.errormessage = '';
                //console.log("HI");
            }, 10000);

            this.buttonclick = false;

        },
        // acceptNumber() {
        //         var x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        //         this.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        //         console.log("End3");
        //     },
      },
        computed: {
    
        },
        async created(){
            this.userName = mirror.USERNAME
        },
    });
    
    </script>
    
    <style scoped>
    
    </style>